import React from "react";
import Image from "../../resources/images/intro-page-3.svg";
import Page from "./components/page";
import SecondPage from "./components/second-page";

const Login: React.FC = () => {
  return (
    <Page image={Image}>
      <SecondPage />
    </Page>
  );
};

export default Login;
