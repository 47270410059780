import { memo, useEffect, useRef, useState } from "react";
import { ACCESS_PLAN, SquareFlags } from "../../../../app/constants";
import { useOnScreen } from "../../../../app/hooks";
import { fromCurrencyToStr } from "../../../../app/utils";
import ShopTextButton from "../../../../components/buttons/shop-text-button/shop-text-button";
import { makeContainer } from "../../../../components/container/container";
import { Candidate } from "../../../../features/candidates/type";
import ShopService from "../../../../features/shop/service";
import { OrderItem } from "../../../../features/shop/type";
import Sillouete from "../../../../resources/icons/user-silhouette.svg";
import "./shop-order-item.css";

type Props = {
  item: OrderItem;
};

const ShopOrderItemContainer = makeContainer("shop-order-item-container");
const ShopOrderItemImage = makeContainer("shop-order-item-image");
const ShopOrderItemDetails = makeContainer("shop-order-item-details");
const ShopOrderItemPrice = makeContainer("shop-order-item-price");
const ShopOrderTitleText = makeContainer("shop-order-item-title-text");
const ShopOrderDetailsText = makeContainer("shop-order-item-details-text");

const ShopOrderReportItem: React.FC<Props> = ({ item }) => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const [visibilityTimeout, setVisiblityTimeout] = useState(null);
  const [candidatePhoto, setCandidatePhoto] = useState(Sillouete);
  const [candidate, setCandidate] = useState<Candidate>(null);

  useEffect(() => {
    const fetchCandidateData = async () => {
      const service = new ShopService();
      const candidate = await service.getCandidate(item.candidate_id);
      setCandidate(candidate);
    };

    fetchCandidateData();
  });

  useEffect(() => {
    const notifyVisibilityChange = () => {
      const shouldFetch = isVisible && !visibilityTimeout;
      const shouldStopFetching = !isVisible && visibilityTimeout;

      const fetchPhoto = async () => {
        const service = new ShopService();
        const photo = await service.getCandidatePhoto(item.candidate_id);
        if (photo) {
          setCandidatePhoto(`data:image/png;base64,${photo}`);
        }
      };

      if (shouldFetch) {
        setVisiblityTimeout(setTimeout(() => fetchPhoto(), 1000));
      }

      if (shouldStopFetching) {
        clearTimeout(visibilityTimeout);
        setVisiblityTimeout(null);
      }
    };

    notifyVisibilityChange();
    // eslint-disable-next-line
  }, [item, isVisible]);

  return (
    <>
      <hr className="shop-order-separator " />
      <ShopOrderItemContainer>
        <ShopOrderItemImage>
          <img
            ref={ref}
            alt={"foto do candidato"}
            className="shop-candidate-order-item-photo"
            src={candidatePhoto}
          ></img>
        </ShopOrderItemImage>
        <ShopOrderItemDetails>
          <ShopOrderTitleText>{item.product.description}</ShopOrderTitleText>
          <ShopOrderDetailsText>{`Candidato: ${candidate?.ballotboxname}`}</ShopOrderDetailsText>
          <ShopOrderDetailsText>{`Ano: ${item.year}`}</ShopOrderDetailsText>
          <ShopOrderDetailsText>{`Turno: ${item.turn}º turno`}</ShopOrderDetailsText>
        </ShopOrderItemDetails>
        <ShopOrderItemPrice>
          <div>{fromCurrencyToStr(item.product.price)}</div>
          {item.report_url && (
            <div>
              <ShopTextButton
                onClick={() => {
                  window.open(item.report_url, "_blank");
                }}
              >
                {"Baixar PDF"}
              </ShopTextButton>
            </div>
          )}
        </ShopOrderItemPrice>
      </ShopOrderItemContainer>
    </>
  );
};

const ShopOrderPlanItem: React.FC<Props> = ({ item }) => {
  return (
    <>
      <hr className="shop-order-separator " />
      <ShopOrderItemContainer>
        <ShopOrderItemImage>
          <img
            alt={"bandeira do estado candidato"}
            className="shop-candidate-order-item-flag"
            src={SquareFlags[item.product.state]}
          ></img>
        </ShopOrderItemImage>
        <ShopOrderItemDetails>
          <ShopOrderTitleText>{item.product.description}</ShopOrderTitleText>
          <ShopOrderDetailsText>{`Estado: ${item.product.state}`}</ShopOrderDetailsText>
          <ShopOrderDetailsText>{`Anos: ${item.product.years[0]}-${
            item.product.years[item.product.years.length - 1]
          }`}</ShopOrderDetailsText>
          <ShopOrderDetailsText>{`Possui Meta de Votos: ${
            item.product.has_votes_target ? "Sim" : "Não"
          }`}</ShopOrderDetailsText>
          <ShopOrderDetailsText>{`Duração: ${item.product.duration} dias`}</ShopOrderDetailsText>
        </ShopOrderItemDetails>
        <ShopOrderItemPrice>
          <div>{fromCurrencyToStr(item.product.price)}</div>
          {item.processed && (
            <div>
              <ShopTextButton
                onClick={() => {
                  window.open("/", "_blank");
                }}
              >
                {"Acessar Plataforma"}
              </ShopTextButton>
            </div>
          )}
        </ShopOrderItemPrice>
      </ShopOrderItemContainer>
    </>
  );
};

const ShopOrderItem: React.FC<Props> = ({ item }) => {
  if (item.product.product_type === ACCESS_PLAN) {
    return <ShopOrderPlanItem item={item} />;
  } else {
    return <ShopOrderReportItem item={item} />;
  }
};

export default memo(ShopOrderItem);
