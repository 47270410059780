import React, { memo, useState } from "react";
import { toPtBr } from "../../../app/utils";
import { GroupAttributeData } from "../../../features/dashboard/types";
import { makeContainer } from "../../container/container";
import WidgetItemText, {
  WidgetSubItemText,
} from "../../text/widget-item-text/widget-item-text";
import "./widget-item.css";

type Props = {
  group?: GroupAttributeData;
};

const WidgetItemContainer = makeContainer("widget-item-container");
const WidgetItemTextContainer = makeContainer("widget-item-text-container");
const WidgetBarContainer = makeContainer("widget-bar-container");

const WidgetBar: React.FC<Props> = ({ group }) => {
  const [width, setWidth] = useState("0%");
  const percentage = group.group_relative_value / 100;
  const percentageBarWidth = `${percentage}%`;

  setTimeout(() => {
    setWidth(percentageBarWidth);
  }, 1500);

  return (
    <div className="widget-bar">
      <div
        className="widget-bar-item-progress"
        style={{
          width: width,
        }}
      >
        {" "}
      </div>
      <span className="widget-bar-item-text">{toPtBr(group.group_value)}</span>
    </div>
  );
};

const WidgetItem: React.FC<Props> = ({ group }) => {
  return (
    <WidgetItemContainer>
      <WidgetItemTextContainer>
        <WidgetItemText>{group.group_name}</WidgetItemText>
        <WidgetSubItemText>{group.group_subname}</WidgetSubItemText>
      </WidgetItemTextContainer>
      <WidgetBarContainer>
        <WidgetBar group={group} />
      </WidgetBarContainer>
    </WidgetItemContainer>
  );
};

export default memo(WidgetItem);
