export enum AttributeTypes {
  SCALAR = "scalar",
  GROUP = "group",
  MAP = "map",
}

export default interface Attribute {
  name: string;
  friendly_name: string | null;
  description: string;
  suffix: string;
  type: AttributeTypes;
  valid_levels: string[];
  valid_filters: string[];
  origin: string;
}
