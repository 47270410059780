import { memo } from "react";
import "./login-subtitle-text.css";

type Props = {
  children: string;
  className?: string;
};

const LoginSubtitleText: React.FC<Props> = ({ children, className }) => {
  return <span className={`login-subtitle-text ${className}`}>{children}</span>;
};

export default memo(LoginSubtitleText);
