import * as React from "react";
import { useSelector } from "react-redux";
import { validateText } from "../../../../../app/utils";
import { selectPalette } from "../../../../../features/notebook/slice";
import { ClippedImage, Paper, PaperInside } from "../commons/commons";
import { TemplatePageProps } from "../commons/interfaces";

const Page10: React.FC<TemplatePageProps> = ({
  className,
  notebook,
  triggerImageEdit,
  triggerFieldEdit,
  updateField,
}) => {
  const { palette } = useSelector(selectPalette);
  return (
    <Paper className={className}>
      <PaperInside>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
        >
          <defs>
            <clipPath id="a">
              <path d="M1473.17 1793.73h126.33a165 165 0 0 1 165 165v347h-142.94a148.39 148.39 0 0 1-148.39-148.39z" />
            </clipPath>
          </defs>
          <path
            d="M-.069-.03H210.8v298.227H-.069z"
            style={{ fill: "#fcfcf5", strokeWidth: 0.354234 }}
          />

          <foreignObject x={18} y={65.932} width={60} height={120}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1px",
                fontSize: "20.8219px",
                fontFamily: "Heavitas",
                lineHeight: "24px",
                fontWeight: 700,
                color: palette.primary,
                textAlign: "end",
                width: "100%",
              }}
            >
              <div style={{ color: palette.terciary }}>meu</div>
              <div>di</div>
              <div>fe</div>
              <div>ren</div>
              <div>cial</div>
            </div>
          </foreignObject>

          <ClippedImage
            id="saphire-page-10-1"
            triggerImageEdit={triggerImageEdit}
            x={85}
            y={18}
            width={104}
            height={183}
            imageNumber={14}
            imageRatio={0.56}
            notebook={notebook}
          >
            <path
              d="m 1473.17,1793.73 h 126.33 a 165,165 0 0 1 165,165 v 347 h -142.94 a 148.39,148.39 0 0 1 -148.39,-148.39 z"
              transform="matrix(.35423 0 0 .35423 -436.33 -616.512)"
              fill={palette.backgroundLight}
            />
          </ClippedImage>

          <foreignObject
            x={20.005}
            y={210.032}
            width={169}
            height={61}
            className="clickable"
          >
            <div
              style={{
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: palette.backgroundGray,
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.strenghts,
                  field: "strenghts",
                  label: "Diferencial",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.strenghts}
            </div>
          </foreignObject>

          <path
            d="M1382.2 1847.4a10.86 10.86 0 0 0-2.18-6.53 7.17 7.17 0 0 1-1.51-3.92 10.55 10.55 0 0 1 1.38-5.38 4.33 4.33 0 0 1 2.19-1.85c3.81-1.64 6.2-4.65 7.88-8.29a18.46 18.46 0 0 0 1-3.29 6 6 0 0 1 2.33-3.52 7.37 7.37 0 0 1 4.23-1.84 16 16 0 0 1 3 .4c1.32.24 2.62.67 4 .78a12.43 12.43 0 0 0 7.75-2c.33-.21.68-.4 1-.6a8.79 8.79 0 0 1 9.36.69 9.4 9.4 0 0 1 2.14 2.75 27.52 27.52 0 0 0 2.33 3.49 11.28 11.28 0 0 0 5.53 3.31c1 .31 2 .59 3 1 3.07 1.08 4.4 3.48 4.77 6.53a15.59 15.59 0 0 1-.58 4.59 14.8 14.8 0 0 0 .28 7.36 10.38 10.38 0 0 0 1.54 3c2.09 2.64 1.95 5.42.87 8.37a7.16 7.16 0 0 1-3.81 4.19 11.81 11.81 0 0 0-4.2 3 10.92 10.92 0 0 0-2.39 4.72c-.14.61-.29 1.22-.42 1.84a6.39 6.39 0 0 1-2.46 4 23.76 23.76 0 0 1-3.3 2 5.53 5.53 0 0 1-4.51.13 15.55 15.55 0 0 0-3.31-.93 9.8 9.8 0 0 0-8.22 2.42 5.61 5.61 0 0 1-5.58 1.41 44.26 44.26 0 0 1-4.66-1.48 5.26 5.26 0 0 1-3.19-3.32 11.1 11.1 0 0 0-4.85-5.87 7.51 7.51 0 0 0-3.34-1.16c-3.61-.28-5.55-2.52-6.83-5.62a7.09 7.09 0 0 1-.23-5 15.05 15.05 0 0 0 .99-5.38zm52.47-4.85a23.78 23.78 0 1 0-23.55 23.84 23.77 23.77 0 0 0 23.55-23.84zM1448.36 1899.24l-15.71-7.42c-1.69 5.64-3.36 11.24-5 16.84h-.14l-11.2-25.66a40.39 40.39 0 0 0 21.4-8.83l10.75 25zM1376 1899.14c3.57-7.92 7.13-15.85 10.75-23.9 6.19 5 13.34 7.48 21.21 8.27l-11 25.16h-.14c-1.67-5.6-3.34-11.2-5-16.86l-15.7 7.41z"
            style={{ fill: palette.secondary }}
            transform="matrix(.35423 0 0 .35423 -436.33 -616.512)"
          />
          <path
            d="M1410.9 1863.34a20.73 20.73 0 1 1 20.7-20.68 20.73 20.73 0 0 1-20.7 20.68zm-12.41-19.52 9.51 9.53 17.67-17.67-3.92-4-13.8 13.84-5.55-5.63z"
            style={{ fill: palette.secondary }}
            transform="matrix(.35423 0 0 .35423 -436.33 -616.512)"
          />
          <path
            d="M-.069 298.196s34.577-17.782 83.798-16.21c49.221 1.573 40.857 13.245 75.806 13.203 41.665-.05 51.01-8.172 51.01-8.172v11.18z"
            style={{ fill: palette.primary, strokeWidth: 0.354234 }}
          />
        </svg>
      </PaperInside>
    </Paper>
  );
};

export default Page10;
