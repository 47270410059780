import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { SECONDARY_TARGET_LEVEL } from "../../../app/constants";
import { AppDispatch } from "../../../app/store";
import {
  setIsPriorityRoot,
  setPriorityLevel,
} from "../../../features/target/slice";
import PrioritiesSelectionPage from "./components/priorities-selection-page";

export const SecondaryPrioritiesPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(setPriorityLevel(SECONDARY_TARGET_LEVEL));
    dispatch(setIsPriorityRoot(true));
  }, [dispatch]);

  return <PrioritiesSelectionPage />;
};

export default SecondaryPrioritiesPage;
