import React, { memo } from "react";
import { Status } from "../../../../app/constants";
import ShopButton from "../../../../components/buttons/shop-button/shop-button";
import {
  Candidate,
  CandidateElectionSummary,
} from "../../../../features/candidates/type";

import "./shop-candidature-search-result-item.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  compactMode?: boolean;
  status?: Status;
  candidate?: Candidate;
  summary?: CandidateElectionSummary;
  party?: string;
  turn?: string;
  candidatureStatus?: string;
  selected?: CandidateElectionSummary | null;
  setSelected?: (CandidateElectionSummary) => void;
};

type CandidateDetailItemProps = {
  children?: string | JSX.Element | JSX.Element[];
  maxWidth?: number;
};

const CandidateDetailItem: React.FC<CandidateDetailItemProps> = ({
  children,
  maxWidth,
}) => {
  return (
    <div
      className="shop-candidate-candidatures-item"
      style={{ maxWidth: maxWidth }}
    >
      {children}
    </div>
  );
};

const CandidatureTurn: React.FC<Props> = ({ summary }) => {
  return (
    <div className="shop-candidate-candidatures-turn">{summary?.turn}</div>
  );
};

const CandidateCandidatureContent: React.FC<Props> = ({
  summary,
  selected,
  setSelected,
}) => {
  const isSelected =
    summary?.electionyear === selected?.electionyear &&
    summary?.turn === selected?.turn &&
    summary?.electionplace === selected?.electionplace;

  const handleClick = () => {
    if (isSelected) {
      setSelected(null);
      return;
    }

    setSelected(summary);
  };

  return (
    <div className="shop-candidate-candidatures-content">
      <CandidateDetailItem maxWidth={35}>
        {summary?.electionyear}
      </CandidateDetailItem>
      <CandidateDetailItem maxWidth={35}>
        <CandidatureTurn summary={summary} />
      </CandidateDetailItem>
      <CandidateDetailItem>{summary?.electionplace}</CandidateDetailItem>
      <CandidateDetailItem>{summary?.office}</CandidateDetailItem>
      <CandidateDetailItem>
        <ShopButton selected={isSelected} onClick={handleClick}>
          {isSelected ? "Selecionado" : "Selecionar"}
        </ShopButton>
      </CandidateDetailItem>
    </div>
  );
};

const ShopCandidatureSearchResultItem: React.FC<Props> = ({
  summary,
  selected,
  setSelected,
}) => {
  return (
    <CandidateCandidatureContent
      summary={summary}
      selected={selected}
      setSelected={setSelected}
    />
  );
};

export default memo(ShopCandidatureSearchResultItem);
