import { memo } from "react";
import { Status } from "../../../../../app/constants";
import { toPtBrContracted } from "../../../../../app/utils";
import { makeContainer } from "../../../../../components/container/container";
import PartySummaryWidgetText from "../../../../../components/text/party-summary-widget-text/party-summary-widget-text";
import WidgetTitleText from "../../../../../components/text/widget-title-text/widget-title-text";
import { PartyProfileData } from "../../../../../features/parties/type";
import "./party-profile-summary.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  summary?: PartyProfileData;
  status?: Status;
};

type WidgetProps = {
  title: string;
  value?: string;
  status?: Status;
};

const PartyProfileSummaryContainer = makeContainer(
  "party-profile-summary-container"
);
const WidgetTitleContainer = makeContainer("party-profile-summary-container");

const PartyProfileSummaryContainerLine: React.FC<Props> = ({ children }) => {
  return (
    <div className={"party-profile-summary-container-line"}>{children}</div>
  );
};

const PartyProfileSummaryContainerWidget: React.FC<WidgetProps> = ({
  title,
  value,
  status,
}) => {
  return (
    <div className={"party-profile-summary-widget"}>
      <WidgetTitleContainer>
        <div>
          <WidgetTitleText hideIcon={true}>{title}</WidgetTitleText>
        </div>
        <PartySummaryWidgetText>
          {status === Status.SUCCEEDED ? value : ""}
        </PartySummaryWidgetText>
      </WidgetTitleContainer>
    </div>
  );
};

const PartyProfileSummary: React.FC<Props> = ({ summary, status }) => {
  return (
    <PartyProfileSummaryContainer>
      <PartyProfileSummaryContainerLine>
        <PartyProfileSummaryContainerWidget
          title="Votos"
          status={status}
          value={toPtBrContracted(summary?.nominal_votes)}
        />
        <PartyProfileSummaryContainerWidget
          title="Votos Legenda"
          status={status}
          value={toPtBrContracted(summary?.legend_votes)}
        />
      </PartyProfileSummaryContainerLine>
      <PartyProfileSummaryContainerLine>
        <PartyProfileSummaryContainerWidget
          title="Canditatos"
          status={status}
          value={toPtBrContracted(summary?.candidatures_number)}
        />
        <PartyProfileSummaryContainerWidget
          title="Eleitos"
          status={status}
          value={toPtBrContracted(summary?.elected_number)}
        />
      </PartyProfileSummaryContainerLine>
    </PartyProfileSummaryContainer>
  );
};

export default memo(PartyProfileSummary);
