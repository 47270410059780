import { memo, useState } from "react";
import Select, { ActionMeta, components } from "react-select";
import DashboardFilterText from "../../text/dashboard-filter-text/dashboard-filter-text";
import "./settings-select.css";

type Option = {
  value: any;
  label: any;
};

type Props = {
  label: string;
  className?: string;
  value?: any;
  placeHolder?: string;
  options?: Option[];
  isDisabled?: boolean;
  onChange?: (newValue: any, actionMeta: ActionMeta<any>) => void;
};

type ContainerProps = {
  children?: string | JSX.Element | JSX.Element[];
  className?: string;
};

const DashSelectContainer: React.FC<ContainerProps> = ({
  children,
  className,
}) => {
  return (
    <div className={`settings-select-outer-container ${className}`}>
      {children}
    </div>
  );
};

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className="custom-css-class">Sem Resultados</span>
    </components.NoOptionsMessage>
  );
};

const style = {
  control: (base) => ({
    ...base,
    border: "1px solid #D5DAE1",
    "&:hover": {
      border: "1px solid #D5DAE1",
    },
    boxShadow: "none",
  }),
  option: (base, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...base,
      backgroundColor: isFocused ? "#f1f1f1" : "#ffffff",
      color: "#333333",
      "&:active": {
        backgroundColor: "#D5DAE1",
      },
    };
  },
  valueContainer: (base, state) => ({
    ...base,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  }),
};

const SettingsSelect: React.FC<Props> = ({
  label,
  className,
  value,
  options,
  placeHolder,
  isDisabled,
  onChange,
}) => {
  const [innerValue, setInnerValue] = useState(value);

  return (
    <DashSelectContainer className={className}>
      <DashboardFilterText>{label}</DashboardFilterText>
      <Select
        styles={style}
        components={{ NoOptionsMessage }}
        placeholder={placeHolder}
        classNames={{
          container: (state) => "settings-select-container",
          control: (state) => "settings-select",
          placeholder: (props) => "settings-select-placeholder",
          option: (props) => {
            return props.isSelected
              ? "settings-select-selected-option"
              : "settings-select-option";
          },
          menu: (props) => "settings-select-menu",
        }}
        value={innerValue}
        options={options}
        onChange={(newValue, actionMeta) => {
          setInnerValue(newValue);
          onChange(newValue, actionMeta);
        }}
        isDisabled={isDisabled}
      />
    </DashSelectContainer>
  );
};

export default memo(SettingsSelect);
