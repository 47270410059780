import { memo } from "react";
import "./popup-menu-item-text.css";

type Props = {
  children: string;
  isSelected?: boolean;
};

const PopUpMenuItemText: React.FC<Props> = ({ children, isSelected }) => {
  return (
    <span
      className={
        isSelected ? "popup-menu-item-text-selected" : "popup-menu-item-text"
      }
    >
      {children}
    </span>
  );
};

export default memo(PopUpMenuItemText);
