import { memo } from "react";
import "./modal-title-text.css";

type Props = {
  children: string;
  className?: string;
};

const ModalTitleText: React.FC<Props> = ({ children, className }) => {
  return <span className={`modal-title-text ${className}`}>{children}</span>;
};

export default memo(ModalTitleText);
