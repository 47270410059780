import { throttle } from "lodash";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Status } from "../../../../app/constants";
import { AppDispatch } from "../../../../app/store";
import { makeContainer } from "../../../../components/container/container";
import Loading from "../../../../components/loading/loading";
import { closeModal, setModalContent } from "../../../../features/modal/slice";
import {
  getTarget,
  saveTarget,
  selectSaveTargetStatus,
  selectTargetRoot,
} from "../../../../features/target/slice";

import TargetFormButton from "../../../../components/buttons/target-form-button/target-form-button";
import TargetService from "../../../../features/target/service";
import { TargetRootResponse } from "../../../../features/target/type";
import Check from "../../../../resources/images/check.png";
import Error from "../../../../resources/images/error.png";
import "./save-target-modal.css";

type ModalProps = {
  show: boolean;
  onClose: () => void;
};

const updateStatus = throttle(
  (currentStatus: Status, nextStatus: Status, setStatus: (Status) => void) => {
    if (currentStatus === Status.IDLE && nextStatus === Status.FAILED) {
      return;
    }
    setStatus(nextStatus);
  },
  500,
  { leading: false, trailing: true }
);

const SaveTargetModalContainer = makeContainer("save-target-modal-container");
const SaveTargetButtonsContainer = makeContainer(
  "save-target-modal-buttons-container"
);
const SaveTargetTopContainer = makeContainer("save-target-modal-top-container");
const SaveTargetTopText = makeContainer("save-target-modal-top-text");
const SaveTargetTitle = makeContainer("save-target-modal-title");
const ContentContainer = makeContainer("save-target-modal-content-container");

const SaveTargetContent: React.FC<{ status: Status }> = ({ status }) => {
  const dispatch: AppDispatch = useDispatch();
  const targetRoot: TargetRootResponse = useSelector(selectTargetRoot);
  const [targetName, setTargetName] = useState("");

  const handleSaveTarget = () => {
    dispatch(
      saveTarget({
        targetId: targetRoot.id,
        service: new TargetService(),
        request: {
          target_id: targetRoot.id,
          name: targetName,
        },
      })
    );
  };

  return (
    <SaveTargetModalContainer>
      <SaveTargetTopContainer>
        <SaveTargetTopText>Falta pouco</SaveTargetTopText>
        <SaveTargetTitle>Dê um nome para sua Meta de Votos</SaveTargetTitle>
      </SaveTargetTopContainer>
      <ContentContainer>
        {status === Status.IDLE && (
          <input
            className="save-target-modal-input"
            value={targetName}
            onChange={(e) => {
              if (e.target.value.length <= 30) {
                setTargetName(e.target.value);
              }
            }}
          />
        )}
        {status === Status.LOADING && <Loading />}
      </ContentContainer>
      <SaveTargetButtonsContainer>
        <TargetFormButton
          disabled={!targetName || status === Status.LOADING}
          onClick={handleSaveTarget}
        >
          Salvar Meta de Votos
        </TargetFormButton>
      </SaveTargetButtonsContainer>
    </SaveTargetModalContainer>
  );
};

const SavedTargetContent: React.FC<{ close: any }> = ({ close }) => {
  return (
    <SaveTargetModalContainer>
      <SaveTargetTopContainer>
        <SaveTargetTopText>Feito</SaveTargetTopText>
        <SaveTargetTitle>Meta salva com sucesso</SaveTargetTitle>
      </SaveTargetTopContainer>
      <ContentContainer>
        <img className="modal-large-icon" alt="ícone sucesso" src={Check} />
      </ContentContainer>
      <SaveTargetButtonsContainer>
        <TargetFormButton onClick={close}>Fechar</TargetFormButton>
      </SaveTargetButtonsContainer>
    </SaveTargetModalContainer>
  );
};

const ErrorDuringSaveTargetContent: React.FC<{ close: any }> = ({ close }) => {
  return (
    <SaveTargetModalContainer>
      <SaveTargetTopContainer>
        <SaveTargetTopText>Algo deu errado...</SaveTargetTopText>
        <SaveTargetTitle>
          Meta não salva, tente novamente mais tarde
        </SaveTargetTitle>
      </SaveTargetTopContainer>
      <ContentContainer>
        <img className="modal-large-icon" alt="ícone erro" src={Error} />
      </ContentContainer>
      <SaveTargetButtonsContainer>
        <TargetFormButton onClick={close}>Fechar</TargetFormButton>
      </SaveTargetButtonsContainer>
    </SaveTargetModalContainer>
  );
};

const SaveTargetModal: React.FC<ModalProps> = memo(({ show, onClose }) => {
  const dispatch: AppDispatch = useDispatch();
  const [status, setStatus] = useState(Status.LOADING);
  const nextStatus: Status = useSelector(selectSaveTargetStatus);
  const targetRoot: TargetRootResponse = useSelector(selectTargetRoot);

  useEffect(() => {
    updateStatus(status, nextStatus, setStatus);
    // eslint-disable-next-line
  }, [nextStatus]);

  useEffect(() => {
    setStatus(Status.IDLE);
  }, [show]);

  const close = () => {
    dispatch(
      getTarget({
        targetId: targetRoot.id,
        service: new TargetService(),
      })
    );

    onClose();
    dispatch(closeModal(null));
  };

  const contentByStatus = {
    [Status.IDLE]: <SaveTargetContent status={status} />,
    [Status.LOADING]: <SaveTargetContent status={status} />,
    [Status.SUCCEEDED]: <SavedTargetContent close={close} />,
    [Status.FAILED]: <ErrorDuringSaveTargetContent close={close} />,
  };

  useEffect(() => {
    const changeModalStatus = () => {
      if (show) {
        dispatch(
          setModalContent({
            content: contentByStatus[status],
            onClose: () => {
              if (status === Status.IDLE) {
                onClose();
              }
            },
          })
        );
      } else {
        dispatch(closeModal(null));
      }
    };

    changeModalStatus();
    // eslint-disable-next-line
  }, [dispatch, onClose, show, status]);

  return <></>;
});

export default SaveTargetModal;
