import { ChangeEventHandler, memo } from "react";
import "./login-input-text.css";

type Props = {
  className?: string;
  value?: string;
  type?: string;
  placeHolder?: string;
  onEnterPressed?: () => void;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

const LoginInputText: React.FC<Props> = ({
  className,
  value,
  type,
  placeHolder,
  onChange,
  onEnterPressed,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onEnterPressed();
    }
  };

  return (
    <input
      onChange={onChange}
      onKeyDown={handleKeyDown}
      value={value}
      type={type}
      placeholder={placeHolder}
      className={`login-input-text ${className}`}
    ></input>
  );
};

export default memo(LoginInputText);
