import { memo, MouseEventHandler } from "react";
import { Status } from "../../../app/constants";
import "./change-password-button.css";

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  status?: Status;
};

const ChangePasswordButton: React.FC<Props> = (props) => {
  const { onClick, status } = props;

  return (
    <button className={"change-password-button"} onClick={onClick}>
      {status !== Status.LOADING ? (
        "Alterar Senha"
      ) : (
        <span className="change-password-button-text">Carregando</span>
      )}
    </button>
  );
};

export default memo(ChangePasswordButton);
