import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Mode, Pages } from "../../app/constants";
import ChangePaneButton from "../../components/buttons/change-pane-button/change-pane-button";
import { makeContainer } from "../../components/container/container";
import Page from "../../components/main-page/page";
import TitleText from "../../components/text/title-text/title-text";
import "./dashboard.css";
import FilterWithData from "./hocs/filter-with-data";
import MapWithData from "./hocs/map-with-data";
import NumberWidgetWithData, {
  WidgetSide,
} from "./hocs/number-widget-with-data";
import WidgetWithData from "./hocs/widget-with-data";

type Props = {
  mode: Mode;
};

type PaneProps = {
  children?: string | JSX.Element | JSX.Element[];
  show: boolean;
};

const PageContainer = makeContainer("dashboard-page-container");
const FilterContainer = makeContainer("dashboard-filter");
const TopWidgetContainer = makeContainer("dashboard-top-widget-container");
const BottomWidgetContainer = makeContainer(
  "dashboard-bottom-widget-container"
);

const LeftPane: React.FC<PaneProps> = ({ children, show }) => {
  return (
    <div
      className={show ? "dashboard-left-pane" : "dashboard-left-pane-hidden"}
    >
      {children}
    </div>
  );
};

const RightPane: React.FC<PaneProps> = ({ children, show }) => {
  return (
    <div
      className={show ? "dashboard-right-pane" : "dashboard-right-pane-hidden"}
    >
      {children}
    </div>
  );
};

const Dashboard: React.FC<Props> = ({ mode }) => {
  const [showRightPane, setShowRightPane] = useState(false);
  const title = mode === Mode.VOTING ? "Mapa de Votação" : "Mapa do Eleitorado";
  const page = mode === Mode.VOTING ? Pages.VOTING : Pages.ELECTORATE;
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1024px)" });

  return (
    <Page title={<TitleText>{title}</TitleText>} page={page}>
      <PageContainer>
        <LeftPane show={!showRightPane || !isSmallScreen}>
          <FilterContainer>
            <FilterWithData mode={mode} />
          </FilterContainer>
          <MapWithData mode={mode} />
        </LeftPane>
        <RightPane show={showRightPane || !isSmallScreen}>
          <TopWidgetContainer>
            <NumberWidgetWithData mode={mode} side={WidgetSide.LEFT} />
            <NumberWidgetWithData mode={mode} side={WidgetSide.RIGHT} />
          </TopWidgetContainer>
          <BottomWidgetContainer>
            <WidgetWithData mode={mode} />
          </BottomWidgetContainer>
        </RightPane>
        <ChangePaneButton
          isLeft={!showRightPane}
          onClick={() => setShowRightPane(!showRightPane)}
        />
      </PageContainer>
    </Page>
  );
};

export const VotingMap: React.FC = () => {
  return <Dashboard mode={Mode.VOTING} />;
};

export const ElectorateMap: React.FC = () => {
  return <Dashboard mode={Mode.ELECTORATE} />;
};

export const CandidateMap: React.FC = () => {
  return <Dashboard mode={Mode.ELECTORATE} />;
};
