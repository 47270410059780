import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BY_BURROW, BY_PLACE, REPORT, ShopPages } from "../../../app/constants";
import { AppDispatch } from "../../../app/store";
import ShopActionButton from "../../../components/buttons/shop-action-button/shop-action-button";
import ShopTextButton from "../../../components/buttons/shop-text-button/shop-text-button";
import ShopSelect from "../../../components/inputs/shop-select/shop-select";
import ShopHeading, {
  Variant,
} from "../../../components/text/shop-heading/shop-heading";
import { selectIsLogged } from "../../../features/auth/slice";
import {
  Candidate,
  CandidateElectionSummary,
} from "../../../features/candidates/type";
import ShopService from "../../../features/shop/service";
import {
  addCartItem,
  selectCandidateProfileData,
  selectDiscountCode,
  selectProducts,
  selectSelectedCandidature,
  setCurrentPage,
  setIsAuthenticating,
} from "../../../features/shop/slice";
import { Product } from "../../../features/shop/type";

import ShopPageContainer from "../components/shop-page-container/shop-page-container";
import ShopSpacer from "../components/shop-spacer/shop-spacer";

const reportTypes = [
  { label: "Detalhado Por Bairros", value: BY_BURROW },
  { label: "Detalhado Por Locais", value: BY_PLACE },
];

const ReportTypeSelect: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const [reportType, setReportType] = useState(reportTypes[0]);
  const isLogged = useSelector(selectIsLogged);
  const products: Product[] = useSelector(selectProducts);
  const candidate: Candidate = useSelector(selectCandidateProfileData);
  const candidature: CandidateElectionSummary = useSelector(
    selectSelectedCandidature
  );
  const selectedCode = useSelector(selectDiscountCode);

  const addToCart = async () => {
    const product = products.find(
      (prod) =>
        prod.product_type === REPORT && prod.report_type === reportType.value
    );

    dispatch(
      addCartItem({
        code: selectedCode,
        service: new ShopService(),
        cartItem: {
          product_id: product.id,
          candidate_id: candidate.id,
          year: candidature.electionyear,
          turn: `${candidature.turn}`,
        },
      })
    );
  };

  const keepShopping = async () => {
    await addToCart();
    dispatch(setCurrentPage(ShopPages.PRODUCT_SELECT));
  };

  const goToCheckout = async () => {
    await addToCart();
    dispatch(setCurrentPage(ShopPages.CART));
  };

  const login = () => {
    dispatch(
      setIsAuthenticating({
        isSigningIn: true,
        isSigningUp: false,
      })
    );
  };

  return (
    <ShopPageContainer>
      <ShopHeading variant={Variant.H1}>
        Siga nosso passo a passo para escolher o seu Relatório:
      </ShopHeading>
      <ShopSpacer space={25} />
      <ShopHeading variant={Variant.H2}>
        Agora selecione o Tipo de Relatório desejado:
      </ShopHeading>

      <ShopSelect
        label=""
        className=""
        value={reportType}
        options={reportTypes}
        placeHolder={"Selecione o nível de detalhe"}
        isLoading={false}
        onChange={(type) => setReportType(type)}
      />
      <ShopSpacer space={225} />

      {isLogged && (
        <>
          <ShopActionButton onClick={goToCheckout}>
            Prosseguir para o pagamento
          </ShopActionButton>
          <ShopActionButton outline={true} onClick={keepShopping}>
            Adicionar ao carrinho e continuar comprando
          </ShopActionButton>
        </>
      )}

      {!isLogged && (
        <ShopActionButton onClick={login}>
          Faça o login ou registre-se para continuar
        </ShopActionButton>
      )}

      <ShopSpacer space={25} />
      <ShopTextButton
        onClick={() => dispatch(setCurrentPage(ShopPages.REPORT_CANDIDATURE))}
      >
        {"Retornar ao anterior"}
      </ShopTextButton>
      <ShopTextButton
        onClick={() => dispatch(setCurrentPage(ShopPages.PRODUCT_SELECT))}
      >
        {"Cancelar"}
      </ShopTextButton>
    </ShopPageContainer>
  );
};

export default ReportTypeSelect;
