import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { makeContainer } from "../../../components/container/container";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  NotebookPages,
  NotebooksActionsOptions,
  NotebooksCausesOptions,
  NotebooksGenderOptions,
  NotebooksOfficesOptions,
  NOTEBOOK_PAGE,
  Status,
} from "../../../app/constants";
import {
  validateEmail,
  validateNumeric,
  validateText,
} from "../../../app/utils";
import NotebookButton from "../../../components/buttons/notebook-button/notebook-button";
import NotebookInputTextArea from "../../../components/inputs/notebook-input-text-area/notebook-input-text-area";
import NotebookInputText from "../../../components/inputs/notebook-input-text/notebook-input-text";
import NotebookSelectText from "../../../components/inputs/notebook-select-text/notebook-select-text";
import Loading from "../../../components/loading/loading";
import TitleText from "../../../components/text/title-text/title-text";
import { setAlert } from "../../../features/alert/slice";
import { AlertType } from "../../../features/alert/type";
import NotebookService from "../../../features/notebook/service";
import {
  createNotebook,
  getNotebookDetails,
  selectCurrentNotebook,
  selectGetCurrentNotebookStatus,
  updateNotebook,
} from "../../../features/notebook/slice";
import {
  CreateNotebookRequest,
  Notebook,
  UpdateNotebookRequest,
} from "../../../features/notebook/type";
import SaveQuestionaireModal from "./components/save-questionaire-modal";
import "./questionaire.css";

const Container = makeContainer("notebook-questionaire-container");
const FormItem = makeContainer("notebook-questionaire-form-item");
const FormItemLabel = makeContainer("notebook-questionaire-form-item-label");
const FormDescription = makeContainer("notebook-questionaire-form-description");
const FormFooter = makeContainer("notebook-questionaire-form-footer");

const textFields = [
  "candidate_name",
  "candidate_ballout_box_name",
  "age",
  "personal_email",
  "phone",
  "place",
  "birthplace",
  "schooling",
  "slogan",
  "personality",
  "moral_values",
  "projects_1",
  "projects_2",
  "projects_3",
  "message",
  "bio",
  "strenghts",
  "first_cause_proposal",
  "second_cause_proposal",
  "third_cause_proposal",
  "first_proposal_title",
  "first_proposal",
  "second_proposal_title",
  "second_proposal",
  "supporters",
  "whatsapp",
  "instagram",
  "facebook",
  "email",
  "youtube",
];

const optionFields = [
  "gender",
  "office",
  "first_cause",
  "second_cause",
  "third_cause",
  "action_point_1",
  "action_point_2",
];

function buildInitialFormState() {
  const state = {};
  textFields.forEach((f) => (state[f] = ""));
  optionFields.forEach((f) => (state[f] = null));
  return state;
}

function buildInitialValidityFormState() {
  const state = {};
  textFields.forEach((f) => (state[f] = null));
  optionFields.forEach((f) => (state[f] = true));
  return state;
}

const NotebookDefaultInput: React.FC<{
  name: string;
  placeHolder: string;
  state: any;
  valitidy: any;
  set: any;
  setValidity: any;
  validate: any;
  isArea?: boolean;
}> = (props) => {
  const {
    name,
    placeHolder,
    state,
    valitidy,
    set,
    setValidity,
    validate,
    isArea,
  } = props;

  if (isArea) {
    return (
      <NotebookInputTextArea
        inputKey={name}
        placeHolder={placeHolder}
        value={state[name]}
        invalid={valitidy[name] != null && !valitidy[name]}
        onChange={(e) => {
          set(name, e.target.value);
          setValidity(name, validate(e.target.value));
        }}
      />
    );
  } else {
    return (
      <NotebookInputText
        inputKey={name}
        placeHolder={placeHolder}
        value={state[name]}
        invalid={valitidy[name] != null && !valitidy[name]}
        onChange={(e) => {
          set(name, e.target.value);
          setValidity(name, validate(e.target.value));
        }}
      />
    );
  }
};

export const NotebookQuestionaire: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const currentNotebook: Notebook | null = useSelector(selectCurrentNotebook);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [formState, setFormState] = useState(buildInitialFormState());
  const [formValidityState, setFormValidityState] = useState(
    buildInitialValidityFormState()
  );
  const [isNew, setIsNew] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const status: Status = useSelector(selectGetCurrentNotebookStatus);

  useEffect(() => {
    if (!currentNotebook) {
      dispatch(
        getNotebookDetails({
          service: new NotebookService(),
        })
      );
    }
    //eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "Deseja realmente sair?";
      return "Deseja realmente sair?";
    };

    if (isChanged) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [isChanged]);

  useEffect(() => {
    if (currentNotebook) {
      setIsNew(false);
      loadData();
      setIsChanged(false);
    } else {
      setIsNew(true);
    }

    //eslint-disable-next-line
  }, [currentNotebook]);

  const set = (field, value) => {
    setIsChanged(true);
    setFormState((s) => ({
      ...s,
      [field]: value,
    }));
  };

  const setValidity = (field, value) => {
    setFormValidityState((s) => ({
      ...s,
      [field]: value,
    }));
  };

  const save = async () => {
    if (checkIsValid()) {
      const resquest = {};
      textFields.forEach((f) => (resquest[f] = formState[f]));
      optionFields.forEach((f) => (resquest[f] = formState[f].value));

      setShowSaveModal(true);

      const service = new NotebookService();
      const existingNotebook = await service.getNotebookDetails();

      if (!existingNotebook) {
        dispatch(
          createNotebook({
            service: service,
            request: resquest as CreateNotebookRequest,
          })
        );
        setIsChanged(false);
      } else {
        setShowSaveModal(false);
        dispatch(
          setAlert({
            message: `Já existe um questionário registrado com o email ${resquest["personal_email"]}`,
            type: AlertType.ERROR,
          })
        );
      }
    }
  };

  const update = async () => {
    if (checkIsValid()) {
      const resquest = {};
      textFields.forEach((f) => (resquest[f] = formState[f]));
      optionFields.forEach((f) => (resquest[f] = formState[f].value));

      setShowSaveModal(true);
      dispatch(
        updateNotebook({
          service: new NotebookService(),
          request: resquest as UpdateNotebookRequest,
        })
      );

      setIsChanged(false);
    }
  };

  const loadData = () => {
    const optionsFieldsMap = {
      gender: NotebooksGenderOptions,
      office: NotebooksOfficesOptions,
      first_cause: NotebooksCausesOptions,
      second_cause: NotebooksCausesOptions,
      third_cause: NotebooksCausesOptions,
      action_point_1: NotebooksActionsOptions,
      action_point_2: NotebooksActionsOptions,
    };

    textFields.forEach((f) => {
      set(f, currentNotebook[f]);
      setValidity(f, true);
    });

    Object.keys(optionsFieldsMap).forEach((f) => {
      const value = optionsFieldsMap[f].find(
        (o) => o?.value === currentNotebook[f] || o === currentNotebook[f]
      );
      set(
        f,
        typeof value === "string" ? { label: value, value: value } : value
      );
      setValidity(f, true);
    });
  };

  const preview = () => {
    navigate(`/${NOTEBOOK_PAGE}/${NotebookPages.NOTEBOOK_VIEW}`);
  };

  const checkIsValid = () => {
    const hasInvalid = Object.keys(formValidityState).some(
      (k) => !formValidityState[k]
    );

    console.log(formValidityState);
    const hasNull = optionFields.some((k) => formState[k] == null);
    return !hasInvalid && !hasNull;
  };

  return (
    <>
      {status === Status.LOADING && <Loading />}
      {status === Status.SUCCEEDED && (
        <Container>
          <TitleText className="notebook-questionaire-container-title">
            {"Questionário do Caderno de Campanha"}
          </TitleText>

          <FormDescription>
            Este questionário ajudará você a preencher seu caderno de campanha.
            Por favor, leia cada pergunta com atenção e responda conforme as
            orientações.
          </FormDescription>

          <FormItem>
            <FormItemLabel>Email</FormItemLabel>
            <NotebookDefaultInput
              name={"personal_email"}
              placeHolder={"Email Pessoal"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateEmail}
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>Nome</FormItemLabel>
            <NotebookDefaultInput
              name={"candidate_name"}
              placeHolder={"Nome"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>Nome de Urna</FormItemLabel>
            <NotebookDefaultInput
              name={"candidate_ballout_box_name"}
              placeHolder={"Nome Urna"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>Idade</FormItemLabel>
            <NotebookDefaultInput
              name={"age"}
              placeHolder={"Idade"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateNumeric}
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>Sexo</FormItemLabel>
            <NotebookSelectText
              inputKey="gender"
              label=""
              options={NotebooksGenderOptions}
              value={formState["gender"]}
              onChange={(e) => set("gender", e)}
              placeHolder="Sexo"
              className="notebook-questionaire-selector"
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>Cargo</FormItemLabel>
            <NotebookSelectText
              inputKey="office"
              label=""
              options={NotebooksOfficesOptions}
              value={formState["office"]}
              onChange={(e) => set("office", e)}
              placeHolder="Cargo"
              className="notebook-questionaire-selector"
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>Telefone</FormItemLabel>
            <NotebookDefaultInput
              name={"phone"}
              placeHolder={"Telefone"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>Município</FormItemLabel>
            <NotebookDefaultInput
              name={"place"}
              placeHolder={"Município"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
            />
          </FormItem>

          {/*  Dados Básicos*/}
          <TitleText className="notebook-questionaire-container-title">
            {"Dados Básicos"}
          </TitleText>
          <FormDescription>
            Nesta etapa, coletaremos as informações básicas da candidatura para
            compor o caderno.
          </FormDescription>

          <FormItem>
            <FormItemLabel>Local de Nascimento</FormItemLabel>
            <FormDescription>
              Descreva o local onde cresceu ou passou a infância. Pode ser o
              nome de um bairro, região, comunidade ou qualquer área com a qual
              você mais se identifica.
            </FormDescription>
            <NotebookDefaultInput
              name={"birthplace"}
              placeHolder={"Local de Nascimento"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>Onde estudou</FormItemLabel>
            <FormDescription>
              Qual a sua formação? Pode ser formal ou informal.
            </FormDescription>
            <NotebookDefaultInput
              name={"schooling"}
              placeHolder={"Onde estudou"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>Slogan</FormItemLabel>
            <FormDescription>
              Se você pudesse resumir sua candidatura em uma frase curta, qual
              seria?
            </FormDescription>
            <NotebookDefaultInput
              name={"slogan"}
              placeHolder={"Slogan da Campanha"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>
              Características pessoais mais marcantes que deseja passar para o
              eleitorado
            </FormItemLabel>
            <FormDescription>
              Descreva suas principais características pessoais que mostram seu
              diferencial ao se conectar com o eleitor. Fale sobre o que as
              pessoas mais gostam em você e o que você considera relevante para
              conquistar votos.
            </FormDescription>
            <NotebookDefaultInput
              name={"personality"}
              placeHolder={"Características pessoais"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
              isArea
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>
              Imagine que ao final da sua campanha, olhando para o passado, qual
              imagem você gostaria que sua equipe tivesse mostrado para o
              eleitor?
            </FormItemLabel>
            <FormDescription>
              Pense em referências positivas de outras campanhas e candidatos ou
              em alguma característica positiva da sua campanha, seja na relação
              com a equipe ou com o eleitorado.
            </FormDescription>
            <NotebookDefaultInput
              name={"moral_values"}
              placeHolder={"Valores morais"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
              isArea
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>Primeiro Projeto</FormItemLabel>
            <FormDescription>
              Cite um projeto que você desenvolve.
            </FormDescription>
            <NotebookDefaultInput
              name={"projects_1"}
              placeHolder={"Descrição do projeto"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
              isArea
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>Segundo Projeto</FormItemLabel>
            <FormDescription>
              Cite outro projeto que você desenvolve.
            </FormDescription>
            <NotebookDefaultInput
              name={"projects_2"}
              placeHolder={"Descrição do projeto"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
              isArea
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>Terceiro Projeto</FormItemLabel>
            <FormDescription>
              Cite mais um projeto que você desenvolve.
            </FormDescription>
            <NotebookDefaultInput
              name={"projects_3"}
              placeHolder={"Descrição do projeto"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
              isArea
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>Pontos relevantes da trajetória</FormItemLabel>
            <FormDescription>
              Descreva suas principais conquistas e resultados que mostram algo
              de valor, com o objetivo de cativar o eleitorado do município.
            </FormDescription>
            <NotebookDefaultInput
              name={"bio"}
              placeHolder={"Trajetória"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
              isArea
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>Mensagem</FormItemLabel>
            <FormDescription>
              Qual é a principal mensagem que você quer que sua candidatura
              passe?
            </FormDescription>
            <NotebookDefaultInput
              name={"message"}
              placeHolder={"Mensagem"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
              isArea
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>Diferencial</FormItemLabel>
            <FormDescription>
              Qual seu diferencial em relação a outros candidatos?
            </FormDescription>
            <NotebookDefaultInput
              name={"strenghts"}
              placeHolder={"Diferencial"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
              isArea
            />
          </FormItem>

          {/* Bandeiras*/}
          <TitleText className="notebook-questionaire-container-title">
            {"Elaboração das bandeiras"}
          </TitleText>
          <FormDescription>
            Etapa para identificar as bandeiras da candidatura, considerando o
            nível de conhecimento da candidata sobre cada tema e os assuntos de
            maior relevância para seu eleitorado.
          </FormDescription>

          <FormItem>
            <FormItemLabel>
              [PRIMEIRA BANDEIRA] Qual desses pontos considera como mais
              relevantes para sua campanha?
            </FormItemLabel>
            <FormDescription>
              Primeira bandeira. Tema que considera mais importantes ou como
              central para campanha.
            </FormDescription>
            <NotebookSelectText
              inputKey="first-cause"
              label=""
              options={NotebooksCausesOptions.map((c) => ({
                value: c,
                label: c,
              }))}
              value={formState["first_cause"]}
              onChange={(e) => set("first_cause", e)}
              placeHolder="Primeira Bandeira"
              className="notebook-questionaire-selector"
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>
              [PRIMEIRA BANDEIRA] Descreva uma proposta para a bandeira
            </FormItemLabel>
            <FormDescription>
              Escreva a principal proposta relacionada ao tema que definiu como
              mais importante ou central para sua campanha. Pense em como irá
              comunicar essa proposta ao eleitorado. Este é o momento de mostrar
              qual problema pretende resolver em relação ao seu tema de campanha
              ou como planeja agir durante seu mandato para trazer algo
              relevante para a sociedade.
            </FormDescription>
            <NotebookDefaultInput
              name={"first_cause_proposal"}
              placeHolder={"Primeira Bandeira"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
              isArea
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>
              [SEGUNDA BANDEIRA] Qual desses pontos considera como mais
              relevantes para sua campanha?
            </FormItemLabel>
            <FormDescription>
              Segunda bandeira. Tema que considera o segundo mais importante
              para campanha.
            </FormDescription>
            <NotebookSelectText
              inputKey="second-cause"
              label=""
              options={NotebooksCausesOptions.map((c) => ({
                value: c,
                label: c,
              }))}
              value={formState["second_cause"]}
              onChange={(e) => set("second_cause", e)}
              placeHolder="Segunda Bandeira"
              className="notebook-questionaire-selector"
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>
              [SEGUNDA BANDEIRA] Descreva uma proposta para a bandeira
            </FormItemLabel>
            <FormDescription>
              Escreva a principal proposta relacionada ao tema que definiu como
              mais importante ou central para sua campanha. Pense em como irá
              comunicar essa proposta ao eleitorado. Este é o momento de mostrar
              qual problema pretende resolver em relação ao seu tema de campanha
              ou como planeja agir durante seu mandato para trazer algo
              relevante para a sociedade.
            </FormDescription>
            <NotebookDefaultInput
              name={"second_cause_proposal"}
              placeHolder={"Segunda Bandeira"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
              isArea
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>
              [TERCEIRA BANDEIRA] Qual desses pontos considera como mais
              relevantes para sua campanha?
            </FormItemLabel>
            <FormDescription>
              Terceira bandeira. Tema que considera o terceiro mais importante
              para campanha. Neste ponto, se você não tem um tema específico em
              mente, reflita sobre algo que considere relevante para seu
              município e para o qual ainda não elaborou uma proposta específica
              ou não comunicou nada até o momento.
            </FormDescription>
            <NotebookSelectText
              inputKey="third-cause"
              label=""
              options={NotebooksCausesOptions.map((c) => ({
                value: c,
                label: c,
              }))}
              value={formState["third_cause"]}
              onChange={(e) => set("third_cause", e)}
              placeHolder="Terceira Bandeira"
              className="notebook-questionaire-selector"
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>
              [TERCEIRA BANDEIRA] Descreva uma proposta para a bandeira
            </FormItemLabel>
            <FormDescription>
              Escreva a principal proposta relacionada ao tema que definiu como
              mais importante ou central para sua campanha. Pense em como irá
              comunicar essa proposta ao eleitorado. Este é o momento de mostrar
              qual problema pretende resolver em relação ao seu tema de campanha
              ou como planeja agir durante seu mandato para trazer algo
              relevante para a sociedade.
            </FormDescription>
            <NotebookDefaultInput
              name={"third_cause_proposal"}
              placeHolder={"Terceira Bandeira"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
              isArea
            />
          </FormItem>

          <TitleText className="notebook-questionaire-container-title">
            {"Condução de Mandato"}
          </TitleText>
          <FormDescription>
            Identifique dois modos de ação, conforme as opções, que demonstrarão
            como será conduzido seu exercício parlamentar. Esses pontos serão
            fundamentais para refletir a forma de atuação política que você
            vislumbra.
          </FormDescription>

          <FormItem>
            <FormItemLabel>Principal proposta</FormItemLabel>
            <FormDescription>
              O que você mais gostaria de mudar no seu município?
            </FormDescription>
            <NotebookDefaultInput
              name={"first_proposal_title"}
              placeHolder={"Título da proposta"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
            />
          </FormItem>

          <FormItem>
            <FormDescription>
              O que você faria de diferente do que é feito hoje, para conseguir
              fazer essa mudança?
            </FormDescription>
            <NotebookDefaultInput
              name={"first_proposal"}
              placeHolder={"Descrição da proposta"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
              isArea
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>Principal melhoria</FormItemLabel>
            <FormDescription>
              O que você mais gostaria de melhorar no seu município?
            </FormDescription>
            <NotebookDefaultInput
              name={"second_proposal_title"}
              placeHolder={"Título da proposta"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
            />
          </FormItem>

          <FormItem>
            <FormDescription>
              E como você você faria essa melhoria se fosse eleito?
            </FormDescription>
            <NotebookDefaultInput
              name={"second_proposal"}
              placeHolder={"Descrição da proposta"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
              isArea
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>Primeira Condução de Mandato</FormItemLabel>
            <FormDescription>
              Dentre essas opções, quais que você considera mais importante para
              seu mandato?
            </FormDescription>
            <NotebookSelectText
              inputKey="action_point_1"
              label=""
              options={NotebooksActionsOptions.map((c) => ({
                value: c,
                label: c,
              }))}
              value={formState["action_point_1"]}
              onChange={(e) => set("action_point_1", e)}
              placeHolder="Primeiro Ponto"
              className="notebook-questionaire-selector"
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>Segunda Condução de Mandato</FormItemLabel>
            <FormDescription>
              E qual é a segunda mais importante?
            </FormDescription>
            <NotebookSelectText
              inputKey="action_point_2"
              label=""
              options={NotebooksActionsOptions.map((c) => ({
                value: c,
                label: c,
              }))}
              value={formState["action_point_2"]}
              onChange={(e) => set("action_point_2", e)}
              placeHolder="Segundo Ponto"
              className="notebook-questionaire-selector"
            />
          </FormItem>

          <TitleText className="notebook-questionaire-container-title">
            {"Apoiadores"}
          </TitleText>
          <FormItem>
            <FormItemLabel>
              Conta pra gente, quem já está te apoiando no seu município?
            </FormItemLabel>
            <NotebookDefaultInput
              name={"supporters"}
              placeHolder={"Apoiadores"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
            />
          </FormItem>

          <TitleText className="notebook-questionaire-container-title">
            {"Redes Sociais"}
          </TitleText>
          <FormDescription>
            Escreva aqui as redes sociais da sua candidatura, para dizer ao
            eleitores como conhecer você e seu trabalho.
          </FormDescription>

          <FormItem>
            <FormItemLabel>WhatsApp de campanha</FormItemLabel>
            <NotebookDefaultInput
              name={"whatsapp"}
              placeHolder={"Whatsapp"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>Instagram</FormItemLabel>
            <NotebookDefaultInput
              name={"instagram"}
              placeHolder={"Instagram"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>Facebook</FormItemLabel>
            <NotebookDefaultInput
              name={"facebook"}
              placeHolder={"Facebook"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>E-mail de campanha</FormItemLabel>
            <NotebookDefaultInput
              name={"email"}
              placeHolder={"Email"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
            />
          </FormItem>

          <FormItem>
            <FormItemLabel>Twitter/X</FormItemLabel>
            <NotebookDefaultInput
              name={"youtube"}
              placeHolder={"Twitter/X"}
              set={set}
              setValidity={setValidity}
              state={formState}
              valitidy={formValidityState}
              validate={validateText}
            />
          </FormItem>

          <FormFooter>
            <NotebookButton
              selected
              className={"notebook-questionaire-form-footer-button"}
              onClick={isNew ? save : update}
              disabled={!checkIsValid()}
            >
              {isNew ? "Salvar" : "Atualizar"}
            </NotebookButton>
            {!isNew && (
              <NotebookButton
                className={"notebook-questionaire-form-footer-button"}
                onClick={preview}
              >
                {"Visualizar"}
              </NotebookButton>
            )}
          </FormFooter>

          <SaveQuestionaireModal
            show={showSaveModal}
            isNew={isNew}
            onClose={() => setShowSaveModal(false)}
          />
        </Container>
      )}
    </>
  );
};
