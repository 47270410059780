import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Status } from "../../app/constants";
import { debounceThunk } from "../../app/utils";
import { Constants, IConstantsService } from "./type";

const constantsData: Constants | null = null;

// Slice's initial state
const initialState = {
  status: Status.IDLE,
  constants: constantsData,
  error: "",
};

type GetConstantsParams = { service: IConstantsService };

// Login's thunk
const getConstantsThunk = createAsyncThunk<Constants, GetConstantsParams>(
  "constants/get",
  async (params) => {
    const { service } = params;
    const response = await service.getConstants();
    return response;
  }
);

// Selectors
export const selectConstants = (state) => state.constants.constants;
export const selectConstantsStatus = (state) => state.constants.status;

// Reducers
const loading = (state, action) => {
  state.status = Status.LOADING;
};

const successfull = (state, action) => {
  state.status = Status.SUCCEEDED;
  state.constants = action.payload;
  state.error = "";
};

const rejected = (state, action) => {
  state.constants = null;
  state.status = Status.FAILED;
  state.error = action.error.message;
};

// Slice
const constantsSlice = createSlice({
  name: "constants",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getConstantsThunk.pending, loading)
      .addCase(getConstantsThunk.fulfilled, successfull)
      .addCase(getConstantsThunk.rejected, rejected);
  },
});

export const getConstants = debounceThunk(getConstantsThunk);
export default constantsSlice.reducer;
