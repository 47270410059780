import { memo } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Status } from "../../../../../app/constants";
import { toPtBr, toPtBrContracted } from "../../../../../app/utils";
import CustomResponsiveContainer from "../../../../../components/charts/CustomResponsiveContainer";
import Loading from "../../../../../components/loading/loading";
import NoData from "../../../../../components/no-data/no-data";
import { PartyProfileData } from "../../../../../features/parties/type";
import "./party-voting-chart.css";

type Props = {
  summary?: PartyProfileData;
  status?: Status;
};

const format = (summary: PartyProfileData) => {
  let votes = summary?.votes?.slice();
  if (!votes) return [];

  votes?.sort((v1, v2) => {
    return v1.year !== v2.year ? v1.year - v2.year : v1.turn - v2.turn;
  });

  const yearGroups = {};
  for (const v of votes) {
    if (!(v.year in yearGroups)) {
      yearGroups[v.year] = {
        Ano: v.year,
        "1º Turno": 0,
        "2º Turno": 0,
      };
    }
    if (v.turn === 1) {
      yearGroups[v.year]["1º Turno"] = v.votes;
    } else {
      yearGroups[v.year]["2º Turno"] = v.votes;
    }
  }

  const data = Object.keys(yearGroups).map((key) => yearGroups[key]);

  data.sort((v1, v2) => {
    return v1.Ano - v2.Ano;
  });

  return data;
};

const formatTicks = (tickItem) => {
  return toPtBrContracted(tickItem);
};

const CustomizedTooltip = ({ payload, label, active }) => {
  if (active) {
    return (
      <div className="barchart-custom-tooltip">
        <div>{`${label}`}</div>
        <div>{`1º Turno: ${toPtBr(payload[1].value)} voto(s)`}</div>
        <div>{`2º Turno: ${toPtBr(payload[0].value)} voto(s)`}</div>
      </div>
    );
  }

  return null;
};

const PartyVotingChart: React.FC<Props> = ({ status, summary }) => {
  const data = format(summary);

  if (status === Status.LOADING) {
    return <Loading />;
  }

  if (!summary?.votes || !summary?.votes.length) {
    return <NoData />;
  }

  return (
    <CustomResponsiveContainer>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 25,
            right: 0,
            left: 15,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip content={CustomizedTooltip} />
          <XAxis dataKey="Ano" />
          <YAxis
            yAxisId="left"
            orientation="left"
            tickFormatter={formatTicks}
          />
          <Tooltip />
          <Legend />
          <Bar
            yAxisId="left"
            dataKey="2º Turno"
            fill="#E76E6E"
            stackId="year"
          />
          <Bar
            yAxisId="left"
            dataKey="1º Turno"
            fill="#2DA5C0"
            stackId="year"
            radius={[6, 6, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </CustomResponsiveContainer>
  );
};

export default memo(PartyVotingChart);
