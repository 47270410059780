import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Pages, Status } from "../../../../app/constants";
import { AppDispatch } from "../../../../app/store";
import LoginCtaButton from "../../../../components/buttons/login-cta-button/login-cta-button";
import LoginTextButton from "../../../../components/buttons/login-text-button/login-text-button";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "../../../../components/buttons/social-login-button/social-login-button";
import { makeContainer } from "../../../../components/container/container";
import LoginCheckboxText from "../../../../components/inputs/login-checkbox/login-checkbox";
import LoginInputText from "../../../../components/inputs/login-input-text/login-input-text";
import LoginFormLabelText from "../../../../components/text/login-form-label-text/login-form-label-text";
import LoginSubtitleText from "../../../../components/text/login-subtitle-text/login-subtitle-text";
import LoginTitleText from "../../../../components/text/login-title-text/login-title-text";
import AuthService from "../../../../features/auth/service";
import {
  cleanUp,
  facebookLogin,
  googleLogin,
  login,
  selectAuthStatus,
} from "../../../../features/auth/slice";
import LoginLogo from "../../../../resources/images/login-logo.png";
import "./login-form.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
};

const Form = makeContainer("login-form");
const KeepConnectedAndForgotPassword = makeContainer("login-keep-and-forgot");
const SocialLogin = makeContainer("login-social-login");
const Signup = makeContainer("login-signup");

const Logo: React.FC = memo(() => {
  return <img alt="logo" className="login-logo" src={LoginLogo} />;
});

const LoginForm: React.FC<Props> = () => {
  const dispatch: AppDispatch = useDispatch();
  const [loginInput, setLoginInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [rememberMe, setRememberMe] = useState(true);

  const navigate = useNavigate();
  const loginStatus: Status = useSelector(selectAuthStatus);

  const loginUser = () => {
    if (loginStatus !== Status.LOADING) {
      const loginParams = {
        credentials: {
          username: loginInput,
          password: passwordInput,
          rememberMe: rememberMe,
        },
        service: new AuthService(),
      };

      dispatch(login(loginParams));
    }
  };

  const loginUserWithFacebook = () => {
    if (loginStatus !== Status.LOADING) {
      const loginParams = {
        rememberMe: rememberMe,
        service: new AuthService(),
      };

      dispatch(facebookLogin(loginParams));
    }
  };

  const loginUserWithGoogle = () => {
    if (loginStatus !== Status.LOADING) {
      const loginParams = {
        rememberMe: rememberMe,
        service: new AuthService(),
      };

      dispatch(googleLogin(loginParams));
    }
  };

  function handleClick(link: string) {
    return () => navigate(`/${link}`);
  }

  useEffect(() => {
    return () => {
      dispatch(cleanUp(null));
    };
  }, [dispatch]);

  return (
    <Form>
      <Logo />
      <LoginTitleText className="login-title">
        Acesse a sua conta
      </LoginTitleText>
      <LoginSubtitleText className="login-subtitle">
        Digite o e-mail e senha de cadastro
      </LoginSubtitleText>
      <LoginFormLabelText className="login-label">E-mail</LoginFormLabelText>
      <LoginInputText
        className="login-input"
        placeHolder="Seu email cadastrado"
        value={loginInput}
        onChange={(e) => setLoginInput(e.target.value)}
      />
      <LoginFormLabelText className="login-label">Senha</LoginFormLabelText>
      <LoginInputText
        className="login-input-password"
        type="password"
        placeHolder="Sua senha"
        value={passwordInput}
        onEnterPressed={loginUser}
        onChange={(e) => setPasswordInput(e.target.value)}
      />
      <KeepConnectedAndForgotPassword>
        <LoginCheckboxText
          checked={rememberMe}
          onChange={(e) => setRememberMe(e.target.checked)}
        >
          Lembrar-me
        </LoginCheckboxText>
        <LoginTextButton onClick={handleClick(Pages.FORGOT)}>
          Esqueceu a senha?
        </LoginTextButton>
      </KeepConnectedAndForgotPassword>
      <LoginCtaButton onClick={loginUser} status={loginStatus}>
        Entrar
      </LoginCtaButton>
      <SocialLogin>
        <GoogleLoginButton onClick={loginUserWithGoogle}>
          Entrar com Google
        </GoogleLoginButton>
        <FacebookLoginButton onClick={loginUserWithFacebook}>
          Entrar com Facebook
        </FacebookLoginButton>
      </SocialLogin>
      <Signup>
        <span className="login-signup-text">Não possui conta? </span>
        <LoginTextButton onClick={handleClick(Pages.SIGNUP)}>
          Cadastre-se
        </LoginTextButton>
      </Signup>
    </Form>
  );
};

export default memo(LoginForm);
