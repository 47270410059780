import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Status } from "../../../../../app/constants";
import { AppDispatch } from "../../../../../app/store";
import { goToTarget } from "../../../../../app/target";
import { canCreateNewTarget } from "../../../../../app/utils";
import DeleteTargetButton from "../../../../../components/buttons/delete-target-button/delete-target-button";
import { makeContainer } from "../../../../../components/container/container";
import Loading from "../../../../../components/loading/loading";
import { setFilters } from "../../../../../features/dashboard/slice";
import TargetService from "../../../../../features/target/service";
import {
  deleteTarget,
  getTargets,
  selectDeleteTargetStatus,
  selectTargetsList,
  selectTargetsStatus,
  setCurrentPage,
} from "../../../../../features/target/slice";
import { TargetRootResponse } from "../../../../../features/target/type";
import TargetImage1 from "../../../../../resources/images/target/target-icon-1.jpg";
import TargetImage2 from "../../../../../resources/images/target/target-icon-2.jpg";
import TargetImage3 from "../../../../../resources/images/target/target-icon-3.jpg";
import TargetImage4 from "../../../../../resources/images/target/target-icon-4.jpg";
import DisabledNewTarget from "../../../../../resources/images/target/target-plus-disabled.jpg";
import NewTarget from "../../../../../resources/images/target/target-plus.jpg";
import "./targets-list.css";

type TargetShortcutProps = {
  children?: string;
  image?: string;
  isDeleting?: boolean;
  onClick?: () => void;
  onDelete?: () => void;
};

const targetImages = [TargetImage1, TargetImage2, TargetImage3, TargetImage4];
const TargetListContainer = makeContainer("target-list-container");

const TargetShortcut: React.FC<TargetShortcutProps> = (props) => {
  const { children, image, isDeleting, onClick, onDelete } = props;

  const onDeleteClicked = (e) => {
    if (!isDeleting) {
      e.stopPropagation();
      onDelete();
    }
  };

  return (
    <div className="target-list-shortcut" onClick={onClick}>
      <div className="target-list-shortcut-image-container">
        <img
          alt="logo da meta"
          className="target-list-shortcut-image"
          src={image}
        />
      </div>
      {onDelete && <DeleteTargetButton onClick={onDeleteClicked} />}
      <div className="target-list-shortcut-text">
        {!isDeleting ? children : "removendo..."}
      </div>
    </div>
  );
};

const TargetsList: React.FC<{ createNewTarget: () => void }> = ({
  createNewTarget,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const targets: TargetRootResponse[] = useSelector(selectTargetsList);
  const status: Status = useSelector(selectTargetsStatus);
  const deleteStatus: Status = useSelector(selectDeleteTargetStatus);
  const creationEnabled = canCreateNewTarget(targets);
  const [targetBeingDeleted, setTargetBeingDeleted] = useState("");

  const openTarget = (target) => () => {
    goToTarget(target, dispatch, setFilters, setCurrentPage);
  };

  const removeTarget = (targetId: string) => () => {
    dispatch(
      deleteTarget({
        targetId: targetId,
        service: new TargetService(),
      })
    );

    setTargetBeingDeleted(targetId);
  };

  useEffect(() => {
    if (deleteStatus === Status.SUCCEEDED) {
      dispatch(
        getTargets({
          service: new TargetService(),
        })
      );
    }
  }, [dispatch, deleteStatus]);

  return (
    <TargetListContainer>
      {status === Status.LOADING && <Loading />}
      {status === Status.SUCCEEDED && (
        <>
          {targets.map((target, index) => (
            <TargetShortcut
              key={`saved-target-${index}`}
              image={targetImages[index]}
              onClick={openTarget(target)}
              onDelete={removeTarget(target.id)}
              isDeleting={targetBeingDeleted === target.id}
            >
              {target.name ? target.name : "[Sem nome]"}
            </TargetShortcut>
          ))}
          <TargetShortcut
            image={creationEnabled ? NewTarget : DisabledNewTarget}
            onClick={createNewTarget}
          >
            {"⠀"}
          </TargetShortcut>
        </>
      )}
    </TargetListContainer>
  );
};

export default memo(TargetsList);
