import React, { memo, useEffect, useState } from "react";
import { SCENARIO_A, SCENARIO_B, Status } from "../../../app/constants";
import {
  ActiveFilter,
  createName,
  FilterNameValue,
  FilterValue,
} from "../../../app/filter";
import { sortByLabel } from "../../../app/utils";
import AddFilterButton from "../../buttons/add-filter-button/add-filter-button";
import { makeContainer } from "../../container/container";
import CandidateSelect from "../../inputs/candidate-select/candidate-select";
import DashSelect from "../../inputs/dash-select/dash-select";
import ScrollContainer from "../../scroll/scroll";
import FilterChip from "./filter-chip";
import "./filter.css";

type FilterProps = {
  filterNames: FilterNameValue[];
  filterValues: FilterValue[];
  activeFilters: ActiveFilter[];
  comparativeActiveFilters?: ActiveFilter[];
  comparativeMode?: boolean;
  status?: Status;
  candidateMode?: boolean;
  onFilterNameChanged: (filterName: any) => void;
  onFilterAdded: (filter: ActiveFilter, scenario: FilterNameValue) => void;
  onFilterRemoved: (filter: ActiveFilter, scenario: FilterNameValue) => void;
};

const FilterContainer = makeContainer("filter-container");
const FilterTopContainer = makeContainer("filter-top-container");
const FilterBottomContainer = makeContainer("filter-bottom-container");
const FilterList = makeContainer("filter-list");

const Filter: React.FC<FilterProps> = ({
  filterNames,
  filterValues,
  activeFilters,
  comparativeActiveFilters,
  comparativeMode,
  status,
  candidateMode,
  onFilterNameChanged,
  onFilterAdded,
  onFilterRemoved,
}) => {
  const [filterName, setFilterName] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [scenarioValue, setScenarioValue] = useState(null);

  const scenarios = [
    createName("Cenário A", SCENARIO_A),
    createName("Cenário B", SCENARIO_B),
  ];

  const addFilter = () => {
    if (filterName && filterValue) {
      const newFiter = {
        name: filterName,
        value: filterValue,
      };

      setFilterValue(null);
      onFilterAdded(newFiter, scenarioValue);
    }
  };

  const removeFilter =
    (activeFilter: ActiveFilter, scenario: FilterNameValue) => () => {
      onFilterRemoved(activeFilter, scenario);
    };

  useEffect(() => {
    setFilterValue(null);
  }, [filterValues]);

  useEffect(() => {
    setFilterName(null);
  }, [filterNames]);

  return (
    <FilterContainer>
      <FilterTopContainer>
        <DashSelect
          label="Filtro"
          className="filter-select"
          placeHolder="Selecione um filtro"
          options={sortByLabel(filterNames)}
          value={filterName}
          onChange={(selected) => {
            setFilterName(selected);
            onFilterNameChanged(selected);
          }}
          isLoading={status === Status.LOADING}
        />
        {!candidateMode && (
          <DashSelect
            label="Valor"
            className="filter-data-select"
            placeHolder="Selecione um valor"
            options={sortByLabel(filterValues)}
            value={filterValue}
            onChange={(selected) => setFilterValue(selected)}
            isLoading={status === Status.LOADING}
          />
        )}
        {candidateMode && (
          <CandidateSelect
            label="Candidato"
            className="filter-data-select"
            placeHolder="Selecione um valor"
            options={sortByLabel(filterValues)}
            value={filterValue}
            onChange={(selected) => setFilterValue(selected)}
            isLoading={status === Status.LOADING}
          />
        )}
        {comparativeMode && (
          <DashSelect
            label="Cenário"
            className="filter-data-select"
            placeHolder="Selecione um cenário"
            options={scenarios}
            value={scenarioValue}
            onChange={(selected) => setScenarioValue(selected)}
            isLoading={status === Status.LOADING}
          />
        )}
        <AddFilterButton onClick={addFilter} />
      </FilterTopContainer>
      <FilterBottomContainer>
        <ScrollContainer>
          <FilterList>
            <>
              {comparativeMode && (
                <FilterChip variant="disabled">{"Cenário A"}</FilterChip>
              )}
              {activeFilters?.map((filter, index) => (
                <FilterChip
                  variant={comparativeMode ? "scenario-a" : "normal"}
                  key={`filter-${index}}`}
                  onClick={removeFilter(filter, scenarios[0])}
                >{`${filter.name.label}: ${filter.value.label}`}</FilterChip>
              ))}
            </>
          </FilterList>
          {comparativeMode && (
            <FilterList>
              <>
                <FilterChip variant="disabled">{"Cenário B"}</FilterChip>
                {comparativeActiveFilters?.map((filter, index) => (
                  <FilterChip
                    variant="scenario-b"
                    key={`filter-comp-${index}}`}
                    onClick={removeFilter(filter, scenarios[1])}
                  >{`${filter.name.label}: ${filter.value.label}`}</FilterChip>
                ))}
              </>
            </FilterList>
          )}
        </ScrollContainer>
      </FilterBottomContainer>
    </FilterContainer>
  );
};

export default memo(Filter);
