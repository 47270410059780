import { memo, MouseEventHandler, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Pages, TargetPages } from "../../../app/constants";
import { setStartupState } from "../../../features/dashboard/slice";
import MenuData from "../../../features/menu/type";
import { setCurrentPage } from "../../../features/target/slice";
import CandidateMapSelected from "../../../resources/icons/menu/candidate-map-selected.svg";
import CandidateMap from "../../../resources/icons/menu/candidate-map.svg";
import CandidateSelected from "../../../resources/icons/menu/candidates-selected.svg";
import Candidates from "../../../resources/icons/menu/candidates.svg";
import ElectionsSelected from "../../../resources/icons/menu/elections-selected.svg";
import Elections from "../../../resources/icons/menu/elections.svg";
import ElectorateSelected from "../../../resources/icons/menu/electorate-selected.svg";
import Electorate from "../../../resources/icons/menu/electorate.svg";
import HomeIconSelected from "../../../resources/icons/menu/home-selected.svg";
import HomeIcon from "../../../resources/icons/menu/home.svg";
import PartiesSelected from "../../../resources/icons/menu/parties-selected.svg";
import Parties from "../../../resources/icons/menu/parties.svg";
import SettingsSelected from "../../../resources/icons/menu/settings-selected.svg";
import Settings from "../../../resources/icons/menu/settings.svg";
import SignoutSelected from "../../../resources/icons/menu/signout-selected.svg";
import Signout from "../../../resources/icons/menu/signout.svg";
import UserDataSelected from "../../../resources/icons/menu/user-data-selected.svg";
import UserData from "../../../resources/icons/menu/user-data.svg";
import VotingSelected from "../../../resources/icons/menu/voting-selected.svg";
import Voting from "../../../resources/icons/menu/voting.svg";
import DataElegeLogoLg from "../../../resources/images/dataelege-logo-lg.png";
import DataElegeLogoMd from "../../../resources/images/dataelege-logo-md.png";
import DataElegeTextLogo from "../../../resources/images/dataelege-logo-text.png";
import TargetButton from "../../buttons/target-button/target-button";
import MenuItemGroupText from "../../text/menu-item-group-text/menu-item-group-text";
import MenuItemText from "../../text/menu-item-text/menu-item-text";
import LogoutModal from "../logout-modal/logout-modal";
import "./menu.css";

type MenuItemProps = {
  children: string;
  icon: string;
  selectedIcon: string;
  expanded: boolean;
  isSelected?: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
};

type MenuItemGroupProps = {
  children: string | JSX.Element | JSX.Element[];
  title: string;
  expanded: boolean;
};

type SideBarProps = {
  menuData: MenuData;
};

const MenuItem: React.FC<MenuItemProps> = memo(
  ({ children, icon, selectedIcon, expanded, isSelected, onClick }) => {
    return (
      <div
        className={expanded ? "menu-entry" : "menu-entry contracted-menu-entry"}
        onClick={onClick}
      >
        <div className="menu-entry-container">
          <img
            alt="icon"
            className="menu-entry-img"
            src={isSelected ? selectedIcon : icon}
          />
        </div>
        {expanded && (
          <MenuItemText isSelected={isSelected}>{children}</MenuItemText>
        )}
      </div>
    );
  }
);

const MenuItemGroup: React.FC<MenuItemGroupProps> = memo(
  ({ children, title, expanded }) => {
    return (
      <div className="menu-entry-group">
        {expanded && <MenuItemGroupText>{title}</MenuItemGroupText>}
        {!expanded && <hr className="menu-separator" />}
        {children}
      </div>
    );
  }
);

const Menu: React.FC<SideBarProps> = ({ menuData }) => {
  const expanded = menuData.isMenuExpanded;
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleClick(link: string) {
    return () => {
      dispatch(setStartupState(null));
      navigate(`/${link}`);
    };
  }

  function handleTargetClick() {
    dispatch(setCurrentPage(TargetPages.HOME));
    handleClick(Pages.TARGET)();
  }

  function handleLogoutClick() {
    setShowLogoutModal(true);
  }

  return (
    <div className={expanded ? "menu" : "contracted-menu"}>
      <div className="menu-items">
        <a
          href="https://www.dataelege.com.br"
          target="_blank"
          rel="noopener noreferrer"
        >
          <picture>
            <source
              srcSet={expanded ? DataElegeTextLogo : DataElegeLogoLg}
              media="(min-width: 1386px)"
            />
            <img
              alt="dataelege-logo"
              className={
                expanded ? "dataelege-logo" : "contracted-dataelege-logo"
              }
              src={expanded ? DataElegeTextLogo : DataElegeLogoMd}
            />
          </picture>
        </a>
        <MenuItemGroup title="Principal" expanded={expanded}>
          <MenuItem
            icon={HomeIcon}
            selectedIcon={HomeIconSelected}
            onClick={handleClick(Pages.HOME)}
            isSelected={menuData.currentPage === Pages.HOME}
            expanded={expanded}
          >
            {"Home"}
          </MenuItem>
          <MenuItem
            icon={Voting}
            selectedIcon={VotingSelected}
            onClick={handleClick(Pages.VOTING)}
            isSelected={menuData.currentPage === Pages.VOTING}
            expanded={expanded}
          >
            {"Mapa de Votação"}
          </MenuItem>
          <MenuItem
            icon={Electorate}
            onClick={handleClick(Pages.ELECTORATE)}
            selectedIcon={ElectorateSelected}
            isSelected={menuData.currentPage === Pages.ELECTORATE}
            expanded={expanded}
          >
            {"Mapa do Eleitorado"}
          </MenuItem>
          <MenuItem
            icon={CandidateMap}
            onClick={handleClick(Pages.CANDIDATE_VOTING)}
            isSelected={menuData.currentPage === Pages.CANDIDATE_VOTING}
            selectedIcon={CandidateMapSelected}
            expanded={expanded}
          >
            {"Mapa do Candidato"}
          </MenuItem>
        </MenuItemGroup>

        <MenuItemGroup title="Consultas" expanded={expanded}>
          <MenuItem
            icon={Elections}
            onClick={handleClick(Pages.ELECTIONS)}
            isSelected={menuData.currentPage === Pages.ELECTIONS}
            selectedIcon={ElectionsSelected}
            expanded={expanded}
          >
            {"Eleições"}
          </MenuItem>
          <MenuItem
            icon={Parties}
            onClick={handleClick(Pages.SEARCH_PARTY)}
            isSelected={
              menuData.currentPage === Pages.SEARCH_PARTY ||
              menuData.currentPage === Pages.PARTY_PROFILE
            }
            selectedIcon={PartiesSelected}
            expanded={expanded}
          >
            {"Partidos"}
          </MenuItem>
          <MenuItem
            icon={Candidates}
            onClick={handleClick(Pages.SEARCH_CANDIDATE)}
            isSelected={
              menuData.currentPage === Pages.SEARCH_CANDIDATE ||
              menuData.currentPage === Pages.CANDIDATE_PROFILE
            }
            selectedIcon={CandidateSelected}
            expanded={expanded}
          >
            {"Candidatos"}
          </MenuItem>
        </MenuItemGroup>

        <MenuItemGroup title="Pessoal" expanded={expanded}>
          <MenuItem
            icon={UserData}
            onClick={handleClick(Pages.PROFILE)}
            selectedIcon={UserDataSelected}
            isSelected={menuData.currentPage === Pages.PROFILE}
            expanded={expanded}
          >
            {"Meus Dados"}
          </MenuItem>
          <MenuItem
            icon={Settings}
            onClick={handleClick(Pages.SETTINGS)}
            isSelected={menuData.currentPage === Pages.SETTINGS}
            selectedIcon={SettingsSelected}
            expanded={expanded}
          >
            {"Configurações"}
          </MenuItem>
          <MenuItem
            onClick={handleLogoutClick}
            icon={Signout}
            selectedIcon={SignoutSelected}
            expanded={expanded}
          >
            {"Logout"}
          </MenuItem>
        </MenuItemGroup>
      </div>
      <TargetButton onClick={handleTargetClick} expanded={expanded} />
      <LogoutModal
        show={showLogoutModal}
        onClose={() => setShowLogoutModal(false)}
      />
    </div>
  );
};

export default memo(Menu);
