import { loadIndexedDB, saveIndexedDB } from "../../app/cache";
import HttpClient from "../../app/client";
import handleError from "../../app/errors";
import {
  CreateNotebookRequest,
  INotebookService,
  Notebook,
  UpdateImageRequest,
  UpdateNotebookRequest,
} from "./type";

export default class NotebookService implements INotebookService {
  async getNotebookDetails(): Promise<Notebook> {
    try {
      const client = new HttpClient("notebooks", false, [404]);
      const notebook = await client.get<Notebook>("/campaigns/notebooks");
      if (notebook) {
        notebook.images = await loadIndexedDB("notebook-images");
        notebook.party = await loadIndexedDB("notebook-party");
      }
      return notebook;
    } catch (error) {
      handleError("Error while getting notebook details", error);
    }
  }

  async createNotebook(request: CreateNotebookRequest): Promise<Notebook> {
    try {
      const client = new HttpClient("notebooks", false, []);
      return await client.post<Notebook>(`/campaigns/notebooks`, request);
    } catch (error) {
      handleError("Error while creating notebook", error);
    }
  }

  async updateNotebook(request: UpdateNotebookRequest): Promise<Notebook> {
    try {
      const client = new HttpClient("notebooks", false, []);

      if (request.party) {
        await saveIndexedDB("notebook-party", request.party);
      }

      return await client.patch<Notebook>(`/campaigns/notebooks`, request);
    } catch (error) {
      handleError("Error while updating notebook", error);
    }
  }

  async updateImage(
    notebook: Notebook,
    request: UpdateImageRequest
  ): Promise<Notebook> {
    try {
      let storedImages = await loadIndexedDB("notebook-images");

      if (!storedImages) {
        storedImages = {};
      }

      storedImages[request.number] = request.image;
      notebook.images = storedImages;
      await saveIndexedDB("notebook-images", storedImages);
      return notebook;
    } catch (error) {
      handleError("Error while updating notebook image", error);
    }
  }
}
