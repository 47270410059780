import Attribute, { AttributeTypes } from "../../app/attribute";
import HttpClient from "../../app/client";
import {
  DashboardFilterConsts,
  STATE_TARGET,
  TargetFilterConsts,
  TARGET_MAP_ATTRIBUTE,
  TARGET_PRIMARY_TOTAL_ATTRIBUTE,
  TARGET_TOTAL_ATTRIBUTE,
} from "../../app/constants";
import handleError from "../../app/errors";
import { ActiveFilter, get } from "../../app/filter";
import store from "../../app/store";
import { addCityPrioritiesList, addRootPriorities } from "../../app/target";
import { IDashboardService, QueryResult } from "../dashboard/types";
import { setTargetCityManually, setTargetRootManually } from "./slice";

import {
  CreateTargetRequest,
  GetStatsRequest,
  GlobalStats,
  ITargetService,
  SaveTargetRequest,
  TargetCityResponse,
  TargetRootResponse,
  UpdateTargetCityRequest,
  UpdateTargetRootRequest,
} from "./type";

export default class TargetService
  implements ITargetService, IDashboardService
{
  async getTargets(): Promise<TargetRootResponse[]> {
    try {
      const client = new HttpClient("target", false, []);
      return await client.get<TargetRootResponse[]>(`/targets`);
    } catch (error) {
      handleError("Error while getting targets", error);
    }
  }

  async getTargetDetails(id: string): Promise<TargetRootResponse> {
    try {
      const client = new HttpClient("target", false, []);
      return await client.get<TargetRootResponse>(`/targets/${id}`);
    } catch (error) {
      handleError("Error while getting targets", error);
    }
  }

  async getTargetCityDetails(
    id: string,
    city_id: string
  ): Promise<TargetCityResponse> {
    try {
      const client = new HttpClient("target", false, []);
      return await client.get<TargetCityResponse>(
        `/targets/${id}/cities/${city_id}`
      );
    } catch (error) {
      handleError("Error while getting city targets", error);
    }
  }

  async createTarget(
    request: CreateTargetRequest
  ): Promise<TargetRootResponse> {
    try {
      let newRequest = JSON.parse(JSON.stringify(request));
      newRequest.manual_category_specification = true;
      addRootPriorities(newRequest);
      addCityPrioritiesList(newRequest);

      const client = new HttpClient("target", false, []);
      return await client.post<TargetCityResponse>(`/targets`, newRequest);
    } catch (error) {
      handleError("Error while creating a target", error);
    }
  }

  async updateTarget(
    request: UpdateTargetRootRequest,
    id: string
  ): Promise<TargetRootResponse> {
    try {
      const client = new HttpClient("target", false, []);
      return await client.put<TargetCityResponse>(`/targets/${id}`, request);
    } catch (error) {
      handleError("Error while updating a target", error);
    }
  }

  async updateTargetCity(
    request: UpdateTargetCityRequest,
    id: string,
    city_id: string
  ): Promise<TargetCityResponse> {
    try {
      const client = new HttpClient("target", false, []);
      return await client.put<TargetCityResponse>(
        `/targets/${id}/cities/${city_id}`,
        request
      );
    } catch (error) {
      handleError("Error while updating a city target", error);
    }
  }

  async saveTarget(request: SaveTargetRequest, id: string): Promise<void> {
    try {
      const client = new HttpClient("target", false, []);
      return await client.patch<TargetCityResponse>(`/targets/${id}`, request);
    } catch (error) {
      handleError("Error while saving the target", error);
    }
  }

  async getStats(request: GetStatsRequest): Promise<GlobalStats> {
    try {
      const client = new HttpClient("target", true, []);
      return await client.post<TargetCityResponse>(`/targets/stats`, request);
    } catch (error) {
      handleError("Error while getting stats", error);
    }
  }

  async deleteTarget(id: string): Promise<void> {
    try {
      const client = new HttpClient("target", false, []);
      return await client.delete(`/targets/${id}`, {});
    } catch (error) {
      handleError("Error while deleting a target", error);
    }
  }

  getAttributesSync(): Attribute[] {
    return [
      {
        name: TARGET_MAP_ATTRIBUTE,
        friendly_name: "Meta de Votos",
        description:
          "A meta de votos estabelecida pela Plataforma DataElege ou manualmente ajustada pelo usuário",
        suffix: "voto(s)",
        type: AttributeTypes.MAP,
        valid_levels: ["STATE", "CITY"],
        valid_filters: [],
        origin: "Target",
      },
      {
        name: TARGET_TOTAL_ATTRIBUTE,
        friendly_name: "Meta Total",
        description:
          "A meta total de votos estabelecida pela Plataforma DataElege ou manualmente ajustada pelo usuário no nível de detalhe atual",
        suffix: "voto(s)",
        type: AttributeTypes.SCALAR,
        valid_levels: ["STATE", "CITY"],
        valid_filters: [],
        origin: "Target",
      },
      {
        name: TARGET_PRIMARY_TOTAL_ATTRIBUTE,
        friendly_name: "Meta Primária",
        description:
          "A meta de votos em bairros/cidades definidos como primários e estabelecida pela Plataforma DataElege ou manualmente ajustada pelo usuário",
        suffix: "voto(s)",
        type: AttributeTypes.SCALAR,
        valid_levels: ["STATE", "CITY"],
        valid_filters: [],
        origin: "Target",
      },
    ];
  }
  async getAttributes(): Promise<Attribute[]> {
    return new Promise((resolve) => {
      resolve(this.getAttributesSync());
    });
  }

  async getData(
    attribute: Attribute,
    filters: ActiveFilter[],
    comparativeFilters: ActiveFilter[],
    comparativeMode: boolean,
    zoneMode: boolean
  ): Promise<QueryResult> {
    const targetIdFilter = get(filters, TargetFilterConsts.TARGET_ID);
    const cityId = get(filters, DashboardFilterConsts.CITY);
    const targetRoot = await this.getTargetDetails(targetIdFilter.value);
    const shouldGetCityDetails =
      targetRoot.target_type === STATE_TARGET && cityId;
    store.dispatch(setTargetRootManually(targetRoot));

    let cityDetails = null;
    if (shouldGetCityDetails) {
      cityDetails = await this.getTargetCityDetails(
        targetIdFilter.value,
        cityId.value
      );
      store.dispatch(setTargetCityManually(cityDetails));
    }

    if (attribute.name === TARGET_MAP_ATTRIBUTE) {
      const result = this.resolveTargetMapAttribute(targetRoot, cityDetails);
      return result;
    }

    if (attribute.name === TARGET_TOTAL_ATTRIBUTE) {
      const result = this.resolveTotalAttribute(targetRoot, cityDetails);
      return result;
    }

    if (attribute.name === TARGET_PRIMARY_TOTAL_ATTRIBUTE) {
      const result = this.resolveTotalPrimaryAttribute(targetRoot, cityDetails);
      return result;
    }

    return null;
  }

  resolveTargetMapAttribute(
    targetRoot: TargetRootResponse,
    cityDetails: TargetCityResponse | null
  ) {
    const targets = cityDetails ? cityDetails.targets : targetRoot.targets;

    const result: QueryResult = {
      scenario_result: targets.map((target) => ({
        id: target.id,
        value: target.value,
        relative_value: 0,
        linear_scale_value: target.linear_scale_value,
        dataelege_scale_value: target.dataelege_scale_value,
      })),
      compare_scenario_result: [],
      comparative_result: [],
      relative_comparative_result: [],
    };

    return result;
  }

  resolveTotalPrimaryAttribute(
    targetRoot: TargetRootResponse,
    cityDetails: TargetCityResponse | null
  ) {
    let totalTarget = 0;
    const cityLevel = cityDetails ? true : false;
    const targets = cityDetails ? cityDetails.targets : targetRoot.targets;

    const targetsDict = targets.reduce((targetsDict, target) => {
      targetsDict[target.id] = target;
      return targetsDict;
    }, {});

    if (cityLevel) {
      const priorities = targetRoot?.city_priorities?.find(
        (p) => p.city_id === cityDetails.city_id
      );
      totalTarget =
        priorities?.priority_specification?.primaries?.reduce(
          (sum, p) => sum + targetsDict[p].value,
          0
        ) ?? 0;
    } else {
      const priorities = targetRoot?.priorities?.primaries;
      totalTarget =
        priorities?.reduce((sum, p) => sum + targetsDict[p].value, 0) ?? 0;
    }

    const result: QueryResult = {
      scenario_result: { value: totalTarget },
      compare_scenario_result: [],
      comparative_result: [],
      relative_comparative_result: [],
    };

    return result;
  }

  resolveTotalAttribute(
    targetRoot: TargetRootResponse,
    cityDetails: TargetCityResponse | null
  ) {
    const targets = cityDetails ? cityDetails.targets : targetRoot.targets;
    const totalTarget = targets.reduce(
      (sum, targetDetails) => sum + targetDetails.value,
      0
    );

    const result: QueryResult = {
      scenario_result: { value: totalTarget },
      compare_scenario_result: [],
      comparative_result: [],
      relative_comparative_result: [],
    };
    return result;
  }
}
