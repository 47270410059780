import { ChangeEventHandler, memo } from "react";
import "./target-checkbox.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  checked?: boolean;
  type?: string;
  disabled?: boolean;
  clickable: boolean;
  onClick: () => any;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

const TargetCheckboxText: React.FC<Props> = ({
  children,
  checked,
  clickable,
  disabled,
  onChange,
  onClick,
}) => {
  const handleClick = () => {
    if (clickable) {
      onClick();
    }
  };

  return (
    <div className={`target-checkbox`}>
      <input
        disabled={disabled}
        className={`target-checkbox-input`}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <div className="target-checkbox-pane" onClick={handleClick}>
        {children}
      </div>
    </div>
  );
};

export default memo(TargetCheckboxText);
