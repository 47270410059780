import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import ChangePaneButton from "../../../components/buttons/change-pane-button/change-pane-button";
import { makeContainer } from "../../../components/container/container";
import "./dashboard-page.css";

import MapWithData from "./hocs/map-with-data";
import NumberWidgetWithData, {
  WidgetSide,
} from "./hocs/number-widget-with-data";
import WidgetWithData from "./hocs/widget-with-data";

type PaneProps = {
  children?: string | JSX.Element | JSX.Element[];
  show: boolean;
};

const PageContainer = makeContainer("dashboard-target-page-container");
const TopWidgetContainer = makeContainer(
  "dashboard-target-top-widget-container"
);
const BottomWidgetContainer = makeContainer(
  "dashboard-target-bottom-widget-container"
);

const LeftPane: React.FC<PaneProps> = ({ children, show }) => {
  return (
    <div
      className={
        show
          ? "dashboard-target-left-pane"
          : "dashboard-target-left-pane-hidden"
      }
    >
      {children}
    </div>
  );
};

const RightPane: React.FC<PaneProps> = ({ children, show }) => {
  return (
    <div
      className={
        show
          ? "dashboard-target-right-pane"
          : "dashboard-target-right-pane-hidden"
      }
    >
      {children}
    </div>
  );
};

const TargetDashboardPage: React.FC = () => {
  const [showRightPane, setShowRightPane] = useState(false);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1024px)" });

  return (
    <PageContainer>
      <LeftPane show={!showRightPane || !isSmallScreen}>
        <MapWithData />
      </LeftPane>
      <RightPane show={showRightPane || !isSmallScreen}>
        <TopWidgetContainer>
          <NumberWidgetWithData side={WidgetSide.LEFT} />
          <NumberWidgetWithData side={WidgetSide.RIGHT} />
        </TopWidgetContainer>
        <BottomWidgetContainer>
          <WidgetWithData />
        </BottomWidgetContainer>
      </RightPane>
      <ChangePaneButton
        isLeft={!showRightPane}
        onClick={() => setShowRightPane(!showRightPane)}
      />
    </PageContainer>
  );
};

export default TargetDashboardPage;
