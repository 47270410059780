const DATAELEGE_DB = "DATAELEGE_DB";
const DATAELEGE_STORE = "DATAELEGE_STORE";

export function openIndexedDB() {
  const indexedDB: IDBFactory =
    window.indexedDB ||
    window["mozIndexedDB"] ||
    window["webkitIndexedDB"] ||
    window["msIndexedDB"] ||
    window["shimIndexedDB"];

  const openDB = indexedDB.open(DATAELEGE_DB, 1);

  openDB.onupgradeneeded = function () {
    openDB.result.createObjectStore(DATAELEGE_STORE, { keyPath: "id" });
  };

  return openDB;
}

export function getStoreIndexedDB(openDB: IDBOpenDBRequest) {
  const result = openDB.result;
  const tx = openDB.result.transaction(DATAELEGE_STORE, "readwrite");
  const store = tx.objectStore(DATAELEGE_STORE);

  const db = {
    result: result,
    tx: tx,
    store: store,
  };

  return db;
}

export async function saveIndexedDB(index: string, data: any) {
  const openDB = openIndexedDB();

  const saveOperation = new Promise<void>((resolve, reject) => {
    openDB.onsuccess = function () {
      var db = getStoreIndexedDB(openDB);
      db.store.put({ id: index, data: data });
      resolve();
    };

    openDB.onerror = function () {
      resolve();
    };
  });

  return await saveOperation;
}

export function loadIndexedDB(index: string) {
  const openDB = openIndexedDB();

  const getOperation = new Promise<any>((resolve, reject) => {
    openDB.onsuccess = function () {
      const db = getStoreIndexedDB(openDB);
      const getData = db.store.get(index);

      getData.onsuccess = function () {
        if (getData.result) {
          resolve(getData.result.data);
        } else {
          resolve(false);
        }
      };

      db.tx.oncomplete = function () {
        db.result.close();
      };
    };

    openDB.onerror = function () {
      resolve(false);
    };
  });

  return getOperation;
}

export async function clearDatabase() {
  const indexedDB: IDBFactory =
    window.indexedDB ||
    window["mozIndexedDB"] ||
    window["webkitIndexedDB"] ||
    window["msIndexedDB"] ||
    window["shimIndexedDB"];

  const openDB = indexedDB.deleteDatabase(DATAELEGE_DB);

  const deleteOperation = new Promise<void>((resolve, reject) => {
    openDB.onsuccess = function () {
      resolve();
    };

    openDB.onerror = function () {
      reject();
    };
  });

  return await deleteOperation;
}
