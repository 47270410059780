export const enum AlertType {
  MESSAGE = "message",
  WARNING = "warn",
  ERROR = "err",
}

export default interface AlertData {
  message: string;
  type: AlertType;
}
