import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Pages } from "../../app/constants";
import ChangePaneButton from "../../components/buttons/change-pane-button/change-pane-button";
import { makeContainer } from "../../components/container/container";
import Page from "../../components/main-page/page";
import TitleText from "../../components/text/title-text/title-text";
import "./elections.css";
import ElectionFilterWithData from "./hocs/election-filter-with-data";
import ElectionRanking from "./hocs/elections-ranking-with-data";
import ElectionSummary from "./hocs/elections-summary-with-data";

type PageProps = {
  children?: string | JSX.Element | JSX.Element[];
  image?: string;
};

type PaneProps = {
  children?: string | JSX.Element | JSX.Element[];
  show: boolean;
};

const PageContainer = makeContainer("elections-page-container");
const BottomLeftPane = makeContainer("elections-bottom-left-pane");

const LeftPane: React.FC<PaneProps> = ({ children, show }) => {
  return (
    <div
      className={show ? "elections-left-pane" : "elections-left-pane-hidden"}
    >
      {children}
    </div>
  );
};

const RightPane: React.FC<PaneProps> = ({ children, show }) => {
  return (
    <div
      className={show ? "elections-right-pane" : "elections-right-pane-hidden"}
    >
      {children}
    </div>
  );
};

const Elections: React.FC<PageProps> = ({ children, image }) => {
  const [showRightPane, setShowRightPane] = useState(false);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1024px)" });

  return (
    <Page title={<TitleText>{"Eleições"}</TitleText>} page={Pages.ELECTIONS}>
      <PageContainer>
        <ChangePaneButton
          isLeft={!showRightPane}
          onClick={() => setShowRightPane(!showRightPane)}
        />
        <LeftPane show={!showRightPane || !isSmallScreen}>
          <ElectionFilterWithData />
          <TitleText className="ranking-candidates-text">
            Ranking de Candidatos
          </TitleText>
          <BottomLeftPane>
            <ElectionRanking />
          </BottomLeftPane>
        </LeftPane>
        <RightPane show={showRightPane || !isSmallScreen}>
          <ElectionSummary />
        </RightPane>
      </PageContainer>
    </Page>
  );
};

export default Elections;
