import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShopPages, Status } from "../../../app/constants";
import { AppDispatch } from "../../../app/store";
import ShopActionButton from "../../../components/buttons/shop-action-button/shop-action-button";
import ShopHeading, {
  Variant,
} from "../../../components/text/shop-heading/shop-heading";
import ShopService from "../../../features/shop/service";
import {
  getProducts,
  selectGetProductStatus,
  setCurrentPage,
} from "../../../features/shop/slice";
import ShopPageContainer from "../components/shop-page-container/shop-page-container";
import ShopSpacer from "../components/shop-spacer/shop-spacer";

const ProductSelect: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const status: Status = useSelector(selectGetProductStatus);

  useEffect(() => {
    const service = new ShopService();
    if (status === Status.IDLE) {
      dispatch(getProducts({ service: service }));
    }
  });

  return (
    <ShopPageContainer>
      <ShopSpacer space={15} />
      <ShopHeading variant={Variant.H1}>Seleção de Produtos</ShopHeading>
      <ShopHeading variant={Variant.H2}>Escolha um dos produtos</ShopHeading>
      <ShopSpacer space={45} />
      <ShopActionButton
        outline={true}
        disable={status !== Status.SUCCEEDED}
        onClick={() => dispatch(setCurrentPage(ShopPages.PLANS))}
      >
        Planos de Acesso
      </ShopActionButton>
      <ShopActionButton
        outline={true}
        disable={status !== Status.SUCCEEDED}
        onClick={() => dispatch(setCurrentPage(ShopPages.REPORT_CANDIDATE))}
      >
        Relatórios em PDF
      </ShopActionButton>
    </ShopPageContainer>
  );
};

export default ProductSelect;
