import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Status, TargetPages } from "../../../app/constants";
import { AppDispatch } from "../../../app/store";
import { getGlobalTargetAdequacy } from "../../../app/target";
import { isValidInitialTarget, toFixedPtBr, toPtBr } from "../../../app/utils";
import TargetFormButton from "../../../components/buttons/target-form-button/target-form-button";
import { makeContainer } from "../../../components/container/container";
import TargetInput from "../../../components/inputs/target-input/target-input";
import Loading from "../../../components/loading/loading";
import TargetFormContainer from "../../../components/target-page/target-form-container";
import TargetService from "../../../features/target/service";
import {
  getStats,
  selectCreateTargetRequest,
  selectGlobalStats,
  selectGlobalStatsStatus,
  setCreateTargetRequest,
  setCurrentPage,
} from "../../../features/target/slice";
import {
  CreateTargetRequest,
  GlobalStats,
} from "../../../features/target/type";
import "./target-selection-page.css";

const TargetSelectionForm = makeContainer("select-target-input-form");
const TargetSummaryText = makeContainer("select-target-summary-text");

const TargetQualityInfo: React.FC<{ adequacy: string; color: string }> = ({
  adequacy,
  color,
}) => {
  return (
    <div className="target-quality-info" style={{ color: color }}>
      {adequacy}
    </div>
  );
};

export const TargetSelectionPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const createTargetRequest: CreateTargetRequest = useSelector(
    selectCreateTargetRequest
  );
  const stats: GlobalStats = useSelector(selectGlobalStats);
  const statsStatus: Status = useSelector(selectGlobalStatsStatus);
  const initialTarget = (createTargetRequest?.initial_target ?? 0).toString();
  const { adequacyLevel, adequacyColor } = getGlobalTargetAdequacy(
    initialTarget,
    stats
  );

  const goToNext = () => {
    dispatch(setCurrentPage(TargetPages.CANDIDATE_DETAILS));
  };

  const canAdvance = () => {
    return parseInt(initialTarget, 10) > 0;
  };

  const setTarget = (e) => {
    if (isValidInitialTarget(e.target.value)) {
      const newRequest = { ...createTargetRequest };
      newRequest["initial_target"] = e.target.value
        ? parseInt(e.target.value)
        : 0;
      dispatch(setCreateTargetRequest(newRequest));
    }
  };

  useEffect(() => {
    dispatch(
      getStats({
        request: {
          target_type: createTargetRequest.target_type,
          office_id: createTargetRequest.office_id,
          state: createTargetRequest.state,
          city_id: createTargetRequest.city_id,
        },
        service: new TargetService(),
      })
    );
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <TargetFormContainer title="Informe sua meta total de votos" step={3}>
      <TargetSelectionForm>
        <div>
          <TargetInput value={initialTarget} onChange={setTarget} />
          <TargetQualityInfo adequacy={adequacyLevel} color={adequacyColor} />
        </div>

        {statsStatus === Status.SUCCEEDED && (
          <TargetSummaryText>
            <>
              Na última eleição,{" "}
              <b>{toPtBr(stats?.elected_candidates)} candidatos </b> foram
              eleitos, com uma média de{" "}
              <b>{toFixedPtBr(stats?.avg_votes_among_elected, 2)} votos </b>por
              candidato. Entre os eleitos, o mais votado teve{" "}
              <b>{toPtBr(stats?.largest_voting)} votos </b> e o menos votado
              teve <b> {toPtBr(stats?.smallest_voting)} votos </b>.
            </>
          </TargetSummaryText>
        )}

        {statsStatus === Status.LOADING && <Loading />}

        <TargetFormButton disabled={!canAdvance()} onClick={goToNext}>
          Próximo Passo
        </TargetFormButton>
      </TargetSelectionForm>
    </TargetFormContainer>
  );
};

export default TargetSelectionPage;
