import * as React from "react";
import { useSelector } from "react-redux";
import { selectPalette } from "../../../../../features/notebook/slice";
import { ClippedImage, Paper, PaperInside } from "../commons/commons";
import { TemplatePageProps } from "../commons/interfaces";

const Page2: React.FC<TemplatePageProps> = ({
  className,
  notebook,
  triggerImageEdit,
  triggerFieldEdit,
  updateField,
}) => {
  const { palette } = useSelector(selectPalette);

  return (
    <Paper className={className}>
      <PaperInside>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
        >
          <ClippedImage
            id="saphire-page-2"
            triggerImageEdit={triggerImageEdit}
            x={0}
            y={0}
            width={210}
            height={297}
            imageNumber={2}
            imageRatio={0.7}
            notebook={notebook}
          >
            <rect width="100%" height="100%" fill={palette.backgroundLight} />
          </ClippedImage>

          <g
            className="page-2_svg__cls-9"
            data-name="CZJK68"
            style={{
              opacity: 0.13,
            }}
          >
            <path d="M174.953 42.26q-.783-.354-1.6-.638c2.977-2.832 6.018-4.956 9.232-5.958 5.523-1.7 10.727.63 15.577 7.212 3.643 4.956 6.705 11.544 9.47 18.99.354.966.708 1.925 1.084 2.888v2.52c-.963-2.478-1.902-5.02-2.896-7.434-3.494-8.496-7.435-15.417-12.221-19.294-3.873-3.133-7.83-3.696-11.853-2.294-2.336.807-4.595 2.21-6.793 4.008" />
            <path d="M169.306 40.723c-.467-.05-.934-.078-1.416-.088a57.4 57.4 0 0 1 8.012-8.39c3.1-2.567 6.276-4.249 9.558-4.433 5.158-.297 9.82 3.328 14.076 10.132 3.388 5.417 6.216 12.253 8.783 19.744.124.354.255.708.382 1.033v2.514c-1.129-3.03-2.209-6.17-3.395-9.074-4.004-9.83-8.563-17.545-14.277-20.692-4.057-2.234-8.09-1.66-12.097.708-3.345 1.965-6.55 4.917-9.626 8.546" />
            <path d="M170.237 32.98c-2.124 2.45-4.205 5.155-6.241 7.998q-.692.142-1.352.333c3.154-4.624 6.372-8.964 9.774-12.578 3.215-3.424 6.528-6.079 10.03-7.286 5.865-2.018 11.268.892 16.242 8.496 2.906 4.436 5.43 9.913 7.675 16.147.79 2.184 1.561 4.408 2.34 6.613v2.514c-.874-2.503-1.714-5.091-2.634-7.498-3.002-7.846-6.323-14.749-10.394-19.348q-5.572-6.29-11.718-5.165c-4.871.878-9.353 4.772-13.722 9.775" />
            <path d="M158.516 41.385q.233.434.46.875c2.51-3.767 5.027-7.513 7.587-11.081 3.855-5.374 7.898-9.824 12.238-12.557 4.482-2.832 8.974-3.282 13.453-.05 4.28 3.087 7.93 8.755 11.063 16.2 1.908 4.539 3.607 9.583 5.399 14.398v-2.765c-.354-.995-.747-1.954-1.09-2.991-2.178-6.564-4.624-12.49-7.435-17.485-5.718-10.122-12.058-13.832-19.085-10.511-4.249 2.01-8.203 5.993-12.037 10.78-3.64 4.574-7.116 9.799-10.553 15.187" />
            <path d="m157.298 37.232-.64 1.02q.222.343.435.687 2.538-3.894 5.083-7.789c3.976-6.018 8.079-11.487 12.48-15.576 3.44-3.208 6.98-5.37 10.694-5.551 4.645-.227 8.904 2.87 12.816 8.772 3.483 5.254 6.372 12.037 8.978 19.599.52 1.504 1.062 2.991 1.575 4.489v-2.514c-.226-.623-.46-1.228-.676-1.876-2.938-8.812-6.202-16.767-10.291-22.6-4.868-6.947-10.108-9.258-15.694-7.113-3.83 1.466-7.434 4.69-10.886 8.712-4.857 5.643-9.382 12.628-13.874 19.74" />
            <path d="M154.604 35.367c.173.226.354.453.513.683 3.894-5.947 7.824-11.867 11.807-17.527 4.17-5.934 8.606-10.532 13.389-12.982 5.448-2.793 10.712-1.646 15.725 4.429 4.404 5.342 7.916 13.219 11.042 22.186q.818 2.354 1.64 4.698V34.35c-.118-.301-.245-.584-.355-.907-2.935-8.92-6.202-16.992-10.291-22.972-4.51-6.599-9.4-9.307-14.66-7.958-4.022 1.03-7.789 4.149-11.435 8.188-4.563 5.06-8.822 11.39-13.031 17.914q-2.163 3.363-4.344 6.75" />
            <path d="m152.512 32.637.435.56.421-.645c5.006-7.671 9.962-15.534 15.259-22.059 4.191-5.165 8.496-9.455 13.222-11.186 4.776-1.77 9.375-.45 13.757 4.524 4.248 4.84 7.728 12.107 10.808 20.498.783 2.124 1.537 4.326 2.305 6.492v-2.513c-.117-.308-.24-.602-.354-.924-2.8-8.359-5.908-15.931-9.725-21.705q-7.636-11.566-16.571-8.217c-3.827 1.416-7.435 4.578-10.908 8.497-5.01 5.664-9.671 12.744-14.313 19.92-1.444 2.255-2.896 4.514-4.336 6.758" />
            <path d="M150.561 30.35c2.51-3.635 5.006-7.314 7.509-10.974 4.195-6.118 8.393-12.203 12.879-17.067 4.142-4.49 8.4-7.966 12.985-8.773s8.9 1.36 12.957 6.479c3.583 4.524 6.6 10.744 9.311 17.86.86 2.258 1.678 4.602 2.517 6.903v-2.513c-.106-.277-.216-.546-.315-.836-2.34-6.78-4.903-13.02-7.902-18.186-5.767-9.94-12.146-13.626-19.177-10.6-3.345 1.445-6.539 4.072-9.636 7.393-4.602 4.956-8.95 11.173-13.251 17.502-2.783 4.096-5.555 8.228-8.34 12.306.155.174.314.34.463.506" />
            <path d="M147.977 27.816c2.552-3.484 5.07-7.123 7.59-10.727 4.517-6.457 9.03-12.929 13.807-18.243 4.276-4.758 8.673-8.595 13.406-9.86 4.054-1.079 7.987-.031 11.793 3.516 4.113 3.83 7.59 9.913 10.677 17.315 1.2 2.875 2.311 5.955 3.462 8.94v-2.514c-.106-.276-.212-.549-.319-.836-2.988-8.27-6.301-15.626-10.333-20.979-4.457-5.916-9.23-8.305-14.3-7.275-3.72.758-7.267 3.186-10.719 6.493-4.92 4.69-9.512 10.907-14.065 17.315-3.866 5.445-7.71 10.974-11.594 16.338.198.17.4.343.595.517" />
            <path d="M144.858 23.904q-.387.488-.772.966c.209.135.421.27.627.407 1.968-2.382 3.894-4.9 5.84-7.434 5.053-6.656 10.076-13.453 15.185-19.846 4.404-5.512 9.01-9.913 13.916-12.295 4.15-2.015 8.291-2.192 12.42.318 4.34 2.638 8.142 7.735 11.494 14.695 1.806 3.757 3.441 7.998 5.151 12.02V10.22c-1.947-5.133-4.029-9.913-6.372-14.09-5.034-9.006-10.65-13.906-16.993-13.513-4.163.255-8.143 2.641-12.037 6.079-4.85 4.287-9.41 10.022-13.92 15.998-4.836 6.414-9.65 12.985-14.54 19.209" />
            <path d="M139.792 22.59c.198.085.393.177.587.266a122 122 0 0 0 8.412-9.371C154.027 7.13 159.213.53 164.485-5.664c5.27-6.195 10.786-11.328 16.688-13.4 2.974-1.062 5.947-1.157 8.921-.038 4.326 1.632 8.231 5.7 11.817 11.608 2.5 4.114 4.723 8.967 6.815 14.185V3.926c-.145-.269-.3-.52-.439-.814-1.416-2.984-2.74-6.153-4.206-8.932-5.19-9.817-11.056-15.417-17.746-15.541-4.203-.074-8.263 1.933-12.239 5.038-4.602 3.607-8.999 8.496-13.315 13.753-5.473 6.652-10.896 13.53-16.412 19.977-1.554 1.824-3.13 3.54-4.73 5.084z" />
            <path d="M134.602 19.726q.436-.285.867-.59c5.353-3.746 10.43-9.152 15.46-14.795 5.618-6.295 11.162-12.986 16.95-18.41 4.914-4.602 9.93-8.23 15.223-9.24 5.608-1.061 10.943 1.215 15.98 7.237C202.68-11.774 205.81-6 208.709.645v-2.758c-.113-.18-.23-.354-.354-.545-1.62-3.07-3.186-6.373-4.87-9.18-4.415-7.35-9.319-11.725-14.703-12.844-3.92-.81-7.789.227-11.598 2.344-4.871 2.705-9.52 6.95-14.054 11.874C157.907-4.8 152.742 1.162 147.523 6.84c-4.573 4.974-9.204 9.498-14.075 12.43a25 25 0 0 1 1.154.456" />
            <path d="M208.72-5.625c-.805-1.462-1.605-2.938-2.415-4.386-4.068-7.272-8.532-12.529-13.527-15.089a15.41 15.41 0 0 0-11.003-1.25c-5.806 1.4-11.35 5.311-16.749 10.374-5.338 4.991-10.62 10.344-15.93 15.51-4.89 4.76-9.832 9.161-14.986 12.057-3.445 1.936-6.932 3.126-10.476 3.09-6.726-.063-12.854-4.658-18.444-13.484a1.14 1.14 0 0 1-.18-.52 3 3 0 0 1 .318.354c3.569 5.366 7.413 9.112 11.552 11.073a15.27 15.27 0 0 0 9.335 1.218c6.996-1.19 13.58-6.238 20.038-12.327 5.855-5.523 11.654-11.385 17.502-16.954 4.564-4.347 9.233-7.965 14.069-10.188 6.32-2.907 12.596-2.942 18.763 1.89 4.308 3.363 8.142 8.716 11.682 15.435a8 8 0 0 0 .432.67z" />
            <path d="M208.72-11.672c-.355-.555-.74-1.086-1.098-1.674-4.603-7.58-9.644-12.79-15.18-15.078a17.77 17.77 0 0 0-10.621-.956c-6.8 1.392-13.297 5.824-19.715 11.05-5.31 4.336-10.621 8.896-15.931 13.236-4.9 3.993-9.881 7.265-15.01 9.134-8.925 3.25-17.493.956-25.522-9.492a8.8 8.8 0 0 1-1.062-1.685 3 3 0 0 1 .326.323q6.91 8.999 14.745 10.673c4.294.917 8.56.206 12.797-1.575 7.056-2.963 13.807-8.479 20.533-14.122 5.456-4.574 10.894-9.29 16.476-12.971 5.155-3.402 10.38-5.856 15.744-6.153q12.574-.687 23.01 16.104c.157.255.33.45.496.673z" />
            <path d="M208.72-17.69c-1.063-1.324-2.125-2.701-3.219-3.968-4.857-5.665-9.987-9.014-15.375-10.154-4.602-.973-9.204-.354-13.76 1.24-6.157 2.123-12.147 6.018-18.13 9.933-5.71 3.753-11.417 7.54-17.152 11.045a36.7 36.7 0 0 1-13.708 5.14c-7.965 1.201-15.64-1.571-22.905-9.763a1.8 1.8 0 0 1-.396-.708c.903.86 1.802 1.77 2.712 2.58 4.503 4.05 9.165 6.182 13.966 6.777 6.018.743 11.944-1.094 17.824-3.955 6.447-3.133 12.745-7.569 19.086-11.764 5.405-3.579 10.825-7.01 16.355-9.346 5.03-2.124 10.093-3.243 15.223-2.577 6.751.878 13.138 5.006 19.117 12.617a5 5 0 0 0 .382.397z" />
            <path d="M208.72-23.708c-1.828-1.572-3.637-3.307-5.492-4.691a29.3 29.3 0 0 0-13.098-5.664c-5.123-.818-10.214-.163-15.301 1.26-5.86 1.64-11.633 4.425-17.414 7.151-5.884 2.772-11.757 5.665-17.666 8.118-5.731 2.386-11.523 3.643-17.347 3.335-6.393-.354-12.638-2.737-18.667-7.966a1.17 1.17 0 0 1-.354-.545c.91.648 1.816 1.338 2.73 1.94a31.6 31.6 0 0 0 12.227 4.903 31.15 31.15 0 0 0 13.63-.651 108 108 0 0 0 13.431-4.645c6.783-2.867 13.524-6.354 20.31-9.187 4.249-1.77 8.54-2.97 12.826-4.166a29.5 29.5 0 0 1 11.945-.708c6.206.824 12.246 3.603 18.055 8.85a1.3 1.3 0 0 0 .202.131z" />
          </g>

          <path
            d="M114.251.29s25.189-.541 48.38 9.807c22.052 9.842 34.73 19.195 41.127 20.944 8.054 2.205 6.627-.153 6.627-.153V-.265Z"
            className="page-2_svg__cls-7"
            style={{
              fill: palette.backgroundLight,
              strokeWidth: 0.354018,
            }}
          />
          <path
            d="M.394 297.742s34.555-17.771 83.746-16.2 40.829 13.234 75.76 13.195c41.636-.053 50.979-8.171 50.979-8.171v11.176z"
            className="page-2_svg__cls-8"
            style={{
              fill: palette.secondary,
              strokeWidth: 0.354018,
            }}
          />
        </svg>
      </PaperInside>
    </Paper>
  );
};

export default Page2;
