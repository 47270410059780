import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Status } from "../../app/constants";
import { debounceThunk } from "../../app/utils";
import { Candidate, CandidateSearch, ICandidateService } from "./type";

const candidates: CandidateSearch[] | null = null;
const candidateData: Candidate | null = null;

// Slice's initial state
const initialState = {
  status: Status.IDLE,
  candidates: candidates,
  candidateData: candidateData,
  error: "",
};

type GetCandidatesParams = { id: string; service: ICandidateService };
type SearchCandidatesParams = { search: string; service: ICandidateService };

// get candidate thunk
const getCandidateThunk = createAsyncThunk<Candidate, GetCandidatesParams>(
  "candidates/getcandidate",
  async (params) => {
    const { id, service } = params;
    const response = await service.getCandidate(id);
    return response;
  }
);

// search candidate thunk
const searchCandidatesThunk = createAsyncThunk<
  CandidateSearch[],
  SearchCandidatesParams
>("candidates/searchcandidates", async (params) => {
  const { search, service } = params;
  const response = await service.searchCandidates(search);
  return response;
});

// Selectors
export const selectCandidates = (state) => state.candidates.candidates;
export const selectCandidatesStatus = (state) => state.candidates.status;
export const selectCandidateProfileData = (state) =>
  state.candidates.candidateData;

// Reducers
const loading = (state, action) => {
  state.status = Status.LOADING;
};

const successfullGetCandidate = (state, action) => {
  state.status = Status.SUCCEEDED;
  state.candidateData = action.payload;
  state.error = "";
};

const successfullSearchCandidates = (state, action) => {
  state.status = Status.SUCCEEDED;
  state.candidates = action.payload;
  state.error = "";
};

const rejected = (state, action) => {
  state.status = Status.FAILED;
  state.error = action.error.message;
};

// Slice
const candidatesSlice = createSlice({
  name: "candidates",
  initialState,
  reducers: {
    cleanUp(state, action) {
      state.status = Status.LOADING;
      state.candidateData = candidateData;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCandidateThunk.pending, loading)
      .addCase(getCandidateThunk.fulfilled, successfullGetCandidate)
      .addCase(getCandidateThunk.rejected, rejected)
      .addCase(searchCandidatesThunk.pending, loading)
      .addCase(searchCandidatesThunk.fulfilled, successfullSearchCandidates)
      .addCase(searchCandidatesThunk.rejected, rejected);
  },
});

export const { cleanUp } = candidatesSlice.actions;
export const getCandidate = debounceThunk(getCandidateThunk);
export const searchCandidates = debounceThunk(searchCandidatesThunk);
export default candidatesSlice.reducer;
