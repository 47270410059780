import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Pages, Status } from "../../../../app/constants";
import { AppDispatch } from "../../../../app/store";
import LoginCtaButton from "../../../../components/buttons/login-cta-button/login-cta-button";
import LoginTextButton from "../../../../components/buttons/login-text-button/login-text-button";
import {
  makeContainer,
  makeSpanContainer,
} from "../../../../components/container/container";
import LoginInputText from "../../../../components/inputs/login-input-text/login-input-text";
import LoginFormLabelText from "../../../../components/text/login-form-label-text/login-form-label-text";
import LoginSubtitleText from "../../../../components/text/login-subtitle-text/login-subtitle-text";
import LoginTitleText from "../../../../components/text/login-title-text/login-title-text";
import AuthService from "../../../../features/auth/service";
import {
  askResetPassword,
  cleanUp,
  selectAuthStatus,
} from "../../../../features/auth/slice";
import LoginLogo from "../../../../resources/images/login-logo.png";
import "./forgot-password-form.css";

const Form = makeContainer("forgot-password-form");
const Signup = makeSpanContainer("forgot-password-signup");

const Logo: React.FC = memo(() => {
  return <img alt="logo" className="forgot-password-logo" src={LoginLogo} />;
});

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const askResetStatus: Status = useSelector(selectAuthStatus);
  const [emailInput, setEmailInput] = useState("");

  function handleClick(link: string) {
    return () => navigate(`/${link}`);
  }

  const askUserPasswordReset = () => {
    if (askResetStatus !== Status.LOADING) {
      const askResetData = {
        data: {
          email: emailInput,
        },
        service: new AuthService(),
      };
      dispatch(askResetPassword(askResetData));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(cleanUp(null));
    };
  }, [dispatch]);

  return (
    <Form>
      <Logo />
      <LoginTitleText className="forgot-password-title">
        Esqueceu sua senha?
      </LoginTitleText>
      <LoginSubtitleText className="forgot-password-subtitle">
        Fique tranquilo. Vamos redefini-lá.
      </LoginSubtitleText>
      <LoginFormLabelText className="forgot-password-label">
        Email
      </LoginFormLabelText>
      <LoginInputText
        className="forgot-password-input"
        placeHolder="Seu email cadastrado"
        value={emailInput}
        onChange={(e) => setEmailInput(e.target.value)}
        onEnterPressed={askUserPasswordReset}
      />
      <LoginCtaButton onClick={askUserPasswordReset} status={askResetStatus}>
        Redefinir Senha
      </LoginCtaButton>
      <Signup>
        <span className="forgot-password-remember-text">Lembrou a senha? </span>
        <LoginTextButton onClick={handleClick(Pages.LOGIN)}>
          Faça o login
        </LoginTextButton>
      </Signup>
    </Form>
  );
};

export default memo(ForgotPasswordForm);
