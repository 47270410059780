import { memo, MouseEventHandler } from "react";
import "./target-selection-button.css";

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  selected?: boolean;
  children: string;
};

const TargetSelectionButton: React.FC<Props> = (props) => {
  const { onClick, selected, children } = props;

  return (
    <button
      className={
        selected
          ? "target-selection-button"
          : "unselected-target-selection-button"
      }
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default memo(TargetSelectionButton);
