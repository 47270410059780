import React, { memo } from "react";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => any;
};

export const makeContainer = (mainClassName: string) => {
  const container: React.FC<Props> = ({
    style,
    children,
    className,
    onClick,
  }) => {
    return (
      <div
        className={`${mainClassName}${className ? " " + className : ""}`}
        style={style}
        onClick={onClick}
      >
        {children}
      </div>
    );
  };
  return memo(container);
};

export const makeSpanContainer = (className: string) => {
  const container: React.FC<Props> = ({ children, style, onClick }) => {
    return (
      <span className={className} style={style} onClick={onClick}>
        {children}
      </span>
    );
  };
  return memo(container);
};

export const makePContainer = (className: string) => {
  const container: React.FC<Props> = ({ children, style }) => {
    return (
      <p className={className} style={style}>
        {children}
      </p>
    );
  };
  return memo(container);
};
