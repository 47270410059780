import { debounce } from "lodash";
import {
  AUTH_PAGE,
  BRAZIL,
  DashboardFilterConsts,
  firstMunicipalElections,
  generalOffices,
  LevelOfDetail,
  MAIN_PAGE,
  MAX_TARGETS,
  municipalOffices,
  SCALES,
  SHOP_PAGE,
  stateOffices,
} from "./constants";
import { ActiveFilter, FilterValue, getValue } from "./filter";
import { Feature, Polygon } from "./maps";

//============= Types =============
export type Map = Record<string, string>;
export const EmptyMap: Map = {};

//============= String functions =============
export function trimStringFields(obj: object): object {
  const newObj = {};
  for (const key in obj) {
    if (typeof obj[key] === "string") {
      newObj[key] = obj[key].trim();
    } else {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}

export function getFirstWordOrEmailPrefix(str: string): string {
  let firstWordOrEmail = str.includes("@")
    ? str.split("@")[0]
    : str.split(" ")[0];
  return firstWordOrEmail.length > 12
    ? firstWordOrEmail.slice(0, 12) + "..."
    : firstWordOrEmail;
}
//============= Value conversions functions =============
export function toPtBr(value: number) {
  return value?.toLocaleString("pt-BR");
}

export function fromPtBr(value: string) {
  return parseInt(value.replaceAll(",", "").replaceAll(".", ""), 10);
}

export function toFixedPtBr(value: number, digits: number) {
  return value?.toLocaleString("pt-BR", {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
}

export function toPtBrContracted(value: number) {
  if (Math.abs(value) < 1000) {
    return `${toFixedPtBr(value, 1)}`;
  }

  const thousands = value / 1000;
  if (Math.abs(thousands) < 1000) {
    return `${toFixedPtBr(thousands, 1)} mil`;
  }

  const millions = thousands / 1000;
  if (Math.abs(millions) < 1000) {
    return `${toFixedPtBr(millions, 1)} mi`;
  }

  const billions = millions / 1000;
  if (Math.abs(billions) < 1000) {
    return `${toFixedPtBr(billions, 1)} bi`;
  }
}

export function fromCurrencyToStr(num) {
  if (!num) {
    return "R$ 0,00";
  }

  const result = num / 100;
  const options = {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return result.toLocaleString("pt-BR", options);
}

//============= DateTime functions =============
export function getTimestampInSeconds() {
  return Math.floor(Date.now() / 1000);
}

export function toDate(dateString: string): Date | null {
  if (!dateString) return null;
  const dateParts = dateString.split("/");
  return new Date(
    parseInt(dateParts[2]),
    parseInt(dateParts[1]) - 1,
    parseInt(dateParts[0])
  );
}

export function getYearsBetween(date: Date): number {
  if (!date) return 0;
  const differenceInMilliseconds = new Date().getTime() - date.getTime();
  const years = differenceInMilliseconds / 1000 / 60 / 60 / 24 / 365;
  return Math.floor(years);
}

export function timestampToDateString(timestamp: number): string {
  const date = new Date(timestamp * 1000);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export function fixDate(time: string): string {
  if (!time) {
    return "";
  }

  const split = time.split("T")[0];
  const splitDate = split.split("-");
  return `em ${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;
}

//============= Array functions =============
export function addToArrayWithoutDups(array: any[], item: any): any[] {
  if (!array.includes(item)) {
    return [...array, item];
  }
  return [...array];
}

export function sortByLabel(array: { label: string }[]): any[] {
  let sorted = [...array];
  sorted.sort((a, b) => (a.label > b.label ? 1 : -1));
  return sorted;
}

export function listToObjKeys(list: string[]): any {
  const obj = {};

  if (!list) {
    return obj;
  }

  for (const item of list) {
    obj[item] = true;
  }
  return obj;
}

//============== Thunk Reducer Function Creators ============

export function debounceThunk(thunk: any) {
  const debounced = debounce((arg, dispatch) => dispatch(thunk(arg)), 1000, {});
  return (arg) => (dispatch) => debounced(arg, dispatch);
}

//============= Routes functions ===========
export const filterMainPages = (item: string) => {
  return item.includes(MAIN_PAGE);
};

export const filterAuthPages = (item: string) => {
  return item.includes(AUTH_PAGE);
};

export const filterShopPages = (item: string) => {
  return item.includes(SHOP_PAGE);
};

//============= Permissions =============
export function getAvailableStates(
  constants: any,
  permissions: any
): FilterValue[] {
  if (!constants || !permissions) {
    return [];
  }

  return constants?.states[0]
    ?.filter((state: any) => {
      for (const statePermission of permissions?.statePermissions) {
        if (statePermission.state === state) {
          return true;
        }
      }
      return false;
    })
    .map((state: any) => ({
      label: state,
      value: state,
    }));
}

export function addBrazil(availableStates: FilterValue[]) {
  if (!availableStates) {
    return availableStates;
  }

  return [
    ...availableStates,
    {
      label: BRAZIL,
      value: "",
    },
  ];
}

export function getAvaliableYearsForState(
  constants: any,
  permissions: any,
  state: string,
  returnAllOnNull: boolean = true
): FilterValue[] {
  const getAll = () => true;
  const getMajoritary = (year) => (year - 2010) % 4 === 0;

  const getAllowedYears = (year) => {
    for (const statePermission of permissions?.statePermissions) {
      if (statePermission.state === state) {
        return statePermission.years.includes(parseInt(year));
      }
    }
    return false;
  };

  const filterFunc =
    !state && returnAllOnNull
      ? getAll
      : !state
      ? getMajoritary
      : getAllowedYears;

  const mapToFilter = (year) => ({
    label: year,
    value: year,
  });

  return constants?.years.filter(filterFunc).map(mapToFilter);
}

//============= Constants =============
export function getMostRecentElection(years: number[]): string {
  const mostRecent = Math.max(...years);
  return mostRecent.toString();
}

//============= Filters =============
export function getAvaliableOfficesForYear(
  constants: any,
  state: string,
  year: number
) {
  const isMunicipalElection = (year - firstMunicipalElections) % 4 === 0;

  const validOffices = isMunicipalElection
    ? municipalOffices
    : state === BRAZIL
    ? generalOffices
    : stateOffices;

  const filteredOffices = constants?.offices?.filter((office) =>
    validOffices.includes(office?.label)
  );

  return filteredOffices;
}

export function getCurrentLevelOfDetail(
  filters: ActiveFilter[],
  zoneMode: boolean
): LevelOfDetail {
  const burrowId = getValue(filters, DashboardFilterConsts.BURROW);
  const cityId = getValue(filters, DashboardFilterConsts.CITY);
  const stateId = getValue(filters, DashboardFilterConsts.STATE);

  if (burrowId) {
    return LevelOfDetail.PLACE;
  }

  if (cityId) {
    return LevelOfDetail.BURROW;
  }

  if (stateId && zoneMode) {
    return LevelOfDetail.ZONE;
  }

  if (stateId && !zoneMode) {
    return LevelOfDetail.CITY;
  }

  return LevelOfDetail.STATE;
}

export function getDrillDownFilter(level: LevelOfDetail) {
  if (level === LevelOfDetail.STATE) {
    return { label: "Estado", value: DashboardFilterConsts.STATE };
  }

  if (level === LevelOfDetail.CITY) {
    return { label: "Cidade", value: DashboardFilterConsts.CITY };
  }

  if (level === LevelOfDetail.BURROW) {
    return { label: "Bairro", value: DashboardFilterConsts.BURROW };
  }

  return null;
}

export function getRollUpFilter(level: LevelOfDetail) {
  if (level === LevelOfDetail.CITY || level === LevelOfDetail.ZONE) {
    return { label: "Estado", value: DashboardFilterConsts.STATE };
  }

  if (level === LevelOfDetail.BURROW) {
    return { label: "Cidade", value: DashboardFilterConsts.CITY };
  }

  if (level === LevelOfDetail.PLACE) {
    return { label: "Bairro", value: DashboardFilterConsts.BURROW };
  }

  return null;
}

//=============Handlers===========
export const handleClick =
  (
    clickTimeout,
    setClickTimeout,
    onClickHandler: (payload: any) => void,
    onDoubleClickHandler: (payload: any) => void,
    payload: any
  ) =>
  (event) => {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
      onDoubleClickHandler(payload);
    } else {
      const timeout = setTimeout(() => {
        onClickHandler(payload);
        setClickTimeout(null);
      }, 200);
      setClickTimeout(timeout);
    }
  };

//============= Maps =============
export const convertPolygonCoords = (polygon: any) => {
  const convertedCoords = [];

  for (const coordinate of polygon) {
    const [long, lat] = coordinate;
    if (!isNaN(long) && !isNaN(lat)) {
      convertedCoords.push([lat, long]);
    }
  }

  return convertedCoords;
};

export const calculateEnvelope = (features: Feature[]): number[][] => {
  if (!features) {
    return [
      [0, 0],
      [0, 0],
    ];
  }

  let [[yMin, xMin], [yMax, xMax]] = [
    [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY],
    [Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY],
  ];

  const reducer = (acc, point) => {
    if (point[0] < acc[0][0]) {
      acc[0][0] = point[0];
    }

    if (point[1] < acc[0][1]) {
      acc[0][1] = point[1];
    }

    if (point[0] > acc[1][0]) {
      acc[1][0] = point[0];
    }

    if (point[1] > acc[1][1]) {
      acc[1][1] = point[1];
    }

    return acc;
  };

  const isPointList = features[0].type === "Point";

  if (isPointList) {
    const points = features.map((item) => item.centroid);
    [[yMin, xMin], [yMax, xMax]] = points.reduce(reducer, [
      [yMin, xMin],
      [yMax, xMax],
    ]);
  } else {
    for (const item of features) {
      for (const polygon of item.polygons) {
        [[yMin, xMin], [yMax, xMax]] = polygon.coords.reduce(reducer, [
          [yMin, xMin],
          [yMax, xMax],
        ]);
      }
    }
  }

  return [
    [yMin, xMin],
    [yMax, xMax],
  ];
};

export const convertPolygons = (type: string, geometry: any): Polygon[] => {
  const convertedPolygons = [];

  for (const element of geometry) {
    if (type === "Polygon") {
      const polygon = element;
      const coords = convertPolygonCoords(polygon);
      convertedPolygons.push({
        coords: coords,
      });
    }

    if (type === "MultiPolygon") {
      const multipolygon = element;

      for (const polygon of multipolygon) {
        const coords = convertPolygonCoords(polygon);
        convertedPolygons.push({
          coords: coords,
        });
      }
    }
  }

  return convertedPolygons;
};

export const getColor = (
  feature: Feature,
  comparativeMode: boolean,
  settings: any
) => {
  if (!settings) {
    return SCALES["COLD_COLORS"][0];
  }

  const values = {
    DATAELEGE: feature.dataelegeScaleValue,
    LINEAR: feature.linearScaleValue,
  };

  let [scale, type] = settings.color_scale.split("-");
  let value = values[type];

  if (value < 0) {
    return SCALES["HOT_COLORS"][-1 * value];
  }

  if (comparativeMode) {
    return SCALES["COLD_COLORS"][value];
  }

  return SCALES[scale][value];
};

export function getHighContrastColor(color: string): string {
  let hsl = RGBToHSL(color);
  hsl.h = 196;
  hsl.s = 0.5;
  hsl.l = hsl.l > 0.5 ? 0.35 : 0.65;
  return HSLToRGB(hsl);
}

function RGBToHSL(color: string): { h: number; s: number; l: number } {
  let r = parseInt(color.substring(1, 3), 16) / 255;
  let g = parseInt(color.substring(3, 5), 16) / 255;
  let b = parseInt(color.substring(5, 7), 16) / 255;
  let max = Math.max(r, g, b);
  let min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0;
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }
  return { h: h * 360, s: s, l: l };
}

function HSLToRGB(hsl: { h: number; s: number; l: number }): string {
  let h = hsl.h / 360;
  let s = hsl.s;
  let l = hsl.l;

  let r, g, b;
  if (s === 0) {
    r = g = b = l;
  } else {
    let hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    let p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  return (
    "#" +
    (
      (1 << 24) +
      (Math.round(r * 255) << 16) +
      (Math.round(g * 255) << 8) +
      Math.round(b * 255)
    )
      .toString(16)
      .slice(1)
  );
}

//============= Shops =============

export function validateName(name: string): boolean {
  const nameRegex = /^[0-9a-zA-ZÀ-ÖØ-öø-ÿ '-]+$/;
  return nameRegex.test(name);
}

export function validateAddress(name: string): boolean {
  const nameRegex = /^[a-zA-ZÀ-ÖØ-öø-ÿ0-9.,:;/\\\- ]+$$/;
  return nameRegex.test(name);
}

export function validateCreditCardNumber(creditCardNumber: string): boolean {
  if (creditCardNumber.length !== 16) {
    return false;
  }

  let sum = 0;
  let isSecondDigit = false;

  for (let i = creditCardNumber.length - 1; i >= 0; i--) {
    let digit = parseInt(creditCardNumber.charAt(i), 10);

    if (isSecondDigit) {
      digit *= 2;

      if (digit > 9) {
        digit -= 9;
      }
    }

    sum += digit;
    isSecondDigit = !isSecondDigit;
  }

  return sum % 10 === 0;
}

export function validateCreditCardDate(input: string): boolean {
  const regex = /^\d{2}\/\d{4}$/;
  if (!regex.test(input)) {
    return false;
  }

  const [month, year] = input.split("/").map(Number);

  if (month < 1 || month > 12) {
    return false;
  }

  const currentYear = new Date().getFullYear();

  if (year < currentYear || year > currentYear + 10) {
    return false;
  }

  return true;
}

export function validateCvv(cvv: string): boolean {
  const cvvRegex = /^[0-9]{3,4}$/;
  return cvvRegex.test(cvv);
}

export function validateCPF(cpf: string): boolean {
  if (cpf.length !== 11) {
    return false;
  }

  const invalidCPFs = [
    "00000000000",
    "11111111111",
    "22222222222",
    "33333333333",
    "44444444444",
    "55555555555",
    "66666666666",
    "77777777777",
    "88888888888",
    "99999999999",
  ];

  if (invalidCPFs.includes(cpf)) {
    return false;
  }

  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i);
  }
  let firstDigit = 11 - (sum % 11);
  if (firstDigit > 9) {
    firstDigit = 0;
  }

  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i);
  }
  let secondDigit = 11 - (sum % 11);
  if (secondDigit > 9) {
    secondDigit = 0;
  }

  return (
    parseInt(cpf.charAt(9)) === firstDigit &&
    parseInt(cpf.charAt(10)) === secondDigit
  );
}

export function validateCEP(cep: string): boolean {
  if (cep.length !== 8) {
    return false;
  }

  return true;
}

//============= Targets =============

export const canCreateNewTarget = (targets) => {
  const savedTargets = targets.filter((t) => !t.in_progress);
  return savedTargets.length < MAX_TARGETS;
};

export const isValidInitialTarget = (value: string) => {
  if (!value) return true;
  const regex = /^\d+$/;
  return regex.test(value) && parseInt(value, 10) > 0;
};

//============= Questionaire =============
export function validateEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function validateText(name: string): boolean {
  if (!name || isStringEmpty(name)) return false;
  return true;
}

export function validateNumeric(input: string): boolean {
  const numericRegex = /^\d{1,3}$/;
  return numericRegex.test(input);
}

function isStringEmpty(input: string): boolean {
  return /^\s*$/.test(input);
}
