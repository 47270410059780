import axios, { AxiosError } from "axios";
import { setAlert } from "../features/alert/slice";
import { AlertType } from "../features/alert/type";
import AuthService from "../features/auth/service";
import { loadIndexedDB, saveIndexedDB } from "./cache";
import handleError from "./errors";
import { hashCode } from "./hash";
import store from "./store";
import { toPtBr } from "./utils";

const TWO_MB = 2000000;
axios.defaults.withCredentials = true;

class HttpClient {
  description: string;
  cacheable: boolean;
  ignoreList?: number[];

  constructor(
    description: string,
    cacheable: boolean,
    ignoreList: number[] = null
  ) {
    this.description = description;
    this.cacheable = cacheable;
    this.ignoreList = ignoreList;
  }

  createClient() {
    return axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
      onDownloadProgress: (progressEvent) => {
        if (progressEvent.total >= TWO_MB) {
          store.dispatch(
            setAlert({
              message: `Baixando (${toPtBr(
                Math.ceil(progressEvent.progress * 100)
              )} %)`,
              type: AlertType.MESSAGE,
            })
          );
        }
      },
    });
  }

  async exec(doRequest: any, key: string) {
    try {
      if (this.cacheable) {
        const cachedData = await loadIndexedDB(key);
        if (cachedData) {
          return cachedData;
        }
      }

      // Executes request
      const response = await doRequest();
      await saveIndexedDB(key, response);

      return response;
    } catch (error) {
      // Check if a 403 error occured
      if (!this.shouldRetry(error)) {
        handleError(this.description, error, this.ignoreList);
        return;
      }

      // Refreshes the token
      await this.refresh();

      // Tries again
      try {
        const response = await doRequest();
        await saveIndexedDB(key, response);
        return response;
      } catch (anotherError) {
        handleError(this.description, anotherError, this.ignoreList);
      }
    }
  }

  async get<T = any>(url: string) {
    const doRequest = async () => {
      const client = this.createClient();
      const response = await client.get<T>(url);
      return response.data;
    };

    const key = `get-${hashCode(url)}`;
    return await this.exec(doRequest, key);
  }

  async post<T = any>(url: string, data: any) {
    const doRequest = async () => {
      const client = this.createClient();
      const response = await client.post<T>(url, data);
      return response.data;
    };

    const key = `post-${hashCode(url)}-${JSON.stringify(data)}`;
    return await this.exec(doRequest, key);
  }

  async patch<T = any>(url: string, data: any) {
    const doRequest = async () => {
      const client = this.createClient();
      const response = await client.patch<T>(url, data);
      return response.data;
    };

    const key = `patch-${hashCode(url)}-${JSON.stringify(data)}`;
    return await this.exec(doRequest, key);
  }

  async put<T = any>(url: string, data: any) {
    const doRequest = async () => {
      const client = this.createClient();
      const response = await client.put<T>(url, data);
      return response.data;
    };

    const key = `put-${hashCode(url)}-${JSON.stringify(data)}`;
    return await this.exec(doRequest, key);
  }

  async delete<T = any>(url: string, data: any) {
    const doRequest = async () => {
      const client = this.createClient();
      const response = await client.delete<T>(url, data);
      return response.data;
    };

    const key = `delete-${hashCode(url)}-${JSON.stringify(data)}`;
    return await this.exec(doRequest, key);
  }

  shouldRetry(error: AxiosError) {
    const isBadRequest =
      error.code === "ERR_BAD_REQUEST" || error.code === "ERR_BAD_RESPONSE";
    const isForbidden = error.response.status === 403;
    return isBadRequest && isForbidden;
  }

  async refresh() {
    const userData = store.getState().auth.userData;

    if (!userData) {
      return;
    }

    const service = new AuthService();
    await service.refreshLogin(userData);
  }
}

export default HttpClient;
