import React, { memo } from "react";
import { Status } from "../../../../../app/constants";
import { makeContainer } from "../../../../../components/container/container";
import Loading from "../../../../../components/loading/loading";
import ScrollContainer from "../../../../../components/scroll/scroll";
import { Candidate } from "../../../../../features/candidates/type";
import "./candidate-details.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  status?: Status;
  candidate?: Candidate;
};

type CandidateDetailItemProps = {
  children?: string | JSX.Element | JSX.Element[];
  maxWidth?: number;
};

const CandidateDetailContainer = makeContainer("candidate-details-container");
const CandidateDetailSubContainer = makeContainer(
  "candidate-details-sub-container"
);

const CandidateDetailItem: React.FC<CandidateDetailItemProps> = ({
  children,
  maxWidth,
}) => {
  return (
    <div className="candidate-details-item" style={{ maxWidth: maxWidth }}>
      {children}
    </div>
  );
};

const CandidateDetailHeader: React.FC<Props> = () => {
  return (
    <div className="candidate-details-header">
      <CandidateDetailItem maxWidth={120}>Sexo</CandidateDetailItem>
      <CandidateDetailItem maxWidth={100}>Etnia</CandidateDetailItem>
      <CandidateDetailItem maxWidth={120}>Estado Civil</CandidateDetailItem>
      <CandidateDetailItem maxWidth={120}>Nascido em</CandidateDetailItem>
      <CandidateDetailItem>Escolaridade</CandidateDetailItem>
      <CandidateDetailItem>Ocupação</CandidateDetailItem>
    </div>
  );
};

const CandidateDetailContent: React.FC<Props> = ({ candidate }) => {
  return (
    <div className="candidate-details-content">
      <CandidateDetailItem maxWidth={120}>{candidate?.sex}</CandidateDetailItem>
      <CandidateDetailItem maxWidth={100}>
        {candidate?.race}
      </CandidateDetailItem>
      <CandidateDetailItem maxWidth={120}>
        {candidate?.maritalstatus}
      </CandidateDetailItem>
      <CandidateDetailItem maxWidth={120}>
        {candidate?.dateofbirth}
      </CandidateDetailItem>
      <CandidateDetailItem>{candidate?.schoollevel}</CandidateDetailItem>
      <CandidateDetailItem>{candidate?.profession}</CandidateDetailItem>
    </div>
  );
};

const CandidateDetails: React.FC<Props> = ({ status, candidate }) => {
  return (
    <CandidateDetailContainer>
      {status === Status.LOADING && <Loading />}
      {status !== Status.LOADING && (
        <ScrollContainer>
          <CandidateDetailSubContainer>
            <CandidateDetailHeader />
            <CandidateDetailContent candidate={candidate} />
          </CandidateDetailSubContainer>
        </ScrollContainer>
      )}
    </CandidateDetailContainer>
  );
};

export default memo(CandidateDetails);
