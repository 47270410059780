import { memo, MouseEventHandler } from "react";
import { Status } from "../../../app/constants";
import "./modal-button.css";

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  status?: Status;
  disabled?: boolean;
  children: string;
  variation: "primary" | "secondary";
};

const ModalButton: React.FC<Props> = (props) => {
  const { onClick, children, disabled, variation } = props;

  return (
    <button
      className={`modal-button modal-button-${variation}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default memo(ModalButton);
