import * as React from "react";
import { useSelector } from "react-redux";
import { validateText } from "../../../../../app/utils";
import { selectPalette } from "../../../../../features/notebook/slice";
import { ClippedImage, Paper, PaperInside } from "../commons/commons";
import { TemplatePageProps } from "../commons/interfaces";

const Page12: React.FC<TemplatePageProps> = ({
  className,
  notebook,
  triggerImageEdit,
  triggerFieldEdit,
  updateField,
}) => {
  const { palette } = useSelector(selectPalette);
  return (
    <Paper className={className}>
      <PaperInside>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="svg1"
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
        >
          <defs>
            <clipPath id="page12-a">
              <path
                id="path518"
                d="M170.07 3170.9h105.1V3276a126.69 126.69 0 0 1-126.68 126.7 105.11 105.11 0 0 1-105.11-105.11 126.69 126.69 0 0 1 126.69-126.69Z"
                transform="rotate(180 460.17 2882.57)"
              />
            </clipPath>
            <clipPath id="page12-b">
              <path
                id="path518"
                d="M424 3061.68a123.47 123.47 0 0 1 123.47 123.47v102.44H445.08a123.47 123.47 0 0 1-123.47-123.47A102.44 102.44 0 0 1 424 3061.68Z"
              />
            </clipPath>
            <clipPath id="page12-c">
              <path
                id="path518"
                d="M470.62 2770.37h101.75v101.76a122.65 122.65 0 0 1-122.65 122.65A101.76 101.76 0 0 1 348 2893a122.65 122.65 0 0 1 122.62-122.63z"
                transform="rotate(180 460.17 2882.57)"
              />
            </clipPath>
            <filter
              id="filter"
              width={454.32}
              height={455.18}
              x={132.41}
              y={2883.32}
              filterUnits="userSpaceOnUse"
            >
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <filter
              id="filter-30"
              width={0.666}
              height={0.998}
              x={0.311}
              y={-0.004}
            >
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
          </defs>

          <g id="layer1">
            <path
              id="rect518"
              d="M-.25.703h210.667v297.941H-.25z"
              style={{
                fill: palette.backgroundLight,
                strokeWidth: 0.353895,
              }}
            />

            <path
              id="path519"
              d="M325 2883.32h18.07a228.39 228.39 0 0 1 228.39 228.39v207.56H364a228.39 228.39 0 0 1-228.39-228.39v-18.07A189.49 189.49 0 0 1 325 2883.32Z"
              style={{
                fill: palette.backgroundLight,
                filter: "url(#filter)",
              }}
              transform="matrix(.3539 0 0 .3539 -1.137 -920.208)"
            />

            <text
              id="text1246"
              x={82.977}
              y={45.616}
              style={{
                fontSize: "14.0426px",
                fontFamily: "Heavitas",
                fill: palette.primary,
                strokeWidth: 0.353895,
              }}
            >
              {"apoiadores"}
            </text>

            <g
              id="CZJK68-13"
              data-name="CZJK68"
              style={{
                opacity: 0.13,
              }}
              transform="matrix(.3539 0 0 .3539 -1.137 -920.208)"
            >
              <g id="g1921" transform="translate(-4.486)">
                <path
                  id="path1892"
                  d="M26.55 3450.12a206 206 0 0 0 15.33-44.31 225.26 225.26 0 0 0 4.28-73.16c-2.11-20.11-5.48-40.2-9.12-60.27-3.27-18.1-7.76-36.15-11.56-54.23-3.5-16.59-6.94-33.19-10.11-49.79-1.42-7.47-2.14-15-2.92-22.44 3.14 14 6 28.09 9.5 42.11 3 12 6.62 23.92 10 35.87 3.91 13.93 8 27.85 11.68 41.79 3.31 12.6 6.55 25.22 9 37.86 2.28 11.59 4.32 23.2 5 34.82a307.15 307.15 0 0 1 0 42.58c-1.84 23.36-8.75 46.46-21.58 69.17z"
                />
                <path
                  id="path1893"
                  d="M49.34 3450.12a150.54 150.54 0 0 0 28.5-45.54c8.35-21 10-42.17 8.12-63.41a187.17 187.17 0 0 0-11.75-50.54c-11.29-29.39-23.75-58.69-35-88.09-6.3-16.48-11.14-33.07-16.56-49.61a26.44 26.44 0 0 1-1.39-8.24c1.71 5.45 3.07 10.92 5.19 16.34 5.74 14.65 11.29 29.32 17.84 43.9 11 24.43 23.45 48.74 33.78 73.24 6.73 15.92 11.41 32 15.86 48.13 4.72 17.08 5.24 34.27 2.68 51.44-3.61 24.26-14.93 47.67-35.45 70.18a12.37 12.37 0 0 0-1.38 2.2z"
                />
                <path
                  id="path1894"
                  d="M73.07 3450.12c4.72-4.39 9.61-8.75 14.14-13.18 17.05-16.66 28.13-34.09 33.47-52.28 6.27-21.34 4.57-42.6-1.79-63.87-7-23.5-19.65-46.46-32.92-69.37-14.44-25-29.13-49.87-42.59-74.93-6-11.2-9.28-22.69-13.75-34.06a2.62 2.62 0 0 1 .2-1.85c10.86 33.27 33 65.28 54 97.4 14.62 22.29 29 44.6 39.07 67.43 8.37 19 13.56 38.23 12.71 57.68-1.31 30.35-17.91 58.85-51.37 85.3a19.72 19.72 0 0 0-1.7 1.73z"
                />
                <path
                  id="path1895"
                  d="M95.86 3450.12c7.4-5.44 15.23-10.77 22.12-16.33q35.07-28.35 41.71-60.69c4.08-19.68.14-39.11-8.23-58.41-11.76-27.12-31.67-53.19-51.58-79.25-17.87-23.38-36.23-46.7-49-70.78-4.09-7.7-7.26-15.51-10.79-23.27a3.43 3.43 0 0 1 0-1.65c4.17 8.13 8 16.5 12.79 24.77 11.91 20.66 28.1 40.71 44.43 60.73 20.19 24.74 41.11 49.37 56 74.91 11.16 19.2 18.62 38.63 18.71 58.56.15 30.9-19.08 59.12-56.9 84.86l-9.68 6.55z"
                />
                <path
                  id="path1896"
                  d="M118.64 3450.12c9.71-6 20-11.9 29-18.13 25.34-17.5 41.05-36.66 46.67-57.41 3.82-14.11 2.61-28.19-2.07-42.22-8.66-26-28-50.67-49.28-75-21.11-24.14-42.83-48.17-63.42-72.39a169.58 169.58 0 0 1-28.86-47 61.59 61.59 0 0 1-3.07-10.4c8.12 24.63 25.68 48 46.62 70.91 19.23 21 39.61 41.87 59 62.88 17.8 19.25 33.61 38.82 43.64 59.24 8.05 16.36 12 32.89 8.92 49.65-4.33 23.5-22.06 44.87-51.53 64.29-8.17 5.38-17.42 10.44-26.17 15.65z"
                />
                <path
                  id="path1897"
                  d="M141.43 3450.12c17.23-8.44 33.27-17.28 46.74-27 30.51-22.12 44.59-46.12 41.75-72.12-2.15-19.6-12.84-38.34-27.28-56.73-17.18-21.9-39.36-42.84-61.18-63.85-21.41-20.62-42.88-41.23-59.34-62.79-10.51-13.77-18.77-27.78-23-42.24-.46-1.54-.76-3.09-1.07-4.64a3.33 3.33 0 0 1 .17-1.7c7.2 29.79 31.58 57.15 59.45 84 21.9 21.07 45 41.9 66.79 63 20.73 20 38.49 40.53 48.53 62.25 12.32 26.64 10 52.72-14.63 78-15.67 16.05-38.17 30-64.84 42.69l-2.6 1.25z"
                />
                <path
                  id="path1898"
                  d="M165.16 3450.12c3.83-1.85 7.59-3.73 11.49-5.55 32.15-15 58.42-31.66 73.3-51.6 12.37-16.57 15.33-33.53 10.64-50.82-6.5-23.93-24.81-46.25-47.74-67.8-20.61-19.38-42.71-38.44-64.29-57.61-21.58-19.17-42.55-38.46-57.84-58.88-9.22-12.32-16.06-24.86-19.16-37.86a11.87 11.87 0 0 1 .07-3.76c5.2 24.16 22.6 46.75 46.14 68.33 24.16 22.15 50.18 43.9 75.55 65.78 21.23 18.31 41.79 36.75 57 56.27 13.51 17.3 22.52 35 21.95 53.46-.68 21.79-15.12 41.67-41.59 59.88-15.47 10.65-33.82 20.17-54.17 29a13.77 13.77 0 0 0-1.89 1.16z"
                />
                <path
                  id="path1899"
                  d="M188 3450.12c3.34-1.48 6.61-3 10-4.44 32.52-13.76 60.09-29.14 77.63-47.8 14.85-15.8 20.15-32.27 17.19-49.32-3.73-21.45-19.27-41.4-38.71-60.82-20.17-20.15-44.56-39.31-68.55-58.56-23.67-19-47.34-38-66.41-58.07-13.73-14.44-24.91-29.24-30.46-44.8a58 58 0 0 1-2.77-28.16 6.88 6.88 0 0 1 .35 1.58c-2.22 21.39 9 41.62 26.4 61.31 17.63 20 40.78 38.68 64.18 57.35 25.4 20.28 51.11 40.48 75.42 61 19.23 16.24 34.29 33.32 43.4 51.35 11.48 22.71 10.17 45-9.89 66.81-14.34 15.57-36 28.94-61.94 41-8.56 4-17.56 7.73-26.37 11.59z"
                />
                <path
                  id="path1900"
                  d="M210.73 3450.12c9.38-3.9 19-7.69 28.09-11.7 32.1-14.13 58.23-30.05 72.87-49.39 11.84-15.63 14-31.63 8.67-47.88-6.91-21.24-24.92-40.78-46.23-59.78-25.61-22.83-56-44.43-84.67-66.48-25.07-19.31-49.24-38.83-66.57-59.86-13.74-16.68-22.63-33.79-21.25-51.72a32.49 32.49 0 0 1 2.67-10.53c-.34 2.18-.64 4.35-1 6.52-2.18 12.3 1.48 24.34 8.18 36.23 9.7 17.22 25 33.5 44.33 48.9 26 20.69 53.3 41.05 80.34 61.47 23.5 17.75 46.8 35.53 65.17 54.51 13.7 14.16 24.38 28.74 28.8 44.11 6.41 22.31-2.38 43.34-27.24 62.95-18.67 14.73-43.6 27.1-71.72 38.26-3.66 1.46-7.28 2.93-10.92 4.39z"
                />
                <path
                  id="path1901"
                  d="M5.66 3176.57a21.44 21.44 0 0 1 .81 3.37c1.71 23 3.25 46 5.11 69 3.23 39.8 6.3 79.62 5.5 119.46-.5 25.19-2.57 50.39-4 75.58-.12 2.06-.52 4.11-.79 6.17H5.66Z"
                />
                <path
                  id="path1902"
                  d="M233.52 3450.12c11.45-4.56 23.3-8.94 34.28-13.72 37.13-16.18 66.26-34.6 78.15-57.69 8.44-16.39 6.28-32.68-2.62-48.88-10.45-19-28.09-36.75-49.8-53.61-25.92-20.13-53.24-39.91-80.11-59.8-24.51-18.14-48.54-36.39-66.87-56-11.45-12.26-20.45-24.85-24.66-38-3.9-12.24-3.6-24.44 3.4-36.49-.65 1.73-1.32 3.46-1.93 5.19-6.7 18.83 0 37 13.52 54.81 14.44 19 35.21 36.71 58.48 53.79 27.95 20.52 56.92 40.75 84.95 61.24 24.67 18 47.19 36.57 62.72 56.65 9.69 12.53 16 25.35 16.74 38.66 1.12 20.84-12.58 39.68-38.28 56.88-20.45 13.69-46.28 25.11-74.58 35.48-1.34.5-2.6 1-3.9 1.55z"
                />
                <path
                  id="path1903"
                  d="M256.31 3450.12c9.45-3.54 19.23-6.92 28.32-10.65 29.63-12.13 55.71-25.55 73.08-42q23.76-22.5 19.51-47.34c-3.35-19.69-18.09-37.85-37-55.44-21.6-20.11-48.34-38.88-75.09-57.64-29.49-20.67-59.64-41.16-84.33-63.11-17.79-15.83-32.37-32.16-39-49.75-5.94-15.87-4.08-31.49 7.32-46.81a3.25 3.25 0 0 1 1.68-1.06c-1.88 3.1-4 6.18-5.58 9.31-8.41 16.71-5.74 33.25 4.2 49.65 9.8 16.17 25.41 31.25 43.33 45.88 26.61 21.72 57.78 42.16 87.79 62.93 27.69 19.17 54.86 38.48 75.69 59.41 12.93 13 23 26.37 27.51 40.53 7.63 23.69-3.36 45.52-32 65.62-16.75 11.74-37.47 21.94-61 31-8.26 3.18-16.66 6.3-25 9.45z"
                />
                <path
                  id="path1904"
                  d="M177.65 3069.68c-3.54 4.83-7.68 9.58-10.49 14.5-9.42 16.47-7.17 32.88 2.27 49.21 9.68 16.73 25.91 32.22 44.51 47.26 21.32 17.24 45.59 33.64 70 50 28.78 19.35 57.78 38.65 82 59.28 18.07 15.41 33.12 31.34 40.72 48.55 12.53 28.4-1.47 54-39.7 77.12-18.88 11.41-41.26 21.29-66.06 30.09-3.91 1.39-7.54 2.93-11.3 4.41h-10.51c18.19-7.25 37.25-14.1 54.38-21.82 28.13-12.66 49.54-27.4 61.2-44.7 12.21-18.11 10.47-36.25-.19-54.36-10.33-17.54-27.13-33.88-47.43-49.46-18.26-14-37.75-27.71-57-41.46-19.67-14-40.16-27.84-59.51-42-24.11-17.6-45.51-35.83-59.13-55.7-8.63-12.56-13.26-25.39-11.87-38.57 1.17-11 6.83-21.55 16.58-31.66a9.22 9.22 0 0 1 1.26-.86z"
                />
                <path
                  id="path1905"
                  d="M302.83 3450.12c5.65-2.12 11.27-4.27 17-6.36 28.53-10.52 54.17-22.21 74-36.29 22.29-15.8 34-33 33.14-51.77-.68-15-8.86-29.32-21-43.21-15.49-17.78-36.15-34.37-58.89-50.43-31-21.92-63-43.57-93.79-65.55-22.57-16.11-42.29-32.93-56.19-51-10.56-13.72-16.9-27.77-15.61-42.36 1.23-14 9.42-27.24 24.29-39.6.45-.37 1-.72 2.08-1.56-.25.77-.19 1-.41 1.22-26.84 22.65-29.91 46.34-15.3 70.79 10.47 17.54 27.9 33.72 47.81 49.4 25.78 20.31 55.42 39.5 84.2 58.94 26.86 18.15 53.26 36.43 74.55 56.06 15.2 14 27.37 28.51 32.92 44 8.09 22.57-.64 43.74-26.87 63.41-22 16.52-52.09 29.72-85.37 41.58-2.44.87-4.73 1.82-7.09 2.74z"
                />
                <path
                  id="path1906"
                  d="M325.61 3450.12c5.92-2.21 11.82-4.43 17.75-6.63 33.88-12.62 63.63-26.81 83.8-44.61 22.95-20.25 27.29-41.52 16.73-63.54-9.26-19.32-26.62-37.25-49-54.1-25.19-18.94-51.71-37.52-78.19-56.1-27-18.95-54.49-37.75-76.22-58.08-14.54-13.6-26.33-27.62-32.09-42.55-9-23.44-.47-45.42 27-65.85a3.58 3.58 0 0 1 2.11-.69c-.72.6-1.4 1.21-2.16 1.81-15.19 11.83-24.66 24.5-27.31 38.13-2.71 13.91 1.86 27.46 10.79 40.75 12.65 18.8 32.17 36.22 55.19 52.8 26.92 19.38 55 38.42 82.8 57.57 24.65 17 48.55 34.22 67.66 52.66 15.26 14.72 27 29.95 30.94 46.19 5.09 21.27-5.15 41-30.07 59.23-22.6 16.53-53.07 29.74-86.77 41.59-1.21.43-2.28.94-3.42 1.42z"
                />
                <path
                  id="path1907"
                  d="M348.4 3450.12c8.18-3.11 16.46-6.16 24.52-9.32 31.7-12.45 59.14-26.42 77.43-43.66 18.79-17.72 23.74-36.29 17.09-55.59-6.54-19-22.75-36.49-42.26-53.43-24.64-21.4-54.35-41.43-83.32-61.65-24-16.76-47.95-33.56-68.18-51.33-17.51-15.39-32.1-31.24-39.46-48.32-11.58-26.87-.15-51.55 34-74.14 1.51-1 3.05-2 4.6-3a2.85 2.85 0 0 1 1.53-.29c-.66.49-1.27 1-2 1.47-19.28 12.46-32.57 26-37.67 41.11-4.3 12.71-2.35 25.32 4 37.83 9.5 18.86 27.43 36.17 48.09 53 24.46 19.89 52.73 38.72 80.25 57.77 27.1 18.74 53.85 37.59 75.23 57.83 14.87 14.07 26.77 28.6 32.16 44.07q12.57 36.08-31.05 67c-21.82 15.41-50.41 28-82 39.28-1.22.44-2.33.94-3.49 1.41z"
                />
                <path
                  id="path1908"
                  d="M371.18 3450.12c8.71-3.39 17.56-6.7 26.08-10.17 26.88-10.95 50.37-23.14 67.46-37.63q29-24.59 24.47-52.35c-3.05-18.52-16.18-35.73-33.13-52.46-18.37-18.12-41.36-35.09-64.46-52-26.4-19.36-53.16-38.63-78.48-58.27-21.88-17-39.48-34.85-49.72-54-16.07-30.09-5.46-58 34.57-83.38a7.67 7.67 0 0 1 3.12-1.2c-1.75 1.21-3.49 2.43-5.25 3.63-19.13 13.07-31.54 27.28-35.47 42.86-3.73 14.8.52 29.29 9.65 43.54 11.6 18.1 29.26 35.05 50.5 51.19 25.3 19.24 51.85 38.14 78 57.15 23.92 17.38 47.4 34.86 66.12 53.54 12.54 12.51 22.46 25.41 27.91 38.94 11.44 28.39-2.48 54.17-40 77.48-19.51 12.12-43.08 22.46-68.7 31.92-1.09.41-2.1.85-3.15 1.28z"
                />
                <path
                  id="path1909"
                  d="M394 3450.12c11.28-4.65 22.91-9.15 33.76-14 28-12.48 50.94-26.53 65.42-43.14 13.38-15.38 17.35-31.27 13.26-47.65-4.77-19.12-19.27-36.89-37.23-54.17-20.08-19.32-44.52-37.56-68.9-55.81-25.84-19.34-52.15-38.56-74-58.9-17-15.82-31.13-32.08-38.17-49.45q-17.37-42.9 38.1-78.66a5.06 5.06 0 0 1 2.37-.71c-.64.51-1.21 1.05-1.93 1.53-19.84 13.28-32.95 27.73-37.62 43.63-4.24 14.4-1.18 28.59 6.72 42.62 11.35 20.16 30.44 38.91 53.17 56.89 24.17 19.12 49.58 37.93 74.5 56.86 24.21 18.38 47.69 36.94 65.41 56.82 12.44 14 21.66 28.28 24.52 43.31 3.9 20.49-5.13 39.77-27.47 57.78-20.22 16.29-48 29.68-79.25 41.75-1.08.42-2.11.86-3.16 1.29z"
                />
                <path
                  id="path1910"
                  d="M416.75 3450.12c15.2-6.92 31.21-13.52 45.41-20.82 26.29-13.53 45.54-28.9 55.5-46.44 9.48-16.69 8.81-33.42 1.21-50.19-9-19.81-25.62-38.42-46.44-56.21-24.17-20.65-49.83-40.94-75-61.36-21.32-17.32-42.08-34.76-58.15-53.23-12.28-14.12-21.53-28.6-24.87-43.73-5.28-24 6.06-46.24 34.47-66.7 4.51-3.25 9.47-6.37 14.25-9.54a2.85 2.85 0 0 1 1.52-.18c-.78.56-1.49 1.13-2.34 1.67-23.54 14.89-39 31.25-44 49.44-3.84 14-1.25 27.89 5.85 41.63 10.5 20.31 28.57 39.33 50.33 57.62 23.5 19.77 48.33 39.22 72.59 58.8 22.57 18.22 44.24 36.64 60.44 56.24 13 15.71 22 31.82 23 48.65 1.48 25.64-17 48.32-51 68.66-15.73 9.41-33.83 17.81-53.22 25.69z"
                />
                <path
                  id="path1911"
                  d="M439.54 3450.12c19.71-8.45 38-17.44 53.59-27.53 22.31-14.5 37.68-30.28 43.84-47.76 4.23-12 3.82-24-.14-36-7.84-23.84-27.09-46-50.62-67.43-23.53-21.43-48.32-42.25-72.33-63.41-21.66-19.09-41.77-38.49-55.34-59.11-9.35-14.23-15.14-28.71-14.7-43.58.71-24.11 18.27-45.71 49.28-65.23a3.49 3.49 0 0 1 2.06-.4c-1.27.88-2.51 1.76-3.8 2.63-18.39 12.41-31.67 25.79-38.58 40.41-6.64 14-6.79 28.16-2.06 42.32 7.67 23 25.73 44.43 48.32 65.1 24.35 22.28 50.34 44.2 75.47 66.31 19.82 17.44 38.32 35.15 51.94 53.8 11.73 16.06 19.32 32.46 19 49.45-.46 27-21.61 50.73-58.7 71.69-10.49 5.94-22.46 11.34-33.73 17-1.12.56-2.06 1.18-3.09 1.78z"
                />
                <path
                  id="path1912"
                  d="M462.33 3450.12c25.1-11.64 46.91-24.37 63.15-38.9 22.74-20.35 31.34-41.91 27.32-64.56-3.8-21.43-17.53-41.7-34.89-61.56-20.44-23.38-45.71-45.77-69.49-68.47-21.31-20.34-41.74-40.84-55.88-62.47-10.79-16.48-17.49-33.25-16.55-50.51 1.36-24.84 19.14-47.21 50.55-67.53.33-.21.34-.52.5-.79l1.25.48c-.84.64-1.6 1.29-2.51 1.91-22.12 15.07-36.81 31.45-42.93 49.31-4.78 13.93-3.83 27.86 1.14 41.75 9.32 26.08 30.73 50.36 55 74.19 21.45 21.09 44 42 65.35 63.05 18.61 18.33 34.65 37.11 44.86 56.79 8 15.43 11.92 31 8.84 46.86-4.22 21.76-20.74 41.57-48.51 59.41-10.66 6.84-23.07 13.13-34.67 19.68-.76.43-1.38.9-2.06 1.36z"
                />
                <path
                  id="path1913"
                  d="M486.06 3450.12c5.53-3.25 11.11-6.48 16.57-9.75 27.47-16.44 47.33-34.48 57-54.66 7-14.7 8.09-29.54 4.73-44.46-5.29-23.46-20.11-45.86-39.18-67.68-18.86-21.56-39.07-42.89-58.59-64.34-18-19.76-34.6-39.72-45.54-60.55-7.31-13.92-11.81-28-11.68-42.33.25-27.13 17.61-51.94 50.94-74.52a4.21 4.21 0 0 1 2-.74 12.28 12.28 0 0 1-1.38 1.3c-20.28 14.41-34.43 30-41.83 46.67a65.58 65.58 0 0 0-4.61 37.72c4.5 28.26 23.57 54.87 46.57 81 20.85 23.66 43 47.09 64 70.71 16.42 18.45 30.09 37.31 38.49 56.85 11 25.54 11.12 50.89-7.13 75.88-12.72 17.41-32.94 32.94-58.32 47.2a28.13 28.13 0 0 0-2.53 1.74z"
                />
                <path
                  id="path1914"
                  d="M508.85 3450.12c2.1-1.48 4.11-3 6.33-4.43 28.63-18.65 48.3-39 56.94-61.35a76.55 76.55 0 0 0 3.63-42.84c-5.26-27.46-22-53.72-41.74-79.64-16.38-21.52-33.61-42.91-50-64.42-15.09-19.79-27.45-39.92-34.5-60.64-12.27-36.07-3.61-70.68 35.85-103.13a32.4 32.4 0 0 1 6.36-4.27 11.31 11.31 0 0 1-1.21 1.31q-34 27.91-40.32 59.58c-3.47 17.35-.77 34.58 5.95 51.71 11.19 28.51 32 55.77 53.34 83 17.27 22.05 35.09 44 49 66.57 12.84 20.84 22.12 41.94 23.23 63.62q2.61 50.79-60.83 93c-1 .63-1.7 1.34-2.54 2z"
                />
                <path
                  id="path1915"
                  d="M531.63 3450.12c5-4.33 10.21-8.62 15-13 21.42-19.63 34-40.36 38.35-62.13 3.68-18.63 1.3-37.13-4.67-55.59-8-24.89-22.68-49.08-37.53-73.26-14.17-23.07-28.47-46.12-41.71-69.3q-15.54-27.23-19.43-55.38c-4.52-32.19 5.95-63.2 36.89-92.55a6.67 6.67 0 0 1 2.7-1.6c-3.25 3.65-6.65 7.28-9.75 11-15.3 18.19-23.34 37-25.59 56.43-2.81 24.34 4.13 48.26 14.93 72 11.84 26.05 28.6 51.53 44.44 77.11 13.52 21.84 26.47 43.74 35.31 66.09 8 20.32 12.24 40.78 9.73 61.43-3.31 27.29-18.9 53.09-47.66 77.23a18.09 18.09 0 0 0-1.5 1.55z"
                />
                <path
                  id="path1916"
                  d="M554.42 3450.12c6-7.39 12.49-14.7 17.73-22.19a122.81 122.81 0 0 0 21.4-53c3.09-20.69.61-41.27-4.76-61.82-6.19-23.67-16.72-47-27-70.36-10.48-23.77-21.39-47.51-30.67-71.37-9-23.16-13.75-46.56-12.58-70.12 1.27-25.83 10.33-51.06 30.08-75.42a4.31 4.31 0 0 1 2.06-1.46c-2.46 3.68-5 7.33-7.33 11a133 133 0 0 0-18.53 49.4 134.51 134.51 0 0 0 2.47 55.06 455.33 455.33 0 0 0 17.54 54.27c10.83 27.42 24 54.64 34.7 82.07 6.7 17.16 11.22 34.5 15.74 51.82 4.18 16 4.63 32.15 2.62 48.26-3.12 25.07-13.6 49.48-33.47 73a5.8 5.8 0 0 0-.5.82z"
                />
                <path
                  id="path1917"
                  d="M600.94 3330c-.54-2.95-1.09-5.91-1.61-8.87-2.35-13.29-4.26-26.6-7.16-39.87-3.16-14.47-7.24-28.89-10.92-43.33-4.22-16.55-8.89-33.09-12.56-49.67-3.58-16.16-6.71-32.35-9-48.56-2.56-17.85-3.45-35.76-.66-53.62a385.21 385.21 0 0 1 8.88-40.63c2.1-7.85 6.06-15.59 9.18-23.38l1.24.09A92.22 92.22 0 0 0 575 3031c-3.5 13.73-7.57 27.44-10 41.21-3.53 19.83-3.08 39.73-.87 59.59 1.7 15.29 4.3 30.58 7.2 45.85 2.78 14.62 6.34 29.22 9.78 43.82 3.44 14.6 7.41 29.45 10.8 44.19 2.67 11.64 4.79 23.31 7.2 35 .49 2.39 1.2 4.76 1.81 7.15z"
                />
                <path
                  id="path1918"
                  d="M577.2 3450.12c4.54-10 10.09-19.92 13.35-30 4.13-12.76 6.39-25.64 9.48-38.46.25-1.05.6-2.09.91-3.14v29.83a4.51 4.51 0 0 0-.77 1q-6.75 20.39-13.47 40.77z"
                />
                <path
                  id="path1919"
                  d="M600.94 3191.06a52.3 52.3 0 0 1-.77-5.93c-.62-31.29-1.39-62.57-1.63-93.86-.09-12.13 1-24.26 1.67-36.39a9.73 9.73 0 0 1 .73-2.3z"
                />
                <path id="path1920" d="M70.52 3113.3v-11.45h.27v11.44z" />
                <path
                  id="path1921"
                  d="m177.36 3069.57 7.53-7.45 1.08.23-8.32 7.33z"
                />
              </g>
            </g>
            <foreignObject
              x={19.005}
              y={60.032}
              width={95}
              height={110}
              className="clickable"
            >
              <div
                style={{
                  fontSize: "5px",
                  fontFamily: "MontserratAlternates",
                  fontWeight: "400",
                  color: palette.backgroundGray,
                  textAlign: "start",
                  width: "100%",
                  height: "100%",
                  whiteSpace: "pre-wrap",
                }}
                onClick={() =>
                  triggerFieldEdit({
                    currentValue: notebook.supporters,
                    field: "supporters",
                    label: "Apoiadores",
                    type: "textarea",
                    options: [],
                    onUpdateField: updateField,
                    validate: validateText,
                  })
                }
              >
                {notebook.supporters}
              </div>
            </foreignObject>

            <ClippedImage
              id="saphire-page-12-1"
              triggerImageEdit={triggerImageEdit}
              x={121}
              y={59}
              width={80}
              height={80}
              imageNumber={16}
              imageRatio={1}
              notebook={notebook}
            >
              <path
                d="M470.62 2770.37h101.75v101.76a122.65 122.65 0 0 1-122.65 122.65A101.76 101.76 0 0 1 348 2893a122.65 122.65 0 0 1 122.62-122.63z"
                transform="matrix(0.35170206,0,0,0.35170206,-0.50807578,-914.93671) rotate(180,460.17,2882.57)"
                style={{ fill: palette.backgroundLight }}
              />
            </ClippedImage>

            <ClippedImage
              id="saphire-page-12-2"
              triggerImageEdit={triggerImageEdit}
              x={112}
              y={162}
              width={80}
              height={80}
              imageNumber={17}
              imageRatio={1}
              notebook={notebook}
            >
              <path
                d="M424 3061.68a123.47 123.47 0 0 1 123.47 123.47v102.44H445.08a123.47 123.47 0 0 1-123.47-123.47A102.44 102.44 0 0 1 424 3061.68Z"
                transform="matrix(0.35170206,0,0,0.35170206,-0.50807578,-914.93671)"
                style={{ fill: palette.backgroundLight }}
              />
            </ClippedImage>

            <ClippedImage
              id="saphire-page-12-3"
              triggerImageEdit={triggerImageEdit}
              x={14}
              y={199}
              width={83}
              height={83}
              imageNumber={18}
              imageRatio={1}
              notebook={notebook}
            >
              <path
                d="M170.07 3170.9h105.1V3276a126.69 126.69 0 0 1-126.68 126.7 105.11 105.11 0 0 1-105.11-105.11 126.69 126.69 0 0 1 126.69-126.69Z"
                transform="matrix(0.35170206,0,0,0.35170206,-0.50807578,-914.93671) rotate(180,159.28,3286.8)"
                style={{ fill: palette.backgroundLight }}
              />
            </ClippedImage>

            <path
              d="M213.5 13a73.685 73.685 0 01.781-12.985h-81.937S152.04 24.68 185.83 22.45c12.772-.843 19.241-3.85 23.888-7.216z"
              style={{
                fill: palette.secondary,
                strokeWidth: 0.353895,
              }}
            />
            <g transform="matrix(.3539 0 0 .3539 -1.137 -920.208)">
              <g id="g1941">
                <path
                  id="path1938"
                  d="M58.74 2729.12c0-1.87-.07-3.75 0-5.62a11.59 11.59 0 0 1 4.89-9.06 1.35 1.35 0 0 1 .19-.08 9.35 9.35 0 0 0 13.54 0 11.74 11.74 0 0 1 3.95 4.7 1.22 1.22 0 0 0 .8.75 17.3 17.3 0 0 0 10.81 0 1.18 1.18 0 0 0 .78-.71 11.44 11.44 0 0 1 3.5-4.39l.44-.32a9.43 9.43 0 0 0 13.6 0c.26.19.53.38.78.59a11.72 11.72 0 0 1 4.17 7.53 1.09 1.09 0 0 0 .1.25v6.41H92.58v-5.7a20.76 20.76 0 0 1-10.12 0v5.69z"
                  style={{
                    fill: palette.primary,
                  }}
                />
                <path
                  id="path1939"
                  d="M82.46 2729.12v-5.69a20.76 20.76 0 0 0 10.12 0v5.7z"
                  style={{
                    fill: palette.primary,
                  }}
                />
                <path
                  id="path1940"
                  d="M80.69 2683.84a9.44 9.44 0 0 0 13.63 0 1.62 1.62 0 0 1 .18.1 20.9 20.9 0 0 0 3.72 2.47 20 20 0 0 1 9.43 14.58.6.6 0 0 1 0 .22 3.88 3.88 0 0 0 1.3 3 5.68 5.68 0 0 1 .52 6.83 5.94 5.94 0 1 1-5.54-9h.37a16.92 16.92 0 0 0-5-10.42v7H75.72v-7a16.9 16.9 0 0 0-5 10.4 6.38 6.38 0 0 1 3.61 1.33 5.93 5.93 0 1 1-7.45 0 1.06 1.06 0 0 0 .34-.69 20.44 20.44 0 0 1 5.47-12.79 19.75 19.75 0 0 1 6.12-4.48 3.51 3.51 0 0 0 .59-.42z"
                  style={{
                    fill: palette.primary,
                  }}
                />
                <path
                  id="path1941"
                  d="M87.53 2671.56a5.93 5.93 0 0 1 0 11.86 5.93 5.93 0 1 1 0-11.86z"
                  style={{
                    fill: palette.primary,
                  }}
                />
              </g>
            </g>
          </g>
        </svg>
      </PaperInside>
    </Paper>
  );
};

export default Page12;
