import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Pages, Status } from "../../../../app/constants";
import { AppDispatch } from "../../../../app/store";
import LoginCtaButton from "../../../../components/buttons/login-cta-button/login-cta-button";
import LoginTextButton from "../../../../components/buttons/login-text-button/login-text-button";
import { makeContainer } from "../../../../components/container/container";
import LoginInputText from "../../../../components/inputs/login-input-text/login-input-text";
import LoginFormLabelText from "../../../../components/text/login-form-label-text/login-form-label-text";
import LoginSubtitleText from "../../../../components/text/login-subtitle-text/login-subtitle-text";
import LoginTitleText from "../../../../components/text/login-title-text/login-title-text";
import AuthService from "../../../../features/auth/service";
import {
  cleanUp,
  resetPassword,
  selectAuthStatus,
} from "../../../../features/auth/slice";
import LoginLogo from "../../../../resources/images/login-logo.png";

import "./reset-password-form.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
};

const Form = makeContainer("reset-password-form");
const Signup = makeContainer("reset-password-signup");

const Logo: React.FC = memo(() => {
  return <img alt="logo" className="reset-password-logo" src={LoginLogo} />;
});

const ResetPasswordForm: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch: AppDispatch = useDispatch();

  const resetStatus: Status = useSelector(selectAuthStatus);
  const [passwordInput, setPasswordInput] = useState("");
  const [repeatPasswordInput, setRepeatPasswordInput] = useState("");

  const userId = searchParams.get("id");
  const code = searchParams.get("code");

  const resetUserPassword = () => {
    if (resetStatus !== Status.LOADING) {
      const resetParams = {
        data: {
          user_id: userId,
          reset_code: code,
          new_password: passwordInput,
          new_password_repeated: repeatPasswordInput,
        },
        service: new AuthService(),
      };

      dispatch(resetPassword(resetParams));
    }
  };

  function handleClick(link: string) {
    return () => navigate(`/${link}`);
  }

  useEffect(() => {
    return () => {
      dispatch(cleanUp(null));
    };
  }, [dispatch]);

  return (
    <Form>
      <Logo />
      <LoginTitleText className="reset-password-title">
        Redefina sua senha
      </LoginTitleText>
      <LoginSubtitleText className="reset-password-subtitle">
        Mínimo de 8 caracteres.
      </LoginSubtitleText>
      <LoginFormLabelText className="reset-password-label">
        Nova senha
      </LoginFormLabelText>
      <LoginInputText
        className="reset-password-input"
        placeHolder="Nova senha"
        type="password"
        value={passwordInput}
        onChange={(e) => setPasswordInput(e.target.value)}
      />
      <LoginFormLabelText className="reset-password-label">
        Redigite a nova senha
      </LoginFormLabelText>
      <LoginInputText
        className="reset-password-input"
        placeHolder="Redigite a nova senha"
        type="password"
        value={repeatPasswordInput}
        onEnterPressed={resetUserPassword}
        onChange={(e) => setRepeatPasswordInput(e.target.value)}
      />
      <LoginCtaButton onClick={resetUserPassword} status={resetStatus}>
        Redefinir Senha
      </LoginCtaButton>
      <Signup>
        <span className="reset-password-signup-text">Deseja cancelar? </span>
        <LoginTextButton onClick={handleClick(Pages.LOGIN)}>
          Faça o login
        </LoginTextButton>
      </Signup>
    </Form>
  );
};

export default memo(ResetPasswordForm);
