import { useSelector } from "react-redux";
import {
  NotebooksOfficesOptions,
  toFeminine,
} from "../../../../../app/constants";
import { validateText } from "../../../../../app/utils";
import { selectPalette } from "../../../../../features/notebook/slice";
import {
  ClippedImage,
  Paper,
  PaperInside,
  PartyDisplay,
} from "../commons/commons";
import { TemplatePageProps } from "../commons/interfaces";

const Page1: React.FC<TemplatePageProps> = ({
  className,
  notebook,
  triggerImageEdit,
  triggerFieldEdit,
  updateField,
}) => {
  const { palette } = useSelector(selectPalette);

  return (
    <Paper className={className}>
      <PaperInside>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
        >
          <defs>
            <filter id="page1-a">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <filter id="page1-c">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <filter id="page1-d">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <filter id="page1-e">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <filter id="page1-b">
              <feDropShadow
                dx="-4"
                dy="-4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
          </defs>
          <rect
            width={209.76}
            height={297.147}
            x={0.238}
            y={-0.237}
            className="cls-7"
            ry={0}
            style={{
              fill: palette.backgroundLight,
              strokeWidth: 0.352449,
            }}
          />
          <g
            className="cls-9"
            data-name="CZJK68"
            style={{
              opacity: 0.13,
            }}
          >
            <path d="M201.927.517a103.176 103.176 0 0 0-5.403 19.117 118.37 118.37 0 0 0-1.505 31.566c.74 8.677 1.928 17.347 3.21 26.003 1.156 7.807 2.735 15.596 4.078 23.396 1.23 7.158 2.443 14.32 3.56 21.5.5 3.22.754 6.453 1.03 9.678-1.108-6.056-2.116-12.118-3.35-18.166-1.056-5.167-2.332-10.32-3.524-15.48-1.378-5.99-2.82-12.01-4.116-18.027-1.167-5.438-2.309-10.88-3.172-16.336-.804-4.998-1.523-10.01-1.763-15.021a162.127 162.127 0 0 1 0-18.37A83.404 83.404 0 0 1 198.578.53z" />
            <path d="M193.898.517a68.615 68.615 0 0 0-10.049 19.646c-2.942 9.065-3.542 18.197-2.858 27.36a96.384 96.384 0 0 0 4.141 21.803c3.983 12.688 8.371 25.327 12.336 38.008 2.22 7.11 3.93 14.267 5.84 21.404a14.098 14.098 0 0 1 .49 3.557c-.603-2.351-1.085-4.713-1.833-7.05-2.02-6.322-3.979-12.649-6.284-18.94-3.877-10.542-8.265-21.03-11.909-31.597-2.369-6.873-4.021-13.827-5.59-20.77-1.663-7.366-1.847-14.803-.944-22.204 1.272-10.447 5.269-20.566 12.5-30.276a5.777 5.777 0 0 0 .487-.951z" />
            <path d="M185.53.517c-1.663 1.897-3.386 3.775-4.983 5.685-6.01 7.19-9.914 14.712-11.796 22.557-2.21 9.203-1.611 18.38.63 27.551 2.468 10.144 6.926 20.047 11.603 29.934 5.09 10.763 10.27 21.517 15.01 32.326 2.116 4.832 3.272 9.791 4.847 14.694a1.353 1.353 0 0 1-.07.803c-3.828-14.358-11.631-28.167-19.033-42.026-5.152-9.618-10.22-19.243-13.77-29.09-2.95-8.216-4.78-16.499-4.48-24.89.462-13.09 6.313-25.377 18.106-36.803a6.513 6.513 0 0 0 .599-.744z" />
            <path d="M177.502.517c-2.608 2.348-5.371 4.646-7.796 7.05q-12.368 12.23-14.704 26.183c-1.438 8.487-.046 16.868 2.9 25.196 4.145 11.705 11.162 22.948 18.183 34.188 6.295 10.087 12.766 20.15 17.27 30.54 1.445 3.323 2.559 6.696 3.803 10.038a1.805 1.805 0 0 1 0 .704c-1.47-3.506-2.82-7.12-4.508-10.686-4.194-8.913-9.904-17.562-15.66-26.2-7.115-10.677-14.488-21.303-19.736-32.324-3.937-8.283-6.563-16.667-6.598-25.267-.053-13.326 6.725-25.503 20.054-36.61 1.135-.944 2.277-1.881 3.412-2.819z" />
            <path d="M169.47.517c-3.423 2.605-7.05 5.132-10.221 7.821-8.921 7.553-14.476 15.818-16.456 24.767-1.347 6.087-.917 12.163.73 18.218 3.055 11.233 9.868 21.852 17.368 32.372 7.44 10.412 15.095 20.795 22.356 31.231a77.687 77.687 0 0 1 10.171 20.294 31.72 31.72 0 0 1 1.079 4.49c-2.862-10.626-9.05-20.717-16.431-30.6-6.778-9.075-13.96-18.062-20.795-27.138-6.273-8.307-11.846-16.752-15.38-25.56-2.82-7.049-4.23-14.19-3.144-21.425 1.526-10.137 7.775-19.357 18.161-27.734 2.883-2.323 6.14-4.505 9.227-6.753z" />
            <path d="M161.437.517c-6.069 3.641-11.726 7.455-16.473 11.663-10.753 9.544-15.716 19.889-14.715 31.114.758 8.459 4.529 16.544 9.615 24.478 6.055 9.449 13.872 18.482 21.563 27.547 7.546 8.896 15.113 17.788 20.914 27.093 3.704 5.939 6.615 11.983 8.106 18.222.159.666.268 1.335.374 2.005a1.723 1.723 0 0 1-.06.73c-2.538-12.854-11.13-24.672-20.953-36.222-7.719-9.093-15.843-18.08-23.54-27.177-7.303-8.635-13.562-17.489-17.087-26.86-4.339-11.494-3.524-22.744 5.16-33.634 5.523-6.926 13.45-12.95 22.853-18.423l.888-.536Z" />
            <path d="M153.074.517c-1.35.8-2.675 1.611-4.05 2.397-11.331 6.467-20.59 13.657-25.835 22.26-4.36 7.148-5.4 14.469-3.746 21.927 2.287 10.326 8.74 19.952 16.822 29.253 7.264 8.36 15.053 16.565 22.66 24.855 7.605 8.29 14.996 16.593 20.385 25.404 3.25 5.315 5.66 10.736 6.753 16.319a6.408 6.408 0 0 1-.021 1.62c-1.837-10.425-7.966-20.17-16.266-29.482-8.522-9.554-17.686-18.937-26.627-28.393-7.48-7.902-14.73-15.86-20.09-24.28-4.762-7.465-7.937-15.099-7.733-23.064.236-9.404 5.326-17.975 14.655-25.838 5.453-4.582 11.92-8.702 19.092-12.495a4.649 4.649 0 0 0 .67-.497z" />
            <path d="M145.041.517c-1.173.638-2.33 1.29-3.524 1.914-11.462 5.939-21.179 12.572-27.36 20.626-5.235 6.816-7.1 13.921-6.056 21.277 1.311 9.255 6.788 17.862 13.643 26.243 7.085 8.699 15.695 16.96 24.15 25.264 8.343 8.198 16.685 16.4 23.406 25.052 4.843 6.231 8.78 12.618 10.736 19.332a30.31 30.31 0 0 1 .976 12.149 3.877 3.877 0 0 1-.123-.68c.786-9.231-3.172-17.975-9.301-26.452-6.217-8.617-14.377-16.688-22.62-24.745-8.956-8.748-18.018-17.464-26.586-26.328-6.777-7.007-12.085-14.373-15.296-22.152-4.046-9.798-3.584-19.434 3.486-28.827 5.057-6.717 12.688-12.487 21.83-17.671 3.017-1.71 6.193-3.338 9.295-5.002z" />
            <path d="M137.013.517c-3.306 1.682-6.697 3.317-9.9 5.047-11.325 6.098-20.524 12.967-25.684 21.31-4.173 6.745-4.934 13.646-3.056 20.657 2.44 9.163 8.784 17.594 16.294 25.792 9.026 9.85 19.737 19.17 29.846 28.686 8.832 8.328 17.354 16.748 23.459 25.827 4.842 7.197 7.976 14.574 7.493 22.314a16.844 16.844 0 0 1-.941 4.55c.116-.938.222-1.875.352-2.82.768-5.308-.521-10.506-2.883-15.634-3.419-7.426-8.811-14.45-15.624-21.094-9.164-8.928-18.786-17.715-28.316-26.526-8.275-7.655-16.494-15.342-22.962-23.515-4.832-6.112-8.596-12.4-10.154-19.032-2.26-9.63.839-18.701 9.6-27.16 6.585-6.344 15.371-11.695 25.282-16.509 1.286-.627 2.566-1.262 3.845-1.893zM209.29 118.539a10.45 10.45 0 0 1-.283-1.453c-.606-9.917-1.149-19.839-1.804-29.753-1.139-17.179-2.22-34.354-1.939-51.542.18-10.874.91-21.743 1.41-32.613.042-.888.183-1.762.279-2.66h2.343z" />
            <path d="M128.98.517c-4.035 1.97-8.212 3.856-12.078 5.918-13.097 6.982-23.357 14.93-27.548 24.908-2.974 7.073-2.213 14.098.927 21.09 3.683 8.202 9.897 15.86 17.549 23.131 9.139 8.688 18.764 17.221 28.238 25.803 8.638 7.825 17.104 15.698 23.565 24.16 4.039 5.287 7.21 10.726 8.691 16.421 1.375 5.287 1.272 10.542-1.198 15.744.229-.747.465-1.494.684-2.241 2.36-8.124 0-15.963-4.77-23.65-5.088-8.208-12.409-15.839-20.61-23.209-9.851-8.85-20.062-17.58-29.94-26.433-8.685-7.782-16.633-15.783-22.107-24.446-3.411-5.407-5.639-10.926-5.9-16.678-.394-8.991 4.434-17.119 13.492-24.541 7.211-5.904 16.312-10.834 26.272-15.31.472-.212.916-.445 1.374-.667z" />
            <path d="M120.951.517c-3.334 1.526-6.78 2.99-9.981 4.582-10.447 5.245-19.638 11.035-25.757 18.137q-8.374 9.707-6.876 20.442c1.18 8.498 6.372 16.333 13.04 23.921 7.613 8.677 17.038 16.773 26.47 24.869 10.39 8.917 21.016 17.756 29.718 27.23 6.27 6.827 11.412 13.876 13.745 21.464 2.094 6.845 1.438 13.587-2.58 20.196a1.142 1.142 0 0 1-.592.458c.663-1.34 1.41-2.668 1.967-4.022 2.964-7.207 2.023-14.34-1.48-21.414-3.454-6.982-8.953-13.485-15.272-19.798-9.379-9.371-20.364-18.19-30.938-27.138-9.76-8.272-19.339-16.6-26.68-25.63-4.554-5.608-8.09-11.38-9.696-17.489-2.69-10.22 1.184-19.642 11.278-28.312 5.907-5.065 13.206-9.467 21.5-13.393 2.907-1.375 5.868-2.717 8.81-4.078z" />
            <path d="M148.672 164.657c1.247-2.083 2.706-4.135 3.697-6.253 3.323-7.105 2.53-14.19-.8-21.235-3.412-7.218-9.132-13.9-15.688-20.39-7.51-7.436-16.068-14.513-24.654-21.583-10.147-8.346-20.368-16.671-28.9-25.574-6.366-6.65-11.653-13.52-14.342-20.946-4.42-12.255.518-23.304 13.993-33.271 6.65-4.934 14.538-9.189 23.279-12.984 1.378-.6 2.657-1.266 3.982-1.904h3.68c-6.411 3.127-13.125 6.084-19.166 9.414-9.915 5.463-17.46 11.825-21.567 19.297-4.303 7.817-3.69 15.645.067 23.459 3.638 7.556 9.562 14.605 16.714 21.323 6.439 6.048 13.305 11.955 20.09 17.887 6.932 6.055 14.157 12.011 20.973 18.105 8.498 7.596 16.04 15.459 20.844 24.03 3.038 5.42 4.674 10.954 4.18 16.643-.412 4.747-2.407 9.298-5.843 13.66a2.978 2.978 0 0 1-.444.37z" />
            <path d="M104.555.517c-1.994.917-3.972 1.84-5.991 2.746-10.052 4.536-19.092 9.583-26.081 15.652-7.857 6.82-11.984 14.243-11.68 22.342.243 6.471 3.122 12.646 7.4 18.641 5.46 7.673 12.742 14.828 20.757 21.757 10.925 9.456 22.204 18.8 33.056 28.284 7.955 6.95 14.905 14.204 19.807 22 3.722 5.917 5.953 11.983 5.502 18.274-.437 6.055-3.32 11.754-8.564 17.087-.155.159-.353.31-.733.673.088-.331.067-.444.148-.525 9.46-9.773 10.538-19.994 5.392-30.547-3.704-7.577-9.847-14.545-16.854-21.323-9.086-8.762-19.533-17.044-29.676-25.43-9.467-7.83-18.772-15.715-26.275-24.188-5.358-6.048-9.647-12.3-11.6-18.972-2.854-9.742.223-18.874 9.467-27.36 7.754-7.127 18.36-12.82 30.092-17.94.857-.374 1.664-.786 2.496-1.181z" />
            <path d="M96.523.517 90.271 3.38C78.33 8.825 67.855 14.947 60.735 22.623c-8.106 8.737-9.618 17.915-5.9 27.417 3.264 8.332 9.383 16.072 17.27 23.343 8.879 8.17 18.226 16.188 27.562 24.202 9.516 8.177 19.201 16.287 26.864 25.06 5.12 5.864 9.276 11.912 11.306 18.355 3.172 10.115.17 19.6-9.516 28.411a1.153 1.153 0 0 1-.747.3c.254-.261.493-.525.761-.78 5.354-5.106 8.692-10.573 9.63-16.455.954-5.992-.656-11.846-3.807-17.577-4.459-8.106-11.339-15.63-19.452-22.782-9.488-8.364-19.402-16.565-29.183-24.84-8.688-7.339-17.111-14.761-23.847-22.716-5.378-6.344-9.516-12.924-10.9-19.935-1.798-9.163 1.81-17.693 10.594-25.556 7.965-7.126 18.704-12.825 30.582-17.943.43-.183.807-.405 1.209-.61z" />
            <path d="M88.49.517c-2.882 1.34-5.8 2.658-8.652 4.04-11.172 5.367-20.844 11.401-27.286 18.838-6.623 7.644-8.368 15.656-6.027 23.966 2.308 8.205 8.018 15.744 14.894 23.05 8.685 9.235 19.156 17.873 29.366 26.6 8.459 7.232 16.918 14.479 24.03 22.148 6.172 6.636 11.314 13.478 13.908 20.847q6.122 17.387-12.001 31.989-.797.645-1.618 1.282a.853.853 0 0 1-.543.127c.233-.211.448-.43.705-.634 6.796-5.378 11.48-11.24 13.277-17.739 1.516-5.484.828-10.926-1.41-16.322-3.348-8.134-9.667-15.603-16.949-22.856-8.62-8.582-18.585-16.71-28.284-24.922-9.548-8.106-18.98-16.212-26.515-24.953-5.24-6.073-9.431-12.336-11.331-19.032Q39.62 31.38 54.98 18.045c7.69-6.651 17.767-12.068 28.9-16.95.43-.186.822-.401 1.23-.606z" />
            <path d="M80.462.517c-3.066 1.463-6.185 2.89-9.192 4.388C61.8 9.632 53.517 14.89 47.494 21.14q-10.221 10.612-8.625 22.589c1.058 7.99 5.703 15.416 11.68 22.634 6.472 7.817 14.574 15.138 22.716 22.447 9.305 8.354 18.736 16.668 27.66 25.14 7.712 7.321 13.915 15.04 17.524 23.315 5.664 12.984 1.924 25.024-12.184 35.975a2.404 2.404 0 0 1-1.1.518c.617-.522 1.23-1.058 1.854-1.569 6.739-5.639 11.116-11.768 12.498-18.486 1.315-6.39-.183-12.642-3.401-18.789-4.085-7.81-10.313-15.123-17.795-22.088-8.92-8.296-18.278-16.452-27.491-24.653-8.438-7.486-16.72-15.043-23.308-23.1-4.42-5.4-7.92-10.965-9.84-16.801C33.65 36.02 38.556 24.9 51.78 14.84c6.876-5.227 15.187-9.689 24.213-13.774.384-.172.744-.352 1.114-.55z" />
            <path d="M72.437.517c-3.972 2.01-8.075 3.944-11.899 6.034C50.669 11.937 42.584 18 37.484 25.168 32.758 31.8 31.36 38.659 32.8 45.723c1.681 8.25 6.792 15.916 13.125 23.374C53 77.432 61.617 85.31 70.21 93.173c9.104 8.346 18.38 16.639 26.082 25.415 5.991 6.827 10.971 13.844 13.452 21.337q6.123 18.504-13.428 33.938a1.55 1.55 0 0 1-.832.303c.226-.219.423-.451.677-.66 6.993-5.727 11.613-11.965 13.263-18.824 1.49-6.213.412-12.335-2.372-18.387-4-8.698-10.725-16.79-18.74-24.548-8.519-8.247-17.474-16.36-26.257-24.53C53.52 79.275 45.245 71.281 39 62.71c-4.381-6.02-7.634-12.206-8.642-18.68-1.371-8.843 1.811-17.161 9.682-24.929C47.166 12.053 56.957 6.298 67.97 1.085c.38-.18.744-.353 1.114-.554z" />
            <path d="M64.4.517C59.045 3.5 53.405 6.347 48.397 9.491c-9.266 5.847-16.05 12.476-19.56 20.044-3.342 7.2-3.106 14.422-.427 21.654 3.172 8.55 9.03 16.565 16.371 24.256 8.515 8.906 17.563 17.66 26.434 26.469 7.517 7.475 14.83 15 20.495 22.969 4.328 6.093 7.591 12.335 8.765 18.87 1.861 10.337-2.136 19.945-12.149 28.774-1.59 1.41-3.338 2.745-5.019 4.116a.825.825 0 0 1-.539.078c.275-.24.525-.49.825-.705 8.296-6.425 13.745-13.485 15.508-21.334 1.35-6.044.437-12.032-2.066-17.975-3.7-8.765-10.07-16.974-17.735-24.865-8.286-8.526-17.037-16.918-25.588-25.376C45.756 78.603 38.122 70.655 32.41 62.2c-4.582-6.775-7.754-13.746-8.106-20.992-.518-11.057 5.991-20.844 17.996-29.606 5.54-4.06 11.92-7.687 18.754-11.085z" />
            <path d="M56.369.517C49.422 4.165 42.975 8.042 37.48 12.395c-7.863 6.26-13.28 13.065-15.445 20.604-1.49 5.185-1.342 10.37.05 15.554 2.77 10.28 9.54 19.846 17.837 29.09 8.244 9.186 17.03 18.23 25.49 27.358 7.633 8.237 14.725 16.604 19.504 25.507 3.295 6.136 5.336 12.381 5.184 18.8-.253 10.404-6.442 19.719-17.372 28.142a1.057 1.057 0 0 1-.722.177c.444-.381.88-.765 1.336-1.139 6.481-5.353 11.162-11.127 13.597-17.432 2.34-6.066 2.393-12.156.726-18.264-2.703-9.904-9.068-19.166-17.03-28.086-8.582-9.612-17.743-19.068-26.6-28.609-6.985-7.525-13.505-15.155-18.306-23.209-4.134-6.932-6.81-14.01-6.696-21.337.162-11.662 7.616-21.887 20.688-30.93 3.698-2.563 7.92-4.896 11.892-7.339.38-.25.733-.507 1.086-.765Z" />
            <path d="M48.34.517C39.49 5.54 31.806 11.031 26.08 17.301c-8.016 8.78-11.047 18.08-9.63 27.844 1.34 9.24 6.179 17.974 12.3 26.557 7.205 10.087 16.108 19.737 24.489 29.542 7.51 8.776 14.711 17.622 19.698 26.952 3.8 7.12 6.161 14.358 5.844 21.802-.48 10.718-6.746 20.375-17.816 29.14-.117.092-.12.226-.177.353l-.437-.204c.293-.275.564-.56.881-.825 7.782-6.517 12.967-13.587 15.117-21.295 1.685-6.01 1.35-12.019-.402-18.014-3.285-11.25-10.83-21.728-19.385-32.01-7.556-9.096-15.49-18.097-23.032-27.198-6.56-7.905-12.198-16.008-15.797-24.502-2.82-6.658-4.205-13.393-3.12-20.216 1.488-9.386 7.31-17.933 17.098-25.63 3.757-2.954 8.135-5.668 12.223-8.495.264-.183.483-.387.723-.585z" />
            <path d="M39.973.517c-1.95 1.41-3.916 2.799-5.84 4.209-9.679 7.09-16.678 14.873-20.087 23.582-2.467 6.344-2.85 12.745-1.667 19.18 1.865 10.123 7.088 19.787 13.813 29.2 6.644 9.305 13.77 18.508 20.65 27.763 6.344 8.522 12.191 17.136 16.047 26.12 2.576 5.992 4.162 12.09 4.116 18.268-.088 11.7-6.203 22.405-17.953 32.15a1.34 1.34 0 0 1-.705.317 4.462 4.462 0 0 1 .486-.56c7.148-6.217 12.132-12.92 14.74-20.136 1.945-5.37 2.333-10.813 1.624-16.272-1.586-12.195-8.307-23.674-16.413-34.935C41.435 99.182 33.629 89.091 26.227 78.9c-5.787-7.959-10.605-16.097-13.566-24.53-3.877-11.018-3.919-21.955 2.517-32.74 4.48-7.507 11.606-14.21 20.551-20.36a9.83 9.83 0 0 0 .892-.752z" />
            <path d="M31.944.517c-.744.638-1.449 1.29-2.235 1.925-10.09 8.032-17.023 16.805-20.068 26.455a40.031 40.031 0 0 0-1.276 18.486c1.854 11.849 7.754 23.177 14.708 34.36 5.773 9.283 11.846 18.51 17.622 27.79 5.315 8.54 9.671 17.228 12.156 26.166 4.328 15.564 1.272 30.498-12.635 44.497a11.705 11.705 0 0 1-2.242 1.843 4.582 4.582 0 0 1 .43-.57q11.984-12.04 14.207-25.705c1.223-7.486.272-14.919-2.097-22.306-3.94-12.304-11.278-24.065-18.796-35.802-6.09-9.516-12.37-18.99-17.27-28.721-4.529-8.988-7.8-18.091-8.19-27.445Q5.347 19.57 27.7 1.384a11.63 11.63 0 0 0 .896-.867z" />
            <path d="M23.912.517c-1.763 1.872-3.599 3.722-5.287 5.611-7.55 8.46-11.984 17.411-13.517 26.804-1.297 8.036-.458 16.019 1.646 23.984 2.82 10.74 7.994 21.175 13.228 31.608 4.994 9.953 10.037 19.9 14.7 29.902a77.123 77.123 0 0 1 6.848 23.892c1.594 13.887-2.097 27.266-12.998 39.93a2.344 2.344 0 0 1-.955.704c1.149-1.575 2.344-3.14 3.436-4.726 5.393-7.85 8.227-15.977 9.02-24.347.99-10.503-1.456-20.823-5.263-31.072-4.169-11.25-10.076-22.243-15.659-33.282C14.346 80.1 9.781 70.65 6.666 61.012c-2.82-8.77-4.314-17.594-3.43-26.504C4.415 22.736 9.903 11.605 20.036 1.184a7.726 7.726 0 0 0 .532-.667z" />
            <path d="M15.88.517c-2.094 3.19-4.417 6.34-6.246 9.573a60.216 60.216 0 0 0-7.546 22.867c-1.09 8.927-.215 17.806 1.678 26.673 2.181 10.221 5.892 20.28 9.516 30.357 3.693 10.256 7.539 20.498 10.81 30.793 3.171 9.992 4.845 20.09 4.437 30.25-.452 11.149-3.641 22.032-10.606 32.543a1.575 1.575 0 0 1-.722.63c.863-1.586 1.762-3.172 2.58-4.758a65.82 65.82 0 0 0 6.53-21.316 70.634 70.634 0 0 0-.87-23.755 229.399 229.399 0 0 0-6.182-23.417C15.442 99.133 10.8 87.386 7.03 75.554c-2.361-7.402-3.954-14.888-5.547-22.36C.008 46.28-.15 39.322.558 32.368c1.103-10.816 4.8-21.344 11.8-31.498a2.35 2.35 0 0 0 .176-.353z" />
            <path d="M-.52 52.36c.19 1.275.384 2.55.567 3.827C.89 61.92 1.552 67.666 2.574 73.39 3.71 79.628 5.12 85.852 6.423 92.084c1.487 7.14 3.133 14.277 4.427 21.429 1.265 6.971 2.365 13.957 3.172 20.95.902 7.704 1.216 15.43.232 23.138-.743 5.854-1.85 11.7-3.13 17.53-.74 3.384-2.132 6.725-3.235 10.087l-.437-.042c.391-1.283.881-2.559 1.15-3.849 1.233-5.92 2.667-11.838 3.524-17.777 1.244-8.558 1.085-17.14.306-25.708-.599-6.601-1.515-13.195-2.537-19.783-.98-6.312-2.235-12.61-3.447-18.909-1.223-6.344-2.612-12.705-3.807-19.067C1.701 75.063.956 70.03.104 65.002c-.173-1.03-.423-2.055-.638-3.084ZM7.85.517C6.254 4.827 4.298 9.11 3.15 13.456 1.693 18.96.889 24.519-.192 30.053c-.092.45-.215.898-.325 1.35V18.535a1.84 1.84 0 0 0 .272-.427Q2.14 9.297 4.502.518zM-.52 112.29c.095.853.26 1.705.275 2.558.215 13.5.486 26.998.57 40.497.036 5.23-.352 10.464-.584 15.698a4.677 4.677 0 0 1-.261.99ZM209.29 183.985a4.508 4.508 0 0 1-.321-1.24c.07-1.918.207-3.832.32-5.746zM207.798 142.311c.734 13.02-.208 25.98-4.747 38.808.959-4.268 2.115-8.525 2.82-12.804a118.55 118.55 0 0 0 1.459-12.998c.24-4.332.053-8.67.053-13.01z" />
            <path d="M205.367 144.535c1 11.825-.98 23.473-7.384 34.84 5.79-11.391 7.849-23.026 7.384-34.84zM202.191 156.018c-.684 6.979-3.362 13.728-7.82 20.273a57.657 57.657 0 0 0 7.82-20.273zM186.43 145.84v4.934h-.096v-4.935zM148.774 164.706l-2.654 3.21-.38-.098 2.932-3.172z" />
          </g>
          <path
            d="M290.77 282.49a228.39 228.39 0 0 1 228.39 228.39v189.49H329.67A228.39 228.39 0 0 1 101.28 472a189.49 189.49 0 0 1 189.49-189.51Z"
            className="cls-13"
            style={{
              fill: palette.secondary,
              filter: "url(#page1-a)",
            }}
            transform="matrix(-.35245 0 0 -.35245 217.8 341.096)"
          />
          <path
            d="M232.16 282.49a228.39 228.39 0 0 1 228.4 228.39v189.49H271.07A228.39 228.39 0 0 1 42.68 472a189.49 189.49 0 0 1 189.48-189.51Z"
            style={{
              fill: palette.primary,
              filter: "url(#page1-b)",
            }}
            transform="rotate(-135 87.147 170.728) scale(.35245)"
          />
          <path
            d="M240.22 173.69h18.07a228.39 228.39 0 0 1 228.4 228.4v207.56H279.13a228.39 228.39 0 0 1-228.39-228.4v-18.07a189.49 189.49 0 0 1 189.48-189.49Z"
            style={{
              fill: palette.backgroundLight,
              filter: "url(#page1-c)",
            }}
            transform="matrix(.35245 0 0 .35245 -.873 -5.312)"
          ></path>

          <ClippedImage
            id="saphire-page-1"
            triggerImageEdit={triggerImageEdit}
            x={17}
            y={55}
            width={155}
            height={155}
            imageNumber={1}
            imageRatio={1}
            notebook={notebook}
          >
            <path
              d="M240.22 173.69h18.07a228.39 228.39 0 0 1 228.4 228.4v207.56H279.13a228.39 228.39 0 0 1-228.39-228.4v-18.07a189.49 189.49 0 0 1 189.48-189.49Z"
              style={{
                fill: palette.backgroundLight,
              }}
              transform="matrix(.33245 0 0 .33245 4.5 2)"
            ></path>
          </ClippedImage>

          <path
            d="M268.71 507.32h327.58v158.11H304.78a36.07 36.07 0 0 1-36.07-36.07v-122z"
            style={{
              fill: palette.backgroundDark,
              filter: "url(#page1-d)",
            }}
            transform="matrix(.35245 0 0 .35245 -.873 -5.312)"
          />

          <foreignObject
            x={104.055}
            y={180.06}
            width={100}
            height={47}
            className="clickable"
          >
            <div>
              <div
                style={{
                  fontSize: "8.9259px",
                  fontFamily: "Heavitas",
                  color: palette.secondary,
                  strokeWidth: 0.352449,
                }}
                onClick={() =>
                  triggerFieldEdit({
                    currentValue: notebook.candidate_ballout_box_name,
                    field: "candidate_ballout_box_name",
                    label: "Nome de Urna",
                    type: "input",
                    options: [],
                    onUpdateField: updateField,
                    validate: validateText,
                  })
                }
              >
                {notebook.candidate_ballout_box_name}
              </div>
              <div
                style={{
                  fontSize: "6.34409px",
                  fontFamily: "MontserratAlternates",
                  color: palette.backgroundLight,
                  strokeWidth: 0.352449,
                }}
                onClick={() =>
                  triggerFieldEdit({
                    currentValue: notebook.office,
                    field: "office",
                    label: "Cargo",
                    type: "select",
                    options: NotebooksOfficesOptions,
                    onUpdateField: updateField,
                    validate: validateText,
                  })
                }
              >
                {notebook.gender === "Feminino"
                  ? `Candidata a ${toFeminine(notebook.office)?.toLowerCase()}`
                  : `Candidato a ${notebook.office?.toLowerCase()}`}
              </div>
              <div
                style={{
                  fontSize: "6.34409px",
                  fontFamily: "MontserratAlternates",
                  color: palette.backgroundLight,
                  strokeWidth: 0.352449,
                }}
                onClick={() =>
                  triggerFieldEdit({
                    currentValue: notebook.age,
                    field: "age",
                    label: "Idade",
                    type: "input",
                    options: [],
                    onUpdateField: updateField,
                    validate: validateText,
                  })
                }
              >
                {`${notebook.age} anos`}
              </div>
            </div>
          </foreignObject>

          <path
            d="M181.6 540.59h17.51a57.17 57.17 0 0 1 57.17 57.17V656a3.65 3.65 0 0 1-3.65 3.65h-58.2a57.17 57.17 0 0 1-57.17-57.17v-17.55a44.34 44.34 0 0 1 44.34-44.34z"
            style={{
              fill: palette.backgroundLight,
              filter: "url(#page1-e)",
            }}
            transform="matrix(.35245 0 0 .35245 -.873 -5.312)"
          />

          <PartyDisplay
            notebook={notebook}
            x={160}
            y={250}
            width={40}
            height={40}
            triggerFieldEdit={triggerFieldEdit}
            updateField={updateField}
          />
        </svg>
      </PaperInside>
    </Paper>
  );
};

export default Page1;
