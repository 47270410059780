import { memo } from "react";
import "./title-text.css";

type Props = {
  children: string;
  className?: string;
};

const TitleText: React.FC<Props> = ({ children, className }) => {
  return <span className={`title-text ${className}`}>{children}</span>;
};

export default memo(TitleText);
