import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Attribute from "../../../../app/attribute";
import { Mode, Status } from "../../../../app/constants";
import { AppDispatch } from "../../../../app/store";
import { toPtBr } from "../../../../app/utils";
import NumberWidget from "../../../../components/dashboard/number-widget/number-widget";
import {
  getLeftNumberWidgetData,
  getRightNumberWidgetData,
  selectAttributes,
  selectDashboardComparativeFilters,
  selectDashboardFilters,
  selectLeftNumberWidgetAttribute,
  selectLeftWidgetData,
  selectLeftWidgetDataStatus,
  selectRightNumberWidgetAttribute,
  selectRightWidgetData,
  selectRightWidgetDataStatus,
  selectZoneMode,
  setLeftWidgetAttribute,
  setRightWidgetAttribute,
} from "../../../../features/dashboard/slice";
import {
  QueryResult,
  ScalarAttributeData,
} from "../../../../features/dashboard/types";
import TargetService from "../../../../features/target/service";
import { selectTargetUpdateCounter } from "../../../../features/target/slice";

export enum WidgetSide {
  LEFT = "left",
  RIGHT = "right",
}

type Props = {
  side: WidgetSide;
  mode?: Mode;
};

const getValidAttribute = (side: WidgetSide, attributes: Attribute[]) => {
  const selectedAttributes = attributes.filter((a) => a.type === "scalar");
  return side === WidgetSide.LEFT
    ? selectedAttributes[0]
    : selectedAttributes[1];
};

const updateMapStatus = (dataLoadingStatus, setStatus) => {
  setStatus(dataLoadingStatus);
};

const NumberWidgetWithData: React.FC<Props> = ({ side, mode }) => {
  const dispatch: AppDispatch = useDispatch();
  const attributes: Attribute[] = useSelector(selectAttributes);
  const dashFilters = useSelector(selectDashboardFilters);
  const dashComparativeFilters = useSelector(selectDashboardComparativeFilters);
  const targetUpdateCounter: number = useSelector(selectTargetUpdateCounter);
  const zoneMode: boolean = useSelector(selectZoneMode);
  const [status, setStatus] = useState(Status.LOADING);

  const data: QueryResult = useSelector(
    side === WidgetSide.LEFT ? selectLeftWidgetData : selectRightWidgetData
  );

  const dataStatus = useSelector(
    side === WidgetSide.LEFT
      ? selectLeftWidgetDataStatus
      : selectRightWidgetDataStatus
  );

  const attribute = useSelector(
    side === WidgetSide.LEFT
      ? selectLeftNumberWidgetAttribute
      : selectRightNumberWidgetAttribute
  );

  const dataSource = data?.scenario_result as ScalarAttributeData;

  useEffect(() => {
    const updateStatus = () => {
      if ([Status.LOADING, Status.SUCCEEDED].includes(dataStatus)) {
        updateMapStatus(dataStatus, setStatus);
      }
    };

    updateStatus();
    // eslint-disable-next-line
  }, [dataStatus]);

  useEffect(() => {
    const setInitialAttribute = () => {
      if (attributes) {
        const newAttribute = getValidAttribute(side, attributes);
        if (newAttribute) {
          const setAttributeAction =
            side === WidgetSide.LEFT
              ? setLeftWidgetAttribute
              : setRightWidgetAttribute;

          dispatch(setAttributeAction(newAttribute));
        }
      }
    };

    setInitialAttribute();
  }, [dispatch, mode, side, attribute, attributes]);

  useEffect(() => {
    const fetchData = () => {
      if (attribute) {
        const getDataAction =
          side === WidgetSide.LEFT
            ? getLeftNumberWidgetData
            : getRightNumberWidgetData;

        dispatch(
          getDataAction({
            attribute: attribute,
            filters: dashFilters,
            comparativeFilters: dashComparativeFilters,
            comparativeMode: targetUpdateCounter,
            zoneMode,
            service: new TargetService(),
          })
        );
      }
    };

    fetchData();
  }, [
    dispatch,
    mode,
    side,
    zoneMode,
    attribute,
    dashFilters,
    dashComparativeFilters,
    targetUpdateCounter,
  ]);

  return (
    <NumberWidget
      attribute={attribute}
      attributes={attributes}
      value={toPtBr(dataSource?.value)}
      onAttributeSelected={(newAttribute) => {
        const setAttributeAction =
          side === WidgetSide.LEFT
            ? setLeftWidgetAttribute
            : setRightWidgetAttribute;
        setStatus(Status.LOADING);
        dispatch(setAttributeAction(newAttribute));
      }}
      status={status}
    />
  );
};

export default NumberWidgetWithData;
