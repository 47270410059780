import { ChangeEventHandler, memo } from "react";
import { GoSearch } from "react-icons/go";
import "./shop-search-input.css";

type Props = {
  className?: string;
  value?: string;
  type?: string;
  placeHolder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

const ShopSearchInput: React.FC<Props> = ({
  className,
  value,
  type,
  placeHolder,
  onChange,
}) => {
  return (
    <div className={`shop-search-input-container ${className}`}>
      <input
        onChange={onChange}
        value={value}
        type={type}
        placeholder={placeHolder}
        className={`shop-search-input`}
      />
      <GoSearch className={"shop-search-input-icon"} />
    </div>
  );
};

export default memo(ShopSearchInput);
