import React, { memo } from "react";
import { COLORS, COLOR_MODE, SIZE_MODES } from "../../../../app/constants";
import { toFilterValue } from "../../../../app/filter";
import { makeContainer } from "../../../../components/container/container";
import SettingsRange from "../../../../components/inputs/settings-range/settings-range";
import SettingsSelect from "../../../../components/inputs/settings-select/settings-select";
import SettingsSwitch from "../../../../components/inputs/settings-switch/settings-switch";
import { Settings } from "../../../../features/settings/type";
import "./mark-settings-form.css";

type Props = {
  settings: Settings;
  onSettingsFieldChange: (field: string, value: any) => void;
};

const MarkSettingsContainer = makeContainer("mark-settings-container");
const MarkSettingsLineContainer = makeContainer("mark-settings-line-container");

const MarkSettingsForm: React.FC<Props> = ({
  settings,
  onSettingsFieldChange,
}) => {
  return (
    <MarkSettingsContainer>
      <MarkSettingsLineContainer>
        <SettingsSwitch
          label="Habilitar Marcadores"
          value={settings?.enable_marker}
          onChange={(value) => {
            onSettingsFieldChange("enable_marker", value);
          }}
        />
      </MarkSettingsLineContainer>
      <MarkSettingsLineContainer>
        <SettingsSelect
          label="Modo de Tamanho"
          value={toFilterValue(settings?.marker_size_mode, SIZE_MODES)}
          options={SIZE_MODES}
          onChange={(selected) => {
            onSettingsFieldChange("marker_size_mode", selected.value);
          }}
        />
        <SettingsRange
          label="Tamanho"
          value={settings?.marker_size}
          min={1}
          max={20}
          onChange={(value) => {
            onSettingsFieldChange("marker_size", value);
          }}
        />
      </MarkSettingsLineContainer>
      <MarkSettingsLineContainer>
        <SettingsSelect
          label="Modo de Cor"
          value={toFilterValue(settings?.marker_color_mode, COLOR_MODE)}
          options={COLOR_MODE}
          onChange={(selected) => {
            onSettingsFieldChange("marker_color_mode", selected.value);
          }}
        />
        <SettingsSelect
          label="Cor"
          className="mark-settings-select"
          value={toFilterValue(settings?.marker_color, COLORS)}
          options={COLORS}
          onChange={(selected) => {
            onSettingsFieldChange("marker_color", selected.value);
          }}
        />
      </MarkSettingsLineContainer>
    </MarkSettingsContainer>
  );
};

export default memo(MarkSettingsForm);
