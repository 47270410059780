import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  NotebookPages,
  NotebookPalettesOptions,
  NotebooksTemplateOptions,
  Status,
} from "../../app/constants";
import { debounceThunk } from "../../app/utils";
import {
  CreateNotebookRequest,
  INotebookService,
  Notebook,
  UpdateImageRequest,
  UpdateNotebookRequest,
} from "./type";

const initialNotebook: string | null = null;

// Slice's initial state
const initialState = {
  currentPage: NotebookPages.NOTEBOOK_VIEW,
  template: NotebooksTemplateOptions[0],
  palette: NotebookPalettesOptions[0],
  getCurrentNotebookStatus: Status.IDLE,
  currentNotebook: initialNotebook,
  notebookStatus: Status.IDLE,
  notebookUploadImageStatus: Status.IDLE,
};

type NotebookGetNotebookDetailsParams = {
  service: INotebookService;
};

type CreateNotebookParams = {
  service: INotebookService;
  request: CreateNotebookRequest;
};

type UpdateNotebookParams = {
  service: INotebookService;
  request?: UpdateNotebookRequest;
};

type UpdateNotebookImageParams = {
  service: INotebookService;
  notebook: Notebook;
  request: UpdateImageRequest;
};

const getNotebookDetailsThunk = createAsyncThunk<
  Notebook,
  NotebookGetNotebookDetailsParams
>("notebook/getnotebookdetails", async (params) => {
  const { service } = params;
  return await service.getNotebookDetails();
});

const createNotebookThunk = createAsyncThunk<Notebook, CreateNotebookParams>(
  "notebook/createnotebook",
  async (params) => {
    const { service, request } = params;
    return await service.createNotebook(request);
  }
);

const updateNotebookThunk = createAsyncThunk<Notebook, UpdateNotebookParams>(
  "notebook/updatenotebook",
  async (params) => {
    const { service, request } = params;
    return await service.updateNotebook(request);
  }
);

const updateNotebookImageThunk = createAsyncThunk<
  Notebook,
  UpdateNotebookImageParams
>("notebook/updatenotebookimage", async (params) => {
  const { service, notebook, request } = params;
  return await service.updateImage(notebook, request);
});

// Selectors
export const selectCurrentPage = (state) => state.notebook.currentPage;
export const selectGetCurrentNotebookStatus = (state) =>
  state.notebook.getCurrentNotebookStatus;
export const selectCurrentNotebook = (state) => state.notebook.currentNotebook;
export const selectNotebookStatus = (state) => state.notebook.notebookStatus;
export const selectUploadNotebookImageStatus = (state) =>
  state.notebook.notebookUploadImageStatus;
export const selectTemplate = (state) => state.notebook.template;
export const selectPalette = (state) => state.notebook.palette;

// Reducers
const loadingGetCurrentNotebook = (state, action) => {
  state.getCurrentNotebookStatus = Status.LOADING;
};

const loadingNotebookOperation = (state, action) => {
  state.notebookStatus = Status.LOADING;
};

const loadingNotebooImagekUpdate = (state, action) => {
  state.notebookUploadImageStatus = Status.LOADING;
};

const sucessGetCurrentNotebook = (state, action) => {
  state.getCurrentNotebookStatus = Status.SUCCEEDED;
  state.currentNotebook = action.payload;
};

const sucessNotebookOperation = (state, action) => {
  state.notebookStatus = Status.SUCCEEDED;
};

const successNotebooImagekUpdate = (state, action) => {
  state.notebookUploadImageStatus = Status.SUCCEEDED;
};

const rejectedGetCurrentNotebook = (state, action) => {
  state.getCurrentNotebookStatus = Status.FAILED;
};

const rejectedNotebookOperation = (state, action) => {
  state.notebookStatus = Status.FAILED;
};

const rejectNotebooImagekUpdate = (state, action) => {
  state.notebookUploadImageStatus = Status.FAILED;
};

// Slice
const notebookSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setCurrentNotebook(state, action) {
      state.currentNotebook = action.payload;
    },
    setTemplate(state, action) {
      state.template = action.payload;
    },
    setPalette(state, action) {
      state.palette = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getNotebookDetailsThunk.pending, loadingGetCurrentNotebook)
      .addCase(getNotebookDetailsThunk.fulfilled, sucessGetCurrentNotebook)
      .addCase(getNotebookDetailsThunk.rejected, rejectedGetCurrentNotebook)
      .addCase(createNotebookThunk.pending, loadingNotebookOperation)
      .addCase(createNotebookThunk.fulfilled, sucessNotebookOperation)
      .addCase(createNotebookThunk.rejected, rejectedNotebookOperation)
      .addCase(updateNotebookThunk.pending, loadingNotebookOperation)
      .addCase(updateNotebookThunk.fulfilled, sucessNotebookOperation)
      .addCase(updateNotebookThunk.rejected, rejectedNotebookOperation)
      .addCase(updateNotebookImageThunk.pending, loadingNotebooImagekUpdate)
      .addCase(updateNotebookImageThunk.fulfilled, successNotebooImagekUpdate)
      .addCase(updateNotebookImageThunk.rejected, rejectNotebooImagekUpdate);
  },
});

export const { setCurrentPage, setCurrentNotebook, setTemplate, setPalette } =
  notebookSlice.actions;
export const getNotebookDetails = debounceThunk(getNotebookDetailsThunk);
export const createNotebook = debounceThunk(createNotebookThunk);
export const updateNotebook = debounceThunk(updateNotebookThunk);
export const updateNotebookImage = debounceThunk(updateNotebookImageThunk);

export default notebookSlice.reducer;
