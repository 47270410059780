import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MapsTypes, MarkColors, Status } from "../../app/constants";
import { debounceThunk } from "../../app/utils";
import { ISettingsService, Settings } from "./type";

// Slice's initial state
const initialState = {
  status: Status.IDLE,
  settings: {
    tiles_server: MapsTypes.GOOGLE_MAPS,
    sticky_tooltip: false,
    enable_choropleth_map: true,
    color_scale: "COLD_COLORS-DATAELEGE",
    enable_choropleth_map_borders: false,
    choropleth_map_opacity: 80,
    enable_marker: false,
    marker_size_mode: "FIXED_SIZE",
    marker_size: 20,
    marker_color: MarkColors.WHITE,
    marker_color_mode: "FIXED_COLOR",
    enable_heatmap: false,
    show_onboarding: false,
  },
  error: "",
};

// get settings thunk
const getSettingsThunk = createAsyncThunk<Settings, ISettingsService>(
  "settings/getsettings",
  async (service) => {
    const response = await service.getSettings();
    return response;
  }
);

// update settings thunk
const updateSettingsThunk = createAsyncThunk<
  Settings,
  { settings: Settings; service: ISettingsService }
>("settings/updatesettings", async (params) => {
  const { settings, service } = params;
  await service.updateSettings(settings);

  const response = await service.getSettings();
  return response;
});

// Selectors
export const selectSettings = (state) => state.settings.settings;

// Reducers
const loading = (state, action) => {
  state.status = Status.LOADING;
};

const successfull = (state, action) => {
  state.status = Status.SUCCEEDED;
  state.settings = action.payload;
};

const rejected = (state, action) => {
  state.status = Status.FAILED;
  state.error = action.error.message;
};

// Slice
const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getSettingsThunk.pending, loading)
      .addCase(getSettingsThunk.fulfilled, successfull)
      .addCase(getSettingsThunk.rejected, rejected)
      .addCase(updateSettingsThunk.pending, loading)
      .addCase(updateSettingsThunk.fulfilled, successfull)
      .addCase(updateSettingsThunk.rejected, rejected);
  },
});

export const getSettings = debounceThunk(getSettingsThunk);
export const updateSettings = debounceThunk(updateSettingsThunk);
export default settingsSlice.reducer;
