import * as React from "react";
import { useSelector } from "react-redux";
import { validateText } from "../../../../../app/utils";
import { selectPalette } from "../../../../../features/notebook/slice";
import { ClippedImage, Paper, PaperInside } from "../commons/commons";
import { TemplatePageProps } from "../commons/interfaces";
import bestContrast from "../commons/utils";

const Page6: React.FC<TemplatePageProps> = ({
  className,
  notebook,
  triggerImageEdit,
  triggerFieldEdit,
  updateField,
}) => {
  const { palette } = useSelector(selectPalette);
  return (
    <Paper className={className}>
      <PaperInside>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
        >
          <defs>
            <clipPath id="page6-a">
              <path d="M1076.44 1474.42a134.4 134.4 0 0 1 134.4 134.4v111.51h-111.5a134.4 134.4 0 0 1-134.4-134.4 111.51 111.51 0 0 1 111.51-111.51z" />
            </clipPath>
            <clipPath id="page6-b">
              <path d="M726.84 1470.51H616.29v-110.55a133.24 133.24 0 0 1 133.24-133.24 110.55 110.55 0 0 1 110.55 110.5 133.24 133.24 0 0 1-133.24 133.29z" />
            </clipPath>
            <clipPath id="page6-c">
              <path d="M1076.19 1003.95a135.3 135.3 0 0 1 135.3 135.3v112.25h-112.25a135.3 135.3 0 0 1-135.3-135.3 112.25 112.25 0 0 1 112.25-112.25z" />
            </clipPath>
          </defs>
          <path
            d="M-1.281.059h212.443v296.933H-1.281z"
            style={{
              fill: palette.secondary,
              strokeWidth: 0.354782,
            }}
          />
          <g
            style={{
              opacity: 0.13,
            }}
          >
            <path d="M25.278 232.48a14.232 14.066 0 0 0-6.177-.645 36.812 36.812 0 0 0-1.028 2.095l-.132.304c-.057.127-.118.278-.143.331l-.31.762c3.058-.822 6.113-.511 9.157.614a74.206 74.206 0 0 1-1.367-3.46zM31.923 201.824a16.085 15.896 0 0 1 1.613-.73c-3.001-3.238-6.084-5.717-9.307-6.846-5.567-1.95-10.817.706-15.703 8.285-3.676 5.682-6.78 13.265-9.546 21.818a157.313 157.313 0 0 1-1.096 3.322v2.9c.97-2.854 1.92-5.77 2.92-8.543 3.525-9.766 7.494-17.716 12.322-22.17 3.901-3.601 7.891-4.233 11.945-2.635 2.36.924 4.632 2.536 6.852 4.6z" />
            <path d="M37.612 200.054a15.8 15.8 0 0 1 1.427-.103c-2.601-3.7-5.274-6.997-8.069-9.632-3.126-2.948-6.324-4.878-9.635-5.093-5.197-.352-9.897 3.823-14.19 11.64-3.415 6.22-6.267 14.079-8.854 22.688-.121.406-.257.79-.386 1.185v2.889c1.139-3.485 2.23-7.09 3.423-10.426 4.036-11.286 8.633-20.16 14.393-23.775 4.09-2.571 8.155-1.912 12.194.797 3.366 2.275 6.596 5.671 9.697 9.83z" />
            <path d="M36.659 191.166c2.141 2.821 4.24 5.921 6.292 9.17.464.11.92.236 1.367.384-3.18-5.315-6.424-10.299-9.854-14.46-3.244-3.933-6.58-6.984-10.114-8.37-5.913-2.32-11.36 1.023-16.373 9.745-2.93 5.097-5.475 11.4-7.738 18.552-.795 2.511-1.573 5.065-2.359 7.6v2.886c.886-2.875 1.728-5.848 2.656-8.613 3.03-9.015 6.377-16.93 10.481-22.22q5.61-7.227 11.81-5.936c4.914 1.033 9.446 5.523 13.832 11.262z" />
            <path d="M48.49 200.823c-.157.331-.31.666-.46 1.001-2.531-4.327-5.068-8.63-7.649-12.729-3.886-6.175-7.965-11.286-12.34-14.429-4.519-3.24-9.044-3.77-13.562-.056-4.315 3.545-7.99 10.059-11.153 18.615-1.923 5.213-3.633 11.008-5.442 16.542v-3.174c.357-1.147.753-2.25 1.1-3.44 2.198-7.543 4.66-14.35 7.494-20.103 5.763-11.639 12.155-15.871 19.243-12.076 4.282 2.31 8.265 6.888 12.112 12.386 3.687 5.266 7.195 11.27 10.657 17.463z" />
            <path d="M49.717 196.05c.218.392.432.784.646 1.175l-.439.787q-2.559-4.487-5.121-8.93c-4.008-6.92-8.148-13.206-12.584-17.914-3.465-3.683-7.037-6.173-10.78-6.377-4.683-.261-8.976 3.298-12.92 10.08-3.508 6.038-6.424 13.84-9.05 22.516-.525 1.732-1.071 3.443-1.589 5.16v-2.888c.229-.706.468-1.411.682-2.155 2.962-10.126 6.256-19.265 10.375-25.97 4.907-7.981 10.188-10.637 15.824-8.172 3.858 1.686 7.473 5.39 10.97 10.01 4.9 6.48 9.461 14.524 13.986 22.679z" />
            <path d="M52.433 193.892c-.175.261-.357.522-.517.787-3.926-6.836-7.887-13.636-11.899-20.143-4.204-6.818-8.68-12.098-13.497-14.916-5.496-3.21-10.803-1.89-15.856 5.093-4.44 6.137-7.98 15.166-11.128 25.493q-.831 2.698-1.656 5.397v-2.889c.118-.353.247-.674.357-1.058 2.959-10.25 6.256-19.518 10.378-26.393 4.543-7.583 9.472-10.697 14.775-9.145 4.054 1.182 7.852 4.768 11.527 9.41 4.6 5.812 8.894 13.082 13.137 20.583 1.46 2.61 2.92 5.206 4.38 7.781z" />
            <path d="M54.546 190.77q-.225.318-.44.646c-.142-.247-.285-.494-.424-.74-5.046-8.818-10.042-17.854-15.381-25.349-4.226-5.936-8.587-10.863-13.33-12.856-4.818-2.02-9.45-.515-13.872 5.202-4.3 5.559-7.787 13.907-10.892 23.55-.792 2.469-1.552 4.973-2.327 7.463v-2.888c.118-.353.243-.706.357-1.059 2.823-9.603 5.957-18.301 9.804-24.939q7.698-13.29 16.705-9.442c3.858 1.637 7.495 5.256 10.996 9.78 5.05 6.505 9.753 14.645 14.428 22.887 1.457 2.572 2.913 5.167 4.376 7.746z" />
            <path d="M56.509 188.143c-2.527-4.18-5.047-8.405-7.57-12.598-4.229-7.03-8.461-14.024-12.983-19.61-4.172-5.157-8.465-9.17-13.087-10.08-4.621-.91-8.972 1.562-13.062 7.445-3.615 5.199-6.655 12.344-9.39 20.52-.866 2.592-1.69 5.29-2.537 7.932v-2.885c.108-.32.218-.628.318-.96 2.363-7.794 4.943-14.964 7.966-20.9 5.817-11.42 12.248-15.656 19.332-12.175 3.376 1.657 6.591 4.676 9.714 8.49 4.66 5.695 9.022 12.837 13.358 20.103 2.805 4.705 5.603 9.449 8.412 14.136z" />
            <path d="M59.114 185.23c-2.573-4.003-5.107-8.183-7.652-12.345-4.553-7.406-9.104-14.852-13.918-20.957-4.315-5.467-8.747-9.876-13.519-11.329-4.086-1.241-8.05-.035-11.887 4.035-4.147 4.402-7.652 11.396-10.764 19.9-1.21 3.3-2.33 6.838-3.49 10.27v-2.889c.107-.317.218-.631.321-.959C1.217 161.454 4.558 153 8.623 146.849c4.493-6.796 9.303-9.54 14.414-8.355 3.75.867 7.327 3.675 10.806 7.456 4.96 5.392 9.593 12.535 14.18 19.899 3.896 6.253 7.772 12.609 11.69 18.77-.203.212-.403.413-.6.61z" />
            <path d="M62.262 180.736c.257.374.517.745.774 1.111-.21.156-.421.307-.632.47-1.98-2.741-3.925-5.644-5.888-8.557-5.093-7.647-10.157-15.455-15.307-22.806-4.44-6.348-9.082-11.392-14.025-14.107-4.183-2.314-8.358-2.519-12.523.352-4.375 3.03-8.208 8.885-11.584 16.884-1.824 4.317-3.47 9.188-5.197 13.812v-2.889c1.967-5.897 4.062-11.403 6.424-16.189 5.075-10.348 10.735-15.98 17.13-15.519 4.201.297 8.209 3.034 12.134 6.984 4.893 4.938 9.486 11.519 14.033 18.386 4.875 7.36 9.728 14.92 14.66 22.068z" />
            <path d="m67.376 179.227-.596.303a136.207 134.61 0 0 1-8.476-10.764c-5.282-7.305-10.51-14.884-15.824-22.002-5.314-7.117-10.874-13.014-16.82-15.402-2.998-1.203-6-1.33-8.997-.039-4.357 1.873-8.297 6.546-11.913 13.336-2.52 4.726-4.76 10.302-6.87 16.302v-3.175c.15-.31.304-.6.443-.938 1.427-3.428 2.762-7.054 4.243-10.263 5.232-11.287 11.142-17.716 17.887-17.854 4.24-.088 8.33 2.218 12.337 5.788 4.64 4.14 9.072 9.773 13.423 15.8 5.517 7.644 10.984 15.551 16.545 22.958 1.57 2.091 3.158 4.052 4.768 5.84z" />
            <path d="M72.6 175.936a48.91 48.91 0 0 1-.878-.677c-5.396-4.303-10.51-10.518-15.584-17-5.664-7.237-11.253-14.92-17.084-21.137-4.957-5.29-10.014-9.46-15.346-10.617-5.65-1.22-11.031 1.411-16.106 8.314C3.976 139.756.8 146.39-2.105 154.026v-3.174c.114-.208.232-.398.339-.628 1.634-3.527 3.212-7.304 4.91-10.545 4.45-8.465 9.393-13.473 14.822-14.76 3.95-.935 7.851.257 11.691 2.694 4.91 3.104 9.597 7.985 14.172 13.642 5.26 6.511 10.467 13.357 15.727 19.882 4.611 5.717 9.28 10.912 14.19 14.284-.393.162-.77.332-1.146.515z" />
            <path d="M-2.12 146.807c.81-1.68 1.617-3.38 2.434-5.04 4.1-8.356 8.601-14.397 13.637-17.335a13.69 13.53 0 0 1 11.092-1.44c5.852 1.609 11.441 6.116 16.887 11.922 5.378 5.735 10.707 11.882 16.06 17.822 4.928 5.466 9.91 10.524 15.106 13.85 3.473 2.226 6.988 3.594 10.564 3.555 6.78-.077 12.955-5.357 18.594-15.497a1.563 1.545 0 0 0 .182-.596 3.69 3.647 0 0 0-.325.42c-3.594 6.172-7.473 10.475-11.642 12.725a13.561 13.403 0 0 1-9.41 1.41c-7.053-1.364-13.694-7.166-20.204-14.164-5.902-6.348-11.748-13.081-17.64-19.48-4.604-4.994-9.308-9.17-14.186-11.709-6.37-3.337-12.698-3.379-18.933 2.173-4.343 3.88-8.208 10.016-11.777 17.737-.135.293-.289.515-.435.769z" />
            <path d="M-2.12 139.876c.357-.642.746-1.252 1.107-1.926 4.64-8.711 9.725-14.693 15.306-17.32A15.703 15.519 0 0 1 25 119.525c6.852 1.6 13.404 6.7 19.87 12.697 5.354 4.983 10.707 10.228 16.06 15.212 4.94 4.585 9.96 8.344 15.132 10.492 9 3.732 17.634 1.097 25.731-10.905a10.639 10.514 0 0 0 1.07-1.936 3.137 3.1 0 0 0-.331.37q-6.96 10.341-14.864 12.263c-4.33 1.058-8.626.237-12.901-1.81-7.113-3.403-13.919-9.74-20.7-16.223-5.499-5.255-10.98-10.676-16.609-14.905-5.196-3.908-10.46-6.73-15.87-7.054q-12.676-.794-23.197 18.502c-.16.293-.336.519-.503.773z" />
            <path d="M-2.12 132.946c1.071-1.524 2.142-3.108 3.244-4.56 4.9-6.519 10.068-10.36 15.5-11.668 4.64-1.118 9.279-.395 13.872 1.41 6.21 2.445 12.244 6.9 18.276 11.418 5.756 4.31 11.509 8.662 17.294 12.697 4.529 3.153 9.143 5.11 13.815 5.911 8.03 1.376 15.767-1.81 23.09-11.22a2.195 2.17 0 0 0 .4-.821c-.91.991-1.817 2.024-2.734 2.963-4.536 4.655-9.24 7.103-14.079 7.787-6.067.854-12.041-1.255-17.969-4.543-6.499-3.6-12.848-8.7-19.236-13.519-5.45-4.112-10.913-8.052-16.488-10.74-5.07-2.443-10.178-3.724-15.345-2.962-6.81 1.009-13.248 5.753-19.272 14.5a5.11 5.05 0 0 1-.385.458z" />
            <path d="M-2.12 126.015c1.846-1.813 3.666-3.802 5.536-5.393 4.304-3.671 8.722-5.692 13.204-6.51 5.164-.942 10.3-.187 15.425 1.446 5.91 1.883 11.73 5.086 17.558 8.218 5.928 3.174 11.852 6.503 17.805 9.328 5.778 2.74 11.616 4.183 17.487 3.827 6.445-.388 12.74-3.142 18.818-9.17a1.278 1.263 0 0 0 .357-.624c-.917.747-1.83 1.538-2.751 2.229-4.037 3.037-8.148 4.825-12.327 5.643a27.366 27.045 0 0 1-13.736-.751 99.295 98.131 0 0 1-13.54-5.333c-6.842-3.298-13.633-7.304-20.475-10.56-4.282-2.035-8.611-3.41-12.93-4.786a25.93 25.627 0 0 0-12.044-.797c-6.253.949-12.345 4.137-18.201 10.182a1.324 1.309 0 0 1-.203.152z" />
          </g>
          <text
            x={12.07}
            y={27.155}
            style={{
              fontSize: "14.3758px",
              fontFamily: "Heavitas",
              fill: palette.terciary,
              strokeWidth: 0.354782,
            }}
            transform="scale(1.00591 .99412)"
          >
            {"MEUS"}
            <tspan
              style={{
                strokeWidth: 0.354782,
                fill: palette.primary,
              }}
            >
              <tspan
                x={12.07}
                y={44.404}
                style={{
                  strokeWidth: 0.354782,
                }}
              >
                {"PROJETOS"}
              </tspan>
            </tspan>
          </text>

          <ClippedImage
            id="saphire-page-6-1"
            triggerImageEdit={triggerImageEdit}
            x={122}
            y={44}
            width={88}
            height={88}
            imageNumber={5}
            imageRatio={1}
            notebook={notebook}
          >
            <path
              d="M1076.19 1003.95a135.3 135.3 0 0 1 135.3 135.3v112.25h-112.25a135.3 135.3 0 0 1-135.3-135.3 112.25 112.25 0 0 1 112.25-112.25z"
              style={{ fill: palette.backgroundLight }}
              transform="matrix(.35688 0 0 .3527 -221.222 -309.762)"
            />
          </ClippedImage>

          <ClippedImage
            id="saphire-page-6-2"
            triggerImageEdit={triggerImageEdit}
            x={0}
            y={122}
            width={88}
            height={88}
            imageNumber={6}
            imageRatio={1}
            notebook={notebook}
          >
            <path
              d="M726.84 1470.51H616.29v-110.55a133.24 133.24 0 0 1 133.24-133.24 110.55 110.55 0 0 1 110.55 110.5 133.24 133.24 0 0 1-133.24 133.29z"
              style={{ fill: palette.backgroundLight }}
              transform="matrix(.35688 0 0 .3527 -221.222 -309.762)"
            />
          </ClippedImage>

          <ClippedImage
            id="saphire-page-6-3"
            triggerImageEdit={triggerImageEdit}
            x={122}
            y={210}
            width={88}
            height={88}
            imageNumber={7}
            imageRatio={1}
            notebook={notebook}
          >
            <path
              d="M1076.44 1474.42a134.4 134.4 0 0 1 134.4 134.4v111.51h-111.5a134.4 134.4 0 0 1-134.4-134.4 111.51 111.51 0 0 1 111.51-111.51z"
              style={{ fill: palette.backgroundLight }}
              transform="matrix(.35688 0 0 .3527 -221.222 -309.762)"
            />
          </ClippedImage>

          <foreignObject
            x={10.005}
            y={55.032}
            width={110}
            height={60}
            className="clickable"
          >
            <div
              style={{
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: bestContrast(
                  palette.secondary,
                  palette.terciary,
                  palette.backgroundLight
                ),
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                height: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.projects_1,
                  field: "projects_1",
                  label: "Projeto",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.projects_1}
            </div>
          </foreignObject>

          <foreignObject
            x={94.005}
            y={140.032}
            width={110}
            height={60}
            className="clickable"
          >
            <div
              style={{
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: bestContrast(
                  palette.secondary,
                  palette.terciary,
                  palette.backgroundLight
                ),
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                height: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.projects_2,
                  field: "projects_2",
                  label: "Projeto",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.projects_2}
            </div>
          </foreignObject>

          <foreignObject
            x={10.005}
            y={230.032}
            width={110}
            height={60}
            className="clickable"
          >
            <div
              style={{
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: bestContrast(
                  palette.secondary,
                  palette.terciary,
                  palette.backgroundLight
                ),
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                height: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.projects_3,
                  field: "projects_3",
                  label: "Projeto",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.projects_3}
            </div>
          </foreignObject>

          <path
            d="M114.152.613s25.392-.54 48.771 9.77c22.23 9.801 35.01 19.123 41.459 20.866 8.119 2.197 6.68-.152 6.68-.152V.059Z"
            style={{
              fill: palette.backgroundLight,
              strokeWidth: 0.354782,
            }}
          />
        </svg>
      </PaperInside>
    </Paper>
  );
};

export default Page6;
