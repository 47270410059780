import { memo } from "react";
import "./dashboard-filter-text.css";

type Props = {
  children: string;
  className?: string;
};

const DashboardFilterText: React.FC<Props> = ({ children, className }) => {
  return <div className={`dashboard-filter-text ${className}`}>{children}</div>;
};

export default memo(DashboardFilterText);
