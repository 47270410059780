import { memo } from "react";
import "./banner-content-text.css";

type Props = {
  children: string;
  className?: string;
};

const BannerContentText: React.FC<Props> = ({ children, className }) => {
  return <span className={`banner-content-text ${className}`}>{children}</span>;
};

export default memo(BannerContentText);
