import { useSelector } from "react-redux";
import {
  NotebooksOfficesOptions,
  toFeminine,
} from "../../../../../app/constants";
import { validateText } from "../../../../../app/utils";
import { selectPalette } from "../../../../../features/notebook/slice";
import {
  ClippedImage,
  Paper,
  PaperInside,
  PartyDisplay,
} from "../commons/commons";
import { TemplatePageProps } from "../commons/interfaces";

const Page1: React.FC<TemplatePageProps> = ({
  className,
  notebook,
  triggerImageEdit,
  triggerFieldEdit,
  updateField,
}) => {
  const { palette } = useSelector(selectPalette);

  return (
    <Paper className={className}>
      <PaperInside>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlSpace="preserve"
          id="page1_svg__svg1"
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
        >
          <defs id="page1_svg__defs1">
            <filter id="page1_svg__filter-6-4">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <filter id="page1_svg__filter-7-6">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <filter id="page1_svg__filter-10-5">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <filter id="page1_svg__filter-5-2">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <filter id="page1_svg__filter-4-1">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <clipPath id="page1_svg__clip-path-2">
              <path
                id="page1_svg__rect8"
                d="M450.24 687.26H806.9v637.5H450.24z"
                style={{
                  fill: "none",
                }}
                transform="rotate(180 628.57 1006.015)"
              />
            </clipPath>
            <style id="style1">{".page1_svg__cls-9{opacity:.13}"}</style>
          </defs>
          <g id="page1_svg__layer1">
            <path
              id="page1_svg__rect635"
              d="M-.059.185h210.73v298.03H-.059z"
              style={{
                fill: palette.backgroundLight,
                strokeWidth: 0.354,
              }}
            />
            <g
              id="page1_svg__g664"
              style={{
                clipPath: "url(#page1_svg__clip-path-2)",
              }}
              transform="translate(-74.973 -170.082)scale(.354)"
            >
              <g
                id="page1_svg__CZJK68-4"
                className="page1_svg__cls-9"
                data-name="CZJK68"
                style={{
                  opacity: 0.13,
                }}
              >
                <g id="page1_svg__g663">
                  <path
                    id="page1_svg__path635"
                    d="M514.41 701.49a390.5 390.5 0 0 1 20.52 72.59 449.6 449.6 0 0 1 5.72 119.86c-2.82 32.95-7.32 65.86-12.19 98.75-4.39 29.64-10.39 59.21-15.48 88.83-4.68 27.18-9.28 54.38-13.52 81.58-1.91 12.23-2.87 24.51-3.91 36.76 4.2-23 8.05-46 12.71-69 4-19.62 8.86-39.19 13.35-58.78 5.23-22.82 10.74-45.62 15.64-68.46 4.42-20.65 8.76-41.32 12.09-62 3-19 5.78-38 6.71-57a620 620 0 0 0 0-69.76 316.6 316.6 0 0 0-28.88-113.32z"
                  />
                  <path
                    id="page1_svg__path636"
                    d="M544.91 701.49a260.2 260.2 0 0 1 38.15 74.6c11.18 34.4 13.44 69.1 10.86 103.89a365.5 365.5 0 0 1-15.72 82.79c-15.11 48.16-31.78 96.17-46.82 144.33-8.42 27-14.91 54.17-22.17 81.28a52.7 52.7 0 0 0-1.85 13.5c2.28-8.93 4.11-17.9 6.95-26.78 7.67-24 15.11-48 23.87-71.91 14.69-40 31.38-79.86 45.22-120 9-26.09 15.26-52.5 21.22-78.87 6.31-28 7-56.13 3.59-84.26-4.83-39.68-20-78.1-47.48-115a22 22 0 0 1-1.84-3.6z"
                  />
                  <path
                    id="page1_svg__path637"
                    d="M576.67 701.49c6.33 7.19 12.87 14.33 18.93 21.58 22.81 27.31 37.65 55.86 44.8 85.66 8.38 35 6.11 69.8-2.41 104.63-9.41 38.51-26.29 76.13-44 113.66-19.33 40.87-39 81.71-57 122.76-8.05 18.34-12.42 37.18-18.4 55.8a5.2 5.2 0 0 0 .26 3c14.54-54.52 44.11-107 72.3-159.58 19.52-36.48 38.85-73 52.24-110.43 11.2-31.2 18.14-62.64 17-94.51-1.76-49.71-24-96.41-68.76-139.75a27 27 0 0 1-2.27-2.82z"
                  />
                  <path
                    id="page1_svg__path638"
                    d="M607.17 701.49c9.9 8.91 20.39 17.64 29.6 26.76q47 46.44 55.84 99.42c5.45 32.24.17 64.06-11 95.68-15.74 44.45-42.39 87.15-69 129.85-23.91 38.3-48.48 76.51-65.61 116-5.48 12.62-9.72 25.4-14.44 38.12a6.9 6.9 0 0 0 0 2.71c5.58-13.32 10.75-27 17.12-40.58 15.93-33.85 37.61-66.7 59.47-99.49 27-40.54 55-80.9 74.9-122.74 14.94-31.45 24.92-63.29 25-95.95.2-50.61-25.53-96.85-76.15-139-4.3-3.58-8.64-7.16-13-10.73z"
                  />
                  <path
                    id="page1_svg__path639"
                    d="M637.66 701.49c13 9.88 26.76 19.48 38.83 29.69 33.92 28.68 55 60.07 62.47 94.06 5.11 23.11 3.49 46.18-2.77 69.18-11.6 42.65-37.42 83-66 122.92-28.26 39.54-57.33 78.91-84.89 118.59a295.3 295.3 0 0 0-38.57 77.07 122 122 0 0 0-4.11 17.06c10.87-40.36 34.38-78.67 62.4-116.19 25.74-34.47 53-68.6 79-103 23.82-31.54 45-63.6 58.41-97.05 10.77-26.82 16.08-53.89 11.94-81.36-5.8-38.49-29.53-73.5-69-105.31-10.94-8.83-23.31-17.11-35-25.64z"
                  />
                  <path
                    id="page1_svg__path640"
                    d="M668.16 701.49c23.06 13.82 44.53 28.3 62.55 44.29 40.84 36.23 59.69 75.51 55.88 118.14-2.87 32.11-17.19 62.82-36.5 93-23 35.88-52.69 70.18-81.88 104.59-28.66 33.79-57.39 67.56-79.43 102.89-14.06 22.56-25.11 45.51-30.78 69.19-.61 2.53-1 5.07-1.43 7.62a6.6 6.6 0 0 0 .23 2.77c9.64-48.81 42.27-93.62 79.57-137.55 29.3-34.52 60.15-68.65 89.38-103.19 27.75-32.79 51.52-66.42 65-102 16.48-43.64 13.39-86.37-19.58-127.72-21-26.29-51.09-49.16-86.78-70l-3.48-2z"
                  />
                  <path
                    id="page1_svg__path641"
                    d="M699.92 701.49c5.12 3 10.16 6.11 15.38 9.09 43 24.56 78.18 51.87 98.1 84.53 16.55 27.15 20.51 54.94 14.23 83.26-8.69 39.22-33.19 75.77-63.88 111.08-27.59 31.75-57.17 63-86 94.39s-57 63-77.41 96.46c-12.34 20.19-21.49 40.76-25.64 62a23.6 23.6 0 0 0 .08 6.16c7-39.59 30.25-76.58 61.76-111.94 32.34-36.3 67.16-71.92 101.11-107.78 28.41-30 55.93-60.2 76.33-92.19 18.09-28.34 30.14-57.34 29.38-87.58-.9-35.7-20.23-68.27-55.66-98.11-20.7-17.43-45.26-33-72.5-47.44a19 19 0 0 1-2.53-1.89z"
                  />
                  <path
                    id="page1_svg__path642"
                    d="M730.42 701.49c4.47 2.42 8.85 4.9 13.41 7.26 43.53 22.55 80.43 47.75 103.9 78.31 19.87 25.89 27 52.88 23 80.81-5 35.15-25.79 67.82-51.81 99.65-27 33-59.63 64.4-91.73 95.94-31.68 31.13-63.36 62.26-88.89 95.13-18.38 23.66-33.33 47.9-40.76 73.4a114.8 114.8 0 0 0-3.7 46.13 13.5 13.5 0 0 0 .46-2.58c-3-35 12.05-68.19 35.33-100.45 23.59-32.71 54.58-63.37 85.89-94 34-33.22 68.41-66.32 101-100 25.73-26.61 45.89-54.59 58.08-84.12 15.37-37.21 13.61-73.8-13.24-109.46-19.19-25.51-48.14-47.41-82.89-67.11-11.46-6.49-23.51-12.66-35.29-19z"
                  />
                  <path
                    id="page1_svg__path643"
                    d="M760.91 701.49c12.55 6.38 25.39 12.59 37.59 19.16 43 23.15 77.94 49.23 97.53 80.91 15.85 25.62 18.68 51.83 11.6 78.45-9.25 34.8-33.35 66.81-61.87 97.94-34.27 37.4-75 72.79-113.32 108.92-33.55 31.63-65.9 63.6-89.09 98.07-18.39 27.33-30.29 55.35-28.44 84.73a63 63 0 0 0 3.57 17.24c-.45-3.55-.85-7.11-1.36-10.67-2.92-20.15 2-39.88 10.94-59.36 13-28.2 33.44-54.88 59.34-80.1 34.79-33.9 71.33-67.26 107.51-100.72 31.45-29.07 62.64-58.21 87.22-89.3 18.34-23.21 32.63-47.09 38.55-72.27 8.58-36.56-3.18-71-36.45-103.13-25-24.13-58.36-44.4-96-62.69-4.89-2.38-9.74-4.79-14.61-7.18z"
                  />
                  <path
                    id="page1_svg__path644"
                    d="M791.41 701.49c15.32 7.51 31.19 14.64 45.87 22.51 49.7 26.5 88.69 56.69 104.6 94.51 11.3 26.86 8.4 53.55-3.51 80.08-14 31.15-37.59 60.22-66.64 87.83-34.7 33-71.26 65.4-107.23 98-32.8 29.72-65 59.61-89.49 91.76-15.32 20.09-27.37 40.72-33 62.34-5.22 20.06-4.82 40 4.55 59.79-.87-2.84-1.76-5.67-2.59-8.51-9-30.85 0-60.62 18.1-89.8 19.33-31.18 47.13-60.15 78.27-88.13 37.41-33.61 76.18-66.76 113.7-100.33 33-29.54 63.15-59.91 83.93-92.81 13-20.52 21.46-41.53 22.41-63.33 1.49-34.14-16.84-65-51.23-93.18-27.38-22.49-61.94-41.22-99.81-58.22-1.8-.8-3.49-1.68-5.22-2.52z"
                  />
                  <path
                    id="page1_svg__path645"
                    d="M821.9 701.49c12.66 5.8 25.74 11.34 37.91 17.43 39.66 19.88 74.56 41.87 97.8 68.82q31.8 36.85 26.11 77.56c-4.48 32.27-24.2 62-49.48 90.83-28.91 33-64.69 63.7-100.5 94.43-39.46 33.86-79.82 67.43-112.86 103.4-23.81 25.92-43.33 52.69-52.14 81.51-8 26-5.46 51.59 9.8 76.68a4.3 4.3 0 0 0 2.24 1.74c-2.51-5.08-5.36-10.13-7.47-15.26-11.25-27.37-7.68-54.46 5.62-81.33 13.12-26.5 34-51.2 58-75.17 35.62-35.59 77.34-69.07 117.49-103.1 37.06-31.41 73.43-63 101.31-97.33 17.3-21.28 30.72-43.21 36.82-66.4 10.21-38.83-4.5-74.59-42.87-107.51-22.42-19.24-50.15-35.95-81.62-50.82-11-5.22-22.29-10.32-33.44-15.48z"
                  />
                  <path
                    id="page1_svg__path646"
                    d="M716.63 1324.76c-4.73-7.9-10.27-15.7-14-23.74-12.62-27-9.6-53.88 3-80.63 13-27.41 34.68-52.79 59.56-77.43 28.53-28.24 61-55.11 93.63-82 38.52-31.7 77.34-63.31 109.71-97.11 24.19-25.26 44.33-51.35 54.49-79.54 16.78-46.54-2-88.5-53.13-126.35-25.27-18.69-55.21-34.88-88.41-49.3-5.23-2.27-10.08-4.81-15.11-7.22h-14c24.35 11.86 49.84 23.1 72.78 35.74 37.65 20.75 66.3 44.9 81.9 73.23 16.34 29.67 14 59.4-.26 89.07-13.81 28.74-36.31 55.51-63.47 81-24.44 23-50.52 45.39-76.31 67.91-26.32 23-53.75 45.62-79.65 68.76-32.26 28.83-60.9 58.7-79.14 91.24-11.54 20.59-17.74 41.6-15.87 63.2 1.56 18 9.14 35.31 22.18 51.87a11.6 11.6 0 0 0 1.69 1.41z"
                  />
                  <path
                    id="page1_svg__path647"
                    d="M884.16 701.49c7.57 3.47 15.09 7 22.71 10.42 38.18 17.22 72.5 36.39 99.06 59.44 29.83 25.89 45.49 54.08 44.35 84.83-.91 24.57-11.85 48-28 70.79-20.73 29.13-48.38 56.3-78.81 82.62-41.53 35.9-84.31 71.37-125.53 107.39-30.2 26.39-56.59 53.94-75.2 83.54-14.14 22.48-22.61 45.5-20.89 69.39 1.65 23 12.6 44.63 32.52 64.89.59.6 1.27 1.18 2.77 2.56-.33-1.26-.25-1.69-.55-2-35.92-37.12-40-75.92-20.48-116 14-28.72 37.35-55.23 64-80.94 34.5-33.26 74.17-64.71 112.68-96.56 36-29.73 71.28-59.67 99.78-91.84 20.34-23 36.63-46.71 44-72 10.84-37-.85-71.68-36-103.9C991.12 747 950.9 725.41 906.35 706c-3.26-1.43-6.32-3-9.48-4.48z"
                  />
                  <path
                    id="page1_svg__path648"
                    d="M914.66 701.49q11.87 5.43 23.75 10.86c45.34 20.67 85.15 43.92 112.15 73.08 30.72 33.18 36.52 68 22.4 104.1-12.39 31.65-35.63 61-65.62 88.64-33.71 31-69.21 61.47-104.65 91.91-36.14 31-72.93 61.85-102 95.15-19.45 22.27-35.23 45.24-42.94 69.71-12.1 38.4-.64 74.41 36.11 107.88a4.35 4.35 0 0 0 2.84 1.13c-1-1-1.88-2-2.9-3-20.33-19.39-33-40.15-36.55-62.48-3.63-22.78 2.49-45 14.45-66.75 16.92-30.8 43-59.35 73.86-86.51 36-31.75 73.67-63 110.81-94.32 33-27.87 65-56.05 90.55-86.26 20.43-24.13 36.2-49.07 41.41-75.69 6.81-34.84-6.89-67.19-40.25-97-30.24-27.07-71-48.71-116.12-68.13-1.63-.71-3.06-1.55-4.58-2.32z"
                  />
                  <path
                    id="page1_svg__path649"
                    d="M945.15 701.49c11 5.08 22 10.08 32.83 15.27 42.42 20.38 79.15 43.28 103.62 71.53 25.14 29 31.77 59.45 22.88 91.07-8.77 31.16-30.45 59.79-56.56 87.53-33 35.06-72.74 67.87-111.52 101-32.15 27.47-64.17 55-91.24 84.1-23.43 25.21-43 51.19-52.81 79.17q-23.25 66 45.57 121.47 3 2.44 6.15 4.86a3.18 3.18 0 0 0 2.06.49c-.89-.8-1.71-1.64-2.68-2.41-25.81-20.42-43.59-42.68-50.42-67.36-5.76-20.82-3.15-41.48 5.29-62 12.71-30.9 36.71-59.25 64.36-86.79 32.73-32.6 70.57-63.45 107.4-94.65 36.26-30.72 72.06-61.59 100.69-94.75 19.89-23 35.81-46.86 43-72.19q16.83-59.13-41.55-109.71c-29.2-25.25-67.46-45.83-109.72-64.36-1.63-.72-3.12-1.53-4.67-2.3z"
                  />
                  <path
                    id="page1_svg__path650"
                    d="M975.65 701.49c11.65 5.55 23.49 11 34.9 16.66 36 17.94 67.42 37.91 90.29 61.65q38.81 40.28 32.75 85.76c-4.09 30.35-21.66 58.54-44.35 86-24.58 29.68-55.35 57.48-86.27 85.25-35.32 31.71-71.14 63.28-105 95.46-29.28 27.79-52.84 57.1-66.54 88.53-21.5 49.3-7.31 95 46.27 136.6a9.06 9.06 0 0 0 4.17 2c-2.34-2-4.67-4-7-5.94-25.59-21.41-42.21-44.69-47.46-70.21-5-24.25.69-48 12.91-71.33 15.52-29.66 39.16-57.44 67.58-83.88 33.87-31.51 69.4-62.48 104.41-93.62 32-28.48 63.44-57.12 88.48-87.72 16.79-20.5 30.07-41.63 37.36-63.79 15.32-46.53-3.32-88.75-53.57-127-26.12-19.85-57.66-36.79-91.94-52.29-1.46-.66-2.82-1.39-4.22-2.09z"
                  />
                  <path
                    id="page1_svg__path651"
                    d="M1006.14 701.49c15.1 7.62 30.67 15 45.19 22.91 37.42 20.45 68.18 43.46 87.55 70.69 17.92 25.19 23.22 51.23 17.75 78-6.39 31.34-25.79 60.44-49.83 88.76-26.87 31.65-59.59 61.53-92.21 91.43-34.58 31.68-69.8 63.17-99.05 96.5-22.74 25.92-41.66 52.56-51.08 81q-23.25 70.26 51 128.86a5.86 5.86 0 0 0 3.16 1.16c-.85-.83-1.61-1.71-2.58-2.5-26.54-21.75-44.09-45.44-50.35-71.48-5.67-23.59-1.57-46.85 9-69.82 15.2-33 40.74-63.76 71.16-93.22 32.35-31.32 66.36-62.13 99.71-93.14 32.4-30.12 63.82-60.52 87.54-93.1 16.64-22.85 29-46.33 32.81-71 5.22-33.57-6.87-65.16-36.76-94.66-27.06-26.7-64.26-48.63-106.06-68.41-1.44-.68-2.82-1.4-4.23-2.1z"
                  />
                  <path
                    id="page1_svg__path652"
                    d="M1036.64 701.49c20.34 11.33 41.77 22.14 60.77 34.11 35.18 22.16 60.95 47.34 74.28 76.07 12.69 27.34 11.79 54.76 1.61 82.23-12 32.46-34.29 63-62.15 92.1-32.34 33.83-66.69 67.07-100.33 100.51-28.54 28.38-56.32 57-77.82 87.22-16.44 23.14-28.83 46.86-33.29 71.65-7.06 39.26 8.11 75.75 46.13 109.27 6 5.32 12.68 10.43 19.07 15.62a3 3 0 0 0 2 .31c-1-.92-2-1.86-3.12-2.74-31.51-24.39-52.23-51.2-58.89-81-5.13-23-1.66-45.7 7.84-68.2 14.06-33.28 38.24-64.45 67.35-94.41 31.46-32.38 64.69-64.24 97.16-96.33 30.21-29.85 59.2-60 80.89-92.13 17.38-25.74 29.43-52.14 30.73-79.72 2-42-22.8-79.15-68.33-112.48-21-15.41-45.27-29.18-71.22-42.08z"
                  />
                  <path
                    id="page1_svg__path653"
                    d="M1067.13 701.49c26.39 13.84 50.93 28.57 71.72 45.1 29.87 23.75 50.43 49.61 58.68 78.24 5.66 19.68 5.1 39.37-.19 59-10.5 39.06-36.25 75.37-67.75 110.47-31.32 34.89-64.67 69.22-96.8 103.89-29 31.28-55.91 63-74.06 96.84-12.51 23.31-20.27 47-19.68 71.4 1 39.5 24.46 74.88 66 106.86a4 4 0 0 0 2.75.67c-1.69-1.44-3.35-2.9-5.08-4.33-24.61-20.32-42.38-42.24-51.63-66.19-8.88-23-9.09-46.15-2.76-69.34 10.26-37.61 34.44-72.79 64.67-106.66 32.59-36.5 67.37-72.41 101-108.63 26.53-28.58 51.29-57.58 69.52-88.14 15.69-26.32 25.85-53.19 25.47-81-.62-44.29-28.93-83.11-78.56-117.46-14.05-9.73-30.07-18.59-45.15-27.86-1.49-.91-2.76-1.93-4.13-2.9z"
                  />
                  <path
                    id="page1_svg__path654"
                    d="M1097.63 701.49c33.6 19.07 62.78 39.92 84.52 63.73 30.44 33.34 41.94 68.66 36.56 105.77-5.08 35.1-23.46 68.31-46.7 100.85-27.35 38.3-61.16 75-93 112.18-28.52 33.32-55.86 66.91-74.79 102.34-14.43 27-23.4 54.48-22.14 82.75 1.81 40.7 25.61 77.36 67.65 110.65.44.34.46.85.67 1.28l1.66-.78c-1.11-1-2.14-2.12-3.35-3.13-29.6-24.69-49.26-51.53-57.46-80.79-6.39-22.82-5.12-45.64 1.53-68.4 12.48-42.72 41.12-82.51 73.57-121.55 28.7-34.54 58.82-68.73 87.46-103.29 24.9-30 46.37-60.8 60-93.05 10.71-25.27 16-50.82 11.84-76.77-5.65-35.64-27.76-68.09-64.92-97.32-14.27-11.21-30.89-21.52-46.41-32.25-1-.7-1.84-1.48-2.75-2.22z"
                  />
                  <path
                    id="page1_svg__path655"
                    d="M1129.39 701.49c7.4 5.32 14.87 10.61 22.18 16 36.76 26.94 63.34 56.48 76.28 89.55 9.42 24.09 10.82 48.39 6.32 72.83-7.07 38.44-26.91 75.13-52.44 110.89-25.23 35.33-52.29 70.27-78.41 105.41-24.06 32.37-46.31 65.07-60.94 99.19-9.79 22.82-15.81 45.9-15.64 69.37.34 44.43 23.56 85.08 68.18 122.08a5.07 5.07 0 0 0 2.62 1.2 18 18 0 0 0-1.85-2.12c-27.14-23.61-46.07-49.07-56-76.46-7.38-20.4-8.86-41.06-6.16-61.79 6-46.3 31.54-89.9 62.32-132.65 27.9-38.76 57.55-77.14 85.7-115.85 22-30.22 40.26-61.11 51.51-93.14 14.69-41.83 14.87-83.37-9.55-124.31-17-28.52-44.08-54-78-77.32a40 40 0 0 1-3.39-2.85z"
                  />
                  <path
                    id="page1_svg__path656"
                    d="M1159.89 701.49c2.82 2.42 5.51 4.89 8.47 7.26 38.32 30.54 64.65 63.85 76.21 100.5 7.34 23.26 8.53 46.67 4.85 70.19-7 45-29.44 88-55.86 130.48-21.92 35.25-45 70.29-66.92 105.53-20.18 32.43-36.72 65.41-46.16 99.36-16.43 59.09-4.84 115.79 48 169a44.8 44.8 0 0 0 8.51 7 17 17 0 0 0-1.63-2.16q-45.49-45.72-53.95-97.62c-4.65-28.41-1-56.64 8-84.7 15-46.71 42.86-91.37 71.38-135.94 23.12-36.13 47-72.12 65.58-109.07 17.19-34.13 29.61-68.7 31.1-104.22q3.48-83.22-81.41-152.28a46 46 0 0 1-3.4-3.29z"
                  />
                  <path
                    id="page1_svg__path657"
                    d="M1190.38 701.49c6.71 7.1 13.67 14.12 20.07 21.3 28.66 32.16 45.57 66.11 51.32 101.78 4.93 30.52 1.74 60.83-6.25 91.08-10.76 40.77-30.35 80.4-50.23 120-19 37.8-38.11 75.58-55.82 113.55a292.7 292.7 0 0 0-26 90.72c-6 52.74 8 103.54 49.36 151.63a8.9 8.9 0 0 0 3.62 2.62c-4.36-6-8.9-11.92-13-18-20.48-29.8-31.25-60.67-34.25-92.45-3.77-39.89 5.52-79.06 20-118 15.85-42.68 38.28-84.42 59.48-126.33 18.1-35.78 35.43-71.66 47.25-108.27 10.76-33.3 16.39-66.82 13-100.64-4.43-44.71-25.3-87-63.79-126.54-.78-.81-1.34-1.69-2-2.53z"
                  />
                  <path
                    id="page1_svg__path658"
                    d="M1220.88 701.49c8 12.1 16.72 24.07 23.73 36.34a228.7 228.7 0 0 1 28.64 86.83c4.14 33.9.82 67.62-6.37 101.29-8.29 38.78-22.37 77-36.15 115.28-14 38.94-28.62 77.82-41 116.92-12.06 37.94-18.41 76.27-16.85 114.87 1.71 42.33 13.83 83.66 40.27 123.57a5.9 5.9 0 0 0 2.75 2.39c-3.28-6-6.76-12-9.81-18.07a249.9 249.9 0 0 1-24.79-80.93 268 268 0 0 1 3.31-90.21 870 870 0 0 1 23.46-88.91c14.5-44.91 32.12-89.52 46.45-134.45 9-28.11 15-56.53 21.06-84.9 5.59-26.25 6.2-52.67 3.51-79.07-4.18-41.08-18.21-81.06-44.8-119.62a11 11 0 0 1-.66-1.33z"
                  />
                  <path
                    id="page1_svg__path659"
                    d="M1283.14 898.34c-.72 4.84-1.45 9.69-2.15 14.53-3.15 21.78-5.71 43.59-9.59 65.33-4.23 23.7-9.68 47.33-14.61 71-5.65 27.13-11.89 54.21-16.81 81.38-4.8 26.47-9 53-12.08 79.55-3.43 29.25-4.62 58.59-.89 87.86 2.83 22.23 7 44.43 11.88 66.56 2.82 12.86 8.11 25.54 12.29 38.31l1.66-.16c-1.48-4.87-3.34-9.71-4.36-14.61-4.68-22.49-10.13-45-13.41-67.51-4.72-32.49-4.12-65.09-1.17-97.62 2.28-25.07 5.76-50.11 9.65-75.12 3.71-24 8.47-47.88 13.08-71.8 4.65-24.14 9.92-48.25 14.45-72.4 3.58-19.07 6.41-38.18 9.64-57.27.66-3.91 1.6-7.81 2.42-11.71z"
                  />
                  <path
                    id="page1_svg__path660"
                    d="M1251.37 701.49c6.07 16.37 13.5 32.63 17.86 49.13 5.53 20.9 8.56 42 12.69 63 .34 1.72.81 3.42 1.22 5.13V769.9a7 7 0 0 1-1-1.62q-9.06-33.39-18-66.79z"
                  />
                  <path
                    id="page1_svg__path661"
                    d="M1283.14 1125.91c-.36 3.24-1 6.48-1 9.72-.82 51.25-1.86 102.51-2.18 153.77-.13 19.87 1.4 39.74 2.23 59.61a18 18 0 0 0 1 3.77z"
                  />
                  <path
                    id="page1_svg__path662"
                    d="M573.26 1253.31v18.75h.36v-18.75z"
                  />
                  <path
                    id="page1_svg__path663"
                    d="m716.25 1325 10.08 12.2 1.44-.38-11.14-12z"
                  />
                </g>
              </g>
            </g>
            <path
              id="page1_svg__path664"
              d="M610.61 672.81c115.428 0 209 93.572 209 209v180.67H639c-115.428 0-209-93.572-209-209 0-99.758 80.852-180.637 180.61-180.67"
              style={{
                fill: palette.secondary,
                filter: "url(#page1_svg__filter-4-1)",
              }}
              transform="rotate(180 183.693 222.114)scale(.354)"
            />
            <path
              id="page1_svg__path665"
              d="M556 672.81c115.428 0 209 93.572 209 209v180.67H584.34c-115.428 0-209-93.572-209-209 0-99.777 80.883-180.664 180.66-180.67"
              style={{
                fill: palette.primary,
                filter: "url(#page1_svg__filter-5-2)",
              }}
              transform="rotate(-135 129.122 237.641)scale(.354)"
            />
            <path
              id="page1_svg__path666"
              d="M579.25 593.18h188.48v188.48c.006 120.446-97.634 218.09-218.08 218.09-104.099 0-188.486-84.391-188.48-188.49 0-120.442 97.638-218.08 218.08-218.08"
              style={{
                fill: palette.backgroundLight,
                filter: "url(#page1_svg__filter-6-4)",
              }}
              transform="translate(-74.973 -170.082)scale(.354)"
            />
            <ClippedImage
              id="emerald-page-1"
              triggerImageEdit={triggerImageEdit}
              x={50}
              y={40}
              width={155}
              height={155}
              imageNumber={1}
              imageRatio={1}
              notebook={notebook}
            >
              <path
                id="page1_svg__path666"
                d="M579.25 593.18h188.48v188.48c.006 120.446-97.634 218.09-218.08 218.09-104.099 0-188.486-84.391-188.48-188.49 0-120.442 97.638-218.08 218.08-218.08"
                style={{
                  fill: palette.backgroundLight,
                }}
                transform="translate(-66.973 -159.082)scale(.340)"
              />
            </ClippedImage>

            <path
              id="page1_svg__path667"
              d="M245.14 965.28h311.59v158.12H209.07v-122a36.07 36.07 0 0 1 36.07-36.12"
              style={{
                fill: palette.backgroundDark,
                filter: "url(#page1_svg__filter-7-6)",
              }}
              transform="rotate(180 98.062 284.654)scale(.354)"
            />

            <foreignObject
              x={10.055}
              y={177.06}
              width={100}
              height={47}
              className="clickable"
            >
              <div>
                <div
                  style={{
                    fontSize: "8.9259px",
                    fontFamily: "Heavitas",
                    color: palette.secondary,
                    strokeWidth: 0.352449,
                  }}
                  onClick={() =>
                    triggerFieldEdit({
                      currentValue: notebook.candidate_ballout_box_name,
                      field: "candidate_ballout_box_name",
                      label: "Nome de Urna",
                      type: "input",
                      options: [],
                      onUpdateField: updateField,
                      validate: validateText,
                    })
                  }
                >
                  {notebook.candidate_ballout_box_name}
                </div>
                <div
                  style={{
                    fontSize: "6.34409px",
                    fontFamily: "MontserratAlternates",
                    color: palette.backgroundLight,
                    strokeWidth: 0.352449,
                  }}
                  onClick={() =>
                    triggerFieldEdit({
                      currentValue: notebook.office,
                      field: "office",
                      label: "Cargo",
                      type: "select",
                      options: NotebooksOfficesOptions,
                      onUpdateField: updateField,
                      validate: validateText,
                    })
                  }
                >
                  {notebook.gender === "Feminino"
                    ? `Candidata a ${toFeminine(
                        notebook.office
                      )?.toLowerCase()}`
                    : `Candidato a ${notebook.office?.toLowerCase()}`}
                </div>
                <div
                  style={{
                    fontSize: "6.34409px",
                    fontFamily: "MontserratAlternates",
                    color: palette.backgroundLight,
                    strokeWidth: 0.352449,
                  }}
                  onClick={() =>
                    triggerFieldEdit({
                      currentValue: notebook.age,
                      field: "age",
                      label: "Idade",
                      type: "input",
                      options: [],
                      onUpdateField: updateField,
                      validate: validateText,
                    })
                  }
                >
                  {`${notebook.age} anos`}
                </div>
              </div>
            </foreignObject>

            <path
              id="page1_svg__path676"
              d="M208.4 885.25a53.69 53.69 0 0 1 53.69 53.69v38.22a3.42 3.42 0 0 1-3.42 3.42h-38.23a53.69 53.69 0 0 1-53.69-53.69 41.65 41.65 0 0 1 41.65-41.65z"
              style={{
                fill: palette.backgroundLight,
                filter: "url(#page1_svg__filter-10-5)",
              }}
              transform="translate(-74.973 -173.082)scale(.354)"
            />
            <g
              id="page1_svg__CZJK68-17"
              className="page1_svg__cls-9"
              data-name="CZJK68"
              style={{
                opacity: 0.13,
              }}
              transform="translate(-74.973 -170.082)scale(.354)"
            >
              <g id="page1_svg__g2147">
                <path
                  id="page1_svg__path2132"
                  d="M88.79 442.23a37 37 0 0 0 3.06-2.09c2.65 8.9 3.84 17.13 2.43 24.15-2.44 12.11-12.94 18.62-31.93 19.18-14.27.42-31.36-2.29-49.9-6.6l-7.2-1.65L0 472.08c6.22 1.36 12.54 2.84 18.65 4.06 21.53 4.29 40.24 5.81 53.51 2 10.74-3.09 16.2-9.55 17.64-18.58a47.4 47.4 0 0 0-1.01-17.33"
                />
                <path
                  id="page1_svg__path2133"
                  d="M98.12 434c.61-.79 1.18-1.6 1.7-2.43a133 133 0 0 1 8.83 25c2 8.81 2.06 16.65-1.12 22.85-5 9.7-17.59 13.61-36.4 12.81-15-.64-32.31-4.07-50.73-8.79-.87-.22-1.71-.4-2.56-.6l-5.25-3.14c7.55 1.75 15.27 3.71 22.62 5.2 24.85 5 45.89 6.43 58.65 0 9.06-4.54 12.23-12.56 11.66-22.76-.52-8.61-3.18-18.14-7.4-28.14"
                />
                <path
                  id="page1_svg__path2134"
                  d="M113.24 445.32c-2.8-6.92-6.2-14.05-9.93-21.29a30 30 0 0 0 .77-2.86c6.24 11.49 11.8 22.74 15.67 33.41 3.65 10.09 5.61 19.41 4.33 27.25-2.14 13.17-14.08 19.28-35.3 18.79-12.41-.28-26.59-2.57-42-6.28-5.42-1.31-10.9-2.69-16.35-4l-5.24-3.14c6.17 1.55 12.48 3.27 18.5 4.61 19.63 4.37 37.64 7 51.65 5.37q19.15-2.22 23.47-14.75c3.42-9.97.19-22.98-5.57-37.11"
                />
                <path
                  id="page1_svg__path2135"
                  d="M108.42 413.59c-.77-.08-1.55-.17-2.32-.27 5.14 9.25 10.23 18.49 14.9 27.58 7 13.69 11.94 26.57 12.94 37.84 1 11.64-2.87 20.34-14.47 24.41-11.08 3.89-26.86 3.41-45.8-.23-11.54-2.21-23.91-5.44-35.9-8.22l5.77 3.46c2.47.58 4.89 1.09 7.42 1.76C67 504.18 82 507.16 95.53 508.3c27.32 2.3 41.93-4.54 42.62-21.4.42-10.22-3.63-22.34-9.48-35.22-5.59-12.32-12.73-25.14-20.25-38.09"
                />
                <path
                  id="page1_svg__path2136"
                  d="m118.4 416.57-1.4-2.44-1.91-.06c3.6 6.31 7.19 12.62 10.72 18.91 8.26 14.72 15.23 29 19 42.07 3 10.23 3.64 19.34 0 26.29-4.57 8.68-15.65 12.52-32.2 12.22-14.74-.26-32-3.51-50.63-8.24-3.71-.95-7.38-1.85-11.08-2.77l5.25 3.15c1.55.37 3.07.7 4.65 1.12 21.57 5.69 41.71 9.73 58.32 9.62 19.77-.13 30.27-6.72 31.85-19.51 1.09-8.76-1.75-19.28-6.39-30.59-6.52-15.84-16.2-32.75-26.18-49.77"
                />
                <path
                  id="page1_svg__path2137"
                  d="m125.22 414-2 .08c8.19 14.5 16.29 29 23.8 43.26 7.86 15 12.64 28.74 12.58 40.45-.08 13.36-8.18 21.45-26.29 22.92-15.91 1.29-36.15-2.16-58.26-7.71l-11.58-2.91 5.25 3.14c.75.17 1.48.29 2.27.51 21.8 5.84 42.18 10 59.09 10.08 18.66.08 29.62-5.37 32.5-16.58 2.21-8.57-.22-19.28-4.7-30.93-5.61-14.58-14.2-30.19-23.26-46q-4.68-8.11-9.4-16.31"
                />
                <path
                  id="page1_svg__path2138"
                  d="m133.18 413.65-1.64.09.89 1.57c10.57 18.69 21.63 37.44 29.51 55.18 6.23 14 10.5 27.22 9 37.91-1.51 10.84-9.23 17.52-24.36 19.24-14.72 1.67-33.63-1.15-54.49-6.07-5.3-1.25-10.7-2.62-16-3.94l5.25 3.14c.77.18 1.51.32 2.3.53 20.48 5.38 39.65 9.21 55.84 9.53q32.42.63 35.11-19.72c1.17-8.71-1.51-19.15-5.95-30.37-6.39-16.14-16.11-33.43-26.06-50.8-3.13-5.42-6.28-10.86-9.4-16.29"
                />
                <path
                  id="page1_svg__path2139"
                  d="M140.07 413c4.87 9.09 9.84 18.2 14.75 27.3 8.22 15.23 16.37 30.43 21.66 44.63 4.88 13.1 7.52 25.15 4.23 34.46s-12.48 14.4-27.56 15.34c-13.32.84-29.58-1.48-47.36-5.47-5.64-1.26-11.42-2.71-17.14-4.07l5.25 3.14c.69.16 1.37.3 2.08.48 16.69 4.23 32.49 7.4 46.52 8.43 27 2 41.61-5 42.9-21.46.61-7.86-1.41-16.92-5-26.67-5.33-14.56-13.62-30.16-22.17-45.86-5.53-10.15-11.15-20.33-16.64-30.47z"
                />
                <path
                  id="page1_svg__path2140"
                  d="M148.16 411.48c4.5 9 9.37 18.07 14.16 27.14 8.58 16.24 17.19 32.49 23.11 47.79 5.29 13.68 8.53 26.44 6 36.58-2.13 8.69-8.59 14.49-20.11 17-12.45 2.65-28.92 1.34-47.71-2.32-7.3-1.42-14.93-3.26-22.41-4.91l5.25 3.14c.69.16 1.38.3 2.09.47 20.49 4.93 39.44 8.13 55 7.52 17.17-.67 27.33-6.32 30.68-16.78 2.45-7.68 1.19-17.15-1.93-27.51-4.46-14.77-12.46-30.85-20.91-47.1-7.17-13.8-14.54-27.67-21.53-41.4z"
                />
                <path
                  id="page1_svg__path2141"
                  d="M159.7 410.71c-.4-.87-.79-1.73-1.18-2.6-.51.21-1 .43-1.53.63 2.85 6.54 6 13.19 9.21 19.87 8.41 17.46 17.15 35 25 52.28 6.73 14.86 10.93 28.7 10.58 40.55-.29 10-4.41 17.74-14.13 22.08-10.2 4.55-25 5.07-43.12 2.43-9.8-1.43-20.42-3.77-30.67-5.7l5.25 3.14a283 283 0 0 0 36.29 6.11c24.25 2.14 40.56-1.89 46.62-13.86 4-7.86 3.33-18.06.36-29.39-3.69-14.14-10.72-29.55-18.31-45.18-8.18-16.8-16.68-33.73-24.37-50.36"
                />
                <path
                  id="page1_svg__path2142"
                  d="M167.94 403.18c-.39.25-.79.49-1.19.73a286 286 0 0 0 10.44 26.93c7.59 17.42 15.74 35.05 23 52.33s11.94 33.68 9.89 46.95c-1 6.69-4 12.21-9.6 16.19-8.09 5.79-20.81 7.77-37 6.88-11.29-.62-23.83-2.66-37-5.4l5.77 3.45c.73.08 1.42.11 2.18.23 7.74 1.2 15.81 2.73 23.2 3.55 26.11 2.88 44.16-.74 51.66-12.69 4.72-7.51 4.92-17.37 2.75-28.44-2.52-12.86-8-26.91-14.27-41.28-8-18.22-16.44-36.63-23.92-54.67-2.11-5.09-4-10.08-5.48-14.91z"
                />
                <path
                  id="page1_svg__path2143"
                  d="M179.54 397.38c.08.76.18 1.53.29 2.31 2 14.37 7.8 30.31 14.12 46.47 7.05 18 15 36.42 20 53.65 4.26 14.64 6.41 28.27 2.77 39.12-3.86 11.47-14.39 18.28-32.41 19.87-12.88 1.14-28.34-.38-45.33-3.47l5.77 3.45c.5 0 1 0 1.5.08 8.17.9 16.72 2.19 24.44 2.65 20.13 1.2 34.57-2.2 42.74-10.55 5.94-6.07 8-14.36 7.67-23.91-.36-12.2-4.18-25.91-9.55-40.28-6.17-16.53-13-33.32-19.2-49.86-5.43-14.49-9.84-28.54-10.69-41-.66.5-1.39.99-2.12 1.47"
                />
                <path
                  id="page1_svg__path2144"
                  d="M152.12 563.18c3.93.37 7.88.77 11.78 1.12 19.58 1.72 35.39.22 46.14-5.62a28.7 28.7 0 0 0 14.54-18.35c3.37-12.26 1.19-27.19-3.5-43.28-4.63-15.89-10.08-32.14-15.12-48.2-4.63-14.8-8.46-29.24-8.92-42.18-.3-8.66 1-16.46 4.9-22.83 7.42-12.06 23.66-17.43 48.14-16.52a3.1 3.1 0 0 1 1.27.32 7.4 7.4 0 0 1-1.11.12c-15.07-.25-27.05 2-35.62 7.06A28.4 28.4 0 0 0 202 390.19c-5.1 14.15-1.7 32.38 4 51.67 5.17 17.5 11.13 35.32 16.42 52.86 4.12 13.69 6.61 26.66 6 38.2-.78 15.06-7.5 26.46-24.29 31.62-11.69 3.59-27 3.85-44.87 1.84-.68-.08-1.25 0-1.87-.06z"
                />
                <path
                  id="page1_svg__path2145"
                  d="M164.71 570.72c1.56 0 3.08 0 4.69.11 20.82 1.13 37.14-1.47 47.92-8.63a32.94 32.94 0 0 0 13.49-18c4.46-14 2.25-31.34-1.7-49.48-3.28-15.06-7.06-30.33-10.35-45.39-3-13.86-4.46-27-2.8-38.58 2.89-20.21 17-32.85 47.47-34.32a21.7 21.7 0 0 1 4.66.19 8 8 0 0 1-1 .19q-26.26 1.24-38.25 13.34c-6.57 6.62-9.7 15.23-10.58 25.13-1.47 16.47 2.74 35.57 7.22 54.8 3.63 15.6 7.58 31.33 9.21 46 1.52 13.58 1 26.1-4.22 36.18q-12.18 23.61-58.54 21.5c-.7 0-1.29 0-1.94.06z"
                />
                <path
                  id="page1_svg__path2146"
                  d="M177.3 578.26c3.93-.28 8-.48 11.77-.86 17.1-1.71 29.64-6.81 37.86-15.14 7-7.12 10.67-16.2 12.32-26.44 2.24-13.81.64-29.48-1.09-45.23-1.64-15-3.36-30.08-4.45-44.84-.86-11.55.54-22 4.11-31.23 6.14-15.91 20.24-26.33 45.2-29.24a4.5 4.5 0 0 1 1.93.18c-2.78.55-5.63 1.05-8.33 1.68-13.33 3.08-22.83 8.86-29.27 16.8-8.09 10-10.66 23-11.07 37.19-.44 15.59 2 32.54 3.88 49.24 1.61 14.26 2.89 28.35 1.78 41.28-1 11.76-4.17 22.32-11.1 30.74-9.15 11.12-24.68 17.52-47.05 18.82a9.5 9.5 0 0 0-1.24.19z"
                />
                <path
                  id="page1_svg__path2147"
                  d="M189.89 585.8c5.27-1.34 10.85-2.45 15.75-4.07 11.29-3.73 19.71-9.47 26-16.66 7.26-8.24 11.41-18.28 13.95-29.26 2.93-12.65 3.37-26.58 4-40.45.58-14.11.92-28.35 2.19-42.11 1.24-13.35 4.89-25.4 11.85-35.57 7.64-11.14 19.41-19.45 36.86-23.82a2.8 2.8 0 0 1 1.52 0c-2.34.83-4.75 1.61-7 2.51a61.9 61.9 0 0 0-23.48 16 57.14 57.14 0 0 0-13.4 25.47 207 207 0 0 0-4.89 30.16c-1.37 15.86-1.39 32.41-2.83 48.24-.91 9.9-3.06 19.16-5.21 28.41a54.1 54.1 0 0 1-11.49 22.49c-8.45 10.19-20.79 17.65-38.08 21.62a2.4 2.4 0 0 0-.49.2z"
                />
              </g>
            </g>
          </g>

          <PartyDisplay
            notebook={notebook}
            x={15}
            y={250}
            width={40}
            height={40}
            triggerFieldEdit={triggerFieldEdit}
            updateField={updateField}
          />
        </svg>
      </PaperInside>
    </Paper>
  );
};

export default Page1;
