import Attribute from "../../app/attribute";
import HttpClient from "../../app/client";
import handleError from "../../app/errors";
import { ActiveFilter } from "../../app/filter";
import { QueryCreator } from "../../app/query";
import { IDashboardService, QueryResult } from "./types";

class DashboardService implements IDashboardService {
  type: string;

  constructor(type: string) {
    this.type = type;
  }

  async getAttributes(): Promise<Attribute[]> {
    try {
      const client = new HttpClient(this.type, false, [404]);
      const response: Attribute[] = await client.get<Attribute[]>(
        `/${this.type}/attributes`
      );
      return response.map((attribute) => {
        attribute.origin = this.type;
        return attribute;
      });
    } catch (error) {
      handleError(`Error while getting ${this.type} attributes`, error);
    }
  }

  async getData(
    attribute: Attribute,
    filters: ActiveFilter[],
    comparativeFilters: ActiveFilter[],
    comparativeMode: boolean,
    zoneMode: boolean
  ): Promise<QueryResult> {
    try {
      const queryRequest = new QueryCreator(
        attribute,
        filters,
        comparativeFilters,
        comparativeMode,
        zoneMode
      ).toRequest();

      const client = new HttpClient(this.type, true, [404]);
      const response = await client.post<QueryResult>(
        `/${this.type}/query`,
        queryRequest
      );
      return response;
    } catch (error) {
      handleError(`Error while getting ${this.type} data`, error);
    }
  }
}

export const votingService = new DashboardService("voting");
export const electorateService = new DashboardService("electorate");
