import { throttle } from "lodash";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShopPages, Status } from "../../../../app/constants";
import { AppDispatch } from "../../../../app/store";
import ModalButton from "../../../../components/buttons/modal-button/modal-button";
import { makeContainer } from "../../../../components/container/container";
import Loading from "../../../../components/loading/loading";
import { closeModal, setModalContent } from "../../../../features/modal/slice";
import {
  selectCreateOrderStatus,
  selectOrder,
  setCurrentPage,
  setOrderId,
} from "../../../../features/shop/slice";
import { Order } from "../../../../features/shop/type";
import Check from "../../../../resources/images/check.png";
import Error from "../../../../resources/images/error.png";
import "./shop-create-order-modal.css";

type LogoutModalProps = {
  show: boolean;
  onClose: () => void;
};

const updateStatus = throttle(
  (nextStatus: Status, setStatus: (Status) => void) => {
    setStatus(nextStatus);
  },
  2500,
  { leading: false, trailing: true }
);

const CreateOrderModalContainer = makeContainer("create-order-modal-container");
const CreateOrderButtonsContainer = makeContainer(
  "create-order-modal-buttons-container"
);
const CreateOrderTopContainer = makeContainer(
  "create-order-modal-top-container"
);
const CreateOrderTopText = makeContainer("create-order-modal-top-text");
const CreateOrderTitle = makeContainer("create-order-modal-title");
const ContentContainer = makeContainer("create-order-modal-content-container");

const CreateOrderModal: React.FC<LogoutModalProps> = memo(
  ({ show, onClose }) => {
    const dispatch: AppDispatch = useDispatch();
    const [status, setStatus] = useState(Status.LOADING);
    const nextStatus: Status = useSelector(selectCreateOrderStatus);
    const order: Order = useSelector(selectOrder);

    useEffect(() => {
      updateStatus(nextStatus, setStatus);
    }, [nextStatus]);

    const close = () => {
      dispatch(closeModal(null));
    };

    const toOrder = () => {
      dispatch(setOrderId(order?.id));
      dispatch(setCurrentPage(ShopPages.ORDER_DETAILS));
      dispatch(closeModal(null));
    };

    const processingPaymentContent = (
      <CreateOrderModalContainer>
        <CreateOrderTopContainer>
          <CreateOrderTopText>Quase lá!</CreateOrderTopText>
          <CreateOrderTitle>Estamos processando seu pedido</CreateOrderTitle>
        </CreateOrderTopContainer>
        <ContentContainer>
          <Loading />
        </ContentContainer>
      </CreateOrderModalContainer>
    );

    const paidContent = (
      <CreateOrderModalContainer>
        <CreateOrderTopContainer>
          <CreateOrderTopText>Feito</CreateOrderTopText>
          <CreateOrderTitle>Pedido criado com sucesso</CreateOrderTitle>
        </CreateOrderTopContainer>
        <ContentContainer>
          <img className="modal-large-icon" alt="ícone sucesso" src={Check} />
        </ContentContainer>
        <CreateOrderButtonsContainer>
          <ModalButton variation="primary" disabled={!order} onClick={toOrder}>
            Ver Pedido
          </ModalButton>
        </CreateOrderButtonsContainer>
      </CreateOrderModalContainer>
    );

    const errorContent = (
      <CreateOrderModalContainer>
        <CreateOrderTopContainer>
          <CreateOrderTopText>Algo deu errado...</CreateOrderTopText>
          <CreateOrderTitle>Pedido não concluído</CreateOrderTitle>
        </CreateOrderTopContainer>
        <ContentContainer>
          <img className="modal-large-icon" alt="ícone erro" src={Error} />
        </ContentContainer>
        <CreateOrderButtonsContainer>
          <ModalButton variation="primary" onClick={close}>
            Fechar e voltar
          </ModalButton>
        </CreateOrderButtonsContainer>
      </CreateOrderModalContainer>
    );

    const contentByStatus = {
      [Status.IDLE]: processingPaymentContent,
      [Status.LOADING]: processingPaymentContent,
      [Status.SUCCEEDED]: paidContent,
      [Status.FAILED]: errorContent,
    };

    useEffect(() => {
      const changeModalStatus = () => {
        if (show) {
          dispatch(
            setModalContent({
              content: contentByStatus[status],
              onClose: () => {},
            })
          );
        } else {
          dispatch(closeModal(null));
        }
      };

      changeModalStatus();
      // eslint-disable-next-line
    }, [dispatch, onClose, show, status]);

    return <></>;
  }
);

export default CreateOrderModal;
