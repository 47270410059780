import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShopPages, Status } from "../../../app/constants";
import { AppDispatch } from "../../../app/store";
import ShopActionButton from "../../../components/buttons/shop-action-button/shop-action-button";
import Loading from "../../../components/loading/loading";
import ShopHeading, {
  Variant,
} from "../../../components/text/shop-heading/shop-heading";
import ShopService from "../../../features/shop/service";
import {
  getOrders,
  selectGetOrdersStatus,
  selectOrders,
  setCurrentPage,
  setGetOrdersStatus,
} from "../../../features/shop/slice";
import { Order } from "../../../features/shop/type";
import ShopOrder from "../components/shop-order/shop-order";
import ShopPageContainer from "../components/shop-page-container/shop-page-container";
import ShopSpacer from "../components/shop-spacer/shop-spacer";

const Orders: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const orders: Order[] = useSelector(selectOrders);
  const status: Status = useSelector(selectGetOrdersStatus);

  useEffect(() => {
    dispatch(
      getOrders({
        service: new ShopService(),
      })
    );

    return () => {
      dispatch(setGetOrdersStatus(Status.LOADING));
    };
  }, [dispatch]);

  return (
    <ShopPageContainer>
      <ShopHeading variant={Variant.H1}>{`Meus Pedidos`}</ShopHeading>
      <ShopSpacer space={10} />
      <ShopHeading
        variant={Variant.H2}
      >{`Confira os pedidos feitos na plataforma`}</ShopHeading>
      {status === Status.LOADING && <Loading />}
      {status === Status.SUCCEEDED && (
        <>
          {orders.map((order, index) => (
            <ShopOrder key={`shop-order-${index}`} order={order} />
          ))}
        </>
      )}
      <ShopSpacer space={80} />
      <ShopActionButton
        onClick={() => dispatch(setCurrentPage(ShopPages.PRODUCT_SELECT))}
      >
        Voltar à loja
      </ShopActionButton>
    </ShopPageContainer>
  );
};

export default Orders;
