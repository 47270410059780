import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ShopPages, Status } from "../../app/constants";
import { debounceThunk } from "../../app/utils";
import {
  Candidate,
  CandidateElectionSummary,
  CandidateSearch,
} from "../candidates/type";
import {
  CartItem,
  CartPrice,
  CreateCartItemRequest,
  CreateOrderRequest,
  IShopService,
  Order,
  Product,
} from "./type";

const initialProducts: Product[] = [];
const initialCart: CartItem[] = [];
const initialOrders: Order[] = [];
const initialDetailedOrder: Order | null = null;
const initialCandidates: CandidateSearch[] = [];
const initialCandidateData: Candidate | null = null;
const initialSelectedCandidate: string | null = null;
const initialSelectedCandidature: CandidateElectionSummary | null = null;

// Slice's initial state
const initialState = {
  getProductStatus: Status.IDLE,
  getCartStatus: Status.IDLE,
  editCartStatus: Status.IDLE,
  createOrderStatus: Status.IDLE,
  getOrderStatus: Status.IDLE,
  getOrdersStatus: Status.IDLE,
  getCandidateStatus: Status.IDLE,
  searchCandidatesStatus: Status.IDLE,
  currentPage: ShopPages.PRODUCT_SELECT,
  isSigningIn: false,
  isSigningUp: false,
  selectedCandidate: initialSelectedCandidate,
  selectedCandidature: initialSelectedCandidature,
  cartPrice: 0.0,
  cart: initialCart,
  discountCode: "",
  products: initialProducts,
  orders: initialOrders,
  order: initialDetailedOrder,
  candidates: initialCandidates,
  candidateData: initialCandidateData,
  error: "",
  orderId: "",
};

type ShopGetCartParams = { code: string; service: IShopService };
type ShopGetCartReturn = { cart: CartItem[]; price: CartPrice };
type ShopGetProductsParams = { service: IShopService };
type ShopGetOrdersParams = { service: IShopService };
type ShopGetOrderParams = { id: string; service: IShopService };
type ShopGetCandidatesParams = { id: string; service: IShopService };
type ShopSearchCandidatesParams = { search: string; service: IShopService };
type ShopAddCartItemParams = {
  code: string;
  cartItem: CreateCartItemRequest;
  service: IShopService;
};
type ShopRemoveCartItemParams = {
  code: string;
  cartItemId: string;
  service: IShopService;
};
type ShopCreateOrderParams = {
  order: CreateOrderRequest;
  service: IShopService;
};

const getCartThunk = createAsyncThunk<ShopGetCartReturn, ShopGetCartParams>(
  "shop/getcart",
  async (params) => {
    const { code, service } = params;
    const cart = await service.getCart();
    const price = await service.getCartPrice(code);
    return { cart, price };
  }
);

const addCartItemThunk = createAsyncThunk<
  ShopGetCartReturn,
  ShopAddCartItemParams
>("shop/addcartitem", async (params) => {
  const { code, cartItem, service } = params;
  await service.addCartItem(cartItem);
  const cart = await service.getCart();
  const price = await service.getCartPrice(code);
  return { cart, price };
});

const removeCartItemThunk = createAsyncThunk<
  ShopGetCartReturn,
  ShopRemoveCartItemParams
>("shop/removecartitem", async (params) => {
  const { code, cartItemId, service } = params;
  await service.removeCartItem(cartItemId);
  const cart = await service.getCart();
  const price = await service.getCartPrice(code);
  return { cart, price };
});

const getProductsThunk = createAsyncThunk<Product[], ShopGetProductsParams>(
  "shop/getproducts",
  async (params) => {
    const { service } = params;
    const response = await service.getProducts();
    return response;
  }
);

const getOrdersThunk = createAsyncThunk<Order[], ShopGetOrdersParams>(
  "shop/getorders",
  async (params) => {
    const { service } = params;
    const response = await service.getOrders();
    return response;
  }
);

const getOrderDetailsThunk = createAsyncThunk<Order, ShopGetOrderParams>(
  "shop/getorderdetails",
  async (params) => {
    const { id, service } = params;
    const response = await service.getOrderDetails(id);
    return response;
  }
);

const getCandidateThunk = createAsyncThunk<Candidate, ShopGetCandidatesParams>(
  "shop/candidates/getcandidate",
  async (params) => {
    const { id, service } = params;
    const response = await service.getCandidate(id);
    return response;
  }
);

// search candidate thunk
const searchCandidatesThunk = createAsyncThunk<
  CandidateSearch[],
  ShopSearchCandidatesParams
>("shop/candidates/searchcandidates", async (params) => {
  const { search, service } = params;
  const response = await service.searchCandidates(search);
  return response;
});

const createOrderThunk = createAsyncThunk<Order, ShopCreateOrderParams>(
  "shop/createorder",
  async (params) => {
    const { order, service } = params;
    return await service.createOrder(order);
  }
);

// Selectors
export const selectGetProductStatus = (state) => state.shop.getProductStatus;
export const selectGetCartStatus = (state) => state.shop.getCartStatus;
export const selectEditCartStatus = (state) => state.shop.editCartStatus;
export const selectCreateOrderStatus = (state) => state.shop.createOrderStatus;
export const selectGetOrderStatus = (state) => state.shop.getOrderStatus;
export const selectGetOrdersStatus = (state) => state.shop.getOrdersStatus;
export const selectGetCandidateStatus = (state) =>
  state.shop.getCandidateStatus;
export const selectSearchCandidatesStatus = (state) =>
  state.shop.searchCandidatesStatus;
export const selectIsSigningIn = (state) => state.shop.isSigningIn;
export const selectIsSigningUp = (state) => state.shop.isSigningUp;
export const selectCurrentPage = (state) => state.shop.currentPage;
export const selectCart = (state) => state.shop.cart;
export const selectCartPrice = (state) => state.shop.cartPrice;
export const selectProducts = (state) => state.shop.products;
export const selectOrders = (state) => state.shop.orders;
export const selectOrder = (state) => state.shop.order;
export const selectOrderId = (state) => state.shop.orderId;
export const selectCandidates = (state) => state.shop.candidates;
export const selectCandidateProfileData = (state) => state.shop.candidateData;
export const selectSelectedCandidate = (state) => state.shop.selectedCandidate;
export const selectSelectedCandidature = (state) =>
  state.shop.selectedCandidature;
export const selectDiscountCode = (state) => state.shop.discountCode;

// Reducers
const loadingGetProducts = (state, action) => {
  state.getProductStatus = Status.LOADING;
};

const loadingGetCart = (state, action) => {
  state.getCartStatus = Status.LOADING;
};

const loadingEditCart = (state, action) => {
  state.editCartStatus = Status.LOADING;
};

const loadingCreateOrder = (state, action) => {
  state.createOrderStatus = Status.LOADING;
};

const loadingGetOrder = (state, action) => {
  state.getOrderStatus = Status.LOADING;
};

const loadingGetOrders = (state, action) => {
  state.getOrdersStatus = Status.LOADING;
};

const loadingGetCandidate = (state, action) => {
  state.getCandidateStatus = Status.LOADING;
};

const loadingSearchCandidates = (state, action) => {
  state.searchCandidatesStatus = Status.LOADING;
};

///
const successGetProduct = (state, action) => {
  state.getProductStatus = Status.SUCCEEDED;
  state.products = action.payload;
  state.error = "";
};

const successGetCart = (state, action) => {
  state.getCartStatus = Status.SUCCEEDED;
  state.cart = action.payload.cart;
  state.cartPrice = action.payload.price;
  state.error = "";
};

const successEditCart = (state, action) => {
  state.editCartStatus = Status.SUCCEEDED;
  state.cart = action.payload.cart;
  state.cartPrice = action.payload.price;
  state.error = "";
};

const successCreateOrder = (state, action) => {
  state.createOrderStatus = Status.SUCCEEDED;
  state.order = action.payload;
  state.error = "";
};

const successGetOrder = (state, action) => {
  state.getOrderStatus = Status.SUCCEEDED;
  state.order = action.payload;
  state.error = "";
};

const successGetOrders = (state, action) => {
  state.getOrdersStatus = Status.SUCCEEDED;
  state.orders = action.payload;
  state.error = "";
};

const successGetCandidate = (state, action) => {
  state.getCandidateStatus = Status.SUCCEEDED;
  state.candidateData = action.payload;
  state.error = "";
};

const successSearchCandidates = (state, action) => {
  state.searchCandidatesStatus = Status.SUCCEEDED;
  state.candidates = action.payload;
  state.error = "";
};

const rejectedGetProducts = (state, action) => {
  state.getProductStatus = Status.FAILED;
};

const rejectedGetCart = (state, action) => {
  state.getCartStatus = Status.FAILED;
};

const rejectedEditCart = (state, action) => {
  state.editCartStatus = Status.FAILED;
};

const rejectedCreateOrder = (state, action) => {
  state.createOrderStatus = Status.FAILED;
};

const rejectedGetOrder = (state, action) => {
  state.getOrderStatus = Status.FAILED;
};

const rejectedGetOrders = (state, action) => {
  state.getOrdersStatus = Status.FAILED;
};

const rejectedGetCandidate = (state, action) => {
  state.getCandidateStatus = Status.FAILED;
};

const rejectedSearchCandidates = (state, action) => {
  state.searchCandidatesStatus = Status.FAILED;
};

// Slice
const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setCreateOrderStatus(state, action) {
      state.createOrderStatus = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setSelectedCandidate(state, action) {
      state.selectedCandidate = action.payload;
    },
    setSelectedCandidature(state, action) {
      state.selectedCandidature = action.payload;
    },
    setIsAuthenticating(state, action) {
      state.isSigningIn = action.payload.isSigningIn;
      state.isSigningUp = action.payload.isSigningUp;
    },
    setDiscountCode(state, action) {
      state.discountCode = action.payload;
    },
    setOrderId(state, action) {
      state.orderId = action.payload;
    },
    setGetOrdersStatus(state, action) {
      state.getOrdersStatus = action.payload;
    },
    setGetOrderStatus(state, action) {
      state.getOrderStatus = action.payload;
    },
    setGetCartStatus(state, action) {
      state.getCartStatus = action.payload;
    },
    setGetCandidateStatus(state, action) {
      state.getCandidateStatus = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCartThunk.pending, loadingGetCart)
      .addCase(getCartThunk.fulfilled, successGetCart)
      .addCase(getCartThunk.rejected, rejectedGetCart)
      .addCase(getProductsThunk.pending, loadingGetProducts)
      .addCase(getProductsThunk.fulfilled, successGetProduct)
      .addCase(getProductsThunk.rejected, rejectedGetProducts)
      .addCase(getOrdersThunk.pending, loadingGetOrders)
      .addCase(getOrdersThunk.fulfilled, successGetOrders)
      .addCase(getOrdersThunk.rejected, rejectedGetOrders)
      .addCase(getOrderDetailsThunk.pending, loadingGetOrder)
      .addCase(getOrderDetailsThunk.fulfilled, successGetOrder)
      .addCase(getOrderDetailsThunk.rejected, rejectedGetOrder)
      .addCase(getCandidateThunk.pending, loadingGetCandidate)
      .addCase(getCandidateThunk.fulfilled, successGetCandidate)
      .addCase(getCandidateThunk.rejected, rejectedGetCandidate)
      .addCase(searchCandidatesThunk.pending, loadingSearchCandidates)
      .addCase(searchCandidatesThunk.fulfilled, successSearchCandidates)
      .addCase(searchCandidatesThunk.rejected, rejectedSearchCandidates)
      .addCase(addCartItemThunk.pending, loadingEditCart)
      .addCase(addCartItemThunk.fulfilled, successEditCart)
      .addCase(addCartItemThunk.rejected, rejectedEditCart)
      .addCase(removeCartItemThunk.pending, loadingEditCart)
      .addCase(removeCartItemThunk.fulfilled, successEditCart)
      .addCase(removeCartItemThunk.rejected, rejectedEditCart)
      .addCase(createOrderThunk.pending, loadingCreateOrder)
      .addCase(createOrderThunk.fulfilled, successCreateOrder)
      .addCase(createOrderThunk.rejected, rejectedCreateOrder);
  },
});

export const {
  setCurrentPage,
  setSelectedCandidate,
  setSelectedCandidature,
  setIsAuthenticating,
  setDiscountCode,
  setOrderId,
  setCreateOrderStatus,
  setGetOrdersStatus,
  setGetOrderStatus,
  setGetCartStatus,
  setGetCandidateStatus,
} = shopSlice.actions;
export const getCart = debounceThunk(getCartThunk);
export const getProducts = debounceThunk(getProductsThunk);
export const getOrders = debounceThunk(getOrdersThunk);
export const getOrderDetails = debounceThunk(getOrderDetailsThunk);
export const getCandidate = debounceThunk(getCandidateThunk);
export const searchCandidates = debounceThunk(searchCandidatesThunk);
export const addCartItem = debounceThunk(addCartItemThunk);
export const removeCartItem = debounceThunk(removeCartItemThunk);
export const createOrder = debounceThunk(createOrderThunk);

export default shopSlice.reducer;
