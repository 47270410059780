import React from "react";
import Image from "../../resources/images/intro-page-2.svg";
import Page from "./components/page";
import ThirdPage from "./components/third-page";

const Login: React.FC = () => {
  return (
    <Page image={Image}>
      <ThirdPage />
    </Page>
  );
};

export default Login;
