import React, { memo } from "react";
import { makeContainer } from "../../../../components/container/container";
import WidgetTitleText from "../../../../components/text/widget-title-text/widget-title-text";
import CalendarIcon from "../../../../resources/icons/calendar.svg";
import "./calendar.css";

const CalendarContainer = makeContainer("home-calendar");
const DaysContainer = makeContainer("home-calendar-days");
const EventsContainer = makeContainer("home-calendar-events");

const NoDataCalendar: React.FC = () => {
  return (
    <div className="no-events">
      <img alt="ícone" src={CalendarIcon} className="calendar-icon" />
      <span className="no-events-text">Sem eventos</span>
    </div>
  );
};

const Banner: React.FC = () => {
  return (
    <CalendarContainer>
      <WidgetTitleText>{"Calendário Eleitoral"}</WidgetTitleText>
      <DaysContainer></DaysContainer>
      <EventsContainer>
        <NoDataCalendar />
      </EventsContainer>
    </CalendarContainer>
  );
};

export default memo(Banner);
