import * as React from "react";
import { useSelector } from "react-redux";
import { validateText } from "../../../../../app/utils";
import { selectPalette } from "../../../../../features/notebook/slice";
import { ClippedImage, Paper, PaperInside } from "../commons/commons";
import { TemplatePageProps } from "../commons/interfaces";

const Page11: React.FC<TemplatePageProps> = ({
  className,
  notebook,
  triggerImageEdit,
  triggerFieldEdit,
  updateField,
}) => {
  const { palette } = useSelector(selectPalette);
  return (
    <Paper className={className}>
      <PaperInside>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
        >
          <defs>
            <filter id="page-11_svg__a">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
          </defs>
          <rect width="100%" height="100%" fill={palette.backgroundLight} />

          <ClippedImage
            id="emerald-page-11"
            triggerImageEdit={triggerImageEdit}
            x={0}
            y={0}
            width={210}
            height={150}
            imageNumber={15}
            imageRatio={1.4}
            notebook={notebook}
          >
            <path
              d="M 1438.39 2202.13 h 599.71 v 260.85 c -197.1 59.02 -455.1 -16.98 -600.1 -12.98 z"
              transform="scale(1, 1.45) matrix(.35486 0 0 .35486 -512.019 -782.708)"
              fill={palette.backgroundLight}
            />
          </ClippedImage>

          <g
            className="page-11_svg__cls-9"
            data-name="CZJK68"
            style={{
              opacity: 0.13,
            }}
          >
            <path d="M32.889 188.397a17 17 0 0 0 1.601.738c-2.982 3.256-6.035 5.751-9.255 6.887-5.539 1.967-10.761-.727-15.621-8.339-3.657-5.716-6.746-13.352-9.5-21.962-.356-1.118-.71-2.23-1.087-3.344v-2.904c.966 2.869 1.906 5.808 2.904 8.599 3.508 9.83 7.456 17.829 12.255 22.313 3.884 3.625 7.854 4.26 11.887 2.655 2.343-.944 4.608-2.563 6.816-4.643" />
            <path d="M38.548 190.179c.469.057.937.092 1.42.103-2.588 3.728-5.247 7.047-8.027 9.699-3.11 2.964-6.29 4.91-9.585 5.127-5.173.355-9.849-3.852-14.116-11.716-3.398-6.263-6.234-14.17-8.808-22.835-.121-.412-.256-.796-.384-1.193v-2.908c1.133 3.504 2.22 7.136 3.405 10.495 4.015 11.36 8.588 20.29 14.318 23.932 4.069 2.584 8.112 1.92 12.131-.806 3.348-2.294 6.561-5.709 9.646-9.898" />
            <path d="M37.6 199.125c2.13-2.84 4.218-5.957 6.26-9.23q.692-.164 1.359-.387c-3.167 5.35-6.39 10.37-9.806 14.556-3.223 3.958-6.543 7.03-10.058 8.424-5.882 2.333-11.3-1.03-16.288-9.809-2.915-5.13-5.446-11.474-7.697-18.674-.792-2.528-1.565-5.098-2.347-7.65v-2.908c.877 2.897 1.719 5.89 2.642 8.673 3.01 9.074 6.344 17.059 10.427 22.366q5.58 7.275 11.748 5.975c4.888-1.036 9.397-5.549 13.76-11.336" />
            <path d="M49.37 189.405a44 44 0 0 1-.459-1.008c-2.52 4.356-5.041 8.687-7.608 12.812-3.866 6.217-7.924 11.361-12.277 14.524-4.494 3.267-9 3.8-13.49.057-4.293-3.568-7.95-10.122-11.095-18.734-1.913-5.248-3.618-11.084-5.414-16.654v3.195c.355 1.15.75 2.261 1.093 3.458 2.187 7.597 4.637 14.445 7.456 20.236 5.734 11.716 12.092 15.976 19.143 12.156 4.26-2.326 8.222-6.934 12.05-12.469 3.667-5.293 7.156-11.35 10.6-17.573" />
            <path d="M50.59 194.208q.325-.586.643-1.178l-.437-.796c-1.697 3.011-3.394 6.018-5.098 8.993-3.983 6.962-8.101 13.289-12.514 18.032-3.451 3.706-7.001 6.209-10.726 6.419-4.657.259-8.928-3.323-12.851-10.147-3.49-6.078-6.39-13.931-9.004-22.668-.522-1.743-1.065-3.462-1.58-5.194v2.907c.228.71.466 1.42.679 2.17 2.946 10.192 6.223 19.395 10.32 26.143 4.882 8.031 10.136 10.704 15.742 8.226 3.838-1.697 7.434-5.424 10.913-10.079 4.875-6.518 9.408-14.602 13.913-22.828" />
            <path d="M53.292 196.367c-.174-.26-.355-.526-.515-.788-3.905 6.88-7.846 13.725-11.836 20.271-4.182 6.863-8.634 12.181-13.43 15.014-5.464 3.231-10.744 1.903-15.77-5.123-4.417-6.177-7.939-15.287-11.07-25.66q-.824-2.72-1.648-5.432v2.904c.118.355.245.678.355 1.065 2.944 10.32 6.224 19.647 10.321 26.566 4.523 7.633 9.426 10.768 14.701 9.21 4.034-1.194 7.811-4.8 11.468-9.473 4.576-5.85 8.847-13.171 13.068-20.719q2.18-3.916 4.356-7.835" />
            <path d="M55.394 199.534q-.224-.32-.44-.646-.209.373-.423.745c-5.016 8.876-9.987 17.968-15.298 25.516-4.203 5.971-8.542 10.934-13.263 12.937-4.79 2.034-9.398.518-13.797-5.233-4.278-5.6-7.746-14.003-10.835-23.709-.788-2.467-1.544-5.006-2.315-7.509v2.908c.118.355.242.71.355 1.065 2.809 9.667 5.926 18.422 9.753 25.104q7.658 13.377 16.619 9.504c3.837-1.651 7.455-5.294 10.938-9.845 5.023-6.55 9.699-14.74 14.353-23.037 1.449-2.6 2.897-5.216 4.353-7.8" />
            <path d="M57.347 202.171c-2.514 4.204-5.02 8.457-7.53 12.682-4.208 7.072-8.418 14.112-12.916 19.736-4.15 5.194-8.425 9.23-13.022 10.146-4.598.916-8.922-1.573-12.99-7.49-3.597-5.234-6.622-12.427-9.341-20.66-.863-2.609-1.683-5.325-2.525-7.984v2.908c.107.32.217.632.316.965 2.35 7.843 4.917 15.06 7.925 21.035 5.786 11.5 12.184 15.763 19.231 12.26 3.359-1.673 6.557-4.708 9.664-8.55 4.636-5.73 8.975-12.919 13.288-20.236 2.79-4.736 5.574-9.515 8.368-14.233-.16-.195-.312-.376-.468-.579" />
            <path d="M59.938 205.1c-2.56 4.03-5.08 8.24-7.611 12.426-4.53 7.456-9.057 14.95-13.846 21.1-4.293 5.502-8.702 9.94-13.449 11.399-4.065 1.253-8.009.039-11.825-4.062-4.126-4.43-7.612-11.467-10.708-20.026-1.203-3.323-2.318-6.888-3.472-10.342v2.908c.107.323.217.639.32.965 2.996 9.586 6.319 18.078 10.363 24.27 4.47 6.837 9.255 9.603 14.339 8.41 3.731-.873 7.289-3.7 10.75-7.509 4.935-5.424 9.54-12.614 14.105-20.027 3.877-6.298 7.732-12.692 11.63-18.897-.202-.21-.4-.412-.596-.615" />
            <path d="M63.07 209.623q.381-.562.77-1.114l-.628-.473c-1.974 2.759-3.906 5.68-5.858 8.613-5.067 7.694-10.104 15.554-15.227 22.952-4.417 6.39-9.036 11.468-13.953 14.223-4.16 2.329-8.314 2.531-12.457-.355-4.353-3.05-8.166-8.947-11.524-16.995-1.815-4.35-3.451-9.252-5.17-13.903v2.88c1.957 5.939 4.04 11.48 6.39 16.295 5.05 10.42 10.68 16.086 17.042 15.62 4.179-.297 8.165-3.056 12.07-7.032 4.865-4.97 9.437-11.595 13.96-18.504 4.857-7.406 9.678-15.007 14.585-22.207" />
            <path d="M68.153 211.143c-.195-.096-.39-.202-.589-.305a135 135 0 0 0-8.432 10.835c-5.254 7.352-10.455 14.985-15.741 22.15-5.287 7.164-10.818 13.096-16.736 15.5-2.982 1.21-5.965 1.338-8.947.043-4.335-1.886-8.254-6.593-11.85-13.424-2.507-4.757-4.736-10.374-6.835-16.409v3.195c.15.313.302.604.44.941 1.42 3.454 2.749 7.118 4.222 10.331 5.201 11.36 11.084 17.833 17.794 17.975 4.214.089 8.286-2.237 12.273-5.826 4.615-4.171 9.024-9.838 13.352-15.908 5.489-7.694 10.928-15.65 16.459-23.105 1.562-2.106 3.142-4.083 4.743-5.88z" />
            <path d="M73.354 214.445q-.436.33-.873.681c-5.368 4.332-10.455 10.587-15.504 17.112-5.634 7.282-11.194 15.018-16.995 21.277-4.93 5.325-9.962 9.522-15.266 10.682-5.62 1.232-10.973-1.398-16.022-8.364-3.607-4.97-6.766-11.648-9.656-19.338v3.195c.113.21.23.398.337.629 1.626 3.55 3.195 7.356 4.885 10.618 4.427 8.503 9.344 13.562 14.744 14.854 3.93.941 7.81-.259 11.63-2.708 4.886-3.128 9.547-8.038 14.099-13.733 5.233-6.557 10.412-13.444 15.645-20.012 4.587-5.755 9.231-10.988 14.116-14.379-.39-.16-.767-.316-1.14-.514" />
            <path d="M-.977 243.777c.806 1.693 1.609 3.4 2.422 5.073 4.079 8.41 8.556 14.495 13.565 17.453a13.65 13.65 0 0 0 11.034 1.448c5.823-1.619 11.382-6.16 16.8-12 5.35-5.772 10.65-11.964 15.976-17.939 4.903-5.506 9.859-10.594 15.028-13.945 3.454-2.237 6.951-3.618 10.509-3.575 6.745.074 12.887 5.39 18.493 15.6a1.5 1.5 0 0 1 .184.596 3 3 0 0 1-.323-.423c-3.578-6.209-7.434-10.54-11.584-12.809a13.5 13.5 0 0 0-9.362-1.42c-7.012 1.378-13.619 7.218-20.094 14.261-5.872 6.39-11.688 13.165-17.553 19.608-4.576 5.027-9.255 9.21-14.108 11.783-6.337 3.363-12.632 3.405-18.834-2.186-4.32-3.906-8.166-10.083-11.716-17.855-.135-.294-.287-.518-.433-.773z" />
            <path d="M-.977 250.753c.355.646.742 1.26 1.101 1.942 4.615 8.765 9.674 14.79 15.223 17.435a15.65 15.65 0 0 0 10.651 1.108c6.816-1.609 13.335-6.746 19.768-12.781 5.325-5.017 10.65-10.296 15.976-15.312 4.913-4.616 9.908-8.404 15.053-10.562 8.953-3.76 17.541-1.108 25.597 10.977a10.3 10.3 0 0 1 1.065 1.95 4 4 0 0 1-.327-.374q-6.923-10.413-14.786-12.347c-4.307-1.065-8.581-.235-12.835 1.82-7.075 3.427-13.845 9.81-20.59 16.332-5.472 5.29-10.925 10.743-16.524 15.003-5.169 3.934-10.409 6.774-15.787 7.1q-12.61.8-23.077-18.627c-.16-.292-.334-.519-.5-.778z" />
            <path d="M-.977 257.732c1.066 1.534 2.13 3.125 3.228 4.59 4.87 6.558 10.015 10.428 15.418 11.742 4.616 1.128 9.23.397 13.8-1.42 6.177-2.464 12.18-6.948 18.18-11.496 5.727-4.339 11.45-8.72 17.205-12.781 4.505-3.174 9.096-5.144 13.743-5.95 7.988-1.385 15.685 1.821 22.97 11.297a2.1 2.1 0 0 1 .398.827c-.906-.998-1.808-2.038-2.72-2.986-4.512-4.686-9.191-7.147-14.006-7.835-6.035-.863-11.978 1.264-17.875 4.569-6.465 3.625-12.78 8.758-19.136 13.608-5.42 4.143-10.856 8.109-16.402 10.81-5.045 2.46-10.125 3.75-15.266 2.983-6.773-1.016-13.178-5.79-19.17-14.595a5 5 0 0 0-.384-.458z" />
            <path d="M-.977 264.709c1.836 1.82 3.647 3.823 5.507 5.428 4.281 3.692 8.677 5.726 13.136 6.554 5.137.948 10.246.188 15.344-1.46 5.875-1.895 11.67-5.115 17.467-8.268 5.897-3.195 11.79-6.55 17.712-9.39 5.748-2.759 11.556-4.21 17.396-3.856 6.412.39 12.674 3.163 18.72 9.23a1.34 1.34 0 0 1 .355.63c-.912-.75-1.82-1.545-2.737-2.245-4.015-3.053-8.105-4.853-12.262-5.68a27.2 27.2 0 0 0-13.665.756 98 98 0 0 0-13.424 5.361c-6.805 3.316-13.561 7.349-20.367 10.626-4.26 2.052-8.567 3.437-12.863 4.818a25.75 25.75 0 0 1-11.982.805c-6.22-.958-12.28-4.168-18.106-10.249a1.3 1.3 0 0 0-.202-.153z" />
          </g>

          <path
            d="M2400.48 2532h204.62a1.88 1.88 0 0 1 1.88 1.88v204.62a229.2 229.2 0 0 1-229.2 229.2 206.5 206.5 0 0 1-206.5-206.5 229.2 229.2 0 0 1 229.2-229.2"
            className="page-11_svg__cls-153"
            style={{
              fill: palette.backgroundLight,
              filter: "url(#page-11_svg__a)",
            }}
            transform="rotate(-90 90.81 949.783)scale(.35502)"
          />

          <path
            d="M160.069 244.465a1.68 1.68 0 0 1-1.218.032 1.73 1.73 0 0 1-.973-.71l-1.154-1.583a1.7 1.7 0 0 1-.245-1.094 1.54 1.54 0 0 1 .55-1.033q.668-.67 1.187-1.278.516-.606 1-1.154a.97.97 0 0 0 .153-1.004 1.73 1.73 0 0 0-.71-.82 4.1 4.1 0 0 1-1.64-1.914 7.4 7.4 0 0 1-.61-2.645 5.68 5.68 0 0 1 1.665-4.392 6.26 6.26 0 0 1 4.317-1.917 6.1 6.1 0 0 1 2.84.519 6.3 6.3 0 0 1 2.13 1.58 7.3 7.3 0 0 1 1.37 2.343 8.3 8.3 0 0 1 .487 2.84 11.95 11.95 0 0 1-2.283 7.175q-2.308 3.17-6.866 5.055m-14.29 0a1.67 1.67 0 0 1-1.214.032 1.72 1.72 0 0 1-.973-.71l-1.157-1.583a1.68 1.68 0 0 1-.242-1.094 1.54 1.54 0 0 1 .547-1.033q.671-.67 1.186-1.278.515-.607 1.005-1.154a.97.97 0 0 0 .149-1.004 1.7 1.7 0 0 0-.71-.82 4.15 4.15 0 0 1-1.64-1.914 7.3 7.3 0 0 1-.608-2.645 5.65 5.65 0 0 1 1.64-4.41 6.26 6.26 0 0 1 4.318-1.916 6.06 6.06 0 0 1 2.84.518 6.3 6.3 0 0 1 2.13 1.58 7.3 7.3 0 0 1 1.367 2.343 8.3 8.3 0 0 1 .486 2.84 11.97 11.97 0 0 1-2.28 7.175q-2.282 3.188-6.844 5.073"
            className="page-11_svg__cls-25"
            style={{
              fill: palette.primary,
              strokeWidth: 0.355022,
            }}
          />
          <path
            d="M-.355 297.392s34.653-17.822 83.984-16.246 40.948 13.274 75.975 13.232c41.757-.05 51.123-8.19 51.123-8.19v11.204z"
            className="page-11_svg__cls-8"
            style={{
              fill: palette.secondary,
              strokeWidth: 0.355022,
            }}
          />

          <foreignObject
            x={52.005}
            y={146.032}
            width={120}
            height={77}
            className="clickable"
          >
            <div
              style={{
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                fontWeight: "400",
                color: palette.backgroundGray,
                textAlign: "start",
                width: "100%",
                height: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.message,
                  field: "message",
                  label: "Mensagem da Candidatura",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.message}
            </div>
          </foreignObject>
        </svg>
      </PaperInside>
    </Paper>
  );
};

export default Page11;
