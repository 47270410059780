import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { ActionMeta, components } from "react-select";
import { Status } from "../../../app/constants";
import { AppDispatch } from "../../../app/store";
import CandidateService from "../../../features/candidates/service";
import {
  searchCandidates,
  selectCandidates,
  selectCandidatesStatus,
} from "../../../features/candidates/slice";
import { CandidateSearch } from "../../../features/candidates/type";
import { CandidateSearchItemDetails } from "../../../pages/candidates/search-candidates/components/candidate-search-item/candidate-search-item";
import DashboardFilterText from "../../text/dashboard-filter-text/dashboard-filter-text";
import "./candidate-select.css";

type SelectOption = {
  value: any;
  label: any;
};

type Props = {
  label: string;
  className?: string;
  value?: any;
  placeHolder?: string;
  options?: SelectOption[];
  isDisabled?: boolean;
  isLoading?: boolean;
  onChange?: (newValue: any, actionMeta: ActionMeta<any>) => void;
};

type ContainerProps = {
  children?: string | JSX.Element | JSX.Element[];
  className?: string;
};

const CandidateSelectContainer: React.FC<ContainerProps> = ({
  children,
  className,
}) => {
  return (
    <div className={`candidate-select-outer-container ${className}`}>
      {children}
    </div>
  );
};

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className="candidate-custom-css-class">
        Nenhum Candidato Encontrado
      </span>
    </components.NoOptionsMessage>
  );
};

const formatOptionLabel = ({ value, label, subLabel }) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
      }}
    >
      <CandidateSearchItemDetails
        compactMode={true}
        candidate={
          {
            id: value,
            name: subLabel,
            ballotbox_name: label,
          } as any
        }
      />
    </div>
  );
};

const style = {
  control: (base) => ({
    ...base,
    border: "1px solid #D5DAE1",
    "&:hover": {
      border: "1px solid #D5DAE1",
    },
    boxShadow: "none",
  }),
  option: (base, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...base,
      backgroundColor: isFocused ? "#f1f1f1" : "#ffffff",
      color: "#27262e;",
      "&:active": {
        backgroundColor: "#D5DAE1",
      },
    };
  },
  valueContainer: (base, state) => ({
    ...base,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  }),
};

const filterOption = (option, inputValue) => {
  return true;
};

const CandidateSelect: React.FC<Props> = ({
  label,
  className,
  value,
  placeHolder,
  isDisabled,
  onChange,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const candidates: CandidateSearch[] = useSelector(selectCandidates);
  const status: Status = useSelector(selectCandidatesStatus);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  useEffect(() => {
    const activateSearchTimeout = () => {
      clearTimeout(searchTimeout);
      setSearchTimeout(
        setTimeout(() => {
          dispatch(
            searchCandidates({
              search: inputValue.toUpperCase(),
              service: new CandidateService(),
            })
          );
        }, 1250)
      );
    };

    activateSearchTimeout();
    // eslint-disable-next-line
  }, [dispatch, inputValue]);

  useEffect(() => {
    const displayCandidatesSearchResult = () => {
      if (candidates) {
        setOptions(
          candidates.map((c) => ({
            label: c.ballotbox_name,
            subLabel: c.name,
            value: c.id,
          }))
        );
      }
    };

    displayCandidatesSearchResult();
  }, [candidates]);

  return (
    <CandidateSelectContainer className={className}>
      <DashboardFilterText>{label}</DashboardFilterText>

      <Select
        styles={style}
        components={{ NoOptionsMessage }}
        formatOptionLabel={formatOptionLabel}
        placeholder={"Busque um candidato"}
        classNames={{
          container: (state) => "candidate-select-container",
          control: (state) => "candidate-select",
          placeholder: (props) => "candidate-select-placeholder",
          option: (props) => {
            return props.isSelected
              ? "candidate-select-selected-option"
              : "candidate-select-option";
          },
          menu: (props) => "candidate-select-menu",
        }}
        value={value}
        options={options}
        onChange={onChange}
        onInputChange={setInputValue}
        filterOption={filterOption}
        isDisabled={isDisabled}
        loadingMessage={() => "Carregando..."}
        isLoading={status === Status.LOADING}
      />
    </CandidateSelectContainer>
  );
};

export default memo(CandidateSelect);
