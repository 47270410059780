import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pages, TargetPages } from "../../app/constants";
import { AppDispatch } from "../../app/store";
import { selectCurrentPage, setCurrentPage } from "../../features/target/slice";
import CandidateDetailsPage from "../../pages/targets/candidate-details/candidate-details-page";
import FillingStrategySelectionPage from "../../pages/targets/filling-strategy-selection/filling-strategy-selection-page";
import { HomePage } from "../../pages/targets/home/home";
import OfficeSelectionPage from "../../pages/targets/office-selection/office-selection-page";
import PrimaryPrioritiesPage from "../../pages/targets/priorities-selection/primary-priorities-page";
import SecondaryPrioritiesPage from "../../pages/targets/priorities-selection/secondary-priorities-page";
import StateSelectionPage from "../../pages/targets/state-selection/state-selection-page";
import TargetDashboardPage from "../../pages/targets/target-dashboard/dashboard-page";
import TargetSelectionPage from "../../pages/targets/target-selection/target-selection-page";
import { makeContainer } from "../container/container";
import Page from "../main-page/page";
import TitleText from "../text/title-text/title-text";
import "./page.css";

type PageProps = {
  title?: string | JSX.Element | JSX.Element[];
  page?: Pages;
  children?: JSX.Element;
};

const PageContainer = makeContainer("target-page-container");

const PageContent = {
  [TargetPages.HOME]: HomePage,
  [TargetPages.STATE_SELECTION]: StateSelectionPage,
  [TargetPages.OFFICE_SELECTION]: OfficeSelectionPage,
  [TargetPages.TARGET_SELECTION]: TargetSelectionPage,
  [TargetPages.CANDIDATE_DETAILS]: CandidateDetailsPage,
  [TargetPages.PRIMARY_PRIORITIES_SELECTION]: PrimaryPrioritiesPage,
  [TargetPages.SECONDARY_PRIORITIES_SELECTION]: SecondaryPrioritiesPage,
  [TargetPages.FILLING_STRATEGY_SELECTION]: FillingStrategySelectionPage,
  [TargetPages.TARGET_DASHBOARD]: TargetDashboardPage,
};

const PageGoBack = {
  [TargetPages.HOME]: null,
  [TargetPages.STATE_SELECTION]: TargetPages.HOME,
  [TargetPages.OFFICE_SELECTION]: TargetPages.STATE_SELECTION,
  [TargetPages.TARGET_SELECTION]: TargetPages.OFFICE_SELECTION,
  [TargetPages.CANDIDATE_DETAILS]: TargetPages.TARGET_SELECTION,
  [TargetPages.PRIMARY_PRIORITIES_SELECTION]: TargetPages.CANDIDATE_DETAILS,
  [TargetPages.SECONDARY_PRIORITIES_SELECTION]:
    TargetPages.PRIMARY_PRIORITIES_SELECTION,
  [TargetPages.FILLING_STRATEGY_SELECTION]:
    TargetPages.SECONDARY_PRIORITIES_SELECTION,
  [TargetPages.TARGET_DASHBOARD]: null,
};

export const TargetPage: React.FC<PageProps> = ({ children, title, page }) => {
  const dispatch: AppDispatch = useDispatch();
  const currentPage = useSelector(selectCurrentPage);
  let TargetPage = PageContent[currentPage];

  const goBack = (page: TargetPages) => {
    if (!page) {
      return null;
    }

    return () => {
      dispatch(setCurrentPage(page));
    };
  };

  return (
    <Page
      title={<TitleText>{"Meta de Votos"}</TitleText>}
      page={Pages.TARGET}
      onGoBack={goBack(PageGoBack[currentPage])}
    >
      <PageContainer>
        <TargetPage />
      </PageContainer>
    </Page>
  );
};

export default TargetPage;
