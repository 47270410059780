import { memo } from "react";
import "./widget-title-text.css";

import { BiInfoCircle } from "react-icons/bi";
import Tooltip from "../../tooltip/tooltip";

type Props = {
  children: string;
  className?: string;
  hideIcon?: boolean;
  tooltipTitle?: string;
  tooltipDescription?: string;
};

const WidgetTitleText: React.FC<Props> = ({
  children,
  className,
  hideIcon,
  tooltipTitle,
  tooltipDescription,
}) => {
  return (
    <div className={`widget-title-text ${className ? className : ""}`}>
      {!hideIcon && tooltipDescription && (
        <Tooltip title={tooltipTitle} text={tooltipDescription}>
          <BiInfoCircle className={"widget-title-info-icon"} />
        </Tooltip>
      )}
      {!hideIcon && !tooltipDescription && (
        <BiInfoCircle className={"widget-title-info-icon"} />
      )}
      <span className="widget-text-span">{children}</span>
    </div>
  );
};

export default memo(WidgetTitleText);
