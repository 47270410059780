import React from "react";
import Image from "../../resources/images/intro-page-3.svg";
import FourthPage from "./components/fourth-page";
import Page from "./components/page";

const Login: React.FC = () => {
  return (
    <Page image={Image}>
      <FourthPage />
    </Page>
  );
};

export default Login;
