import HttpClient from "../../app/client";
import handleError from "../../app/errors";
import { Candidate, CandidateSearch } from "../candidates/type";
import {
  CartItem,
  CartPrice,
  CreateCartItemRequest,
  CreateOrderRequest,
  IShopService,
  Order,
  Product,
} from "./type";

const validOffices = [
  "VEREADOR",
  "PREFEITO",
  "DEPUTADO ESTADUAL",
  "DEPUTADO FEDERAL",
  "SENADOR",
  "GOVERNADOR",
];

export default class ShopService implements IShopService {
  async addCartItem(cartItem: CreateCartItemRequest): Promise<CartItem> {
    try {
      const client = new HttpClient("shop", false, []);
      return await client.post<CartItem>(`/shop/cart/`, cartItem);
    } catch (error) {
      handleError("Error while adding cart item", error);
    }
  }

  async removeCartItem(id: string): Promise<void> {
    try {
      const client = new HttpClient("shop", false, [404]);
      await client.delete(`/shop/cart/${id}`, {});
    } catch (error) {
      handleError("Error while removing cart item", error);
    }
  }

  async getCart(): Promise<CartItem[]> {
    try {
      const client = new HttpClient("shop", false, []);
      return await client.get<CartItem>(`/shop/cart`);
    } catch (error) {
      handleError("Error while getting car", error);
    }
  }

  async getCartPrice(code: string): Promise<CartPrice> {
    try {
      const client = new HttpClient("shop", false, [404]);
      if (code) {
        return await client.get<CartItem>(`/shop/cart/price?code=${code}`);
      } else {
        return await client.get<CartItem>(`/shop/cart/price`);
      }
    } catch (error) {
      handleError("Error while getting cart price", error);
    }
  }

  async createOrder(createOrderRequest: CreateOrderRequest): Promise<Order> {
    try {
      const client = new HttpClient("shop", false, []);
      return await client.post<Order>(`/shop/orders`, createOrderRequest);
    } catch (error) {
      handleError("Error while creating order", error);
    }
  }

  async getOrders(): Promise<Order[]> {
    try {
      const client = new HttpClient("shop", false, []);
      return await client.get<Order[]>(`/shop/orders`);
    } catch (error) {
      handleError("Error while getting orders", error);
    }
  }

  async getOrderDetails(id: string): Promise<Order> {
    try {
      const client = new HttpClient("shop", false, []);
      return await client.get<Order>(`/shop/orders/${id}`);
    } catch (error) {
      handleError("Error while getting order details", error);
    }
  }

  async getProducts(): Promise<Product[]> {
    try {
      const client = new HttpClient("shop", false, []);
      return await client.get<Product[]>(`/shop/products`);
    } catch (error) {
      handleError("Error while getting products", error);
    }
  }

  async getCandidatePhoto(candidateId: string): Promise<string> {
    try {
      const client = new HttpClient("candidates", true, [404]);
      return await client.get<string>(`/candidates/${candidateId}/photo`);
    } catch (error) {
      handleError("Error while getting candidate photo", error);
    }
  }

  async searchCandidates(search: string): Promise<CandidateSearch[]> {
    try {
      if (!search) {
        return [];
      }

      const client = new HttpClient("candidates", true, [404]);
      return await client.post<CandidateSearch[]>(`/shop/candidates/search`, {
        search: search,
      });
    } catch (error) {
      handleError("Error while getting candidate", error);
    }
  }

  async getCandidate(candidateId: string): Promise<Candidate> {
    try {
      const client = new HttpClient("candidates", true, []);
      const candidate: Candidate = await client.get<Candidate>(
        `/shop/candidates/${candidateId}`
      );

      candidate.election_summary = candidate.election_summary.filter(
        (summary) =>
          validOffices.includes(summary.office) &&
          (summary.totalizationsituationfirstturn?.includes("ELEITO") ||
            summary.totalizationsituationsecondturn?.includes("ELEITO") ||
            summary.totalizationsituationfirstturn?.includes("SUPLENTE") ||
            summary.totalizationsituationsecondturn?.includes("SUPLENTE"))
      );

      return candidate;
    } catch (error) {
      handleError("Error while getting candidate", error);
    }
  }
}
