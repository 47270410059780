import SimpleBar from "simplebar-react";
import Loading from "../loading/loading";
import "./table.css";

type TableColumn = {
  label: String;
  style?: React.CSSProperties;
};

type Props = {
  data: any[];
  columns: TableColumn[];
  cells: any[];
  loading?: Boolean;
};

export const Table: React.FC<Props> = (props) => {
  const { columns, data, cells, loading } = props;

  return (
    <div className="table-container">
      <div className="t-header">
        <div className="t-line">
          {columns.map((column, index) => (
            <div key={`col-${index}`} className="t-cell" style={column.style}>
              {column.label}
            </div>
          ))}
        </div>
      </div>

      {!loading && (
        <div className="t-body">
          <SimpleBar style={{ maxHeight: "100%" }}>
            {data.map((row, index) => (
              <div key={`line-${index}`} className="t-line t-line-data">
                {cells.map((Cell, cellIndex) => (
                  <div
                    key={`cell-${index}-${cellIndex}`}
                    className="t-cell"
                    style={columns[cellIndex].style}
                  >
                    <Cell row={row} />
                  </div>
                ))}
              </div>
            ))}
          </SimpleBar>
        </div>
      )}

      {loading && (
        <div className="t-body">
          <Loading />
        </div>
      )}
    </div>
  );
};
