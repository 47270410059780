import { memo } from "react";
import "./profile-title-text.css";

type Props = {
  children: string;
  className?: string;
};

const TitleText: React.FC<Props> = ({ children, className }) => {
  return <span className={`profile-title-text ${className}`}>{children}</span>;
};

export default memo(TitleText);
