import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Attribute, { AttributeTypes } from "../../../app/attribute";
import { DashboardFilterConsts, Mode, Status } from "../../../app/constants";
import { hasFilter } from "../../../app/filter";
import { MapFeatures } from "../../../app/maps";
import { AppDispatch } from "../../../app/store";
import Widget from "../../../components/dashboard/widget/widget";
import {
  electorateService,
  votingService,
} from "../../../features/dashboard/service";
import {
  getWidgetData,
  selectAttributes,
  selectComparativeMode,
  selectDashboardComparativeFilters,
  selectDashboardFilters,
  selectMapAttribute,
  selectMapDataStatus,
  selectMapFeatures,
  selectWidgetAttribute,
  selectWidgetData,
  selectWidgetDataStatus,
  selectZoneMode,
  setWidgetAttribute,
  setWidgetData,
} from "../../../features/dashboard/slice";
import {
  GroupAttributeData,
  QueryResult,
} from "../../../features/dashboard/types";
import { selectMapsStatus } from "../../../features/maps/slice";

type Props = {
  mode: Mode;
};
const MAP_ATTRIBUTE = "map_attribute";

const isFromCurrentMode = (mode: Mode, attribute: Attribute) => {
  const origin = mode === Mode.VOTING ? "voting" : "electorate";
  return attribute.origin === origin;
};

const getService = (mode: Mode) => {
  return mode === Mode.VOTING ? votingService : electorateService;
};

const addMapAttribute = (
  mode: Mode,
  comparativeMode: boolean,
  attributes: Attribute[]
) => {
  const copiedAttributes = [...attributes];

  copiedAttributes.unshift({
    friendly_name: `Atributo do Mapa`,
    description: "Lista com os dados do mapa ordenados",
    origin: mode === Mode.VOTING ? "voting" : "electorate",
    name: MAP_ATTRIBUTE,
    suffix: "",
    valid_filters: [],
    valid_levels: [],
    type: AttributeTypes.GROUP,
  });

  if (comparativeMode) {
    return [copiedAttributes[0]];
  }

  return copiedAttributes;
};

const toGroupAttributeData = (map: MapFeatures): QueryResult => {
  const valuesSum = map?.features?.reduce(
    (previous, current) => previous + current.numericalValue,
    0
  );

  let sorted = map?.features ? [...map?.features] : [];
  sorted?.sort((a, b) => (a.numericalValue < b.numericalValue ? 1 : -1));

  const groupData: GroupAttributeData[] = sorted?.map((feature) => ({
    group_name: feature.name,
    group_subname: "",
    group_id: "",
    group_value: feature.numericalValue,
    group_relative_value: Math.floor(
      (10000 * feature.numericalValue) / valuesSum
    ),
  }));

  return {
    scenario_result: groupData,
    comparative_result: groupData,
    compare_scenario_result: groupData,
    relative_comparative_result: groupData,
  };
};

const WidgetWithData: React.FC<Props> = ({ mode }) => {
  const dispatch: AppDispatch = useDispatch();
  const attributes: Attribute[] = useSelector(selectAttributes);
  const dashFilters = useSelector(selectDashboardFilters);
  const dashComparativeFilters = useSelector(selectDashboardComparativeFilters);
  const comparativeMode: boolean = useSelector(selectComparativeMode);
  const zoneMode: boolean = useSelector(selectZoneMode);
  const attribute: Attribute = useSelector(selectWidgetAttribute);
  const data: QueryResult = useSelector(selectWidgetData);
  const dataStatus = useSelector(selectWidgetDataStatus);

  const map: MapFeatures = useSelector(selectMapFeatures);
  const mapAttribute: Attribute = useSelector(selectMapAttribute);
  const rawMapsStatus: Status = useSelector(selectMapsStatus);
  const mapDataStatus: Status = useSelector(selectMapDataStatus);

  const [status, setStatus] = useState(Status.LOADING);
  const [mapAttributeMode, setMapAttributeMode] = useState(true);

  const dataSource = data?.scenario_result as GroupAttributeData[];

  useEffect(() => {
    const updateStatus = () => {
      if (mapAttributeMode) {
        const newStatus =
          rawMapsStatus !== Status.SUCCEEDED ||
          mapDataStatus !== Status.SUCCEEDED
            ? Status.LOADING
            : Status.SUCCEEDED;

        setStatus(newStatus);
        return;
      }

      if ([Status.LOADING, Status.SUCCEEDED].includes(dataStatus)) {
        setStatus(dataStatus);
      }
    };

    updateStatus();
    // eslint-disable-next-line
  }, [dataStatus, mapAttributeMode, rawMapsStatus, mapDataStatus]);

  useEffect(() => {
    const setInitialAttribute = () => {
      if (attributes && !attribute) {
        const newAttribute = addMapAttribute(
          mode,
          comparativeMode,
          attributes
        )[0];
        if (newAttribute) {
          dispatch(setWidgetAttribute(newAttribute));
        }
      }
    };

    setInitialAttribute();
  }, [dispatch, mode, comparativeMode, attribute, mapAttribute, attributes]);

  useEffect(() => {
    const fetchData = () => {
      if (
        attribute &&
        isFromCurrentMode(mode, attribute) &&
        hasFilter(dashFilters, DashboardFilterConsts.YEAR)
      ) {
        if (!mapAttributeMode && attribute.name !== MAP_ATTRIBUTE) {
          dispatch(
            getWidgetData({
              attribute: attribute,
              filters: dashFilters,
              comparativeFilters: dashComparativeFilters,
              comparativeMode,
              zoneMode,
              service: getService(mode),
            })
          );
        }
      }
    };

    fetchData();
  }, [
    dispatch,
    mode,
    comparativeMode,
    zoneMode,
    attribute,
    dashFilters,
    dashComparativeFilters,
    mapAttributeMode,
  ]);

  useEffect(() => {
    const setMapDataAttribute = () => {
      if (mapAttributeMode) {
        const widgetData = toGroupAttributeData(map);
        dispatch(setWidgetData(widgetData));
      }
    };

    setMapDataAttribute();
  }, [dispatch, map, mapAttributeMode]);

  return (
    <Widget
      attribute={attribute}
      attributes={addMapAttribute(mode, comparativeMode, attributes)}
      data={dataSource}
      status={status}
      onAttributeSelected={(newAttribute) => {
        setStatus(Status.LOADING);
        dispatch(setWidgetAttribute(newAttribute));
        setMapAttributeMode(newAttribute.name === MAP_ATTRIBUTE);
      }}
    />
  );
};

export default WidgetWithData;
