import { DashboardFilterConsts } from "./constants";

// The name of filter
export type FilterNameValue = {
  label: string; // what is shown to the user
  value: string; // actual filter name used in the api
};

// A possible value for a filter
export type FilterValue = {
  label: string; // what is shown to the user
  value: any; // actual filter value used in the api
  image?: any; // image to illustrate the filter (e.g. candidate photo)
};

// A filter used to query data from the api
export type ActiveFilter = {
  name: FilterNameValue; // The name of the filter
  value: FilterValue; // The value of the filter
};

export function create(
  name: FilterNameValue,
  value: FilterValue
): ActiveFilter {
  return { name, value };
}

export function createName(label: string, value: any): FilterNameValue {
  return { label, value };
}

export function createValue(label: string, value: any): FilterValue {
  return { label, value };
}

export function getName(filter: ActiveFilter) {
  return filter?.name?.value ?? null;
}

export function toFilterNames(values: string[]) {
  return values.map((v) => ({ label: v, value: v }));
}

export function hasFilter(
  filters: ActiveFilter[],
  filterNameValue: string
): boolean {
  if (!filters || !filters.length) return false;
  return filters.some((f) => f.name.value === filterNameValue);
}

export function get(
  filters: ActiveFilter[],
  filterNameValue: string
): FilterValue {
  if (!filters || !filters.length) return null;
  return filters.find((f) => f.name.value === filterNameValue)?.value ?? null;
}

export function getValue(
  filters: ActiveFilter[],
  filterNameValue: string
): any {
  if (!filters || !filters.length) return null;
  return (
    filters.find((f) => f.name.value === filterNameValue)?.value?.value ?? null
  );
}

export function add(
  filters: ActiveFilter[],
  newFilter: ActiveFilter
): ActiveFilter[] {
  if (!filters || !filters.length) return newFilter ? [newFilter] : [];

  const existent = filters.find(
    (f) =>
      f.name.value === newFilter.name.value &&
      f.value.value === newFilter.value.value
  );

  if (existent) {
    return [...filters];
  }

  if (newFilter.name.value === DashboardFilterConsts.YEAR) {
    filters = filters.filter(
      (f) => f.name.value !== DashboardFilterConsts.YEAR
    );
  }

  return [...filters, newFilter];
}

export function remove(
  filters: ActiveFilter[],
  filterNameValue: string
): ActiveFilter[] {
  if (!filters || !filters.length) return [];
  return filters.filter((f) => f.name.value !== filterNameValue);
}

export function removeWithValue(
  filters: ActiveFilter[],
  filterToRemove: ActiveFilter
): ActiveFilter[] {
  if (!filters || !filters.length) return [];

  return filters.filter(
    (f) =>
      f.name.value !== filterToRemove.name.value ||
      f.value.value !== filterToRemove.value.value
  );
}

export function removeDanglingFilters(
  removedFilter: ActiveFilter,
  filters: ActiveFilter[]
): ActiveFilter[] {
  const possibleDangling = {
    [DashboardFilterConsts.CITY]: [DashboardFilterConsts.BURROW],
    [DashboardFilterConsts.STATE]: [
      DashboardFilterConsts.CITY,
      DashboardFilterConsts.BURROW,
    ],
  };

  let filtersCopied = [...filters];
  const filterName = getName(removedFilter);

  if (filterName in possibleDangling) {
    for (const dangling of possibleDangling[filterName]) {
      if (hasFilter(filtersCopied, dangling)) {
        filtersCopied = remove(filtersCopied, dangling);
      }
    }
  }

  return filtersCopied;
}

export function removeComplementaryFilters(
  removedFilter: ActiveFilter,
  filters: ActiveFilter[]
) {
  const filtersToRemove = [
    DashboardFilterConsts.STATE.toString(),
    DashboardFilterConsts.CITY.toString(),
    DashboardFilterConsts.BURROW.toString(),
  ];

  const filterName = getName(removedFilter);
  if (filtersToRemove.includes(filterName)) {
    return remove(filters, filterName);
  }

  return filters;
}

export function removeAll(filters: ActiveFilter[]): ActiveFilter[] {
  return [];
}

export function replace(
  filters: ActiveFilter[],
  newFilter: ActiveFilter
): ActiveFilter[] {
  const filtersWithoutNewFilter = remove(filters, getName(newFilter));
  return add(filtersWithoutNewFilter, newFilter);
}

export function toFilterValue(value: any, options: FilterValue[]) {
  return options.find((o) => o.value === value);
}
