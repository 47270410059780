import * as React from "react";
import { useSelector } from "react-redux";
import { validateText } from "../../../../../app/utils";
import { selectPalette } from "../../../../../features/notebook/slice";
import { ClippedImage, Paper, PaperInside } from "../commons/commons";
import { TemplatePageProps } from "../commons/interfaces";

const Page7: React.FC<TemplatePageProps> = ({
  className,
  notebook,
  triggerImageEdit,
  triggerFieldEdit,
  updateField,
}) => {
  const { palette } = useSelector(selectPalette);
  return (
    <Paper className={className}>
      <PaperInside>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
        >
          <defs>
            <filter id="page-7_svg__a">
              <feDropShadow
                dx="-3"
                dy="-3"
                stdDeviation="7"
                floodColor="#000000"
                floodOpacity="0.5"
              />
            </filter>
          </defs>

          <ClippedImage
            id="emerald-page-7"
            triggerImageEdit={triggerImageEdit}
            x={0}
            y={0}
            offsetX={-50}
            width={210}
            height={297}
            imageNumber={8}
            imageRatio={0.7}
            notebook={notebook}
          >
            <rect width="100%" height="100%" fill={palette.backgroundLight} />
          </ClippedImage>

          <path
            d="M1757 1337.56h266.35v560.88h-118A148.39 148.39 0 0 1 1757 1750.05Z"
            className="page-7_svg__cls-71"
            style={{
              fill: palette.backgroundLight,
              filter: "url(#page-7_svg__a)",
            }}
            transform="matrix(.35176 0 0 .35176 -506.3 -471.87)"
          />

          <path
            d="M191.266 18.343a10.27 10.27 0 0 1 1.955 6.16 7.1 7.1 0 0 1-.415 2.462 6.3 6.3 0 0 1-1.174 2.008 5.4 5.4 0 0 1-1.826 1.358 5.2 5.2 0 0 1-2.427.443 5.37 5.37 0 0 1-3.704-1.643 4.84 4.84 0 0 1-1.407-3.784 6.2 6.2 0 0 1 .524-2.294 3.55 3.55 0 0 1 1.407-1.643 1.5 1.5 0 0 0 .601-.703.83.83 0 0 0-.133-.862 59 59 0 0 1-.859-.992 24 24 0 0 0-1.016-1.097 1.32 1.32 0 0 1-.471-.887 1.46 1.46 0 0 1 .207-.939l.992-1.358a1.48 1.48 0 0 1 .834-.598 1.44 1.44 0 0 1 1.055.025q3.9 1.632 5.857 4.344m-12.263 0a10.27 10.27 0 0 1 1.956 6.16 7.1 7.1 0 0 1-.415 2.462 6.2 6.2 0 0 1-1.175 2.008 5.4 5.4 0 0 1-1.829 1.358 5.2 5.2 0 0 1-2.423.443 5.38 5.38 0 0 1-3.708-1.643 4.85 4.85 0 0 1-1.407-3.784 6.3 6.3 0 0 1 .52-2.27 3.55 3.55 0 0 1 1.408-1.642 1.47 1.47 0 0 0 .598-.703.82.82 0 0 0-.13-.862 59 59 0 0 1-.859-.992 22 22 0 0 0-1.02-1.098 1.32 1.32 0 0 1-.468-.886 1.46 1.46 0 0 1 .208-.94l.992-1.357a1.48 1.48 0 0 1 .833-.598 1.44 1.44 0 0 1 1.056.025q3.908 1.607 5.863 4.32M.005 284.557a50.8 50.8 0 0 1-1.13 12.902h118.522s-34.335-25.06-83.212-22.847c-18.478.838-27.831 3.827-34.553 7.176q.184 1.385.373 2.769"
            className="page-7_svg__cls-25"
            style={{
              fill: palette.primary,
              strokeWidth: 0.351758,
            }}
          />

          <foreignObject
            x={115.005}
            y={40.032}
            width={87}
            height={120}
            className="clickable"
          >
            <div
              style={{
                fontSize: "9px",
                fontFamily: "MontserratAlternates",
                fontWeight: "700",
                color: palette.backgroundGray,
                textAlign: "center",
                width: "100%",
                height: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.slogan,
                  field: "slogan",
                  label: "Slogan",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.slogan}
            </div>
          </foreignObject>
        </svg>
      </PaperInside>
    </Paper>
  );
};

export default Page7;
