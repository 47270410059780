import { memo } from "react";
import "./candidate-search-result-header-text.css";

type Props = {
  children: string;
  className: string;
};

const CandidateSearchResultHeaderText: React.FC<Props> = ({
  children,
  className,
}) => {
  return (
    <span className={`candidate-search-result-header-text ${className}`}>
      {children}
    </span>
  );
};

export default memo(CandidateSearchResultHeaderText);
