import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { brazilianStates, Status, TargetPages } from "../../../app/constants";
import { AppDispatch } from "../../../app/store";
import TargetFormButton from "../../../components/buttons/target-form-button/target-form-button";
import { makeContainer } from "../../../components/container/container";
import TargetSelect from "../../../components/inputs/target-select/target-select";
import TargetFormContainer from "../../../components/target-page/target-form-container";
import PermissionService from "../../../features/permission/service";
import {
  getPermissions,
  selectPermissions,
  selectPermissionsStatus,
} from "../../../features/permission/slice";
import { PermissionsData } from "../../../features/permission/type";
import {
  selectCreateTargetRequest,
  setCreateTargetRequest,
  setCurrentPage,
} from "../../../features/target/slice";
import { CreateTargetRequest } from "../../../features/target/type";
import "./state-selection-page.css";

const SelectStateForm = makeContainer("select-state-form");

const getStatesWithTarget = (permissions: PermissionsData) => {
  const statePermissions = permissions?.statePermissions?.filter(
    (p) => p.yearsWithTarget.length
  );
  const statesWithTarget = statePermissions?.map((s) => s.state);
  return brazilianStates.filter((s) => statesWithTarget?.includes(s.value));
};

export const StateSelectionPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const createTargetRequest: CreateTargetRequest = useSelector(
    selectCreateTargetRequest
  );
  const permissions: PermissionsData = useSelector(selectPermissions);
  const status: Status = useSelector(selectPermissionsStatus);
  const statesWithTarget = getStatesWithTarget(permissions);
  const selectedState = brazilianStates.find(
    (s) => s.value === createTargetRequest?.state
  );

  const goToNext = () => {
    dispatch(setCurrentPage(TargetPages.OFFICE_SELECTION));
  };

  useEffect(() => {
    dispatch(getPermissions(new PermissionService()));
  }, [dispatch]);

  return (
    <TargetFormContainer
      title="Selecione o estado onde irá se candidatar"
      step={1}
    >
      <SelectStateForm>
        <div>
          <TargetSelect
            label=""
            options={statesWithTarget}
            className=""
            placeHolder={"Selecione um estado"}
            value={selectedState}
            isLoading={status === Status.LOADING}
            onChange={(e) => {
              const newRequest = { ...createTargetRequest };
              newRequest["state"] = e.value;
              dispatch(setCreateTargetRequest(newRequest));
            }}
          />
        </div>

        <TargetFormButton selected disabled={!selectedState} onClick={goToNext}>
          Próximo Passo
        </TargetFormButton>
      </SelectStateForm>
    </TargetFormContainer>
  );
};

export default StateSelectionPage;
