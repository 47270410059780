const Cellphone = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={69.776}
    height={139.085}
    viewBox="0 0 69.776 139.085"
  >
    <defs>
      <linearGradient
        xlinkHref="#cell-a"
        id="cell-d"
        x1={1109.93}
        x2={1109.93}
        y1={2877.96}
        y2={2926.12}
        gradientTransform="rotate(180 1109.93 2901.79)"
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient
        id="cell-a"
        x1={1109.93}
        x2={1109.93}
        y1={2877.96}
        y2={2926.12}
        gradientTransform="rotate(180 1109.93 2901.79)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#221e20" />
        <stop offset={0.01} stopColor="#252123" />
        <stop offset={0.31} stopColor="#666568" />
        <stop offset={0.45} stopColor="#7f8083" />
        <stop offset={0.56} stopColor="#727376" />
        <stop offset={0.76} stopColor="#515052" />
        <stop offset={1} stopColor="#221e20" />
      </linearGradient>
      <linearGradient
        xlinkHref="#cell-a"
        id="cell-e"
        x1={915.47}
        x2={915.47}
        y1={2840.13}
        y2={2856.33}
      />
      <linearGradient
        xlinkHref="#cell-a"
        id="cell-f"
        x1={915.47}
        x2={915.47}
        y1={2867.59}
        y2={2897.75}
      />
      <linearGradient
        xlinkHref="#cell-a"
        id="cell-g"
        x1={915.47}
        x2={915.47}
        y1={2904.83}
        y2={2935.65}
      />
      <linearGradient
        xlinkHref="#cell-a"
        id="cell-h"
        x1={915.74}
        x2={1109.93}
        y1={2985.63}
        y2={2985.63}
      />
      <linearGradient
        xlinkHref="#cell-b"
        id="i"
        x1={1012.83}
        x2={1012.83}
        y1={3177.07}
        y2={2793.34}
        gradientTransform="rotate(-90 1012.835 2985.635)"
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient
        id="cell-b"
        x1={1012.83}
        x2={1012.83}
        y1={3177.07}
        y2={2793.34}
        gradientTransform="rotate(-90 1012.835 2985.635)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4e4d4d" />
        <stop offset={1} />
      </linearGradient>
      <linearGradient
        xlinkHref="#c"
        id="cell-j"
        x1={1102.71}
        x2={1106.98}
        y1={2985.63}
        y2={2985.63}
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient
        id="cell-c"
        x1={1102.71}
        x2={1106.98}
        y1={2985.63}
        y2={2985.63}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopOpacity={0} />
        <stop offset={0.2} stopColor="#3d3d3d" stopOpacity={0.24} />
        <stop offset={0.49} stopColor="#8f8f8f" stopOpacity={0.56} />
        <stop offset={0.73} stopColor="#ccc" stopOpacity={0.8} />
        <stop offset={0.91} stopColor="#cell-f1f1f1" stopOpacity={0.94} />
        <stop offset={1} stopColor="#cell-fff" />
      </linearGradient>
      <linearGradient
        xlinkHref="#c"
        id="k"
        x1={9408.36}
        x2={9412.62}
        y1={2985.63}
        y2={2985.63}
        gradientTransform="matrix(-1 0 0 1 10331.31 0)"
      />
      <linearGradient
        id="l"
        x1={1012.83}
        x2={1012.83}
        y1={2803.59}
        y2={2800.51}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4e4d4d" />
        <stop offset={0.08} stopColor="#434242" />
        <stop offset={0.31} stopColor="#262525" />
        <stop offset={0.55} stopColor="#111" />
        <stop offset={0.78} stopColor="#040404" />
        <stop offset={1} />
      </linearGradient>
      <linearGradient
        xlinkHref="#cell-b"
        id="m"
        x1={1036.97}
        x2={1031.42}
        y1={2805.36}
        y2={2799.82}
      />
      <linearGradient
        id="n"
        x1={1036.44}
        x2={1031.9}
        y1={2804.83}
        y2={2800.29}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} />
        <stop offset={1} stopColor="#4e4d4d" />
      </linearGradient>
      <linearGradient
        id="o"
        x1={1032.14}
        x2={1036.29}
        y1={2800.53}
        y2={2804.68}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#315aa5" />
        <stop offset={0.69} stopOpacity={0} />
      </linearGradient>
      <radialGradient
        id="p"
        cx={1034.26}
        cy={2805.03}
        r={2.59}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#315aa5" />
        <stop offset={0.86} stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="q"
        cx={1036.5}
        cy={2801.62}
        r={1.7}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#315aa5" />
        <stop offset={0.7} stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="r"
        cx={1032.45}
        cy={2803.31}
        r={2.58}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#cell-fff" />
        <stop offset={0.15} stopColor="#cell-bdbdbd" stopOpacity={0.74} />
        <stop offset={0.29} stopColor="#838383" stopOpacity={0.52} />
        <stop offset={0.43} stopColor="#545454" stopOpacity={0.33} />
        <stop offset={0.57} stopColor="#303030" stopOpacity={0.19} />
        <stop offset={0.69} stopColor="#151515" stopOpacity={0.08} />
        <stop offset={0.8} stopColor="#060606" stopOpacity={0.02} />
        <stop offset={0.89} stopOpacity={0} />
      </radialGradient>
    </defs>
    <g transform="translate(-320.911 -979.43) scale(.35134)">
      <path
        d="M1109.85 2877.71h2.15v48.16h-2.13a2 2 0 0 1-2-2v-44.22a2 2 0 0 1 1.98-1.94z"
        className="cls-75"
        style={{
          fill: "url(#cell-d)",
        }}
        transform="rotate(180 1109.93 2901.79)"
      />
      <path
        d="M915.39 2840.22h2.13v16.14h-2.13a2 2 0 0 1-2-2v-12.19a2 2 0 0 1 2-1.95z"
        className="cls-76"
        style={{
          fill: "url(#cell-e)",
        }}
      />
      <path
        d="M915.39 2867.4h2.13v30.41h-2.13a2 2 0 0 1-2-2v-26.47a2 2 0 0 1 2-1.94z"
        className="cls-77"
        style={{
          fill: "url(#cell-f)",
        }}
      />
      <path
        d="M915.39 2904.83h2.13v30.41h-2.13a2 2 0 0 1-2-2v-26.44a2 2 0 0 1 2-1.97z"
        className="cls-78"
        style={{
          fill: "url(#cell-g)",
        }}
      />
      <rect
        width={194.19}
        height={395.87}
        x={915.74}
        y={2787.69}
        className="cls-79"
        rx={28.22}
        style={{
          fill: "url(#cell-h)",
        }}
      />
      <rect
        width={388.43}
        height={186.74}
        x={818.62}
        y={2892.26}
        rx={24.28}
        transform="rotate(90 1012.835 2985.635)"
      />
      <path
        d="M842.68 2896H1183a20.33 20.33 0 0 1 20.33 20.33v138.63a20.34 20.34 0 0 1-20.34 20.34H842.68a20.34 20.34 0 0 1-20.34-20.34v-138.64a20.34 20.34 0 0 1 20.34-20.32Z"
        className="cls-80"
        style={{
          fill: "url(#i)",
        }}
        transform="rotate(90 1012.835 2985.635)"
      />
      <rect
        width={378}
        height={176.32}
        x={823.84}
        y={2897.47}
        className="cls-10"
        rx={18.76}
        style={{
          fill: props.screenColor,
        }}
        transform="rotate(90 1012.835 2985.635)"
      />
      <path
        d="M1103.46 3158.77v-346.28a22.9 22.9 0 0 0-8.69-18 23 23 0 0 1 11.44 19.83v342.56a22.94 22.94 0 0 1-11.44 19.83 22.88 22.88 0 0 0 8.69-17.94z"
        className="cls-81"
        style={{
          mixBlendMode: "screen",
          fill: "url(#cell-j)",
        }}
      />
      <path
        d="M922.21 3158.77v-346.28a22.9 22.9 0 0 1 8.69-18 23 23 0 0 0-11.44 19.83v342.56a22.94 22.94 0 0 0 11.44 19.83 22.88 22.88 0 0 1-8.69-17.94z"
        className="cls-82"
        style={{
          mixBlendMode: "screen",
          fill: "url(#k)",
        }}
      />
      <path d="M961.35 2795.66a7.91 7.91 0 0 1 3.57 5.71c.5 4.47 2.24 9.93 9.56 9.93h76.71c7.32 0 9.06-5.46 9.56-9.93a7.91 7.91 0 0 1 3.57-5.71z" />
      <rect
        width={24.48}
        height={2.92}
        x={1000.59}
        y={2800.56}
        className="cls-83"
        rx={1.14}
        style={{
          fill: "url(#l)",
        }}
      />
      <circle cx={1034.04} cy={2802.44} r={2.59} />
      <circle
        cx={1034.04}
        cy={2802.44}
        r={3.72}
        className="cls-84"
        style={{
          fill: "url(#m)",
        }}
      />
      <circle
        cx={1034.04}
        cy={2802.44}
        r={3.05}
        className="cls-85"
        style={{
          mixBlendMode: "multiply",
          fill: "url(#n)",
        }}
      />
      <circle
        cx={1034.04}
        cy={2802.44}
        r={2.59}
        className="cls-86"
        style={{
          fill: "url(#o)",
        }}
      />
      <circle
        cx={1034.04}
        cy={2802.44}
        r={2.59}
        className="cls-87"
        style={{
          fill: "url(#p)",
        }}
      />
      <circle
        cx={1034.04}
        cy={2802.44}
        r={2.59}
        className="cls-88"
        style={{
          fill: "url(#q)",
        }}
      />
      <circle
        cx={1034.04}
        cy={2802.44}
        r={2.59}
        className="cls-89"
        style={{
          opacity: 0.5,
          mixBlendMode: "screen",
          fill: "url(#r)",
        }}
      />
      <text
        className="cls-90"
        style={{
          fontWeight: 800,
          fontSize: "5.11px",
          fontFamily: "Gotham-Black,&quot",
          fill: "#2b2a2a",
        }}
        transform="translate(935.84 2809.26)"
      >
        {"8:30"}
      </text>
      <path
        d="M1064.52 2808.29h1.18v1.93h-1.18zM1066.21 2807.52h1.18v2.71h-1.18zM1067.9 2806.76h1.18v3.46h-1.18zM1069.59 2805.91h1.18v4.32h-1.18zM1073.34 2808.1a3.77 3.77 0 0 1 5.26 0l.79-.79a4.88 4.88 0 0 0-6.84 0z"
        className="cls-53"
        style={{
          fill: "#2b2a2a",
        }}
      />
      <path
        d="M1074.4 2809.16a2.27 2.27 0 0 1 3.14 0l.79-.79a3.36 3.36 0 0 0-4.72 0z"
        className="cls-53"
        style={{
          fill: "#2b2a2a",
        }}
      />
      <path
        d="M1075.47 2810.23a.75.75 0 0 1 .5-.2.77.77 0 0 1 .51.2l.79-.8a1.86 1.86 0 0 0-2.6 0z"
        className="cls-53"
        style={{
          fill: "#2b2a2a",
        }}
      />
      <rect
        width={7.52}
        height={3.27}
        x={1081.05}
        y={2806.43}
        className="cls-53"
        rx={0.22}
        style={{
          fill: "#2b2a2a",
        }}
      />
      <rect
        width={0.6}
        height={1.41}
        x={1088.74}
        y={2807.36}
        className="cls-53"
        rx={0.22}
        style={{
          fill: "#2b2a2a",
        }}
      />
    </g>
  </svg>
);
export default Cellphone;
