import HttpClient from "../../app/client";
import handleError from "../../app/errors";
import { Constants, IConstantsService } from "./type";

export default class ConstantsService implements IConstantsService {
  async getConstants() {
    try {
      const client = new HttpClient("constants", true);
      return await client.get<Constants>("/constants/");
    } catch (error) {
      handleError("Error during loging in", error);
    }
  }
}
