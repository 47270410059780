import React, { memo } from "react";
import { Status } from "../../../../app/constants";
import { Map } from "../../../../app/utils";
import { makeContainer } from "../../../../components/container/container";
import Loading from "../../../../components/loading/loading";
import NoData from "../../../../components/no-data/no-data";
import ScrollContainer from "../../../../components/scroll/scroll";
import { ElectionRankingItem } from "../../../../features/elections/type";
import ElectionRankingItemContainer from "./election-ranking-item";
import "./election-ranking.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  result?: ElectionRankingItem[];
  status?: Status;
  photos?: Map;
  onVisibilityChange?: (candidateId: string, isVisible: boolean) => void;
};

const ElectionRankingContainer = makeContainer("election-ranking-container");

const ElectionRanking: React.FC<Props> = ({
  result,
  status,
  photos,
  onVisibilityChange,
}) => {
  const isReady = result && status === Status.SUCCEEDED;
  const isLoading = status === Status.LOADING;
  const isEmpty =
    status === Status.IDLE || (status === Status.SUCCEEDED && !result);

  return (
    <ElectionRankingContainer>
      {isReady && (
        <ScrollContainer>
          <>
            {result?.map((rankingItem) => (
              <ElectionRankingItemContainer
                key={rankingItem.candidateId}
                rankingItem={rankingItem}
                photos={photos}
                onVisibilityChange={onVisibilityChange}
              />
            ))}
          </>
        </ScrollContainer>
      )}
      {isLoading && <Loading />}
      {isEmpty && <NoData />}
    </ElectionRankingContainer>
  );
};

export default memo(ElectionRanking);
