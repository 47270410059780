import * as React from "react";
import { useSelector } from "react-redux";
import { validateText } from "../../../../../app/utils";
import { selectPalette } from "../../../../../features/notebook/slice";
import { ClippedImage, Paper, PaperInside } from "../commons/commons";
import { TemplatePageProps } from "../commons/interfaces";

const Page3: React.FC<TemplatePageProps> = ({
  className,
  notebook,
  triggerImageEdit,
  triggerFieldEdit,
  updateField,
}) => {
  const { palette } = useSelector(selectPalette);

  return (
    <Paper className={className}>
      <PaperInside>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
        >
          <defs>
            <filter id="page-3_svg__a">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="4"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <filter id="page-3_svg__b">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="4"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <clipPath id="page-3_svg__c">
              <path
                d="M1824.64 675.56h-390.98V473.14h390.98z"
                className="page-3_svg__cls-5"
              />
            </clipPath>
          </defs>
          <path
            d="M.664.774h209.795v296.707H.664z"
            style={{ fill: palette.backgroundLight, strokeWidth: 0.35243 }}
          />
          <path
            d="M1624.42 629.14h400.18v605h-436.25V665.21a36.07 36.07 0 0 1 36.07-36.07"
            className="page-3_svg__cls-10"
            style={{
              fill: palette.backgroundLight,
              filter: "url(#page-3_svg__a)",
            }}
            transform="matrix(-.34633 0 0 -.34633 756.166 482.396)"
          />
          <path
            d="M1600.07 1012.13h143.21v143.21a175.34 175.34 0 0 1-175.34 175.34 143.21 143.21 0 0 1-143.21-143.21 175.34 175.34 0 0 1 175.34-175.34"
            className="page-3_svg__cls-11"
            style={{
              fill: palette.primary,
              filter: "url(#page-3_svg__b)",
            }}
            transform="matrix(-.34633 0 0 -.34633 602.062 648.49)"
          />
          <foreignObject
            x={118.637}
            y={61.45}
            width={88}
            height={47}
            className="clickable"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                fontSize: "8.9259px",
                fontFamily: "Heavitas",
                color: palette.primary,
                strokeWidth: 0.352449,
                width: "100%",
                height: "24px",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.candidate_ballout_box_name,
                  field: "candidate_ballout_box_name",
                  label: "Nome de Urna",
                  type: "input",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.candidate_ballout_box_name}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                fontSize: "5.9259px",
                fontFamily: "MontserratAlternates",
                color: palette.backgroundGray,
                strokeWidth: 0.352449,
                width: "100%",
                marginTop: "1px",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.place,
                  field: "place",
                  label: "Munícipio",
                  type: "input",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.place}
            </div>
          </foreignObject>
          <text
            x={139.919}
            y={117.084}
            className="page-3_svg__cls-29"
            style={{
              fontSize: "7px",
              letterSpacing: "0.02em",
              fontFamily: "Heavitas",
              fill: palette.backgroundGray,
              strokeWidth: 0.35243,
            }}
          >
            {"ORIGEM"}
          </text>
          <foreignObject
            x={120.005}
            y={122.032}
            width={80}
            height={54}
            className="clickable"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: palette.backgroundGray,
                strokeWidth: 0.352449,
                width: "100%",
                height: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.birthplace,
                  field: "birthplace",
                  label: "Local de Nascimento",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.birthplace}
            </div>
          </foreignObject>

          <path
            d="M118.637 85.769h81.717"
            className="page-3_svg__cls-31"
            style={{
              fill: "none",
              stroke: "#1d1d1b",
              strokeWidth: 0.346332,
              strokeMiterlimit: 10,
            }}
          />

          <text
            x={140.522}
            y={190.746}
            className="page-3_svg__cls-29"
            style={{
              letterSpacing: "0.02em",
              fontSize: "7px",
              fontFamily: "Heavitas",
              fill: palette.backgroundGray,
              strokeWidth: 0.35243,
            }}
          >
            {"FORMA\xC7\xC3O"}
          </text>

          <foreignObject
            x={120.005}
            y={194.032}
            width={80}
            height={60}
            className="clickable"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: palette.backgroundGray,
                strokeWidth: 0.352449,
                width: "100%",
                height: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.schooling,
                  field: "schooling",
                  label: "Onde estudou",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.schooling}
            </div>
          </foreignObject>

          <ClippedImage
            id="saphire-page-3"
            triggerImageEdit={triggerImageEdit}
            x={0}
            y={65}
            width={110}
            height={191}
            imageNumber={3}
            imageRatio={0.62}
            notebook={notebook}
          >
            <path
              d="M1371.19 201.36a166.7 166.7 0 0 1 166.7 166.7v369.4h-138.3a166.7 166.7 0 0 1-166.7-166.7V339.67a138.3 138.3 0 0 1 138.3-138.3z"
              style={{ fill: palette.backgroundLight }}
              transform="translate(-433.375 -5.055) scale(.35243)"
            />
          </ClippedImage>

          <path
            d="M1799.15 779.24c.68.14 1.38.24 2 .43a10.83 10.83 0 0 1 6.4 15.94c-2.83 4.77-5.68 9.54-8.51 14.32a.56.56 0 0 1-.47.26 1.41 1.41 0 0 1-1.59-.89c-2.68-4.58-5.42-9.13-8.13-13.69a10.87 10.87 0 0 1 8.21-16.31 1 1 0 0 0 .23-.06zm1.89 10.85a2.85 2.85 0 0 0-2.8-2.79 2.8 2.8 0 0 0-2.79 2.78 2.73 2.73 0 0 0 2.8 2.79 2.77 2.77 0 0 0 2.75-2.78z"
            className="page-3_svg__cls-33"
            style={{
              fill: "#6c6c6c",
            }}
            transform="matrix(.34633 0 0 .34633 -495.12 -162.901)"
          />
          <path
            d="M1816.16 815.08h-35.84a4.3 4.3 0 0 1 .25-1.91c1.86-3.68 3.67-7.37 5.5-11.07a.5.5 0 0 1 .52-.33h3.19a.54.54 0 0 1 .55.3c2 3.31 3.92 6.61 5.88 9.91a.53.53 0 0 0 .52.3h3a.48.48 0 0 0 .48-.29q2.94-4.95 5.89-9.91a.55.55 0 0 1 .55-.32h3.15a.43.43 0 0 1 .47.29q2.81 5.59 5.63 11.19c.06.12.14.23.21.35zM1814.44 1008.2l-4.5.91-8 1.61-4.36.85a6.9 6.9 0 0 1-2.83-.25l-9-1.78-9.64-1.95-1.6-.33a1.2 1.2 0 0 1-1.06-1.18 1.11 1.11 0 0 1 .93-1.18c1.34-.31 2.69-.56 4-.84l8.8-1.77c3-.6 5.94-1.26 8.93-1.74a8.6 8.6 0 0 1 2.92.26c2.53.47 5.06 1 7.59 1.52l9.33 1.89 3 .58c1 .18 1.41.84 1.14 1.68a1.14 1.14 0 0 1-.93.77c-.48.11-1 .19-1.46.29-.24 0-.36.12-.36.41v9a.78.78 0 0 0 .19.47 2.22 2.22 0 0 1 .11 2.77 2.19 2.19 0 1 1-3.49-2.64 1.2 1.2 0 0 0 .27-.78v-8.08z"
            className="page-3_svg__cls-33"
            style={{
              fill: "#6c6c6c",
            }}
            transform="matrix(.34633 0 0 .34633 -495.12 -162.901)"
          />
          <path
            d="M1783.14 1010.48c2.92.58 5.83 1.17 8.75 1.74a40 40 0 0 0 4.41.78 12.4 12.4 0 0 0 3.14-.32c2.82-.53 5.63-1.11 8.44-1.68l2.64-.52v7.45a1.54 1.54 0 0 1-.72 1.33 11.2 11.2 0 0 1-4.25 1.6 44.8 44.8 0 0 1-11.68.75 34 34 0 0 1-7-1 8.7 8.7 0 0 1-3.1-1.35 1.47 1.47 0 0 1-.67-1.21v-7.39a.5.5 0 0 1 .04-.18"
            className="page-3_svg__cls-33"
            style={{
              fill: "#6c6c6c",
            }}
            transform="matrix(.34633 0 0 .34633 -495.12 -162.901)"
          />
          <g
            className="page-3_svg__cls-340"
            clipPath="url(#page-3_svg__c)"
            transform="matrix(.34633 0 0 .34633 -495.12 -162.901)"
          >
            <g
              className="page-3_svg__cls-9"
              data-name="CZJK68"
              style={{
                opacity: 0.13,
              }}
            >
              <path d="M1435.93 148.81a87 87 0 0 0 8.25 3.79c-15.35 16.76-31.13 29.6-47.63 35.45-28.49 10.1-55.34-3.75-80.38-42.91-18.8-29.42-34.6-68.69-48.86-113-1.85-5.76-3.72-11.46-5.59-17.19V0c5 14.77 9.81 29.88 14.93 44.24 18 50.57 38.38 91.74 63.06 114.8 20 18.65 40.4 22 61.14 13.65 12.09-4.85 23.73-13.18 35.08-23.88" />
              <path d="M1465.06 158q3.62.44 7.22.53c-13.3 19.17-27 36.25-41.29 49.89-16 15.26-32.37 25.25-49.36 26.38C1355 236.53 1331 215 1309 174.47c-17.48-32.22-32.07-72.9-45.31-117.48-.63-2.11-1.31-4.1-2-6.14v-15c5.82 18 11.41 36.71 17.51 54 20.66 58.5 44.18 104.38 73.67 123.12 20.92 13.29 41.73 9.88 62.4-4.14 17.39-11.73 33.91-29.3 49.79-50.83" />
              <path d="M1460.18 204c11-14.58 21.69-30.66 32.2-47.58a78 78 0 0 0 7-2c-16.27 27.52-32.9 53.34-50.43 74.85-16.58 20.36-33.67 36.16-51.75 43.34-30.26 12-58.13-5.3-83.79-50.46-15-26.39-28-59-39.6-96.07-4.07-13-8-26.23-12.07-39.36V71.79c4.52 14.9 8.84 30.29 13.59 44.61 15.49 46.69 32.63 87.77 53.63 115.12q28.74 37.43 60.45 30.74c25.13-5.26 48.31-28.49 70.77-58.26" />
              <path d="M1520.72 154q-1.2-2.58-2.36-5.19c-12.95 22.41-25.94 44.69-39.14 65.92-19.89 32-40.75 58.45-63.15 74.71-23.13 16.8-46.29 19.54-69.42.3-22.08-18.36-40.91-52.08-57.08-96.39-9.85-27-18.6-57-27.85-85.67v16.45c1.88 5.92 3.86 11.64 5.63 17.8 11.24 39.07 23.85 74.31 38.42 104 29.5 60.23 62.21 82.28 98.47 62.54 22-12 42.31-35.67 62-64.14 18.81-27.17 36.76-58.26 54.48-90.33" />
              <path d="M1527 178.71c1.1-2 2.2-4.05 3.31-6.07-.77-1.35-1.52-2.72-2.26-4.09q-13.08 23.23-26.22 46.26c-20.5 35.82-41.68 68.37-64.39 92.77-17.74 19.06-36 31.94-55.17 33-24 1.35-45.93-17.08-66.11-52.19-18-31.27-32.91-71.67-46.33-116.61-2.68-9-5.41-17.82-8.12-26.72v15c1.17 3.71 2.38 7.32 3.49 11.16 15.15 52.43 32 99.77 53.1 134.48 25.11 41.33 52.14 55.08 81 42.33 19.74-8.74 38.25-27.91 56.15-51.86 25.05-33.57 48.39-75.17 71.55-117.46" />
              <path d="M1540.91 189.82c-.9-1.35-1.78-2.71-2.66-4.07-20.16 35.4-40.36 70.61-60.89 104.3-21.52 35.3-44.42 62.65-69.08 77.24-28.12 16.62-55.28 9.79-81.14-26.37-22.72-31.77-40.84-78.63-57-132q-4.22-14-8.46-27.95v15c.6 1.79 1.26 3.48 1.81 5.4 15.13 53.09 32 101.08 53.1 136.68 23.26 39.26 48.48 55.39 75.63 47.36 20.74-6.13 40.19-24.69 59-48.73 23.54-30.1 45.51-67.75 67.23-106.59q11.25-20.09 22.46-40.27" />
              <path d="M1551.71 206.06a244 244 0 0 1-2.25-3.34q-1.1 1.92-2.17 3.84c-25.83 45.64-51.4 92.43-78.72 131.26-21.62 30.73-43.94 56.25-68.22 66.56-24.65 10.47-48.36 2.67-71-26.93-22-28.8-39.86-72-55.75-122-4-12.7-7.94-25.75-11.9-38.63v14.95c.6 1.83 1.24 3.58 1.79 5.5C1278 287 1294 332.08 1313.67 366.46q39.4 68.81 85.51 48.89c19.74-8.49 38.3-27.22 56.27-50.65 25.84-33.68 49.9-75.82 73.84-118.51 7.48-13.34 14.94-26.78 22.42-40.13" />
              <path d="M1561.76 219.67c-12.93 21.63-25.82 43.51-38.74 65.24-21.64 36.39-43.31 72.6-66.44 101.54-21.36 26.71-43.34 47.39-67 52.2s-45.91-8.09-66.84-38.55c-18.5-26.92-34.06-63.94-48-106.27-4.44-13.43-8.67-27.37-13-41.08v15c.54 1.65 1.11 3.24 1.63 5 12.08 40.35 25.29 77.49 40.75 108.22C1333.87 440 1366.78 462 1403 444c17.28-8.59 33.74-24.22 49.72-44 23.85-29.48 46.18-66.47 68.37-104.15 14.35-24.37 28.66-48.94 43-73.21-.73-.99-1.53-1.98-2.33-2.97" />
              <path d="M1575.09 234.75c-13.16 20.73-26.14 42.38-39.16 63.83-23.3 38.4-46.58 76.91-71.25 108.54-22.07 28.3-44.76 51.13-69.18 58.65-20.9 6.44-41.2.19-60.83-20.9-21.22-22.79-39.16-59-55.09-103-6.19-17.09-11.92-35.42-17.86-53.19v15c.55 1.66 1.11 3.28 1.64 5 15.42 49.21 32.51 93 53.32 124.84 23 35.19 47.6 49.41 73.77 43.28 19.19-4.5 37.49-19 55.3-38.62 25.39-27.93 49.08-64.91 72.56-103 19.94-32.39 39.78-65.29 59.83-97.21-1.03-1.16-2.04-2.19-3.05-3.22" />
              <path d="M1591.2 258c1.32-1.94 2.64-3.85 4-5.76-1.08-.79-2.17-1.59-3.24-2.42-10.14 14.19-20.15 29.15-30.13 44.31-26.07 39.59-52 80-78.34 118.08-22.71 32.81-46.48 59-71.78 73.17-21.41 12-42.78 13-64.09-1.91-22.39-15.69-42-46-59.29-87.42-9.33-22.37-17.75-47.6-26.58-71.53v14.95c10 30.55 20.79 59.06 32.8 83.84 26 53.59 54.94 82.75 87.66 80.42 21.5-1.53 42.07-15.72 62.13-36.17 25-25.52 48.54-59.65 71.81-95.21 24.98-38.19 49.85-77.3 75.05-114.35" />
              <path d="M1617.37 265.83c-1-.5-2-1-3-1.57-14.75 16.7-29.14 35.8-43.39 55.75-27 37.82-53.78 77.07-81 113.93-27.35 37.08-55.65 67.39-86.09 79.75-15.35 6.23-30.69 6.89-46 .22-22.32-9.71-42.47-33.91-61-69.06-12.88-24.47-24.36-53.36-35.15-84.42v16.45c.76 1.61 1.55 3.11 2.26 4.86 7.23 17.75 14.13 36.61 21.71 53.15 26.77 58.41 57 91.74 91.55 92.46 21.69.45 42.63-11.5 63.14-30 23.81-21.45 46.42-50.6 68.69-81.82 28.23-39.58 56.22-80.53 84.67-118.88 8-10.83 16.16-21 24.41-30.24-.28-.19-.55-.41-.8-.58" />
              <path d="M1644.11 282.88c-1.5 1.14-3 2.3-4.49 3.51-27.62 22.28-53.79 54.46-79.76 88-29 37.46-57.58 77.26-87.44 109.46-25.36 27.35-51.24 49-78.59 55-28.93 6.34-56.46-7.2-82.44-43-18.56-25.58-34.81-59.92-49.67-99.48v16.46c.58 1.07 1.18 2.05 1.73 3.24 8.36 18.27 16.4 37.83 25.14 54.62 22.77 43.75 48.07 69.77 75.84 76.42 20.23 4.84 40.14-1.33 59.84-13.94 25.14-16.08 49.11-41.35 72.52-70.65 26.93-33.72 53.58-69.17 80.5-103 23.59-29.61 47.53-56.53 72.62-74-1.91-.81-3.91-1.69-5.8-2.64" />
              <path d="M1261.72 433.71c4.15 8.71 8.27 17.5 12.45 26.11 21 43.26 44 74.55 69.79 89.77 18.78 11.09 37.72 12.74 56.77 7.45 30-8.33 58.55-31.67 86.42-61.72 27.53-29.71 54.76-61.55 82.15-92.3 25.23-28.32 50.72-54.5 77.31-71.73 17.78-11.52 35.77-18.61 54.06-18.4 34.64.39 66.31 27.73 95.15 80.25a7.5 7.5 0 0 1 .94 3.08 18.5 18.5 0 0 1-1.65-2.18c-18.41-32-38.25-54.23-59.59-65.89-15.91-8.69-32-10.42-48.17-7.25-36.08 7.1-70.06 37.1-103.35 73.35-30.21 32.85-60.12 67.74-90.29 100.87-23.55 25.87-47.63 47.4-72.59 60.63-32.61 17.3-65 17.51-96.89-11.24-22.22-20-42.06-51.88-60.26-91.86-.69-1.51-1.48-2.66-2.22-4z" />
              <path d="M1261.72 469.61c1.89 3.31 3.81 6.48 5.66 10 23.8 45.11 49.76 76.1 78.32 89.7a80.47 80.47 0 0 0 54.71 5.71c35.07-8.28 68.6-34.66 101.7-65.75 27.47-25.8 54.78-52.94 82.24-78.76 25.28-23.77 51-43.23 77.44-54.34 46.06-19.34 90.25-5.69 131.68 56.47a53.7 53.7 0 0 1 5.46 10 17 17 0 0 1-1.68-1.91q-35.64-53.57-76.08-63.51c-22.15-5.47-44.15-1.22-66 9.37-36.41 17.62-71.21 50.45-106 84-28.15 27.21-56.21 55.28-85 77.19-26.6 20.23-53.55 34.84-81.23 36.6q-64.86 4.1-118.68-95.82c-.82-1.51-1.71-2.68-2.57-4z" />
              <path d="M1261.72 505.5c5.53 7.89 11 16.09 16.6 23.62 25.07 33.74 51.53 53.64 79.33 60.41 23.78 5.79 47.41 2 71-7.36 31.78-12.66 62.66-35.72 93.54-59.12 29.46-22.33 58.9-44.86 88.5-65.71 23.17-16.32 46.79-26.46 70.7-30.6 41.11-7.12 80.7 9.37 118.18 58.1a11.3 11.3 0 0 1 2 4.26c-4.66-5.13-9.29-10.48-14-15.35-23.22-24.11-47.28-36.78-72.06-40.31-31.08-4.44-61.61 6.49-92 23.51-33.26 18.65-65.79 45.05-98.46 70-27.88 21.31-55.85 41.71-84.38 55.62-26 12.66-52.08 19.29-78.44 15.34-34.84-5.22-67.79-29.79-98.62-75.08a27 27 0 0 0-2-2.37z" />
              <path d="M1261.72 541.39c9.43 9.38 18.76 19.68 28.32 27.93 22 19 44.64 29.47 67.68 33.72 26.42 4.87 52.7 1 78.94-7.5 30.23-9.76 60-26.33 89.84-42.55 30.35-16.5 60.66-33.69 91.13-48.31 29.57-14.19 59.45-21.66 89.53-19.83 33 2 65.2 16.28 96.31 47.4a6.8 6.8 0 0 1 1.86 3.23c-4.69-3.86-9.36-8-14.08-11.54-20.66-15.72-41.7-25-63.08-29.18a140 140 0 0 0-70.31 3.89 505.6 505.6 0 0 0-69.29 27.62c-35 17.07-69.77 37.81-104.79 54.67-21.91 10.55-44.06 17.67-66.17 24.79-20.46 6.58-41 7.3-61.63 4.13-32-4.91-63.17-21.43-93.22-52.72a7.3 7.3 0 0 0-1-.79z" />
            </g>
          </g>
          <path
            d="M119.489-1.51s24.641-.527 47.33 9.597c21.573 9.624 33.975 18.778 40.23 20.489 7.882 2.157 6.486-.15 6.486-.15V-2.05Z"
            className="page-3_svg__cls-8"
            style={{
              fill: palette.secondary,
              strokeWidth: 0.346332,
            }}
          />
        </svg>
      </PaperInside>
    </Paper>
  );
};

export default Page3;
