import { memo, MouseEventHandler } from "react";
import "./target-form-button.css";

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  selected?: boolean;
  disabled?: boolean;
  children: string | JSX.Element | JSX.Element[];
};

const TargetFormButton: React.FC<Props> = (props) => {
  const { onClick, disabled, children } = props;

  return (
    <button
      className={
        !disabled ? "target-form-button" : "target-form-button-disabled"
      }
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default memo(TargetFormButton);
