import { memo, useEffect, useRef, useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { NotebookPages, NOTEBOOK_PAGE, Pages } from "../../../app/constants";
import { AppDispatch } from "../../../app/store";
import { getFirstWordOrEmailPrefix } from "../../../app/utils";
import { selectAuthUserData } from "../../../features/auth/slice";
import { UserData } from "../../../features/auth/type";
import { selectMenuData, setExpanded } from "../../../features/menu/slice";
import MenuData from "../../../features/menu/type";
import UserSillhouette from "../../../resources/icons/user-silhouette.svg";
import HamburguerButton from "../../buttons/hamburguer-button/hamburguer-button";
import PopMenu from "../../menu/pop-menu";
import LogoutModal from "../logout-modal/logout-modal";

import PermissionService from "../../../features/permission/service";
import { getPermissions } from "../../../features/permission/slice";
import "./title-bar.css";

type TitleBarProps = {
  children?: string | JSX.Element | JSX.Element[];
};

const UserPhotoDisplay: React.FC<{ photo: string }> = memo(({ photo }) => {
  return (
    <img
      className="user-photo-display"
      src={photo ? photo : UserSillhouette}
      alt="foto do usuario"
    />
  );
});

const UserNameDisplay: React.FC = memo(() => {
  const dispatch: AppDispatch = useDispatch();
  const [menuOpened, setMenuOpenened] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const userData: UserData = useSelector(selectAuthUserData);
  const userDisplayRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getPermissions(new PermissionService()));
  }, [dispatch]);

  function handleClick(link: string) {
    return () => navigate(`/${link}`);
  }

  const entries = [
    {
      key: "Loja",
      label: "Loja",
      onClick: handleClick(Pages.SHOP),
    },
    {
      key: "Meus Dados",
      label: "Meus Dados",
      onClick: handleClick(Pages.PROFILE),
    },
    {
      key: "Meu Caderno",
      label: "Meu Caderno",
      onClick: () => {
        handleClick(`${NOTEBOOK_PAGE}/${NotebookPages.NOTEBOOK_VIEW}`)();
      },
    },
    {
      key: "Configurações",
      label: "Configurações",
      onClick: handleClick(Pages.SETTINGS),
    },
    { key: "Logout", label: "Logout", onClick: () => setShowLogoutModal(true) },
  ];

  return (
    <PopMenu
      open={menuOpened}
      entries={entries}
      containerRef={userDisplayRef}
      fixedWidth={150}
      onClose={() => setMenuOpenened(false)}
    >
      <div
        ref={userDisplayRef}
        className="user-name-display"
        onClick={() => setMenuOpenened(true)}
      >
        <UserPhotoDisplay photo={userData.photoUrl} />
        <div className="user-name-text">
          {getFirstWordOrEmailPrefix(userData.name)}
        </div>
      </div>

      <LogoutModal
        show={showLogoutModal}
        onClose={() => setShowLogoutModal(false)}
      />
    </PopMenu>
  );
});

const TitleBar: React.FC<TitleBarProps> = (props) => {
  const dispatch: AppDispatch = useDispatch();
  const menuData: MenuData = useSelector(selectMenuData);

  return (
    <div className="title-bar">
      <div className="title">
        {menuData.onGoBack && (
          <BsChevronLeft
            className="title-bar-btn"
            onClick={menuData.onGoBack}
          />
        )}
        {menuData.currentPageTitle}
      </div>
      <div className="user-details">
        <UserNameDisplay />
      </div>
      <div className="hamburguer-area">
        <HamburguerButton
          onClick={() => {
            dispatch(setExpanded(true));
          }}
        />
      </div>
    </div>
  );
};

export default memo(TitleBar);
