import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DATAELEGE_STRATEGY,
  PROPORTIONAL_STRATEGY,
} from "../../../app/constants";
import { AppDispatch } from "../../../app/store";
import TargetFormButton from "../../../components/buttons/target-form-button/target-form-button";
import TargetSelectionButton from "../../../components/buttons/target-selection-button/target-selection-button";
import { makeContainer } from "../../../components/container/container";
import TargetFormContainer from "../../../components/target-page/target-form-container";
import ConstantsService from "../../../features/constants/service";
import { getConstants } from "../../../features/constants/slice";
import MapsService from "../../../features/maps/service";
import { getCities } from "../../../features/maps/slice";
import TargetService from "../../../features/target/service";
import {
  createTarget,
  selectCreateTargetRequest,
  setCreateTargetRequest,
} from "../../../features/target/slice";
import { CreateTargetRequest } from "../../../features/target/type";
import CreateTargetModal from "./components/create-target-modal";
import "./filling-strategy-selection-page.css";

const SelectStrategyForm = makeContainer("select-strategy-form");
const SelectStrategyButtonGroup = makeContainer("select-strategy-button-group");
const SelectStrategyInfoText = makeContainer("select-strategy-info-text");

export const FillingStrategySelectionPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const createTargetRequest: CreateTargetRequest = useSelector(
    selectCreateTargetRequest
  );
  const [showCreateModal, setShowCreateModal] = useState(false);

  const goToNext = () => {
    dispatch(
      createTarget({
        request: createTargetRequest,
        service: new TargetService(),
      })
    );
    setShowCreateModal(true);
  };

  const canAdvance = () => {
    return createTargetRequest["filling_strategy"];
  };

  const selectFillingStrategy = (strategy) => () => {
    const newRequest = { ...createTargetRequest };
    newRequest["filling_strategy"] = strategy;
    dispatch(setCreateTargetRequest(newRequest));
  };

  useEffect(() => {
    dispatch(getConstants({ service: new ConstantsService() }));
  }, [dispatch]);

  useEffect(() => {
    if (createTargetRequest) {
      dispatch(
        getCities({
          service: new MapsService(),
          stateId: createTargetRequest.state,
        })
      );
    }
  }, [dispatch, createTargetRequest]);

  return (
    <TargetFormContainer
      title="Selecione a estratégia de preenchimento"
      step={7}
    >
      <SelectStrategyForm>
        <SelectStrategyButtonGroup>
          <TargetSelectionButton
            selected={
              createTargetRequest["filling_strategy"] === DATAELEGE_STRATEGY
            }
            onClick={selectFillingStrategy(DATAELEGE_STRATEGY)}
          >
            Estratégia DataElege
          </TargetSelectionButton>
          <TargetSelectionButton
            selected={
              createTargetRequest["filling_strategy"] === PROPORTIONAL_STRATEGY
            }
            onClick={selectFillingStrategy(PROPORTIONAL_STRATEGY)}
          >
            Estratégia Proporcional
          </TargetSelectionButton>
        </SelectStrategyButtonGroup>

        {createTargetRequest["filling_strategy"] === DATAELEGE_STRATEGY && (
          <SelectStrategyInfoText>
            <>
              A estratégia de preenchimento automático da <b>DataElege</b>{" "}
              estabelece metas personalizadas de acordo com as características
              do candidato. (recomendado)
            </>
          </SelectStrategyInfoText>
        )}

        {createTargetRequest["filling_strategy"] === PROPORTIONAL_STRATEGY && (
          <SelectStrategyInfoText>
            <>
              A estratégia de preenchimento <b>proporcional</b> distribui os
              votos de acordo com a quantidade de eleitores em cada município ou
              bairro.
            </>
          </SelectStrategyInfoText>
        )}

        <TargetFormButton disabled={!canAdvance()} onClick={goToNext}>
          Gerar Meta
        </TargetFormButton>
      </SelectStrategyForm>

      <CreateTargetModal
        show={showCreateModal}
        onClose={() => setShowCreateModal(false)}
      />
    </TargetFormContainer>
  );
};

export default FillingStrategySelectionPage;
