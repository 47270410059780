import HttpClient from "../../app/client";
import handleError from "../../app/errors";
import { Candidate, CandidateSearch, ICandidateService } from "./type";

export default class CandidateService implements ICandidateService {
  async getCandidatePhoto(candidateId: string): Promise<string> {
    try {
      const client = new HttpClient("candidates", true, [404]);
      return await client.get<string>(`/candidates/${candidateId}/photo`);
    } catch (error) {
      handleError("Error while getting candidate photo", error);
    }
  }

  async searchCandidates(search: string): Promise<CandidateSearch[]> {
    try {
      if (!search) {
        return [];
      }

      const client = new HttpClient("candidates", true, [404]);
      return await client.post<CandidateSearch[]>(`/candidates/search`, {
        search: search,
      });
    } catch (error) {
      handleError("Error while getting candidate", error);
    }
  }

  async getCandidate(candidateId: string): Promise<Candidate> {
    try {
      const client = new HttpClient("candidates", true, []);
      return await client.get<CandidateSearch[]>(`/candidates/${candidateId}`);
    } catch (error) {
      handleError("Error while getting candidate", error);
    }
  }
}
