import { memo } from "react";
import { makeContainer } from "../../../../../components/container/container";
import PartyAcronymText from "../../../../../components/text/party-acronym-text/party-acronym-text";
import PartyNameText from "../../../../../components/text/party-name-text/party-name-text";
import WidgetTitleText from "../../../../../components/text/widget-title-text/widget-title-text";
import PartyService from "../../../../../features/parties/service";
import { Party } from "../../../../../features/parties/type";
import "./party-profile-card.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  party?: Party;
};

const PartyProfileCardContainer = makeContainer("party-profile-card-container");
const PartyProfileCardSubContainer = makeContainer(
  "party-profile-card-sub-container"
);
const ImagePane = makeContainer("party-profile-card-image-pane");
const DetailsPane = makeContainer("party-profile-card-details-pane");
const WidgetTitleContainer = makeContainer(
  "party-profile-card-title-container"
);

const PartyProfileCard: React.FC<Props> = ({ party }) => {
  const service = new PartyService();

  return (
    <PartyProfileCardContainer>
      <WidgetTitleContainer>
        <WidgetTitleText>{"Informação do Partido"}</WidgetTitleText>
      </WidgetTitleContainer>
      <PartyProfileCardSubContainer>
        <ImagePane>
          <img
            className="party-profile-card-logo"
            alt="logo do partido"
            src={service.getPartyLogo(party?.abbrev)}
          />
        </ImagePane>
        <DetailsPane>
          <PartyAcronymText>{party?.abbrev}</PartyAcronymText>
          <PartyNameText>{party?.name}</PartyNameText>
        </DetailsPane>
      </PartyProfileCardSubContainer>
    </PartyProfileCardContainer>
  );
};

export default memo(PartyProfileCard);
