import { memo, MouseEventHandler } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import "./add-filter-button.css";

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const AddFilterButton: React.FC<Props> = (props) => {
  const { onClick } = props;

  return (
    <button className={"add-filter-button"} onClick={onClick}>
      <AiOutlinePlus />
    </button>
  );
};

export default memo(AddFilterButton);
