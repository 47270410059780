import * as React from "react";
import { useSelector } from "react-redux";
import { validateText } from "../../../../../app/utils";
import { selectPalette } from "../../../../../features/notebook/slice";
import { ClippedImage, Paper, PaperInside } from "../commons/commons";
import { TemplatePageProps } from "../commons/interfaces";

const Page9: React.FC<TemplatePageProps> = ({
  className,
  notebook,
  triggerImageEdit,
  triggerFieldEdit,
  updateField,
}) => {
  const { palette } = useSelector(selectPalette);
  return (
    <Paper className={className}>
      <PaperInside>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
        >
          <defs>
            <clipPath id="page-9_svg__b">
              <path
                d="M671.51 2399.08a135.3 135.3 0 0 1 135.3 135.3v112.25H694.56a135.3 135.3 0 0 1-135.3-135.3 112.25 112.25 0 0 1 112.25-112.25"
                className="page-9_svg__cls-5"
              />
            </clipPath>
            <clipPath id="page-9_svg__d">
              <path
                d="M671.68 2707.91A135.13 135.13 0 0 1 806.81 2843v110.55a1.88 1.88 0 0 1-1.88 1.88H694.38a135.13 135.13 0 0 1-135.13-135.13 112.43 112.43 0 0 1 112.43-112.43z"
                className="page-9_svg__cls-5"
              />
            </clipPath>
            <filter id="page-9_svg__a">
              <feDropShadow
                dx="6"
                dy="6"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <filter id="page-9_svg__c">
              <feDropShadow
                dx="6"
                dy="6"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
          </defs>
          <path
            d="M-.514-.594h210.63v297.889H-.514z"
            className="page-9_svg__cls-25"
            style={{ fill: palette.primary, strokeWidth: 0.353833 }}
          />
          <g
            className="page-9_svg__cls-9"
            data-name="CZJK68"
            style={{ opacity: 0.13 }}
          >
            <path
              d="M527.93 2957.45c-1.13-.17-2.26-.29-3.38-.38a200.5 200.5 0 0 1 16.27-32.61c13.7-21.44 28.4-29 44-23.49 9 3.2 17.68 10.23 26.08 19.4q-2.31.93-4.52 2.08c-6.21-5.86-12.59-10.42-19.2-13.08-11.36-4.56-22.54-2.73-33.48 7.48-9.7 9.04-18.12 23.15-25.77 40.6"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M520.59 2954.71c-.27.75-.53 1.52-.79 2.28-1 0-1.94.09-2.89.19 6-18.3 12.48-35 20-48.78 12-22.17 25.19-34 39.76-33 9.31.62 18.27 6.09 27 14.44 7.83 7.47 15.32 16.82 22.61 27.32-1.32 0-2.64.13-4 .29-8.69-11.8-17.74-21.42-27.24-27.86-11.32-7.68-22.71-9.55-34.17-2.27-16.09 10.24-28.97 35.36-40.28 67.39"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M547.8 2877.16c-11.49 15-20.88 37.47-29.36 63-1.88 5.65-3.63 11.62-5.39 17.57-.69.13-1.37.28-2 .45v-1.79c2.2-7.18 4.38-14.43 6.61-21.55 6.34-20.28 13.47-38.15 21.68-52.6 14.05-24.72 29.31-34.2 45.88-27.63 9.9 3.94 19.25 12.59 28.33 23.73 9.6 11.78 18.7 25.92 27.62 41-1.25-.42-2.53-.78-3.83-1.09-5.75-9.26-11.6-18.07-17.63-26.05-12.29-16.3-25-29-38.76-31.89q-17.42-3.61-33.15 16.85"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M652.81 2919.61c-.44.94-.87 1.89-1.3 2.84-7.09-12.27-14.19-24.47-21.42-36.09-10.89-17.51-22.32-32-34.58-40.91-12.66-9.19-25.35-10.69-38-.16-12.09 10.05-22.39 28.51-31.25 52.78-5.39 14.78-10.19 31.21-15.25 46.9v-9c1-3.24 2.11-6.37 3.08-9.74 6.16-21.39 13.06-40.69 21-57 16.15-33 34.06-45.06 53.91-34.24 12 6.55 23.17 19.53 34 35.11 10.28 14.9 20.11 31.95 29.81 49.51"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M656.25 2906.08c.61 1.1 1.21 2.21 1.81 3.32-.42.74-.83 1.49-1.23 2.24-4.78-8.48-9.56-16.95-14.36-25.33-11.22-19.61-22.82-37.43-35.25-50.79-9.72-10.44-19.72-17.49-30.21-18.08-13.12-.73-25.15 9.36-36.2 28.58-9.83 17.12-18 39.24-25.36 63.85-1.47 4.91-3 9.75-4.45 14.63v-8.19c.64-2 1.3-4 1.91-6.11 8.3-28.71 17.52-54.63 29.07-73.63 13.75-22.63 28.55-30.16 44.34-23.18 10.81 4.79 20.94 15.28 30.74 28.39 13.73 18.37 26.5 41.13 39.19 64.3"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M663.86 2900c-.49.73-1 1.48-1.45 2.22-11-19.38-22.1-38.66-33.34-57.1-11.78-19.33-24.32-34.31-37.83-42.29-15.39-9.1-30.26-5.36-44.42 14.43-12.44 17.4-22.36 43.06-31.18 72.28q-2.32 7.66-4.64 15.3v-8.18c.33-1 .69-1.91 1-3 8.29-29.07 17.52-55.34 29.07-74.83 12.74-21.5 26.55-30.33 41.41-25.94 11.36 3.36 22 13.52 32.3 26.68 12.89 16.49 24.92 37.1 36.81 58.36q6.14 11.07 12.27 22.07"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="m669.77 2891.11-1.23 1.82c-.39-.7-.79-1.4-1.19-2.1-14.13-25-28.13-50.61-43.09-71.87-11.84-16.82-24.06-30.8-37.36-36.44-13.49-5.73-26.47-1.46-38.86 14.74-12 15.77-21.82 39.44-30.52 66.78-2.22 6.95-4.35 14.1-6.52 21.15V2877c.33-1 .68-2 1-3 7.91-27.23 16.69-51.89 27.47-70.71q21.57-37.68 46.81-26.77c10.81 4.65 21 14.91 30.81 27.73 14.15 18.45 27.32 41.52 40.43 64.89 4.08 7.29 8.16 14.66 12.25 21.97"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M675.28 2883.65c-7.08-11.84-14.14-23.82-21.21-35.72-11.85-19.92-23.71-39.75-36.38-55.59-11.69-14.63-23.73-26-36.68-28.58s-25.13 4.43-36.59 21.1c-10.13 14.74-18.65 35-26.31 58.19-2.43 7.35-4.74 15-7.11 22.49v-8.19c.3-.9.61-1.78.89-2.72 6.62-22.09 13.85-42.42 22.32-59.25 16.3-32.39 34.32-44.4 54.17-34.53 9.46 4.71 18.47 13.26 27.22 24.08 13.06 16.14 25.28 36.39 37.43 57 7.86 13.34 15.7 26.8 23.57 40.09-.45.55-.88 1.09-1.32 1.63"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M682.58 2875.4c-7.21-11.35-14.31-23.21-21.44-35-12.76-21-25.51-42.11-39-59.43-12.09-15.49-24.51-28-37.88-32.11-11.45-3.53-22.56-.11-33.31 11.44-11.62 12.48-21.44 32.3-30.16 56.42-3.39 9.35-6.53 19.39-9.78 29.12v-8.19c.3-.91.61-1.8.9-2.72 8.44-26.95 17.8-50.92 29.19-68.35 12.59-19.27 26.07-27.06 40.39-23.7 10.51 2.47 20.53 10.42 30.28 21.15 13.9 15.28 26.87 35.54 39.73 56.41 10.92 17.74 21.78 35.75 32.76 53.23-.58.6-1.13 1.16-1.68 1.73"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M691.4 2862.66c.72 1.06 1.44 2.11 2.17 3.15-.59.43-1.19.87-1.77 1.32-5.56-7.77-11-16-16.5-24.25-14.27-21.68-28.46-43.82-42.89-64.66-12.44-18-25.45-32.3-39.3-40.06-11.72-6.56-23.42-7.13-35.09 1-12.26 8.59-23 25.2-32.46 47.87-5.11 12.25-9.72 26.06-14.56 39.17v-8.19c5.51-16.73 11.38-32.34 18-45.9 14.22-29.35 30.08-45.31 48-44 11.77.84 23 8.61 34 19.81 13.7 14 26.58 32.66 39.32 52.12 13.68 20.96 27.26 42.34 41.08 62.62"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M705.72 2858.38c-.56.27-1.11.57-1.66.86a384 384 0 0 1-23.76-30.52c-14.79-20.71-29.44-42.21-44.33-62.39s-30.47-36.89-47.14-43.66c-8.4-3.41-16.8-3.77-25.2-.12-12.21 5.31-23.25 18.57-33.38 37.81-7.06 13.4-13.34 29.22-19.25 46.22v-9c.42-.89.85-1.7 1.24-2.66 4-9.72 7.74-20.05 11.89-29.1 14.65-32 31.22-50.23 50.12-50.63 11.87-.25 23.34 6.3 34.57 16.42 13 11.74 25.42 27.7 37.61 44.8 15.46 21.67 30.78 44.08 46.36 65.08 4.4 5.93 8.85 11.5 13.36 16.56z"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M720.37 2849q-1.23-.93-2.46-1.92c-15.12-12.2-29.45-29.82-43.67-48.2-15.87-20.51-31.53-42.3-47.88-59.93-13.88-15-28.05-26.81-43-30.09-15.84-3.47-30.91 3.94-45.13 23.56-10.16 14-19.06 32.82-27.2 54.47v-9c.32-.59.65-1.12.95-1.77 4.58-10 9-20.72 13.76-29.91 12.47-23.95 26.32-38.2 41.53-41.84 11.07-2.65 22 .73 32.76 7.63 13.76 8.81 26.89 22.64 39.71 38.69 14.74 18.46 29.33 37.87 44.07 56.36 12.92 16.21 26 31 39.76 40.5-1.09.45-2.15.98-3.2 1.45"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M511 2766.46c2.27-4.77 4.53-9.58 6.82-14.29 11.49-23.69 24.1-40.82 38.21-49.16a38.4 38.4 0 0 1 31.08-4.07c16.4 4.56 32.06 17.34 47.32 33.79 15.07 16.26 30 33.7 45 50.53 13.81 15.51 27.77 29.85 42.33 39.28 9.73 6.31 19.58 10.19 29.6 10.07 19-.21 36.3-15.18 52.09-43.93a4.2 4.2 0 0 0 .55-1.68 9 9 0 0 0-.91 1.19c-10.08 17.49-20.94 29.69-32.63 36.08a38.06 38.06 0 0 1-26.37 4c-19.75-3.88-38.36-20.33-56.6-40.17-16.54-18-32.92-37.08-49.44-55.22-12.89-14.17-26.08-26-39.74-33.2-17.85-9.47-35.58-9.59-53 6.16-12.17 11-23 28.4-33 50.29-.38.83-.81 1.46-1.22 2.18z"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M511 2746.81c1-1.82 2.09-3.55 3.1-5.46 13-24.7 27.24-41.67 42.88-49.12a44.08 44.08 0 0 1 30-3.12c19.2 4.53 37.55 19 55.68 36 15 14.12 30 29 45 43.12 13.84 13 27.91 23.67 42.4 29.75 25.22 10.59 49.41 3.12 72.1-30.92a29 29 0 0 0 3-5.48 9 9 0 0 0-.92 1.05q-19.5 29.31-41.65 34.77c-12.13 3-24.17.66-36.15-5.13-19.93-9.65-39-27.63-58-46-15.44-14.91-30.81-30.27-46.58-42.27-14.56-11.08-29.32-19.08-44.47-20q-35.52-2.25-65 52.47c-.45.82-.94 1.46-1.41 2.19z"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M511 2727.15c3-4.32 6-8.8 9.09-12.93 13.72-18.47 28.21-29.36 43.43-33.07 13-3.17 26-1.12 38.87 4 17.4 6.93 34.31 19.56 51.21 32.37 16.13 12.22 32.25 24.56 48.46 36 12.68 8.94 25.62 14.49 38.71 16.76 22.5 3.9 44.18-5.13 64.7-31.81a6.1 6.1 0 0 0 1.12-2.34c-2.55 2.81-5.09 5.74-7.66 8.41-12.72 13.2-25.89 20.14-39.45 22.07-17 2.43-33.74-3.56-50.35-12.87-18.21-10.21-36-24.67-53.9-38.33-15.23-11.66-30.58-22.84-46.23-30.41-14.2-6.93-28.51-10.56-42.94-8.4-19.08 2.86-37.12 16.31-54 41.11A16 16 0 0 1 511 2719Z"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M511 2707.5c5.17-5.13 10.27-10.77 15.51-15.29 12.06-10.4 24.44-16.13 37-18.46 14.47-2.67 28.85-.53 43.22 4.11 16.55 5.34 32.87 14.41 49.2 23.29 16.61 9 33.21 18.45 49.89 26.45 16.19 7.77 32.55 11.86 49 10.86 18.06-1.1 35.7-8.91 52.73-25.95a3.84 3.84 0 0 0 1-1.77c-2.57 2.11-5.13 4.35-7.71 6.32-11.31 8.6-22.83 13.67-34.54 16a76.6 76.6 0 0 1-38.49-2.14 276.4 276.4 0 0 1-37.81-15.14c-19.17-9.34-38.2-20.7-57.38-29.93-12-5.78-24.12-9.68-36.22-13.57a72.5 72.5 0 0 0-33.75-2.27c-17.53 2.7-34.59 11.74-51 28.87a3.7 3.7 0 0 1-.57.43z"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
          </g>
          <g
            className="page-9_svg__cls-9"
            data-name="CZJK68"
            style={{ opacity: 0.13 }}
          >
            <path
              d="M711.93 2597.36a49 49 0 0 0-4.52-2.08c8.41-9.17 17.05-16.2 26.08-19.4 15.6-5.53 30.3 2 44 23.49 10.3 16.11 19 37.61 26.75 61.86 1 3.15 2 6.28 3.07 9.42v8.19c-2.73-8.09-5.38-16.36-8.18-24.23-9.88-27.69-21-50.22-34.53-62.85-10.93-10.21-22.11-12-33.47-7.48-6.61 2.66-12.98 7.22-19.2 13.08"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M696 2592.34a41 41 0 0 0-4-.29c7.28-10.5 14.78-19.85 22.61-27.32 8.76-8.35 17.72-13.82 27-14.44 14.57-1 27.74 10.85 39.77 33 9.57 17.64 17.56 39.91 24.81 64.32.34 1.16.72 2.25 1.08 3.37v8.18c-3.19-9.87-6.25-20.09-9.59-29.56-11.31-32-24.19-57.15-40.33-67.41-11.46-7.28-22.85-5.41-34.17 2.27-9.46 6.46-18.5 16.08-27.18 27.88"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M698.65 2567.14c-6 8-11.87 16.79-17.63 26.05-1.3.31-2.57.67-3.82 1.09 8.91-15.07 18-29.21 27.61-41 9.08-11.14 18.44-19.79 28.34-23.73 16.56-6.57 31.83 2.91 45.87 27.63 8.22 14.45 15.34 32.32 21.69 52.6 2.23 7.12 4.41 14.37 6.61 21.55v8.19c-2.48-8.16-4.84-16.59-7.44-24.42-8.49-25.57-17.87-48.06-29.37-63q-15.74-20.49-33.09-16.82c-13.77 2.86-26.42 15.56-38.77 31.86"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M665.51 2594.52c.44.94.87 1.89 1.29 2.84 7.09-12.27 14.2-24.47 21.43-36.09 10.89-17.51 22.31-32 34.58-40.91 12.66-9.19 25.34-10.69 38-.16 12.08 10.05 22.39 28.51 31.25 52.78 5.39 14.78 10.18 31.21 15.25 46.9v-9c-1-3.24-2.11-6.37-3.08-9.74-6.16-21.39-13.07-40.69-21-57-16.16-33-34.06-45.06-53.92-34.24-12 6.55-23.16 19.53-33.94 35.11-10.37 14.92-20.16 31.99-29.86 49.51"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M662.07 2581c-.61 1.1-1.21 2.21-1.82 3.32.42.74.83 1.49 1.24 2.24 4.78-8.48 9.55-17 14.35-25.33 11.23-19.61 22.83-37.43 35.26-50.79 9.71-10.44 19.72-17.49 30.21-18.08 13.12-.73 25.15 9.36 36.19 28.58 9.84 17.12 18 39.24 25.37 63.85 1.47 4.91 3 9.75 4.45 14.63v-8.19c-.64-2-1.31-4-1.91-6.11-8.3-28.71-17.53-54.63-29.08-73.63-13.74-22.63-28.54-30.16-44.33-23.18-10.81 4.79-20.94 15.28-30.74 28.39-13.73 18.36-26.51 41.12-39.19 64.3"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M654.45 2574.91c.5.73 1 1.48 1.46 2.22 11-19.38 22.1-38.66 33.34-57.1 11.78-19.33 24.32-34.31 37.82-42.29 15.4-9.1 30.27-5.36 44.43 14.43 12.44 17.4 22.36 43.06 31.18 72.28q2.31 7.66 4.64 15.3v-8.18c-.33-1-.69-1.91-1-3-8.29-29.07-17.53-55.34-29.08-74.83-12.74-21.5-26.54-30.33-41.41-25.94-11.35 3.36-22 13.52-32.29 26.68-12.9 16.49-24.92 37.1-36.82 58.36z"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M648.54 2566c.42.6.83 1.21 1.23 1.82.4-.7.8-1.4 1.19-2.1 14.14-25 28.14-50.61 43.1-71.87 11.84-16.82 24.06-30.8 37.35-36.44 13.5-5.73 26.48-1.46 38.87 14.74 12.05 15.77 21.82 39.44 30.52 66.78 2.21 7 4.35 14.1 6.52 21.15v-8.19c-.33-1-.68-2-1-3-7.9-27.23-16.68-51.89-27.46-70.71q-21.57-37.68-46.81-26.77c-10.81 4.65-21 14.91-30.81 27.73-14.15 18.45-27.33 41.52-40.43 64.89-4.09 7.31-8.17 14.68-12.27 21.97"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M643 2558.56c7.08-11.84 14.14-23.82 21.21-35.72 11.85-19.92 23.71-39.75 36.38-55.59 11.69-14.63 23.72-25.95 36.67-28.58s25.14 4.43 36.6 21.1c10.13 14.74 18.65 35 26.31 58.19 2.42 7.35 4.74 15 7.11 22.49v-8.19c-.3-.9-.61-1.78-.89-2.72-6.62-22.09-13.85-42.42-22.32-59.25-16.3-32.39-34.32-44.4-54.17-34.53-9.46 4.71-18.48 13.26-27.22 24.08-13.06 16.14-25.28 36.39-37.44 57-7.85 13.34-15.69 26.8-23.56 40.09z"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M635.74 2550.31c7.21-11.35 14.31-23.21 21.44-34.95 12.76-21 25.51-42.11 39-59.43 12.09-15.49 24.51-28 37.88-32.11 11.44-3.53 22.56-.11 33.31 11.44 11.61 12.48 21.43 32.3 30.16 56.42 3.39 9.35 6.52 19.39 9.78 29.12v-8.19c-.3-.91-.61-1.8-.9-2.72-8.44-26.95-17.8-50.92-29.19-68.35-12.59-19.27-26.07-27.06-40.39-23.7-10.51 2.47-20.53 10.42-30.28 21.15-13.91 15.28-26.88 35.54-39.73 56.41-10.92 17.74-21.78 35.76-32.76 53.23q.855.825 1.68 1.68"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M626.92 2537.57c-.72 1.06-1.45 2.11-2.17 3.15l1.77 1.32c5.55-7.77 11-16 16.5-24.25 14.27-21.68 28.45-43.82 42.89-64.66 12.43-18 25.45-32.3 39.3-40.06 11.72-6.56 23.42-7.13 35.09 1 12.26 8.59 23 25.2 32.46 47.87 5.11 12.25 9.72 26.06 14.56 39.17V2493c-5.51-16.73-11.39-32.34-18-45.9-14.22-29.35-30.08-45.31-48-44-11.77.84-23 8.61-34 19.81-13.7 14-26.57 32.66-39.31 52.12-13.68 20.85-27.27 42.26-41.09 62.54"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M612.59 2533.29c.56.27 1.11.57 1.67.86a384 384 0 0 0 23.74-30.52c14.8-20.71 29.45-42.21 44.34-62.39s30.47-36.89 47.13-43.66c8.4-3.41 16.8-3.77 25.2-.12 12.22 5.31 23.26 18.57 33.39 37.81 7 13.4 13.34 29.22 19.25 46.22v-9c-.42-.89-.85-1.7-1.24-2.66-4-9.72-7.74-20-11.89-29.1-14.66-32-31.23-50.23-50.12-50.63-11.88-.25-23.34 6.3-34.57 16.42-13 11.74-25.42 27.7-37.61 44.8-15.46 21.67-30.78 44.08-46.36 65.08-4.4 5.93-8.85 11.5-13.36 16.56z"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M598 2524q1.23-.93 2.46-1.92c15.12-12.2 29.45-29.82 43.67-48.2 15.87-20.51 31.53-42.3 47.87-59.93 13.89-15 28.06-26.81 43-30.09 15.84-3.47 30.91 3.94 45.14 23.56 10.16 14 19.06 32.82 27.2 54.47v-9c-.32-.59-.65-1.12-1-1.77-4.58-10-9-20.72-13.76-29.91-12.48-24-26.32-38.2-41.53-41.84-11.08-2.65-22 .73-32.76 7.63-13.77 8.81-26.89 22.64-39.71 38.69-14.75 18.46-29.33 37.87-44.08 56.36-12.91 16.21-26 31-39.76 40.5 1.1.45 2.16.89 3.26 1.45"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M807.32 2441.37c-2.28-4.77-4.53-9.58-6.82-14.29-11.49-23.69-24.1-40.82-38.21-49.16a38.4 38.4 0 0 0-31.08-4.07c-16.4 4.56-32.06 17.34-47.32 33.79-15.08 16.26-30 33.7-45 50.53-13.81 15.51-27.77 29.85-42.33 39.28-9.74 6.31-19.59 10.19-29.6 10.07-19-.21-36.31-15.18-52.1-43.93a4.3 4.3 0 0 1-.51-1.69 10 10 0 0 1 .91 1.19c10.07 17.49 20.94 29.69 32.62 36.08a38 38 0 0 0 26.37 4c19.76-3.88 38.37-20.33 56.61-40.17 16.54-18 32.92-37.08 49.43-55.22 12.9-14.16 26.08-25.95 39.75-33.2 17.85-9.47 35.57-9.59 53.05 6.16 12.16 11 23 28.4 33 50.29.38.83.81 1.46 1.22 2.18z"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M807.32 2421.72c-1-1.82-2.09-3.55-3.1-5.46-13-24.7-27.25-41.67-42.89-49.12a44 44 0 0 0-30-3.12c-19.2 4.53-37.56 19-55.68 36-15 14.12-30 29-45 43.12-13.84 13-27.91 23.67-42.4 29.75-25.22 10.59-49.41 3.12-72.1-30.92a29.7 29.7 0 0 1-3-5.48 8 8 0 0 1 .93 1.05q19.5 29.31 41.65 34.77c12.13 3 24.17.66 36.14-5.13 19.94-9.65 39-27.62 58-46 15.42-14.9 30.78-30.26 46.55-42.26 14.56-11.08 29.31-19.08 44.47-20q35.52-2.25 65 52.47c.45.82.94 1.46 1.41 2.19z"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M807.32 2402.06c-3-4.32-6-8.8-9.09-12.93-13.73-18.47-28.21-29.36-43.44-33.07-13-3.17-25.95-1.12-38.86 4-17.4 6.93-34.31 19.56-51.21 32.37-16.13 12.22-32.25 24.56-48.46 36-12.69 8.94-25.62 14.49-38.71 16.76-22.51 3.9-44.18-5.13-64.71-31.81a6.3 6.3 0 0 1-1.11-2.34c2.55 2.81 5.08 5.74 7.66 8.41 12.71 13.2 25.88 20.14 39.45 22.07 17 2.43 33.74-3.56 50.34-12.87 18.21-10.21 36-24.67 53.91-38.33 15.27-11.66 30.58-22.84 46.2-30.45 14.21-6.93 28.52-10.56 43-8.4 19.08 2.86 37.12 16.31 54 41.11a14 14 0 0 0 1.08 1.3z"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
            <path
              d="M807.32 2382.41c-5.17-5.13-10.28-10.77-15.51-15.29-12.06-10.4-24.44-16.13-37.06-18.46-14.46-2.67-28.85-.53-43.22 4.11-16.55 5.34-32.86 14.41-49.19 23.29-16.61 9-33.21 18.45-49.89 26.45-16.19 7.77-32.55 11.86-49 10.86-18.06-1.1-35.7-8.91-52.73-25.95a3.7 3.7 0 0 1-1-1.77c2.57 2.11 5.12 4.35 7.71 6.32 11.31 8.61 22.83 13.67 34.53 16a76.6 76.6 0 0 0 38.5-2.14 277 277 0 0 0 37.94-15.12c19.17-9.34 38.2-20.7 57.37-29.93 12-5.78 24.13-9.68 36.23-13.57a72.5 72.5 0 0 1 33.75-2.27c17.52 2.7 34.59 11.74 51 28.87a3.7 3.7 0 0 0 .57.43z"
              className="page-9_svg__cls-63"
              style={{ fill: "#fff" }}
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
            />
          </g>
          <text
            x={12.79}
            y={26.357}
            className="page-9_svg__cls-67"
            style={{
              fontSize: "14.3534px",
              fontFamily: "Heavitas",
              fill: palette.secondary,
              strokeWidth: 0.35423,
            }}
          >
            {"PRINCIPAIS"}
            <tspan
              className="page-9_svg__cls-8"
              style={{ strokeWidth: 0.353833, fill: palette.backgroundLight }}
            >
              <tspan x={12.79} y={43.561} style={{ strokeWidth: 0.353833 }}>
                {"PROPOSTAS"}
              </tspan>
            </tspan>
          </text>

          <path
            d="M211.62 2387.42h335a259.21 259.21 0 0 1 259.21 259.21H211.62Z"
            className="page-9_svg__cls-68"
            style={{
              fill: palette.backgroundLight,
              filter: "url(#page-9_svg__a)",
            }}
            transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
          />

          <ClippedImage
            id="emerald-page-9-1"
            triggerImageEdit={triggerImageEdit}
            x={123}
            y={68}
            width={89}
            height={89}
            imageNumber={12}
            imageRatio={1}
            notebook={notebook}
          >
            <path
              d="M671.51 2399.08a135.3 135.3 0 0 1 135.3 135.3v112.25H694.56a135.3 135.3 0 0 1-135.3-135.3 112.25 112.25 0 0 1 112.25-112.25"
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
              fill={palette.backgroundLight}
            />
          </ClippedImage>

          <path
            d="M114.032-.056s25.176-.538 48.352 9.808c22.04 9.836 34.71 19.185 41.1 20.933 8.054 2.204 6.628-.152 6.628-.152V-.587Z"
            className="page-9_svg__cls-8"
            style={{ fill: palette.secondary, strokeWidth: 0.353833 }}
          />
          <path
            d="M210.9 2696.25h336.48a259.21 259.21 0 0 1 259.21 259.21H210.9Z"
            className="page-9_svg__cls-129"
            style={{
              fill: palette.backgroundLight,
              filter: "url(#page-9_svg__c)",
            }}
            transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
          />

          <ClippedImage
            id="emerald-page-9-2"
            triggerImageEdit={triggerImageEdit}
            x={122}
            y={177}
            width={89}
            height={89}
            imageNumber={13}
            imageRatio={1}
            notebook={notebook}
          >
            <path
              d="M671.68 2707.91A135.13 135.13 0 0 1 806.81 2843v110.55a1.88 1.88 0 0 1-1.88 1.88H694.38a135.13 135.13 0 0 1-135.13-135.13 112.43 112.43 0 0 1 112.43-112.43z"
              transform="matrix(.35383 0 0 .35383 -75.393 -780.359)"
              fill={palette.backgroundLight}
            />
          </ClippedImage>

          <foreignObject
            x={8.005}
            y={70.032}
            width={110}
            height={80}
            className="clickable"
          >
            <div
              style={{
                fontSize: "6px",
                fontFamily: "MontserratAlternates",
                fontWeight: 500,
                color: palette.backgroundGray,
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.first_proposal_title,
                  field: "first_proposal_title",
                  label: "Título",
                  type: "input",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.first_proposal_title}
            </div>

            <div
              style={{
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: palette.backgroundGray,
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.first_proposal,
                  field: "first_proposal",
                  label: "Primeira Bandeira",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.first_proposal}
            </div>
          </foreignObject>

          <foreignObject
            x={8.005}
            y={180.032}
            width={110}
            height={80}
            className="clickable"
          >
            <div
              style={{
                fontSize: "6px",
                fontFamily: "MontserratAlternates",
                fontWeight: 500,
                color: palette.backgroundGray,
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.second_proposal_title,
                  field: "second_proposal_title",
                  label: "Título",
                  type: "input",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.second_proposal_title}
            </div>

            <div
              style={{
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: palette.backgroundGray,
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.second_proposal,
                  field: "second_proposal",
                  label: "Primeira Bandeira",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.second_proposal}
            </div>
          </foreignObject>
        </svg>
      </PaperInside>
    </Paper>
  );
};

export default Page9;
