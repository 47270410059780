import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TargetPages } from "../../../app/constants";
import { AppDispatch } from "../../../app/store";
import { canCreateNewTarget } from "../../../app/utils";
import TargetButton from "../../../components/buttons/target-button/target-button";
import { makeContainer } from "../../../components/container/container";
import BannerContentText from "../../../components/text/banner-content-text/banner-content-text";
import BannerTitleText from "../../../components/text/banner-title-text/banner-title-text";
import TitleText from "../../../components/text/title-text/title-text";
import { setAlert } from "../../../features/alert/slice";
import { AlertType } from "../../../features/alert/type";
import TargetService from "../../../features/target/service";
import {
  getTargets,
  selectTargetsList,
  setCreateTargetRequest,
  setCurrentPage,
} from "../../../features/target/slice";
import { TargetRootResponse } from "../../../features/target/type";
import BannerImage from "../../../resources/images/target-banner.svg";
import TargetsList from "./components/targets-list/targets-list";
import "./home.css";

const PageContainer = makeContainer("target-home-page-container");
const HomeLeftPane = makeContainer("target-home-left-pane");
const LeftContainer = makeContainer("target-home-banner-left");
const RightContainer = makeContainer("target-home-banner-right");
const BannerContainer = makeContainer("target-home-banner");

export const TargetBanner: React.FC<{ createNewTarget: () => void }> = ({
  createNewTarget,
}) => {
  return (
    <BannerContainer>
      <LeftContainer>
        <BannerTitleText> Meta de Votos disponível! </BannerTitleText>
        <BannerContentText>
          Maximize suas chances de ser eleito nas próximas eleições através da
          Meta de Votos!
        </BannerContentText>
        <TargetButton expanded={true} onClick={createNewTarget} />
      </LeftContainer>
      <RightContainer>
        <img
          className={"target-home-banner-image"}
          src={BannerImage}
          alt="Banner Meta de Votos"
        />
      </RightContainer>
    </BannerContainer>
  );
};

const Line: React.FC = () => {
  return <hr className="target-home-separator-line" />;
};

export const HomePage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const targets: TargetRootResponse[] = useSelector(selectTargetsList);
  const creationEnabled = canCreateNewTarget(targets);

  const createNewTarget = () => {
    if (!creationEnabled) {
      dispatch(
        setAlert({
          message:
            "Você já atingiu o limite de metas de voto para esta conta. Delete uma das metas para continuar.",
          type: AlertType.WARNING,
        })
      );
      return;
    }

    dispatch(setCurrentPage(TargetPages.STATE_SELECTION));
  };

  useEffect(() => {
    dispatch(
      getTargets({
        service: new TargetService(),
      })
    );

    dispatch(setCreateTargetRequest(null));
  }, [dispatch]);

  return (
    <PageContainer>
      <HomeLeftPane>
        <TargetBanner createNewTarget={createNewTarget} />
        <Line />
        <TitleText>{"Metas de Votos já criadas"}</TitleText>
        <TargetsList createNewTarget={createNewTarget} />
      </HomeLeftPane>
    </PageContainer>
  );
};

export default HomePage;
