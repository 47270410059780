import { Candidate, CandidateSearch } from "../candidates/type";

export class Product {
  id: string;
  name: string;
  description: string;
  image_url: string;
  product_type: string;
  price: number;
  report_type: string;
  state: string;
  years: string;
  has_votes_target: boolean;
  duration: number;
}

export class CartItem {
  id: string;
  product: Product;
  candidate_id: string;
  year: string;
  turn: string;
}

export class CreateCartItemRequest {
  product_id: string;
  candidate_id?: string;
  year?: string;
  turn?: string;
}

export class OrderItem {
  id: string;
  product: Product;
  processed: boolean;
  candidate_id: string;
  year: string;
  turn: string;
  report_url: string;
}

export class Order {
  id: string;
  status: string;
  created: string;
  price: number;
  user_id: string;
  payment_gateway_id: string;
  boleto_url: string;
  pix_url: string;
  items: OrderItem[];
}

export class Address {
  street: string;
  number: string;
  locality: string;
  city: string;
  region: string;
  region_code: string;
  country: string;
  postal_code: string;
}

export class Holder {
  name: string;
  tax_id: string;
  email: string;
  address: Address;
}

export class CreateOrderRequest {
  payment_method: string;
  encrypted_card: string;
  installments: number;
  holder?: Holder;
}

export class CartPrice {
  cost: number;
  discount: number;
  final_cost: number;
}

export interface IShopService {
  addCartItem(cartItem: CreateCartItemRequest): Promise<CartItem>;
  removeCartItem(id: string): Promise<void>;
  getCart(): Promise<CartItem[]>;
  getCartPrice(code: string): Promise<CartPrice>;
  createOrder(createOrderRequest: CreateOrderRequest): Promise<Order>;
  getOrders(): Promise<Order[]>;
  getOrderDetails(id: string): Promise<Order>;
  getProducts(): Promise<Product[]>;
  getCandidatePhoto(candidateId: string): Promise<string>;
  searchCandidates(search: string): Promise<CandidateSearch[]>;
  getCandidate(candidateId: string): Promise<Candidate>;
}

export const getPaymentMethod = (order: Order) => {
  if (!order) {
    return "";
  }

  if (order.boleto_url) {
    return "no boleto";
  }

  if (order.pix_url) {
    return "no pix";
  }

  return "no cartão";
};
