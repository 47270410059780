import React, { memo } from "react";
import IntroButton from "../../../components/buttons/intro-button/intro-button";
import { makeContainer } from "../../../components/container/container";
import IntroContentText from "../../../components/text/intro-content-text/intro-content-text";
import IntroTitleText from "../../../components/text/intro-title-text/intro-title-text";
import "./content-page.css";

const IntroPane = makeContainer("intro-pane");

const FirstPage: React.FC = () => {
  return (
    <IntroPane>
      <IntroTitleText className="intro-title-text">
        {"Veja a votação por bairro ou local"}
      </IntroTitleText>
      <IntroContentText className="content-text">
        {
          "Através da DataElege você pode navegar por mapas eleitorais para conhecer o número de votos de qualquer candidato."
        }
      </IntroContentText>
      <IntroButton>{"Próxima"}</IntroButton>
    </IntroPane>
  );
};

export default memo(FirstPage);
