import * as React from "react";
import { useSelector } from "react-redux";
import { validateText } from "../../../../../app/utils";
import { selectPalette } from "../../../../../features/notebook/slice";
import { ClippedImage, Paper, PaperInside } from "../commons/commons";
import { TemplatePageProps } from "../commons/interfaces";

const Page9: React.FC<TemplatePageProps> = ({
  className,
  notebook,
  triggerImageEdit,
  triggerFieldEdit,
  updateField,
}) => {
  const { palette } = useSelector(selectPalette);
  return (
    <Paper className={className}>
      <PaperInside>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
        >
          <defs>
            <clipPath id="a">
              <path d="M460.9 1914.65a135.3 135.3 0 0 1 135.3 135.3v112.25H483.95a135.3 135.3 0 0 1-135.3-135.3 112.25 112.25 0 0 1 112.25-112.25Z" />
            </clipPath>
          </defs>

          <path
            d="M-.063.006h210.866v298.223H-.063z"
            style={{
              fill: palette.primary,
              strokeWidth: 0.35423,
            }}
          />

          <g
            data-name="CZJK68"
            style={{
              opacity: 0.13,
            }}
          >
            <path
              d="M17.94 2475a47.85 47.85 0 0 0-3.38-.38A200.94 200.94 0 0 1 30.83 2442c13.71-21.44 28.41-29 44-23.49 9 3.2 17.67 10.23 26.08 19.41a45.07 45.07 0 0 0-4.51 2.08c-6.22-5.86-12.59-10.42-19.21-13.08-11.36-4.56-22.54-2.73-33.47 7.48-9.72 9.06-18.13 23.18-25.78 40.6z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M10.6 2472.28c-.26.75-.52 1.52-.79 2.28-1 0-1.94.09-2.89.19 6-18.3 12.49-35 20-48.78 12-22.17 25.2-34 39.76-33 9.31.62 18.27 6.09 27 14.44 7.83 7.47 15.33 16.82 22.61 27.32-1.32 0-2.63.13-4 .29-8.69-11.8-17.73-21.42-27.24-27.86-11.32-7.68-22.71-9.55-34.17-2.27-16.09 10.24-28.97 35.36-40.28 67.39z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M37.82 2394.73c-11.5 15-20.88 37.47-29.37 63-1.87 5.65-3.63 11.62-5.39 17.57-.69.13-1.37.28-2 .45V2474c2.2-7.18 4.38-14.42 6.61-21.55 6.35-20.28 13.47-38.14 21.69-52.6 14-24.72 29.31-34.2 45.87-27.62 9.9 3.93 19.26 12.58 28.34 23.73 9.59 11.77 18.7 25.91 27.61 41-1.25-.42-2.52-.78-3.82-1.09-5.76-9.26-11.61-18.07-17.63-26-12.3-16.29-25-29-38.77-31.89q-17.42-3.71-33.14 16.75z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M142.82 2437.18c-.44.94-.87 1.89-1.29 2.84-7.09-12.27-14.2-24.47-21.43-36.09-10.89-17.51-22.31-32-34.58-40.91-12.66-9.19-25.34-10.69-38-.16-12.08 10-22.39 28.51-31.25 52.78-5.39 14.78-10.18 31.21-15.25 46.9v-9c1-3.24 2.11-6.37 3.08-9.74 6.16-21.39 13.07-40.69 21-57 16.16-33 34.06-45.05 53.92-34.24 12 6.55 23.16 19.53 33.94 35.12 10.33 14.92 20.16 31.94 29.86 49.5z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M146.26 2423.65c.61 1.11 1.21 2.22 1.82 3.32-.42.74-.83 1.49-1.24 2.24q-7.17-12.72-14.35-25.33c-11.23-19.61-22.83-37.43-35.26-50.79-9.71-10.44-19.72-17.49-30.23-18.09-13.12-.74-25.15 9.35-36.19 28.58-9.84 17.11-18 39.23-25.37 63.84-1.47 4.91-3 9.75-4.45 14.63v-8.19c.64-2 1.31-4 1.91-6.11 8.3-28.71 17.53-54.63 29.08-73.63 13.76-22.61 28.56-30.12 44.35-23.12 10.81 4.79 20.94 15.28 30.74 28.39 13.73 18.33 26.51 41.09 39.19 64.26z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M153.88 2417.57c-.5.73-1 1.48-1.46 2.23-11-19.38-22.1-38.66-33.34-57.11-11.78-19.33-24.32-34.3-37.82-42.29-15.4-9.1-30.27-5.36-44.43 14.44-12.44 17.39-22.36 43-31.18 72.27q-2.31 7.66-4.64 15.31v-8.19c.33-1 .69-1.91 1-3 8.29-29.06 17.53-55.34 29.08-74.83 12.74-21.5 26.54-30.33 41.41-25.94 11.35 3.36 22 13.53 32.29 26.69 12.89 16.48 24.92 37.09 36.82 58.35z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M159.79 2408.68q-.63.9-1.23 1.83c-.4-.7-.8-1.4-1.19-2.11-14.14-25-28.14-50.61-43.1-71.86-11.84-16.83-24.06-30.8-37.35-36.45-13.5-5.73-26.48-1.46-38.87 14.74-12 15.77-21.82 39.44-30.52 66.78-2.21 6.95-4.35 14.1-6.52 21.15v-8.18c.33-1 .68-2 1-3 7.9-27.23 16.68-51.89 27.46-70.71q21.57-37.68 46.81-26.78c10.81 4.65 21 14.91 30.81 27.73 14.15 18.45 27.33 41.52 40.43 64.89 4.09 7.29 8.17 14.66 12.27 21.97z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M165.29 2401.23c-7.08-11.85-14.14-23.83-21.21-35.73-11.85-19.92-23.71-39.75-36.38-55.59C96 2295.28 84 2284 71 2281.33c-13-2.67-25.14 4.43-36.6 21.11-10.13 14.73-18.65 35-26.31 58.18-2.42 7.35-4.74 15-7.11 22.49v-8.19c.3-.9.61-1.78.89-2.72 6.62-22.09 13.85-42.42 22.32-59.25 16.3-32.38 34.32-44.39 54.17-34.52 9.46 4.7 18.48 13.25 27.22 24.07 13.06 16.14 25.28 36.4 37.44 57 7.85 13.34 15.69 26.79 23.56 40.08q-.58.82-1.29 1.65z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M172.59 2393c-7.21-11.35-14.31-23.21-21.44-34.95-12.76-21-25.51-42.11-39-59.43-12.09-15.49-24.51-28-37.88-32.11-11.44-3.53-22.56-.11-33.31 11.44-11.61 12.48-21.43 32.31-30.16 56.42-3.39 9.36-6.52 19.39-9.78 29.12v-8.19c.3-.91.61-1.79.9-2.72 8.44-26.94 17.8-50.92 29.19-68.35 12.59-19.27 26.07-27.06 40.39-23.7 10.5 2.47 20.5 10.39 30.27 21.12 13.91 15.29 26.88 35.54 39.73 56.42 10.92 17.73 21.78 35.75 32.76 53.22-.56.55-1.12 1.11-1.67 1.71z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M181.41 2380.23c.72 1.06 1.45 2.11 2.17 3.15-.59.43-1.18.87-1.77 1.33-5.55-7.77-11-16-16.5-24.26-14.27-21.68-28.45-43.82-42.89-64.66-12.43-18-25.45-32.3-39.3-40.06-11.72-6.56-23.42-7.13-35.09 1.05-12.26 8.59-23 25.19-32.46 47.86-5.11 12.25-9.72 26.06-14.56 39.17v-8.19c5.51-16.73 11.39-32.34 18-45.9 14.22-29.34 30.08-45.31 48-44 11.77.83 23 8.6 34 19.8 13.7 14 26.57 32.66 39.31 52.13 13.68 20.89 27.27 42.35 41.09 62.58z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="m195.74 2376-1.67.86a383.46 383.46 0 0 1-23.75-30.52c-14.8-20.71-29.45-42.2-44.34-62.38-14.89-20.18-30.47-36.9-47.13-43.67-8.4-3.41-16.8-3.77-25.2-.12-12.22 5.32-23.26 18.57-33.39 37.82-7 13.39-13.34 29.21-19.25 46.21v-9c.42-.89.85-1.7 1.24-2.66 4-9.72 7.74-20 11.89-29.1 14.66-32 31.23-50.23 50.12-50.63 11.88-.25 23.34 6.3 34.57 16.42 13 11.74 25.42 27.7 37.61 44.8 15.46 21.67 30.78 44.09 46.36 65.09 4.4 5.93 8.85 11.49 13.36 16.55z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M210.38 2366.62c-.82-.63-1.64-1.27-2.46-1.93-15.12-12.2-29.45-29.82-43.67-48.19-15.87-20.52-31.53-42.31-47.87-59.93-13.89-15-28.06-26.82-43-30.1-15.83-3.47-30.9 4-45.13 23.56C18.05 2264 9.15 2282.85 1 2304.5v-9c.32-.59.65-1.13 1-1.78 4.58-10 9-20.71 13.76-29.91 12.47-23.95 26.32-38.2 41.53-41.84 11.08-2.65 22 .73 32.76 7.63 13.77 8.81 26.89 22.64 39.71 38.69 14.74 18.46 29.33 37.87 44.08 56.37 12.91 16.21 26 30.94 39.75 40.5-1.1.45-2.16.94-3.21 1.46z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M1 2284c2.28-4.77 4.53-9.58 6.82-14.29 11.49-23.69 24.1-40.82 38.21-49.16a38.39 38.39 0 0 1 31.08-4.07c16.4 4.56 32.06 17.34 47.32 33.79 15.08 16.26 30 33.7 45 50.54 13.81 15.5 27.77 29.84 42.33 39.27 9.74 6.31 19.59 10.19 29.6 10.07 19-.21 36.3-15.18 52.1-43.93a4.21 4.21 0 0 0 .51-1.69 9.77 9.77 0 0 0-.91 1.19c-10.07 17.49-20.94 29.69-32.62 36.08a38 38 0 0 1-26.37 4c-19.76-3.88-38.37-20.33-56.61-40.16-16.54-18-32.92-37.09-49.43-55.23-12.9-14.16-26.08-25.95-39.75-33.2-17.85-9.47-35.57-9.58-53 6.16-12.16 11-23 28.4-33 50.29-.38.83-.81 1.46-1.22 2.18z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M1 2264.38c1-1.82 2.09-3.55 3.1-5.46 13-24.7 27.25-41.67 42.89-49.12a44 44 0 0 1 30-3.12c19.2 4.53 37.56 19 55.68 36 15 14.12 30 29 45 43.12 13.84 13 27.91 23.67 42.4 29.75 25.22 10.59 49.41 3.12 72.1-30.92a29.69 29.69 0 0 0 3-5.48 8.08 8.08 0 0 0-.93 1q-19.5 29.33-41.65 34.77c-12.13 3-24.17.66-36.15-5.13-19.93-9.65-39-27.62-58-46-15.42-14.9-30.78-30.27-46.55-42.27-14.56-11.08-29.31-19.08-44.47-20q-35.52-2.24-65 52.47c-.45.83-.94 1.46-1.41 2.19z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M1 2244.73c3-4.32 6-8.81 9.09-12.93 13.73-18.48 28.21-29.37 43.44-33.08 13-3.17 25.95-1.12 38.86 4 17.4 6.93 34.31 19.56 51.21 32.37 16.13 12.22 32.25 24.56 48.46 36 12.69 8.94 25.62 14.49 38.71 16.76 22.51 3.9 44.18-5.13 64.71-31.81a6.29 6.29 0 0 0 1.11-2.34c-2.55 2.81-5.08 5.74-7.66 8.41-12.71 13.2-25.88 20.14-39.45 22.07-17 2.43-33.74-3.55-50.34-12.87-18.21-10.21-36-24.67-53.91-38.33-15.27-11.66-30.58-22.83-46.2-30.45-14.21-6.93-28.52-10.56-42.95-8.4C37 2197 19 2210.44 2.09 2235.24a14.32 14.32 0 0 1-1.08 1.3z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M1 2225.07c5.17-5.13 10.28-10.77 15.51-15.29 12.06-10.4 24.44-16.13 37.06-18.46 14.46-2.66 28.85-.53 43.22 4.11 16.55 5.34 32.86 14.42 49.19 23.29 16.61 9 33.21 18.45 49.89 26.46 16.19 7.76 32.55 11.86 49 10.85 18.06-1.1 35.7-8.91 52.73-26a3.68 3.68 0 0 0 1-1.77c-2.57 2.11-5.12 4.35-7.71 6.32-11.31 8.61-22.83 13.67-34.53 16a76.72 76.72 0 0 1-38.5-2.13 277 277 0 0 1-37.86-15.1c-19.17-9.34-38.2-20.7-57.37-29.93-12-5.77-24.13-9.67-36.23-13.57a72.39 72.39 0 0 0-33.75-2.26c-17.52 2.69-34.59 11.73-51 28.86a3.79 3.79 0 0 1-.57.44z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
          </g>

          <g
            data-name="CZJK68"
            style={{
              opacity: 0.13,
            }}
          >
            <path
              d="M501.32 2112.93a48.66 48.66 0 0 0-4.52-2.08c8.41-9.17 17.05-16.2 26.08-19.4 15.6-5.54 30.3 2 44 23.49 10.3 16.1 19 37.6 26.75 61.86 1 3.15 2 6.28 3.07 9.42v8.18c-2.73-8.08-5.38-16.36-8.18-24.22-9.88-27.69-21-50.23-34.53-62.85-10.93-10.21-22.11-12-33.47-7.48-6.61 2.66-12.98 7.22-19.2 13.08z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M485.37 2107.91c-1.32-.16-2.64-.26-3.95-.29 7.28-10.5 14.78-19.85 22.61-27.32 8.76-8.35 17.72-13.83 27-14.44 14.57-1 27.74 10.85 39.77 33 9.57 17.64 17.56 39.91 24.81 64.32.34 1.16.72 2.24 1.08 3.36v8.19c-3.19-9.87-6.25-20.1-9.59-29.56-11.31-32-24.19-57.15-40.33-67.41-11.46-7.28-22.85-5.41-34.17 2.26-9.49 6.47-18.53 16.09-27.23 27.89z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M488 2082.71c-6 8-11.87 16.78-17.63 26.05-1.3.3-2.57.67-3.82 1.09 8.91-15.07 18-29.21 27.61-41 9.08-11.15 18.44-19.8 28.34-23.73 16.56-6.58 31.83 2.9 45.87 27.63 8.22 14.45 15.34 32.32 21.69 52.6 2.23 7.12 4.41 14.36 6.61 21.55v8.19c-2.48-8.16-4.84-16.59-7.44-24.43-8.49-25.56-17.87-48.05-29.37-63q-15.74-20.49-33.09-16.83c-13.77 2.87-26.43 15.58-38.77 31.88z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M454.9 2110.08c.44 1 .87 1.89 1.29 2.85 7.09-12.27 14.2-24.47 21.43-36.1 10.89-17.5 22.31-32 34.58-40.9 12.66-9.2 25.34-10.7 38-.17 12.08 10.06 22.39 28.52 31.25 52.78 5.39 14.78 10.18 31.22 15.25 46.91v-9c-1-3.24-2.11-6.37-3.08-9.74-6.16-21.4-13.07-40.69-21-57-16.16-33-34.06-45-53.92-34.24-12 6.55-23.16 19.53-33.94 35.12-10.33 14.91-20.16 31.94-29.86 49.49z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M451.46 2096.56c-.61 1.1-1.21 2.21-1.82 3.32.42.74.83 1.49 1.24 2.24 4.78-8.48 9.55-16.95 14.35-25.33 11.23-19.61 22.83-37.43 35.26-50.79 9.71-10.44 19.72-17.49 30.21-18.08 13.12-.73 25.15 9.36 36.19 28.58 9.84 17.12 18 39.24 25.37 63.84 1.47 4.92 3 9.76 4.45 14.64v-8.19c-.64-2-1.31-4-1.91-6.11-8.3-28.71-17.53-54.63-29.08-73.64-13.74-22.62-28.54-30.15-44.33-23.17-10.81 4.78-20.94 15.28-30.74 28.39-13.73 18.36-26.51 41.13-39.19 64.3z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M443.84 2090.48c.5.73 1 1.48 1.46 2.22 11-19.38 22.1-38.66 33.34-57.1 11.78-19.33 24.32-34.31 37.82-42.29 15.4-9.1 30.27-5.36 44.43 14.43 12.44 17.4 22.36 43.06 31.18 72.28q2.31 7.67 4.64 15.3v-8.19c-.33-1-.69-1.9-1-3-8.29-29.07-17.53-55.34-29.08-74.84-12.74-21.49-26.54-30.32-41.41-25.93-11.35 3.36-22 13.52-32.29 26.68-12.9 16.48-24.92 37.1-36.82 58.36z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M437.93 2081.58q.63.91 1.23 1.83c.4-.7.8-1.4 1.19-2.1 14.14-25 28.14-50.61 43.1-71.87 11.84-16.82 24.06-30.8 37.35-36.44 13.5-5.73 26.48-1.47 38.87 14.74 12.05 15.77 21.82 39.44 30.52 66.78 2.21 6.95 4.35 14.09 6.52 21.15v-8.19c-.33-1-.68-2-1-3-7.9-27.23-16.68-51.89-27.46-70.71q-21.57-37.68-46.81-26.77c-10.81 4.65-21 14.91-30.81 27.73-14.15 18.45-27.33 41.52-40.43 64.89-4.09 7.29-8.2 14.65-12.27 21.96z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M432.43 2074.13c7.08-11.84 14.14-23.82 21.21-35.72 11.85-19.93 23.71-39.75 36.38-55.59 11.69-14.63 23.72-26 36.67-28.58 12.95-2.58 25.14 4.42 36.6 21.1 10.13 14.74 18.65 35 26.31 58.19 2.42 7.35 4.74 15 7.11 22.49v-8.19c-.3-.91-.61-1.78-.89-2.72-6.62-22.09-13.85-42.42-22.32-59.25-16.3-32.39-34.32-44.4-54.17-34.53-9.46 4.7-18.48 13.26-27.22 24.08-13.06 16.14-25.28 36.39-37.44 57-7.85 13.34-15.69 26.8-23.56 40.09z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M425.13 2065.88c7.21-11.36 14.31-23.21 21.44-35 12.76-21 25.51-42.11 39-59.43 12.09-15.5 24.51-28 37.88-32.12 11.44-3.52 22.56-.1 33.31 11.45 11.61 12.48 21.43 32.3 30.16 56.41 3.39 9.36 6.52 19.4 9.78 29.13v-8.19c-.3-.91-.61-1.8-.9-2.73-8.44-26.94-17.8-50.91-29.19-68.35-12.59-19.26-26.07-27-40.39-23.69-10.51 2.46-20.53 10.42-30.28 21.14-13.91 15.29-26.88 35.54-39.73 56.42-10.92 17.74-21.78 35.75-32.76 53.23.55.59 1.13 1.16 1.68 1.73z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M416.31 2053.13c-.72 1.07-1.45 2.11-2.17 3.15l1.77 1.33c5.55-7.77 11-16 16.5-24.26 14.27-21.67 28.45-43.81 42.89-64.65 12.43-18 25.45-32.31 39.3-40.06 11.72-6.56 23.42-7.13 35.09 1 12.26 8.59 23 25.2 32.46 47.87 5.11 12.25 9.72 26.06 14.56 39.16v-8.19c-5.51-16.72-11.39-32.33-18-45.9-14.22-29.34-30.08-45.3-48-44-11.77.84-23 8.61-34 19.81-13.7 14-26.57 32.65-39.31 52.12-13.68 20.94-27.27 42.35-41.09 62.62z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M402 2048.86c.56.27 1.11.57 1.67.86a381.87 381.87 0 0 0 23.75-30.53c14.8-20.7 29.45-42.2 44.34-62.38 14.89-20.18 30.47-36.9 47.13-43.66 8.4-3.41 16.8-3.77 25.2-.12 12.22 5.31 23.26 18.57 33.39 37.81 7 13.4 13.34 29.22 19.25 46.22v-9c-.42-.88-.85-1.7-1.24-2.65-4-9.73-7.74-20.05-11.89-29.1-14.66-32-31.23-50.23-50.12-50.63-11.88-.25-23.34 6.3-34.57 16.41-13 11.75-25.42 27.71-37.61 44.8-15.46 21.67-30.78 44.09-46.36 65.09-4.4 5.93-8.85 11.5-13.36 16.56.12.1.26.2.42.32z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M387.34 2039.52q1.23-.93 2.46-1.92c15.12-12.2 29.45-29.82 43.67-48.2 15.87-20.51 31.53-42.3 47.87-59.93 13.89-15 28.06-26.82 43-30.09 15.84-3.47 30.91 3.94 45.14 23.56 10.16 14 19.06 32.81 27.2 54.47v-9c-.32-.59-.65-1.12-1-1.77-4.58-10-9-20.72-13.76-29.91-12.48-24-26.32-38.2-41.53-41.84-11.08-2.65-22 .73-32.76 7.63-13.77 8.81-26.89 22.64-39.71 38.68-14.75 18.47-29.33 37.87-44.08 56.37-12.91 16.21-26 31-39.76 40.5 1.15.45 2.21.93 3.26 1.45z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M596.71 1956.94c-2.28-4.77-4.53-9.58-6.82-14.3-11.49-23.68-24.1-40.82-38.21-49.15a38.44 38.44 0 0 0-31.08-4.08c-16.4 4.56-32.06 17.35-47.32 33.8-15.08 16.26-30 33.7-45 50.53-13.81 15.51-27.77 29.84-42.33 39.28-9.74 6.3-19.59 10.18-29.6 10.07-19-.22-36.31-15.18-52.1-43.94a4.15 4.15 0 0 1-.51-1.68 9.77 9.77 0 0 1 .91 1.19c10.07 17.49 20.94 29.69 32.62 36.08a38.07 38.07 0 0 0 26.37 4c19.76-3.88 38.37-20.33 56.61-40.17 16.54-18 32.92-37.09 49.43-55.23 12.9-14.16 26.08-25.94 39.75-33.19 17.85-9.47 35.57-9.59 53.05 6.15 12.16 11 23 28.41 33 50.3.38.82.81 1.46 1.22 2.18z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M596.71 1937.29c-1-1.82-2.09-3.55-3.1-5.47-13-24.69-27.25-41.66-42.89-49.11a44 44 0 0 0-30-3.12c-19.2 4.53-37.56 19-55.68 36-15 14.13-30 29-45 43.13-13.84 13-27.91 23.67-42.4 29.75-25.22 10.59-49.41 3.12-72.1-30.92a29.48 29.48 0 0 1-3-5.49 9.65 9.65 0 0 1 .93 1q19.5 29.33 41.65 34.78c12.13 3 24.17.66 36.14-5.14 19.94-9.64 39-27.62 58-46 15.42-14.9 30.78-30.26 46.55-42.26 14.56-11.08 29.31-19.08 44.47-20q35.52-2.25 65 52.46c.45.83.94 1.47 1.41 2.2z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M596.71 1917.63c-3-4.32-6-8.8-9.09-12.93-13.73-18.47-28.21-29.37-43.44-33.07-13-3.18-25.95-1.12-38.86 4-17.4 6.94-34.31 19.56-51.21 32.38-16.13 12.22-32.25 24.56-48.46 36-12.69 8.94-25.62 14.49-38.71 16.76-22.51 3.89-44.18-5.13-64.71-31.82a6.15 6.15 0 0 1-1.11-2.33c2.55 2.81 5.08 5.74 7.66 8.41 12.71 13.2 25.88 20.13 39.45 22.07 17 2.43 33.74-3.56 50.34-12.87 18.21-10.21 36-24.67 53.91-38.33 15.27-11.67 30.58-22.84 46.2-30.46 14.21-6.93 28.52-10.55 42.95-8.39 19.08 2.86 37.12 16.31 54 41.11a14.15 14.15 0 0 0 1.08 1.29z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
            <path
              d="M596.71 1898c-5.17-5.13-10.28-10.77-15.51-15.29-12.06-10.4-24.44-16.13-37.06-18.46-14.46-2.67-28.85-.53-43.22 4.11-16.55 5.34-32.86 14.41-49.19 23.29-16.61 9-33.21 18.45-49.89 26.45-16.19 7.77-32.55 11.86-49 10.86-18.06-1.11-35.7-8.91-52.73-26a3.68 3.68 0 0 1-1-1.77c2.57 2.11 5.12 4.35 7.71 6.32 11.31 8.6 22.83 13.67 34.53 16a76.61 76.61 0 0 0 38.5-2.13 277 277 0 0 0 37.94-15.12c19.17-9.34 38.2-20.7 57.37-29.93 12-5.78 24.13-9.68 36.23-13.57a72.53 72.53 0 0 1 33.75-2.27c17.52 2.69 34.59 11.74 51 28.87a3.71 3.71 0 0 0 .57.43z"
              style={{
                fill: "#fff",
              }}
              transform="translate(-.421 -616.468) scale(.35423)"
            />
          </g>

          <text
            x={13.256}
            y={26.991}
            style={{
              fontSize: "14.3534px",
              fontFamily: "Heavitas",
              fill: palette.secondary,
              strokeWidth: 0.35423,
            }}
          >
            {"PRINCIPAIS\n\n   "}
            <tspan
              style={{
                strokeWidth: 0.35423,
                fill: palette.backgroundLight,
              }}
            >
              <tspan
                x={13.256}
                y={44.214}
                style={{
                  strokeWidth: 0.35423,
                }}
              >
                {"PROPOSTAS"}
              </tspan>
            </tspan>
          </text>

          <ClippedImage
            id="saphire-page-9-1"
            triggerImageEdit={triggerImageEdit}
            x={123}
            y={61}
            width={89}
            height={89}
            imageNumber={12}
            imageRatio={1}
            notebook={notebook}
          >
            <path
              d="M460.9 1914.65a135.3 135.3 0 0 1 135.3 135.3v112.25H483.95a135.3 135.3 0 0 1-135.3-135.3 112.25 112.25 0 0 1 112.25-112.25Z"
              transform="translate(-.421 -616.468) scale(.35423)"
              fill={palette.backgroundLight}
            />
          </ClippedImage>

          <foreignObject
            x={15.005}
            y={70.032}
            width={100}
            height={74}
            className="clickable"
          >
            <div
              style={{
                fontSize: "6px",
                fontFamily: "MontserratAlternates",
                fontWeight: 500,
                color: palette.backgroundLight,
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.first_proposal_title,
                  field: "first_proposal_title",
                  label: "Título",
                  type: "input",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.first_proposal_title}
            </div>

            <div
              style={{
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: palette.backgroundLight,
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.first_proposal,
                  field: "first_proposal",
                  label: "Primeira Bandeira",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.first_proposal}
            </div>
          </foreignObject>

          <ClippedImage
            id="saphire-page-9-2"
            triggerImageEdit={triggerImageEdit}
            x={0}
            y={177}
            width={89}
            height={89}
            imageNumber={13}
            imageRatio={1}
            notebook={notebook}
          >
            <path
              d="m 133.24,2246.85 h 110.55 v 110.55 a 133.24,133.24 0 0 1 -133.24,133.24 v 0 A 110.55,110.55 0 0 1 0,2380.09 v 0 a 133.24,133.24 0 0 1 133.24,-133.24 z"
              transform="translate(-.421 -616.468) scale(.35423) rotate(180,121.895,2368.74)"
              fill={palette.backgroundLight}
            />
          </ClippedImage>

          <foreignObject
            x={100.005}
            y={190.032}
            width={100}
            height={74}
            className="clickable"
          >
            <div
              style={{
                fontSize: "6px",
                fontFamily: "MontserratAlternates",
                fontWeight: 500,
                color: palette.backgroundLight,
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.second_proposal_title,
                  field: "second_proposal_title",
                  label: "Título",
                  type: "input",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.second_proposal_title}
            </div>

            <div
              style={{
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: palette.backgroundLight,
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.second_proposal,
                  field: "second_proposal",
                  label: "Primeira Bandeira",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.second_proposal}
            </div>
          </foreignObject>

          <path
            d="M114.612.548s25.203-.542 48.409 9.812c22.065 9.844 34.75 19.206 41.147 20.956 8.063 2.207 6.635-.152 6.635-.152V-.008Z"
            style={{
              fill: palette.secondary,
              strokeWidth: 0.35423,
            }}
          />
        </svg>
      </PaperInside>
    </Paper>
  );
};

export default Page9;
