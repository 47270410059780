import { memo } from "react";
import "./marker.css";

type Props = {
  fill: string;
};

const Marker: React.FC<Props> = ({ fill }) => {
  return (
    <svg
      id="dataelege-animated-maker"
      viewBox="43.75 40.35 274.32 414.02"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="100%"
      height="100%"
    >
      <defs>
        <filter
          id="eRWCJGf341a2-filter"
          x="-150%"
          width="400%"
          y="-150%"
          height="400%"
        >
          <feComponentTransfer
            id="eRWCJGf341a2-filter-brightness-0"
            result="result"
          >
            <feFuncR
              id="eRWCJGf341a2-filter-brightness-0-R"
              type="linear"
              slope="1"
            />
            <feFuncG
              id="eRWCJGf341a2-filter-brightness-0-G"
              type="linear"
              slope="1"
            />
            <feFuncB
              id="eRWCJGf341a2-filter-brightness-0-B"
              type="linear"
              slope="1"
            />
          </feComponentTransfer>
        </filter>
      </defs>
      <g id="eRWCJGf341a2_to" transform="translate(180.910702,277.35)">
        <g
          transform="scale(0.88,0.84) translate(-180.910702,-247.350899)"
          filter="url(#eRWCJGf341a2-filter)"
        >
          <path
            d="M205.72,138c0-19.047-6.697-35.309-20.09-48.7852-13.393-13.4766-29.554-20.2147-48.483-20.2147s-35.09,6.7381-48.484,20.2147C75.2697,102.691,68.5732,118.953,68.5732,138s6.6965,35.309,20.0898,48.785C102.057,200.262,118.218,207,137.147,207s35.09-6.738,48.483-20.215c13.393-13.476,20.09-29.738,20.09-48.785m68.574,0c0,19.586-2.947,35.668-8.84,48.246L167.951,394.863c-2.857,5.932-7.098,10.599-12.723,14.016-5.626,3.414-11.653,5.121-18.081,5.121-6.429,0-12.456-1.707-18.081-5.121-5.625-3.417-9.777-8.084-12.456-14.016L8.83953,186.246C2.94651,173.668,0,157.586,0,138C0,99.9064,13.3932,67.3827,40.1796,40.4295C66.966,13.4766,99.2884,0,137.147,0c37.858,0,70.18,13.4766,96.965,40.4295C260.899,67.3827,274.294,99.9064,274.294,138"
            transform="translate(43.763699 40.350899)"
            fill={fill}
            stroke="#27262e"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

export default memo(Marker);
