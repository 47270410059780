import { memo } from "react";
import {
  makeContainer,
  makePContainer,
} from "../../components/container/container";
import "./terms.css";

type Props = {
  children: string | JSX.Element | JSX.Element[];
};

const TermsContainer = makeContainer("terms-container");
const TermsGlobalTitle = makePContainer("terms-global-title");
const TermsSectionTitle = makePContainer("terms-section-title");
const TermsParagraph = makePContainer("terms-paragraph");

const TermsUL: React.FC<Props> = ({ children }) => {
  return <ul className="terms-ul">{children}</ul>;
};

const TermsOL: React.FC<Props> = ({ children }) => {
  return <ol className="terms-ol">{children}</ol>;
};

const TermsLI: React.FC<Props> = ({ children }) => {
  return <li className="terms-li">{children}</li>;
};

const TermsPage: React.FC = () => {
  return (
    <TermsContainer>
      <TermsGlobalTitle>
        POLÍTICA DE PRIVACIDADE E CONDIÇÕES DE USO DA PLATAFORMA DATAELEGE
      </TermsGlobalTitle>
      <TermsSectionTitle>INFORMAÇÕES GERAIS E DEFINIÇÕES</TermsSectionTitle>
      <TermsParagraph>
        A DATAELEGE valoriza a privacidade de seus usuários e criou a presente
        Política de Privacidade para manifestar seu compromisso em proteger a
        privacidade e dados pessoais de seus usuários, nos termos da Lei Geral
        de Proteção de Dados - LGPD e legislação vigente, servindo a presente
        Política de Privacidade para regular, de forma simples, transparente e
        objetiva, quais dados e informações serão obtidos, assim como quando os
        mesmos poderão ser utilizados. Definições:
      </TermsParagraph>
      <TermsParagraph>
        Usuário: todas as pessoas físicas que utilizarão ou visitarão o Site e
        demais canais eletrônicos da DATAELEGE, maiores de 18 (dezoito) anos e
        totalmente capazes de praticar os atos da vida civil, bem como aqueles
        que precisam ser representados ou assistidos.
      </TermsParagraph>
      <TermsParagraph>
        Dados Pessoais: significa quaisquer informações fornecidas e/ou
        coletadas pela DATAELEGE, por qualquer meio, ainda que públicos, que:
        (i) identifiquem, ou que, quando usadas em combinação com outras
        informações tratadas pela DATAELEGE identifiquem um indivíduo; ou (ii)
        por meio das quais a identificação ou informações de contato de uma
        pessoa física possam ser derivadas. Os Dados Pessoais podem estar em
        qualquer mídia ou formato, inclusive registros eletrônicos ou
        computadorizados, bem como em arquivos baseados em papel.
      </TermsParagraph>
      <TermsParagraph>
        Consentimento: autorização expressa e inequívoca dada pelo Usuário
        titular do dado pessoal para que a DATAELEGE trate seus dados pessoais
        para as finalidades descritas neste documento.
      </TermsParagraph>
      <TermsParagraph>
        Esta política se aplica, em geral, a todos os Usuários e potenciais
        Usuários dos serviços oferecidos pela DATAELEGE, incluindo Usuários dos
        sites ou outros meios operados pela DATAELEGE e resume como a DATAELEGE
        poderá coletar, produzir, receptar, classificar, utilizar, acessar,
        reproduzir, transmitir, distribuir, processar, arquivar, armazenar,
        eliminar, avaliar ou controlar a informação, modificar, comunicar,
        transferir, difundir ou extrair os dados coletados, incluindo as
        informações de identificação pessoal, de acordo com as bases legais
        aplicáveis e todas as leis de privacidade e proteção de dados em vigor.
      </TermsParagraph>
      <TermsParagraph>
        Ao acessar e/ou utilizar o site da DATAELEGE, o Usuário declara ter no
        mínimo 18 (dezoito) anos e ter capacidade plena e expressa para a
        aceitação dos termos e condições desta Política de Privacidade e do
        Termo de Consentimento para todos os fins de direito. Caso o Usuário não
        se enquadre na descrição acima e/ou não concorde, ainda que em parte,
        com os termos e condições contidos nesta Política de Privacidade, não
        deverá acessar e/ou utilizar os serviços oferecidos pela DATAELEGE, bem
        como os sites e serviços por ele operados.
      </TermsParagraph>
      <TermsGlobalTitle>Política de Privacidade</TermsGlobalTitle>
      <TermsSectionTitle>
        1 - CADASTRO, COLETA E USOS DE INFORMAÇÕES PESSOAIS
      </TermsSectionTitle>
      <TermsParagraph>
        O Usuário está ciente que fornece informação de forma consciente e
        voluntária por meio de formulário ou por meio dos sites operados pela
        DATAELEGE, podendo receber nossos e-mails e de nossos parceiros,
        inclusive de divulgação de serviços, produtos, marketing e campanhas no
        geral. Todos os dados pessoais que o Usuário fornece ao realizar o
        cadastro, inclusive nos sites por operados pela DATAELEGE, serão
        mantidos em sigilo e serão utilizados apenas para o propósito que
        motivou o cadastro ou nos termos do item 3.
      </TermsParagraph>
      <TermsSectionTitle>
        2 - COMPARTILHAMENTO E TRATAMENTO DE INFORMAÇÕES PESSOAIS
      </TermsSectionTitle>
      <TermsParagraph>
        A DATAELEGE não disponibilizará Dados Pessoais coletados em seus sites
        para corretores de lista de e-mail sem seu expresso consentimento. A
        DATAELEGE poderá divulgar os Dados Pessoais coletados a terceiros, nas
        seguintes situações e nos limites exigidos e autorizados pela Lei:
      </TermsParagraph>
      <TermsOL>
        <TermsLI>
          <TermsParagraph>
            Para melhoria constante do nosso conteúdo e a geração de dados
            estatísticos sobre os usuários, sem que sejam reveladas informações
            que permitam a identificação individual do usuário;
          </TermsParagraph>
        </TermsLI>
        <TermsLI>
          <TermsParagraph>
            Com os seus clientes e parceiros quando necessário e/ou apropriado à
            prestação de serviços relacionados;
          </TermsParagraph>
        </TermsLI>
        <TermsLI>
          <TermsParagraph>
            Com as empresas e indivíduos contratados para a execução de
            determinadas atividades e serviços em nome da DATAELEGE;
          </TermsParagraph>
        </TermsLI>
        <TermsLI>
          <TermsParagraph>
            Com as instituições parceiras da DATAELEGE para envio de conteúdo
            com a finalidade comercial, promoção de produtos, vendas,
            desenvolvimento institucional e informativos relevantes para o
            objeto da instituição.
          </TermsParagraph>
        </TermsLI>
        <TermsLI>
          <TermsParagraph>
            Com fornecedores e parceiros para consecução dos serviços
            contratados com a DATAELEGE (como tecnologia da informação,
            contabilidade, entre outros);
          </TermsParagraph>
        </TermsLI>
        <TermsLI>
          <TermsParagraph>
            Para propósitos administrativos como: pesquisa, planejamento,
            desenvolvimento de serviços, segurança e gerenciamento de risco;
          </TermsParagraph>
        </TermsLI>
        <TermsLI>
          <TermsParagraph>
            Quando necessário em decorrência de obrigação legal, determinação de
            autoridade competente, ou decisão judicial.
          </TermsParagraph>
        </TermsLI>
      </TermsOL>
      <TermsParagraph>
        Nas hipóteses de compartilhamento de Dados Pessoais com terceiros, todos
        os sujeitos mencionados nos itens supra deverão utilizar os Dados
        Pessoais partilhados de maneira consistente e de acordo com os
        propósitos para os quais foram coletados (ou com os quais o Usuário
        consentiu previamente) e de acordo com o que foi determinado por esta
        Política de Privacidade, outras declarações de privacidade de website ou
        países, e todas as leis de privacidade e proteção de dados aplicáveis.
      </TermsParagraph>
      <TermsSectionTitle>3 - COOKIES</TermsSectionTitle>
      <TermsParagraph>
        Podemos utilizar “cookies” (informações enviadas pelo nosso servidor ao
        computador do usuário, com o objetivo de identificá-lo). Se, no seu
        cadastro, o usuário optar por “Lembrar minha ID no computador”, será
        gerado um cookie no computador do usuário. Esse cookie será lido todas
        as vezes que o usuário acessar o Portal, e, dessa forma, não precisará
        mais identificar-se para acessar os recursos do site.
      </TermsParagraph>
      <TermsParagraph>
        A DATAELEGE utiliza cookies para facilitar o uso e melhor adaptar as
        Páginas aos seus interesses e necessidades, bem como para compilarmos
        informações sobre a utilização de nossos sites e serviços, auxiliando a
        melhorar suas estruturas e seus conteúdos. Os cookies também podem ser
        utilizados para acelerar suas atividades e experiências futuras nas
        Páginas:
      </TermsParagraph>
      <TermsUL>
        <TermsLI>
          <TermsParagraph>
            Necessários: Esses cookies nos ajudam a entender como os visitantes
            interagem com as Páginas da DATAELEGE, fornecendo informações sobre
            as áreas visitadas, o tempo de visita ao site e quaisquer problemas
            encontrados, como mensagens de erro;
          </TermsParagraph>
        </TermsLI>

        <TermsLI>
          <TermsParagraph>
            Funcionais: Esses cookies permitem que as Páginas da DATAELEGE se
            lembrem de suas escolhas, para proporcionar uma experiência mais
            personalizada. Também, possibilitam que os Usuários assistam a
            vídeos e utilizem ferramentas sociais, campos para comentários,
            fóruns, entre outros;
          </TermsParagraph>
        </TermsLI>

        <TermsLI>
          <TermsParagraph>
            Marketing: Esses cookies são utilizados para fornecer mais conteúdo
            relevante e do interesse dos Usuários. Podem ser utilizados para
            apresentar publicidade mais direcionada ou limitar o número que esta
            é veiculada, nas Páginas da DATAELEGE. Também, permitem a medição da
            eficácia de uma campanha publicitária da DATAELEGE.
          </TermsParagraph>
        </TermsLI>
      </TermsUL>
      <TermsParagraph>
        Após o Usuário consentir para a utilização de cookies, quando do uso das
        Páginas da DATAELEGE, a DATAELEGE armazenará um cookie em seu
        dispositivo para lembrar disso na próxima sessão. A qualquer momento, o
        Usuário poderá revogar seu consentimento quanto aos cookies, pelo que
        deverá apagar os cookies das Páginas da DATAELEGE utilizando as
        configurações de seu navegador de preferência.
      </TermsParagraph>
      <TermsSectionTitle>
        4 - MOTIVOS LEGAIS PARA A DIVULGAÇÃO DE SEUS DADOS
      </TermsSectionTitle>
      <TermsParagraph>
        Em certas circunstâncias, a DATAELEGE poderá divulgar Dados Pessoais, na
        medida necessária ou apropriada, para órgãos governamentais, consultores
        e outros terceiros com o objetivo de cumprir com a legislação aplicável
        ou com uma ordem ou intimação judicial ou, ainda, se a DATAELEGE
        acreditar de boa-fé que tal ação seja necessária para:
      </TermsParagraph>
      <TermsOL>
        <TermsLI>
          <TermsParagraph>
            Cumprir com uma legislação que exija tal divulgação;
          </TermsParagraph>
        </TermsLI>

        <TermsLI>
          <TermsParagraph>
            Investigar, impedir ou tomar medidas relacionadas a atividades
            ilegais suspeitas ou reais ou para cooperar com órgãos públicos ou
            para proteger a segurança nacional;
          </TermsParagraph>
        </TermsLI>

        <TermsLI>
          <TermsParagraph>Execução de seus contratos;</TermsParagraph>
        </TermsLI>

        <TermsLI>
          <TermsParagraph>
            Investigar e se defender contra quaisquer reivindicações ou
            alegações de terceiros;
          </TermsParagraph>
        </TermsLI>

        <TermsLI>
          <TermsParagraph>
            Proteger a segurança ou a integridade dos serviços (por exemplo, o
            compartilhamento com empresas que estão sofrendo ameaças
            semelhantes);
          </TermsParagraph>
        </TermsLI>

        <TermsLI>
          <TermsParagraph>
            Exercer ou proteger os direitos, a propriedade e a segurança da
            DATAELEGE e suas empresas coligadas; e
          </TermsParagraph>
        </TermsLI>

        <TermsLI>
          <TermsParagraph>
            Proteger os direitos e a segurança pessoal de seus funcionários,
            usuários ou do público.
          </TermsParagraph>
        </TermsLI>
      </TermsOL>
      <TermsParagraph>
        Exceto nos casos em que seja vedado por lei ou proibido por mandado
        judicial ou, ainda, se a requisição for emergencial, a DATAELEGE
        cientificará os respectivos Usuários sobre eventuais demandas legais que
        resultem na divulgação de informações pessoais.
      </TermsParagraph>
      <TermsSectionTitle>
        5 - SEGURANÇA DE INFORMAÇÕES PESSOAIS
      </TermsSectionTitle>
      <TermsParagraph>
        Todos os Dados Pessoais serão guardados na base de dados da DATAELEGE ou
        em base de dados mantidas “na nuvem” pelos fornecedores de serviços
        contratados pela DATAELEGE, os quais estão devidamente de acordo com a
        legislação de dados vigente. A DATAELEGE e seus fornecedores utilizam
        vários procedimentos de segurança para proteger a confidencialidade,
        segurança e integridade de seus Dados Pessoais, prevenindo a ocorrência
        de eventuais danos em virtude do tratamento desses dados. Embora a
        DATAELEGE utilize medidas de segurança e monitore seu sistema para
        verificar vulnerabilidades e ataques para proteger seus Dados Pessoais
        contra divulgação não autorizada, mau uso ou alteração, o Usuário
        entende e concorda que não há garantias de que as informações não
        poderão ser acessadas, divulgadas, alteradas ou destruídas por violação
        de qualquer uma das proteções físicas, técnicas ou administrativas.
      </TermsParagraph>
      <TermsSectionTitle>6 - RETENÇÃO DE DADOS</TermsSectionTitle>
      <TermsParagraph>
        A DATAELEGE retém todos os dados fornecidos, inclusive os Dados
        Pessoais, enquanto o cadastro do Usuário estiver ativo e conforme seja
        necessário para consecução de seus serviços. A DATAELEGE reterá seus
        Dados Pessoais e manterá seus dados armazenados até eventual
        requerimento de exclusão, ou após 3 anos da exclusão do cadastro do
        usuário. A DATAELEGE poderá vir a manter seus Dados Pessoais após
        receber seu pedido de exclusão ou após 3 anos da exclusão do cadastro do
        usuário, caso seja necessário para cumprimento de obrigações legais,
        resolver disputas, manter a segurança, evitar fraudes e abuso e garantir
        o cumprimento de contratos.
      </TermsParagraph>
      <TermsSectionTitle>7 - BASES LEGAIS PARA PROCESSAMENTO</TermsSectionTitle>
      <TermsParagraph>
        A DATAELEGE apenas trata Dados Pessoais em situações em que está
        autorizada legalmente ou mediante seu expresso e inequívoco
        consentimento do Usuário. Conforme descrito na presente Política, a
        DATAELEGE tem bases legais para coletar, produzir, compartilhar,
        receptar, classificar, utilizar, acessar, reproduzir, transmitir,
        distribuir, processar, arquivar, armazenar, eliminar, avaliar ou
        controlar a informação, modificar, comunicar, transferir, difundir ou
        extrair dados sobre o Usuário.
      </TermsParagraph>
      <TermsParagraph>
        As bases legais incluem seu consentimento (colhido de forma expressa e
        inequívoca no Termo de Consentimento), contratos e procedimentos
        preliminares contratuais (em que o processamento é necessário para
        firmar o contrato com o Usuário) e interesses legítimos, interesses
        incluem proteger o Usuário e a DATAELEGE de ameaças, cumprir a desde que
        tal processamento não viole seus direitos e liberdades. Tais legislação
        aplicável, o exercício regular de direitos em processo judicial,
        administrativo ou arbitral, habilitar a realização ou administração dos
        negócios, incluindo controle de qualidade, relatórios e serviços
        oferecidos, gerenciar transações empresariais, entender e melhorar os
        negócios e relacionamentos com os clientes e permitir que os usuários
        encontrem oportunidades econômicas.
      </TermsParagraph>
      <TermsParagraph>
        O Usuário tem o direito de negar ou retirar o consentimento fornecido a
        DATAELEGE, quando esta for a base legal para tratamento dos dados
        pessoais, podendo a DATAELEGE encerrar a consecução de seus serviços
        para este usuário na hipótese de ocorrência de tal solicitação. Caso
        tenha dúvidas sobre as bases legais para coleta, tratamento e
        armazenamento de seus dados pessoais, entre em contato com a DATAELEGE
        por meio do e-mail institucional@dataelege.com.br.
      </TermsParagraph>
      <TermsSectionTitle>
        8 - DIREITO DE ACESSAR E CONTROLAR SEUS DADOS PESSOAIS
      </TermsSectionTitle>
      <TermsParagraph>
        A DATAELEGE oferece ao Usuário opções do que fazer com seus Dados
        Pessoais coletados, tratados e armazenados, incluindo sua exclusão e/ou
        correção. O Usuário pode:
      </TermsParagraph>
      <TermsOL>
        <TermsLI>
          <TermsParagraph>
            Excluir dados: o Usuário pode solicitar a exclusão de alguns dos
            seus Dados Pessoais;
          </TermsParagraph>
        </TermsLI>

        <TermsLI>
          <TermsParagraph>
            Alterar ou corrigir dados: o Usuário pode editar ou solicitar a
            edição de alguns dos seus Dados Pessoais. O Usuário também pode
            solicitar atualizações, alterações ou correções de seus dados em
            determinados casos, principalmente se eles estiverem incorretos;
          </TermsParagraph>
        </TermsLI>

        <TermsLI>
          <TermsParagraph>
            Colocar objeções, limites ou restrições ao uso de dados: o Usuário
            pode solicitar a interrupção do uso de todos ou alguns de seus Dados
            Pessoais, ou limitar a nossa utilização de tais dados, destacando-se
            que a DATAELEGE poderá tratar os Dados Pessoais de acordo com a
            legislação vigente; e
          </TermsParagraph>
        </TermsLI>

        <TermsLI>
          <TermsParagraph>
            O Usuário tem direito de acessar ou levar seus dados: o Usuário pode
            solicitar uma cópia dos seus Dados Pessoais e dos dados que o
            Usuário forneceu em um formato legível sob a forma impressa ou por
            meio eletrônico.
          </TermsParagraph>
        </TermsLI>
      </TermsOL>
      <TermsParagraph>
        O Usuário pode fazer as solicitações listadas acima entrando em contato
        por meio do e-mail institucional@dataelege.com.br e estes pedidos serão
        considerados de acordo com as leis aplicáveis.
      </TermsParagraph>
      <TermsSectionTitle>9 - MEDIAÇÃO E FORO DE ELEIÇÃO</TermsSectionTitle>
      <TermsParagraph>
        Esta política está sujeita à Lei da República Federativa do Brasil e o
        Foro da Comarca do Rio de Janeiro é competente para dirimir qualquer
        controvérsia com relação à mesma. Em caso de incidente com Dados
        Pessoais, a DATAELEGE, desde já, se reserva a nomear a JUSPRO - Justiça
        Sem Processo Mediação e Conciliação S/S Ltda. como Câmara de Mediação
        apta a dirimir as questões envolvendo os Usuários titulares dos dados,
        nos termos do artigo 52, §7º da Lei Geral de Proteção de Dados.
      </TermsParagraph>
      <TermsGlobalTitle>CONDIÇÕES DE USO</TermsGlobalTitle>
      <TermsSectionTitle>1 - ACEITAÇÃO DOS TERMOS DE USO</TermsSectionTitle>
      <TermsParagraph>
        Ao acessar a plataforma DATAELEGE, o usuário concorda em cumprir e estar
        sujeito a estes termos de uso. Caso o usuário não concorde com estes
        termos, o mesmo não deve utilizar a plataforma DATAELEGE.
      </TermsParagraph>
      <TermsSectionTitle>2 - DESCRIÇÃO DO SERVIÇO</TermsSectionTitle>
      <TermsParagraph>
        A DATAELEGE fornece uma plataforma on-line para visualização e análise
        de dados eleitorais diversos. Na plataforma ocorre a venda de produtos
        digitais, como relatórios e planos de acesso aos dados eletorais para
        anos e estados específicos.
      </TermsParagraph>
      <TermsSectionTitle>3 - PREÇOS E PAGAMENTOS</TermsSectionTitle>
      <TermsParagraph>
        Os preços dos produtos exibidos na loja DATAELEGE incluem todas as taxas
        e impostos aplicáveis. Você deve pagar pelos produtos com uma forma de
        pagamento aceita.
      </TermsParagraph>
      <TermsSectionTitle>4 - ENTREGA</TermsSectionTitle>
      <TermsParagraph>
        Os produtos serão entregues eletronicamente pela DATAELEGE através de
        meios específicos, como download ou e-mail. A DATAELEGE não realiza
        nenhuma entrega física de produtos, exceto em casos especiais.
      </TermsParagraph>
      <TermsSectionTitle>5 - GARANTIA E RESPONSABILIDADE</TermsSectionTitle>
      <TermsParagraph>
        A DATAELEGE garante que todos os produtos disponíveis na loja estarão de
        acordo com a descrição fornecida. No entanto, a DATAELEGE não garante
        que os produtos satisfarão as necessidades do usuário ou que não haverá
        problemas de natureza técnica.
      </TermsParagraph>
      <TermsParagraph>
        Os dados fornecidos são baseados em informações disponíveis no momento
        da coleta. Embora todos os esforços tenham sido feitos para garantir a
        precisão e a integridade dos dados, a DATAELEGE não garante que esses
        dados sejam precisos, completos ou atualizados. Além disso, a DATAELEGE
        não se responsabiliza por quaisquer danos ou perdas decorrentes do uso
        dos dados fornecidos, incluindo, sem limitação, danos diretos ou
        indiretos.
      </TermsParagraph>
      <TermsParagraph>
        Os mapas eleitorais fornecidos, sobretudo os mapas de bairros e de zonas
        eleitorais, não constituem de forma alguma uma representação geográfica
        precisa ou mesmo uma divisão política oficial das localidades. Esses
        mapas fornecem uma aproximação baseada na geolocalização dos dados
        informados pelos órgãos compotentes (TSE e IBGE) e estão sujeitos às
        imprecisões causadas por falhas no processo de geocalização,
        inconsistência nos dados de endereços dos locais de votação e por outros
        diversos fatores.
      </TermsParagraph>
      <TermsSectionTitle>
        6 - PROIBIÇÃO DE ACESSO INDEVIDO À PLATAFORMA
      </TermsSectionTitle>
      <TermsParagraph>
        A DATAELEGE proíbe expressamente o uso de softwares de terceiros, tais
        como bots, crawlers, scrapers e similares, para coletar ou acessar as
        APIs da plataforma sem a nossa permissão explícita. O uso desses
        softwares indevidos pode causar danos significativos ao nosso sistema e
        aos nossos usuários, além de violar as leis de propriedade intelectual.
        Caso a DATAELEGE identifique a utilização desses softwares indevidos,
        medidas apropriadas serão tomadas, incluindo, mas não se limitando a,
        notificação, bloqueio de acesso e processo legal. Além disso, o
        perpetrador será responsável por qualquer dano causado ao nosso sistema
        ou aos nossos usuários como resultado do uso desses softwares.
      </TermsParagraph>
      <TermsSectionTitle>7 - ALTERAÇÕES DOS TERMOS DE USO </TermsSectionTitle>
      <TermsParagraph>
        A DATAELEGE se reserva ao direito de alterar estes termos de uso a
        qualquer momento. Se fizermos alterações significativas, notificaremos
        os usuários por e-mail ou publicando uma notificação em nossa
        plataforma.
      </TermsParagraph>
      <TermsSectionTitle>8 - DISPONIBILIDADE DE ACESSO</TermsSectionTitle>
      <TermsParagraph>
        O acesso à plataforma DATAELEGE e a disponibilidade de produtos podem
        ser interrompidos ou limitados por motivos técnicos ou outros de força
        maior. A DATAELEGE não pode ser responsabilizada por qualquer
        interrupção ou limitação temporária de acesso.
      </TermsParagraph>
    </TermsContainer>
  );
};

export default memo(TermsPage);
