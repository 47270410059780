import React, { memo } from "react";
import { FilterValue } from "../../../app/filter";
import { makeContainer } from "../../container/container";
import "./shop-radio-button.css";

type Props = {
  value: string;
  options: FilterValue[];
  onChange: (string) => void;
};

const ShopRadioButtonContainer = makeContainer("shop-radio-button-container");

const ShopRadioButton: React.FC<Props> = ({ value, options, onChange }) => {
  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <ShopRadioButtonContainer>
      {options.map((option, index) => (
        <div className="radio-button" key={index}>
          <input
            type="radio"
            id={`option${index}`}
            name="option"
            value={option.value}
            checked={value === option.value}
            onChange={handleOptionChange}
          />
          <label className="shop-radio-button-label" htmlFor={`option${index}`}>
            {option.label}
          </label>
        </div>
      ))}
    </ShopRadioButtonContainer>
  );
};

export default memo(ShopRadioButton);
