import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  AllPartiesProfileFilterNames,
  PartiesFilterConsts,
  Status,
} from "../../../../../app/constants";
import {
  ActiveFilter,
  add,
  create,
  createName,
  createValue,
  FilterNameValue,
  getValue,
  hasFilter,
  remove,
  replace,
} from "../../../../../app/filter";
import { AppDispatch } from "../../../../../app/store";
import {
  addToArrayWithoutDups,
  getAvailableStates,
  getAvaliableYearsForState,
} from "../../../../../app/utils";
import { makeContainer } from "../../../../../components/container/container";
import Filter from "../../../../../components/dashboard/filter/filter";
import { setAlert } from "../../../../../features/alert/slice";
import { AlertType } from "../../../../../features/alert/type";
import ConstantsService from "../../../../../features/constants/service";
import {
  getConstants,
  selectConstants,
} from "../../../../../features/constants/slice";
import { Constants } from "../../../../../features/constants/type";
import PartyService from "../../../../../features/parties/service";
import {
  cleanUp,
  getParties,
  selectParties,
  selectPartiesDataFilter,
  selectPartiesStatus,
  setFilter,
} from "../../../../../features/parties/slice";
import { Party } from "../../../../../features/parties/type";
import PermissionService from "../../../../../features/permission/service";
import {
  getPermissions,
  selectPermissions,
} from "../../../../../features/permission/slice";
import { PermissionsData } from "../../../../../features/permission/type";

import "./party-profile-filter.css";

type PageProps = {
  children?: string | JSX.Element | JSX.Element[];
  image?: string;
};

const FilterContainer = makeContainer("party-profile-filter");

const PartyProfileFilter: React.FC<PageProps> = ({ children, image }) => {
  const dispatch: AppDispatch = useDispatch();
  const constants: Constants = useSelector(selectConstants);
  const permissions: PermissionsData = useSelector(selectPermissions);
  const status: Status = useSelector(selectPartiesStatus);
  const parties: Party[] = useSelector(selectParties);
  const partyProfileFilter: ActiveFilter[] = useSelector(
    selectPartiesDataFilter
  );
  const [filterNames] = useState(AllPartiesProfileFilterNames);
  const [filterValues, setFilterValues] = useState([]);
  const { id } = useParams();

  const filterValuesProviders = {
    [PartiesFilterConsts.YEARS]: () => {
      const stateFilter = getValue(
        partyProfileFilter,
        PartiesFilterConsts.STATE
      );
      return getAvaliableYearsForState(constants, permissions, stateFilter);
    },
    [PartiesFilterConsts.OFFICE]: () => {
      return constants?.offices;
    },
    [PartiesFilterConsts.STATE]: () => {
      return getAvailableStates(constants, permissions);
    },
  };

  const filterSetRules = {
    [PartiesFilterConsts.YEARS]: (filter: ActiveFilter) => {
      const currentYearsFilter =
        getValue(partyProfileFilter, PartiesFilterConsts.YEARS) ?? [];
      const allButYears = remove(partyProfileFilter, PartiesFilterConsts.YEARS);

      const newFilterValue = addToArrayWithoutDups(
        currentYearsFilter,
        filter.value.value
      );

      const newFilter = create(
        filter.name,
        createValue(newFilterValue.join(", "), newFilterValue)
      );
      return add(allButYears, newFilter);
    },
    [PartiesFilterConsts.OFFICE]: (filter: ActiveFilter) => {
      return replace(partyProfileFilter, filter);
    },
    [PartiesFilterConsts.STATE]: (filter: ActiveFilter) => {
      if (hasFilter(partyProfileFilter, PartiesFilterConsts.YEARS)) {
        dispatch(
          setAlert({
            message:
              "Remova o filtro de anos antes de adicionar um filtro de estado",
            type: AlertType.WARNING,
          })
        );
        return partyProfileFilter;
      }

      const years = getAvaliableYearsForState(
        constants,
        permissions,
        filter.value.value
      ).map((f) => f.value);

      const yearFilter = create(
        createName("Ano", PartiesFilterConsts.YEARS),
        createValue(years.join(", "), years)
      );
      return add(replace(partyProfileFilter, filter), yearFilter);
    },
  };

  const filterUnSetRules = {
    [PartiesFilterConsts.PARTY_ID]: (filter: ActiveFilter) => {
      dispatch(
        setAlert({
          message: "Não é possível remover o filtro de partido.",
          type: AlertType.WARNING,
        })
      );
      return partyProfileFilter;
    },
    [PartiesFilterConsts.YEARS]: (filter: ActiveFilter) => {
      if (hasFilter(partyProfileFilter, PartiesFilterConsts.STATE)) {
        dispatch(
          setAlert({
            message: "Remova o filtro de estado antes do filtro de anos.",
            type: AlertType.WARNING,
          })
        );
        return partyProfileFilter;
      }

      return remove(partyProfileFilter, PartiesFilterConsts.YEARS);
    },
    [PartiesFilterConsts.OFFICE]: (filter: ActiveFilter) => {
      return remove(partyProfileFilter, PartiesFilterConsts.OFFICE);
    },
    [PartiesFilterConsts.STATE]: (filter: ActiveFilter) => {
      return remove(
        remove(partyProfileFilter, PartiesFilterConsts.STATE),
        PartiesFilterConsts.YEARS
      );
    },
  };

  const onFilterNameChanged = (filter: FilterNameValue) => {
    setFilterValues(filterValuesProviders[filter.value]());
  };

  const onFilterAdded = (filter: ActiveFilter) => {
    dispatch(setFilter(filterSetRules[filter.name.value](filter)));
  };

  const onFilterRemoved = (filter: ActiveFilter) => {
    dispatch(setFilter(filterUnSetRules[filter.name.value](filter)));
  };

  useEffect(() => {
    const setupInitialState = () => {
      dispatch(getParties({ service: new PartyService() }));
      dispatch(getPermissions(new PermissionService()));
      dispatch(getConstants({ service: new ConstantsService() }));
    };

    setupInitialState();
  }, [dispatch]);

  useEffect(() => {
    const addPartyFilter = () => {
      if (parties && status !== Status.LOADING) {
        dispatch(
          setFilter([
            create(
              createName("Partido", PartiesFilterConsts.PARTY_ID),
              createValue(parties?.find((p) => p.id === id).abbrev, id)
            ),
          ])
        );
      }
    };
    addPartyFilter();
    // eslint-disable-next-line
  }, [parties, id]);

  useEffect(() => {
    return () => {
      dispatch(cleanUp(null));
    };
  }, [dispatch]);

  return (
    <FilterContainer>
      <Filter
        filterNames={filterNames}
        filterValues={filterValues}
        activeFilters={partyProfileFilter}
        onFilterNameChanged={onFilterNameChanged}
        onFilterAdded={onFilterAdded}
        onFilterRemoved={onFilterRemoved}
      />
    </FilterContainer>
  );
};

export default PartyProfileFilter;
