import React from "react";
import { makeContainer } from "../../../components/container/container";
import "./page.css";

type PageProps = {
  children?: string | JSX.Element | JSX.Element[];
  image?: string;
};

type ImagePaneProps = {
  image?: string;
};

const PageContainer = makeContainer("intro-page-container");
const IntroContent = makeContainer("intro-content");

const Dots: React.FC = () => {
  return (
    <>
      <div className="blue-dots-up" />
      <div className="black-dots-left" />
      <div className="blue-dots-down" />
    </>
  );
};

const ImagePage: React.FC<ImagePaneProps> = ({ image }) => {
  return (
    image && (
      <img alt="imagem intro" src={image} className="intro-image-pane"></img>
    )
  );
};

const Page: React.FC<PageProps> = ({ children, image }) => {
  return (
    <PageContainer>
      <IntroContent>{children}</IntroContent>
      <ImagePage image={image} />
      <Dots />
    </PageContainer>
  );
};

export default Page;
