import { memo } from "react";
import "./profile-form-text.css";

type Props = {
  children: string;
  className?: string;
};

const ProfileFormText: React.FC<Props> = ({ children, className }) => {
  return <span className={`profile-form-text ${className}`}>{children}</span>;
};

export default memo(ProfileFormText);
