import { memo, MouseEventHandler } from "react";
import sidebarCircle from "../../../resources/icons/retreat-sidebar-circle.svg";
import leftArrow from "../../../resources/icons/retreat-sidebar-left-arrow.svg";
import rightArrow from "../../../resources/icons/retreat-sidebar-right-arrow.svg";
import "./retreat-button.css";

type Props = {
  expanded: boolean;
  onClick?: MouseEventHandler<HTMLImageElement>;
};

const RetreatButton: React.FC<Props> = (props) => {
  const { expanded, onClick } = props;

  return (
    <>
      <img
        className={expanded ? "retreat-button" : "contracted-retreat"}
        onClick={onClick}
        src={sidebarCircle}
        alt="botão retrair menu"
      />
      <img
        className={
          expanded ? "retreat-button-arrow" : "contracted-retreat-arrow"
        }
        onClick={onClick}
        src={expanded ? leftArrow : rightArrow}
        alt="seta"
      />
    </>
  );
};

export default memo(RetreatButton);
