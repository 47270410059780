import { memo, useEffect, useRef, useState } from "react";
import { ACCESS_PLAN, SquareFlags } from "../../../../app/constants";
import { useOnScreen } from "../../../../app/hooks";
import { fromCurrencyToStr } from "../../../../app/utils";
import ShopCartDeleteTextButton from "../../../../components/buttons/shop-cart-delete-text-button/shop-cart-delete-text-button";
import { makeContainer } from "../../../../components/container/container";
import { Candidate } from "../../../../features/candidates/type";
import ShopService from "../../../../features/shop/service";
import { CartItem } from "../../../../features/shop/type";
import Sillouete from "../../../../resources/icons/user-silhouette.svg";
import "./shop-cart-item.css";

type Props = {
  item: CartItem;
  removeChartItem: (string) => void;
};

const ShopCartItemContainer = makeContainer("shop-cart-item-container");
const ShopCartItemImage = makeContainer("shop-cart-item-image");
const ShopCartItemDetails = makeContainer("shop-cart-item-details");
const ShopCartItemPrice = makeContainer("shop-cart-item-price");
const ShopCartTitleText = makeContainer("shop-cart-item-title-text");
const ShopCartDetailsText = makeContainer("shop-cart-item-details-text");

const ShopCartReportItem: React.FC<Props> = ({ item, removeChartItem }) => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const [visibilityTimeout, setVisiblityTimeout] = useState(null);
  const [candidatePhoto, setCandidatePhoto] = useState(Sillouete);
  const [candidate, setCandidate] = useState<Candidate>(null);

  useEffect(() => {
    const fetchCandidateData = async () => {
      const service = new ShopService();
      const candidate = await service.getCandidate(item.candidate_id);
      setCandidate(candidate);
    };

    fetchCandidateData();
  });

  useEffect(() => {
    const notifyVisibilityChange = () => {
      const shouldFetch = isVisible && !visibilityTimeout;
      const shouldStopFetching = !isVisible && visibilityTimeout;

      const fetchPhoto = async () => {
        const service = new ShopService();
        const photo = await service.getCandidatePhoto(item.candidate_id);
        if (photo) {
          setCandidatePhoto(`data:image/png;base64,${photo}`);
        }
      };

      if (shouldFetch) {
        setVisiblityTimeout(setTimeout(() => fetchPhoto(), 1000));
      }

      if (shouldStopFetching) {
        clearTimeout(visibilityTimeout);
        setVisiblityTimeout(null);
      }
    };

    notifyVisibilityChange();
    // eslint-disable-next-line
  }, [item, isVisible]);

  return (
    <>
      <hr className="shop-cart-separator " />
      <ShopCartItemContainer>
        <ShopCartItemImage>
          <img
            ref={ref}
            alt={"foto do candidato"}
            className="shop-candidate-cart-item-photo"
            src={candidatePhoto}
          ></img>
        </ShopCartItemImage>
        <ShopCartItemDetails>
          <ShopCartTitleText>{item.product.description}</ShopCartTitleText>
          <ShopCartDetailsText>{`Candidato: ${candidate?.ballotboxname}`}</ShopCartDetailsText>
          <ShopCartDetailsText>{`Ano: ${item.year}`}</ShopCartDetailsText>
          <ShopCartDetailsText>{`Turno: ${item.turn}º turno`}</ShopCartDetailsText>
          <ShopCartDeleteTextButton onClick={() => removeChartItem(item.id)}>
            Excluir do carrinho
          </ShopCartDeleteTextButton>
        </ShopCartItemDetails>
        <ShopCartItemPrice>
          {fromCurrencyToStr(item.product.price)}
        </ShopCartItemPrice>
      </ShopCartItemContainer>
    </>
  );
};

const ShopCartPlanItem: React.FC<Props> = ({ item, removeChartItem }) => {
  return (
    <>
      <hr className="shop-cart-separator " />
      <ShopCartItemContainer>
        <ShopCartItemImage>
          <img
            alt={"bandeira do estado candidato"}
            className="shop-candidate-cart-item-flag"
            src={SquareFlags[item.product.state]}
          ></img>
        </ShopCartItemImage>
        <ShopCartItemDetails>
          <ShopCartTitleText>{item.product.description}</ShopCartTitleText>
          <ShopCartDetailsText>{`Estado: ${item.product.state}`}</ShopCartDetailsText>
          <ShopCartDetailsText>{`Anos: ${item.product.years[0]}-${
            item.product.years[item.product.years.length - 1]
          }`}</ShopCartDetailsText>
          <ShopCartDetailsText>{`Possui Meta de Votos: ${
            item.product.has_votes_target ? "Sim" : "Não"
          }`}</ShopCartDetailsText>
          <ShopCartDetailsText>{`Duração: ${item.product.duration} dias`}</ShopCartDetailsText>
          <ShopCartDeleteTextButton onClick={() => removeChartItem(item.id)}>
            Excluir do carrinho
          </ShopCartDeleteTextButton>
        </ShopCartItemDetails>
        <ShopCartItemPrice>
          {fromCurrencyToStr(item.product.price)}
        </ShopCartItemPrice>
      </ShopCartItemContainer>
    </>
  );
};

const ShopCartItem: React.FC<Props> = ({ item, removeChartItem }) => {
  if (item.product.product_type === ACCESS_PLAN) {
    return <ShopCartPlanItem item={item} removeChartItem={removeChartItem} />;
  } else {
    return <ShopCartReportItem item={item} removeChartItem={removeChartItem} />;
  }
};

export default memo(ShopCartItem);
