import React, { memo } from "react";
import { useSelector } from "react-redux";
import { Status } from "../../../app/constants";
import {
  selectElectionsResults,
  selectElectionsStatus,
} from "../../../features/elections/slice";
import { ElectionResult } from "../../../features/elections/type";
import ElectionSummaryContainer from "../components/election-summary/election-summary";

const ElectionsSummaryWithData: React.FC = () => {
  const results: ElectionResult = useSelector(selectElectionsResults);
  const status: Status = useSelector(selectElectionsStatus);
  return (
    <ElectionSummaryContainer summary={results?.summary} status={status} />
  );
};

export default memo(ElectionsSummaryWithData);
