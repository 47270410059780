import { memo } from "react";
import "./intro-content-text.css";

type Props = {
  children: string;
  className: string;
};

const IntroContetText: React.FC<Props> = ({ children, className }) => {
  return <span className={`intro-content-text ${className}`}>{children}</span>;
};

export default memo(IntroContetText);
