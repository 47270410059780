import React from "react";
import { useSelector } from "react-redux";
import { Pages, ShopPages } from "../../app/constants";
import { selectIsLogged } from "../../features/auth/slice";
import {
  selectCurrentPage,
  selectIsSigningIn,
  selectIsSigningUp,
} from "../../features/shop/slice";
import ShopLogin from "../../pages/shop/auth/login";
import ShopSignup from "../../pages/shop/auth/signup";
import Cart from "../../pages/shop/checkout/cart";
import Checkout from "../../pages/shop/checkout/checkout";
import OrderDetails from "../../pages/shop/order/order-details";
import Orders from "../../pages/shop/order/orders";
import PlanSelect from "../../pages/shop/plans/plan-select";
import ProductSelect from "../../pages/shop/product/product-select";
import ReportCandidateSelect from "../../pages/shop/report/report-candidate-select";
import ReportCandidatureSelect from "../../pages/shop/report/report-candidature-select";
import ReportTypeSelect from "../../pages/shop/report/report-type-select";
import { makeContainer } from "../container/container";
import { ShopMenu } from "./menu";
import "./page.css";

type PageProps = {
  title?: string | JSX.Element | JSX.Element[];
  page?: Pages;
  children?: JSX.Element;
};

const PageContainer = makeContainer("shop-page-container");
const Main = makeContainer("shop-main");

const PageContent = {
  [ShopPages.LOGIN]: ShopLogin,
  [ShopPages.SIGNUP]: ShopSignup,
  [ShopPages.CART]: Cart,
  [ShopPages.CHECKOUT]: Checkout,
  [ShopPages.ORDER_DETAILS]: OrderDetails,
  [ShopPages.ORDERS]: Orders,
  [ShopPages.PLANS]: PlanSelect,
  [ShopPages.PRODUCT]: ProductSelect,
  [ShopPages.REPORT_CANDIDATE]: ReportCandidateSelect,
  [ShopPages.REPORT_CANDIDATURE]: ReportCandidatureSelect,
  [ShopPages.REPORT_TYPE]: ReportTypeSelect,
};

const PagesRequiringLogin = [
  ShopPages.CART,
  ShopPages.CHECKOUT,
  ShopPages.ORDER_DETAILS,
  ShopPages.ORDERS,
];

export const ShopPage: React.FC<PageProps> = ({ children, title, page }) => {
  const currentPage = useSelector(selectCurrentPage);
  const isLogged = useSelector(selectIsLogged);
  const isSigningIn = useSelector(selectIsSigningIn);
  const isSigningUp = useSelector(selectIsSigningUp);

  let Page = PageContent[currentPage];

  const requiresLogin = PagesRequiringLogin.includes(currentPage);
  const shouldSeeLogin = !isLogged && requiresLogin;

  if (isSigningIn || shouldSeeLogin) {
    Page = PageContent[ShopPages.LOGIN];
  }

  if (isSigningUp) {
    Page = PageContent[ShopPages.SIGNUP];
  }

  return (
    <PageContainer>
      <ShopMenu />
      <Main>
        <Page />
      </Main>
    </PageContainer>
  );
};

export default ShopPage;
