import React, { memo } from "react";
import "./shop-page-container.css";

type Props = {
  children: string | JSX.Element | JSX.Element[];
};

const ShopPageContainer: React.FC<Props> = ({ children }) => {
  return <div className="shop-page">{children}</div>;
};

export default memo(ShopPageContainer);
