import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import { resetAlert, selectAlertData } from "../../features/alert/slice";
import AlertData, { AlertType } from "../../features/alert/type";
import "./alert.css";

const AlertTypeClasses = {
  [AlertType.MESSAGE]: "alert-show-message",
  [AlertType.WARNING]: "alert-show-warn",
  [AlertType.ERROR]: "alert-show-error",
};

const Alert: React.FC = (props) => {
  const alertData: AlertData = useSelector(selectAlertData);
  const dispatch: AppDispatch = useDispatch();

  const hasMessage = alertData.message !== "";
  const currentClass = hasMessage
    ? AlertTypeClasses[alertData.type]
    : "alert-hidden";

  if (hasMessage) {
    setTimeout(() => {
      dispatch(resetAlert(null));
    }, 5000);
  }

  return (
    <div className={`base-alert ${currentClass}`}>{alertData.message}</div>
  );
};

export default memo(Alert);
