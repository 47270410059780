import * as React from "react";
import { useSelector } from "react-redux";
import { NotebooksCausesOptions } from "../../../../../app/constants";
import { validateText } from "../../../../../app/utils";
import { selectPalette } from "../../../../../features/notebook/slice";
import { ClippedImage, Paper, PaperInside } from "../commons/commons";
import { TemplatePageProps } from "../commons/interfaces";

const Page8: React.FC<TemplatePageProps> = ({
  className,
  notebook,
  triggerImageEdit,
  triggerFieldEdit,
  updateField,
}) => {
  const { palette } = useSelector(selectPalette);
  return (
    <Paper className={className}>
      <PaperInside>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
        >
          <defs>
            <clipPath id="page8-a">
              <path d="M1955.7 1720.13h-111.5v-111.51a134.4 134.4 0 0 1 134.4-134.4 111.51 111.51 0 0 1 111.51 111.51 134.4 134.4 0 0 1-134.41 134.4z" />
            </clipPath>
            <clipPath id="page8-b">
              <path d="M2307.87 1238.11a133.24 133.24 0 0 1 133.24 133.24v110.55h-110.54a133.24 133.24 0 0 1-133.24-133.24 110.55 110.55 0 0 1 110.55-110.55z" />
            </clipPath>
            <clipPath id="page8-c">
              <path d="M1947.43 1261.09h-98.74v-98.74a119 119 0 0 1 119-119 98.74 98.74 0 0 1 98.74 98.74 119 119 0 0 1-119 119z" />
            </clipPath>
          </defs>
          <path
            d="M.46.434h210.144v297.201H.46z"
            style={{
              fill: palette.backgroundLight,
              strokeWidth: 0.353017,
            }}
          />
          <path
            d="M.055.761h209.97v296.955H.055z"
            style={{
              fill: palette.backgroundLight,
              strokeWidth: 0.352724,
            }}
          />
          <g
            data-name="CZJK68"
            style={{
              opacity: 0.13,
            }}
          >
            <path d="M177.67 155.265c-.48.222-.978.423-1.486.603 2.766 2.666 5.605 4.709 8.575 5.643 5.125 1.605 9.96-.596 14.462-6.828 3.386-4.677 6.229-10.935 8.793-17.99l1.005-2.733v-2.38c-.892 2.348-1.763 4.754-2.687 7.054-3.246 8.045-6.903 14.592-11.348 18.26-3.594 2.967-7.27 3.5-11.005 2.173a21.125 21.125 0 0 1-6.31-3.802z" />
            <path d="M172.428 156.725a14.65 14.65 0 0 1-1.298.081 53.776 53.776 0 0 0 7.432 7.94c2.878 2.427 5.823 4.017 8.881 4.194 4.79.279 9.118-3.153 13.072-9.594 3.146-5.125 5.77-11.598 8.155-18.695.113-.352.236-.652.353-.977v-2.38c-1.058 2.87-2.053 5.84-3.15 8.588-3.718 9.309-7.95 16.607-13.259 19.587-3.767 2.116-7.51 1.573-11.23-.656-3.126-1.866-6.1-4.663-8.956-8.088z" />
            <path d="M173.299 164.044c-1.982-2.317-3.905-4.875-5.795-7.57a15.928 15.928 0 0 1-1.256-.313c2.928 4.377 5.922 8.486 9.076 11.908 2.984 3.238 6.06 5.753 9.315 6.895 5.443 1.909 10.462-.843 15.079-8.028 2.698-4.2 5.04-9.393 7.129-15.283.73-2.07 1.446-4.173 2.169-6.261v-2.38c-.811 2.37-1.587 4.817-2.445 7.096-2.786 7.428-5.872 13.964-9.65 18.313q-5.175 5.958-10.882 4.893c-4.518-.84-8.69-4.533-12.74-9.27z" />
            <path d="M162.41 156.09c.145-.271.286-.55.427-.825 2.328 3.566 4.667 7.11 7.055 10.486 3.576 5.087 7.333 9.298 11.364 11.887 4.163 2.67 8.332 3.108 12.494.046 3.975-2.92 7.361-8.286 10.271-15.333 1.764-4.296 3.351-9.072 5.012-13.63v2.618c-.352.942-.705 1.852-1.012 2.822-2.021 6.218-4.293 11.823-6.913 16.557-5.312 9.58-11.196 13.09-17.725 9.946-3.954-1.9-7.615-5.675-11.156-10.204-3.397-4.32-6.628-9.27-9.817-14.37z" />
            <path d="M161.278 160.02c-.197-.322-.395-.643-.596-.963.138-.216.275-.434.406-.653q2.352 3.697 4.719 7.361c3.693 5.697 7.502 10.875 11.59 14.758 3.193 3.034 6.484 5.08 9.93 5.252 4.314.212 8.268-2.72 11.897-8.303 3.235-4.977 5.922-11.403 8.339-18.553.483-1.41.973-2.822 1.46-4.233v2.381c-.208.59-.427 1.164-.628 1.764-2.727 8.342-5.76 15.872-9.555 21.396-4.519 6.575-9.383 8.762-14.571 6.734-3.556-1.39-6.886-4.441-10.106-8.25-4.515-5.344-8.712-11.969-12.885-18.692z" />
            <path d="M158.777 161.804a27.6 27.6 0 0 0 .476-.645c3.63 5.643 7.267 11.234 10.963 16.578 3.88 5.615 7.993 9.968 12.43 12.289 5.062 2.642 9.95 1.555 14.603-4.194 4.091-5.055 7.35-12.511 10.254-21.001q.758-2.23 1.52-4.448v2.38c-.106.286-.226.554-.325.858-2.723 8.448-5.76 16.08-9.555 21.745-4.187 6.243-8.726 8.818-13.612 7.534-3.735-.977-7.234-3.929-10.617-7.76-4.232-4.79-8.19-10.779-12.102-16.955q-2.02-3.206-4.035-6.38z" />
            <path d="M156.834 164.372c.134-.176.271-.353.405-.533l.388.61c4.65 7.263 9.252 14.71 14.17 20.885 3.88 4.89 7.907 8.95 12.278 10.582 4.437 1.665 8.701.423 12.775-4.285 3.961-4.586 7.175-11.457 10.032-19.4.726-2.018 1.428-4.095 2.14-6.145v2.378c-.105.292-.221.571-.32.878-2.6 7.912-5.485 15.075-9.03 20.543q-7.09 10.934-15.386 7.78c-3.555-1.35-6.896-4.33-10.126-8.059-4.653-5.358-8.984-12.063-13.291-18.853-1.348-2.12-2.691-4.258-4.035-6.38z" />
            <path d="M155.024 166.538c2.328 3.442 4.646 6.92 6.974 10.377 3.88 5.792 7.791 11.552 11.957 16.155 3.845 4.232 7.799 7.537 12.056 8.303 4.257.765 8.26-1.288 12.028-6.13 3.33-4.283 6.13-10.173 8.649-16.907.797-2.137 1.559-4.356 2.335-6.536v2.378c-.095.264-.198.518-.293.79-2.173 6.42-4.55 12.345-7.333 17.216-5.358 9.411-11.287 12.9-17.81 10.032-3.107-1.365-6.07-3.852-8.948-6.995-4.289-4.69-8.306-10.581-12.303-16.578-2.582-3.88-5.157-7.788-7.76-11.64z" />
            <path d="M152.626 168.936c2.366 3.298 4.702 6.74 7.054 10.155 4.194 6.11 8.384 12.236 12.822 17.284 3.975 4.504 8.056 8.134 12.45 9.333 3.764 1.023 7.408.028 10.935-3.326 3.82-3.626 7.055-9.386 9.915-16.391 1.115-2.72 2.145-5.644 3.214-8.466v2.378c-.095.264-.198.522-.293.793-2.776 7.827-5.852 14.793-9.598 19.859-4.137 5.597-8.567 7.862-13.276 6.885-3.453-.706-6.748-3.03-9.954-6.145-4.585-4.44-8.818-10.327-13.05-16.39-3.592-5.158-7.161-10.389-10.77-15.468.187-.173.367-.338.55-.5z" />
            <path d="M149.726 172.636c-.236-.306-.476-.61-.705-.913.194-.127.391-.254.582-.385 1.827 2.258 3.63 4.635 5.425 7.055 4.691 6.3 9.354 12.73 14.109 18.786 4.088 5.217 8.366 9.386 12.92 11.64 3.852 1.905 7.696 2.07 11.534-.303 4.028-2.498 7.563-7.323 10.67-13.908 1.679-3.56 3.196-7.573 4.783-11.38v2.378a101.528 101.528 0 0 1-5.901 13.337c-4.677 8.525-9.876 13.163-15.777 12.793-3.88-.243-7.573-2.497-11.182-5.753-4.504-4.06-8.737-9.488-12.924-15.146-4.522-6.085-8.99-12.31-13.534-18.2z" />
            <path d="m145.017 173.882.547-.25a115.253 115.253 0 0 1 7.81 8.867c4.86 6.017 9.678 12.264 14.57 18.126 4.893 5.862 10.018 10.72 15.496 12.698 2.762.991 5.523 1.094 8.285.035 4.014-1.544 7.64-5.396 10.973-10.987 2.318-3.88 4.385-8.49 6.325-13.432v2.618c-.134.257-.279.497-.406.772-1.301 2.822-2.543 5.827-3.908 8.465-4.818 9.291-10.264 14.593-16.476 14.709-3.9.07-7.672-1.83-11.365-4.769-4.285-3.414-8.352-8.053-12.345-13.019-5.08-6.296-10.116-12.807-15.238-18.91-1.446-1.724-2.906-3.34-4.391-4.81.028-.054.074-.082.123-.113z" />
            <path d="M140.203 176.594c.271.18.54.353.807.557 4.97 3.528 9.683 8.663 14.356 14.003 5.217 5.961 10.363 12.293 15.735 17.414 4.565 4.353 9.224 7.792 14.148 8.744 5.206 1.01 10.159-1.146 14.836-6.846 3.34-4.07 6.264-9.523 8.938-15.827v2.618c-.102.172-.212.328-.31.518-1.507 2.907-2.953 6.018-4.522 8.688-4.103 6.959-8.653 11.1-13.654 12.158-3.64.769-7.22-.212-10.77-2.219-4.521-2.557-8.838-6.578-13.05-11.237-4.846-5.365-9.643-11.005-14.49-16.38-4.233-4.71-8.553-8.992-13.068-11.768.349-.134.698-.275 1.044-.423z" />
            <path d="M209.023 200.604c-.744 1.383-1.489 2.783-2.24 4.152-3.778 6.881-7.922 11.862-12.56 14.281a14.067 14.067 0 0 1-10.23 1.185c-5.393-1.326-10.539-5.04-15.555-9.82-4.955-4.722-9.855-9.791-14.786-14.683-4.54-4.505-9.128-8.67-13.915-11.41-3.2-1.832-6.437-2.96-9.728-2.929-6.233.064-11.936 4.413-17.125 12.77a1.111 1.111 0 0 0-.17.486 2.66 2.66 0 0 0 .297-.353c3.312-5.083 6.885-8.628 10.727-10.48a13.936 13.936 0 0 1 8.67-1.156c6.493 1.128 12.61 5.908 18.606 11.671 5.435 5.224 10.818 10.776 16.25 16.046 4.232 4.116 8.571 7.541 13.05 9.647 5.866 2.751 11.693 2.786 17.436-1.788 4-3.175 7.57-8.254 10.846-14.614.123-.24.268-.423.399-.635z" />
            <path d="M209.023 206.3c-.353.526-.684 1.03-1.016 1.584-4.286 7.178-8.956 12.106-14.109 14.271a16.225 16.225 0 0 1-9.845.91c-6.313-1.319-12.345-5.516-18.302-10.461-4.939-4.106-9.877-8.42-14.815-12.53-4.55-3.78-9.17-6.877-13.936-8.644-8.289-3.076-16.243-.907-23.7 8.983a8.391 8.391 0 0 0-.984 1.595 3.076 3.076 0 0 0 .304-.304q6.412-8.521 13.692-10.105c3.986-.868 7.947-.194 11.884 1.492 6.55 2.822 12.814 8.024 19.068 13.365 5.065 4.331 10.116 8.797 15.298 12.281 4.786 3.217 9.636 5.542 14.62 5.82q11.672.657 21.358-15.24c.148-.24.31-.428.462-.64z" />
            <path d="M209.023 212.008c-.995 1.255-1.979 2.56-2.988 3.76-4.511 5.364-9.273 8.532-14.275 9.608-4.282.924-8.532.324-12.775-1.171-5.721-2.014-11.287-5.683-16.836-9.404-5.29-3.552-10.581-7.136-15.929-10.451a33.583 33.583 0 0 0-12.723-4.871c-7.407-1.133-14.525 1.492-21.269 9.245a1.644 1.644 0 0 0-.353.677c.836-.818 1.672-1.669 2.515-2.444 4.184-3.835 8.512-5.849 12.97-6.413 5.594-.705 11.09 1.033 16.55 3.739 5.996 2.97 11.84 7.17 17.72 11.139 5.02 3.39 10.053 6.635 15.168 8.85 4.67 2.01 9.375 3.065 14.109 2.437 6.271-.829 12.2-4.737 17.749-11.943a4.585 4.585 0 0 1 .353-.378z" />
            <path d="M209.023 217.718c-1.697 1.492-3.376 3.132-5.097 4.444a26.927 26.927 0 0 1-12.18 5.362c-4.754.776-9.484.155-14.207-1.192-5.44-1.552-10.8-4.187-16.17-6.77-5.463-2.623-10.916-5.357-16.4-7.685-5.324-2.257-10.7-3.446-16.113-3.153-5.937.32-11.736 2.589-17.333 7.54a1.058 1.058 0 0 0-.353.516c.843-.614 1.683-1.266 2.533-1.838a29.054 29.054 0 0 1 11.354-4.642 28.348 28.348 0 0 1 12.652.621 98.036 98.036 0 0 1 12.472 4.392c6.3 2.716 12.557 6.017 18.86 8.698 3.944 1.679 7.93 2.822 11.908 3.943a26.839 26.839 0 0 0 11.094.656c5.76-.78 11.368-3.407 16.775-8.388a1.894 1.894 0 0 1 .187-.123z" />
          </g>

          <text
            x={13.596}
            y={27.631}
            style={{
              fontSize: "14.2924px",
              fontFamily: "Heavitas",
              fill: palette.primary,
              strokeWidth: 0.352724,
            }}
          >
            {"PRINCIPAIS"}
            <tspan
              style={{
                strokeWidth: 0.352724,
              }}
            >
              <tspan
                x={13.596}
                y={44.781}
                style={{
                  fill: palette.backgroundGray,
                  strokeWidth: 0.352724,
                }}
              >
                {"BANDEIRAS"}
              </tspan>
            </tspan>
          </text>

          <ClippedImage
            id="saphire-page-8-1"
            triggerImageEdit={triggerImageEdit}
            x={0}
            y={58}
            width={87}
            height={87}
            imageNumber={9}
            imageRatio={1}
            notebook={notebook}
          >
            <path
              d="M1947.43 1261.09h-98.74v-98.74a119 119 0 0 1 119-119 98.74 98.74 0 0 1 98.74 98.74 119 119 0 0 1-119 119z"
              fill={palette.backgroundLight}
              transform="matrix(.35272 0 0 .35272 -651.37 -309.083)"
            />
          </ClippedImage>

          <foreignObject
            x={85.005}
            y={75.032}
            width={110}
            height={50}
            className="clickable"
          >
            <div
              style={{
                fontSize: "6px",
                fontFamily: "MontserratAlternates",
                fontWeight: 500,
                color: palette.backgroundDark,
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.first_cause,
                  field: "first_cause",
                  label: "Primeira Bandeira",
                  type: "select",
                  options: NotebooksCausesOptions.map((c) => ({
                    value: c,
                    label: c,
                  })),
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.first_cause}
            </div>

            <div
              style={{
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: palette.backgroundGray,
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.first_cause_proposal,
                  field: "first_cause_proposal",
                  label: "Primeira Bandeira",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.first_cause_proposal}
            </div>
          </foreignObject>

          <ClippedImage
            id="saphire-page-8-2"
            triggerImageEdit={triggerImageEdit}
            x={123}
            y={127}
            width={87}
            height={87}
            imageNumber={10}
            imageRatio={1}
            notebook={notebook}
          >
            <path
              d="M2307.87 1238.11a133.24 133.24 0 0 1 133.24 133.24v110.55h-110.54a133.24 133.24 0 0 1-133.24-133.24 110.55 110.55 0 0 1 110.55-110.55z"
              fill={palette.backgroundLight}
              transform="matrix(.35272 0 0 .35272 -651.37 -309.083)"
            />
          </ClippedImage>

          <foreignObject
            x={10.0}
            y={145.032}
            width={110}
            height={50}
            className="clickable"
          >
            <div
              style={{
                fontSize: "6px",
                fontFamily: "MontserratAlternates",
                fontWeight: 500,
                color: palette.backgroundDark,
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.second_cause,
                  field: "second_cause",
                  label: "Segunda Bandeira",
                  type: "select",
                  options: NotebooksCausesOptions.map((c) => ({
                    value: c,
                    label: c,
                  })),
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.second_cause}
            </div>

            <div
              style={{
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: palette.backgroundGray,
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.second_cause_proposal,
                  field: "second_cause_proposal",
                  label: "Segunda Bandeira",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.second_cause_proposal}
            </div>
          </foreignObject>

          <ClippedImage
            id="saphire-page-8-3"
            triggerImageEdit={triggerImageEdit}
            x={0}
            y={210}
            width={87}
            height={87}
            imageNumber={11}
            imageRatio={1}
            notebook={notebook}
          >
            <path
              d="M1955.7 1720.13h-111.5v-111.51a134.4 134.4 0 0 1 134.4-134.4 111.51 111.51 0 0 1 111.51 111.51 134.4 134.4 0 0 1-134.41 134.4z"
              fill={palette.backgroundLight}
              transform="matrix(.35272 0 0 .35272 -651.37 -309.083)"
            />
          </ClippedImage>

          <foreignObject
            x={95.0}
            y={225.032}
            width={110}
            height={50}
            className="clickable"
          >
            <div
              style={{
                fontSize: "6px",
                fontFamily: "MontserratAlternates",
                fontWeight: 500,
                color: palette.backgroundDark,
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.third_cause,
                  field: "third_cause",
                  label: "Terceira Bandeira",
                  type: "select",
                  options: NotebooksCausesOptions.map((c) => ({
                    value: c,
                    label: c,
                  })),
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.third_cause}
            </div>

            <div
              style={{
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: palette.backgroundGray,
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.third_cause_proposal,
                  field: "third_cause_proposal",
                  label: "Terceira Bandeira",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.third_cause_proposal}
            </div>
          </foreignObject>

          <path
            d="M219.344 12.718a50.651 50.651 0 0 1 1.132-12.941H101.629s34.426 25.131 83.437 22.927c18.528-.84 27.907-3.838 34.648-7.192z"
            style={{
              fill: palette.primary,
              strokeWidth: 0.352724,
            }}
          />
        </svg>
      </PaperInside>
    </Paper>
  );
};

export default Page8;
