import React, { memo } from "react";
import { COLOR_SCALE, TILES } from "../../../../app/constants";
import { toFilterValue } from "../../../../app/filter";
import { makeContainer } from "../../../../components/container/container";
import SettingsRange from "../../../../components/inputs/settings-range/settings-range";
import SettingsSelect from "../../../../components/inputs/settings-select/settings-select";
import SettingsSwitch from "../../../../components/inputs/settings-switch/settings-switch";
import { Settings } from "../../../../features/settings/type";
import "./choropleth-settings-form.css";

type Props = {
  settings: Settings;
  onSettingsFieldChange: (field: string, value: any) => void;
};

const ChoroplethSettingsContainer = makeContainer(
  "choropleth-settings-container"
);

const ChoroplethSettingsLineContainer = makeContainer(
  "choropleth-settings-line-container"
);

const ChoroplethSettingsForm: React.FC<Props> = ({
  settings,
  onSettingsFieldChange,
}) => {
  return (
    <ChoroplethSettingsContainer>
      <ChoroplethSettingsLineContainer>
        <SettingsSwitch
          label="Habilitar Mapa Cloroplético"
          value={settings?.enable_choropleth_map}
          onChange={(checked) => {
            onSettingsFieldChange("enable_choropleth_map", checked);
          }}
        />
        <SettingsSwitch
          label="Mostar Bordas"
          value={settings?.enable_choropleth_map_borders}
          onChange={(checked) => {
            onSettingsFieldChange("enable_choropleth_map_borders", checked);
          }}
        />
        <SettingsSwitch
          label="Tooltip Fixa"
          value={settings?.sticky_tooltip}
          onChange={(checked) => {
            onSettingsFieldChange("sticky_tooltip", checked);
          }}
        />
        <SettingsRange
          label="Opacidade"
          value={settings?.choropleth_map_opacity}
          min={0}
          max={100}
          onChange={(value) => {
            onSettingsFieldChange("choropleth_map_opacity", value);
          }}
        />
      </ChoroplethSettingsLineContainer>
      <ChoroplethSettingsLineContainer>
        <SettingsSelect
          label="Escala de Cores"
          className="choropleth-settings-data-select"
          placeHolder="Selecione uma escala"
          value={toFilterValue(settings?.color_scale, COLOR_SCALE)}
          options={COLOR_SCALE}
          onChange={(selected) => {
            onSettingsFieldChange("color_scale", selected.value);
          }}
        />
        <SettingsSelect
          label="Servidor de Tiles"
          className="choropleth-settings-data-select"
          placeHolder="Selecione um servidor"
          value={toFilterValue(settings?.tiles_server, TILES)}
          options={TILES}
          onChange={(selected) => {
            onSettingsFieldChange("tiles_server", selected.value);
          }}
        />
      </ChoroplethSettingsLineContainer>
    </ChoroplethSettingsContainer>
  );
};

export default memo(ChoroplethSettingsForm);
