declare global {
  interface Window {
    PagSeguro: any;
  }
}

export function getEncryptedCard(cardData) {
  const encryptedCard = window.PagSeguro.encryptCard({
    publicKey: process.env.REACT_APP_PAG_SEGURO_PKEY,
    holder: cardData.name,
    number: cardData.creditCard,
    expMonth: cardData.expirationDate.split("/")[0],
    expYear: cardData.expirationDate.split("/")[1],
    securityCode: cardData.cvv,
  })["encryptedCard"];

  return encryptedCard;
}
