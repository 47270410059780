import * as React from "react";
import { useSelector } from "react-redux";
import { validateText } from "../../../../../app/utils";
import { selectPalette } from "../../../../../features/notebook/slice";
import { Paper, PaperInside } from "../commons/commons";
import { TemplatePageProps } from "../commons/interfaces";

const Page4: React.FC<TemplatePageProps> = ({
  className,
  notebook,
  triggerImageEdit,
  triggerFieldEdit,
  updateField,
}) => {
  const { palette } = useSelector(selectPalette);
  return (
    <Paper className={className}>
      <PaperInside>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
        >
          <defs>
            <linearGradient
              id="page4-b"
              x1={4033.06}
              x2={4132.84}
              y1={-2131.95}
              y2={-2131.95}
              gradientTransform="matrix(1 -.09 -.09 -1 -1807.73 -1267.01)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} stopColor="#e6e6e6" />
              <stop offset={0.2} stopColor="#fff" />
              <stop offset={1} stopColor="#e6e6e6" />
            </linearGradient>
            <linearGradient
              id="page4-c"
              x1={4178.63}
              x2={4286.11}
              y1={-428.4}
              y2={-437.81}
              gradientTransform="matrix(1 -.2 -.09 -2.24 -1688.47 386.27)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} stopColor="#282628" />
              <stop offset={0.11} stopColor="#26282d" />
              <stop offset={0.24} stopColor="#161317" />
              <stop offset={0.44} stopColor="#181519" />
              <stop offset={0.56} stopColor="#1e1e22" />
              <stop offset={0.58} stopColor="#202024" />
              <stop offset={0.72} stopColor="#232427" />
              <stop offset={0.84} stopColor="#2d2e31" />
              <stop offset={0.84} stopColor="#2e2f32" />
              <stop offset={0.88} stopColor="#252226" />
              <stop offset={0.9} stopColor="#1e181d" />
            </linearGradient>
            <linearGradient
              id="page4-e"
              x1={4041.92}
              x2={4132.07}
              y1={-2085.82}
              y2={-2085.82}
              gradientTransform="scale(1 -1) rotate(82.5 2349.452 -2250.46)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset={0} />
              <stop offset={0.83} stopColor="#666" />
            </linearGradient>
            <linearGradient
              xlinkHref="#page4-b"
              id="l"
              x1={1194.73}
              x2={1294.52}
              y1={-126.21}
              y2={-126.21}
              gradientTransform="matrix(-1 .09 .09 1 3093.7 459.72)"
            />
            <linearGradient
              xlinkHref="#page4-c"
              id="m"
              x1={1255.69}
              x2={1363.17}
              y1={608.8}
              y2={599.4}
              gradientTransform="matrix(-1 .2 .09 2.24 2974.41 -1193.51)"
            />
            <linearGradient
              xlinkHref="#page4-e"
              id="o"
              x1={1203.6}
              x2={1293.74}
              y1={-80.08}
              y2={-80.08}
              gradientTransform="matrix(-.13 .99 .99 .13 2125.96 -776.12)"
            />
            <linearGradient
              xlinkHref="#page4-b"
              id="h"
              x1={4033.06}
              x2={4132.84}
              y1={-2131.95}
              y2={-2131.95}
              gradientTransform="matrix(1 -.09 -.09 -1 -1807.73 -1267.01)"
              gradientUnits="userSpaceOnUse"
            />
            <linearGradient
              xlinkHref="#page4-c"
              id="i"
              x1={4178.63}
              x2={4286.11}
              y1={-428.4}
              y2={-437.81}
              gradientTransform="matrix(1 -.2 -.09 -2.24 -1688.47 386.27)"
              gradientUnits="userSpaceOnUse"
            />
            <linearGradient
              xlinkHref="#page4-e"
              id="k"
              x1={4041.92}
              x2={4132.07}
              y1={-2085.82}
              y2={-2085.82}
              gradientTransform="scale(1 -1) rotate(82.5 2349.452 -2250.46)"
              gradientUnits="userSpaceOnUse"
            />
            <mask
              id="page4-j"
              width={21.26}
              height={88.71}
              x={2389.47}
              y={446.9}
              maskUnits="userSpaceOnUse"
            >
              <g mask="url(#page4-a)">
                <path d="m2410.73 447.4-19.21 88.21-2.05-.44 18.95-88.27z" />
              </g>
            </mask>
            <mask
              id="#page4-a"
              width={21.26}
              height={32766}
              x={2389.47}
              y={-9922.54}
              maskUnits="userSpaceOnUse"
            />
            <mask
              id="#page4-n"
              width={21.26}
              height={88.71}
              x={1877.44}
              y={407.93}
              maskUnits="userSpaceOnUse"
            >
              <g mask="url(#page4-d)">
                <path d="m1877.44 496.14 19.21-88.2 2.05.44-18.95 88.27z" />
              </g>
            </mask>
            <mask
              id="#page4-d"
              width={21.26}
              height={32766}
              x={1877.44}
              y={-9922.54}
              maskUnits="userSpaceOnUse"
            />
            <filter
              id="#page4-f"
              width={173.52}
              height={224.98}
              x={2309.21}
              y={0}
              filterUnits="userSpaceOnUse"
            >
              <feOffset dx={6} dy={10} />
              <feGaussianBlur result="blur-10" stdDeviation={3} />
              <feFlood floodOpacity={0.5} floodColor="#1C1C1C" />
              <feComposite in2="blur-10" operator="in" />
              <feComposite in="SourceGraphic" />
            </filter>
            <filter id="dropshadow">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <clipPath id="#page4-g">
              <path d="M1830.13 295.17h627.91v353.2h-627.91z" />
            </clipPath>
          </defs>
          <path
            d="M-.909-1.39h211.695v299.395H-.909z"
            style={{
              fill: palette.backgroundLight,
              strokeWidth: 0.355622,
            }}
          />
          <g
            style={{
              opacity: 0.13,
            }}
          >
            <path d="M194.076 255.768c.885-3.645 2.037-7.283 3.2-10.914 1.067-3.264 2.22-6.522 3.36-9.78l1.868-.956c.178-.117.356-.242.526-.356-1.18 4.339-2.446 8.678-3.42 13.023-1.292 5.786-2.49 11.576-3.24 17.372a52.952 52.952 0 0 0 1.522 21.089 52.156 52.156 0 0 0 5.451 12.774h-3.375c-4.562-6.544-7.023-13.204-7.674-19.94a71.793 71.793 0 0 1 0-12.272c.245-3.354.97-6.683 1.782-10.04z" />
            <path d="M185.025 248.457c1.305-2.507 2.71-5.007 4.147-7.504l3.58-1.831c-2.133 4.306-4.299 8.616-6.347 12.93a45.67 45.67 0 0 0-4.175 14.58c-.686 6.124-.085 12.227 2.888 18.28 2.204 4.498 5.43 8.89 10.135 13.126h-3.713a3.453 3.453 0 0 0-.49-.633c-7.298-6.49-11.33-13.25-12.615-20.232a28.94 28.94 0 0 1 .953-14.826c1.583-4.644 3.262-9.306 5.637-13.89Z" />
            <path d="M176.152 245.544q1.686-.44 3.35-.932c-3.67 5.335-6.97 10.67-8.983 16.128-2.262 6.127-2.866 12.258-.636 18.407 1.899 5.242 5.839 10.267 11.902 15.071 1.611 1.277 3.347 2.532 5.029 3.798h-3.379a5.733 5.733 0 0 0-.6-.498c-11.9-7.624-17.803-15.84-18.272-24.587-.3-5.605 1.547-11.138 4.523-16.63a89.617 89.617 0 0 1 7.066-10.757z" />
            <path d="M163.802 247.973a95.568 95.568 0 0 0 3.172-.48c-3.162 3.78-5.964 7.596-8.044 11.487-2.977 5.561-4.378 11.163-2.927 16.835q2.379 9.32 14.833 17.493c2.45 1.604 5.235 3.14 7.866 4.708h-3.375l-3.442-1.888c-13.453-7.418-20.288-15.555-20.235-24.46.032-5.747 2.685-11.348 6.654-16.881a87.23 87.23 0 0 1 5.498-6.814Z" />
            <path d="M152.471 248.947q1.473-.053 2.924-.142c-4.805 4.979-8.813 10.018-10.968 15.267-1.664 4.047-2.094 8.105-.736 12.17 1.999 5.981 7.586 11.504 16.597 16.55 3.2 1.796 6.867 3.485 10.313 5.224h-3.378c-3.112-1.5-6.402-2.959-9.307-4.509-10.48-5.598-16.785-11.76-18.325-18.532-1.103-4.832.31-9.601 3.168-14.313 2.436-4.001 5.811-7.892 9.712-11.715z" />
            <path d="M142.233 249q1.323.047 2.664.058a100.013 100.013 0 0 0-4.165 4.025c-5.131 5.303-8.933 10.705-9.698 16.359-1.013 7.5 3.998 14.41 14.848 20.786 4.79 2.813 10.494 5.36 16.622 7.792h-3.375l-.925-.356c-9.484-3.656-17.486-7.678-23.059-12.304-8.762-7.276-9.601-14.794-5.202-22.476 2.734-4.804 7.087-9.399 12.29-13.883z" />
            <path d="M133.378 248.218c.743.104 1.487.2 2.237.281-7.45 5.857-13.318 11.92-15.487 18.397-1.668 4.978-.616 9.872 3.783 14.648 5.292 5.747 14.634 10.551 26.067 14.872 1.387.526 2.725 1.067 4.087 1.6h-3.379a5.146 5.146 0 0 0-.672-.33c-7.237-2.536-13.763-5.281-19.264-8.35-9.413-5.25-14.549-10.979-14.79-17.262-.203-5.335 3.001-10.424 7.823-15.41a90.168 90.168 0 0 1 9.595-8.446z" />
            <path d="M123.093 248.812q1.451-.992 2.913-1.977c.551.132 1.102.256 1.66.377a134.996 134.996 0 0 0-5.223 4c-6.914 5.602-12.447 11.349-13.767 17.533-1.066 4.915.833 9.666 6.114 14.225 6.237 5.38 16.042 9.811 27.607 13.78 1.212.416 2.375.85 3.556 1.277h-3.375c-3.133-1.113-6.334-2.198-9.378-3.34-9.246-3.467-16.927-7.318-22.027-11.806-7.134-6.277-7.603-12.714-3.517-19.26 3.247-5.207 8.602-10.132 15.437-14.809z" />
            <path d="M105.647 254.858c4.064-3.403 8.808-6.672 13.823-9.89.501.167 1.002.33 1.507.487a187.539 187.539 0 0 0-5.665 3.912c-7.578 5.477-13.983 11.11-16.44 17.234-1.881 4.683-1.127 9.292 3.083 13.801 5.207 5.573 14.499 10.164 25.914 14.225 3.244 1.156 6.654 2.251 9.986 3.371h-3.375l-3.883-1.262c-10-3.2-18.866-6.785-25.505-11.024-8.841-5.651-11.967-11.715-9.687-18.137 1.571-4.428 5.37-8.631 10.242-12.717zM209.74 228.584c.357-.309.712-.622 1.046-.938v70.37h-2.364c-.096-.59-.242-1.184-.281-1.778-.502-7.262-1.238-14.523-1.423-21.789-.284-11.483.807-22.959 1.956-34.435.388-3.809.729-7.617 1.067-11.43z" />
            <path d="M90.81 259.805c5.523-5.79 13.531-11.135 22.301-16.33l.21-.122c.551.157 1.11.31 1.671.46a633.643 633.643 0 0 0-6.582 4.078c-7.717 4.858-13.99 9.958-17.707 15.452-3.165 4.67-3.936 9.367-.931 14.09 4.228 6.657 14.58 11.967 27.791 16.629 3.912 1.38 8.12 2.638 12.191 3.954h-3.375c-.462-.15-.91-.302-1.387-.444-10.067-2.991-19.25-6.284-26.522-10.232-9.143-4.957-14.011-10.387-13.613-16.394.252-3.837 2.507-7.532 5.953-11.141z" />
            <path d="M86.094 255.754c6.106-4.979 13.745-9.63 21.757-14.207.413.153.829.306 1.249.452-6.22 3.641-12.166 7.343-17.298 11.212-6.718 5.072-11.96 10.313-13.158 16.003q-1.504 7.163 6.938 13.646c6.177 4.74 15.449 8.61 25.99 12.09 3.232 1.068 6.706 2.05 10.07 3.07h-3.36c-2.962-.907-5.95-1.807-8.89-2.724-8.361-2.618-15.73-5.555-21.694-8.94-10.195-5.794-14.104-12.092-11.38-18.916 1.611-4.086 5.178-7.959 9.776-11.686z" />
            <path d="M103.09 239.545q.58.27 1.173.533c-5.06 2.927-10.089 5.857-14.883 8.837-7.219 4.488-13.193 9.2-16.867 14.257-3.79 5.22-4.41 10.449-.067 15.673 4.146 4.978 11.757 9.246 21.764 12.884 6.092 2.222 12.87 4.2 19.339 6.287h-3.717c-1.333-.423-2.624-.871-4.015-1.27-8.82-2.538-16.778-5.387-23.492-8.673-13.596-6.66-18.574-14.043-14.118-22.23 2.702-4.96 8.054-9.552 14.48-13.997 6.146-4.246 13.166-8.3 20.403-12.301z" />
            <path d="m97.513 238.144 1.37-.747c.305.174.615.355.924.508q-5.249 2.956-10.444 5.921c-8.087 4.624-15.434 9.41-20.943 14.535-4.303 4.008-7.212 8.133-7.468 12.447-.281 5.387 3.873 10.366 11.81 14.918 7.056 4.054 16.177 7.425 26.316 10.459 2.024.604 4.022 1.22 6.046 1.831h-3.375c-.84-.263-1.654-.537-2.521-.786-11.836-3.42-22.522-7.226-30.36-11.988-9.328-5.668-12.447-11.77-9.556-18.279 1.974-4.46 6.302-8.638 11.708-12.678 7.553-5.658 16.938-10.928 26.493-16.141z" />
            <path d="M95.006 234.99q.452.305.918.597c-7.991 4.552-15.94 9.114-23.546 13.748-7.97 4.858-14.143 10.029-17.425 15.594-3.756 6.348-2.212 12.482 5.95 18.318 7.172 5.132 17.752 9.222 29.8 12.86l6.313 1.91h-3.379c-.405-.136-.786-.285-1.22-.41-11.984-3.417-22.816-7.222-30.853-11.988-8.863-5.249-12.504-10.942-10.694-17.07 1.387-4.683 5.576-9.072 11.003-13.314 6.796-5.317 15.292-10.278 24.061-15.178q4.52-2.543 9.072-5.068z" />
            <path d="M91.34 232.55c.249.17.498.355.754.508l-.868.491c-10.313 5.829-20.868 11.6-29.634 17.781-6.935 4.883-12.7 9.918-15.025 15.402-2.365 5.562-.605 10.918 6.078 16.024 6.5 4.98 16.262 8.998 27.535 12.586 2.867.91 5.811 1.778 8.72 2.685h-3.375c-.412-.135-.807-.277-1.24-.405-11.228-3.262-21.399-6.882-29.162-11.324q-15.533-8.89-11.024-19.303c1.917-4.456 6.149-8.645 11.437-12.703 7.603-5.835 17.116-11.266 26.753-16.671 3.002-1.7 6.035-3.39 9.05-5.071z" />
            <path d="M88.267 230.28c-4.886 2.92-9.826 5.83-14.73 8.746-8.214 4.886-16.39 9.776-22.923 15-6.046 4.822-10.697 9.783-11.782 15.124-1.084 5.342 1.825 10.363 8.702 15.09 6.078 4.174 14.431 7.688 23.99 10.846 3.03 1 6.178 1.956 9.275 2.93h-3.378c-.356-.12-.733-.252-1.12-.355-9.111-2.728-17.493-5.712-24.432-9.204-13.353-6.718-18.304-14.15-14.225-22.333 1.939-3.912 5.466-7.617 9.926-11.227 6.657-5.38 15.007-10.423 23.514-15.434 5.501-3.24 11.049-6.469 16.525-9.712z" />
            <path d="M84.853 227.272c-4.68 2.973-9.566 5.9-14.41 8.841-8.67 5.26-17.361 10.516-24.502 16.085-6.401 4.979-11.543 10.103-13.24 15.615-1.454 4.72-.046 9.303 4.716 13.734 5.146 4.79 13.321 8.841 23.265 12.447 3.858 1.398 7.994 2.692 12.005 4.033h-3.374c-.377-.125-.744-.249-1.124-.356-11.11-3.481-20.982-7.34-28.183-12.038-7.945-5.188-11.156-10.746-9.77-16.653 1.018-4.332 4.297-8.464 8.72-12.486 6.302-5.73 14.652-11.078 23.262-16.359 7.312-4.506 14.74-8.983 21.945-13.514.232.196.466.424.69.651z" />
            <path d="M79.608 223.638q.658-.448 1.298-.9c.181.245.356.49.548.733-3.201 2.29-6.58 4.548-10 6.803-8.94 5.882-18.066 11.735-26.672 17.681-7.404 5.132-13.318 10.495-16.515 16.206-2.707 4.833-2.941 9.659.43 14.47 3.556 5.054 10.388 9.485 19.737 13.386 5.046 2.105 10.743 4.004 16.145 6h-3.375c-6.895-2.27-13.332-4.695-18.926-7.405-12.091-5.864-18.68-12.404-18.137-19.79.356-4.854 3.557-9.499 8.18-14.026 5.76-5.65 13.467-10.96 21.494-16.213 8.606-5.64 17.436-11.248 25.793-16.945z" />
            <path d="M77.844 217.727c.114.231.238.46.356.687a157.079 157.079 0 0 1-12.586 9.794c-8.535 6.102-17.4 12.14-25.722 18.282-8.322 6.142-15.214 12.56-18.002 19.435-1.422 3.464-1.554 6.927-.05 10.391 2.191 5.036 7.657 9.602 15.591 13.766 5.523 2.91 12.049 5.498 19.058 7.934h-3.713c-.355-.17-.711-.355-1.099-.512-4.008-1.629-8.264-3.2-11.998-4.9-13.187-6.046-20.708-12.874-20.872-20.666-.103-4.896 2.596-9.623 6.757-14.253 4.84-5.377 11.422-10.48 18.47-15.509 8.938-6.372 18.18-12.692 26.84-19.114 2.443-1.814 4.737-3.649 6.824-5.509zM75.43 181.479c-.591-.356-1.192-.69-1.814-1.032-.46-.249-.9-.505-1.352-.757a1.508 1.508 0 0 1 .733.114c.896.458 1.757.92 2.589 1.383z" />
            <path d="M73.996 211.692c-.256.356-.52.676-.793 1.01-5.028 6.238-12.294 12.145-19.872 18.01-8.457 6.543-17.426 12.997-24.712 19.736-6.174 5.726-11.057 11.569-12.408 17.746-1.422 6.529 1.625 12.742 9.716 18.61 5.775 4.189 13.513 7.859 22.457 11.212h-3.716c-.242-.131-.462-.266-.733-.39-4.125-1.89-8.535-3.703-12.33-5.69-9.875-5.14-15.75-10.85-17.254-17.12-1.092-4.567.302-9.062 3.147-13.514 3.631-5.672 9.335-11.089 15.95-16.359 7.614-6.081 15.615-12.091 23.244-18.176 6.682-5.334 12.76-10.729 16.714-16.394.178.448.377.882.59 1.32zM73.253 188.104c-2.77-3.158-6.757-6.142-11.87-8.965a12.202 12.202 0 0 1-.893-.552l.44-.135c.061.075.065.164.182.224 5.181 2.717 9.32 5.566 12.319 8.535-.06.306-.121.598-.178.893z" />
            <path d="M39.945 298.016c-1.966-.935-3.95-1.867-5.892-2.81-9.77-4.74-16.832-9.957-20.27-15.757a15.829 15.829 0 0 1-1.683-12.802c1.881-6.757 7.152-13.219 13.933-19.51 6.707-6.216 13.894-12.365 20.836-18.549 6.401-5.69 12.305-11.447 16.195-17.45 2.6-4.015 4.2-8.077 4.154-12.205-.089-7.824-6.263-14.972-18.115-21.484a1.778 1.778 0 0 0-.712-.21 4.662 4.662 0 0 0 .491.356c7.212 4.154 12.244 8.635 14.876 13.454a15.647 15.647 0 0 1 1.636 10.87c-1.597 8.148-8.379 15.819-16.558 23.34-7.415 6.821-15.292 13.575-22.76 20.385-5.836 5.316-10.697 10.754-13.684 16.387-3.912 7.361-3.955 14.67 2.535 21.874 4.52 5.014 11.71 9.495 20.737 13.603.355.156.604.334.9.501z" />
            <path d="M31.84 298.016c-.746-.426-1.461-.86-2.25-1.276-10.182-5.374-17.18-11.235-20.25-17.682a18.176 18.176 0 0 1-1.29-12.35c1.87-7.917 7.823-15.488 14.843-22.96 5.825-6.202 11.953-12.365 17.781-18.567 5.367-5.69 9.762-11.508 12.27-17.482 4.363-10.399 1.283-20.374-12.75-29.727a12.137 12.137 0 0 0-2.262-1.234 3.489 3.489 0 0 0 .43.38q12.092 8.045 14.34 17.177c1.233 5 .273 9.958-2.116 14.904-3.98 8.219-11.38 16.075-18.97 23.916-6.141 6.359-12.478 12.692-17.425 19.204-4.566 6.003-7.866 12.09-8.261 18.336q-.928 14.644 21.632 26.792c.356.185.605.388.904.58z" />
            <path d="M23.74 298.016c-1.779-1.248-3.631-2.49-5.335-3.748-7.614-5.658-12.109-11.632-13.638-17.91-1.305-5.37-.459-10.7 1.664-16.024 2.845-7.172 8.062-14.146 13.347-21.116 5.04-6.65 10.124-13.297 14.833-19.976 3.684-5.234 5.974-10.565 6.91-15.964 1.607-9.278-2.134-18.218-13.12-26.671a2.532 2.532 0 0 0-.96-.463c1.156 1.067 2.365 2.099 3.468 3.158 5.44 5.246 8.3 10.669 9.097 16.27 1.003 7.016-1.465 13.908-5.306 20.758-4.21 7.507-10.171 14.85-15.804 22.226-4.808 6.298-9.417 12.61-12.557 19.05-2.845 5.858-4.353 11.758-3.46 17.707 1.177 7.867 6.721 15.292 16.949 22.266a5.69 5.69 0 0 1 .533.444z" />
            <path d="M15.635 298.016c-2.116-2.133-4.442-4.235-6.305-6.401-4.29-4.979-6.65-10.078-7.61-15.292-1.1-5.964-.218-11.895 1.692-17.82 2.201-6.824 5.946-13.553 9.602-20.27 3.723-6.85 7.607-13.692 10.907-20.573 3.2-6.675 4.89-13.418 4.474-20.21-.452-7.447-3.674-14.72-10.697-21.743a1.561 1.561 0 0 0-.733-.42c.871 1.067 1.796 2.113 2.607 3.18 3.556 4.662 5.636 9.413 6.59 14.224a31.611 31.611 0 0 1-.883 15.872 113.877 113.877 0 0 1-6.234 15.647c-3.851 7.902-8.535 15.75-12.34 23.656-2.382 4.947-3.99 9.958-5.597 14.936-1.487 4.624-1.647 9.268-.932 13.912 1.11 7.227 4.836 14.264 11.903 21.046a1.778 1.778 0 0 1 .177.235z" />
            <path d="M-.909 263.393c.192-.854.388-1.707.573-2.557.836-3.834 1.515-7.67 2.546-11.494 1.124-4.171 2.571-8.329 3.884-12.493 1.5-4.769 3.161-9.538 4.466-14.314 1.273-4.662 2.386-9.328 3.2-14 .911-5.146 1.224-10.314.235-15.456a93.884 93.884 0 0 0-3.157-11.714c-.747-2.262-2.156-4.492-3.265-6.74l-.441.03a23.471 23.471 0 0 1 1.16 2.57c1.244 3.959 2.688 7.91 3.556 11.878 1.255 5.719 1.095 11.451.309 17.177-.605 4.41-1.53 8.816-2.56 13.215-.99 4.217-2.255 8.424-3.479 12.635-1.223 4.21-2.638 8.489-3.84 12.738-.95 3.354-1.704 6.718-2.56 10.075-.175.69-.428 1.376-.644 2.063zM7.534 298.016c-1.615-2.88-3.588-5.74-4.748-8.645-1.468-3.677-2.272-7.39-3.371-11.085-.089-.302-.213-.604-.324-.903v8.595c.093.096.231.189.27.288q2.405 5.872 4.794 11.736zM-.909 223.339a12.35 12.35 0 0 0 .274-1.707c.217-9.019.494-18.037.58-27.056.032-3.495-.356-6.995-.594-10.49a2.432 2.432 0 0 0-.26-.662z" />
          </g>
          <path
            d="M2419.34.17a140.61 140.61 0 0 1-52.91 7.89A186 186 0 0 1 2312.26 0c3.69 95 65.38 175.06 150.66 205.84q-3.14-8.21-5.56-16.83-3.32-24.6-6.61-49.2a139.33 139.33 0 0 1 15.94-71.29c.32-1 .68-2 1-3A160.29 160.29 0 0 1 2419.34.17Z"
            style={{
              fill: palette.backgroundLight,
              filter: "url(#dropshadow)",
            }}
            transform="matrix(.35562 0 0 .35562 -657.687 -7.236)"
          />
          <g
            clipPath="url(#page4-g)"
            transform="matrix(.35562 0 0 .35562 -657.687 -7.236)"
          >
            <path
              d="M2212.79 406a11.9 11.9 0 0 1 11.49-12.31l84.28-2.95 15.7 11.37a11.92 11.92 0 0 1-11.49 12.32l-87.66 3.06a11.91 11.91 0 0 1-12.32-11.49Z"
              style={{
                fill: "#daa081",
              }}
            />
            <path
              d="M2184 392.11a11.92 11.92 0 0 1 12.12-11.7l87.7 1.53a11.92 11.92 0 0 1 11.7 12.12 11.92 11.92 0 0 1-12.12 11.7l-87.7-1.53a11.92 11.92 0 0 1-11.7-12.12Z"
              style={{
                fill: "#daa081",
              }}
            />
            <path
              d="M2170.09 376.14a11.9 11.9 0 0 1 12.51-11.27l87.6 4.59a11.92 11.92 0 0 1 11.27 12.54 11.92 11.92 0 0 1-12.47 11.25l-87.6-4.59a11.91 11.91 0 0 1-11.26-12.52z"
              style={{
                fill: "#daa081",
              }}
            />
            <path
              d="M2271.1 396a11.92 11.92 0 0 0 12.52-11.27 12.25 12.25 0 0 0-.18-2.79h-2v.06a11.92 11.92 0 0 1-12.52 11.27l-84.39-4.43z"
              style={{
                fill: "#page4-b67e62",
              }}
            />
            <path
              d="M2295.55 394.11a11.91 11.91 0 0 1-12.12 11.69l-70.61-1.23 73.35 3.42a11.91 11.91 0 0 0 11.07-16.8 12 12 0 0 0-4.88-5.4 11.83 11.83 0 0 1 2.89 5.47 12.2 12.2 0 0 1 .3 2.85z"
              style={{
                fill: "#page4-b67e62",
              }}
            />
            <path
              d="M2099.22 454.73a9.21 9.21 0 0 1 4.39 1.12c7.92 4.14 16 .42 16-5.4v-32.56a19.05 19.05 0 0 0-19.05-19.06h-107.44a19.05 19.05 0 0 0-19 19.06v107.47a19.06 19.06 0 0 0 19 19.06h107.48a19.06 19.06 0 0 0 19.05-19.06v-32.57c0-5.81-8.12-9.53-16-5.39a9.33 9.33 0 0 1-4.39 1.12c-6.77 0-12.25-7.56-12.25-16.9s5.44-16.89 12.21-16.89z"
              style={{
                fill: palette.secondary,
              }}
            />
            <path
              d="M2295.1 398.83h-107.48a19.05 19.05 0 0 0-19 19.06v32.56c0 5.82-8.12 9.54-16 5.4a9.24 9.24 0 0 0-4.39-1.12c-6.77 0-12.26 7.56-12.26 16.89 0 9.33 5.49 16.9 12.26 16.9a9.36 9.36 0 0 0 4.39-1.12c7.92-4.14 16-.42 16 5.39v32.57a19.06 19.06 0 0 0 19 19.06h107.48a19.06 19.06 0 0 0 19.05-19.06V417.89a19.05 19.05 0 0 0-19.05-19.06z"
              style={{
                fill: palette.primary,
              }}
            />
            <path
              d="M2169.83 364.16a12.37 12.37 0 0 0 11.26 13.42l70.44 6.17 2.94 1.37c-.15.13-.29.27-.43.41a15.62 15.62 0 0 0 0 22.09l40.18 40.19a24.17 24.17 0 0 1 6.48 11.66c.13.84.28 1.68.44 2.52a77.51 77.51 0 0 0 60.28 61c39.87 8.44 137.21 15.08 184 27.24l51.69-60.41s-15.2 8.45-37.26 19.59l-15.72 16.15c6 20.66-61.95-89.83-76.64-72.08-28.94 35-64.95 16.15-89.8-5.39-13-11.88-26.9-32.16-33.69-44.87-1.6-3-3.18-6.08-5.73-8.63a26 26 0 0 0-10.25-6.32l-67.68-27.73q-.41-.18-.81-.33a12 12 0 0 0-3.71-.92l-72.54-6.35a12.39 12.39 0 0 0-13.45 11.22z"
              style={{
                fill: "#e5ac88",
              }}
            />
            <path
              d="m2181.08 377.57 70.43 6.17 2.95 1.37 11.11-2.8-87.35-7.81a9.38 9.38 0 0 1-8.17-6.65 12.38 12.38 0 0 0 11.03 9.72z"
              style={{
                fill: "#f8d3bc",
              }}
            />
            <path
              d="M2254 385.53c7.08 7.09 22.11-1.21 22.11-1.21l61.68 61.61s-47.6-60.76-58.49-71.65c-12.53-12.53-20.48-11-25.78-5.69-4.04 4.08-5.05 11.41.48 16.94z"
              style={{
                fill: "#c88d6d",
              }}
            />
            <path
              d="m2254.45 407.73 60.79 60.8a16 16 0 1 0 22.59-22.6L2277 385.14a16 16 0 0 0-22.59 22.59z"
              style={{
                fill: "#e5ac88",
              }}
            />
            <path
              d="m2254 407.63 40.19 40.18a24.2 24.2 0 0 1 6.48 11.67c.13.84.28 1.67.44 2.51a77.47 77.47 0 0 0 60.28 61c39.87 8.45 137.21 15.08 184 27.24l19.18-48.57c-38.92-7.73-38 16.21-81.27 15.39-190.34-3.63-149.09-55.8-192-85.31-20.48-14.07-31.76-30.49-41.56-37.9a15.59 15.59 0 0 0 4.26 13.79z"
              style={{
                fill: "#eeb799",
              }}
            />
            <path
              d="m2254 407.63 40.19 40.18a24.2 24.2 0 0 1 6.48 11.67c.13.84.28 1.67.44 2.51a77.47 77.47 0 0 0 60.28 61c39.87 8.45 137.21 15.08 184 27.24l4.16-10.54c-20-7-64.67-19-137.41-19.39-103-.49-103.35-58-111.72-70.12-6.95-10-37.95-36.7-49.11-46.16a15.72 15.72 0 0 0 2.69 3.61z"
              style={{
                fill: "#f8d3bc",
              }}
            />
            <path
              d="M2257.11 406.86c-.21-.21-.41-.43-.6-.65-.19-.22-.44-.39-.65-.6zM2274.48 387c.21.21.41.43.6.65.19.22.44.39.65.6z"
              style={{
                fill: "#fce2d4",
              }}
            />
            <path
              d="M2257.11 388.24a13.14 13.14 0 0 0-.6 18c5.17 4.54 10.79 2.09 15.73-2.84 4.94-4.93 7.37-10.56 2.84-15.73a13.15 13.15 0 0 0-17.97.57z"
              style={{
                fill: "#fce2d4",
              }}
            />
            <path
              d="M2327 404.86c17.53 20.27 26.6 98.41 116.44 79.31 40-8.51 87.84 76 131.64 61.61l22.09-55.94s-15.2 8.46-37.26 19.6l-15.73 16.14c6 20.67-61.95-89.83-76.64-72.08-28.94 35-64.95 16.16-89.79-5.38-13-11.88-26.91-32.17-33.69-44.88-1.6-3-3.19-6.08-5.73-8.62a26.08 26.08 0 0 0-10.25-6.33l-47.4-17.56c17.43 10.57 37.39 23.85 46.32 34.13z"
              style={{
                fill: "#daa081",
              }}
            />
            <path
              d="M2251.9 360.52c5.77.61 3.34-1.79 21.61 8.45 18.67 10.47 45.58 17.66 63.65 43.95 18.07 26.29 34.53 75.51 109.82 66.82 0 0 67.27 89.87 125.48 72.58l24.69-62.52s-15.2 8.45-37.26 19.59l-15.73 16.14c6 20.67-76.64-72.08-76.64-72.08-28.94 35-64.95 16.16-89.79-5.38-13-11.88-26.91-32.16-33.69-44.87-1.6-3-3.19-6.08-5.73-8.63a26.06 26.06 0 0 0-10.25-6.32l-67.69-27.72-.8-.34a12.3 12.3 0 0 0-3.72-.91l-72.53-6.35a12.29 12.29 0 0 0-5.39.72c17.07 1.51 57.53 5.13 73.97 6.87z"
              style={{
                fill: "#c88d6d",
              }}
            />
            <path
              d="M2173.76 356.14c3 .25 13.76-.11 18.94-2.42 0 0-6.63-.82-8.85-1-12.79-.66-8.12 1.12-10.09 3.42z"
              style={{
                fill: "#e9c9ba",
              }}
            />
            <path
              d="M2400.21 444.61h90.28v113.07h-90.28z"
              style={{
                fill: "url(#h)",
              }}
              transform="rotate(-77.71 2445.407 501.112)"
            />
            <path
              d="M2412.53 462.66a5.9 5.9 0 1 0-8.34 0 5.9 5.9 0 0 0 8.34 0z"
              style={{
                fill: palette.primary,
              }}
            />
            <path
              d="M2406.7 457.65a1.38 1.38 0 1 0-1-1.69 1.38 1.38 0 0 0 1 1.69zM2405.79 461.05a1.38 1.38 0 1 0-1-1.69 1.38 1.38 0 0 0 1 1.69zM2410.21 458.6a1.38 1.38 0 1 0-1-1.69 1.38 1.38 0 0 0 1 1.69zM2409.3 462a1.38 1.38 0 1 0-1-1.69 1.39 1.39 0 0 0 1 1.69z"
              style={{
                fill: "#2b2b2b",
              }}
            />
            <path
              d="M2511.61 358.56h107.79v336.2h-107.79z"
              style={{
                fill: "url(#page4-c)",
              }}
              transform="rotate(-77.71 2565.55 526.647)"
            />
            <g mask="url(#j)">
              <path
                d="m2410.73 447.4-19.21 88.21-2.05-.44 18.95-88.27z"
                style={{
                  fill: "url(#page4-k)",
                }}
              />
            </g>
            <path
              d="M2118.35 579.39a12.4 12.4 0 0 0-11.27-13.39l-70.43-6.16-3-1.37.44-.41a15.63 15.63 0 0 0 0-22.1L1994 495.74a24.15 24.15 0 0 1-6.48-11.67c-.13-.83-.28-1.67-.44-2.51a77.47 77.47 0 0 0-60.28-61c-39.87-8.45-137.21-15.08-184-27.24l-51.7 60.41s15.2-8.46 37.26-19.59l15.7-16.14c-6-20.67 62 89.83 76.64 72.08 28.93-35 65-16.16 89.79 5.38 13 11.88 26.91 32.17 33.69 44.88 1.6 3 3.19 6.08 5.73 8.63a26 26 0 0 0 10.25 6.32l67.69 27.73c.26.12.53.23.8.33a12 12 0 0 0 3.71.92l72.54 6.35a12.4 12.4 0 0 0 13.45-11.23z"
              style={{
                fill: "#e5ac88",
              }}
            />
            <path
              d="m2107.09 566-70.43-6.16-3-1.37-11.1 2.8L2110 569a9.41 9.41 0 0 1 8.18 6.66 12.41 12.41 0 0 0-11.09-9.66z"
              style={{
                fill: "#f8d3bc",
              }}
            />
            <path
              d="M2034.14 558c-7.09-7.09-22.12 1.21-22.12 1.21l-61.68-61.61s47.6 60.75 58.49 71.64c12.53 12.53 20.48 11 25.79 5.7 4.07-4.06 5.09-11.36-.48-16.94z"
              style={{
                fill: "#c88d6d",
              }}
            />
            <path
              d="m2033.73 535.82-60.8-60.8a16 16 0 0 0-22.59 22.6l60.79 60.79a16 16 0 0 0 22.6-22.59z"
              style={{
                fill: "#e5ac88",
              }}
            />
            <path
              d="M2034.14 535.92 1994 495.73a24.11 24.11 0 0 1-6.47-11.66c-.14-.84-.28-1.68-.45-2.51a77.47 77.47 0 0 0-60.28-61c-39.87-8.45-137.2-15.09-184-27.24l-19.18 48.57c38.92 7.73 38-16.21 81.27-15.39 190.34 3.63 149.09 55.8 192 85.31 20.48 14.07 31.76 30.49 41.56 37.89a15.58 15.58 0 0 0-4.31-13.78z"
              style={{
                fill: "#eeb799",
              }}
            />
            <path
              d="M2034.14 535.92 1994 495.73a24.11 24.11 0 0 1-6.47-11.66c-.14-.84-.28-1.68-.45-2.51a77.47 77.47 0 0 0-60.28-61c-39.87-8.45-137.2-15.09-184-27.24l-4.16 10.54c20 7 64.68 19 137.41 19.39 103 .49 103.35 58 111.72 70.12 7 10 38 36.69 49.11 46.16a15.44 15.44 0 0 0-2.74-3.61z"
              style={{
                fill: "#f8d3bc",
              }}
            />
            <path
              d="M2031.07 536.69c.21.21.4.42.6.64.2.22.43.39.64.6zM2013.69 556.56c-.21-.21-.41-.43-.6-.65-.19-.22-.44-.39-.64-.6z"
              style={{
                fill: "#fce2d4",
              }}
            />
            <path
              d="M2031.07 555.31a13.17 13.17 0 0 0 .6-18c-5.18-4.53-10.8-2.08-15.73 2.85s-7.38 10.56-2.85 15.73a13.18 13.18 0 0 0 17.98-.58z"
              style={{
                fill: "#fce2d4",
              }}
            />
            <path
              d="M1961.21 538.69c-17.52-20.27-26.6-98.41-116.44-79.31-40 8.51-87.83-76-131.64-61.61L1691 453.71s15.2-8.46 37.26-19.6L1744 418c-6-20.67 62 89.83 76.64 72.08 28.94-35 64.95-16.16 89.79 5.38 13 11.88 26.91 32.16 33.7 44.88 1.59 3 3.18 6.08 5.73 8.62a26.13 26.13 0 0 0 10.25 6.33l47.4 17.55c-17.45-10.6-37.4-23.84-46.3-34.15z"
              style={{
                fill: "#daa081",
              }}
            />
            <path
              d="M2036.28 583c-5.77-.61-3.35 1.79-21.62-8.45-18.67-10.47-45.58-17.66-63.65-44s-34.52-75.51-109.82-66.83c0 0-67.26-89.86-125.48-72.57l-24.71 62.6s15.2-8.45 37.26-19.59L1744 418c-6-20.67 76.64 72.07 76.64 72.07 28.94-34.95 64.95-16.15 89.79 5.39 13 11.88 26.91 32.16 33.7 44.87 1.59 3 3.18 6.08 5.73 8.63a26 26 0 0 0 10.25 6.32l67.71 27.72.8.34a12.37 12.37 0 0 0 3.72.91l72.54 6.35a12.44 12.44 0 0 0 5.39-.73c-17.13-1.48-57.55-5.1-73.99-6.87z"
              style={{
                fill: "#c88d6d",
              }}
            />
            <path
              d="M2114.42 587.41c-3-.25-13.76.11-18.94 2.42 0 0 6.62.82 8.84.95 12.79.71 8.12-1.07 10.1-3.37z"
              style={{
                fill: "#e9c9ba",
              }}
            />
            <path
              d="M1797.69 385.87h90.28v113.07h-90.28z"
              style={{
                fill: "url(#l)",
              }}
              transform="rotate(-77.71 1842.873 442.387)"
            />
            <path
              d="M1875.64 480.89a5.9 5.9 0 1 0 8.35 0 5.9 5.9 0 0 0-8.35 0z"
              style={{
                fill: palette.secondary,
              }}
            />
            <path
              d="M1881.48 485.89a1.383 1.383 0 1 0-.72 2.67 1.383 1.383 0 0 0 .72-2.67zM1882.38 482.5a1.38 1.38 0 1 0 1 1.69 1.38 1.38 0 0 0-1-1.69zM1878 485a1.38 1.38 0 1 0 1 1.69 1.38 1.38 0 0 0-1-1.69zM1878.87 481.56a1.37 1.37 0 0 0-1.69 1 1.38 1.38 0 1 0 1.69-1z"
              style={{
                fill: "#2b2b2b",
              }}
            />
            <path
              d="M1668.76 248.78h107.79v336.2h-107.79z"
              style={{
                fill: "url(#m)",
              }}
              transform="rotate(-77.71 1722.68 416.875)"
            />
            <g mask="url(#n)">
              <path
                d="m1877.44 496.14 19.21-88.2 2.05.44-18.95 88.27z"
                style={{
                  fill: "url(#o)",
                }}
              />
            </g>
          </g>
          <path
            d="M2073.92 481.62c-.12.44-.23.89-.37 1.32a7.08 7.08 0 0 1-6.71 4.77h-8a.87.87 0 0 0-.89.53q-2.71 4.78-5.45 9.55l-.33.56-3.09-1.78c.68-1.19 1.34-2.33 2-3.48q2.43-4.24 4.85-8.49a.71.71 0 0 1 .73-.43h9.79a3.6 3.6 0 0 0 3.91-3.88V459.7a3.58 3.58 0 0 0-3.86-3.86h-34.76a3.59 3.59 0 0 0-3.89 3.89v20.53a3.6 3.6 0 0 0 3.94 3.91h15.51v3.54h-15.9a7 7 0 0 1-7.09-7.09v-21.19a7 7 0 0 1 5.84-7 1.86 1.86 0 0 0 .25-.1h37.41a1.86 1.86 0 0 0 .25.1 7 7 0 0 1 5.47 4.67c.16.43.25.87.37 1.31z"
            style={{
              fill: palette.backgroundDark,
            }}
            transform="matrix(.35562 0 0 .35562 -657.687 -7.236)"
          />
          <path
            d="M2049.32 478.6c-2-1.87-4-3.73-6-5.61-.64-.62-1.25-1.26-1.84-1.91a4.94 4.94 0 0 1-.76-5.47 4.78 4.78 0 0 1 4.75-2.68 4.35 4.35 0 0 1 2.64 1.15c.32.3.58.66 1 1.11.27-.33.42-.56.61-.75a4.72 4.72 0 0 1 6.66-.29 4.89 4.89 0 0 1 .32 7c-2.25 2.39-4.58 4.71-6.87 7.06l-.65.67z"
            style={{
              fill: palette.primary,
            }}
            transform="matrix(.35562 0 0 .35562 -657.687 -7.236)"
          />
          <path
            d="M2238.56 494.48c-.93-.19-1.88-.36-2.81-.6a35.46 35.46 0 0 1-7-2.78 27.14 27.14 0 0 0-9-2.78V474.71a.67.67 0 0 1 .49-.74 31.35 31.35 0 0 1 6.46-2.06 10.93 10.93 0 0 1 7.93 1.47l3.11 1.9a19.3 19.3 0 0 0 8.25 2.77c1.51.17 3 .26 4.55.31a3.05 3.05 0 0 1 3.16 3.08 3.1 3.1 0 0 1-3 3.35 26.45 26.45 0 0 1-4.07-.22c-2.35-.32-4.68-.75-7-1.16a15.19 15.19 0 0 1-1.59-.47 1.59 1.59 0 0 0-2 1.08 1.56 1.56 0 0 0 1.11 2 46.94 46.94 0 0 0 13.75 1.98l1.25-.06v.14a21.4 21.4 0 0 1-7.68 5.53 28 28 0 0 1-2.9.73l-.29.1zM2206.76 476.33a1.92 1.92 0 0 1 2-1.19h7.7v14.3a1.62 1.62 0 0 1-1.82 1.81h-5.91a1.93 1.93 0 0 1-2-1.2z"
            style={{
              fill: palette.backgroundDark,
            }}
            transform="matrix(.35562 0 0 .35562 -657.687 -7.236)"
          />
          <path
            d="M2238.11 455.83h8.42l-4.15 15.91h-.12zM2249.83 455.84h7.71l-10.7 11.89-.09-.05zM2237.81 467.72l-10.68-11.86h7.69l3.08 11.82zM2246.38 452.57h-8.15c.05-.18.09-.34.15-.5.68-1.82 1.38-3.64 2-5.46.12-.33.25-.49.64-.47h2.63a.57.57 0 0 1 .43.26c.77 2 1.51 4 2.26 6 .06.06.04.1.04.17zM2237.19 446.15l-2.41 6.41h-7.54a.69.69 0 0 1 .09-.16c1.92-1.92 3.83-3.86 5.78-5.75a2.06 2.06 0 0 1 1.2-.48c.92-.07 1.86-.02 2.88-.02zM2247.46 446.15h3a1.84 1.84 0 0 1 1 .47c2 1.91 3.89 3.86 5.82 5.79 0 0 0 .06.05.13h-7.54z"
            style={{
              fill: palette.secondary,
            }}
            transform="matrix(.35562 0 0 .35562 -657.687 -7.236)"
          />
          <path
            d="M2253.88 476.06a6.14 6.14 0 0 1 7-1.56 1.56 1.56 0 0 1 .66 2.31c-1.49 2-3 3.87-4.64 5.79a6.58 6.58 0 0 0-3.02-6.54z"
            style={{
              fill: palette.backgroundDark,
            }}
            transform="matrix(.35562 0 0 .35562 -657.687 -7.236)"
          />
          <path
            d="M2079.09 584.28h361v182.43h-325a36.07 36.07 0 0 1-36.07-36.07V584.28Z"
            style={{
              fill: palette.backgroundLight,
              filter: "url(#dropshadow)",
            }}
            transform="matrix(.35562 0 0 .35562 -657.687 -7.236)"
          />
          <path
            d="M1882.91 126.31h349.61v186.1h-385.68v-150a36.07 36.07 0 0 1 36.07-36.1z"
            style={{
              fill: palette.backgroundLight,
              filter: "url(#dropshadow)",
            }}
            transform="matrix(-.35562 0 0 -.35562 796.73 148.783)"
          />
          <text
            x={18.021}
            y={50.087}
            style={{
              letterSpacing: "0.02em",
              fontSize: "7px",
              fontFamily: "Heavitas",
              fill: palette.primary,
              strokeWidth: 0.35243,
            }}
          >
            {"PERSONALIDADE"}
          </text>
          <text
            x={158.762}
            y={213.057}
            style={{
              letterSpacing: "0.02em",
              fontSize: "7px",
              fontFamily: "Heavitas",
              fill: palette.secondary,
              strokeWidth: 0.35243,
            }}
          >
            {"VALORES"}
          </text>
          <foreignObject
            x={18.005}
            y={56.032}
            width={105}
            height={42}
            className="clickable"
          >
            <div
              style={{
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: palette.backgroundGray,
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                height: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.personality,
                  field: "personality",
                  label: "Personalidade",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.personality}
            </div>
          </foreignObject>
          <foreignObject
            x={90.005}
            y={216.032}
            width={105}
            height={43}
            className="clickable"
          >
            <div
              style={{
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: palette.backgroundGray,
                textAlign: "end",
                strokeWidth: 0.352449,
                width: "100%",
                height: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.moral_values,
                  field: "moral_values",
                  label: "Valores",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.moral_values}
            </div>
          </foreignObject>
        </svg>
      </PaperInside>
    </Paper>
  );
};

export default Page4;
