import { throttle } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShopPages, Status } from "../../../app/constants";
import { AppDispatch } from "../../../app/store";
import { fromCurrencyToStr } from "../../../app/utils";
import ShopActionButton from "../../../components/buttons/shop-action-button/shop-action-button";
import { makeContainer } from "../../../components/container/container";
import ShopInputText from "../../../components/inputs/shop-input-text/shop-input-text";
import Loading from "../../../components/loading/loading";
import ShopHeading, {
  Variant,
} from "../../../components/text/shop-heading/shop-heading";
import ShopService from "../../../features/shop/service";
import {
  getCart,
  removeCartItem,
  selectCart,
  selectCartPrice,
  selectDiscountCode,
  selectEditCartStatus,
  selectGetCartStatus,
  setCreateOrderStatus,
  setCurrentPage,
  setDiscountCode,
  setGetCartStatus,
} from "../../../features/shop/slice";
import { CartItem, CartPrice } from "../../../features/shop/type";
import ShopCartItem from "../components/shop-cart-item/shop-cart-item";
import ShopPageContainer from "../components/shop-page-container/shop-page-container";
import ShopSpacer from "../components/shop-spacer/shop-spacer";
import "./cart.css";

const DiscountContainer = makeContainer("shop-cart-discount-container");
const DiscountButtonContainer = makeContainer(
  "shop-cart-discount-button-container"
);
const DiscountInputContainer = makeContainer(
  "shop-cart-discount-input-container"
);
const TotalContainer = makeContainer("shop-cart-total-container");
const TotalLine = makeContainer("shop-cart-total-line");
const TotalTitle = makeContainer("shop-total-title");
const SubtotalTitle = makeContainer("shop-subtotal-title");
const DiscountTitle = makeContainer("shop-discount-title");

const Cart: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const getCartStatus: Status = useSelector(selectGetCartStatus);
  const editCartStatus: Status = useSelector(selectEditCartStatus);

  const cartItems: CartItem[] = useSelector(selectCart);
  const price: CartPrice = useSelector(selectCartPrice);
  const selectedCode = useSelector(selectDiscountCode);
  const [code, setCode] = useState("");

  const isLoading =
    getCartStatus === Status.LOADING || editCartStatus === Status.LOADING;
  const hasItems = cartItems && cartItems?.length > 0;
  const showItems = !isLoading && hasItems;
  const showNoItems = !isLoading && !hasItems;

  const loadCart = useCallback(() => {
    dispatch(getCart({ code: selectedCode, service: new ShopService() }));
  }, [dispatch, selectedCode]);

  const loadCartWithCurrentCode = useCallback(() => {
    dispatch(getCart({ code: code, service: new ShopService() }));
  }, [dispatch, code]);

  // eslint-disable-next-line
  const remove = useCallback(
    throttle(
      (id: string) => {
        dispatch(
          removeCartItem({
            cartItemId: id,
            code: selectedCode,
            service: new ShopService(),
          })
        );
      },
      5000,
      { leading: true, trailing: false }
    ),
    // eslint-disable-next-line
    [dispatch, selectedCode]
  );

  // Alterando código de desconto
  useEffect(() => {
    if (price?.discount > 0.0) {
      dispatch(setDiscountCode(code));
    } else {
      dispatch(setDiscountCode(""));
      if (!price) {
        dispatch(getCart({ code: "", service: new ShopService() }));
      }
    }
    // eslint-disable-next-line
  }, [dispatch, price]);

  // Carregando carrinho ao iniciar
  useEffect(() => {
    loadCart();
    dispatch(setCreateOrderStatus(Status.LOADING));

    return () => {
      dispatch(setGetCartStatus(Status.LOADING));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <ShopPageContainer>
      <ShopHeading variant={Variant.H1}>Carrinho de Compras</ShopHeading>
      <ShopSpacer space={10} />
      <ShopHeading variant={Variant.H2}>Resumo do Pedido</ShopHeading>

      {isLoading && <Loading />}
      {showNoItems && (
        <>
          <ShopSpacer space={80} />
          <ShopHeading variant={Variant.H2}>Carrinho Vazio</ShopHeading>
        </>
      )}

      {showItems && (
        <>
          {cartItems?.map((item, index) => (
            <ShopCartItem
              key={`shop-cart-item-${index}`}
              item={item}
              removeChartItem={remove}
            />
          ))}

          <hr className="shop-cart-separator " />
          <DiscountContainer>
            <DiscountInputContainer>
              <ShopInputText
                inputKey=""
                label=""
                placeHolder="Código de Desconto"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </DiscountInputContainer>
            <DiscountButtonContainer>
              <ShopActionButton onClick={loadCartWithCurrentCode}>
                OK
              </ShopActionButton>
            </DiscountButtonContainer>
          </DiscountContainer>

          <hr className="shop-cart-separator " />
          <TotalContainer>
            <TotalLine>
              <SubtotalTitle>Subtotal</SubtotalTitle>
              <SubtotalTitle>{fromCurrencyToStr(price?.cost)}</SubtotalTitle>
            </TotalLine>
            <TotalLine>
              <DiscountTitle>{`Desconto${
                selectedCode ? " (" + selectedCode + ")" : ""
              }`}</DiscountTitle>
              <DiscountTitle>
                {fromCurrencyToStr(price?.discount)}
              </DiscountTitle>
            </TotalLine>
            <TotalLine>
              <TotalTitle>Total</TotalTitle>
              <TotalTitle>{fromCurrencyToStr(price?.final_cost)}</TotalTitle>
            </TotalLine>
          </TotalContainer>
        </>
      )}

      <ShopSpacer space={80} />
      <ShopActionButton
        disable={!hasItems || isLoading}
        onClick={() => dispatch(setCurrentPage(ShopPages.CHECKOUT))}
      >
        Fechar pedido e prosseguir para o pagamento
      </ShopActionButton>
      <ShopActionButton
        outline={true}
        onClick={() => dispatch(setCurrentPage(ShopPages.PRODUCT_SELECT))}
      >
        Continuar comprando
      </ShopActionButton>
    </ShopPageContainer>
  );
};

export default Cart;
