import * as React from "react";
import { useSelector } from "react-redux";
import { selectPalette } from "../../../../../features/notebook/slice";
import { ClippedImage, Paper, PaperInside } from "../commons/commons";
import { TemplatePageProps } from "../commons/interfaces";

const Page2: React.FC<TemplatePageProps> = ({
  className,
  notebook,
  triggerImageEdit,
  triggerFieldEdit,
  updateField,
}) => {
  const { palette } = useSelector(selectPalette);

  return (
    <Paper className={className}>
      <PaperInside>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
        >
          <defs>
            <filter id="page2-b" width="210mm">
              <feDropShadow
                dx="3"
                dy="3"
                stdDeviation="3"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
          </defs>

          <ClippedImage
            id="saphire-page-2"
            triggerImageEdit={triggerImageEdit}
            x={0}
            y={0}
            width={210}
            height={297}
            imageNumber={2}
            imageRatio={0.7}
            notebook={notebook}
          >
            <rect width="100%" height="100%" fill={palette.backgroundLight} />
          </ClippedImage>

          <path
            d="m 0.01861166,1.2261568 c 0,0 25.04084534,48.5598932 22.84427534,117.6866532 -2.19657,69.12677 -19.477379,47.97298 -18.5917242,106.48945 0.8856548,58.51649 11.5100052,71.64315 11.5100052,71.64315 H 0.03618526 Z"
            style={{
              fill: palette.primary,
              filter: "url(#page2-b)",
            }}
          />
        </svg>
      </PaperInside>
    </Paper>
  );
};

export default Page2;
