import { throttle } from "lodash";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Status } from "../../../../app/constants";
import { AppDispatch } from "../../../../app/store";
import { makeContainer } from "../../../../components/container/container";
import Loading from "../../../../components/loading/loading";
import { closeModal, setModalContent } from "../../../../features/modal/slice";
import {
  selectCreateTargetStatus,
  selectTargetRoot,
  setCurrentPage,
} from "../../../../features/target/slice";

import { goToTarget } from "../../../../app/target";
import TargetFormButton from "../../../../components/buttons/target-form-button/target-form-button";
import { setFilters } from "../../../../features/dashboard/slice";
import { TargetRootResponse } from "../../../../features/target/type";
import Check from "../../../../resources/images/check.png";
import Error from "../../../../resources/images/error.png";
import "./create-target-modal.css";

type ModalProps = {
  show: boolean;
  onClose: () => void;
};

const updateStatus = throttle(
  (nextStatus: Status, setStatus: (Status) => void) => {
    setStatus(nextStatus);
  },
  2500,
  { leading: false, trailing: true }
);

const CreateTargetModalContainer = makeContainer(
  "create-target-modal-container"
);
const CreateTargetButtonsContainer = makeContainer(
  "create-target-modal-buttons-container"
);
const CreateTargetTopContainer = makeContainer(
  "create-target-modal-top-container"
);
const CreateTargetTopText = makeContainer("create-target-modal-top-text");
const CreateTargetTitle = makeContainer("create-target-modal-title");
const ContentContainer = makeContainer("create-target-modal-content-container");

const CreateTargetModal: React.FC<ModalProps> = memo(({ show, onClose }) => {
  const dispatch: AppDispatch = useDispatch();
  const [status, setStatus] = useState(Status.LOADING);
  const nextStatus: Status = useSelector(selectCreateTargetStatus);
  const target: TargetRootResponse = useSelector(selectTargetRoot);

  useEffect(() => {
    updateStatus(nextStatus, setStatus);
  }, [nextStatus]);

  useEffect(() => {
    setStatus(Status.IDLE);
  }, [show]);

  const close = () => {
    onClose();
    dispatch(closeModal(null));
  };

  const toTarget = () => {
    goToTarget(target, dispatch, setFilters, setCurrentPage);
  };

  const processingContent = (
    <CreateTargetModalContainer>
      <CreateTargetTopContainer>
        <CreateTargetTopText>Quase lá!</CreateTargetTopText>
        <CreateTargetTitle>Estamos preenchendo sua meta</CreateTargetTitle>
      </CreateTargetTopContainer>
      <ContentContainer>
        <Loading />
      </ContentContainer>
    </CreateTargetModalContainer>
  );

  const createContent = (
    <CreateTargetModalContainer>
      <CreateTargetTopContainer>
        <CreateTargetTopText>Feito</CreateTargetTopText>
        <CreateTargetTitle>Meta criada com sucesso</CreateTargetTitle>
      </CreateTargetTopContainer>
      <ContentContainer>
        <img className="modal-large-icon" alt="ícone sucesso" src={Check} />
      </ContentContainer>
      <CreateTargetButtonsContainer>
        <TargetFormButton onClick={toTarget}>Ver Meta</TargetFormButton>
      </CreateTargetButtonsContainer>
    </CreateTargetModalContainer>
  );

  const errorContent = (
    <CreateTargetModalContainer>
      <CreateTargetTopContainer>
        <CreateTargetTopText>Algo deu errado...</CreateTargetTopText>
        <CreateTargetTitle>Meta não criada</CreateTargetTitle>
      </CreateTargetTopContainer>
      <ContentContainer>
        <img className="modal-large-icon" alt="ícone erro" src={Error} />
      </ContentContainer>
      <CreateTargetButtonsContainer>
        <TargetFormButton onClick={close}>Fechar e voltar</TargetFormButton>
      </CreateTargetButtonsContainer>
    </CreateTargetModalContainer>
  );

  const contentByStatus = {
    [Status.IDLE]: processingContent,
    [Status.LOADING]: processingContent,
    [Status.SUCCEEDED]: createContent,
    [Status.FAILED]: errorContent,
  };

  useEffect(() => {
    const changeModalStatus = () => {
      if (show) {
        dispatch(
          setModalContent({
            content: contentByStatus[status],
            onClose: () => {},
          })
        );
      } else {
        dispatch(closeModal(null));
      }
    };

    changeModalStatus();
    // eslint-disable-next-line
  }, [dispatch, onClose, show, status]);

  return <></>;
});

export default CreateTargetModal;
