import { memo } from "react";
import "./party-summary-widget-text.css";

type Props = {
  children: string;
  className?: string;
};

const PartySummaryWidgetText: React.FC<Props> = ({ children, className }) => {
  return (
    <div className={`party-summary-widget-text ${className}`}>{children}</div>
  );
};

export default memo(PartySummaryWidgetText);
