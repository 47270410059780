import { AiOutlineClose } from "react-icons/ai";

import { memo, MouseEventHandler } from "react";
import "./delete-target-button.css";

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const DeleteTargetButton: React.FC<Props> = (props) => {
  const { onClick } = props;

  return (
    <button className="delete-target-button" onClick={onClick}>
      <AiOutlineClose className="delete-target-button-icon" />
    </button>
  );
};

export default memo(DeleteTargetButton);
