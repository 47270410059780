import { memo } from "react";
import "./intro-title-text.css";

type Props = {
  children: string;
  className: string;
};

const IntroTitleText: React.FC<Props> = ({ children, className }) => {
  return <span className={`intro-title-text ${className}`}>{children}</span>;
};

export default memo(IntroTitleText);
