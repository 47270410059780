import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Status } from "../../app/constants";
import { ActiveFilter } from "../../app/filter";
import { debounceThunk } from "../../app/utils";
import { Election, ElectionResult, IElectionsService } from "./type";

const electionsData: Election | null = null;
const electionsResultData: ElectionResult | null = null;
const electionFilter: ActiveFilter[] | null = null;

// Slice's initial state
const initialState = {
  status: Status.IDLE,
  electionData: electionsData,
  electionResultData: electionsResultData,
  electionFilter: electionFilter,
  error: "",
};

type GetElectionsParams = {
  data: {
    year: string;
    state: string;
  };
  service: IElectionsService;
};

type GetElectionResultParams = {
  filter: ActiveFilter[];
  service: IElectionsService;
};

// get elections thunk
const getElectionsThunk = createAsyncThunk<Election[], GetElectionsParams>(
  "elections/getelections",
  async (params) => {
    const { data, service } = params;
    const response = await service.getElections(data.state, data.year);
    return response;
  }
);

// get elections result thunk
const getElectionsResultsThunk = createAsyncThunk<
  ElectionResult,
  GetElectionResultParams
>("elections/getelectionsresult", async (params) => {
  const { filter, service } = params;
  const response = await service.getElectionResult(filter);
  return response;
});

// Selectors
export const selectElections = (state) => state.elections.electionData;
export const selectElectionsResults = (state) =>
  state.elections.electionResultData;
export const selectElectionsStatus = (state) => state.elections.status;
export const selectElectionsFilter = (state) => state.elections.electionFilter;

// Reducers
const loading = (state, action) => {
  state.status = Status.LOADING;
};

const successfullElections = (state, action) => {
  state.status = Status.SUCCEEDED;
  state.electionData = action.payload;
  state.error = "";
};

const successfullElectionsResult = (state, action) => {
  state.status = Status.SUCCEEDED;
  state.electionResultData = action.payload;
  state.error = "";
};

const rejected = (state, action) => {
  state.status = Status.FAILED;
  state.error = action.error.message;
};

// Slice
const electionsSlice = createSlice({
  name: "election",
  initialState,
  reducers: {
    setFilter(state, action) {
      state.electionFilter = action.payload;
    },
    cleanUp(state, action) {
      state.status = Status.IDLE;
      state.electionFilter = electionFilter;
      state.electionResultData = electionsResultData;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getElectionsThunk.pending, loading)
      .addCase(getElectionsThunk.fulfilled, successfullElections)
      .addCase(getElectionsThunk.rejected, rejected)
      .addCase(getElectionsResultsThunk.pending, loading)
      .addCase(getElectionsResultsThunk.fulfilled, successfullElectionsResult)
      .addCase(getElectionsResultsThunk.rejected, rejected);
  },
});

export const { setFilter, cleanUp } = electionsSlice.actions;
export const getElections = debounceThunk(getElectionsThunk);
export const getElectionsResults = debounceThunk(getElectionsResultsThunk);
export default electionsSlice.reducer;
