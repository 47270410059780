import HttpClient from "../../app/client";
import { ElectionsFilterConsts } from "../../app/constants";
import handleError from "../../app/errors";
import { ActiveFilter, getValue } from "../../app/filter";
import { Election, ElectionResult, IElectionsService } from "./type";

export default class ElectionsService implements IElectionsService {
  async getElections(state: string, year: string): Promise<Election[]> {
    try {
      const client = new HttpClient("elections", true);
      return await client.post<Election[]>("/elections/", {
        year: year,
        state: state,
      });
    } catch (error) {
      handleError("Error while getting elections", error);
    }
  }

  async getElectionResult(filter: ActiveFilter[]): Promise<ElectionResult> {
    try {
      const client = new HttpClient("elections", true);
      return await client.post<ElectionResult[]>("/elections/query", {
        year: getValue(filter, ElectionsFilterConsts.YEAR),
        turn: getValue(filter, ElectionsFilterConsts.TURN),
        office_id: getValue(filter, ElectionsFilterConsts.OFFICE),
        election_id: getValue(filter, ElectionsFilterConsts.ELECTION),
        state: getValue(filter, ElectionsFilterConsts.STATE),
      });
    } catch (error) {
      handleError("Error while getting elections result", error);
    }
  }
}
