import React, { memo } from "react";
import { Status } from "../../../../app/constants";
import Loading from "../../../../components/loading/loading";
import ScrollContainer from "../../../../components/scroll/scroll";
import { CandidateSearch } from "../../../../features/candidates/type";
import ShopCandidateSearchResultItem from "../shop-candidate-search-result-item/shop-candidate-search-result-item";
import "./shop-candidate-search-result.css";

type Props = {
  candidates: CandidateSearch[];
  status: Status;
  selectedCandidate: string | null;
  setSelectedCandidate: (string) => void;
};

const ShopCandidateSearchResult: React.FC<Props> = ({
  candidates,
  status,
  selectedCandidate,
  setSelectedCandidate,
}) => {
  const isLoading = status === Status.LOADING;

  return (
    <div className={`shop-candidate-search-result`}>
      {isLoading && <Loading />}
      {!isLoading && (
        <ScrollContainer>
          {candidates.map((candidate, index) => (
            <ShopCandidateSearchResultItem
              key={`shop-candidate-${index}`}
              candidate={candidate}
              isSelected={selectedCandidate === candidate.id}
              setSelectedCandidate={setSelectedCandidate}
            />
          ))}
        </ScrollContainer>
      )}
    </div>
  );
};

export default memo(ShopCandidateSearchResult);
