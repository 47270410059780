import * as React from "react";
import { useSelector } from "react-redux";
import { validateText } from "../../../../../app/utils";
import { selectPalette } from "../../../../../features/notebook/slice";
import { ClippedImage, Paper, PaperInside } from "../commons/commons";
import { TemplatePageProps } from "../commons/interfaces";

const Page11: React.FC<TemplatePageProps> = ({
  className,
  notebook,
  triggerImageEdit,
  triggerFieldEdit,
  updateField,
}) => {
  const { palette } = useSelector(selectPalette);
  return (
    <Paper className={className}>
      <PaperInside>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
        >
          <defs>
            <filter id="page11-a" width={210} height={297}>
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
          </defs>
          <ClippedImage
            id="saphire-page-11"
            triggerImageEdit={triggerImageEdit}
            x={0}
            y={0}
            offsetY={-50}
            width={210}
            height={297}
            imageNumber={15}
            imageRatio={0.7}
            notebook={notebook}
          >
            <rect width="100%" height="100%" fill={palette.backgroundLight} />
          </ClippedImage>
          <clipPath id="page11-b">
            <path
              d="M2095.87 2157.42h167.33v206.68a201.68 201.68 0 0 1-201.68 201.68 167.33 167.33 0 0 1-167.33-167.33v-39.35a201.68 201.68 0 0 1 201.68-201.68z"
              style={{
                fill: palette.backgroundLight,
                filter: "url(#page11-a)",
              }}
              transform="rotate(-90 100.714 856.697) scale(.35513)"
            />
          </clipPath>

          <path
            d="M10.1816 213.1047l0-59.4239 73.3983 0a71.6226 71.6226 0 0171.6226 71.6226 59.4239 59.4239 0 01-59.4239 59.4239l-13.9744-0a71.6226 71.6226 0 01-71.6226-71.6226z"
            style={{
              fill: palette.backgroundLight,
              filter: "url(#page11-a)",
            }}
          />

          <foreignObject
            x={22.005}
            y={180.032}
            width={110}
            height={65}
            className="clickable"
          >
            <div
              style={{
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                fontWeight: "400",
                color: palette.backgroundGray,
                textAlign: "start",
                width: "100%",
                height: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.message,
                  field: "message",
                  label: "Mensagem da Candidatura",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.message}
            </div>
          </foreignObject>

          <path
            d="M118.116 267.43a1.449 1.449 0 0 1-1.066.03 1.47 1.47 0 0 1-.841-.608l-1.002-1.371a1.47 1.47 0 0 1-.21-.948 1.332 1.332 0 0 1 .473-.895q.582-.583 1.026-1.105c.302-.355.59-.685.87-1.001a.838.838 0 0 0 .132-.87 1.47 1.47 0 0 0-.604-.71 3.576 3.576 0 0 1-1.42-1.662 6.314 6.314 0 0 1-.526-2.291 4.89 4.89 0 0 1 1.42-3.818 5.43 5.43 0 0 1 3.744-1.662 5.245 5.245 0 0 1 2.446.448 5.54 5.54 0 0 1 1.847 1.37 6.325 6.325 0 0 1 1.183 2.028 7.12 7.12 0 0 1 .422 2.486 10.36 10.36 0 0 1-1.974 6.219q-1.968 2.727-5.92 4.36zm-12.38 0a1.449 1.449 0 0 1-1.066.03 1.47 1.47 0 0 1-.841-.608l-1.002-1.371a1.449 1.449 0 0 1-.21-.948 1.318 1.318 0 0 1 .473-.895c.387-.387.728-.757 1.026-1.105.299-.348.59-.685.87-1.001a.831.831 0 0 0 .132-.87 1.46 1.46 0 0 0-.607-.71 3.57 3.57 0 0 1-1.42-1.662 6.314 6.314 0 0 1-.527-2.291 4.88 4.88 0 0 1 1.421-3.818 5.43 5.43 0 0 1 3.74-1.662 5.249 5.249 0 0 1 2.45.448 5.494 5.494 0 0 1 1.843 1.37 6.268 6.268 0 0 1 1.186 2.028 7.206 7.206 0 0 1 .423 2.486 10.384 10.384 0 0 1-1.975 6.219q-1.964 2.727-5.916 4.36z"
            style={{
              fill: palette.primary,
              strokeWidth: 0.355131,
            }}
          />
        </svg>
      </PaperInside>
    </Paper>
  );
};

export default Page11;
