import AC from "../resources/images/square-flags/AC.svg";
import AL from "../resources/images/square-flags/AL.svg";
import AM from "../resources/images/square-flags/AM.svg";
import AP from "../resources/images/square-flags/AP.svg";
import BA from "../resources/images/square-flags/BA.svg";
import CE from "../resources/images/square-flags/CE.svg";
import DF from "../resources/images/square-flags/DF.svg";
import ES from "../resources/images/square-flags/ES.svg";
import GO from "../resources/images/square-flags/GO.svg";
import MA from "../resources/images/square-flags/MA.svg";
import MG from "../resources/images/square-flags/MG.svg";
import MS from "../resources/images/square-flags/MS.svg";
import MT from "../resources/images/square-flags/MT.svg";
import PA from "../resources/images/square-flags/PA.svg";
import PB from "../resources/images/square-flags/PB.svg";
import PE from "../resources/images/square-flags/PE.svg";
import PI from "../resources/images/square-flags/PI.svg";
import PR from "../resources/images/square-flags/PR.svg";
import RJ from "../resources/images/square-flags/RJ.svg";
import RN from "../resources/images/square-flags/RN.svg";
import RO from "../resources/images/square-flags/RO.svg";
import RR from "../resources/images/square-flags/RR.svg";
import RS from "../resources/images/square-flags/RS.svg";
import SC from "../resources/images/square-flags/SC.svg";
import SE from "../resources/images/square-flags/SE.svg";
import SP from "../resources/images/square-flags/SP.svg";
import TO from "../resources/images/square-flags/TO.svg";

//========== Request status while fetching data ==========
export enum Status {
  IDLE = "idle",
  LOADING = "loading",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
}

//================

export const CoolColorScale = [
  "#f9fbe8",
  "#f6fae5",
  "#f2f8e0",
  "#f0f6dd",
  "#ecf5d9",
  "#e9f3d6",
  "#e5f2d2",
  "#e2f0ce",
  "#dfefcb",
  "#dbedc8",
  "#d7ebc9",
  "#d5e9c9",
  "#d1e8c9",
  "#cde5ca",
  "#c9e3ca",
  "#c5e2cb",
  "#c2e0cb",
  "#bedfcc",
  "#badccc",
  "#b6dbcd",
  "#b2d9cd",
  "#aed7cd",
  "#aad6ce",
  "#a6d4ce",
  "#a1d1cf",
  "#9dd0cf",
  "#9acfcf",
  "#95ccd0",
  "#90cad0",
  "#8bc9d1",
  "#88c7d1",
  "#83c6d1",
  "#7dc3d2",
  "#78c1d2",
  "#74c0d2",
  "#6fbed3",
  "#68bdd3",
  "#61bbd4",
  "#5eb9d4",
  "#56b8d4",
  "#4eb6d5",
  "#47b4d5",
  "#42b2d4",
  "#42afd3",
  "#42add2",
  "#42acd1",
  "#42a9cf",
  "#42a7ce",
  "#42a4cd",
  "#42a3cb",
  "#41a0ca",
  "#419ec9",
  "#419cc7",
  "#4199c6",
  "#4197c5",
  "#4194c3",
  "#4092c2",
  "#4090c1",
  "#408ec0",
  "#408cbe",
  "#3f8abd",
  "#3f88bc",
  "#3f85ba",
  "#3e84b9",
  "#3e82b8",
  "#3d7fb7",
  "#3d7db5",
  "#3d7bb4",
  "#3c79b3",
  "#3c77b1",
  "#3b74b0",
  "#3b72af",
  "#3a70ad",
  "#3a6dac",
  "#396bab",
  "#396aa9",
  "#3868a8",
  "#3866a7",
  "#3763a6",
  "#3661a5",
  "#365fa3",
  "#355ca2",
  "#345ba0",
  "#34589f",
  "#33569e",
  "#32559d",
  "#32539b",
  "#31519a",
  "#304f99",
  "#2f4d98",
  "#2f4a96",
  "#2e4895",
  "#2d4693",
  "#2c4493",
  "#2b4291",
  "#2a3f90",
  "#293e8e",
  "#283b8d",
  "#27398c",
  "#26388b",
  "#253689",
  "#243388",
  "#233287",
  "#222f86",
  "#212d84",
  "#1f2b83",
  "#1e2982",
  "#1d2781",
  // "#696969",
  // "#696969",
  "#030852",
  "#030852",
];

export const HotColorScale = [
  "#fbffd1",
  "#fcfdc4",
  "#fdfaba",
  "#fef8b0",
  "#fef6a6",
  "#fff49a",
  "#fff28c",
  "#ffef80",
  "#ffee74",
  "#feec68",
  "#fee964",
  "#fee663",
  "#fee461",
  "#fee160",
  "#fddf5e",
  "#fddc5d",
  "#fdd95b",
  "#fdd75a",
  "#fdd459",
  "#fcd257",
  "#fccf56",
  "#fccc54",
  "#fbca53",
  "#fbc752",
  "#fbc550",
  "#fbc24f",
  "#fabf4d",
  "#fabc4c",
  "#fabb4b",
  "#f9b849",
  "#f9b548",
  "#f8b247",
  "#f8af45",
  "#f8ad44",
  "#f7aa43",
  "#f7a841",
  "#f6a540",
  "#f6a23f",
  "#f5a03d",
  "#f59d3c",
  "#f49a3b",
  "#f49839",
  "#f39638",
  "#f39237",
  "#f28f35",
  "#f28d34",
  "#f18a32",
  "#f08831",
  "#f08430",
  "#ef812f",
  "#ef7f2d",
  "#ee7b2c",
  "#ed792b",
  "#ed762a",
  "#ec7328",
  "#eb7127",
  "#ea6e26",
  "#ea6b25",
  "#e96723",
  "#e86422",
  "#e86121",
  "#e75e1f",
  "#e65b1e",
  "#e5581d",
  "#e5541c",
  "#e3521b",
  "#e0511c",
  "#db511d",
  "#d9501e",
  "#d4501f",
  "#d24f1f",
  "#cd4f20",
  "#ca4e21",
  "#c74d22",
  "#c34d22",
  "#bf4c23",
  "#bb4c24",
  "#b84b24",
  "#b54a25",
  "#b14a25",
  "#ae4926",
  "#aa4926",
  "#a84827",
  "#a44727",
  "#a14728",
  "#9e4628",
  "#9a4528",
  "#964529",
  "#944429",
  "#90432a",
  "#8c422a",
  "#89422a",
  "#86412b",
  "#82412b",
  "#7e402b",
  "#7c3f2c",
  "#793e2c",
  "#753e2c",
  "#723d2d",
  "#6f3c2d",
  "#6c3b2d",
  "#683b2d",
  "#643a2e",
  "#60392e",
  "#5e382e",
  "#5b372e",
  "#57372e",
  "#53362f",
  //"#696969",
  //"#696969",
  "#5c0011",
  "#5c0011",
];

export const MultiColorScale = [
  "#0000ff",
  "#0000ff",
  "#0000ff",
  "#0000ff",
  "#0000ff",
  "#0000ff",
  "#0000ff",
  "#0000ff",
  "#0000ff",
  "#0000ff",
  "#0000ff",
  "#0000ff",
  "#0000ff",
  "#0000ff",
  "#0000ff",
  "#0000ff",
  "#0000ff",
  "#0000ff",
  "#0000ff",
  "#0000ff",
  "#0000ff",
  "#0000ff",
  "#0001ff",
  "#000bff",
  "#0015ff",
  "#001fff",
  "#0029ff",
  "#0033ff",
  "#003dff",
  "#0047ff",
  "#0051ff",
  "#005bff",
  "#0065ff",
  "#006fff",
  "#0078ff",
  "#0082ff",
  "#008cff",
  "#0096ff",
  "#00a0ff",
  "#00aaff",
  "#00b4ff",
  "#00beff",
  "#00c8ff",
  "#00d2ff",
  "#00dcff",
  "#00e6ff",
  "#00f0ff",
  "#00faff",
  "#00fff6",
  "#00ffe3",
  "#00ffcf",
  "#00ffbb",
  "#00ffa7",
  "#00ff93",
  "#00ff7f",
  "#00ff6b",
  "#00ff57",
  "#00ff43",
  "#00ff2f",
  "#00ff1b",
  "#00ff07",
  "#0dff00",
  "#21ff00",
  "#35ff00",
  "#49ff00",
  "#5dff00",
  "#71ff00",
  "#85ff00",
  "#99ff00",
  "#adff00",
  "#c1ff00",
  "#d5ff00",
  "#e9ff00",
  "#fdff00",
  "#fff700",
  "#ffed00",
  "#ffe300",
  "#ffd900",
  "#ffcf00",
  "#ffc500",
  "#ffbb00",
  "#ffb100",
  "#ffa700",
  "#ff9d00",
  "#ff9300",
  "#ff8900",
  "#ff7f00",
  "#ff7500",
  "#ff6b00",
  "#ff6200",
  "#ff5800",
  "#ff4e00",
  "#ff4400",
  "#ff3a00",
  "#ff3000",
  "#ff2600",
  "#ff1c00",
  "#ff1200",
  "#ff0800",
  "#ff1200",
  "#ff0800",
  "#ff0800",
];

export const SCALES = {
  COLD_COLORS: CoolColorScale,
  HOT_COLORS: HotColorScale,
  MULTICOLORS: MultiColorScale,
};

export const DEFAULT_TIMEOUT = 1000;
//========== Plataform Pages ==========

export const MAIN_PAGE = "main";
export const AUTH_PAGE = "auth";
export const SHOP_PAGE = "shop";
export const NOTEBOOK_PAGE = "notebook";
export const NOT_FOUND = "/404";

export enum Pages {
  HOME = `main/home`,
  LOGIN = "auth/login",
  SIGNUP = "auth/signup",
  FORGOT = "auth/forgot",
  RESET = "auth/reset",
  INTRO_1 = "main/intro-1",
  INTRO_2 = "main/intro-2",
  INTRO_3 = "main/intro-3",
  INTRO_4 = "main/intro-4",
  VOTING = "main/voting",
  ELECTORATE = "main/electorate",
  CANDIDATE_VOTING = "main/search-candidate?map=true",
  ELECTIONS = "main/elections",
  SEARCH_PARTY = "main/search-party",
  PARTY_PROFILE = "main/party-profile/:id",
  SEARCH_CANDIDATE = "main/search-candidate",
  CANDIDATE_PROFILE = "main/candidate-profile/:id",
  PROFILE = "main/profile",
  SETTINGS = "main/settings",
  TARGET = "main/target",
  SHOP = "shop",
  TERMS = "terms",
  NOT_FOUND = "404",
}

export enum ShopPages {
  PRODUCT_SELECT = `product-select`,
  LOGIN = "shop-login",
  SIGNUP = "shop-signup",
  CART = "cart",
  CHECKOUT = "checkout",
  ORDER_DETAILS = "order-details",
  ORDERS = "orders",
  PLANS = "plan-select",
  PRODUCT = "product-select",
  REPORT_CANDIDATE = "report-candidate-select",
  REPORT_CANDIDATURE = "report-candidature-select",
  REPORT_TYPE = "report-type-select",
}

export enum TargetPages {
  HOME = "target-home",
  STATE_SELECTION = "target-state-selection",
  OFFICE_SELECTION = "target-office-selection",
  TARGET_SELECTION = "target-target-selection",
  CANDIDATE_DETAILS = "target-candidate-details",
  PRIMARY_PRIORITIES_SELECTION = "target-primary-priorities-selection",
  SECONDARY_PRIORITIES_SELECTION = "target-secondary-priorities-selection",
  FILLING_STRATEGY_SELECTION = "target-filling-strategy-selection",
  TARGET_DASHBOARD = "target-dashboard",
}

export enum NotebookPages {
  NOTEBOOK_QUESTIONAIRE = "notebook-questionaire",
  NOTEBOOK_VIEW = "notebook-view",
}

//========== Elections Helper Constants ==========
export const BRAZIL = "BRASIL";
export const PRESIDENTIAL = "PRESIDENCIAL";
export const municipalOffices = ["PREFEITO", "VEREADOR"];
export const generalOffices = ["PRESIDENTE"];
export const firstMunicipalElections = 2008;
export const stateOffices = [
  "DEPUTADO ESTADUAL",
  "DEPUTADO FEDERAL",
  "SENADOR",
  "GOVERNADOR",
];

//========== Parties Helper Constants ==========
export enum PartiesFilterConsts {
  YEARS = "years",
  OFFICE = "office",
  STATE = "state",
  PARTY_ID = "partyId",
}

export const AllPartiesProfileFilterNames = [
  { label: "Ano", value: PartiesFilterConsts.YEARS },
  { label: "Cargo", value: PartiesFilterConsts.OFFICE },
  { label: "Estado", value: PartiesFilterConsts.STATE },
];

//========== Elections Helper Constants ==========
export enum ElectionsFilterConsts {
  STATE = "state",
  YEAR = "year",
  TURN = "turn",
  OFFICE = "office",
  ELECTION = "electionId",
}

//========== Dashboard Helper Constants ==========
export enum Mode {
  VOTING = "voting",
  ELECTORATE = "electorate",
}

export enum DashboardFilterConsts {
  STATE = "state",
  CITY = "city",
  BURROW = "burrow",
  YEAR = "year",
  TURN = "turn",
  OFFICE = "officeId",
  PARTY = "partyId",
  CANDIDATE = "candidateId",
  SEX = "candidateGender",
  RACE = "candidateRace",
  SCHOOL_LEVEL = "candidateSchooling",
  ELECTION_STATUS = "candidateStatus",
  ELECTORATE_MARITAL_STATUS = "maritalStatus",
  ELECTORATE_SEX = "sex",
  ELECTORATE_AGE_GROUP = "ageGroup",
  ELECTORATE_SCHOOLING = "schooling",
}

export const DashboardVotingFilterNames = [
  { label: "Ano", value: DashboardFilterConsts.YEAR },
  { label: "Turno", value: DashboardFilterConsts.TURN },
  { label: "Cargo", value: DashboardFilterConsts.OFFICE },
  { label: "Partido", value: DashboardFilterConsts.PARTY },
  { label: "Candidato", value: DashboardFilterConsts.CANDIDATE },
  { label: "Raça", value: DashboardFilterConsts.RACE },
  { label: "Sexo", value: DashboardFilterConsts.SEX },
  { label: "Escolaridade", value: DashboardFilterConsts.SCHOOL_LEVEL },
  { label: "Resultado", value: DashboardFilterConsts.ELECTION_STATUS },
];

export const DashboardElectorateFilterNames = [
  { label: "Ano", value: DashboardFilterConsts.YEAR },
  {
    label: "Estado Civil",
    value: DashboardFilterConsts.ELECTORATE_MARITAL_STATUS,
  },
  { label: "Sexo", value: DashboardFilterConsts.ELECTORATE_SEX },
  { label: "Faixa Etária", value: DashboardFilterConsts.ELECTORATE_AGE_GROUP },
  { label: "Escolaridade", value: DashboardFilterConsts.ELECTORATE_SCHOOLING },
];

export enum LevelOfDetail {
  STATE = "state",
  CITY = "city",
  ZONE = "zone",
  BURROW = "burrow",
  PLACE = "place",
}

export const BR_STATES = {
  ACRE: "AC",
  ALAGOAS: "AL",
  AMAPÁ: "AP",
  AMAZONAS: "AM",
  BAHIA: "BA",
  CEARÁ: "CE",
  "DISTRITO FEDERAL": "DF",
  "ESPÍRITO SANTO": "ES",
  GOIÁS: "GO",
  MARANHÃO: "MA",
  "MATO GROSSO": "MT",
  "MATO GROSSO DO SUL": "MS",
  "MINAS GERAIS": "MG",
  PARÁ: "PA",
  PARAÍBA: "PB",
  PARANÁ: "PR",
  PERNAMBUCO: "PE",
  PIAUÍ: "PI",
  "RIO DE JANEIRO": "RJ",
  "RIO GRANDE DO NORTE": "RN",
  "RIO GRANDE DO SUL": "RS",
  RONDÔNIA: "RO",
  RORAIMA: "RR",
  "SANTA CATARINA": "SC",
  "SÃO PAULO": "SP",
  SERGIPE: "SE",
  TOCANTINS: "TO",
};
//========== Settings Helper Constants ==========
export enum MapsTypes {
  NONE = "NONE",
  GOOGLE_MAPS = "GOOGLE_MAPS",
  OPEN_STREET_MAPS = "OPEN_STREET_MAPS",
  WIKIMEDIA_MAPS = "WIKIMEDIA_MAPS",
}

export const MapsUrls = {
  [MapsTypes.NONE]: ["", ""],
  [MapsTypes.GOOGLE_MAPS]: [
    "https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
    ["mt0", "mt1", "mt2", "mt3"],
  ],
  [MapsTypes.OPEN_STREET_MAPS]: [
    "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    "abc",
  ],
  [MapsTypes.WIKIMEDIA_MAPS]: [
    "https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png",
    "abc",
  ],
};

export enum MarkColors {
  WHITE = "#F3F3F3",
  ORANGE = "#E69F00",
  LIGHT_BLUE = "#56B4E9",
  GREEN = "#009E73",
  YELLOW = "#F0E442",
  DARK_BLUE = "#0072B2",
  RED = "#D55E00",
}

export enum MarkColorModes {
  FIXED_COLOR = "FIXED_COLOR",
  SCALED_COLOR = "SCALED_COLOR",
}

export enum MarkSizeModes {
  FIXED_SIZE = "FIXED_SIZE",
  SCALED_SIZE = "SCALED_SIZE",
}

export const TILES = [
  { label: "Sem Mapa de Fundo", value: MapsTypes.NONE },
  { label: "Google Maps", value: MapsTypes.GOOGLE_MAPS },
  { label: "OpenStreet Maps", value: MapsTypes.OPEN_STREET_MAPS },
  { label: "Wikimedia Maps", value: MapsTypes.WIKIMEDIA_MAPS },
];

export const COLOR_SCALE = [
  { label: "Cores Frias (Linear)", value: "COLD_COLORS-LINEAR" },
  { label: "Cores Frias (DataElege)", value: "COLD_COLORS-DATAELEGE" },
  { label: "Cores Quentes (Linear)", value: "HOT_COLORS-LINEAR" },
  { label: "Cores Quentes (DataElege)", value: "HOT_COLORS-DATAELEGE" },
  { label: "Multicores (Linear)", value: "MULTICOLORS-LINEAR" },
  { label: "Multicores (DataElege)", value: "MULTICOLORS-DATAELEGE" },
];

export const SIZE_MODES = [
  { label: "Tamanho Fixo", value: MarkSizeModes.FIXED_SIZE },
  { label: "Tamanho em Escala", value: MarkSizeModes.SCALED_SIZE },
];

export const COLOR_MODE = [
  { label: "Cor Fixa", value: MarkColorModes.FIXED_COLOR },
  { label: "Cor em Escala", value: MarkColorModes.SCALED_COLOR },
];

export const COLORS = [
  { label: "Branco", value: MarkColors.WHITE },
  { label: "Laranja", value: MarkColors.ORANGE },
  { label: "Azul Claro", value: MarkColors.LIGHT_BLUE },
  { label: "Verde", value: MarkColors.GREEN },
  { label: "Azul Escuro", value: MarkColors.DARK_BLUE },
  { label: "Amarelo", value: MarkColors.YELLOW },
  { label: "Vermelho", value: MarkColors.RED },
];

//========== Dashboard Helper Constants ==========
export const SCENARIO_A = "SCENARIO_A";
export const SCENARIO_B = "SCENARIO_B";

//========== Shop Constants ==========
export const BY_BURROW = "BY_BURROW";
export const BY_PLACE = "BY_PLACE";
export const REPORT = "REPORT";
export const ACCESS_PLAN = "ACCESS_PLAN";
export const BOLETO = "boleto";
export const CREDIT_CARD = "credit_card";
export const PIX = "pix";

export const WAITING_PAYMENT = "WAITING_PAYMENT";
export const IN_PROGRESS = "IN_PROGRESS";
export const COMPLETED = "COMPLETED";
export const EXPIRED = "EXPIRED";

export const brazilianStates = [
  { label: "Acre", value: "AC" },
  { label: "Alagoas", value: "AL" },
  { label: "Amapá", value: "AP" },
  { label: "Amazonas", value: "AM" },
  { label: "Bahia", value: "BA" },
  { label: "Ceará", value: "CE" },
  { label: "Distrito Federal", value: "DF" },
  { label: "Espírito Santo", value: "ES" },
  { label: "Goiás", value: "GO" },
  { label: "Maranhão", value: "MA" },
  { label: "Mato Grosso", value: "MT" },
  { label: "Mato Grosso do Sul", value: "MS" },
  { label: "Minas Gerais", value: "MG" },
  { label: "Pará", value: "PA" },
  { label: "Paraíba", value: "PB" },
  { label: "Paraná", value: "PR" },
  { label: "Pernambuco", value: "PE" },
  { label: "Piauí", value: "PI" },
  { label: "Rio de Janeiro", value: "RJ" },
  { label: "Rio Grande do Norte", value: "RN" },
  { label: "Rio Grande do Sul", value: "RS" },
  { label: "Rondônia", value: "RO" },
  { label: "Roraima", value: "RR" },
  { label: "Santa Catarina", value: "SC" },
  { label: "São Paulo", value: "SP" },
  { label: "Sergipe", value: "SE" },
  { label: "Tocantins", value: "TO" },
];

export const targetOptions = [
  { label: "Sim", value: true },
  { label: "Não", value: false },
];

export const SquareFlags = {
  AC: AC,
  AL: AL,
  AM: AM,
  AP: AP,
  BA: BA,
  CE: CE,
  DF: DF,
  ES: ES,
  GO: GO,
  MA: MA,
  MG: MG,
  MS: MS,
  MT: MT,
  PA: PA,
  PB: PB,
  PE: PE,
  PI: PI,
  PR: PR,
  RJ: RJ,
  RN: RN,
  RO: RO,
  RR: RR,
  RS: RS,
  SC: SC,
  SE: SE,
  SP: SP,
  TO: TO,
};

//========== Target Constants ==========
export const MAX_TARGETS = 3;
export const TARGET_OFFICES = [
  "VEREADOR",
  "DEPUTADO ESTADUAL",
  "DEPUTADO FEDERAL",
];
export const STATE_TARGET = "state_target";
export const CITY_TARGET = "city_target";
export const PRIMARY_TARGET_LEVEL = "primary_level";
export const SECONDARY_TARGET_LEVEL = "secondary_level";
export const DATAELEGE_STRATEGY = "dataelege";
export const PROPORTIONAL_STRATEGY = "proportional";
export const TARGET_MAP_ATTRIBUTE = "target_map";
export const TARGET_TOTAL_ATTRIBUTE = "target_total";
export const TARGET_PRIMARY_TOTAL_ATTRIBUTE = "target_primary_total";
export const BELOW_LEAST_VOTED = "below_least_voted";
export const BELOW_AVERAGE = "below_average";
export const ON_AVERAGE = "on_average";
export const OPTIMISTIC = "optimistic";
export const TOO_OPTIMISTIC = "too_optimistic";
export const UNFEASIBLE = "unfeasible";

export const PRIMARY_PRIORITY = "Primário";
export const SECUNDARY_PRIORITY = "Secundário";
export const COMPLEMENTARY_PRIORITY = "Complementar";
export const BELOW_LEAST_VOTED_PT = "Menor que menos votado";
export const BELOW_AVERAGE_PT = "Abaixo da média";
export const ON_AVERAGE_PT = "Na média";
export const OPTIMISTIC_PT = "Otimista";
export const TOO_OPTIMISTIC_PT = "Muito otimista";
export const UNFEASIBLE_PT = "Impossível";

export enum TargetFilterConsts {
  TARGET_ID = "targetId",
}

export enum TargetGroupType {
  NO_GROUP = "nogroup",
  ADEQUACY = "adequacy",
  PRIORITY = "priority",
}

//========== Notebooks Constants ==========
export const NotebooksReviewStatusFilter = [
  { label: "Revisado", value: true },
  {
    label: "Não Revisado",
    value: false,
  },
];

export const NotebooksGenderOptions = [
  { label: "Feminino", value: "Feminino" },
  { label: "Masculino", value: "Masculino" },
  { label: "Outro", value: "Outro" },
];

export const NotebooksOfficesOptions = [
  { label: "Vereador", value: "Vereador" },
  { label: "Prefeito", value: "Prefeito" },
  { label: "Deputado Estadual", value: "Deputado Estadual" },
  { label: "Deputado Federal", value: "Deputado Federal" },
  { label: "Senador", value: "Senador" },
  { label: "Governador", value: "Governador" },
];

export const NotebooksCausesOptions = [
  "Saúde",
  "Educação",
  "Assistência Social",
  "Direitos Humanos",
  "Empoderamento Feminino",
  "Diversidade Racial / Combate ao Racismos",
  "Diversidade sexual e de gênero / Combate a LGBTfobia",
  "Fazenda / Orçamento",
  "Planejamento Governamental",
  "Meio Ambiente",
  "Trabalho e Renda",
  "Infraestrutura, obras e conservação pública",
  "Comunicação",
  "Cultura",
  "Esporte e Lazer",
  "Agricultura",
  "Transporte",
  "Economia",
  "Turismo",
  "Legislativo",
  "Judiciário",
  "Segurança Pública",
  "Causa animal",
  "Juventude",
  "Criança e adolescente",
  "Saneamento básico (Ex: coleta de lixo, água e esgoto)",
  "Pessoas com deficiência",
];

export const NotebooksActionsOptions = [
  "Realizar planejamento governamental de qualidade",
  "Utilizar inovações tecnológicas aplicadas ao setor público",
  "Promover eficiência na alocação de recursos públicos",
  "Elaborar políticas públicas para igualdade social",
  "Fortalecer a transparência e o controle social",
  "Melhorar a qualidade dos serviços e das políticas públicas",
  "Implementar planejamento orçamentário eficaz",
  "Redução e simplificação da burocracia",
  "Fortalecer o combate a corrupção",
  "Colocar os interesses públicos acima dos pessoais",
  "Promover gestão com mais participação social e popular",
];

export const toFeminine = (value: string) => {
  const map = {
    Vereador: "Vereadora",
    Prefeito: "Prefeita",
    "Deputado Estadual": "Deputada Estadual",
    "Deputado Federal": "Deputada Federal",
    Senador: "Senadora",
    Governador: "Governadora",
    Candidato: "Candidata",
  };

  return map[value];
};

export const SAPPHIRE = "safira";
export const EMERALD = "esmeralda";
export const RUBY = "rubi";

export const NotebooksTemplateOptions = [
  { label: "Modelo Safira", value: SAPPHIRE },
  { label: "Modelo Esmeralda", value: EMERALD },
  // { label: "Modelo Rubi", value: RUBY },
];

const NotebookPalettes = {
  GreenYellow: {
    primary: "#59AF36",
    secondary: "#FFD700",
    terciary: "#1D2B48",
    backgroundDark: "#2B2A2A",
    backgroundGray: "#6C6C6C",
    backgroundLight: "#FCFCF5",
  },
  RedYellow: {
    primary: "#E50000",
    secondary: "#F4D240",
    terciary: "#FF9700",
    backgroundDark: "#38362A",
    backgroundGray: "#6B6868",
    backgroundLight: "#FFFEFA",
  },
  YellowOrange: {
    primary: "#FFDA00",
    secondary: "#F29F27",
    terciary: "#A71214",
    backgroundDark: "#003D14",
    backgroundGray: "#686763",
    backgroundLight: "#FFFEFD",
  },
  OragenOlive: {
    primary: "#F16D23",
    secondary: "#96BB3A",
    terciary: "#C5112C",
    backgroundDark: "#273A7C",
    backgroundGray: "#525C77",
    backgroundLight: "#FFFBF8",
  },
  BlueOrange: {
    primary: "#284891",
    secondary: "#FAB117",
    terciary: "#E5C51A",
    backgroundDark: "#2D7624",
    backgroundGray: "#6C6C6C",
    backgroundLight: "#FCFCF5",
  },
  LightBlue: {
    primary: "#19AFEC",
    secondary: "#1F9A40",
    terciary: "#46D397",
    backgroundDark: "#112D42",
    backgroundGray: "#8C9B8F",
    backgroundLight: "#FBFFFA",
  },
  GreenRed: {
    primary: "#03A748",
    secondary: "#ED2228",
    terciary: "#2F29A8",
    backgroundDark: "#382626",
    backgroundGray: "#6C6C6C",
    backgroundLight: "#FCFCF5",
  },
  RoseBlue: {
    primary: "#FF94AE",
    secondary: "#097489",
    terciary: "#C21A7C",
    backgroundDark: "#352532",
    backgroundGray: "#6C6C6C",
    backgroundLight: "#FCFCF5",
  },
};

export const NotebookPalettesOptions = Object.keys(NotebookPalettes).map(
  (k) => ({
    label: k,
    value: k,
    palette: NotebookPalettes[k],
  })
);
