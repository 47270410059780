import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { Status } from "../../../app/constants";
import { EmptyMap } from "../../../app/utils";
import CandidateService from "../../../features/candidates/service";
import {
  selectElectionsResults,
  selectElectionsStatus,
} from "../../../features/elections/slice";
import { ElectionResult } from "../../../features/elections/type";
import ElectionRanking from "../components/election-ranking/election-ranking";

const ElectionRankingWithData: React.FC = () => {
  const results: ElectionResult = useSelector(selectElectionsResults);
  const status: Status = useSelector(selectElectionsStatus);
  const [photos, setPhotos] = useState(EmptyMap);

  const fetchCandidatePhoto = async (candidateId: string) => {
    const service = new CandidateService();
    const base64Photo = await service.getCandidatePhoto(candidateId);

    setPhotos((prevState) => ({
      ...prevState,
      [candidateId]: base64Photo,
    }));
  };

  const notifyVisibilityChange = (candidateId: string, isVisible: boolean) => {
    if (isVisible) {
      fetchCandidatePhoto(candidateId);
    }
  };

  return (
    <ElectionRanking
      result={results?.ranking}
      status={status}
      photos={photos}
      onVisibilityChange={notifyVisibilityChange}
    />
  );
};

export default memo(ElectionRankingWithData);
