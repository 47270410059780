import * as React from "react";
import { useSelector } from "react-redux";
import { validateText } from "../../../../../app/utils";
import { selectPalette } from "../../../../../features/notebook/slice";
import { ClippedImage, Paper, PaperInside } from "../commons/commons";
import { TemplatePageProps } from "../commons/interfaces";

const Page5: React.FC<TemplatePageProps> = ({
  className,
  notebook,
  triggerImageEdit,
  triggerFieldEdit,
  updateField,
}) => {
  const { palette } = useSelector(selectPalette);
  return (
    <Paper className={className}>
      <PaperInside>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
        >
          <defs>
            <clipPath id="page5-a">
              <path d="M60.59 935.5h126.32a165 165 0 0 1 165 165v347H209a148.39 148.39 0 0 1-148.41-148.41Z" />
            </clipPath>
          </defs>
          <path
            d="M-.224-.032h209.955v296.934H-.224z"
            style={{
              fill: palette.backgroundLight,
              strokeWidth: 0.352699,
            }}
          />
          <g
            style={{
              opacity: 0.13,
            }}
          >
            <path d="M17.443 42.324c-.878 3.615-2.021 7.22-3.174 10.824-1.058 3.235-2.198 6.469-3.333 9.7l-1.852.948c-.176.117-.353.237-.522.353 1.17-4.303 2.43-8.606 3.393-12.916C13.239 45.495 14.424 39.75 15.168 34a52.52 52.52 0 0 0-1.51-20.915A51.762 51.762 0 0 0 8.238.423h3.35C16.11 6.913 18.55 13.52 19.2 20.196a71.598 71.598 0 0 1 0 12.175c-.232 3.319-.952 6.641-1.756 9.953z" />
            <path d="M26.42 49.583a211.472 211.472 0 0 1-4.113 7.438l-3.548 1.816c2.116-4.27 4.264-8.542 6.292-12.82a45.294 45.294 0 0 0 4.14-14.46c.681-6.074.085-12.13-2.86-18.133-2.19-4.458-5.39-8.818-10.056-13.015h3.683a3.679 3.679 0 0 0 .486.628c7.238 6.433 11.24 13.142 12.514 20.062a28.703 28.703 0 0 1-.949 14.704c-1.551 4.616-3.22 9.226-5.59 13.78z" />
            <path d="M35.219 52.464q-1.672.434-3.322.924c3.64-5.29 6.912-10.58 8.909-15.995 2.243-6.077 2.843-12.157.635-18.255-1.887-5.203-5.795-10.186-11.805-14.951-1.598-1.267-3.323-2.512-4.99-3.764h3.343a5.798 5.798 0 0 0 .6.49c11.797 7.562 17.652 15.713 18.117 24.39.297 5.558-1.534 11.046-4.486 16.488a88.073 88.073 0 0 1-7.001 10.673z" />
            <path d="M47.461 50.052q-1.583.218-3.142.476c3.132-3.746 5.91-7.53 7.974-11.389 2.952-5.52 4.345-11.071 2.906-16.697Q52.833 13.195 40.485 5.09c-2.43-1.59-5.192-3.114-7.802-4.666h3.347c1.14.621 2.282 1.245 3.414 1.87C52.787 9.653 59.57 17.72 59.516 26.55c-.035 5.7-2.662 11.255-6.602 16.743a85.861 85.861 0 0 1-5.453 6.758z" />
            <path d="M58.705 49.085c-.97.039-1.936.085-2.899.145 4.769-4.938 8.74-9.936 10.88-15.142 1.648-4.013 2.075-8.038.727-12.072-1.982-5.933-7.523-11.41-16.46-16.412-3.174-1.763-6.81-3.46-10.228-5.18h3.347c3.086 1.488 6.348 2.934 9.23 4.472 10.394 5.551 16.647 11.66 18.178 18.379 1.09 4.793-.31 9.523-3.146 14.196-2.409 3.964-5.76 7.823-9.629 11.614z" />
            <path d="M68.86 49.036a82.286 82.286 0 0 0-2.642-.057 99.265 99.265 0 0 0 4.13-3.992c5.09-5.259 8.863-10.616 9.618-16.224 1.005-7.439-3.96-14.295-14.725-20.616C60.493 5.357 54.833 2.83 48.756.42h3.347l.917.352c9.406 3.626 17.342 7.619 22.869 12.207 8.69 7.217 9.505 14.673 5.16 22.287-2.712 4.758-7.03 9.322-12.19 13.77z" />
            <path d="M77.642 49.812a74.668 74.668 0 0 0-2.219-.282c7.407-5.81 13.212-11.82 15.36-18.242 1.654-4.938.61-9.79-3.752-14.528-5.249-5.7-14.514-10.464-25.853-14.753-1.376-.519-2.702-1.058-4.05-1.584h3.348a5.333 5.333 0 0 0 .666.328C68.32 3.263 74.792 5.99 80.248 9.03c9.34 5.21 14.43 10.892 14.669 17.12.201 5.29-2.973 10.338-7.742 15.286a89.822 89.822 0 0 1-9.533 8.377z" />
            <path d="M87.842 49.226q-1.432.977-2.889 1.961a65.141 65.141 0 0 0-1.643-.374 134.561 134.561 0 0 0 5.177-3.967c6.857-5.555 12.345-11.259 13.653-17.388 1.058-4.878-.825-9.587-6.063-14.108-6.183-5.337-15.91-9.735-27.38-13.668C67.495 1.27 66.341.836 65.17.416h3.35c3.105 1.1 6.282 2.18 9.302 3.312 9.17 3.435 16.785 7.259 21.846 11.71 7.071 6.225 7.537 12.609 3.488 19.102-3.217 5.16-8.535 10.041-15.314 14.686z" />
            <path d="M105.145 43.223c-4.028 3.376-8.733 6.617-13.71 9.805a73.72 73.72 0 0 0-1.495-.48c1.93-1.283 3.81-2.577 5.619-3.879 7.516-5.435 13.868-11.018 16.305-17.092 1.866-4.645 1.122-9.22-3.054-13.688-5.164-5.527-14.38-10.08-25.705-14.108-3.213-1.146-6.599-2.233-9.904-3.344h3.347L80.4 1.69c9.918 3.192 18.693 6.726 25.295 10.934 8.769 5.608 11.869 11.618 9.608 17.988-1.559 4.394-5.326 8.56-10.158 12.612zM1.907 69.298c-.353.31-.706.621-1.034.932V.423h2.342c.095.586.24 1.175.279 1.764C4.005 9.389 4.71 16.59 4.9 23.8c.282 11.389-.804 22.774-1.943 34.152-.364 3.774-.716 7.551-1.051 11.346Z" />
            <path d="M119.86 38.32c-5.478 5.743-13.42 11.04-22.118 16.197l-.205.12q-.825-.237-1.66-.455 3.293-2.018 6.528-4.046c7.653-4.818 13.875-9.875 17.56-15.328 3.14-4.627 3.905-9.287.928-13.97-4.197-6.603-14.46-11.869-27.567-16.496-3.88-1.365-8.052-2.617-12.09-3.919h3.35c.459.145.903.3 1.376.441 9.981 2.963 19.088 6.232 26.304 10.144 9.065 4.916 13.893 10.302 13.502 16.26-.254 3.805-2.49 7.466-5.908 11.053z" />
            <path d="M124.537 42.338c-6.053 4.938-13.632 9.551-21.579 14.108-.409-.155-.821-.303-1.234-.451 6.165-3.612 12.066-7.28 17.152-11.121 6.666-5.03 11.861-10.228 13.05-15.85q1.492-7.104-6.881-13.533c-6.127-4.705-15.322-8.54-25.776-11.992-3.206-1.058-6.652-2.032-9.988-3.04h3.347c2.941.899 5.9 1.788 8.817 2.701 8.292 2.593 15.6 5.51 21.515 8.867 10.108 5.746 13.985 11.992 11.286 18.76-1.611 4.01-5.149 7.827-9.71 11.551z" />
            <path d="M107.699 58.41q-.575-.271-1.164-.528c5.019-2.9 10.01-5.806 14.76-8.761 7.16-4.455 13.089-9.125 16.729-14.14 3.76-5.178 4.373-10.366.067-15.544-4.113-4.937-11.66-9.17-21.582-12.778-6.045-2.208-12.764-4.165-19.183-6.236h3.671c1.327.42 2.603.86 3.982 1.26 8.75 2.514 16.64 5.34 23.3 8.602 13.483 6.606 18.421 13.928 14.002 22.047-2.68 4.92-7.985 9.474-14.362 13.882-6.095 4.211-13.057 8.229-20.22 12.197z" />
            <path d="m113.211 59.818-1.357.74c-.304-.169-.607-.352-.914-.504q5.199-2.927 10.355-5.869c8.02-4.585 15.307-9.332 20.77-14.418 4.272-3.971 7.154-8.063 7.408-12.345.3-5.364-3.827-10.284-11.689-14.813-6.998-4.024-16.044-7.368-26.1-10.373-2.007-.6-3.989-1.21-5.996-1.816h3.347c.833.26 1.64.532 2.501.78 11.738 3.392 22.337 7.166 30.11 11.889 9.255 5.622 12.345 11.674 9.477 18.128-1.957 4.42-6.25 8.564-11.61 12.57-7.517 5.62-16.825 10.843-26.302 16.03z" />
            <path d="M115.698 62.95c-.3-.201-.603-.399-.91-.596 7.925-4.511 15.812-9.036 23.352-13.636 7.904-4.814 14.03-9.942 17.283-15.465 3.724-6.296 2.193-12.377-5.901-18.164-7.114-5.09-17.607-9.146-29.556-12.754L113.706.44h3.35c.402.134.78.279 1.21.402 11.886 3.39 22.629 7.167 30.6 11.89 8.793 5.209 12.4 10.856 10.606 16.93-1.372 4.644-5.53 9-10.913 13.208-6.74 5.29-15.166 10.19-23.864 15.053q-4.479 2.5-8.997 5.026z" />
            <path d="M119.338 65.348c-.244-.17-.494-.352-.748-.5l.86-.487c10.229-5.785 20.697-11.509 29.391-17.635 6.881-4.843 12.595-9.837 14.902-15.276 2.345-5.52.6-10.828-6.028-15.892-6.447-4.938-16.125-8.924-27.306-12.482-2.846-.907-5.767-1.764-8.652-2.663h3.347c.413.134.805.275 1.235.398 11.135 3.234 21.218 6.825 28.921 11.234q15.406 8.817 10.934 19.144c-1.901 4.42-6.098 8.574-11.34 12.599-7.544 5.784-16.978 11.173-26.536 16.53-2.984 1.687-5.993 3.358-8.98 5.03z" />
            <path d="M122.382 67.602c4.846-2.9 9.745-5.784 14.608-8.676 8.148-4.843 16.256-9.696 22.735-14.877 5.996-4.78 10.61-9.7 11.689-14.997 1.08-5.298-1.813-10.281-8.63-14.965-6.028-4.14-14.317-7.626-23.797-10.757-3.005-.995-6.127-1.94-9.195-2.907h3.347c.37.12.727.247 1.111.353 9.036 2.705 17.35 5.643 24.23 9.124 13.244 6.666 18.157 14.034 14.108 22.153-1.922 3.88-5.42 7.555-9.843 11.132-6.603 5.34-14.884 10.337-23.32 15.307-5.454 3.213-10.96 6.419-16.39 9.635-.209-.169-.428-.349-.653-.525z" />
            <path d="M125.76 70.586c4.642-2.949 9.488-5.855 14.292-8.768 8.599-5.217 17.219-10.43 24.3-15.953 6.35-4.938 11.45-10.02 13.132-15.49 1.46-4.68.05-9.224-4.677-13.629-5.103-4.75-13.212-8.764-23.07-12.344-3.827-1.383-7.932-2.667-11.91-3.996h3.35c.37.12.734.247 1.111.352 11.018 3.453 20.81 7.28 27.951 11.94 7.88 5.149 11.065 10.661 9.69 16.516-1.006 4.3-4.261 8.398-8.646 12.383-6.253 5.686-14.534 10.99-23.07 16.246-7.255 4.465-14.623 8.909-21.768 13.402a38.585 38.585 0 0 1-.684-.66z" />
            <path d="M130.977 74.19q-.653.445-1.287.89c-.18-.24-.353-.484-.544-.724 3.175-2.271 6.529-4.514 9.918-6.747 8.867-5.837 17.921-11.64 26.453-17.54 7.343-5.086 13.208-10.408 16.38-16.072 2.683-4.793 2.92-9.576-.424-14.348-3.527-5.012-10.306-9.407-19.575-13.276-5.008-2.088-10.658-3.975-16.016-5.95h3.347c6.839 2.25 13.223 4.652 18.77 7.343 11.993 5.816 18.528 12.3 18.006 19.628-.353 4.811-3.527 9.417-8.112 13.91-5.714 5.602-13.353 10.867-21.317 16.077-8.557 5.6-17.314 11.16-25.6 16.81z" />
            <path d="M132.72 80.049c-.114-.226-.234-.452-.353-.677a156.436 156.436 0 0 1 12.482-9.72c8.464-6.05 17.257-12.042 25.51-18.13 8.254-6.087 15.089-12.46 17.854-19.275 1.41-3.435 1.545-6.87.05-10.306-2.173-4.997-7.594-9.522-15.463-13.653C167.323 5.403 160.854 2.836 153.9.42h3.686c.352.169.705.352 1.086.504 3.975 1.619 8.197 3.174 11.9 4.86 13.078 5.996 20.541 12.772 20.703 20.5.1 4.856-2.578 9.543-6.7 14.135-4.801 5.333-11.33 10.394-18.32 15.382-8.86 6.32-18.03 12.587-26.615 18.957-2.426 1.799-4.701 3.619-6.772 5.464-.06-.053-.102-.113-.148-.173zM135.114 116c.586.352 1.182.684 1.799 1.022.455.25.896.505 1.34.755a1.439 1.439 0 0 1-.726-.116 71.968 71.968 0 0 1-2.568-1.372c.053-.082.106-.19.155-.29z" />
            <path d="M136.535 86.038c.258-.353.515-.67.787-1.006 4.99-6.182 12.193-12.044 19.709-17.857 8.39-6.49 17.3-12.894 24.509-19.578 6.123-5.679 10.965-11.473 12.306-17.596 1.41-6.476-1.612-12.641-9.636-18.457-5.728-4.155-13.403-7.795-22.273-11.12h3.682c.24.126.459.264.727.384 4.091 1.873 8.464 3.671 12.228 5.643 9.798 5.1 15.62 10.764 17.113 16.982 1.083 4.53-.3 8.987-3.122 13.403-3.6 5.643-9.258 10.997-15.818 16.224-7.552 6.031-15.487 11.992-23.053 18.023-6.627 5.29-12.655 10.645-16.577 16.26-.172-.441-.37-.875-.582-1.305zM137.276 109.432c2.744 3.129 6.701 6.091 11.77 8.892.32.173.592.352.885.543l-.437.137c-.057-.074-.064-.162-.18-.225-5.139-2.695-9.244-5.517-12.214-8.465.056-.303.116-.593.176-.882z" />
            <path d="M170.306.423c1.95.928 3.919 1.852 5.848 2.787 9.685 4.698 16.69 9.854 20.104 15.624a15.706 15.706 0 0 1 1.665 12.697c-1.862 6.702-7.09 13.11-13.819 19.35-6.648 6.165-13.78 12.263-20.665 18.393-6.348 5.643-12.203 11.357-16.062 17.31-2.578 3.982-4.165 8.01-4.12 12.105.09 7.76 6.212 14.845 17.971 21.303a1.725 1.725 0 0 0 .688.212 3.594 3.594 0 0 0-.487-.353c-7.153-4.123-12.143-8.564-14.753-13.343a15.519 15.519 0 0 1-1.623-10.785c1.584-8.077 8.31-15.685 16.422-23.148 7.354-6.761 15.166-13.459 22.573-20.213 5.791-5.29 10.609-10.666 13.575-16.253 3.88-7.3 3.919-14.548-2.518-21.694-4.483-4.977-11.615-9.417-20.566-13.49-.353-.156-.596-.332-.892-.498z" />
            <path d="M178.344.423c.745.42 1.45.854 2.233 1.266 10.098 5.33 17.039 11.142 20.104 17.537a18.023 18.023 0 0 1 1.277 12.249c-1.855 7.851-7.76 15.356-14.722 22.77-5.777 6.151-11.854 12.264-17.635 18.415-5.322 5.643-9.678 11.413-12.168 17.338-4.328 10.31-1.273 20.207 12.648 29.483a12.041 12.041 0 0 0 2.243 1.22 4.232 4.232 0 0 0-.43-.377q-11.992-7.979-14.221-17.032c-1.224-4.96-.272-9.876 2.098-14.782 3.947-8.15 11.287-15.946 18.813-23.723 6.091-6.302 12.377-12.584 17.283-19.045 4.532-5.957 7.801-11.992 8.193-18.186q.92-14.524-21.455-26.575c-.353-.18-.596-.381-.896-.572z" />
            <path d="M186.383.423c1.763 1.238 3.6 2.47 5.29 3.714 7.555 5.615 11.992 11.537 13.526 17.762 1.298 5.326.455 10.616-1.647 15.893-2.822 7.117-8 14.03-13.24 20.943-4.998 6.596-10.042 13.188-14.708 19.815-3.657 5.188-5.925 10.478-6.853 15.832-1.594 9.202 2.095 18.066 13.008 26.453a2.532 2.532 0 0 0 .955.455c-1.15-1.058-2.349-2.081-3.438-3.132-5.4-5.199-8.236-10.581-9.026-16.133-.995-6.958 1.453-13.797 5.262-20.587 4.176-7.449 10.088-14.732 15.674-22.047 4.772-6.243 9.34-12.503 12.454-18.89 2.822-5.813 4.317-11.66 3.435-17.565-1.17-7.802-6.67-15.166-16.813-22.079-.204-.141-.352-.296-.529-.44z" />
            <path d="M194.417.423c2.116 2.116 4.405 4.2 6.253 6.349 4.254 4.938 6.596 9.992 7.548 15.166 1.09 5.915.219 11.798-1.679 17.674-2.183 6.768-5.893 13.438-9.523 20.104-3.692 6.796-7.54 13.582-10.817 20.403-3.174 6.624-4.85 13.311-4.437 20.048.452 7.385 3.644 14.598 10.613 21.56a1.513 1.513 0 0 0 .705.42c-.864-1.058-1.763-2.116-2.585-3.153-3.527-4.628-5.59-9.336-6.532-14.108a31.327 31.327 0 0 1 .871-15.745 113.301 113.301 0 0 1 6.183-15.518c3.82-7.837 8.465-15.622 12.239-23.462 2.363-4.906 3.957-9.876 5.551-14.813 1.474-4.586 1.633-9.192.928-13.798-1.104-7.17-4.8-14.147-11.809-20.873a2.024 2.024 0 0 1-.176-.233z" />
            <path d="M210.825 34.773c-.19.846-.381 1.69-.568 2.536-.83 3.798-1.503 7.607-2.526 11.399-1.114 4.133-2.55 8.26-3.851 12.387-1.488 4.733-3.132 9.46-4.43 14.2-1.263 4.62-2.363 9.25-3.174 13.882-.9 5.107-1.214 10.228-.233 15.331a93.68 93.68 0 0 0 3.132 11.615c.74 2.243 2.137 4.458 3.238 6.701l.437-.025a23.564 23.564 0 0 1-1.15-2.55c-1.23-3.925-2.666-7.844-3.527-11.78-1.248-5.671-1.09-11.36-.31-17.035.6-4.374 1.52-8.744 2.543-13.11.98-4.18 2.232-8.355 3.446-12.528 1.213-4.172 2.617-8.419 3.809-12.634.942-3.329 1.69-6.662 2.54-9.995.172-.68.423-1.361.638-2.042zM202.455.423c1.598 2.854 3.555 5.693 4.705 8.57 1.457 3.648 2.257 7.33 3.344 10.998.088.3.215.596.32.896v-8.528c-.091-.092-.229-.184-.267-.283q-2.385-5.823-4.755-11.639zM210.825 74.483a12.299 12.299 0 0 0-.272 1.697c-.215 8.944-.49 17.889-.575 26.833-.032 3.467.353 6.934.59 10.401a2.388 2.388 0 0 0 .257.66z" />
          </g>
          <text
            x={131.481}
            y={101.468}
            style={{
              fontSize: "20.7317px",
              fontFamily: "Heavitas",
              fill: palette.secondary,
              strokeWidth: 0.352699,
            }}
          >
            <tspan
              style={{
                strokeWidth: 0.352699,
                fill: palette.terciary,
              }}
            >
              {"MI"}
            </tspan>
            <tspan
              x={131.481}
              y={126.348}
              style={{
                strokeWidth: 0.352699,
                fill: palette.terciary,
              }}
            >
              {"NHA"}
            </tspan>
            <tspan
              style={{
                strokeWidth: 0.352699,
                fill: palette.secondary,
              }}
            >
              <tspan
                x={131.481}
                y={151.227}
                style={{
                  strokeWidth: 0.352699,
                }}
              >
                {"HIS"}
              </tspan>
              <tspan
                x={131.481}
                y={176.106}
                style={{
                  strokeWidth: 0.352699,
                }}
              >
                {"T\xD3"}
              </tspan>
              <tspan
                x={131.481}
                y={200.982}
                style={{
                  strokeWidth: 0.352699,
                }}
              >
                {"RIA"}
              </tspan>
            </tspan>
          </text>

          <ClippedImage
            id="saphire-page-5"
            triggerImageEdit={triggerImageEdit}
            x={20}
            y={20}
            width={104}
            height={181}
            imageNumber={4}
            imageRatio={0.57}
            notebook={notebook}
          >
            <path
              d="M60.59 935.5h126.32a165 165 0 0 1 165 165v347H209a148.39 148.39 0 0 1-148.41-148.41Z"
              style={{ fill: palette.backgroundLight }}
              transform="matrix(.3527 0 0 .3527 -.58 -309.854)"
            />
          </ClippedImage>

          <foreignObject
            x={20.005}
            y={210.032}
            width={175}
            height={73}
            className="clickable"
          >
            <div
              style={{
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: palette.backgroundGray,
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                height: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.bio,
                  field: "bio",
                  label: "Trajetória",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.bio}
            </div>
          </foreignObject>
        </svg>
      </PaperInside>
    </Paper>
  );
};

export default Page5;
