import { ChangeEventHandler, memo } from "react";
import "./notebook-input-text-area.css";

type Props = {
  inputKey: string;
  width?: number;
  className?: string;
  value?: string;
  invalid?: boolean;
  placeHolder?: string;
  onEnterPressed?: () => void;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
};

const NotebookInputTextArea: React.FC<Props> = ({
  inputKey,
  width,
  className,
  value,
  placeHolder,
  invalid,
  onChange,
  onEnterPressed,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (onEnterPressed) {
        onEnterPressed();
      }
    }
  };

  return (
    <div
      className={`notebook-input-label-container-area`}
      style={{ width: width ? `${width}px` : "100%" }}
    >
      <textarea
        key={inputKey}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        value={value}
        placeholder={placeHolder}
        className={`notebook-input-text-area ${className} ${
          invalid ? "notebook-input-text-area-invalid" : ""
        }`}
      ></textarea>
    </div>
  );
};

export default memo(NotebookInputTextArea);
