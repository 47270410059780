import { memo, MouseEventHandler } from "react";
import { RiArrowGoBackFill } from "react-icons/ri";
import "./go-back-button.css";

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const GoBackButton: React.FC<Props> = (props) => {
  const { onClick } = props;

  return (
    <button className={"go-back-button"} onClick={onClick}>
      <RiArrowGoBackFill className="go-back-button-icon" />
      Voltar
    </button>
  );
};

export default memo(GoBackButton);
