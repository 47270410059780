import SimpleBar from "simplebar-react";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  hidden?: boolean;
};

const ScrollContainer: React.FC<Props> = ({ children, hidden }) => {
  return <SimpleBar hidden={hidden}>{children}</SimpleBar>;
};

export default ScrollContainer;
