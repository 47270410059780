import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { MapFeatures } from "../../../../app/maps";
import {
  getColor,
  getHighContrastColor,
  toPtBrContracted,
} from "../../../../app/utils";
import { selectComparativeMode } from "../../../../features/dashboard/slice";
import { selectSettings } from "../../../../features/settings/slice";
import { Settings } from "../../../../features/settings/type";
import { makeContainer } from "../../../container/container";
import "./map-legend.css";

type Props = {
  map?: MapFeatures;
};

const MapLegendContainer = makeContainer("map-legend-container");
const MapLegendItem = makeContainer("map-legend-item");

const MapLegend: React.FC<Props> = ({ map }) => {
  const settings: Settings = useSelector(selectSettings);
  const comparativeMode: boolean = useSelector(selectComparativeMode);
  const [scale, setScale] = useState<{ label: string; color: string }[]>([]);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1024px)" });
  const isMediumScreen = useMediaQuery({ query: "(max-width: 1386px)" });

  useEffect(() => {
    const createLegend = () => {
      if (map && map.features?.length) {
        const legendKeysDict = new Map<number, string>();
        const legendKeys = [];
        const sampledLegendKeys = [];
        const maxKeys = isSmallScreen ? 4 : isMediumScreen ? 7 : 9;

        for (const item of map.features) {
          const color = getColor(item, comparativeMode, settings);
          legendKeysDict[item.numericalValue] = color;
        }

        for (const [label, color] of Object.entries(legendKeysDict)) {
          legendKeys.push({
            label: toPtBrContracted(Number(label)),
            value: Number(label),
            color: color,
          });
        }

        legendKeys.sort((a, b) => a.value - b.value);

        const numKeys = Math.min(legendKeys.length, maxKeys);
        const step = legendKeys.length / numKeys;

        for (let i = 0; i < legendKeys.length - 1; i += step) {
          sampledLegendKeys.push(legendKeys[Math.ceil(i)]);
        }

        sampledLegendKeys.push(legendKeys[legendKeys.length - 1]);

        setScale(sampledLegendKeys);
      }
    };

    createLegend();
  }, [map, settings, comparativeMode, isSmallScreen, isMediumScreen]);

  return (
    <MapLegendContainer>
      {scale.map((key, index) => (
        <MapLegendItem
          key={`legend-key-${index}-${key.color}`}
          style={{
            backgroundColor: key.color,
            color: getHighContrastColor(key.color),
          }}
        >
          <div>{key.label.split(" ")[0]}</div>
          <div>{key.label.split(" ")[1]}</div>
        </MapLegendItem>
      ))}
    </MapLegendContainer>
  );
};

export default memo(MapLegend);
