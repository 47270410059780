import { memo } from "react";
import "./number-widget-text.css";

type Props = {
  children: string;
  className?: string;
};

const NumberWidgetText: React.FC<Props> = ({ children, className }) => {
  return <div className={`number-widget-text ${className}`}>{children}</div>;
};

export default memo(NumberWidgetText);
