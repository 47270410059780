import { memo } from "react";
import { useDispatch } from "react-redux";
import { ShopPages } from "../../../../app/constants";
import { AppDispatch } from "../../../../app/store";
import { fixDate, fromCurrencyToStr } from "../../../../app/utils";
import { makeContainer } from "../../../../components/container/container";
import { setCurrentPage, setOrderId } from "../../../../features/shop/slice";
import { getPaymentMethod, Order } from "../../../../features/shop/type";
import ShopOrderStatus from "../shop-order-status/shop-order-status";
import "./shop-order.css";

type Props = {
  order: Order;
};

const ShopOrderContainer = makeContainer("shop-order-container");
const ShopOrderDetails = makeContainer("shop-order-details");
const ShopOrderPrice = makeContainer("shop-order-price");
const ShopOrderPaymentMethod = makeContainer("shop-order-payment-method");
const ShopOrderDate = makeContainer("shop-order-date");
const ShopOrderTitleText = makeContainer("shop-order-title-text");

const ShopOrder: React.FC<Props> = ({ order }) => {
  const dispatch: AppDispatch = useDispatch();

  return (
    <>
      <hr className="shop-order-separator " />
      <ShopOrderContainer>
        <ShopOrderDetails>
          <ShopOrderTitleText>
            <div
              onClick={() => {
                dispatch(setOrderId(order.id));
                dispatch(setCurrentPage(ShopPages.ORDER_DETAILS));
              }}
            >{`Pedido #${order.id.split("-").slice(-1)[0].toUpperCase()}`}</div>
          </ShopOrderTitleText>
          <ShopOrderStatus orderStatus={order.status}></ShopOrderStatus>
          <ShopOrderDate>{`${fixDate(order.created)}`}</ShopOrderDate>
        </ShopOrderDetails>
        <ShopOrderPrice>
          <ShopOrderPrice>{fromCurrencyToStr(order.price)}</ShopOrderPrice>
          <ShopOrderPaymentMethod>{`${getPaymentMethod(
            order
          )}`}</ShopOrderPaymentMethod>
        </ShopOrderPrice>
      </ShopOrderContainer>
    </>
  );
};

export default memo(ShopOrder);
