import * as React from "react";
import { useSelector } from "react-redux";
import { validateText } from "../../../../../app/utils";
import { selectPalette } from "../../../../../features/notebook/slice";
import Cellphone from "../../../../../resources/images/notebooks/cellphone";
import Icons from "../../../../../resources/images/notebooks/icons.png";
import SocialMedia from "../../../../../resources/images/notebooks/social-media";
import {
  ClippedImage,
  Paper,
  PaperInside,
  PartyDisplay,
} from "../commons/commons";
import { TemplatePageProps } from "../commons/interfaces";

const Page13: React.FC<TemplatePageProps> = ({
  className,
  notebook,
  triggerImageEdit,
  triggerFieldEdit,
  updateField,
}) => {
  const { palette } = useSelector(selectPalette);

  return (
    <Paper className={className}>
      <PaperInside>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
        >
          <defs>
            <filter id="page13-b" width={210} height={297}>
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
          </defs>

          <g
            style={{
              opacity: 0.13,
            }}
          >
            <path d="M177.42 169.734a16.38 16.38 0 0 0-1.535-.703c2.856-3.116 5.79-5.505 8.86-6.594 5.299-1.877 10.295.702 14.954 7.982 3.513 5.474 6.436 12.778 9.085 21.017q.524 1.61 1.054 3.201v2.783c-.927-2.748-1.827-5.559-2.779-8.229-3.355-9.409-7.139-17.068-11.731-21.358-3.714-3.468-7.515-4.09-11.373-2.54-2.26.889-4.423 2.442-6.535 4.44z" />
            <path d="M172.002 168.016c-.45-.053-.899-.084-1.345-.095 2.46-3.57 5.02-6.746 7.684-9.282 2.972-2.84 6.018-4.698 9.18-4.92 4.95-.326 9.423 3.686 13.51 11.22 3.253 5.993 5.972 13.56 8.431 21.853.12.393.246.762.352 1.145v2.779c-1.086-3.352-2.108-6.827-3.26-10.041-3.84-10.892-8.219-19.419-13.703-22.904-3.893-2.46-7.761-1.838-11.594.77-3.23 2.199-6.303 5.47-9.255 9.475z" />
            <path d="M172.909 159.454c-2.048 2.712-4.037 5.705-5.973 8.853a13.428 13.428 0 0 0-1.3.352c3.029-5.12 6.124-9.922 9.38-13.924 3.086-3.787 6.265-6.728 9.628-8.08 5.621-2.235 10.814.983 15.589 9.387 2.79 4.919 5.21 10.98 7.378 17.87.755 2.42 1.497 4.88 2.245 7.321v2.783c-.843-2.772-1.644-5.622-2.53-8.299-2.88-8.685-6.067-16.327-9.974-21.432q-5.351-6.967-11.243-5.72c-4.712 1.037-9.023 5.359-13.2 10.889z" />
            <path d="M161.645 168.757c.151.32.299.643.443.966 2.406-4.17 4.823-8.312 7.28-12.261 3.699-5.949 7.578-10.892 11.745-13.9 4.304-3.123 8.611-3.632 12.915-.056 4.107 3.415 7.61 9.69 10.618 17.919 1.83 5.02 3.46 10.603 5.182 15.937v-3.06c-.352-1.104-.703-2.168-1.054-3.314-2.108-7.266-4.438-13.822-7.15-19.355-5.488-11.204-11.57-15.308-18.315-11.633-4.09 2.224-7.874 6.633-11.535 11.946-3.492 5.062-6.83 10.845-10.13 16.811z" />
            <path d="m160.478 164.162-.618 1.127c.144.253.281.506.422.763q2.434-4.329 4.876-8.608c3.816-6.675 7.754-12.719 11.978-17.254 3.302-3.549 6.7-5.945 10.262-6.142 4.459-.253 8.545 3.162 12.297 9.708 3.341 5.818 6.12 13.35 8.619 21.692.498 1.668 1.004 3.316 1.51 4.971v-2.793c-.218-.703-.442-1.363-.65-2.077-2.81-9.753-5.972-18.557-9.876-25.015-4.673-7.69-9.7-10.249-15.065-7.874-3.672 1.624-7.115 5.19-10.442 9.645-4.663 6.25-9.005 13.983-13.313 21.857z" />
            <path d="M157.89 162.096c.168.25.35.502.494.755 3.75-6.584 7.509-13.133 11.328-19.4 4.005-6.567 8.263-11.658 12.852-14.37 5.231-3.093 10.283-1.82 15.107 4.918 4.216 5.913 7.596 14.63 10.593 24.559q.79 2.596 1.578 5.196v-2.804c-.113-.351-.236-.65-.351-1.004-2.811-9.877-5.973-18.804-9.88-25.427-4.325-7.304-9.016-10.305-14.054-8.812-3.865 1.142-7.476 4.596-10.972 9.065-4.381 5.6-8.468 12.606-12.508 19.83q-2.101 3.741-4.188 7.494z" />
            <path d="M155.883 159.074c.14.204.277.411.418.622l.404-.703c4.803-8.488 9.56-17.194 14.64-24.414 4.027-5.72 8.176-10.467 12.694-12.385 4.585-1.946 8.995-.495 13.204 5.01 4.093 5.358 7.413 13.4 10.371 22.686.752 2.365 1.476 4.792 2.214 7.189v-2.804c-.113-.351-.232-.664-.352-1.022-2.687-9.251-5.67-17.63-9.331-24.025q-7.333-12.8-15.905-9.096c-3.675 1.58-7.126 5.066-10.47 9.423-4.807 6.267-9.283 14.106-13.734 22.046-1.378 2.488-2.762 4.99-4.153 7.473z" />
            <path d="M154.01 156.541c2.407-4.023 4.807-8.08 7.21-12.135 4.023-6.77 8.053-13.506 12.356-18.888 3.974-4.972 8.081-8.815 12.463-9.711 4.38-.896 8.54 1.503 12.433 7.17 3.444 5.007 6.325 11.893 8.939 19.767.825 2.498 1.612 5.094 2.417 7.645v-2.79c-.102-.309-.207-.604-.302-.924-2.249-7.508-4.705-14.405-7.582-20.131-5.54-11.004-11.661-15.083-18.407-11.732-3.215 1.599-6.275 4.504-9.25 8.18-4.435 5.484-8.588 12.367-12.716 19.376-2.67 4.532-5.333 9.103-8.007 13.618.151.186.299.372.446.555z" />
            <path d="M151.533 153.737c2.46-3.864 4.863-7.884 7.284-11.875 4.335-7.143 8.664-14.307 13.252-20.192 4.107-5.27 8.327-9.51 12.87-10.891 3.89-1.198 7.666-.035 11.316 3.886 3.95 4.24 7.284 10.976 10.25 19.169 1.152 3.18 2.216 6.588 3.323 9.894v-2.804c-.102-.306-.207-.608-.306-.924-2.867-9.153-6.046-17.297-9.918-23.224-4.276-6.545-8.858-9.19-13.724-8.049-3.57.836-6.974 3.542-10.287 7.185-4.725 5.193-9.135 12.076-13.498 19.166-3.71 6.029-7.4 12.149-11.13 18.087.185.186.379.38.568.572z" />
            <path d="m148.536 149.409-.737 1.054.6.453c1.887-2.642 3.75-5.425 5.604-8.242 4.849-7.379 9.67-14.887 14.574-21.97 4.216-6.099 8.646-10.972 13.35-13.607 3.982-2.231 7.959-2.424 11.922.351 4.163 2.92 7.817 8.562 11.028 16.264 1.736 4.163 3.303 8.854 4.947 13.309v-2.773c-1.872-5.68-3.864-10.986-6.103-15.596-4.83-9.967-10.22-15.392-16.309-14.96-3.998.285-7.824 2.924-11.555 6.729-4.656 4.75-9.03 11.095-13.351 17.71-4.656 7.112-9.276 14.388-13.97 21.278z" />
            <path d="m143.667 147.95.565.292a129.016 129.016 0 0 0 8.081-10.368c5.028-7.027 10.006-14.338 15.066-21.196 5.06-6.858 10.35-12.536 16.014-14.834 2.853-1.16 5.71-1.282 8.562-.042 4.15 1.806 7.898 6.324 11.341 12.849 2.4 4.55 4.533 9.925 6.542 15.701v-3.06c-.14-.299-.291-.576-.421-.903-1.346-3.303-2.628-6.809-4.04-9.887-4.98-10.867-10.608-17.064-17.03-17.215-4.034-.085-7.93 2.143-11.746 5.579-4.43 3.988-8.635 9.412-12.778 15.22-5.27 7.378-10.456 14.978-15.75 22.113-1.494 2.017-3.004 3.907-4.54 5.622.039.06.088.095.134.13z" />
            <path d="M138.692 144.789c.28-.211.558-.429.836-.65 5.137-4.146 10.006-10.133 14.837-16.376 5.393-6.97 10.712-14.374 16.267-20.378 4.715-5.087 9.532-9.11 14.62-10.224 5.382-1.177 10.501 1.339 15.336 8.007 3.45 4.757 6.475 11.148 9.24 18.505v-3.06c-.11-.2-.222-.383-.323-.604-1.557-3.398-3.05-7.027-4.677-10.161-4.237-8.137-8.941-12.979-14.11-14.215-3.763-.903-7.466.246-11.13 2.592-4.676 2.99-9.135 7.691-13.492 13.144-5.01 6.272-9.964 12.866-14.974 19.152-4.388 5.505-8.843 10.512-13.509 13.758.362.158.724.327 1.079.51z" />
            <path d="M209.824 116.724c-.773-1.62-1.539-3.254-2.319-4.856-3.903-8.05-8.186-13.867-13-16.7a13.035 13.035 0 0 0-10.54-1.387c-5.572 1.55-10.891 5.892-16.077 11.485-5.122 5.523-10.189 11.447-15.283 17.167-4.694 5.27-9.434 10.14-14.38 13.35-3.31 2.144-6.655 3.462-10.056 3.423-6.443-.07-12.335-5.158-17.704-14.925a1.472 1.472 0 0 1-.172-.573 3.573 3.573 0 0 1 .31.404c3.421 5.941 7.11 10.087 11.084 12.255a12.884 12.884 0 0 0 8.96 1.35c6.71-1.315 13.03-6.905 19.231-13.647 5.622-6.11 11.184-12.599 16.795-18.761 4.38-4.814 8.86-8.82 13.502-11.279 6.067-3.218 12.09-3.256 18.027 2.091 4.132 3.724 7.82 9.651 11.208 17.086a8.18 8.18 0 0 0 .414.741z" />
            <path d="M209.824 110.048c-.351-.618-.702-1.209-1.054-1.855-4.427-8.394-9.258-14.155-14.57-16.689a14.985 14.985 0 0 0-10.189-1.054c-6.52 1.543-12.76 6.447-18.916 12.23-5.112 4.8-10.189 9.838-15.3 14.655-4.702 4.42-9.487 8.039-14.406 10.108-8.566 3.594-16.787 1.054-24.495-10.509a9.978 9.978 0 0 1-1.016-1.862 2.85 2.85 0 0 1 .313.352q6.63 9.967 14.152 11.815c4.121 1.02 8.214.225 12.297-1.756 6.77-3.278 13.246-9.385 19.707-15.632 5.238-5.062 10.456-10.283 15.81-14.359 4.95-3.763 9.96-6.482 15.108-6.805q12.065-.763 22.078 17.823c.151.281.316.499.478.745z" />
            <path d="M209.824 103.37c-1.03-1.466-2.048-2.99-3.088-4.393-4.662-6.278-9.585-9.978-14.756-11.243-4.427-1.075-8.822-.379-13.207 1.37-5.91 2.355-11.658 6.645-17.399 10.998-5.48 4.152-10.958 8.344-16.463 12.223-4.311 3.035-8.707 4.919-13.155 5.692-7.645 1.324-15.009-1.757-21.983-10.808a2.108 2.108 0 0 1-.38-.794c.868.956 1.73 1.954 2.604 2.86 4.321 4.483 8.783 6.84 13.404 7.498 5.783.825 11.464-1.209 17.106-4.374 6.187-3.468 12.238-8.38 18.316-13.025 5.186-3.96 10.389-7.757 15.694-10.343 4.828-2.358 9.69-3.587 14.595-2.853 6.479.97 12.61 5.54 18.343 13.966a5.27 5.27 0 0 0 .352.439z" />
            <path d="M209.824 96.694c-1.756-1.757-3.492-3.661-5.27-5.197-4.096-3.534-8.306-5.48-12.588-6.271-4.92-.907-9.803-.18-14.686 1.405-5.622 1.813-11.166 4.898-16.714 7.916-5.646 3.07-11.281 6.268-16.952 8.987-5.502 2.639-11.057 4.03-16.653 3.686-6.138-.373-12.129-3.025-17.919-8.815a1.268 1.268 0 0 1-.351-.601c.875.703 1.756 1.479 2.62 2.147a28.178 28.178 0 0 0 11.732 5.417 26.038 26.038 0 0 0 13.08-.724 94.036 94.036 0 0 0 12.891-5.136c6.51-3.162 12.979-7.027 19.493-10.171 4.075-1.964 8.196-3.289 12.297-4.61a24.594 24.594 0 0 1 11.464-.77c5.973.914 11.752 3.985 17.342 9.806a1.086 1.086 0 0 0 .193.148z" />
          </g>

          <rect width="100%" height="100%" fill={palette.backgroundLight} />

          <g
            className="cls-9"
            style={{
              opacity: 0.13,
            }}
          >
            <path d="M177.42 169.734a16.38 16.38 0 0 0-1.535-.703c2.856-3.116 5.79-5.505 8.86-6.594 5.299-1.877 10.295.702 14.954 7.982 3.513 5.474 6.436 12.778 9.085 21.017q.524 1.61 1.054 3.201v2.783c-.927-2.748-1.827-5.559-2.779-8.229-3.355-9.409-7.139-17.068-11.731-21.358-3.714-3.468-7.515-4.09-11.373-2.54-2.26.889-4.423 2.442-6.535 4.44z" />
            <path d="M172.002 168.016c-.45-.053-.899-.084-1.345-.095 2.46-3.57 5.02-6.746 7.684-9.282 2.972-2.84 6.018-4.698 9.18-4.92 4.95-.326 9.423 3.686 13.51 11.22 3.253 5.993 5.972 13.56 8.431 21.853.12.393.246.762.352 1.145v2.779c-1.086-3.352-2.108-6.827-3.26-10.041-3.84-10.892-8.219-19.419-13.703-22.904-3.893-2.46-7.761-1.838-11.594.77-3.23 2.199-6.303 5.47-9.255 9.475z" />
            <path d="M172.909 159.454c-2.048 2.712-4.037 5.705-5.973 8.853a13.428 13.428 0 0 0-1.3.352c3.029-5.12 6.124-9.922 9.38-13.924 3.086-3.787 6.265-6.728 9.628-8.08 5.621-2.235 10.814.983 15.589 9.387 2.79 4.919 5.21 10.98 7.378 17.87.755 2.42 1.497 4.88 2.245 7.321v2.783c-.843-2.772-1.644-5.622-2.53-8.299-2.88-8.685-6.067-16.327-9.974-21.432q-5.351-6.967-11.243-5.72c-4.712 1.037-9.023 5.359-13.2 10.889z" />
            <path d="M161.645 168.757c.151.32.299.643.443.966 2.406-4.17 4.823-8.312 7.28-12.261 3.699-5.949 7.578-10.892 11.745-13.9 4.304-3.123 8.611-3.632 12.915-.056 4.107 3.415 7.61 9.69 10.618 17.919 1.83 5.02 3.46 10.603 5.182 15.937v-3.06c-.352-1.104-.703-2.168-1.054-3.314-2.108-7.266-4.438-13.822-7.15-19.355-5.488-11.204-11.57-15.308-18.315-11.633-4.09 2.224-7.874 6.633-11.535 11.946-3.492 5.062-6.83 10.845-10.13 16.811z" />
            <path d="m160.478 164.162-.618 1.127c.144.253.281.506.422.763q2.434-4.329 4.876-8.608c3.816-6.675 7.754-12.719 11.978-17.254 3.302-3.549 6.7-5.945 10.262-6.142 4.459-.253 8.545 3.162 12.297 9.708 3.341 5.818 6.12 13.35 8.619 21.692.498 1.668 1.004 3.316 1.51 4.971v-2.793c-.218-.703-.442-1.363-.65-2.077-2.81-9.753-5.972-18.557-9.876-25.015-4.673-7.69-9.7-10.249-15.065-7.874-3.672 1.624-7.115 5.19-10.442 9.645-4.663 6.25-9.005 13.983-13.313 21.857z" />
            <path d="M157.89 162.096c.168.25.35.502.494.755 3.75-6.584 7.509-13.133 11.328-19.4 4.005-6.567 8.263-11.658 12.852-14.37 5.231-3.093 10.283-1.82 15.107 4.918 4.216 5.913 7.596 14.63 10.593 24.559q.79 2.596 1.578 5.196v-2.804c-.113-.351-.236-.65-.351-1.004-2.811-9.877-5.973-18.804-9.88-25.427-4.325-7.304-9.016-10.305-14.054-8.812-3.865 1.142-7.476 4.596-10.972 9.065-4.381 5.6-8.468 12.606-12.508 19.83q-2.101 3.741-4.188 7.494z" />
            <path d="M155.883 159.074c.14.204.277.411.418.622l.404-.703c4.803-8.488 9.56-17.194 14.64-24.414 4.027-5.72 8.176-10.467 12.694-12.385 4.585-1.946 8.995-.495 13.204 5.01 4.093 5.358 7.413 13.4 10.371 22.686.752 2.365 1.476 4.792 2.214 7.189v-2.804c-.113-.351-.232-.664-.352-1.022-2.687-9.251-5.67-17.63-9.331-24.025q-7.333-12.8-15.905-9.096c-3.675 1.58-7.126 5.066-10.47 9.423-4.807 6.267-9.283 14.106-13.734 22.046-1.378 2.488-2.762 4.99-4.153 7.473z" />
            <path d="M154.01 156.541c2.407-4.023 4.807-8.08 7.21-12.135 4.023-6.77 8.053-13.506 12.356-18.888 3.974-4.972 8.081-8.815 12.463-9.711 4.38-.896 8.54 1.503 12.433 7.17 3.444 5.007 6.325 11.893 8.939 19.767.825 2.498 1.612 5.094 2.417 7.645v-2.79c-.102-.309-.207-.604-.302-.924-2.249-7.508-4.705-14.405-7.582-20.131-5.54-11.004-11.661-15.083-18.407-11.732-3.215 1.599-6.275 4.504-9.25 8.18-4.435 5.484-8.588 12.367-12.716 19.376-2.67 4.532-5.333 9.103-8.007 13.618.151.186.299.372.446.555z" />
            <path d="M151.533 153.737c2.46-3.864 4.863-7.884 7.284-11.875 4.335-7.143 8.664-14.307 13.252-20.192 4.107-5.27 8.327-9.51 12.87-10.891 3.89-1.198 7.666-.035 11.316 3.886 3.95 4.24 7.284 10.976 10.25 19.169 1.152 3.18 2.216 6.588 3.323 9.894v-2.804c-.102-.306-.207-.608-.306-.924-2.867-9.153-6.046-17.297-9.918-23.224-4.276-6.545-8.858-9.19-13.724-8.049-3.57.836-6.974 3.542-10.287 7.185-4.725 5.193-9.135 12.076-13.498 19.166-3.71 6.029-7.4 12.149-11.13 18.087.185.186.379.38.568.572z" />
            <path d="m148.536 149.409-.737 1.054.6.453c1.887-2.642 3.75-5.425 5.604-8.242 4.849-7.379 9.67-14.887 14.574-21.97 4.216-6.099 8.646-10.972 13.35-13.607 3.982-2.231 7.959-2.424 11.922.351 4.163 2.92 7.817 8.562 11.028 16.264 1.736 4.163 3.303 8.854 4.947 13.309v-2.773c-1.872-5.68-3.864-10.986-6.103-15.596-4.83-9.967-10.22-15.392-16.309-14.96-3.998.285-7.824 2.924-11.555 6.729-4.656 4.75-9.03 11.095-13.351 17.71-4.656 7.112-9.276 14.388-13.97 21.278z" />
            <path d="m143.667 147.95.565.292a129.016 129.016 0 0 0 8.081-10.368c5.028-7.027 10.006-14.338 15.066-21.196 5.06-6.858 10.35-12.536 16.014-14.834 2.853-1.16 5.71-1.282 8.562-.042 4.15 1.806 7.898 6.324 11.341 12.849 2.4 4.55 4.533 9.925 6.542 15.701v-3.06c-.14-.299-.291-.576-.421-.903-1.346-3.303-2.628-6.809-4.04-9.887-4.98-10.867-10.608-17.064-17.03-17.215-4.034-.085-7.93 2.143-11.746 5.579-4.43 3.988-8.635 9.412-12.778 15.22-5.27 7.378-10.456 14.978-15.75 22.113-1.494 2.017-3.004 3.907-4.54 5.622.039.06.088.095.134.13z" />
            <path d="M138.692 144.789c.28-.211.558-.429.836-.65 5.137-4.146 10.006-10.133 14.837-16.376 5.393-6.97 10.712-14.374 16.267-20.378 4.715-5.087 9.532-9.11 14.62-10.224 5.382-1.177 10.501 1.339 15.336 8.007 3.45 4.757 6.475 11.148 9.24 18.505v-3.06c-.11-.2-.222-.383-.323-.604-1.557-3.398-3.05-7.027-4.677-10.161-4.237-8.137-8.941-12.979-14.11-14.215-3.763-.903-7.466.246-11.13 2.592-4.676 2.99-9.135 7.691-13.492 13.144-5.01 6.272-9.964 12.866-14.974 19.152-4.388 5.505-8.843 10.512-13.509 13.758.362.158.724.327 1.079.51z" />
            <path d="M209.824 116.724c-.773-1.62-1.539-3.254-2.319-4.856-3.903-8.05-8.186-13.867-13-16.7a13.035 13.035 0 0 0-10.54-1.387c-5.572 1.55-10.891 5.892-16.077 11.485-5.122 5.523-10.189 11.447-15.283 17.167-4.694 5.27-9.434 10.14-14.38 13.35-3.31 2.144-6.655 3.462-10.056 3.423-6.443-.07-12.335-5.158-17.704-14.925a1.472 1.472 0 0 1-.172-.573 3.573 3.573 0 0 1 .31.404c3.421 5.941 7.11 10.087 11.084 12.255a12.884 12.884 0 0 0 8.96 1.35c6.71-1.315 13.03-6.905 19.231-13.647 5.622-6.11 11.184-12.599 16.795-18.761 4.38-4.814 8.86-8.82 13.502-11.279 6.067-3.218 12.09-3.256 18.027 2.091 4.132 3.724 7.82 9.651 11.208 17.086a8.18 8.18 0 0 0 .414.741z" />
            <path d="M209.824 110.048c-.351-.618-.702-1.209-1.054-1.855-4.427-8.394-9.258-14.155-14.57-16.689a14.985 14.985 0 0 0-10.189-1.054c-6.52 1.543-12.76 6.447-18.916 12.23-5.112 4.8-10.189 9.838-15.3 14.655-4.702 4.42-9.487 8.039-14.406 10.108-8.566 3.594-16.787 1.054-24.495-10.509a9.978 9.978 0 0 1-1.016-1.862 2.85 2.85 0 0 1 .313.352q6.63 9.967 14.152 11.815c4.121 1.02 8.214.225 12.297-1.756 6.77-3.278 13.246-9.385 19.707-15.632 5.238-5.062 10.456-10.283 15.81-14.359 4.95-3.763 9.96-6.482 15.108-6.805q12.065-.763 22.078 17.823c.151.281.316.499.478.745z" />
            <path d="M209.824 103.37c-1.03-1.466-2.048-2.99-3.088-4.393-4.662-6.278-9.585-9.978-14.756-11.243-4.427-1.075-8.822-.379-13.207 1.37-5.91 2.355-11.658 6.645-17.399 10.998-5.48 4.152-10.958 8.344-16.463 12.223-4.311 3.035-8.707 4.919-13.155 5.692-7.645 1.324-15.009-1.757-21.983-10.808a2.108 2.108 0 0 1-.38-.794c.868.956 1.73 1.954 2.604 2.86 4.321 4.483 8.783 6.84 13.404 7.498 5.783.825 11.464-1.209 17.106-4.374 6.187-3.468 12.238-8.38 18.316-13.025 5.186-3.96 10.389-7.757 15.694-10.343 4.828-2.358 9.69-3.587 14.595-2.853 6.479.97 12.61 5.54 18.343 13.966a5.27 5.27 0 0 0 .352.439z" />
            <path d="M209.824 96.694c-1.756-1.757-3.492-3.661-5.27-5.197-4.096-3.534-8.306-5.48-12.588-6.271-4.92-.907-9.803-.18-14.686 1.405-5.622 1.813-11.166 4.898-16.714 7.916-5.646 3.07-11.281 6.268-16.952 8.987-5.502 2.639-11.057 4.03-16.653 3.686-6.138-.373-12.129-3.025-17.919-8.815a1.268 1.268 0 0 1-.351-.601c.875.703 1.756 1.479 2.62 2.147a28.178 28.178 0 0 0 11.732 5.417 26.038 26.038 0 0 0 13.08-.724 94.036 94.036 0 0 0 12.891-5.136c6.51-3.162 12.979-7.027 19.493-10.171 4.075-1.964 8.196-3.289 12.297-4.61a24.594 24.594 0 0 1 11.464-.77c5.973.914 11.752 3.985 17.342 9.806a1.086 1.086 0 0 0 .193.148z" />
          </g>

          <foreignObject x={21} y={80} width={75} height={120}>
            <div
              style={{
                width: "100%",
                height: "100%",
                fontSize: "17.0085px",
                fontFamily: "Heavitas",
                color: palette.terciary,
                strokeWidth: 0.35134,
              }}
            >
              <div>MINHAS</div>
              <div>REDES</div>
            </div>
          </foreignObject>

          <foreignObject width="69.776" height="139.085" x="105" y="65">
            <Cellphone screenColor={palette.secondary} />
          </foreignObject>

          <foreignObject width="11.622" height="68.522" x="88" y="132">
            <img
              src={Icons}
              width={"100%"}
              height={"100%"}
              alt="social-media"
            />
          </foreignObject>

          <foreignObject
            x={20.005}
            y={132.7}
            width={65}
            height={12}
            className="clickable"
          >
            <div
              style={{
                fontSize: "6px",
                fontWeight: "700",
                fontFamily: "MontserratAlternates",
                color: palette.terciary,
                strokeWidth: 0.352449,
                width: "100%",
                height: "100%",
                textAlign: "end",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.whatsapp,
                  field: "whatsapp",
                  label: "whatsapp",
                  type: "input",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.whatsapp}
            </div>
          </foreignObject>

          <foreignObject
            x={20.005}
            y={152.5}
            width={65}
            height={12}
            className="clickable"
          >
            <div
              style={{
                fontSize: "6px",
                fontWeight: "700",
                fontFamily: "MontserratAlternates",
                color: palette.terciary,
                strokeWidth: 0.352449,
                width: "100%",
                height: "100%",
                textAlign: "end",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.instagram,
                  field: "instagram",
                  label: "Instagram",
                  type: "input",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.instagram}
            </div>
          </foreignObject>

          <foreignObject
            x={20.005}
            y={171.5}
            width={65}
            height={12}
            className="clickable"
          >
            <div
              style={{
                fontSize: "6px",
                fontWeight: "700",
                fontFamily: "MontserratAlternates",
                color: palette.terciary,
                strokeWidth: 0.352449,
                width: "100%",
                height: "100%",
                textAlign: "end",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.facebook,
                  field: "facebook",
                  label: "Facebook",
                  type: "input",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.facebook}
            </div>
          </foreignObject>

          <foreignObject
            x={20.005}
            y={191.5}
            width={65}
            height={12}
            className="clickable"
          >
            <div
              style={{
                fontSize: "6px",
                fontWeight: "700",
                fontFamily: "MontserratAlternates",
                color: palette.terciary,
                strokeWidth: 0.352449,
                width: "100%",
                height: "100%",
                textAlign: "end",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.youtube,
                  field: "youtube",
                  label: "Twitter/X",
                  type: "input",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.youtube}
            </div>
          </foreignObject>

          <ClippedImage
            id="saphire-page-13"
            triggerImageEdit={triggerImageEdit}
            x={115}
            y={90}
            width={48}
            height={48}
            imageNumber={19}
            imageRatio={1}
            notebook={notebook}
          >
            <path
              d="M134.592 90.394h3.738a23.568 23.568 0 0 1 23.568 23.568v23.29h-23.29a23.568 23.568 0 0 1-23.568-23.567v-3.739a19.552 19.552 0 0 1 19.552-19.552z"
              style={{
                fill: palette.backgroundLight,
                strokeWidth: 0.35134,
              }}
            />
          </ClippedImage>

          <path
            d="M888.89 2690.6h17.51a57.17 57.17 0 0 1 57.17 57.17V2806a3.65 3.65 0 0 1-3.65 3.65h-58.2a57.17 57.17 0 0 1-57.17-57.17v-17.51a44.34 44.34 0 0 1 44.34-44.37z"
            style={{
              fill: palette.backgroundLight,
              filter: "url(#page13-b)",
            }}
            transform="translate(-216.352 -914.153) scale(.35134)"
          />
          <path
            d="M1087.32 3141.89h17.51a57.17 57.17 0 0 1 57.17 57.17v58.2a3.65 3.65 0 0 1-3.65 3.65h-58.2a57.17 57.17 0 0 1-57.17-57.17v-17.51a44.34 44.34 0 0 1 44.34-44.34z"
            style={{
              fill: palette.backgroundLight,
              filter: "url(#page13-b)",
            }}
            transform="translate(-216.352 -914.153) scale(.35134)"
          />
          <path
            d="M209.065.296s-34.29 17.638-83.11 16.078C77.138 14.814 85.433 3.237 50.77 3.279 9.444 3.33.176 11.385.176 11.385V.296Z"
            style={{
              fill: palette.primary,
              strokeWidth: 0.35134,
            }}
            transform="translate(-1, -1)"
          />

          <foreignObject width="80.419" height="58.239" x="115" y="145">
            <SocialMedia />
          </foreignObject>

          <PartyDisplay
            notebook={notebook}
            x={160}
            y={250}
            width={40}
            height={40}
            triggerFieldEdit={triggerFieldEdit}
            updateField={updateField}
          />
        </svg>
      </PaperInside>
    </Paper>
  );
};

export default Page13;
