import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../app/store";
import { validateText } from "../../../../../app/utils";
import {
  makeContainer,
  makeSpanContainer,
} from "../../../../../components/container/container";
import PartyService from "../../../../../features/parties/service";
import {
  getParties,
  selectParties,
} from "../../../../../features/parties/slice";
import { Party } from "../../../../../features/parties/type";
import "./commons.css";
import { ClippedImageProps, PartyDisplayProps } from "./interfaces";

export const Paper = makeContainer("a4-paper");
export const PaperInside = makeContainer("a4-paper-inside");
export const ImagePane = makeContainer("image-pane");
export const FieldPane = makeContainer("field-pane");
export const FieldSpan = makeSpanContainer("field-pane");

export const ClippedImage: React.FC<ClippedImageProps> = ({
  id,
  x,
  y,
  offsetX = 0,
  offsetY = 0,
  width,
  height,
  imageNumber,
  imageRatio,
  triggerImageEdit,
  notebook,
  children,
}) => {
  const imageUrl = notebook.images?.[String(imageNumber)];

  return (
    <>
      {children && (
        <defs>
          <clipPath id={id}>
            {React.cloneElement(children, {
              id: undefined, // Ensure the cloned path doesn't have an id to avoid duplicates
            })}
          </clipPath>
        </defs>
      )}
      {children &&
        React.cloneElement(children, {
          onClick: () => triggerImageEdit(imageNumber, imageRatio),
          className: "clickable",
        })}
      {imageUrl ? (
        <image
          clipPath={`url(#${id})`}
          className="clickable"
          onClick={() => triggerImageEdit(imageNumber, imageRatio)}
          x={x}
          y={y}
          width={width}
          height={height}
          preserveAspectRatio="xMinYMin slice"
          href={`data:image;base64,${imageUrl}`}
        />
      ) : (
        <text
          className="clickable print-hidden"
          onClick={() => triggerImageEdit(imageNumber, imageRatio)}
          x={x + width / 2 + offsetX}
          y={y + height / 2 + offsetY}
          style={{
            fontSize: "6px",
            fontFamily: "MontserratAlternates",
            textAnchor: "middle",
            dominantBaseline: "middle",
          }}
        >
          {"Selecione uma imagem"}
        </text>
      )}
    </>
  );
};

export const PartyDisplay: React.FC<PartyDisplayProps> = ({
  x,
  y,
  width,
  height,
  triggerFieldEdit,
  updateField,
  notebook,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const parties: Party[] = useSelector(selectParties);

  React.useEffect(() => {
    const params = {
      service: new PartyService(),
    };

    dispatch(getParties(params));
  }, [dispatch]);

  return (
    <foreignObject x={x} y={y} width={width} height={height}>
      <div
        className="clickable"
        style={{
          width: "100%",
          height: "100%",
        }}
        onClick={() =>
          triggerFieldEdit({
            currentValue: notebook.party,
            field: "party",
            label: "Partido",
            type: "select",
            options: parties?.map((p) => ({
              value: p.abbrev,
              label: p.abbrev,
            })),
            onUpdateField: updateField,
            validate: validateText,
          })
        }
      >
        {notebook.party ? (
          <img
            style={{
              width: "100%",
              height: "100%",
            }}
            alt="logo do partido"
            src={new PartyService().getPartyLogo(notebook.party)}
          />
        ) : (
          <div
            className="clickable print-hidden"
            style={{
              fontSize: "6px",
              fontFamily: "MontserratAlternates",
              textAnchor: "middle",
              dominantBaseline: "middle",
            }}
          >
            {"Selecione um partido"}
          </div>
        )}
      </div>
    </foreignObject>
  );
};
