import { memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Pages } from "../../../../../app/constants";
import SeeMoreButton from "../../../../../components/buttons/see-more-button/see-more-button";
import { makeContainer } from "../../../../../components/container/container";
import PartyAcronymText from "../../../../../components/text/party-search-acronym-text/party-acronym-text";
import PartyNameText from "../../../../../components/text/party-search-name-text/party-name-text";
import PartyService from "../../../../../features/parties/service";
import { Party } from "../../../../../features/parties/type";
import "./party-card.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  party?: Party;
};

const ImagePane = makeContainer("party-card-image-pane");
const DetailsPane = makeContainer("party-card-details-pane");

const PartyCard: React.FC<Props> = ({ party }) => {
  const service = new PartyService();
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isMapOpener = queryParams.get("map");

  function handleClick() {
    if (!isMapOpener) {
      navigate(`/${Pages.PARTY_PROFILE.replace(":id", party.id)}`);
    } else {
      navigate(`/${Pages.VOTING}?party=${party.abbrev}&partyId=${party.id}`);
    }
  }

  return (
    <div className={"party-card-container"}>
      <ImagePane>
        <img
          className="party-card-logo"
          alt="logo do partido"
          src={service.getPartyLogo(party.abbrev)}
        />
      </ImagePane>
      <DetailsPane>
        <div>
          <PartyAcronymText>{party.abbrev}</PartyAcronymText>
          <PartyNameText>{party.name}</PartyNameText>
        </div>
        <SeeMoreButton onMap={isMapOpener !== null} onClick={handleClick} />
      </DetailsPane>
    </div>
  );
};

export default memo(PartyCard);
