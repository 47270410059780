import React, { memo } from "react";
import { useMediaQuery } from "react-responsive";
import { Status } from "../../../../../app/constants";
import { makeContainer } from "../../../../../components/container/container";
import Loading from "../../../../../components/loading/loading";
import { Table } from "../../../../../components/table/table";
import {
  Candidate,
  CandidateElectionSummary,
} from "../../../../../features/candidates/type";
import PartyService from "../../../../../features/parties/service";
import "./candidate-candidatures.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  status?: Status;
  candidate?: Candidate;
  summary?: CandidateElectionSummary;
  party?: string;
  turn?: string;
  candidatureStatus?: string;
};

type CandidateDetailItemProps = {
  children?: string | JSX.Element | JSX.Element[];
  maxWidth?: number;
};

const CandidateDetailContainer = makeContainer(
  "candidate-candidatures-container"
);

const CandidateDetailItem: React.FC<CandidateDetailItemProps> = ({
  children,
  maxWidth,
}) => {
  return (
    <div className="candidate-candidatures-item" style={{ maxWidth: maxWidth }}>
      {children}
    </div>
  );
};

const CandidatureTurn: React.FC<Props> = ({ summary }) => {
  return <div className="candidate-candidatures-turn">{summary?.turn}</div>;
};

const CandidatureParty: React.FC<Props> = ({ party }) => {
  const service = new PartyService();

  return (
    <div className="candidate-candidatures-party">
      <img
        className="candidate-candidatures-party-image"
        alt="logo do partido"
        src={service.getPartyLogo(party)}
      />
      {party}
    </div>
  );
};

const CandidatureStatus: React.FC<Props> = ({ summary }) => {
  const turnStatus =
    summary.turn === 1
      ? summary?.totalizationsituationfirstturn
      : summary?.totalizationsituationsecondturn;

  const missingResults = ["#NULO#", "#NULO"];
  const candidatureStatus = !missingResults.includes(turnStatus)
    ? turnStatus
    : "-";
  const isElected =
    candidatureStatus.includes("ELEITO") &&
    !candidatureStatus.includes("NÃO") &&
    !candidatureStatus.includes("NAO");

  return (
    <div
      className={
        isElected
          ? "candidate-candidatures-status-elected"
          : "candidate-candidatures-status"
      }
    >
      {candidatureStatus}
    </div>
  );
};

const CandidateCandidatures: React.FC<Props> = ({ status, candidate }) => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1024px)" });

  return (
    <CandidateDetailContainer>
      {status === Status.LOADING && <Loading />}
      {status !== Status.LOADING && isSmallScreen && (
        <Table
          data={candidate?.election_summary ?? []}
          columns={[
            {
              label: "Ano",
              style: {
                width: "40%",
              },
            },
            {
              label: "Resultado",
              style: {
                width: "60%",
              },
            },
          ]}
          cells={[
            ({ row }) => (
              <CandidateDetailItem maxWidth={60}>
                {row?.electionyear}
              </CandidateDetailItem>
            ),
            ({ row }) => <CandidatureStatus summary={row} />,
          ]}
        />
      )}
      {status !== Status.LOADING && !isSmallScreen && (
        <Table
          data={candidate?.election_summary ?? []}
          columns={[
            {
              label: "Ano",
              style: {
                width: "10%",
              },
            },
            {
              label: "Turno",
              style: {
                width: "10%",
              },
            },
            {
              label: "Local",
              style: {
                width: "20%",
              },
            },
            {
              label: "Cargo",
              style: {
                width: "20%",
              },
            },
            {
              label: "Partido",
              style: {
                width: "20%",
              },
            },
            {
              label: "Resultado",
              style: {
                width: "20%",
              },
            },
          ]}
          cells={[
            ({ row }) => (
              <CandidateDetailItem maxWidth={60}>
                {row?.electionyear}
              </CandidateDetailItem>
            ),
            ({ row }) => (
              <CandidateDetailItem maxWidth={60}>
                <CandidatureTurn summary={row} />
              </CandidateDetailItem>
            ),
            ({ row }) => (
              <CandidateDetailItem>{row?.electionplace}</CandidateDetailItem>
            ),
            ({ row }) => (
              <CandidateDetailItem>{row?.office}</CandidateDetailItem>
            ),
            ({ row }) => <CandidatureParty party={row?.partyabbrev} />,
            ({ row }) => <CandidatureStatus summary={row} />,
          ]}
        />
      )}
    </CandidateDetailContainer>
  );
};

export default memo(CandidateCandidatures);
