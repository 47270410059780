import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Status } from "../../app/constants";
import { ActiveFilter } from "../../app/filter";
import { debounceThunk } from "../../app/utils";
import { IPartyService, Party, PartyProfileData } from "./type";

const partiesData: Party[] | null = null;
const partyProfileData: PartyProfileData | null = null;
const partyProfileFilter: ActiveFilter[] | null = null;

// Slice's initial state
const initialState = {
  status: Status.IDLE,
  error: "",
  parties: partiesData,
  partyData: partyProfileData,
  partyDataFilter: partyProfileFilter,
};

type GetPartiesParams = { service: IPartyService };

// get parties thunk
const getPartiesThunk = createAsyncThunk<Party[], GetPartiesParams>(
  "parties/get",
  async (params) => {
    const { service } = params;
    const response = await service.getParties();
    return response;
  }
);

// get party profile data thunk
const getPartiesProfileDataThunk = createAsyncThunk<
  PartyProfileData,
  GetPartiesParams
>("parties/getprofile", async (params, store) => {
  const { service } = params;
  const state: any = store.getState();
  return service.getPartyProfileData(state.parties.partyDataFilter);
});

export const selectParties = (state) => state.parties.parties;
export const selectPartiesStatus = (state) => state.parties.status;
export const selectPartiesProfileData = (state) => state.parties.partyData;
export const selectPartiesDataFilter = (state) => state.parties.partyDataFilter;

// Reducers
const loading = (state, action) => {
  state.status = Status.LOADING;
};

const successfullGetParties = (state, action) => {
  state.parties = action.payload;
  state.error = "";
};

const successfullGetPartiesProfile = (state, action) => {
  state.status = Status.SUCCEEDED;
  state.partyData = action.payload;
  state.error = "";
};

const rejected = (state, action) => {
  state.status = Status.FAILED;
  state.error = action.error.message;
};

// Slice
const partiesSlice = createSlice({
  name: "parties",
  initialState,
  reducers: {
    setFilter(state, action) {
      state.partyDataFilter = action.payload;
    },
    cleanUp(state, action) {
      state.status = Status.IDLE;
      state.partyData = partyProfileData;
      state.partyDataFilter = partyProfileFilter;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPartiesThunk.fulfilled, successfullGetParties)
      .addCase(getPartiesThunk.rejected, rejected)
      .addCase(getPartiesProfileDataThunk.pending, loading)
      .addCase(
        getPartiesProfileDataThunk.fulfilled,
        successfullGetPartiesProfile
      )
      .addCase(getPartiesProfileDataThunk.rejected, rejected);
  },
});

export const { setFilter, cleanUp } = partiesSlice.actions;
export const getPartiesProfileData = debounceThunk(getPartiesProfileDataThunk);
export const getParties = debounceThunk(getPartiesThunk);

export default partiesSlice.reducer;
