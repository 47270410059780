import * as React from "react";
import { useSelector } from "react-redux";
import { validateText } from "../../../../../app/utils";
import { selectPalette } from "../../../../../features/notebook/slice";
import { ClippedImage, Paper, PaperInside } from "../commons/commons";
import { TemplatePageProps } from "../commons/interfaces";

const Page7: React.FC<TemplatePageProps> = ({
  className,
  notebook,
  triggerImageEdit,
  triggerFieldEdit,
  updateField,
}) => {
  const { palette } = useSelector(selectPalette);
  return (
    <Paper className={className}>
      <PaperInside>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
        >
          <defs>
            <filter id="page7-a" width={210} height={297}>
              <feDropShadow
                dx="-3"
                dy="-3"
                stdDeviation="7"
                floodColor="#000000"
                floodOpacity="0.5"
              />
            </filter>
          </defs>

          <ClippedImage
            id="saphire-page-7"
            triggerImageEdit={triggerImageEdit}
            x={0}
            y={0}
            offsetY={-50}
            width={210}
            height={297}
            imageNumber={8}
            imageRatio={0.7}
            notebook={notebook}
          >
            <rect width="100%" height="100%" fill={palette.backgroundLight} />
          </ClippedImage>

          <path
            d="M1562.48 1247.41a228.39 228.39 0 0 1 228.39 228.39v207.56h-189.48A228.39 228.39 0 0 1 1373 1455v-18.07a189.49 189.49 0 0 1 189.49-189.49z"
            style={{
              fill: palette.backgroundLight,
              filter: "url(#page7-a)",
            }}
            transform="matrix(0 -.35302 .35302 0 -393.749 765.981)"
          />

          <path
            d="M181.386 157.268a10.308 10.308 0 0 1 1.963 6.181 7.06 7.06 0 0 1-.42 2.471 6.248 6.248 0 0 1-1.176 2.016 5.507 5.507 0 0 1-1.836 1.363 5.214 5.214 0 0 1-2.432.445 5.412 5.412 0 0 1-3.72-1.649 4.875 4.875 0 0 1-1.413-3.798 6.312 6.312 0 0 1 .523-2.277 3.53 3.53 0 0 1 1.412-1.649 1.486 1.486 0 0 0 .6-.706.826.826 0 0 0-.13-.861 77.616 77.616 0 0 1-.862-.996 22.53 22.53 0 0 0-1.024-1.101 1.324 1.324 0 0 1-.47-.89 1.461 1.461 0 0 1 .209-.942l.995-1.363a1.638 1.638 0 0 1 1.886-.576q3.929 1.628 5.895 4.332zm-12.306 0a10.308 10.308 0 0 1 1.963 6.181 7.149 7.149 0 0 1-.42 2.471 6.192 6.192 0 0 1-1.18 2.016 5.461 5.461 0 0 1-1.832 1.363 5.218 5.218 0 0 1-2.436.445 5.408 5.408 0 0 1-3.717-1.649 4.861 4.861 0 0 1-1.412-3.798 6.312 6.312 0 0 1 .523-2.277 3.53 3.53 0 0 1 1.412-1.649 1.472 1.472 0 0 0 .603-.706.819.819 0 0 0-.13-.861c-.28-.318-.569-.646-.865-.996-.297-.35-.636-.706-1.02-1.101a1.31 1.31 0 0 1-.47-.89 1.44 1.44 0 0 1 .208-.942l.996-1.363a1.638 1.638 0 0 1 1.885-.576q3.929 1.628 5.892 4.332z"
            style={{
              fill: palette.primary,
              strokeWidth: 0.353017,
            }}
          />

          <foreignObject
            x={60.005}
            y={175.032}
            width={125}
            height={80}
            className="clickable"
          >
            <div
              style={{
                fontSize: "9px",
                fontFamily: "MontserratAlternates",
                fontWeight: "700",
                color: palette.backgroundGray,
                textAlign: "center",
                width: "100%",
                height: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.slogan,
                  field: "slogan",
                  label: "Slogan",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.slogan}
            </div>
          </foreignObject>
        </svg>
      </PaperInside>
    </Paper>
  );
};

export default Page7;
