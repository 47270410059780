import { memo } from "react";
import "./login-form-label-text.css";

type Props = {
  children: string;
  className?: string;
};

const LoginSubtitleText: React.FC<Props> = ({ children, className }) => {
  return (
    <span className={`login-form-label-text ${className}`}>{children}</span>
  );
};

export default memo(LoginSubtitleText);
