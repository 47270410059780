import { useDispatch, useSelector } from "react-redux";
import { components } from "react-select";
import { NotebookPalettesOptions } from "../../../../../app/constants";
import { AppDispatch } from "../../../../../app/store";
import NotebookSelectText from "../../../../../components/inputs/notebook-select-text/notebook-select-text";
import {
  selectPalette,
  setPalette,
} from "../../../../../features/notebook/slice";

const PaletteOption = (props) => {
  const { palette } = props.data;

  return (
    <components.Option {...props}>
      <div style={{ display: "flex" }}>
        {Object.keys(palette).map((key, index) => (
          <div
            key={index}
            style={{
              backgroundColor: palette[key],
              width: "20px",
              height: "20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              marginRight: "5px",
              borderRadius: "2px",
            }}
          />
        ))}
      </div>
    </components.Option>
  );
};

const SingleValue = (props) => {
  const { palette } = props.data;

  return (
    <components.SingleValue {...props}>
      <div style={{ display: "flex" }}>
        {Object.keys(palette).map((key, index) => (
          <div
            key={index}
            style={{
              backgroundColor: palette[key],
              width: "15px",
              height: "15px",
              marginRight: "5px",
              borderRadius: "2px",
              padding: "1px",
            }}
          />
        ))}
      </div>
    </components.SingleValue>
  );
};

const PaletteSelect = () => {
  const dispatch: AppDispatch = useDispatch();
  const palette = useSelector(selectPalette);

  return (
    <NotebookSelectText
      inputKey="palette-select"
      label=""
      value={palette}
      onChange={(value) => dispatch(setPalette(value))}
      options={NotebookPalettesOptions}
      optionComponent={PaletteOption}
      singleValueComponent={SingleValue}
      disableClear
    />
  );
};

export default PaletteSelect;
