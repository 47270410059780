import { memo } from "react";
import Select, { ActionMeta, components } from "react-select";
import "./shop-select.css";

type Option = {
  value: any;
  label: any;
};

type Props = {
  label: string;
  className?: string;
  value?: any;
  placeHolder?: string;
  options?: Option[];
  isDisabled?: boolean;
  isLoading?: boolean;
  onChange?: (newValue: any, actionMeta: ActionMeta<any>) => void;
};

type ContainerProps = {
  children?: string | JSX.Element | JSX.Element[];
  className?: string;
};

const ShopSelectContainer: React.FC<ContainerProps> = ({
  children,
  className,
}) => {
  return (
    <div className={`shop-select-outer-container ${className}`}>{children}</div>
  );
};

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className="custom-css-class">Sem Resultados</span>
    </components.NoOptionsMessage>
  );
};

const style = {
  control: (base) => ({
    ...base,
    border: "1px solid #D5DAE1",
    "&:hover": {
      border: "1px solid #D5DAE1",
    },
    boxShadow: "none",
  }),
  option: (base, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...base,
      backgroundColor: isFocused ? "#f1f1f1" : "#ffffff",
      color: "#27262e;",
      "&:active": {
        backgroundColor: "#D5DAE1",
      },
    };
  },
  valueContainer: (base, state) => ({
    ...base,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  }),
  indicatorSeparator: (base, state) => ({
    display: "none",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#556987 !important",
  }),
};

const ShopSelect: React.FC<Props> = ({
  label,
  className,
  value,
  options,
  placeHolder,
  isDisabled,
  isLoading,
  onChange,
}) => {
  return (
    <ShopSelectContainer className={className}>
      <Select
        styles={style}
        components={{ NoOptionsMessage }}
        placeholder={placeHolder}
        classNames={{
          container: (state) => "shop-select-container",
          control: (state) => "shop-select",
          placeholder: (props) => "shop-select-placeholder",
          option: (props) => {
            return props.isSelected
              ? "shop-select-selected-option"
              : "shop-select-option";
          },
          menu: (props) => "shop-select-menu",
        }}
        value={value}
        options={options}
        onChange={onChange}
        isDisabled={isDisabled}
        isLoading={isLoading}
        loadingMessage={() => "Carregando..."}
      />
    </ShopSelectContainer>
  );
};

export default memo(ShopSelect);
