import React, { memo } from "react";
import { Status } from "../../../../app/constants";
import { toPtBr } from "../../../../app/utils";
import { makeContainer } from "../../../../components/container/container";
import Loading from "../../../../components/loading/loading";
import NoData from "../../../../components/no-data/no-data";
import NumberWidgetText from "../../../../components/text/number-widget-text/number-widget-text";
import WidgetTitleText from "../../../../components/text/widget-title-text/widget-title-text";

import "./election-widget.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  title?: string;
  status?: Status;
  value?: number;
};

const WidgetContainer = makeContainer("election-number-widget-container");
const WidgetMarkContainer = makeContainer(
  "election-number-widget-mark-container"
);
const WidgetTitleContainer = makeContainer(
  "election-number-widget-title-container"
);

const NumberWidget: React.FC<Props> = ({ title, status, value }) => {
  const shouldShowNumber =
    !!value && isNaN(value) === false && status === Status.SUCCEEDED;
  const shouldShowNoData =
    status === Status.IDLE || (!value && status === Status.SUCCEEDED);

  return (
    <WidgetContainer>
      <WidgetTitleContainer>
        <WidgetTitleText>{title}</WidgetTitleText>
      </WidgetTitleContainer>
      {shouldShowNumber && (
        <NumberWidgetText className="election-number-widget-value">
          {toPtBr(value)}
        </NumberWidgetText>
      )}
      {status === Status.LOADING && (
        <WidgetMarkContainer>
          <Loading />
        </WidgetMarkContainer>
      )}
      {shouldShowNoData && (
        <WidgetMarkContainer>
          <NoData />
        </WidgetMarkContainer>
      )}
    </WidgetContainer>
  );
};

export default memo(NumberWidget);
