import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pages } from "../../app/constants";
import { AppDispatch } from "../../app/store";
import { makeContainer } from "../../components/container/container";
import Page from "../../components/main-page/page";
import ProfileTitleText from "../../components/text/profile-title-text/profile-title-text";
import TitleText from "../../components/text/title-text/title-text";
import { selectAuthUserData } from "../../features/auth/slice";
import { UserData } from "../../features/auth/type";
import PermissionService from "../../features/permission/service";
import {
  getPermissions,
  selectPermissions,
} from "../../features/permission/slice";
import { PermissionsData } from "../../features/permission/type";
import ProfileOrders from "./components/profile-orders/profile-orders";
import ProfilePlans from "./components/profile-plans/profile-plans";
import ProfileProfile from "./components/profile-profile/profile-profile";
import "./profile.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
};

const PageContainer = makeContainer("profile-page-container");
const ProfileTopContainer = makeContainer("profile-top-container");
const ProfileBottomContainer = makeContainer("profile-bottom-container");

const ProfilePersonalInfoContainer: React.FC<Props> = ({ children }) => {
  return (
    <div className="profile-personal-info-container">
      <ProfileTitleText>Perfil</ProfileTitleText>
      <div className="profile-personal-info-inner-container">{children}</div>
    </div>
  );
};

const ProfileAcquiredPlans: React.FC<Props> = ({ children }) => {
  return (
    <div className="profile-acquired-plans-container">
      <ProfileTitleText>Meus Planos</ProfileTitleText>
      <div className="profile-acquired-plans-inner-container">{children}</div>
    </div>
  );
};

const ProfileOrdersContainer: React.FC<Props> = ({ children }) => {
  return (
    <div className="profile-orders-all-container">
      <ProfileTitleText>Meus Pedidos</ProfileTitleText>
      <div className="profile-orders-all-inner-container">{children}</div>
    </div>
  );
};

const Profile: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const userData: UserData = useSelector(selectAuthUserData);
  const permissions: PermissionsData = useSelector(selectPermissions);

  useEffect(() => {
    dispatch(getPermissions(new PermissionService()));
  }, [dispatch]);

  return (
    <Page title={<TitleText>{"Meus Dados"}</TitleText>} page={Pages.PROFILE}>
      <PageContainer>
        <ProfileTopContainer>
          <ProfilePersonalInfoContainer>
            <ProfileProfile userData={userData} />
          </ProfilePersonalInfoContainer>
          <ProfileAcquiredPlans>
            <ProfilePlans permissions={permissions} />
          </ProfileAcquiredPlans>
        </ProfileTopContainer>
        <ProfileBottomContainer>
          <ProfileOrdersContainer>
            <ProfileOrders />
          </ProfileOrdersContainer>
        </ProfileBottomContainer>
      </PageContainer>
    </Page>
  );
};

export default Profile;
