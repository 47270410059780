import { createSlice } from "@reduxjs/toolkit";
import AlertData, { AlertType } from "./type";

const data: AlertData = {
  message: "",
  type: AlertType.MESSAGE,
};

// Slice's initial state
const initialState = {
  data: data,
};

// Selectors
export const selectAlertData = (state) => state.alert.data;

// Slice
const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setAlert(state, action) {
      state.data.message = action.payload.message;
      state.data.type = action.payload.type;
    },
    resetAlert(state, action) {
      state.data.message = "";
    },
  },
});

export const { setAlert, resetAlert } = alertSlice.actions;
export default alertSlice.reducer;
