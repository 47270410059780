import React, { memo, useEffect, useRef, useState } from "react";
import { BiExitFullscreen, BiFullscreen } from "react-icons/bi";
import { BsFillBarChartFill } from "react-icons/bs";
import { FaLevelDownAlt, FaLevelUpAlt } from "react-icons/fa";
import { FiChevronDown } from "react-icons/fi";
import { HiOutlineBarsArrowUp } from "react-icons/hi2";
import { ImExit } from "react-icons/im";
import { MdFilterCenterFocus, MdLegendToggle } from "react-icons/md";
import { RiZoomInLine, RiZoomOutLine } from "react-icons/ri";
import { TbLetterM, TbLetterZ } from "react-icons/tb";
import { useSelector } from "react-redux";
import Attribute, { AttributeTypes } from "../../../../app/attribute";
import { MapFeatures } from "../../../../app/maps";
import {
  selectAttributes,
  selectComparativeMode,
  selectFullScreenMode,
  selectZoneMode,
} from "../../../../features/dashboard/slice";
import MapButton from "../../../buttons/map-button/map-button";
import { makeContainer } from "../../../container/container";
import PopMenu from "../../../menu/pop-menu";
import "./map-controls.css";
import MapLegend from "./map-legend";
import MapTopDisplay from "./map-top-display";

type Props = {
  map?: MapFeatures;
  onCenterMapBtnClick: () => void;
  onZoomInBtnClick: () => void;
  onZoomOutBtnClick: () => void;
  onComparativeModeBtnClick: () => void;
  onZoneCityToggleBtnClick: () => void;
  onFullScreenBtnClick: () => void;
  onRollUpBtnClick: () => void;
  onDrillDownBtnClick: () => void;
  onAttributeSelected: (att: Attribute) => void;
  handleFeatureSelected: (selected: any) => void;
};

const MapControlsLeftContainer = makeContainer("map-controls-left-container");
const MapControlsRightContainer = makeContainer("map-controls-right-container");
const MapControlsTopRightContainer = makeContainer(
  "map-controls-top-right-container"
);

const createGetOffset = () => {
  let offset = 0;
  return function (): React.CSSProperties {
    const current = offset;
    offset += 32;
    return { bottom: current, transition: "all 0.35s" };
  };
};

const LeftBottomPaneButtons = ({
  onCenterMapBtnClick,
  onZoomInBtnClick,
  onZoomOutBtnClick,
  onComparativeModeBtnClick,
  onZoneCityToggleBtnClick,
  onLegendToogleBtnClick,
}) => {
  const comparativeMode: boolean = useSelector(selectComparativeMode);
  const zoneMode: boolean = useSelector(selectZoneMode);
  const [expandedMenu, setExpandedMenu] = useState(true);
  const getLeftOffset = createGetOffset();

  const mapButtonClassName = expandedMenu
    ? "map-button-visible"
    : "map-button-hidden";

  return (
    <>
      <MapButton
        className={"map-button-visible map-menu-button"}
        style={getLeftOffset()}
        onClick={() => setExpandedMenu(!expandedMenu)}
      >
        <HiOutlineBarsArrowUp className="map-button-icon" />
      </MapButton>
      <MapButton
        className={mapButtonClassName}
        onClick={onLegendToogleBtnClick}
        style={getLeftOffset()}
      >
        <MdLegendToggle className="map-button-icon" />
      </MapButton>

      {onZoneCityToggleBtnClick && (
        <MapButton
          className={mapButtonClassName}
          onClick={onZoneCityToggleBtnClick}
          style={getLeftOffset()}
        >
          {!zoneMode && <TbLetterM className="map-button-icon" />}
          {zoneMode && <TbLetterZ className="map-button-icon" />}
        </MapButton>
      )}

      {onComparativeModeBtnClick && (
        <MapButton
          className={mapButtonClassName}
          onClick={onComparativeModeBtnClick}
          style={getLeftOffset()}
        >
          {!comparativeMode && (
            <BsFillBarChartFill className="map-button-icon" />
          )}
          {comparativeMode && <ImExit className="map-button-icon" />}
        </MapButton>
      )}

      <MapButton
        className={mapButtonClassName}
        onClick={onCenterMapBtnClick}
        style={getLeftOffset()}
      >
        <MdFilterCenterFocus className="map-button-icon" />
      </MapButton>
      <MapButton
        className={mapButtonClassName}
        onClick={onZoomOutBtnClick}
        style={getLeftOffset()}
      >
        <RiZoomOutLine className="map-button-icon" />
      </MapButton>
      <MapButton
        className={mapButtonClassName}
        onClick={onZoomInBtnClick}
        style={getLeftOffset()}
      >
        <RiZoomInLine className="map-button-icon" />
      </MapButton>
    </>
  );
};

const MapControls: React.FC<Props> = ({
  map,
  onCenterMapBtnClick,
  onZoomInBtnClick,
  onZoomOutBtnClick,
  onComparativeModeBtnClick,
  onZoneCityToggleBtnClick,
  onFullScreenBtnClick,
  onRollUpBtnClick,
  onDrillDownBtnClick,
  onAttributeSelected,
  handleFeatureSelected,
}) => {
  const [menuOpened, setMenuOpenened] = useState(false);
  const [legendVisible, setLegendVisible] = useState(false);
  const fullScreenMode: boolean = useSelector(selectFullScreenMode);
  const attributes: Attribute[] = useSelector(selectAttributes);
  const ref = useRef(null);
  const getRightOffset = createGetOffset();

  const MapControlsContainer = makeContainer(
    `map-controls-container ${
      fullScreenMode ? "map-controls-container-fullscreen" : ""
    }`
  );

  useEffect(() => {
    function handleKeyPress(event) {
      if (event.key === "Escape" && fullScreenMode) {
        onFullScreenBtnClick();
      }
    }

    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [fullScreenMode, onFullScreenBtnClick]);

  return (
    <MapControlsContainer>
      <MapTopDisplay map={map} handleFeatureSelected={handleFeatureSelected} />
      <MapControlsLeftContainer>
        <LeftBottomPaneButtons
          onCenterMapBtnClick={onCenterMapBtnClick}
          onZoomInBtnClick={onZoomInBtnClick}
          onZoomOutBtnClick={onZoomOutBtnClick}
          onComparativeModeBtnClick={onComparativeModeBtnClick}
          onZoneCityToggleBtnClick={onZoneCityToggleBtnClick}
          onLegendToogleBtnClick={() => setLegendVisible(!legendVisible)}
        />
      </MapControlsLeftContainer>

      <MapControlsRightContainer>
        <MapButton
          className="map-button-visible"
          onClick={onFullScreenBtnClick}
          style={getRightOffset()}
        >
          {!fullScreenMode && <BiFullscreen className="map-button-icon" />}
          {fullScreenMode && <BiExitFullscreen className="map-button-icon" />}
        </MapButton>
        <MapButton
          className="map-button-visible"
          onClick={onDrillDownBtnClick}
          style={getRightOffset()}
        >
          <FaLevelDownAlt className="map-button-icon" />
        </MapButton>
        <MapButton
          className="map-button-visible"
          onClick={onRollUpBtnClick}
          style={getRightOffset()}
        >
          <FaLevelUpAlt className="map-button-icon" />
        </MapButton>
      </MapControlsRightContainer>

      <MapControlsTopRightContainer>
        <PopMenu
          open={menuOpened}
          entries={attributes
            ?.filter((att) => att.type === AttributeTypes.MAP)
            .map((att) => ({
              label: att.friendly_name,
              key: "",
              onClick: () => onAttributeSelected(att),
            }))}
          containerRef={ref}
          onClose={() => setMenuOpenened(false)}
          searchable={true}
        >
          <MapButton
            ref={ref}
            className="map-button-attributes"
            onClick={() => setMenuOpenened(true)}
          >
            <FiChevronDown className="map-button-icon" />
          </MapButton>
        </PopMenu>
      </MapControlsTopRightContainer>

      {legendVisible && <MapLegend map={map} />}
    </MapControlsContainer>
  );
};

export default memo(MapControls);
