import React, { memo } from "react";
import { makeContainer } from "../../../../components/container/container";
import TargetCheckboxText from "../../../../components/inputs/target-checkbox/target-checkbox";
import ScrollContainer from "../../../../components/scroll/scroll";
import "./priorities-selector.css";

const PrioritiesListContainer = makeContainer(
  "proprities-selector-list-container"
);
const PrioritiesList = makeContainer("proprities-selector-list");
const PrioritiesListItem = makeContainer("proprities-selector-list-item");

export type PriorityItem = {
  id: string;
  name: string;
  alreadySelected?: boolean;
};

type Props = {
  items: PriorityItem[];
  onCheck: (p: PriorityItem) => void;
  onUncheck: (p: PriorityItem) => void;
  getChecked: (p: PriorityItem) => boolean;
  onClick: (p: PriorityItem) => void;
  clickable: boolean;
};

type ItemProps = {
  item: PriorityItem;
  onCheck: (p: PriorityItem) => void;
  onUncheck: (p: PriorityItem) => void;
  getChecked: (p: PriorityItem) => boolean;
  onClick: (p: PriorityItem) => void;
  clickable: boolean;
};

export const PrioritySelectorItem: React.FC<ItemProps> = (props) => {
  const { item, onCheck, onUncheck, getChecked, onClick, clickable } = props;

  const onChange = (e) => {
    e.target.checked ? onCheck(item) : onUncheck(item);
  };

  const onTextClick = () => {
    onClick(item);
  };

  return (
    <TargetCheckboxText
      checked={item.alreadySelected ? true : getChecked(item)}
      onChange={onChange}
      onClick={onTextClick}
      disabled={item.alreadySelected}
      clickable={clickable}
    >
      {item.name}
    </TargetCheckboxText>
  );
};

export const PrioritiesSelector: React.FC<Props> = (props) => {
  const { items, onCheck, onUncheck, getChecked, onClick, clickable } = props;

  return (
    <PrioritiesListContainer>
      <ScrollContainer>
        <PrioritiesList>
          {items.map((item, index) => (
            <PrioritiesListItem key={`priority-item-${index}`}>
              <PrioritySelectorItem
                item={item}
                onCheck={onCheck}
                onUncheck={onUncheck}
                getChecked={getChecked}
                onClick={onClick}
                clickable={clickable}
              />
            </PrioritiesListItem>
          ))}
        </PrioritiesList>
      </ScrollContainer>
    </PrioritiesListContainer>
  );
};

export default memo(PrioritiesSelector);
