import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pages } from "../../../app/constants";
import { AppDispatch } from "../../../app/store";
import { makeContainer } from "../../../components/container/container";
import SearchInput from "../../../components/inputs/search-input/search-input";
import Page from "../../../components/main-page/page";
import NoData from "../../../components/no-data/no-data";
import ScrollContainer from "../../../components/scroll/scroll";
import TitleText from "../../../components/text/title-text/title-text";
import PartyService from "../../../features/parties/service";
import { getParties, selectParties } from "../../../features/parties/slice";
import { Party } from "../../../features/parties/type";
import PartyCard from "./components/party-card/party-card";
import "./search-party.css";

type PropsSearchContainer = {
  onChange?: React.Dispatch<React.SetStateAction<string>>;
};

const PageContainer = makeContainer("parties-search-page-container");
const BottomSearchContainer = makeContainer("parties-bottom-search-container");
const SearchResultContainer = makeContainer("parties-search-result-container");

const TopSearchContainer: React.FC<PropsSearchContainer> = memo(
  ({ onChange }) => {
    const [search, setSearch] = useState("");
    const [sendTimeout, setSendTimeout] = useState(null);

    const handleChange = (e) => {
      setSearch(e.target.value);
      clearTimeout(sendTimeout);
      setSendTimeout(
        setTimeout(() => onChange(e.target.value.toLowerCase()), 1000)
      );
    };

    return (
      <div className="parties-top-search-container">
        <SearchInput
          placeHolder="Buscar partidos"
          value={search}
          onChange={handleChange}
        />
      </div>
    );
  }
);

const SearchParty: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const parties: Party[] = useSelector(selectParties);
  const [search, setSearch] = useState("");
  const [filteredParties, setFilteredParties] = useState([]);

  useEffect(() => {
    const params = {
      service: new PartyService(),
    };

    dispatch(getParties(params));
  }, [dispatch]);

  useEffect(() => {
    const displayPartySearchResults = () => {
      const filtered = parties?.filter(
        (party) =>
          party.name.toLowerCase().includes(search) ||
          party.abbrev.toLowerCase().includes(search)
      );

      filtered?.sort((a, b) => a.name.localeCompare(b.name));
      setFilteredParties(filtered);
    };

    displayPartySearchResults();
  }, [parties, search]);

  const hasMatches = filteredParties?.length > 0;

  return (
    <Page
      title={<TitleText>{"Buscar Partido"}</TitleText>}
      page={Pages.SEARCH_PARTY}
    >
      <PageContainer>
        <TopSearchContainer onChange={setSearch} />
        <BottomSearchContainer>
          <ScrollContainer>
            <SearchResultContainer>
              <>
                {filteredParties?.map((party, index) => (
                  <PartyCard key={`${party.id}-${index}`} party={party} />
                ))}
              </>
              {!hasMatches && <NoData />}
            </SearchResultContainer>
          </ScrollContainer>
        </BottomSearchContainer>
      </PageContainer>
    </Page>
  );
};

export default SearchParty;
