import { ChangeEventHandler, memo } from "react";
import "./login-checkbox.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  checked?: boolean;
  type?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

const LoginCheckboxText: React.FC<Props> = ({
  children,
  checked,
  onChange,
}) => {
  return (
    <div className={`login-checkbox`}>
      <input
        className={`login-checkbox-input`}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      {children}
    </div>
  );
};

export default memo(LoginCheckboxText);
