import { memo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMouseLeaveAlerter, useOutsideAlerter } from "../../../app/hooks";
import { AppDispatch } from "../../../app/store";
import { selectMenuData, setExpanded } from "../../../features/menu/slice";
import MenuData from "../../../features/menu/type";
import RetreatButton from "../../buttons/retreat-button/retreat-button";
import Menu from "../menu/menu";

import "./side-bar.css";

const Sidebar: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const menuData: MenuData = useSelector(selectMenuData);
  const wrapperRef = useRef(null);

  const close = () => {
    dispatch(setExpanded(false));
  };

  const toogle = () => {
    dispatch(setExpanded(!menuData.isMenuExpanded));
  };

  useOutsideAlerter(wrapperRef, close);
  useMouseLeaveAlerter(wrapperRef, close);

  return (
    <div
      ref={wrapperRef}
      className={menuData.isMenuExpanded ? "sidebar" : "contracted-sidebar"}
    >
      <RetreatButton expanded={menuData.isMenuExpanded} onClick={toogle} />
      <Menu menuData={menuData} />
    </div>
  );
};

export default memo(Sidebar);
