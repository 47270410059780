import { useDispatch, useSelector } from "react-redux";
import { TargetPages } from "../../../app/constants";
import { AppDispatch } from "../../../app/store";
import TargetFormButton from "../../../components/buttons/target-form-button/target-form-button";
import { makeContainer } from "../../../components/container/container";
import TargetSelect from "../../../components/inputs/target-select/target-select";
import TargetFormContainer from "../../../components/target-page/target-form-container";
import { selectConstants } from "../../../features/constants/slice";
import { Constants } from "../../../features/constants/type";
import {
  selectCreateTargetRequest,
  setCreateTargetRequest,
  setCurrentPage,
} from "../../../features/target/slice";
import { CreateTargetRequest } from "../../../features/target/type";
import "./candidate-details-page.css";

const CandidateInfoForm = makeContainer("candidate-details-form");
const CandidateInfoGroup = makeContainer("candidate-details-group");
const CandidateInfoLabel = makeContainer("candidate-info-label");

const toOptions = (values: string[]) => {
  return values.map((v) => ({
    label: v,
    value: v,
  }));
};

const yearOptions = () => {
  const years = [];
  for (let year = 1920; year <= 2020; year++) {
    years.push({
      label: year.toString(),
      value: year.toString(),
    });
  }

  return years;
};

export const CandidateDetailsPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const createTargetRequest: CreateTargetRequest = useSelector(
    selectCreateTargetRequest
  );
  const constants: Constants = useSelector(selectConstants);

  const goToNext = () => {
    dispatch(setCurrentPage(TargetPages.PRIMARY_PRIORITIES_SELECTION));
  };

  const setValue = (field) => (e) => {
    const newRequest = { ...createTargetRequest };
    newRequest[field] = e.value;
    dispatch(setCreateTargetRequest(newRequest));
  };

  const getValue = (field) => {
    return createTargetRequest
      ? {
          label: createTargetRequest[field],
          options: createTargetRequest[field],
        }
      : null;
  };

  return (
    <TargetFormContainer title="Informe seus dados" step={4}>
      <CandidateInfoForm>
        <CandidateInfoGroup>
          <CandidateInfoLabel>Sexo</CandidateInfoLabel>
          <TargetSelect
            label=""
            placeHolder="Selecione o sexo"
            value={getValue("sex")}
            options={toOptions(constants.candidate_genders)}
            onChange={setValue("sex")}
          />

          <CandidateInfoLabel>Ano de Nascimento</CandidateInfoLabel>
          <TargetSelect
            label=""
            placeHolder="Selecione o ano de nascimento"
            value={getValue("age_group")}
            options={yearOptions()}
            onChange={setValue("age_group")}
          />

          <CandidateInfoLabel>Escolaridade</CandidateInfoLabel>
          <TargetSelect
            label=""
            placeHolder="Selecione a escolaridade"
            value={getValue("school_level")}
            options={toOptions(constants.school_levels)}
            onChange={setValue("school_level")}
          />

          <CandidateInfoLabel>Raça</CandidateInfoLabel>
          <TargetSelect
            label=""
            placeHolder="Selecione a raça"
            value={getValue("race")}
            options={toOptions(constants.races)}
            onChange={setValue("race")}
          />
        </CandidateInfoGroup>

        <TargetFormButton selected onClick={goToNext}>
          Próximo Passo
        </TargetFormButton>
      </CandidateInfoForm>
    </TargetFormContainer>
  );
};

export default CandidateDetailsPage;
