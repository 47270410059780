import { memo, MouseEventHandler } from "react";
import "./shop-button.css";

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  selected?: boolean;
  children: string;
};

const ShopButton: React.FC<Props> = (props) => {
  const { onClick, selected, children } = props;

  return (
    <button
      className={selected ? "shop-button" : "unselected-shop-button"}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default memo(ShopButton);
