import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BRAZIL,
  ElectionsFilterConsts,
  PRESIDENTIAL,
  Status,
} from "../../../app/constants";
import {
  ActiveFilter,
  add,
  create,
  createName,
  createValue,
  get,
  getValue,
  hasFilter,
  remove,
  removeAll,
  replace,
} from "../../../app/filter";
import { AppDispatch } from "../../../app/store";
import {
  addBrazil,
  getAvailableStates,
  getAvaliableOfficesForYear,
  getAvaliableYearsForState,
} from "../../../app/utils";
import DashSelect from "../../../components/inputs/dash-select/dash-select";
import ConstantsService from "../../../features/constants/service";
import {
  getConstants,
  selectConstants,
  selectConstantsStatus,
} from "../../../features/constants/slice";
import { Constants } from "../../../features/constants/type";
import ElectionsService from "../../../features/elections/service";
import {
  cleanUp,
  getElections,
  getElectionsResults,
  selectElections,
  selectElectionsFilter,
  selectElectionsStatus,
  setFilter,
} from "../../../features/elections/slice";
import { Election } from "../../../features/elections/type";
import PermissionService from "../../../features/permission/service";
import {
  getPermissions,
  selectPermissions,
  selectPermissionsStatus,
} from "../../../features/permission/slice";
import { PermissionsData } from "../../../features/permission/type";

const ElectionFilterWithData: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const constants: Constants = useSelector(selectConstants);
  const constantsStatus: Constants = useSelector(selectConstantsStatus);
  const elections: Election[] = useSelector(selectElections);
  const electionStatus: Status = useSelector(selectElectionsStatus);
  const permissions: PermissionsData = useSelector(selectPermissions);
  const permissionsStatus: Status = useSelector(selectPermissionsStatus);
  const filters: ActiveFilter[] = useSelector(selectElectionsFilter);
  const isLoading = [constantsStatus, permissionsStatus].includes(
    Status.LOADING
  );
  const isElectionLoading = electionStatus === Status.LOADING;

  const [turns, setTurns] = useState([]);
  const [offices, setOffices] = useState([]);
  const [years, setYears] = useState([]);
  const [states, setStates] = useState([]);
  const [electionsOptions, setElectionOptions] = useState([]);

  // Effects
  useEffect(() => {
    const doInitialSetup = () => {
      dispatch(getConstants({ service: new ConstantsService() }));
      dispatch(getPermissions(new PermissionService()));
      setFilter([]);
    };

    doInitialSetup();
  }, [dispatch]);

  useEffect(() => {
    const filterStates = () => {
      if (constants && permissions) {
        setStates(addBrazil(getAvailableStates(constants, permissions)));
      }
    };

    filterStates();
  }, [dispatch, constants, permissions]);

  useEffect(() => {
    const setupElectionOptions = () => {
      if (elections) {
        const sortedElectionOptions = [...elections];
        sortedElectionOptions.sort((e1, e2) =>
          e1.place < e2.place ? -1 : e1.place > e2.place ? 1 : 0
        );
        setElectionOptions(
          sortedElectionOptions.map((election) =>
            createValue(election.place, election.election_id)
          )
        );
      }
    };

    setupElectionOptions();
  }, [elections]);

  useEffect(() => {
    return () => {
      dispatch(cleanUp(null));
    };
  }, [dispatch]);

  //Events
  const onStateChange = (stateSelection) => {
    // Creating new state filter
    const stateFilter = create(
      createName("estado", ElectionsFilterConsts.STATE),
      stateSelection
    );

    // Setting up election filter state
    const newFilters = add(removeAll(filters), stateFilter);
    dispatch(setFilter(newFilters));

    // Filling the years options available for that state
    const state = getValue(newFilters, ElectionsFilterConsts.STATE);
    const filteredYears = getAvaliableYearsForState(
      constants,
      permissions,
      state,
      false
    );

    setYears(filteredYears);
    setTurns([]);
    setOffices([]);
    setElectionOptions([]);
  };

  const onYearChange = (yearSelection) => {
    // Creating new state filter
    const yearFilter = create(
      createName("ano", ElectionsFilterConsts.YEAR),
      yearSelection
    );

    const cleanedUpFilters = remove(
      remove(
        remove(filters, ElectionsFilterConsts.TURN),
        ElectionsFilterConsts.OFFICE
      ),
      ElectionsFilterConsts.ELECTION
    );

    // Setting up election filter state
    const newFilters = replace(cleanedUpFilters, yearFilter);
    dispatch(setFilter(newFilters));

    // Get state
    const stateLabel = get(newFilters, ElectionsFilterConsts.STATE).label;
    const stateValue = get(newFilters, ElectionsFilterConsts.STATE).value;
    const year = parseInt(getValue(newFilters, ElectionsFilterConsts.YEAR));

    // Filling turns and offices options
    setTurns(constants?.turns);
    const filteredOffices = getAvaliableOfficesForYear(
      constants,
      stateLabel,
      year
    );
    setOffices(filteredOffices);

    //Getting elections
    if (stateLabel === BRAZIL) {
      setElectionOptions([
        {
          label: PRESIDENTIAL,
          value: null,
        },
      ]);
    } else {
      dispatch(
        getElections({
          data: {
            state: stateValue,
            year: year,
          },
          service: new ElectionsService(),
        })
      );
    }
  };

  const onTurnChange = (turnSelection) => {
    // Creating new state filter
    const turnFilter = create(
      createName("turn", ElectionsFilterConsts.TURN),
      turnSelection
    );

    const cleanedUpFilters = remove(
      remove(filters, ElectionsFilterConsts.OFFICE),
      ElectionsFilterConsts.ELECTION
    );

    const newFilters = replace(cleanedUpFilters, turnFilter);
    dispatch(setFilter(newFilters));
  };

  const onOfficeChange = (officeSelection) => {
    // Creating new state filter
    const officeFilter = create(
      createName("office", ElectionsFilterConsts.OFFICE),
      officeSelection
    );

    const cleanedUpFilters = remove(filters, ElectionsFilterConsts.ELECTION);
    const newFilters = replace(cleanedUpFilters, officeFilter);
    dispatch(setFilter(newFilters));
  };

  const onElectionChange = (electionSelection) => {
    // Creating new state filter
    const electionFilter = create(
      createName("election", ElectionsFilterConsts.ELECTION),
      electionSelection
    );

    const newFilters = replace(filters, electionFilter);
    dispatch(setFilter(newFilters));
    dispatch(
      getElectionsResults({
        filter: newFilters,
        service: new ElectionsService(),
      })
    );
  };

  return (
    <div className={"elections-top-left-pane"}>
      <DashSelect
        label="Estado"
        className="filter-select-state"
        placeHolder="Selecione o estado"
        options={states}
        value={get(filters, ElectionsFilterConsts.STATE)}
        onChange={onStateChange}
        isLoading={isLoading}
      />
      <DashSelect
        label="Ano"
        className="filter-select-year"
        placeHolder="Selecione o ano"
        options={years}
        value={get(filters, ElectionsFilterConsts.YEAR)}
        onChange={onYearChange}
        isDisabled={!hasFilter(filters, ElectionsFilterConsts.STATE)}
      />
      <DashSelect
        label="Turno"
        className="filter-select-turn"
        placeHolder="Selecione o turno"
        options={turns}
        value={get(filters, ElectionsFilterConsts.TURN)}
        onChange={onTurnChange}
        isDisabled={!hasFilter(filters, ElectionsFilterConsts.YEAR)}
      />
      <DashSelect
        label="Cargo"
        className="filter-select-office"
        placeHolder="Selecione o cargo"
        options={offices}
        value={get(filters, ElectionsFilterConsts.OFFICE)}
        onChange={onOfficeChange}
        isDisabled={!hasFilter(filters, ElectionsFilterConsts.TURN)}
      />
      <DashSelect
        label="Eleição"
        className="filter-select-local"
        placeHolder="Selecione a eleição"
        options={electionsOptions}
        value={get(filters, ElectionsFilterConsts.ELECTION)}
        onChange={onElectionChange}
        isDisabled={!hasFilter(filters, ElectionsFilterConsts.OFFICE)}
        isLoading={isElectionLoading}
      />
    </div>
  );
};

export default memo(ElectionFilterWithData);
