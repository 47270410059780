import React, { memo } from "react";
import {
  COMPLETED,
  EXPIRED,
  IN_PROGRESS,
  WAITING_PAYMENT,
} from "../../../../app/constants";
import "./shop-order-status.css";

type Props = {
  orderStatus: string;
};

const OrderStatusConfig = {
  [WAITING_PAYMENT]: {
    class: "waiting-payment-status-text",
    text: "Aguardando o pagamento",
  },
  [IN_PROGRESS]: {
    class: "in-progress-status-text",
    text: "Em progresso",
  },
  [COMPLETED]: {
    class: "completed-status-text",
    text: "Finalizado",
  },
  [EXPIRED]: {
    class: "expired-status-text",
    text: "Expirado",
  },
};

const ShopOrderStatus: React.FC<Props> = ({ orderStatus }) => {
  const config = OrderStatusConfig[orderStatus];
  return <div className={config?.class}>{config?.text}</div>;
};

export default memo(ShopOrderStatus);
