import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShopPages, Status } from "../../../app/constants";
import { AppDispatch } from "../../../app/store";
import ShopActionButton from "../../../components/buttons/shop-action-button/shop-action-button";
import ShopTextButton from "../../../components/buttons/shop-text-button/shop-text-button";
import ShopHeading, {
  Variant,
} from "../../../components/text/shop-heading/shop-heading";
import ShopService from "../../../features/shop/service";
import {
  getCandidate,
  selectCandidateProfileData,
  selectGetCandidateStatus,
  selectSelectedCandidate,
  selectSelectedCandidature,
  setCurrentPage,
  setGetCandidateStatus,
  setSelectedCandidature,
} from "../../../features/shop/slice";
import { ShopCandidateSearchItemDetails } from "../components/shop-candidate-search-result-item/shop-candidate-search-result-item";
import ShopCandidatureSearchResult from "../components/shop-candidature-search-result/shop-candidature-search-result";
import ShopPageContainer from "../components/shop-page-container/shop-page-container";
import ShopSpacer from "../components/shop-spacer/shop-spacer";

const ReportCandidatureSelect: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const status: Status = useSelector(selectGetCandidateStatus);
  const selectedCandidate = useSelector(selectSelectedCandidate);
  const selectedCandidature = useSelector(selectSelectedCandidature);
  const candidate = useSelector(selectCandidateProfileData);

  useEffect(() => {
    dispatch(
      getCandidate({
        id: selectedCandidate,
        service: new ShopService(),
      })
    );

    return () => {
      dispatch(setGetCandidateStatus(Status.LOADING));
    };
  }, [dispatch, selectedCandidate]);

  return (
    <ShopPageContainer>
      <ShopHeading variant={Variant.H1}>
        Siga nosso passo a passo para escolher o seu Relatório:
      </ShopHeading>
      <ShopSpacer space={10} />

      <ShopHeading variant={Variant.H2}>
        Selecione uma única candidatura:
      </ShopHeading>

      <ShopSpacer space={15} />
      {status !== Status.LOADING && (
        <ShopCandidateSearchItemDetails candidate={candidate} />
      )}
      {status === Status.LOADING && <ShopSpacer space={44} />}
      <ShopSpacer space={15} />

      <ShopCandidatureSearchResult
        status={status}
        candidate={candidate}
        selectedCandidature={selectedCandidature}
        setSelectedCandidature={(candidature) =>
          dispatch(setSelectedCandidature(candidature))
        }
      />
      <ShopSpacer space={10} />
      <ShopActionButton
        disable={selectedCandidature === null}
        onClick={() => dispatch(setCurrentPage(ShopPages.REPORT_TYPE))}
      >
        Prosseguir para o tipo de relatório
      </ShopActionButton>
      <ShopSpacer space={10} />
      <ShopTextButton
        onClick={() => dispatch(setCurrentPage(ShopPages.REPORT_CANDIDATE))}
      >
        {"Retornar ao anterior"}
      </ShopTextButton>
      <ShopTextButton
        onClick={() => dispatch(setCurrentPage(ShopPages.PRODUCT_SELECT))}
      >
        {"Cancelar"}
      </ShopTextButton>
    </ShopPageContainer>
  );
};

export default ReportCandidatureSelect;
