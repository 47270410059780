import { ChangeEventHandler, memo } from "react";
import "./profile-input.css";

type Props = {
  className?: string;
  value?: string;
  type?: string;
  placeHolder?: string;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

const ProfileInput: React.FC<Props> = ({
  className,
  value,
  type,
  placeHolder,
  disabled,
  onChange,
}) => {
  return (
    <input
      disabled={disabled}
      value={value}
      type={type}
      placeholder={placeHolder}
      className={`profile-input ${className}`}
      onChange={onChange}
    ></input>
  );
};

export default memo(ProfileInput);
