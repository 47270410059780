import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Pages, Status } from "../../../app/constants";
import { AppDispatch } from "../../../app/store";
import { makeContainer } from "../../../components/container/container";
import SearchInput from "../../../components/inputs/search-input/search-input";
import Loading from "../../../components/loading/loading";
import Page from "../../../components/main-page/page";
import NoData from "../../../components/no-data/no-data";
import ScrollContainer from "../../../components/scroll/scroll";
import CandidateSearchResultHeaderText from "../../../components/text/candidate-search-result-header-text/candidate-search-result-header-text";
import TitleText from "../../../components/text/title-text/title-text";
import CandidateService from "../../../features/candidates/service";
import {
  searchCandidates,
  selectCandidates,
  selectCandidatesStatus,
} from "../../../features/candidates/slice";
import { CandidateSearch } from "../../../features/candidates/type";
import CandidateSearchItem from "./components/candidate-search-item/candidate-search-item";
import "./search-candidates.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
};

type PropsSearchContainer = {
  onChange?: React.Dispatch<React.SetStateAction<string>>;
};

const PageContainer = makeContainer("candidates-search-page-container");
const BottomSearchContainer = makeContainer(
  "candidates-bottom-search-container"
);
const SearchResultContainer = makeContainer(
  "candidates-search-result-container"
);
const SearchResultItemsList = makeContainer(
  "candidates-search-result-items-list"
);

const TopSearchContainer: React.FC<PropsSearchContainer> = ({ onChange }) => {
  const [search, setSearch] = useState("");
  const [sendTimeout, setSendTimeout] = useState(null);

  const handleChange = (e) => {
    setSearch(e.target.value);
    clearTimeout(sendTimeout);
    setSendTimeout(
      setTimeout(() => onChange(e.target.value.toUpperCase()), 800)
    );
  };

  return (
    <div className="candidates-top-search-container">
      <SearchInput
        placeHolder="Buscar candidatos (nome e cpf)"
        value={search}
        onChange={handleChange}
      />
    </div>
  );
};

const SearchResultContainerHeader: React.FC<Props> = ({ children }) => {
  return (
    <div className="candidates-search-result-container-header">
      <CandidateSearchResultHeaderText className="candidate-search-header-candidate">
        {"Candidato"}
      </CandidateSearchResultHeaderText>
      <CandidateSearchResultHeaderText className="candidate-search-header-elections">
        {"Pleitos"}
      </CandidateSearchResultHeaderText>
      <CandidateSearchResultHeaderText className="candidate-search-header-wins">
        {"Vitórias"}
      </CandidateSearchResultHeaderText>
      <CandidateSearchResultHeaderText className="candidate-search-header-actions">
        {""}
      </CandidateSearchResultHeaderText>
    </div>
  );
};

const SearchCandidate: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const candidates: CandidateSearch[] = useSelector(selectCandidates);
  const status: Status = useSelector(selectCandidatesStatus);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchCandidates = () => {
      dispatch(
        searchCandidates({
          search: search,
          service: new CandidateService(),
        })
      );
    };

    fetchCandidates();
  }, [dispatch, search]);

  const isLoading = status === Status.LOADING;
  const isEmpty = (!search || candidates?.length === 0) && !isLoading;
  const showList = !isLoading && !isEmpty;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isMapOpener = queryParams.get("map");

  return (
    <Page
      title={<TitleText>{"Buscar Candidato"}</TitleText>}
      page={isMapOpener ? Pages.CANDIDATE_VOTING : Pages.SEARCH_CANDIDATE}
    >
      <PageContainer>
        <TopSearchContainer onChange={setSearch} />
        <BottomSearchContainer>
          {showList && (
            <>
              <SearchResultContainerHeader />
              <SearchResultItemsList>
                <ScrollContainer>
                  <SearchResultContainer>
                    {!isLoading && (
                      <>
                        {candidates?.map((candidate) => (
                          <CandidateSearchItem
                            key={candidate.id}
                            candidate={candidate}
                          />
                        ))}
                      </>
                    )}
                  </SearchResultContainer>
                </ScrollContainer>
              </SearchResultItemsList>
            </>
          )}
          {isLoading && <Loading />}
          {isEmpty && <NoData />}
        </BottomSearchContainer>
      </PageContainer>
    </Page>
  );
};

export default SearchCandidate;
