import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { Pages } from "../../app/constants";
import { NotebookPageComponents } from "../../app/pages";
import { selectIsLogged } from "../../features/auth/slice";
import { makeContainer } from "../container/container";
import { NotebookMenu } from "./menu";
import "./page.css";

type PageProps = {
  title?: string | JSX.Element | JSX.Element[];
  page?: Pages;
  children?: JSX.Element;
};

const PageContainer = makeContainer("notebook-page-container");
const Main = makeContainer("notebook-main");

type ProtectedRouteProps = {
  isAuthenticated: boolean;
  authenticationPath: string;
  outlet: JSX.Element;
};

function ProtectedRoute({
  isAuthenticated,
  authenticationPath,
  outlet,
}: ProtectedRouteProps) {
  if (isAuthenticated) {
    return outlet;
  } else {
    return <Navigate to={{ pathname: authenticationPath }} />;
  }
}

function ProtectedPage(defaultProtectedRouteProps) {
  return <ProtectedRoute {...defaultProtectedRouteProps} outlet={<Outlet />} />;
}

export const NotebookPage: React.FC<PageProps> = ({
  children,
  title,
  page,
}) => {
  const isLogged = useSelector(selectIsLogged);
  const protetecedProps: Omit<ProtectedRouteProps, "outlet"> = {
    isAuthenticated: isLogged,
    authenticationPath: Pages.LOGIN,
  };

  return (
    <PageContainer>
      <NotebookMenu />
      <Main>
        <Routes>
          <Route element={<ProtectedPage {...protetecedProps} />}>
            {Object.keys(NotebookPageComponents).map((key) => {
              const ElementPage = NotebookPageComponents[key];
              return <Route key={key} path={key} element={<ElementPage />} />;
            })}
          </Route>
        </Routes>
      </Main>
    </PageContainer>
  );
};

export default NotebookPage;
