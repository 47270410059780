import { ChangeEventHandler, memo } from "react";
import { GoSearch } from "react-icons/go";
import "./search-input.css";

type Props = {
  className?: string;
  value?: string;
  type?: string;
  placeHolder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

const SearchInput: React.FC<Props> = ({
  className,
  value,
  type,
  placeHolder,
  onChange,
}) => {
  return (
    <div className={`search-input-container ${className}`}>
      <input
        onChange={onChange}
        value={value}
        type={type}
        placeholder={placeHolder}
        className={`search-input`}
      />
      <GoSearch className={"search-input-icon"} />
    </div>
  );
};

export default memo(SearchInput);
