import { memo } from "react";
import "./shop-heading.css";

type Props = {
  children: string;
  variant: Variant;
};

export enum Variant {
  H1 = "H1",
  H2 = "H2",
}

const ShopHeading: React.FC<Props> = ({ children, variant }) => {
  return (
    <div
      className={`${
        variant === Variant.H1 ? "shop-heading1" : "shop-heading2"
      }`}
    >
      {children}
    </div>
  );
};

export default memo(ShopHeading);
