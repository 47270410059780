import { memo } from "react";
import Select, {
  ActionMeta,
  components,
  GroupBase,
  OptionProps,
} from "react-select";
import "./notebook-select-text.css";

export type Option = {
  value: any;
  label: any;
};

type Props = {
  inputKey: string;
  label: string;
  className?: string;
  value?: any;
  placeHolder?: string;
  options?: Option[];
  isDisabled?: boolean;
  isLoading?: boolean;
  disableClear?: boolean;
  singleValueComponent?: any;
  optionComponent?: React.ComponentType<
    OptionProps<any, false, GroupBase<any>>
  >;
  onChange?: (newValue: any, actionMeta: ActionMeta<any>) => void;
};

type ContainerProps = {
  children?: string | JSX.Element | JSX.Element[];
  className?: string;
};

const NotebookSelectContainer: React.FC<ContainerProps> = ({
  children,
  className,
}) => {
  return (
    <div className={`notebook-select-text-outer-container ${className}`}>
      {children}
    </div>
  );
};

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className="custom-css-class">Sem Resultados</span>
    </components.NoOptionsMessage>
  );
};

const style = {
  control: (base) => ({
    ...base,
    border: "1px solid #d5dae1;",
    "&:hover": {
      border: "1px solid #d5dae1;",
    },
    height: "37px",
    minHeight: "37px",
    borderRadius: "8px",
    boxShadow: "none",
  }),
  placeholder: (base) => ({
    ...base,
    color: "#bbc0c9",
  }),
  option: (base, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...base,
      backgroundColor: isFocused ? "#f1f1f1" : "#ffffff",
      color: "#27262e;",
      "&:active": {
        backgroundColor: "#D5DAE1",
      },
    };
  },
  valueContainer: (base, state) => ({
    ...base,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    "&:placeholder": {
      color: "#bbc0c9",
    },
  }),
  indicatorSeparator: (base, state) => ({
    display: "none",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#556987 !important",
  }),
};

const NotebookSelect: React.FC<Props> = ({
  label,
  className,
  value,
  options,
  optionComponent,
  singleValueComponent,
  placeHolder,
  isDisabled,
  isLoading,
  disableClear,
  onChange,
}) => {
  return (
    <NotebookSelectContainer className={className}>
      <label className="notebook-input-label">{label}</label>
      <Select
        styles={style}
        components={{
          NoOptionsMessage,
          Option: optionComponent || components.Option,
          SingleValue: singleValueComponent || components.SingleValue,
        }}
        placeholder={placeHolder}
        classNames={{
          container: (state) => "notebook-select-text-container",
          control: (state) => "notebook-select-text",
          placeholder: (props) => "notebook-select-text-placeholder",
          option: (props) => {
            return props.isSelected
              ? "notebook-select-text-selected-option"
              : "notebook-select-text-option";
          },
          menu: (props) => "notebook-select-text-menu",
        }}
        value={value}
        options={options}
        onChange={onChange}
        isDisabled={isDisabled}
        isLoading={isLoading}
        loadingMessage={() => "Carregando..."}
        isClearable={!disableClear}
      />
    </NotebookSelectContainer>
  );
};

export default memo(NotebookSelect);
