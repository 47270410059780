import HttpClient from "../../app/client";
import handleError from "../../app/errors";
import store from "../../app/store";
import { setAlert } from "../alert/slice";
import { AlertType } from "../alert/type";
import { ISettingsService, Settings } from "./type";

export default class SettingsService implements ISettingsService {
  async getSettings(): Promise<Settings> {
    try {
      const client = new HttpClient("auth", false, [404]);
      const response = await client.get<Settings>(`/settings/`);
      return response;
    } catch (error) {
      handleError("Error while getting settings", error);
    }
  }

  async updateSettings(settings: Settings): Promise<any> {
    try {
      const client = new HttpClient("auth", false, [404]);
      const response = await client.put(`/settings/`, settings);

      store.dispatch(
        setAlert({
          message: "Configurações atualizadas com sucesso.",
          type: AlertType.MESSAGE,
        })
      );

      return response;
    } catch (error) {
      handleError("Error while updating settings", error);
    }
  }
}
