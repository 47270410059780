import React, { useEffect, useRef, useState } from "react";
import { FaPrint } from "react-icons/fa";
import { IoIosListBox } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import SimpleBar from "simplebar-react";
import {
  EMERALD,
  NotebookPages as AppNotebookPages,
  NotebooksTemplateOptions,
  NOTEBOOK_PAGE,
  RUBY,
  SAPPHIRE,
  Status,
} from "../../../app/constants";
import { AppDispatch } from "../../../app/store";
import NotebookButton from "../../../components/buttons/notebook-button/notebook-button";
import { makeContainer } from "../../../components/container/container";
import NotebookSelectText from "../../../components/inputs/notebook-select-text/notebook-select-text";
import NotebooksRange from "../../../components/inputs/notebooks-range/notebooks-range";
import Loading from "../../../components/loading/loading";
import NotebookService from "../../../features/notebook/service";
import {
  getNotebookDetails,
  selectCurrentNotebook,
  selectGetCurrentNotebookStatus,
  selectTemplate,
  setTemplate,
  updateNotebook,
  updateNotebookImage,
} from "../../../features/notebook/slice";
import { Notebook } from "../../../features/notebook/type";
import ChangeFieldModal, {
  ChangeFieldProps,
} from "./components/change-field-modal/change-field-modal";
import PaletteSelect from "./components/palette-select/palette.select";
import UploadImageModal from "./components/upload-imagem-modal/upload-image-modal";
import { TemplatePageProps } from "./templates/commons/interfaces";
import EmeraldTemplatePages from "./templates/esmeralda";
import RubyTemplatePages from "./templates/rubi";
import SapphireTemplatePages from "./templates/safira";
import "./view.css";

const Container = makeContainer("view-container");
const Controls = makeContainer("notebook-view-toolbar-controls");
const PreviewContainer = makeContainer("preview-container");
const Toolbar = makeContainer("notebook-view-toolbar");
const PageControls = makeContainer("notebook-view-toolbar-page-controls");
const NotebookPageText = makeContainer("notebook-page-text");
const NotebookZoom = makeContainer("notebook-zoom");
const NoNotebook = makeContainer("notebook-view-no-notebook");

const NotebookPages = {
  [SAPPHIRE]: SapphireTemplatePages,
  [EMERALD]: EmeraldTemplatePages,
  [RUBY]: RubyTemplatePages,
};

export const NotebookView: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const componentRef = useRef();

  const [page, setPage] = useState(1);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showFieldModal, setShowFieldModal] = useState(false);
  const [fieldModalProps, setFieldModalProps] = useState(null);
  const [notebook, setNotebook] = useState(null);
  const [imageNumber, setImageNumber] = useState(0);
  const [ratio, setRatio] = useState(1);

  const [zoom, setZoom] = useState(100);
  const currentNotebook: Notebook = useSelector(selectCurrentNotebook);
  const status: Status = useSelector(selectGetCurrentNotebookStatus);
  const template = useSelector(selectTemplate);

  useEffect(() => {
    dispatch(
      getNotebookDetails({
        service: new NotebookService(),
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (currentNotebook) {
      setNotebook({ ...currentNotebook });
    } else if (status === Status.SUCCEEDED) {
      navigate(`/${NOTEBOOK_PAGE}/${AppNotebookPages.NOTEBOOK_QUESTIONAIRE}`);
    }
  }, [navigate, status, currentNotebook]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getClassName = (thisPage: number) => {
    return page === thisPage ? "" : "invisible";
  };

  const canReturn = () => {
    return page > 1;
  };

  const canAdvance = () => {
    return page < NotebookPages[template.value].length;
  };

  const triggerFieldEdit = (props: ChangeFieldProps) => {
    setFieldModalProps(props);
    setShowFieldModal(true);
  };

  const triggerImageEdit = (imageNumber: number, ratio: number) => {
    setShowImageModal(true);
    setImageNumber(imageNumber);
    setRatio(ratio);
  };

  const updateImage = (image: string) => {
    const imageBase64 = image.split(",")[1];
    notebook.images = { ...notebook.images, [imageNumber]: imageBase64 };
    setNotebook(notebook);

    dispatch(
      updateNotebookImage({
        service: new NotebookService(),
        notebook: notebook,
        request: {
          number: imageNumber,
          image: imageBase64,
        },
      })
    );
  };

  const updateField = (field, newValue) => {
    notebook[field] = newValue;
    setNotebook(notebook);

    dispatch(
      updateNotebook({
        service: new NotebookService(),
        request: {
          [field]: newValue,
        },
      })
    );
  };

  return (
    <Container>
      {status === Status.SUCCEEDED && notebook && (
        <PreviewContainer>
          <Toolbar className="notebook-top-view-toolbar">
            <NotebookButton
              selected
              onClick={() =>
                navigate(
                  `/${NOTEBOOK_PAGE}/${AppNotebookPages.NOTEBOOK_QUESTIONAIRE}`
                )
              }
              icon={() => <IoIosListBox />}
            >
              Questionário
            </NotebookButton>

            <Controls>
              <NotebookSelectText
                inputKey="template-select"
                label=""
                value={template}
                onChange={(value) => dispatch(setTemplate(value))}
                options={NotebooksTemplateOptions}
                disableClear
              />
              <PaletteSelect />
            </Controls>

            <Controls>
              <NotebookButton
                selected
                className={"notebook-view-print-button"}
                disabled={status !== Status.SUCCEEDED}
                onClick={handlePrint}
                icon={() => <FaPrint />}
              >
                Imprimir
              </NotebookButton>
            </Controls>
          </Toolbar>

          <SimpleBar style={{ maxHeight: "100%" }}>
            <div
              style={{
                transform: `scale(${zoom / 100.0}, ${zoom / 100.0})`,
              }}
            >
              <div className="a4-paper-container" ref={componentRef}>
                {NotebookPages[template.value].map(
                  (
                    PageComponent: React.FC<TemplatePageProps>,
                    number: number
                  ) => (
                    <PageComponent
                      key={`page-number-${number + 1}`}
                      className={getClassName(number + 1)}
                      notebook={notebook}
                      triggerFieldEdit={triggerFieldEdit}
                      triggerImageEdit={triggerImageEdit}
                      updateField={updateField}
                    />
                  )
                )}
              </div>
            </div>
          </SimpleBar>

          <Toolbar className="notebook-bottom-view-toolbar">
            <PageControls>
              <NotebookButton
                selected
                variant="small"
                disabled={!canReturn()}
                onClick={() => setPage(page - 1)}
              >
                {"<"}
              </NotebookButton>
              <NotebookPageText>{page.toString()}</NotebookPageText>
              <NotebookButton
                selected
                variant="small"
                disabled={!canAdvance()}
                onClick={() => setPage(page + 1)}
              >
                {">"}
              </NotebookButton>
            </PageControls>
            <NotebookZoom>
              <NotebooksRange
                value={zoom}
                min={25}
                max={100}
                onChange={(e) => setZoom(e)}
              />
            </NotebookZoom>
          </Toolbar>
        </PreviewContainer>
      )}
      {status === Status.SUCCEEDED && !notebook && (
        <NoNotebook>Sem caderno de campanha cadastrado</NoNotebook>
      )}
      {status === Status.LOADING && <Loading />}
      {showImageModal && (
        <UploadImageModal
          show={showImageModal}
          aspect={ratio}
          onClose={() => setShowImageModal(false)}
          onUpdateImage={(image: string) => {
            updateImage(image);
          }}
        />
      )}
      {showFieldModal && (
        <ChangeFieldModal
          show={showFieldModal}
          props={fieldModalProps}
          onClose={() => setShowFieldModal(false)}
        />
      )}
    </Container>
  );
};
