import { FiChevronDown } from "react-icons/fi";

import {
  forwardRef,
  memo,
  MouseEventHandler,
  useImperativeHandle,
  useRef,
} from "react";
import "./select-attribute-button.css";

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  ref?: any;
};

export type Ref = HTMLButtonElement;

const SelectAttributeButton = forwardRef<Ref, Props>((props, ref) => {
  const buttonRef = useRef(null);
  const { onClick } = props;

  useImperativeHandle(ref, () => buttonRef.current);

  return (
    <button
      ref={buttonRef}
      className={"select-attribute-button"}
      onClick={onClick}
    >
      <FiChevronDown />
    </button>
  );
});

export default memo(SelectAttributeButton);
