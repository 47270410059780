import React from "react";
import GoBackButton from "../../components/buttons/go-back-button/go-back-button";
import { makeContainer } from "../../components/container/container";
import "./not-found.css";

const NotFoundContainer = makeContainer("not-found-container");
const NotFoundContent = makeContainer("not-found-content");
const NotFound404 = makeContainer("not-found-404");

const PageNotFound: React.FC = () => {
  const goBack = () => {
    window.location.href = "/";
  };

  return (
    <NotFoundContainer>
      <NotFound404>404</NotFound404>
      <NotFoundContent>
        <span>PÁGINA NÃO ENCONTRADA</span>
        <GoBackButton onClick={goBack} />
      </NotFoundContent>
    </NotFoundContainer>
  );
};

export default PageNotFound;
