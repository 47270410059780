import React, { memo, useEffect, useState } from "react";
import InputLabelText from "../../text/input-label-text/input-label-text";
import "./settings-range.css";

type Props = {
  value?: number;
  type?: string;
  placeHolder?: string;
  className?: string;
  label?: string;
  min: number;
  max: number;
  onChange?: (value: number) => void;
};

type ContainerProps = {
  children?: string | JSX.Element | JSX.Element[];
  className?: string;
};

const Container: React.FC<ContainerProps> = ({ children, className }) => {
  return (
    <div className={`settings-range-outer-container ${className}`}>
      {children}
    </div>
  );
};

const SettingsRange: React.FC<Props> = ({
  value,
  label,
  className,
  min,
  max,
  onChange,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [innerValue, setInnerValue] = useState(value);
  const [timeoutForChange, setTimeoutForChange] = useState(null);

  const onInputChange = () => {
    const input = inputRef.current;
    const inputValue = parseInt(input.value);
    clearTimeout(timeoutForChange);

    const value =
      ((inputValue - parseInt(input.min)) /
        (parseInt(input.max) - parseInt(input.min))) *
      100;

    input.style.background =
      "linear-gradient(to right, #0A6789 0%, #2DA5C0 " +
      value +
      "%, rgba(0, 0, 0, 0.2) " +
      value +
      "%, rgba(0, 0, 0, 0.2) 100%)";

    if (innerValue !== inputValue) {
      setInnerValue(inputValue);
      setTimeoutForChange(
        setTimeout(() => onChange(parseInt(input.value)), 1000)
      );
    }
  };

  useEffect(() => {
    onInputChange();
    //eslint-disable-next-line
  }, [inputRef]);

  return (
    <Container>
      <InputLabelText className={className}>{label}</InputLabelText>
      <div className="settings-slidecontainer">
        <input
          ref={inputRef}
          className="settings-slider-input"
          type="range"
          min={min}
          max={max}
          value={innerValue}
          onInput={onInputChange}
        />
      </div>
    </Container>
  );
};

export default memo(SettingsRange);
