import { memo } from "react";
import Logo1 from "../../resources/images/logo-part-1.svg";
import Logo2 from "../../resources/images/logo-part-2.svg";
import Logo3 from "../../resources/images/logo-part-3.svg";
import "./no-data.css";

type Props = {
  noDataText?: string;
};

const NoData: React.FC<Props> = ({ noDataText }) => {
  return (
    <div className="no-data-container">
      <svg className="no-data-container-svg" viewBox="0 0 150 150">
        <g>
          <image
            x="0"
            y="0"
            width="150"
            height="150"
            href={Logo1}
            className="no-data-1"
          ></image>
          <image
            x="0"
            y="0"
            width="150"
            height="150"
            href={Logo2}
            className="no-data-2"
          ></image>
          <image
            x="0"
            y="0"
            width="150"
            height="150"
            href={Logo3}
            className="no-data-3"
            style={{}}
          ></image>
        </g>
      </svg>
      <span className="no-data-text">
        {noDataText ? noDataText : "sem dados"}
      </span>
    </div>
  );
};

export default memo(NoData);
