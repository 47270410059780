import { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { saveIndexedDB } from "../../../app/cache";
import { AppDispatch } from "../../../app/store";
import { logout } from "../../../features/auth/slice";
import { closeModal, setModalContent } from "../../../features/modal/slice";
import ModalButton from "../../buttons/modal-button/modal-button";
import { makeContainer } from "../../container/container";
import ModalTitleText from "../../text/modal-title-text/modal-title-text";
import "./logout-modal.css";

type LogoutModalProps = {
  show: boolean;
  onClose: () => void;
};

const LogoutModal: React.FC<LogoutModalProps> = memo(({ show, onClose }) => {
  const dispatch: AppDispatch = useDispatch();

  function handleLogout() {
    dispatch(closeModal(null));
    dispatch(logout(null));
    saveIndexedDB("notebook-images", {});
  }

  const LogoutModalContainer = makeContainer("logout-modal-container");
  const LogoutButtonsContainer = makeContainer(
    "logout-modal-buttons-container"
  );

  const modalComponent = (
    <LogoutModalContainer>
      <ModalTitleText>Deseja realmente sair?</ModalTitleText>
      <LogoutButtonsContainer>
        <ModalButton variation="primary" onClick={handleLogout}>
          Logout
        </ModalButton>
        <ModalButton variation="secondary" onClick={onClose}>
          Cancelar
        </ModalButton>
      </LogoutButtonsContainer>
    </LogoutModalContainer>
  );

  useEffect(() => {
    const changeModalStatus = () => {
      if (show) {
        dispatch(
          setModalContent({
            content: modalComponent,
            onClose: onClose,
          })
        );
      } else {
        dispatch(closeModal(null));
      }
    };

    changeModalStatus();
    // eslint-disable-next-line
  }, [dispatch, onClose, show]);

  return <></>;
});

export default memo(LogoutModal);
