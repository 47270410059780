import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PRIMARY_TARGET_LEVEL, Status, TargetPages } from "../../app/constants";
import { debounceThunk } from "../../app/utils";
import {
  CreateTargetRequest,
  GetStatsRequest,
  GlobalStats,
  ITargetService,
  SaveTargetRequest,
  TargetCityResponse,
  TargetLevel,
  TargetRootResponse,
  UpdateTargetCityRequest,
  UpdateTargetRootRequest,
} from "./type";

const initialGlobalStats: GlobalStats | null = null;
const initialCreateTargetRequest: CreateTargetRequest | null = null;
const initialTargetList: TargetRootResponse[] = [];
const initialTargetRoot: TargetRootResponse | null = null;
const initialTargetCity: TargetCityResponse | null = null;
const initialUpdateTargetRootRequest: UpdateTargetRootRequest | null = null;
const initialUpdateTargetCityRequest: UpdateTargetCityRequest | null = null;
const initialPriorityLevel: TargetLevel = PRIMARY_TARGET_LEVEL;
const initialIsPriorityRoot: boolean = true;
const initialCityName = "";
const initialPriorityCityId = "";
const initialTargetUpdateCounter = 0;

// Slice's initial state
const initialState = {
  cityName: initialCityName,
  currentPage: TargetPages.HOME,
  priorityLevel: initialPriorityLevel,
  priorityCityId: initialPriorityCityId,
  priorityCityName: initialCityName,
  isPriorityRoot: initialIsPriorityRoot,
  getGlobalStatsStatus: Status.IDLE,
  globalStats: initialGlobalStats,
  createTargetStatus: Status.IDLE,
  createTargetRequest: initialCreateTargetRequest,
  getTargetsStatus: Status.IDLE,
  targetsList: initialTargetList,
  getTargetRootStatus: Status.IDLE,
  targetRoot: initialTargetRoot,
  getTargetCityStatus: Status.IDLE,
  targetCity: initialTargetCity,
  updateTargetStatus: Status.IDLE,
  updateTragetRootRequest: initialUpdateTargetRootRequest,
  updateTragetCityRequest: initialUpdateTargetCityRequest,
  saveTargetStatus: Status.IDLE,
  deleteTargetStatus: Status.IDLE,
  targetAttributes: [],
  targetUpdateCounter: initialTargetUpdateCounter,
};

type GetStatsParams = { request: GetStatsRequest; service: ITargetService };
type CreateTargetParams = {
  request: CreateTargetRequest;
  service: ITargetService;
};
type GetTargetsParams = { service: ITargetService };
type GetTargetRootParams = { targetId: string; service: ITargetService };
type GetTargetCityParams = {
  targetId: string;
  cityId: string;
  service: ITargetService;
};
type UpdateTargetRootParams = {
  targetId: string;
  request: UpdateTargetRootRequest;
  service: ITargetService;
};
type UpdateTargetCityParams = {
  targetId: string;
  cityId: string;
  request: UpdateTargetCityRequest;
  service: ITargetService;
};
type SaveTargetParams = {
  targetId: string;
  request: SaveTargetRequest;
  service: ITargetService;
};
type DeleteTargetParams = { targetId: string; service: ITargetService };

const getStatsThunk = createAsyncThunk<GlobalStats, GetStatsParams>(
  "target/createtarget ",
  async (params) => {
    const { request, service } = params;
    const response = await service.getStats(request);
    return response;
  }
);

const createTargetThunk = createAsyncThunk<
  TargetRootResponse,
  CreateTargetParams
>("target/createtarget", async (params) => {
  const { request, service } = params;
  const response = await service.createTarget(request);
  return response;
});

const getTargetsThunk = createAsyncThunk<
  TargetRootResponse[],
  GetTargetsParams
>("target/gettargets", async (params) => {
  const { service } = params;
  const response = await service.getTargets();
  return response;
});

const getTargetThunk = createAsyncThunk<
  TargetRootResponse,
  GetTargetRootParams
>("target/gettarget", async (params) => {
  const { targetId, service } = params;
  const response = await service.getTargetDetails(targetId);
  return response;
});

const getTargetCityThunk = createAsyncThunk<
  TargetCityResponse,
  GetTargetCityParams
>("target/gettargetcity", async (params) => {
  const { targetId, cityId, service } = params;
  const response = await service.getTargetCityDetails(targetId, cityId);
  return response;
});

const updateTargetThunk = createAsyncThunk<
  TargetRootResponse,
  UpdateTargetRootParams
>("target/updatetarget", async (params) => {
  const { targetId, request, service } = params;
  const response = await service.updateTarget(request, targetId);
  return response;
});

const updateTargetCityThunk = createAsyncThunk<
  TargetCityResponse,
  UpdateTargetCityParams
>("target/updatetargetcity", async (params) => {
  const { targetId, cityId, request, service } = params;
  const response = await service.updateTargetCity(request, targetId, cityId);
  return response;
});

const saveTargetThunk = createAsyncThunk<void, SaveTargetParams>(
  "target/savetarget",
  async (params) => {
    const { targetId, request, service } = params;
    const response = await service.saveTarget(request, targetId);
    return response;
  }
);

const deleteTargetThunk = createAsyncThunk<void, DeleteTargetParams>(
  "target/deletetarget",
  async (params) => {
    const { targetId, service } = params;
    const response = await service.deleteTarget(targetId);
    return response;
  }
);

// Selectors
export const selectCityName = (state) => state.target.cityName;
export const selectCurrentPage = (state) => state.target.currentPage;
export const selectPriorityLevel = (state) => state.target.priorityLevel;
export const selectPriorityCityId = (state) => state.target.priorityCityId;
export const selectPriorityCityName = (state) => state.target.priorityCityName;
export const selectIsPriorityRoot = (state) => state.target.isPriorityRoot;
export const selectGlobalStatsStatus = (state) =>
  state.target.getGlobalStatsStatus;
export const selectGlobalStats = (state) => state.target.globalStats;
export const selectCreateTargetStatus = (state) =>
  state.target.createTargetStatus;
export const selectCreateTargetRequest = (state) =>
  state.target.createTargetRequest;
export const selectTargetsStatus = (state) => state.target.getTargetsStatus;
export const selectTargetsList = (state) => state.target.targetsList;
export const selectTargetRootStatus = (state) =>
  state.target.getTargetRootStatus;
export const selectTargetRoot = (state) => state.target.targetRoot;
export const selectTargetCityStatus = (state) =>
  state.target.getTargetCityStatus;
export const selectTargetCity = (state) => state.target.targetCity;
export const selectUpdateTargetStatus = (state) =>
  state.target.updateTargetStatus;
export const selectUpdateTargetRootRequest = (state) =>
  state.target.updateTragetRootRequest;
export const selectUpdateTargetCityRequest = (state) =>
  state.target.updateTragetCityRequest;
export const selectSaveTargetStatus = (state) => state.target.saveTargetStatus;
export const selectDeleteTargetStatus = (state) =>
  state.target.deleteTargetStatus;
export const selectTargetUpdateCounter = (state) =>
  state.target.targetUpdateCounter;

// Reducers
const loadingGetStats = (state, action) => {
  state.getGlobalStatsStatus = Status.LOADING;
};

const successGetStats = (state, action) => {
  state.getGlobalStatsStatus = Status.SUCCEEDED;
  state.globalStats = action.payload;
};

const rejectedGetStats = (state, action) => {
  state.getGlobalStatsStatus = Status.FAILED;
};

const loadingCreateTarget = (state, action) => {
  state.createTargetStatus = Status.LOADING;
};

const successCreateTarget = (state, action) => {
  state.createTargetStatus = Status.SUCCEEDED;
  state.targetRoot = action.payload;
};

const rejectedCreateTarget = (state, action) => {
  state.createTargetStatus = Status.FAILED;
};

const loadingListTargets = (state, action) => {
  state.getTargetsStatus = Status.LOADING;
};

const successListTargets = (state, action) => {
  state.getTargetsStatus = Status.SUCCEEDED;
  state.targetsList = action.payload;
};

const rejectedListTargets = (state, action) => {
  state.getTargetsStatus = Status.FAILED;
};

const loadingGetTarget = (state, action) => {
  state.getTargetRootStatus = Status.LOADING;
};

const successGetTarget = (state, action) => {
  state.getTargetRootStatus = Status.SUCCEEDED;
  state.targetRoot = action.payload;
};

const rejectedGetTarget = (state, action) => {
  state.getTargetRootStatus = Status.FAILED;
};

const loadingGetTargetCity = (state, action) => {
  state.getTargetCityStatus = Status.LOADING;
};

const successGetTargetCity = (state, action) => {
  state.getTargetCityStatus = Status.SUCCEEDED;
  state.targetCity = action.payload;
};

const rejectedGetTargetCity = (state, action) => {
  state.getTargetCityStatus = Status.FAILED;
};

const loadingUpdateTarget = (state, action) => {
  state.updateTargetStatus = Status.LOADING;
};

const successUpdateTarget = (state, action) => {
  state.updateTargetStatus = Status.SUCCEEDED;
  state.targetRoot = action.payload;
  state.targetUpdateCounter = state.targetUpdateCounter + 1;
};

const rejectedUpdateTarget = (state, action) => {
  state.updateTargetStatus = Status.FAILED;
};

const loadingUpdateTargetCity = (state, action) => {
  state.updateTargetStatus = Status.LOADING;
};

const successUpdateTargetCity = (state, action) => {
  state.updateTargetStatus = Status.SUCCEEDED;
  state.targetCity = action.payload;
  state.targetUpdateCounter = state.targetUpdateCounter + 1;
};

const rejectedUpdateTargetCity = (state, action) => {
  state.updateTargetStatus = Status.FAILED;
};

const loadingSaveTarget = (state, action) => {
  state.saveTargetStatus = Status.LOADING;
};

const successSaveTarget = (state, action) => {
  state.saveTargetStatus = Status.SUCCEEDED;
};

const rejectedSaveTarget = (state, action) => {
  state.saveTargetStatus = Status.FAILED;
};

const loadingDeleteTarget = (state, action) => {
  state.deleteTargetStatus = Status.LOADING;
};

const successDeleteTarget = (state, action) => {
  state.deleteTargetStatus = Status.SUCCEEDED;
};

const rejectedDeleteTarget = (state, action) => {
  state.deleteTargetStatus = Status.FAILED;
};

// Slice
const targetSlice = createSlice({
  name: "target",
  initialState,
  reducers: {
    setTargetRootManually(state, action) {
      state.getTargetRootStatus = Status.SUCCEEDED;
      state.targetRoot = action.payload;
    },
    setTargetCityManually(state, action) {
      state.getTargetRootStatus = Status.SUCCEEDED;
      state.targetCity = action.payload;
    },
    setCityName(state, action) {
      state.cityName = action.payload;
    },
    setCreateTargetRequest(state, action) {
      state.createTargetRequest = action.payload;
    },
    setUpdateTargetRootRequest(state, action) {
      state.updateTragetRootRequest = action.payload;
    },
    setUpdateTargetCityRequest(state, action) {
      state.updateTragetCityRequest = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setPriorityLevel(state, action) {
      state.priorityLevel = action.payload;
    },
    setPriorityCityId(state, action) {
      state.priorityCityId = action.payload;
    },
    setPriorityCityName(state, action) {
      state.priorityCityName = action.payload;
    },
    setIsPriorityRoot(state, action) {
      state.isPriorityRoot = action.payload;
    },
    setTargetUpdateCounter(state, action) {
      state.targetUpdateCounter = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getStatsThunk.pending, loadingGetStats)
      .addCase(getStatsThunk.fulfilled, successGetStats)
      .addCase(getStatsThunk.rejected, rejectedGetStats)
      .addCase(createTargetThunk.pending, loadingCreateTarget)
      .addCase(createTargetThunk.fulfilled, successCreateTarget)
      .addCase(createTargetThunk.rejected, rejectedCreateTarget)
      .addCase(getTargetsThunk.pending, loadingListTargets)
      .addCase(getTargetsThunk.fulfilled, successListTargets)
      .addCase(getTargetsThunk.rejected, rejectedListTargets)
      .addCase(getTargetThunk.pending, loadingGetTarget)
      .addCase(getTargetThunk.fulfilled, successGetTarget)
      .addCase(getTargetThunk.rejected, rejectedGetTarget)
      .addCase(getTargetCityThunk.pending, loadingGetTargetCity)
      .addCase(getTargetCityThunk.fulfilled, successGetTargetCity)
      .addCase(getTargetCityThunk.rejected, rejectedGetTargetCity)
      .addCase(updateTargetThunk.pending, loadingUpdateTarget)
      .addCase(updateTargetThunk.fulfilled, successUpdateTarget)
      .addCase(updateTargetThunk.rejected, rejectedUpdateTarget)
      .addCase(updateTargetCityThunk.pending, loadingUpdateTargetCity)
      .addCase(updateTargetCityThunk.fulfilled, successUpdateTargetCity)
      .addCase(updateTargetCityThunk.rejected, rejectedUpdateTargetCity)
      .addCase(saveTargetThunk.pending, loadingSaveTarget)
      .addCase(saveTargetThunk.fulfilled, successSaveTarget)
      .addCase(saveTargetThunk.rejected, rejectedSaveTarget)
      .addCase(deleteTargetThunk.pending, loadingDeleteTarget)
      .addCase(deleteTargetThunk.fulfilled, successDeleteTarget)
      .addCase(deleteTargetThunk.rejected, rejectedDeleteTarget);
  },
});

export const {
  setTargetRootManually,
  setTargetCityManually,
  setCityName,
  setCreateTargetRequest,
  setUpdateTargetRootRequest,
  setUpdateTargetCityRequest,
  setCurrentPage,
  setPriorityLevel,
  setPriorityCityId,
  setPriorityCityName,
  setIsPriorityRoot,
  setTargetUpdateCounter,
} = targetSlice.actions;

export const getStats = debounceThunk(getStatsThunk);
export const createTarget = debounceThunk(createTargetThunk);
export const getTargets = debounceThunk(getTargetsThunk);
export const getTarget = debounceThunk(getTargetThunk);
export const getTargetCity = debounceThunk(getTargetCityThunk);
export const updateTarget = debounceThunk(updateTargetThunk);
export const updateTargetCity = debounceThunk(updateTargetCityThunk);
export const saveTarget = debounceThunk(saveTargetThunk);
export const deleteTarget = debounceThunk(deleteTargetThunk);

export default targetSlice.reducer;
