import { debounce } from "lodash";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PRIMARY_TARGET_LEVEL,
  STATE_TARGET,
  Status,
  TargetPages,
} from "../../../../app/constants";
import { AppDispatch } from "../../../../app/store";
import {
  addCityPrioritiesList,
  addRootPriorities,
  filterAlreadySelected,
  findCityPriority,
  findOrCreateCityPriority,
} from "../../../../app/target";
import { listToObjKeys } from "../../../../app/utils";
import TargetFormButton from "../../../../components/buttons/target-form-button/target-form-button";
import TargetPageTextButton from "../../../../components/buttons/target-text-button/target-text-button";
import { makeContainer } from "../../../../components/container/container";
import SearchInput from "../../../../components/inputs/search-input/search-input";
import Loading from "../../../../components/loading/loading";
import TargetFormContainer from "../../../../components/target-page/target-form-container";
import MapsService from "../../../../features/maps/service";
import {
  getBurrows,
  getCities,
  selectMapsStatus,
  selectTargetBurrows,
  selectTargetCities,
} from "../../../../features/maps/slice";
import { TargetBurrow, TargetCity } from "../../../../features/maps/types";
import {
  selectCityName,
  selectCreateTargetRequest,
  selectIsPriorityRoot,
  selectPriorityCityId,
  selectPriorityCityName,
  selectPriorityLevel,
  setCreateTargetRequest,
  setCurrentPage,
  setIsPriorityRoot,
  setPriorityCityId,
  setPriorityCityName,
} from "../../../../features/target/slice";
import { CreateTargetRequest } from "../../../../features/target/type";
import "./priorities-selection-page.css";
import PrioritiesSelector, { PriorityItem } from "./priorities-selector";

const PrioritiesSelectionForm = makeContainer("priorities-selection-form");
const ComponentLimiter = makeContainer("priorities-selection-limiter");
const TargetSelectionCountContainer = makeContainer(
  "target-selection-count-container"
);
const updateStatus = debounce(
  (rawMapsStatus, setStatus) => {
    setStatus(rawMapsStatus);
  },
  750,
  { leading: false }
);

export const PrioritiesSelectionPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const createTargetRequest: CreateTargetRequest = useSelector(
    selectCreateTargetRequest
  );
  const priorityLevel = useSelector(selectPriorityLevel);
  const priorityCityId = useSelector(selectPriorityCityId);
  const isPriorityRoot = useSelector(selectIsPriorityRoot);
  const cityName = useSelector(selectCityName);
  const priorityCityName = useSelector(selectPriorityCityName);
  const rawStatus: Status = useSelector(selectMapsStatus);
  const cities: TargetCity[] = useSelector(selectTargetCities);
  const burrows: TargetBurrow[] = useSelector(selectTargetBurrows);

  const [items, setItems] = useState<PriorityItem[]>([]);
  const [search, setSearch] = useState("");
  const [selectedItems, setSelectedItems] = useState<any>({});
  const [status, setStatus] = useState(Status.IDLE);
  const [selectionCount, setSelectionCount] = useState(0);

  const goToNext = () => {
    if (priorityLevel === PRIMARY_TARGET_LEVEL) {
      dispatch(setCurrentPage(TargetPages.SECONDARY_PRIORITIES_SELECTION));
    } else {
      dispatch(setCurrentPage(TargetPages.FILLING_STRATEGY_SELECTION));
    }
  };

  // Effects
  useEffect(() => {
    const createDictOfSelectedItems = () => {
      const field =
        priorityLevel === PRIMARY_TARGET_LEVEL ? "primaries" : "secondaries";
      if (isPriorityRoot) {
        const newSelected = listToObjKeys(
          createTargetRequest?.priorities?.[field]
        );
        setSelectedItems(newSelected);
        setSelectionCount(Object.keys(newSelected).length);
      } else {
        const cityPriority = findCityPriority(
          createTargetRequest,
          priorityCityId
        );
        const newSelected = listToObjKeys(
          cityPriority?.priority_specification?.[field]
        );
        setSelectedItems(newSelected);
        setSelectionCount(Object.keys(newSelected).length);
      }
    };

    createDictOfSelectedItems();
  }, [isPriorityRoot, priorityCityId, priorityLevel, createTargetRequest]);

  useEffect(() => {
    const isStateTarget = createTargetRequest.target_type === STATE_TARGET;
    const shouldSetItemsFromCities = isPriorityRoot && isStateTarget;
    let items = [];

    if (shouldSetItemsFromCities) {
      items = filterAlreadySelected(
        cities,
        priorityLevel,
        isPriorityRoot,
        createTargetRequest
      );
    } else {
      items = filterAlreadySelected(
        burrows,
        priorityLevel,
        isPriorityRoot,
        createTargetRequest
      );
    }

    if (search) {
      const upperSearch = search.toLocaleUpperCase();
      items = items.filter((item) => item.name.includes(upperSearch));
    }

    items.sort((a, b) => a.name.localeCompare(b.name));
    setItems(items);
    // eslint-disable-next-line
  }, [search, cities, burrows, priorityLevel, isPriorityRoot]);

  useEffect(() => {
    const isStateTarget = createTargetRequest.target_type === STATE_TARGET;
    const shouldGetCities = isPriorityRoot && isStateTarget;
    const shouldGetRootBurrows = isPriorityRoot && !isStateTarget;
    const shouldGetBurrows = !isPriorityRoot && priorityCityId;

    if (shouldGetCities) {
      dispatch(
        getCities({
          service: new MapsService(),
          stateId: createTargetRequest.state,
        })
      );
    } else if (shouldGetRootBurrows) {
      dispatch(
        getBurrows({
          service: new MapsService(),
          stateId: createTargetRequest.state,
          cityId: createTargetRequest.city_id,
        })
      );
    } else if (shouldGetBurrows) {
      dispatch(
        getBurrows({
          service: new MapsService(),
          stateId: createTargetRequest.state,
          cityId: priorityCityId,
        })
      );
    }
    // eslint-disable-next-line
  }, [dispatch, isPriorityRoot]);

  useEffect(() => {
    updateStatus(rawStatus, setStatus);
  }, [rawStatus]);

  // Alteração da prioridade
  const addPriority = (priorityItem: PriorityItem) => {
    let newRequest = JSON.parse(JSON.stringify(createTargetRequest));
    const field =
      priorityLevel === PRIMARY_TARGET_LEVEL ? "primaries" : "secondaries";

    if (isPriorityRoot) {
      newRequest = addRootPriorities(newRequest);
      newRequest.priorities[field].push(priorityItem.id);
    } else {
      newRequest = addCityPrioritiesList(newRequest);
      const cityPriority = findOrCreateCityPriority(newRequest, priorityCityId);
      cityPriority.priority_specification[field].push(priorityItem.id);
    }

    dispatch(setCreateTargetRequest(newRequest));
    setSelectionCount(selectionCount + 1);
  };

  const removePriority = (priorityItem: PriorityItem) => {
    let newRequest = JSON.parse(JSON.stringify(createTargetRequest));
    const field =
      priorityLevel === PRIMARY_TARGET_LEVEL ? "primaries" : "secondaries";

    if (isPriorityRoot) {
      newRequest.priorities[field] = newRequest.priorities[field].filter(
        (p) => p !== priorityItem.id
      );
    } else {
      const cityPriority = findCityPriority(newRequest, priorityCityId);
      cityPriority.priority_specification[field] =
        cityPriority.priority_specification[field].filter(
          (p) => p !== priorityItem.id
        );
    }

    dispatch(setCreateTargetRequest(newRequest));
    setSelectionCount(selectionCount - 1);
  };

  const isChecked = (priorityItem: PriorityItem) => {
    return priorityItem.id in selectedItems;
  };

  const goToBurrows = (priorityItem: PriorityItem) => {
    setStatus(Status.LOADING);
    dispatch(setPriorityCityId(priorityItem.id));
    dispatch(setPriorityCityName(priorityItem.name));
    dispatch(setIsPriorityRoot(false));
    setSearch("");
  };

  const backToRoot = () => {
    dispatch(setIsPriorityRoot(true));
    setSearch("");
  };

  const listType =
    isPriorityRoot && createTargetRequest.target_type === STATE_TARGET
      ? "municípios"
      : "bairros";

  const place =
    isPriorityRoot && createTargetRequest.target_type === STATE_TARGET
      ? createTargetRequest.state
      : !isPriorityRoot
      ? priorityCityName
      : cityName;

  return (
    <TargetFormContainer
      title={`Selecione os ${listType} ${
        priorityLevel === PRIMARY_TARGET_LEVEL ? "PRIMÁRIOS" : "SECUNDÁRIOS"
      } em ${place}`}
      step={priorityLevel === PRIMARY_TARGET_LEVEL ? 5 : 6}
    >
      <PrioritiesSelectionForm>
        <ComponentLimiter>
          <SearchInput
            placeHolder={`Procurar em ${place}`}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <TargetSelectionCountContainer>
            {`${listType} selecionado: ${selectionCount}`}
          </TargetSelectionCountContainer>
          {!isPriorityRoot && (
            <TargetPageTextButton onClick={backToRoot}>
              Voltar aos municípios
            </TargetPageTextButton>
          )}
        </ComponentLimiter>

        {status === Status.SUCCEEDED && (
          <PrioritiesSelector
            items={items}
            getChecked={isChecked}
            onCheck={addPriority}
            onUncheck={removePriority}
            onClick={goToBurrows}
            clickable={
              isPriorityRoot && createTargetRequest.target_type === STATE_TARGET
            }
          />
        )}

        {status === Status.LOADING && <Loading />}

        <ComponentLimiter>
          <TargetFormButton selected onClick={goToNext}>
            Próximo Passo
          </TargetFormButton>
        </ComponentLimiter>
      </PrioritiesSelectionForm>
    </TargetFormContainer>
  );
};

export default memo(PrioritiesSelectionPage);
