import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Pages, Status } from "../../../app/constants";
import { AppDispatch } from "../../../app/store";
import { makeContainer } from "../../../components/container/container";
import Page from "../../../components/main-page/page";
import TitleText from "../../../components/text/title-text/title-text";
import CandidateService from "../../../features/candidates/service";
import {
  cleanUp,
  getCandidate,
  selectCandidateProfileData,
  selectCandidatesStatus,
} from "../../../features/candidates/slice";
import { Candidate } from "../../../features/candidates/type";
import "./candidates-profile.css";
import CandidateCandidatures from "./components/candidate-candidatures/candidate-candidatures";
import CandidateDetails from "./components/candidate-details/candidate-details";
import CandidateProfileCard from "./components/candidate-profile-card/candidate-profile-card";
import CandidateVotingChart from "./components/candidate-voting-chart/candidate-voting-chart";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  status?: Status;
  candidate?: Candidate;
};

const PageContainer = makeContainer("candidates-profile-page-container");
const CandidateProfileTopContainer = makeContainer(
  "candidates-profile-top-container"
);
const CandidateProfileMiddleContainer = makeContainer(
  "candidates-profile-middle-container"
);
const CandidateProfileBottomContainer = makeContainer(
  "candidates-profile-bottom-container"
);
const CandidateProfileInfoContainer = makeContainer(
  "candidates-profile-info-container"
);
const CandidateProfileVotingHistoryContainer = makeContainer(
  "candidates-profile-voting-history-container"
);
const CandidateProfileCandidaturesContainer = makeContainer(
  "candidates-profile-candidatures-container"
);

const CandidateProfileDetailsContainer: React.FC<Props> = ({
  status,
  candidate,
}) => {
  return (
    <div className="candidates-profile-details-container">
      <CandidateDetails status={status} candidate={candidate} />
    </div>
  );
};

const PartyProfile: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { id } = useParams();
  const status: Status = useSelector(selectCandidatesStatus);
  const candidate: Candidate = useSelector(selectCandidateProfileData);

  useEffect(() => {
    const fetchCandidates = () => {
      dispatch(getCandidate({ id: id, service: new CandidateService() }));
    };

    fetchCandidates();
  }, [id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(cleanUp(null));
    };
  }, [dispatch]);

  return (
    <Page
      title={<TitleText>{"Perfil do Candidato"}</TitleText>}
      page={Pages.CANDIDATE_PROFILE}
    >
      <PageContainer>
        <CandidateProfileTopContainer>
          <CandidateProfileInfoContainer>
            <CandidateProfileCard status={status} candidate={candidate} />
          </CandidateProfileInfoContainer>
          <CandidateProfileVotingHistoryContainer>
            <CandidateVotingChart status={status} candidate={candidate} />
          </CandidateProfileVotingHistoryContainer>
        </CandidateProfileTopContainer>
        <CandidateProfileMiddleContainer>
          <CandidateProfileDetailsContainer
            status={status}
            candidate={candidate}
          />
        </CandidateProfileMiddleContainer>
        <CandidateProfileBottomContainer>
          <CandidateProfileCandidaturesContainer>
            <CandidateCandidatures status={status} candidate={candidate} />
          </CandidateProfileCandidaturesContainer>
        </CandidateProfileBottomContainer>
      </PageContainer>
    </Page>
  );
};

export default PartyProfile;
