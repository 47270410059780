import React, { memo, useState } from "react";
import { BiExport } from "react-icons/bi";
import { FaRegSave } from "react-icons/fa";
import {
  BELOW_AVERAGE_PT,
  BELOW_LEAST_VOTED_PT,
  COMPLEMENTARY_PRIORITY,
  ON_AVERAGE_PT,
  OPTIMISTIC_PT,
  PRIMARY_PRIORITY,
  SECUNDARY_PRIORITY,
  Status,
  TargetGroupType,
  TOO_OPTIMISTIC_PT,
  UNFEASIBLE_PT,
} from "../../../app/constants";
import { toPtBr } from "../../../app/utils";
import { CompleteTargetDetails } from "../../../features/target/type";
import TargetFormButton from "../../buttons/target-form-button/target-form-button";
import { makeContainer } from "../../container/container";
import SearchInput from "../../inputs/search-input/search-input";
import TargetMiniSelect from "../../inputs/target-mini-select/target-mini-select";
import Loading from "../../loading/loading";
import ScrollContainer from "../../scroll/scroll";
import TargetWidgetItem from "../target-widget-item/target-widget-item";
import "./target-widget.css";

type Props = {
  data: CompleteTargetDetails[];
  isSaved: Boolean;
  status: Status;
  handleTargetValueUpdate: (
    value: number,
    target: CompleteTargetDetails
  ) => void;
  handleSaveOrUpdate?: () => void;
  handleExport?: () => void;
};

const WidgetContainer = makeContainer("target-widget-container");
const WidgetTitleContainer = makeContainer("target-widget-title-container");
const WidgetDataContainer = makeContainer("target-widget-data-container");
const WidgetGroupSelectionContainer = makeContainer(
  "target-widget-group-selection-container"
);
const WidgetButtonsContainer = makeContainer("target-widget-buttons-container");
const WidgetGroupContainer = makeContainer("target-widget-group-container");
const WidgetGroupTitleContainer = makeContainer(
  "target-widget-group-title-container"
);

const groupBy = (targets: CompleteTargetDetails[], groupBy: string) => {
  const priorities = [
    PRIMARY_PRIORITY,
    SECUNDARY_PRIORITY,
    COMPLEMENTARY_PRIORITY,
  ];

  const adequacyLevels = [
    UNFEASIBLE_PT,
    TOO_OPTIMISTIC_PT,
    OPTIMISTIC_PT,
    ON_AVERAGE_PT,
    BELOW_AVERAGE_PT,
    BELOW_LEAST_VOTED_PT,
  ];

  if (groupBy === TargetGroupType.NO_GROUP) {
    return [
      {
        groupName: "",
        targets: targets,
      },
    ];
  }

  const groupFilters =
    groupBy === TargetGroupType.ADEQUACY ? adequacyLevels : priorities;
  return groupFilters
    .map((groupFilter) => {
      const filteredTargets = targets.filter(
        (t) => t.adequacy_level === groupFilter || t.priority === groupFilter
      );

      const total = filteredTargets.reduce(
        (current, target) => current + target.value,
        0
      );

      const group = {
        groupName: groupFilter,
        targets: filteredTargets,
        total: total,
      };

      return group;
    })
    .filter((g) => g.targets.length);
};

const TargetWidget: React.FC<Props> = ({
  data,
  isSaved,
  status,
  handleTargetValueUpdate,
  handleSaveOrUpdate,
  handleExport,
}) => {
  const [groupType, setGroupType] = useState(TargetGroupType.NO_GROUP);
  const [search, setSearch] = useState("");
  const isLoading = status === Status.LOADING;

  const searchUpper = search.toUpperCase();
  const groups = groupBy(
    data.filter((t) => !search || t.name.includes(searchUpper)),
    groupType
  );

  return (
    <WidgetContainer>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <WidgetTitleContainer>
            <SearchInput
              value={search}
              placeHolder="Buscar"
              onChange={(e) => setSearch(e.target.value)}
            />
            <WidgetGroupSelectionContainer>
              <TargetMiniSelect
                value={groupType}
                onChange={(e) => setGroupType(e.target.value)}
              />
            </WidgetGroupSelectionContainer>
          </WidgetTitleContainer>
          <WidgetDataContainer>
            <ScrollContainer>
              {groups?.map((targetGroup, index) => (
                <WidgetGroupContainer key={`widget-group-${index}`}>
                  {targetGroup.groupName && (
                    <WidgetGroupTitleContainer>{`${
                      targetGroup.groupName
                    } (${toPtBr(
                      targetGroup.total
                    )} votos)`}</WidgetGroupTitleContainer>
                  )}
                  <>
                    {targetGroup?.targets.map((targetDetails, subindex) => (
                      <TargetWidgetItem
                        key={`widget-item-${subindex}`}
                        disabled={!isSaved}
                        details={targetDetails}
                        onChange={handleTargetValueUpdate}
                      />
                    ))}
                  </>
                </WidgetGroupContainer>
              ))}
            </ScrollContainer>
          </WidgetDataContainer>
          <WidgetButtonsContainer>
            <TargetFormButton onClick={handleSaveOrUpdate}>
              <>
                <FaRegSave />
                {isSaved ? "Atualizar" : "Salvar"}
              </>
            </TargetFormButton>
            <TargetFormButton disabled={!isSaved} onClick={handleExport}>
              <>
                <BiExport />
                {"Exportar"}
              </>
            </TargetFormButton>
          </WidgetButtonsContainer>
        </>
      )}
    </WidgetContainer>
  );
};

export default memo(TargetWidget);
