import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  COMPLEMENTARY_PRIORITY,
  DashboardFilterConsts,
  Mode,
  PRIMARY_PRIORITY,
  SECUNDARY_PRIORITY,
  STATE_TARGET,
  Status,
} from "../../../../app/constants";
import { get } from "../../../../app/filter";
import { MapFeatures } from "../../../../app/maps";
import { AppDispatch } from "../../../../app/store";
import {
  generateCSV,
  getOrCreateTargetCityUpdate,
  getOrCreateTargetRootUpdate,
  getTargetAdequacyLevel,
  getTargetAdequacySettings,
  updateOrReplaceTargetUpdate,
} from "../../../../app/target";
import TargetWidget from "../../../../components/dashboard/target-widget/target-widget";
import {
  selectDashboardComparativeFilters,
  selectMapDataStatus,
  selectMapFeatures,
} from "../../../../features/dashboard/slice";
import { selectMapsStatus } from "../../../../features/maps/slice";
import TargetService from "../../../../features/target/service";
import {
  selectTargetCity,
  selectTargetRoot,
  selectUpdateTargetCityRequest,
  selectUpdateTargetRootRequest,
  selectUpdateTargetStatus,
  setUpdateTargetCityRequest,
  setUpdateTargetRootRequest,
  updateTarget,
  updateTargetCity,
} from "../../../../features/target/slice";
import {
  CompleteTargetDetails,
  PrioritySpecification,
  TargetCityResponse,
  TargetDetails,
  TargetRootResponse,
  UpdateTargetCityRequest,
  UpdateTargetRootRequest,
} from "../../../../features/target/type";
import SaveTargetModal from "../components/save-target-modal";

type Props = {
  mode?: Mode;
};

const fromTargetsToDataSource = (
  targets: TargetDetails[],
  priorities: PrioritySpecification,
  map: MapFeatures
): CompleteTargetDetails[] => {
  if (!targets || !map) {
    return [];
  }

  //Criando dicionário de features
  const featuresDict = map.features.reduce((featuresDict, feature) => {
    featuresDict[feature.id] = feature.name;
    return featuresDict;
  }, {});

  //Adicionando nomes
  const writableTargets = JSON.parse(JSON.stringify(targets));
  const targetsWithNames = writableTargets.map((target) => {
    if (target.id in featuresDict) {
      target.name = featuresDict[target.id];
    }
    return target;
  });

  //Complementando dados
  const completeTargets = targetsWithNames.map((target) => {
    const isPrimary =
      priorities?.primaries &&
      priorities?.primaries.find((p) => target.id === p);
    const isSecondary =
      priorities?.secondaries &&
      priorities?.secondaries.find((p) => target.id === p);
    target.priority = isPrimary
      ? PRIMARY_PRIORITY
      : isSecondary
      ? SECUNDARY_PRIORITY
      : COMPLEMENTARY_PRIORITY;

    const adequacyLevel = getTargetAdequacyLevel(target.value, target);
    const adequacySettings = getTargetAdequacySettings(adequacyLevel);
    target.adequacy_level = adequacySettings.adequacyLevel;
    return target;
  });

  // Ordenando
  completeTargets.sort((a, b) => (a.value < b.value ? 1 : -1));

  return completeTargets;
};

const WidgetWithData: React.FC<Props> = () => {
  const dispatch: AppDispatch = useDispatch();
  const targetRoot: TargetRootResponse = useSelector(selectTargetRoot);
  const targetCity: TargetCityResponse = useSelector(selectTargetCity);
  const dashFilters = useSelector(selectDashboardComparativeFilters);
  const map: MapFeatures = useSelector(selectMapFeatures);
  const rawMapsStatus: Status = useSelector(selectMapsStatus);
  const mapDataStatus: Status = useSelector(selectMapDataStatus);
  const updateTargetStatus: Status = useSelector(selectUpdateTargetStatus);
  const updateTargetRootRequest: UpdateTargetRootRequest = useSelector(
    selectUpdateTargetRootRequest
  );
  const updateTargetCityRequest: UpdateTargetCityRequest = useSelector(
    selectUpdateTargetCityRequest
  );

  const [status, setStatus] = useState(Status.LOADING);
  const [data, setData] = useState<CompleteTargetDetails[]>([]);
  const [showSaveModal, setShowSaveModal] = useState(false);

  useEffect(() => {
    const setWidgetData = () => {
      const cityId = get(dashFilters, DashboardFilterConsts.CITY);

      const shouldUseCityDetails =
        targetRoot?.target_type === STATE_TARGET && cityId;

      const targets = shouldUseCityDetails
        ? targetCity?.targets
        : targetRoot?.targets;

      const priorities = shouldUseCityDetails
        ? targetRoot?.city_priorities?.find((p) => p.city_id === cityId.value)
            ?.priority_specification
        : targetRoot?.priorities;

      const widgetData = fromTargetsToDataSource(targets, priorities, map);
      setData(widgetData);
    };

    const resetUpdateRequests = () => {
      dispatch(setUpdateTargetRootRequest(null));
      dispatch(setUpdateTargetCityRequest(null));
    };

    setWidgetData();
    resetUpdateRequests();
  }, [dispatch, map, dashFilters, targetRoot, targetCity]);

  useEffect(() => {
    const updateStatus = () => {
      const newStatus =
        rawMapsStatus !== Status.SUCCEEDED ||
        mapDataStatus !== Status.SUCCEEDED ||
        updateTargetStatus === Status.LOADING
          ? Status.LOADING
          : Status.SUCCEEDED;

      setStatus(newStatus);
    };

    updateStatus();
    // eslint-disable-next-line
  }, [rawMapsStatus, mapDataStatus, updateTargetStatus]);

  const handleTargetValueUpdate = (
    value: number,
    target: CompleteTargetDetails
  ) => {
    const cityId = get(dashFilters, DashboardFilterConsts.CITY);
    const shouldUpdateCityTarget =
      targetRoot?.target_type === STATE_TARGET && cityId;

    if (shouldUpdateCityTarget) {
      const newRequest = getOrCreateTargetCityUpdate(
        cityId.value,
        updateTargetCityRequest
      );
      updateOrReplaceTargetUpdate(value, target.id, newRequest.targets);
      dispatch(setUpdateTargetCityRequest(newRequest));
    } else {
      const newRequest = getOrCreateTargetRootUpdate(updateTargetRootRequest);
      updateOrReplaceTargetUpdate(value, target.id, newRequest.targets);
      dispatch(setUpdateTargetRootRequest(newRequest));
    }
  };

  const handleSaveOrUpdate = () => {
    const isSavedTarget = !targetRoot.in_progress;
    const cityId = get(dashFilters, DashboardFilterConsts.CITY);
    const shouldUpdateCityTarget =
      targetRoot?.target_type === STATE_TARGET && cityId;

    if (isSavedTarget) {
      if (shouldUpdateCityTarget) {
        dispatch(
          updateTargetCity({
            targetId: targetRoot.id,
            cityId: cityId.value,
            request: updateTargetCityRequest,
            service: new TargetService(),
          })
        );
      } else {
        dispatch(
          updateTarget({
            targetId: targetRoot.id,
            request: updateTargetRootRequest,
            service: new TargetService(),
          })
        );
      }

      dispatch(setUpdateTargetRootRequest(null));
      dispatch(setUpdateTargetCityRequest(null));
      return;
    }

    setShowSaveModal(true);
  };

  const handleExport = (data) => () => {
    generateCSV(data);
  };

  return (
    <>
      <TargetWidget
        data={data}
        isSaved={!targetRoot?.in_progress}
        status={status}
        handleTargetValueUpdate={handleTargetValueUpdate}
        handleSaveOrUpdate={handleSaveOrUpdate}
        handleExport={handleExport(data)}
      />
      <SaveTargetModal
        show={showSaveModal}
        onClose={() => setShowSaveModal(false)}
      />
    </>
  );
};

export default WidgetWithData;
