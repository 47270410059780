import HttpClient from "../../app/client";
import { DashboardFilterConsts } from "../../app/constants";
import handleError from "../../app/errors";
import { ActiveFilter, getValue } from "../../app/filter";
import AC from "../../resources/images/flags/ac.png";
import AL from "../../resources/images/flags/al.png";
import AM from "../../resources/images/flags/am.png";
import AP from "../../resources/images/flags/ap.png";
import BA from "../../resources/images/flags/ba.png";
import BR from "../../resources/images/flags/br.png";
import CE from "../../resources/images/flags/ce.png";
import DF from "../../resources/images/flags/df.png";
import ES from "../../resources/images/flags/es.png";
import GO from "../../resources/images/flags/go.png";
import MA from "../../resources/images/flags/ma.png";
import MG from "../../resources/images/flags/mg.png";
import MS from "../../resources/images/flags/ms.png";
import MT from "../../resources/images/flags/mt.png";
import PA from "../../resources/images/flags/pa.png";
import PB from "../../resources/images/flags/pb.png";
import PE from "../../resources/images/flags/pe.png";
import PI from "../../resources/images/flags/pi.png";
import PR from "../../resources/images/flags/pr.png";
import RJ from "../../resources/images/flags/rj.png";
import RN from "../../resources/images/flags/rn.png";
import RO from "../../resources/images/flags/ro.png";
import RR from "../../resources/images/flags/rr.png";
import RS from "../../resources/images/flags/rs.png";
import SC from "../../resources/images/flags/sc.png";
import SE from "../../resources/images/flags/se.png";
import SP from "../../resources/images/flags/sp.png";
import TO from "../../resources/images/flags/to.png";
import {
  GeoProperties,
  IMapsService,
  RawGeoMap,
  TargetBurrow,
  TargetCity,
} from "./types";

const Flags = {
  AC: AC,
  AL: AL,
  AM: AM,
  AP: AP,
  BA: BA,
  BR: BR,
  CE: CE,
  DF: DF,
  ES: ES,
  GO: GO,
  MA: MA,
  MG: MG,
  MS: MS,
  MT: MT,
  PA: PA,
  PB: PB,
  PE: PE,
  PI: PI,
  PR: PR,
  RJ: RJ,
  RN: RN,
  RO: RO,
  RR: RR,
  RS: RS,
  SC: SC,
  SE: SE,
  SP: SP,
  TO: TO,
};

export default class MapsService implements IMapsService {
  getStateFlag(state: string): string {
    return Flags[state];
  }

  async getMaps(
    filters: ActiveFilter[],
    zoneMode: boolean
  ): Promise<RawGeoMap<GeoProperties>> {
    try {
      const client = new HttpClient("maps", true);
      const burrowId = getValue(filters, DashboardFilterConsts.BURROW);
      const cityId = getValue(filters, DashboardFilterConsts.CITY);
      const stateId = getValue(filters, DashboardFilterConsts.STATE);

      if (burrowId) {
        return await client.get<RawGeoMap<GeoProperties>>(
          `maps/states/${stateId}/cities/${cityId}/burrows/${burrowId}/places`
        );
      }

      if (cityId) {
        return await client.get<RawGeoMap<GeoProperties>>(
          `maps/states/${stateId}/cities/${cityId}/burrows`
        );
      }

      if (stateId && zoneMode) {
        return await client.get<RawGeoMap<GeoProperties>>(
          `maps/states/${stateId}/zones`
        );
      }

      if (stateId && !zoneMode) {
        return await client.get<RawGeoMap<GeoProperties>>(
          `maps/states/${stateId}/cities`
        );
      }

      return await client.get<RawGeoMap<GeoProperties>>("/maps/all");
    } catch (error) {
      handleError("Error while getting maps", error);
    }
  }

  async getTargetCities(stateId: string): Promise<TargetCity[]> {
    const client = new HttpClient("maps", true);
    const cities: TargetCity[] = await client.get<TargetCity[]>(
      `maps/states/${stateId}/cities?list_only=true`
    );

    return cities;
  }

  async getTargetBurrows(
    stateId: string,
    cityId: string
  ): Promise<TargetBurrow[]> {
    const client = new HttpClient("maps", true);
    const burrows: TargetBurrow[] = await client.get<TargetBurrow[]>(
      `maps/states/${stateId}/cities/${cityId}/burrows?list_only=true`
    );

    return burrows;
  }
}

/*
  - Create two thunks for saving targets and saving burrows.
  - The redux state will contain the request for the api
  - Also in the redux, the priorities selector must be a dictionary with
    data about which cities/burrows have been marked.
  - The action that selects new priorities will update data in the redux store in
  both formats.
*/
