import * as React from "react";
import { useSelector } from "react-redux";
import { validateText } from "../../../../../app/utils";
import { selectPalette } from "../../../../../features/notebook/slice";
import { ClippedImage, Paper, PaperInside } from "../commons/commons";
import { TemplatePageProps } from "../commons/interfaces";

const Page3: React.FC<TemplatePageProps> = ({
  className,
  notebook,
  triggerImageEdit,
  triggerFieldEdit,
  updateField,
}) => {
  const { palette } = useSelector(selectPalette);

  return (
    <Paper className={className}>
      <PaperInside>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
        >
          <defs>
            <filter id="page3-a">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="4"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <filter id="page3-c">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <filter id="page3-d">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <clipPath id="page3-b">
              <path d="M1371.19 201.36a166.7 166.7 0 0 1 166.7 166.7v369.4h-138.3a166.7 166.7 0 0 1-166.7-166.7V339.67a138.3 138.3 0 0 1 138.3-138.3z" />
            </clipPath>
            <filter
              id="page3-f"
              width={336.96}
              height={337.74}
              x={1229.69}
              y={300.44}
              filterUnits="userSpaceOnUse"
            >
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
          </defs>
          <path
            d="M.664.774h209.795v296.707H.664z"
            style={{ fill: palette.backgroundLight, strokeWidth: 0.35243 }}
          />
          <g data-name="CZJK68" style={{ opacity: 0.13 }}>
            <path d="M193.902 49.24c.877 4.184 2.015 8.357 3.171 12.53 1.058 3.742 2.2 7.481 3.334 11.224l1.85 1.096c.177.134.353.275.519.413-1.167-4.98-2.425-9.96-3.387-14.947-1.283-6.64-2.467-13.29-3.21-19.944a69.996 69.996 0 0 1 1.504-24.205A65.489 65.489 0 0 1 203.114.75h-3.345c-4.521 7.51-6.956 15.154-7.605 22.883a95.4 95.4 0 0 0 0 14.097c.215 3.835.93 7.68 1.738 11.51z" />
            <path d="M184.932 57.639c1.294 2.876 2.682 5.748 4.106 8.613l3.549 2.115c-2.115-4.934-4.26-9.868-6.287-14.837a58.92 58.92 0 0 1-4.141-16.72c-.677-7.027-.082-14.037 2.861-20.983A49.418 49.418 0 0 1 195.068.764h-3.683a4.19 4.19 0 0 1-.483.726c-7.235 7.447-11.232 15.207-12.504 23.218a38.31 38.31 0 0 0 .945 17.019c1.568 5.31 3.22 10.643 5.59 15.912z" />
            <path d="M176.139 60.976q1.67.5 3.316 1.058c-3.637-6.108-6.907-12.25-8.902-18.51-2.241-7.049-2.82-14.069-.63-21.128 1.881-6.016 5.786-11.785 11.795-17.297 1.597-1.466 3.32-2.908 4.983-4.36h-3.344a6.22 6.22 0 0 1-.6.571c-11.792 8.75-17.642 18.182-18.104 28.22-.296 6.434 1.53 12.785 4.483 19.087a104.992 104.992 0 0 0 7.003 12.36z" />
            <path d="M163.906 58.185c1.057.17 2.115.353 3.14.55-3.133-4.335-5.91-8.716-7.972-13.18-2.946-6.387-4.338-12.815-2.9-19.325q2.361-10.7 14.703-20.088c2.425-1.843 5.188-3.606 7.796-5.403h-3.348c-1.135.723-2.277 1.442-3.408 2.168-13.333 8.514-20.11 17.85-20.057 28.07.035 6.598 2.661 13.04 6.598 19.384 1.631 2.636 3.471 5.24 5.448 7.824z" />
            <path d="M152.67 57.068c.97.042 1.936.095 2.898.166-4.765-5.7-8.737-11.5-10.873-17.523-1.646-4.645-2.076-9.304-.73-13.97 1.981-6.866 7.518-13.206 16.452-18.997 3.172-2.061 6.802-4 10.22-5.99h-3.344c-3.087 1.723-6.344 3.393-9.223 5.176-10.386 6.425-16.638 13.495-18.164 21.27-1.09 5.547.306 11.013 3.143 16.43 2.407 4.585 5.756 9.05 9.622 13.438z" />
            <path d="M142.524 57.008a73.101 73.101 0 0 1 2.636-.063c-1.441-1.53-2.819-3.07-4.123-4.624-5.086-6.083-8.857-12.286-9.61-18.77-1.005-8.61 3.957-16.54 14.71-23.86 4.747-3.228 10.403-6.15 16.476-8.941h-3.348l-.91.408c-9.402 4.198-17.329 8.811-22.851 14.13-8.684 8.348-9.516 16.976-5.16 25.79 2.71 5.509 7.024 10.785 12.18 15.93z" />
            <path d="M133.745 57.907q1.103-.18 2.22-.324c-7.4-6.725-13.201-13.682-15.348-21.115-1.653-5.72-.61-11.33 3.75-16.814 5.244-6.594 14.499-12.11 25.83-17.068 1.374-.603 2.7-1.223 4.05-1.836h-3.345a5.212 5.212 0 0 1-.67.38c-7.169 2.908-13.64 6.062-19.088 9.583-9.332 6.023-14.421 12.603-14.657 19.81-.205 6.108 2.97 11.983 7.753 17.688a94.874 94.874 0 0 0 9.505 9.696z" />
            <path d="M123.556 57.227q1.435 1.134 2.887 2.27a79.971 79.971 0 0 1 1.642-.43 145.666 145.666 0 0 1-5.173-4.582c-6.855-6.429-12.336-13.04-13.643-20.124-1.057-5.639.821-11.091 6.055-16.321 6.181-6.178 15.894-11.27 27.359-15.824 1.205-.48 2.358-.976 3.524-1.466h-3.344c-3.102 1.275-6.277 2.52-9.294 3.83-9.163 3.98-16.772 8.403-21.83 13.555-7.07 7.204-7.53 14.59-3.485 22.104 3.228 5.964 8.515 11.617 15.302 16.988z" />
            <path d="M106.266 50.277c4.025 3.905 8.726 7.658 13.7 11.351.493-.193.993-.377 1.494-.556a182.29 182.29 0 0 1-5.618-4.497c-7.51-6.288-13.854-12.751-16.293-19.779-1.864-5.374-1.117-10.668 3.055-15.842 5.16-6.396 14.37-11.665 25.686-16.338 3.21-1.329 6.59-2.583 9.896-3.877h-3.345c-1.283.483-2.562.97-3.848 1.449-9.91 3.693-18.68 7.788-25.28 12.659-8.761 6.488-11.86 13.445-9.597 20.828 1.554 5.086 5.318 9.918 10.15 14.602zM209.426 80.441c.352.353.705.705 1.033 1.057V.75h-2.344c-.095.68-.236 1.357-.278 2.037-.494 8.338-1.223 16.673-1.41 25.022-.282 13.181.8 26.355 1.938 39.525.388 4.36.74 8.733 1.061 13.107z" />
            <path d="M91.56 44.606c5.473 6.647 13.413 12.78 22.104 18.746l.204.137c.55-.18 1.1-.352 1.66-.525q-3.295-2.336-6.523-4.68c-7.648-5.58-13.865-11.447-17.548-17.738-3.136-5.36-3.901-10.75-.927-16.173 4.19-7.637 14.45-13.745 27.546-19.088 3.877-1.582 8.043-3.027 12.078-4.535h-3.345c-.458.169-.902.352-1.374.507C115.46 4.69 106.36 8.471 99.15 13c-9.058 5.692-13.883 11.923-13.491 18.816.25 4.406 2.488 8.645 5.9 12.79z" />
            <path d="M86.886 49.258c6.051 5.71 13.622 11.052 21.562 16.307.409-.18.821-.352 1.237-.522-6.164-4.18-12.057-8.426-17.142-12.87-6.658-5.819-11.85-11.828-13.04-18.327q-1.48-8.24 6.886-15.68c6.118-5.44 15.31-9.867 25.756-13.896 3.203-1.23 6.647-2.35 9.98-3.524h-3.347c-2.936 1.057-5.897 2.072-8.811 3.126-8.286 3.003-15.588 6.376-21.498 10.263-10.105 6.647-13.978 13.872-11.278 21.71 1.607 4.687 5.142 9.117 9.695 13.413z" />
            <path d="M103.729 67.86c.384-.209.772-.413 1.16-.614-5.016-3.355-10-6.72-14.746-10.14-7.155-5.155-13.08-10.572-16.716-16.363-3.757-5.99-4.37-11.982-.067-17.974 4.106-5.72 11.651-10.597 21.565-14.802 6.04-2.555 12.754-4.82 19.165-7.217h-3.665c-1.325.486-2.605.997-3.983 1.455-8.74 2.915-16.624 6.182-23.278 9.96-13.473 7.644-18.41 16.116-13.991 25.512 2.675 5.696 7.979 10.964 14.347 16.064 6.08 4.878 13.037 9.526 20.209 14.118z" />
            <path d="m98.203 69.488 1.36.86c.3-.201.606-.395.913-.585a1881.092 1881.092 0 0 1-10.351-6.795c-8.01-5.315-15.296-10.802-20.751-16.684-4.268-4.582-7.147-9.333-7.401-14.295-.303-6.21 3.82-11.905 11.676-17.132 6.996-4.655 16.035-8.525 26.08-12.003 2.009-.705 3.986-1.41 5.991-2.115h-3.348c-.832.3-1.639.617-2.495.902C88.144 5.567 77.554 9.934 69.79 15.386c-9.248 6.506-12.335 13.512-9.47 20.98 1.952 5.118 6.245 9.914 11.598 14.549 7.518 6.523 16.818 12.57 26.285 18.573z" />
            <path d="M95.718 73.09c.3-.233.606-.462.91-.688-7.92-5.209-15.807-10.46-23.335-15.782-7.898-5.575-14.016-11.51-17.27-17.9-3.717-7.284-2.188-14.322 5.9-21.022 7.109-5.89 17.594-10.573 29.534-14.76L97.71.746h-3.344c-.402.155-.78.324-1.21.465C81.28 5.134 70.54 9.508 62.58 14.974 53.793 21 50.184 27.534 51.981 34.569c1.371 5.374 5.523 10.414 10.901 15.285 6.735 6.1 15.155 11.792 23.846 17.42q4.482 2.911 8.99 5.815z" />
            <path d="m92.099 75.888.747-.582-.86-.564c-10.22-6.696-20.68-13.314-29.365-20.395-6.876-5.603-12.585-11.387-14.893-17.678-2.34-6.386-.596-12.529 6.026-18.393C60.197 12.574 69.867 7.95 81.04 3.826c2.84-1.057 5.759-2.058 8.645-3.083h-3.348c-.408.155-.8.32-1.23.465C73.98 4.95 63.904 9.106 56.207 14.205Q40.806 24.425 45.282 36.36c1.9 5.114 6.09 9.925 11.33 14.58 7.539 6.696 16.966 12.93 26.514 19.134 2.981 1.945 5.973 3.876 8.973 5.815z" />
            <path d="M89.04 78.492c-4.84-3.351-9.735-6.696-14.595-10.037-8.144-5.607-16.247-11.221-22.717-17.216-5.992-5.533-10.605-11.229-11.68-17.357-1.075-6.13 1.812-11.895 8.624-17.319 6.023-4.793 14.305-8.81 23.775-12.448C75.453 2.966 78.572 1.87 81.638.75h-3.344c-.353.14-.73.285-1.114.42-9.026 3.132-17.332 6.554-24.208 10.572-13.238 7.715-18.143 16.24-14.097 25.636 1.924 4.476 5.42 8.744 9.84 12.885 6.593 6.178 14.872 11.961 23.299 17.713 5.452 3.718 10.95 7.425 16.38 11.15.201-.218.423-.426.645-.634z" />
            <path d="M85.667 81.946c-4.638-3.411-9.484-6.77-14.28-10.143-8.593-6.04-17.21-12.074-24.283-18.464-6.344-5.72-11.44-11.598-13.125-17.924-1.438-5.417-.042-10.679 4.677-15.765 5.1-5.497 13.199-10.146 23.053-14.273 3.823-1.603 7.926-3.09 11.898-4.627h-3.36a35.55 35.55 0 0 1-1.113.423C58.128 5.169 48.341 9.596 41.208 14.988c-7.874 5.956-11.056 12.335-9.685 19.116 1.008 4.972 4.26 9.716 8.641 14.33 6.246 6.58 14.524 12.719 23.053 18.798 7.25 5.17 14.608 10.312 21.748 15.507.24-.268.47-.529.702-.793z" />
            <path d="m80.468 86.119 1.287 1.032c.18-.282.352-.563.542-.838-3.171-2.63-6.523-5.223-9.913-7.81C63.527 71.75 54.48 65.036 45.95 58.206c-7.34-5.885-13.202-12.046-16.37-18.6-2.678-5.548-2.915-11.085.426-16.607 3.525-5.801 10.295-10.887 19.56-15.363C54.572 5.218 60.214 3.055 65.571.75h-3.348C55.39 3.354 49.01 6.135 43.47 9.247 31.48 15.978 24.964 23.492 25.49 31.95c.352 5.572 3.524 10.904 8.105 16.1 5.713 6.484 13.347 12.578 21.301 18.608 8.529 6.488 17.28 12.923 25.572 19.46z" />
            <path d="M78.706 92.9c.113-.265.233-.522.353-.786A162.657 162.657 0 0 0 66.586 80.87c-8.458-7-17.244-13.935-25.491-20.98S26.018 45.47 23.25 37.582c-1.41-3.975-1.54-7.95-.049-11.926 2.171-5.78 7.588-11.003 15.454-15.8C44.13 6.52 50.593 3.544 57.54.75h-3.68c-.352.193-.704.401-1.085.585-3.971 1.871-8.194 3.661-11.89 5.639-13.065 6.921-20.52 14.756-20.681 23.708-.102 5.617 2.573 11.045 6.696 16.36 4.8 6.17 11.324 12.028 18.326 17.797 8.853 7.317 18.013 14.57 26.595 21.939 2.424 2.083 4.698 4.19 6.766 6.326zM76.32 134.507c-.585.395-1.18.79-1.8 1.181-.455.29-.892.582-1.336.874a1.315 1.315 0 0 0 .726-.134q1.328-.79 2.562-1.59z" />
            <path d="M74.896 99.832q-.38-.589-.782-1.163c-4.987-7.158-12.187-13.939-19.697-20.67-8.381-7.51-17.27-14.919-24.487-22.654-6.118-6.57-10.96-13.28-12.3-20.367-1.41-7.493 1.614-14.626 9.632-21.361C32.985 8.81 40.654 4.598 49.518.75h-3.683c-.24.148-.458.306-.705.447-4.088 2.168-8.458 4.25-12.222 6.513-9.805 5.914-15.627 12.455-17.114 19.659-1.086 5.237.3 10.396 3.119 15.507 3.598 6.513 9.251 12.726 15.806 18.788 7.542 6.981 15.476 13.885 23.032 20.86 6.625 6.115 12.645 12.335 16.564 18.816.162-.51.37-1.018.581-1.508zM74.16 126.906c-2.746 3.619-6.696 7.048-11.764 10.287-.317.204-.589.42-.881.63l.437.16c.056-.089.06-.19.176-.261 5.138-3.12 9.237-6.386 12.208-9.812-.06-.335-.116-.67-.176-1.004z" />
            <path d="M41.151.75c-1.945 1.075-3.915 2.142-5.84 3.224-9.677 5.438-16.68 11.409-20.088 18.084-2.467 4.867-2.851 9.772-1.667 14.71 1.864 7.753 7.087 15.172 13.812 22.39 6.643 7.137 13.77 14.192 20.649 21.29C54.36 86.986 60.21 93.59 64.063 100.48c2.58 4.607 4.162 9.27 4.116 14.006-.088 8.976-6.203 17.184-17.953 24.67a1.508 1.508 0 0 1-.705.243 3.912 3.912 0 0 1 .487-.43c7.147-4.768 12.13-9.907 14.742-15.44a20.508 20.508 0 0 0 1.621-12.48c-1.582-9.35-8.307-18.153-16.413-26.784-7.348-7.827-15.154-15.577-22.555-23.394-5.787-6.104-10.605-12.335-13.565-18.81-3.877-8.458-3.916-16.835 2.516-25.107C20.834 11.2 27.96 6.057 36.904 1.342c.353-.176.596-.384.892-.575z" />
            <path d="M33.123.75c-.744.49-1.452.986-2.234 1.466-10.09 6.167-17.023 12.892-20.068 20.293a23.884 23.884 0 0 0-1.276 14.178C11.4 45.773 17.3 54.46 24.255 63.035c5.774 7.119 11.842 14.196 17.622 21.311 5.315 6.552 9.671 13.21 12.156 20.067 4.327 11.934 1.275 23.384-12.635 34.12a11.486 11.486 0 0 1-2.241 1.409 3.56 3.56 0 0 1 .43-.434q11.982-9.233 14.206-19.715c1.223-5.737.271-11.44-2.115-17.103-3.94-9.434-11.277-18.453-18.795-27.454-6.09-7.296-12.37-14.566-17.269-22.027-4.529-6.89-7.796-13.872-8.19-21.047Q6.507 15.354 28.862 1.409c.352-.208.6-.44.895-.663z" />
            <path d="M25.091.75c-1.762 1.43-3.598 2.85-5.286 4.3-7.55 6.495-12 13.35-13.516 20.557C4.992 31.767 5.83 37.889 7.935 44c2.82 8.233 7.993 16.233 13.226 24.233 4.994 7.634 10.038 15.264 14.7 22.933 3.651 5.99 5.921 12.127 6.848 18.326 1.593 10.65-2.097 20.91-12.998 30.623a2.467 2.467 0 0 1-.955.528c1.15-1.209 2.347-2.407 3.436-3.626 5.393-6.016 8.226-12.25 9.02-18.679.993-8.057-1.456-15.969-5.263-23.828-4.172-8.617-10.08-17.047-15.662-25.512-4.764-7.225-9.328-14.471-12.44-21.85-2.82-6.725-4.318-13.495-3.433-20.33 1.17-9.025 6.664-17.564 16.797-25.55.208-.166.352-.353.532-.511z" />
            <path d="M17.063.75c-2.097 2.442-4.406 4.86-6.249 7.337-4.25 5.706-6.594 11.567-7.545 17.537-1.09 6.844-.215 13.653 1.677 20.441 2.185 7.835 5.893 15.556 9.516 23.282C18.155 77.209 22 85.065 25.27 92.959c3.172 7.662 4.846 15.405 4.437 23.197-.451 8.55-3.64 16.896-10.605 24.956a1.512 1.512 0 0 1-.722.483c.863-1.216 1.78-2.425 2.583-3.648a42.415 42.415 0 0 0 6.527-16.346 41.752 41.752 0 0 0-.87-18.217 144.324 144.324 0 0 0-6.179-17.974c-3.82-9.068-8.458-18.076-12.232-27.137-2.362-5.677-3.955-11.415-5.544-17.145C1.189 35.84 1.03 30.49 1.739 25.159c1.1-8.293 4.797-16.37 11.796-24.156a1.762 1.762 0 0 0 .176-.267z" />
            <path d="M.664 40.504c.19.976.384 1.956.568 2.932.831 4.398 1.504 8.81 2.526 13.195 1.114 4.782 2.549 9.558 3.845 14.333 1.491 5.477 3.134 10.95 4.43 16.434 1.262 5.346 2.362 10.703 3.172 16.067.903 5.907 1.216 11.831.236 17.742a122.269 122.269 0 0 1-3.13 13.441c-.743 2.598-2.135 5.16-3.234 7.754l-.44-.032a29.301 29.301 0 0 0 1.152-2.95c1.23-4.542 2.664-9.078 3.524-13.635 1.244-6.562 1.085-13.142.306-19.715-.599-5.061-1.515-10.118-2.537-15.155-.98-4.838-2.23-9.667-3.447-14.499-1.216-4.831-2.611-9.744-3.803-14.622-.944-3.848-1.688-7.711-2.537-11.563-.176-.79-.426-1.579-.641-2.365zM9.03.75c-1.596 3.305-3.552 6.587-4.7 9.92C2.873 14.9 2.073 19.155.987 23.398c-.088.352-.215.705-.324 1.036v-9.868c.095-.11.233-.215.271-.328Q3.318 7.492 5.686.75zM.664 86.46a16.233 16.233 0 0 1 .275 1.964c.215 10.35.486 20.701.57 31.052.036 4.011-.351 8.025-.584 12.04a2.932 2.932 0 0 1-.26.757z" />
          </g>

          <path
            d="M1408.23 80.44h143.21v143.22A175.34 175.34 0 0 1 1376.1 399a143.21 143.21 0 0 1-143.21-143.21 175.34 175.34 0 0 1 175.34-175.35Z"
            style={{ fill: palette.primary, filter: "url(#page3-a)" }}
            transform="rotate(180 273.953 81.957) scale(.35243)"
          />

          <ClippedImage
            id="saphire-page-3"
            triggerImageEdit={triggerImageEdit}
            x={0}
            y={80}
            width={110}
            height={175}
            imageNumber={3}
            imageRatio={0.62}
            notebook={notebook}
          >
            <path
              d="M1371.19 201.36a166.7 166.7 0 0 1 166.7 166.7v369.4h-138.3a166.7 166.7 0 0 1-166.7-166.7V339.67a138.3 138.3 0 0 1 138.3-138.3z"
              style={{ fill: palette.backgroundLight }}
              transform="translate(-433.375 -5.055) scale(.35243)"
            />
          </ClippedImage>

          <path
            d="M1232.89 144.55h303.83v144.4H1269a36.07 36.07 0 0 1-36.07-36.07V144.55Z"
            style={{ fill: palette.backgroundLight, filter: "url(#page3-c)" }}
            transform="translate(-433.375 -5.055) scale(.35243)"
          />
          <path
            d="M1371.18 586.38h410.1v223.76h-374a36.07 36.07 0 0 1-36.07-36.07V586.38Z"
            style={{ fill: palette.backgroundLight, filter: "url(#page3-d)" }}
            transform="translate(-433.375 -5.055) scale(.35243)"
          />

          <foreignObject
            x={12.005}
            y={48.032}
            width={88}
            height={47}
            className="clickable"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                fontSize: "8.9259px",
                fontFamily: "Heavitas",
                color: palette.primary,
                strokeWidth: 0.352449,
                width: "100%",
                height: "24px",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.candidate_ballout_box_name,
                  field: "candidate_ballout_box_name",
                  label: "Nome de Urna",
                  type: "input",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.candidate_ballout_box_name}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                fontSize: "5.9259px",
                fontFamily: "MontserratAlternates",
                color: palette.backgroundGray,
                strokeWidth: 0.352449,
                width: "100%",
                marginTop: "5px",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.place,
                  field: "place",
                  label: "Munícipio",
                  type: "input",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.place}
            </div>
          </foreignObject>
          <text
            x={138.425}
            y={111.039}
            style={{
              fontSize: "7px",
              letterSpacing: "0.02em",
              fontFamily: "Heavitas",
              fill: palette.backgroundGray,
              strokeWidth: 0.35243,
            }}
          >
            {"ORIGEM"}
          </text>

          <foreignObject
            x={120.005}
            y={118.032}
            width={80}
            height={80}
            className="clickable"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: palette.backgroundGray,
                strokeWidth: 0.352449,
                width: "100%",
                height: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.birthplace,
                  field: "birthplace",
                  label: "Local de Nascimento",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.birthplace}
            </div>
          </foreignObject>

          <text
            x={80.934}
            y={216.913}
            style={{
              letterSpacing: "0.02em",
              fontSize: "7px",
              fontFamily: "Heavitas",
              fill: palette.backgroundGray,
              strokeWidth: 0.35243,
            }}
          >
            {"FORMAÇÃO"}
          </text>
          <path
            d="M1588 300.23c.68.13 1.37.23 2 .42a10.83 10.83 0 0 1 6.4 15.94l-8.52 14.32a.51.51 0 0 1-.46.26 1.41 1.41 0 0 1-1.6-.88c-2.67-4.59-5.41-9.14-8.12-13.7a10.83 10.83 0 0 1 1.28-12.75 10.64 10.64 0 0 1 6.92-3.55l.24-.06zm1.88 10.84a2.84 2.84 0 0 0-2.79-2.79 2.81 2.81 0 0 0-2.79 2.78 2.79 2.79 0 0 0 5.58 0z"
            style={{ fill: palette.backgroundGray }}
            transform="translate(-433.375 -5.055) scale(.35243)"
          />
          <path
            d="M1605 336.06h-35.84a4.46 4.46 0 0 1 .24-1.91c1.86-3.67 3.68-7.37 5.51-11.06a.48.48 0 0 1 .52-.33h3.18a.53.53 0 0 1 .55.3l5.89 9.92a.52.52 0 0 0 .52.29h3a.5.5 0 0 0 .49-.29q2.94-5 5.89-9.91a.53.53 0 0 1 .54-.31h3.15a.45.45 0 0 1 .48.29q2.8 5.61 5.63 11.2a3.87 3.87 0 0 0 .21.34zM1438.42 616.9l-4.51.91-8 1.61-4.36.85a7.13 7.13 0 0 1-2.83-.25l-9-1.78-9.64-2-1.59-.34a1.19 1.19 0 0 1-1.07-1.17 1.12 1.12 0 0 1 .94-1.18c1.33-.31 2.68-.57 4-.84l8.79-1.77c3-.6 5.94-1.26 8.93-1.74a8.57 8.57 0 0 1 2.92.26c2.54.47 5.07 1 7.6 1.52l9.32 1.89 3 .58c1 .18 1.42.84 1.15 1.68a1.15 1.15 0 0 1-.93.77c-.48.11-1 .19-1.47.28-.23 0-.35.13-.35.42v9a.83.83 0 0 0 .18.47 2.22 2.22 0 0 1 .12 2.77 2.192 2.192 0 1 1-3.5-2.64 1.14 1.14 0 0 0 .27-.78v-8.08z"
            style={{ fill: palette.backgroundGray }}
            transform="translate(-433.375 -5.055) scale(.35243)"
          />
          <path
            d="M1407.12 619.18c2.91.58 5.83 1.17 8.75 1.74a39.15 39.15 0 0 0 4.41.78 12.33 12.33 0 0 0 3.13-.32c2.82-.53 5.63-1.12 8.45-1.68l2.63-.52v7.45a1.5 1.5 0 0 1-.72 1.33 11.09 11.09 0 0 1-4.24 1.6 44.88 44.88 0 0 1-11.68.75 34.18 34.18 0 0 1-7-1 8.62 8.62 0 0 1-3.09-1.35 1.45 1.45 0 0 1-.68-1.21v-7.39a.48.48 0 0 1 .04-.18z"
            style={{ fill: palette.backgroundGray }}
            transform="translate(-433.375 -5.055) scale(.35243)"
          />
          <foreignObject
            x={60.005}
            y={223.032}
            width={125}
            height={53}
            className="clickable"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: palette.backgroundGray,
                strokeWidth: 0.352449,
                width: "100%",
                height: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.schooling,
                  field: "schooling",
                  label: "Onde estudou",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.schooling}
            </div>
          </foreignObject>
        </svg>
      </PaperInside>
    </Paper>
  );
};

export default Page3;
