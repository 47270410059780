import { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Pages, Status } from "../../../../../app/constants";
import { getYearsBetween, toDate, toPtBr } from "../../../../../app/utils";
import { makeContainer } from "../../../../../components/container/container";
import Loading from "../../../../../components/loading/loading";
import CandidateBalloutNameText from "../../../../../components/text/candidate-ballout-name-text/candidate-ballout-name-text";
import CandidateNameText from "../../../../../components/text/candidate-name-text/candidate-name-text";
import WidgetTitleText from "../../../../../components/text/widget-title-text/widget-title-text";
import CandidateService from "../../../../../features/candidates/service";
import { Candidate } from "../../../../../features/candidates/type";
import Sillouete from "../../../../../resources/icons/user-silhouette.svg";
import "./candidate-profile-card.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  status?: Status;
  candidate?: Candidate;
};

const CandidateProfileCardContainer = makeContainer(
  "candidate-profile-card-container"
);
const CandidateProfileCardSubContainer = makeContainer(
  "candidate-profile-card-sub-container"
);
const ImagePane = makeContainer("candidate-profile-card-image-pane");
const DetailsPane = makeContainer("candidate-profile-card-details-pane");
const WidgetTitleContainer = makeContainer(
  "candidate-profile-card-title-container"
);

const CandidateProfileCard: React.FC<Props> = ({ status, candidate }) => {
  const [candidatePhoto, setCandidatePhoto] = useState(Sillouete);
  const age = getYearsBetween(toDate(candidate?.dateofbirth));
  const totalVoltes = candidate?.election_summary?.reduce(
    (total, election) => election.num_votes + total,
    0
  );

  useEffect(() => {
    const fetchPhoto = async () => {
      if (candidate) {
        const service = new CandidateService();
        const photo = await service.getCandidatePhoto(candidate.id);
        if (photo) {
          setCandidatePhoto(`data:image/png;base64,${photo}`);
        }
      }
    };

    fetchPhoto();
  }, [candidate]);

  return (
    <CandidateProfileCardContainer>
      {status === Status.LOADING && <Loading />}
      {status === Status.SUCCEEDED && (
        <>
          <WidgetTitleContainer>
            <WidgetTitleText>{"Info do Candidato"}</WidgetTitleText>
          </WidgetTitleContainer>
          <CandidateProfileCardSubContainer>
            <ImagePane>
              <img
                className="candidate-profile-card-image"
                alt="foto do candidato"
                src={candidatePhoto}
              />
            </ImagePane>
            <DetailsPane>
              <CandidateBalloutNameText>
                {candidate?.ballotboxname}
              </CandidateBalloutNameText>
              <CandidateNameText>{candidate?.name}</CandidateNameText>
              <CandidateNameText>
                {`${age} anos | ${toPtBr(totalVoltes)} votos`}
              </CandidateNameText>
              <CandidateNameText>
                <Link
                  to={`/${Pages.VOTING}?candidateId=${candidate?.id}&candidate=${candidate?.ballotboxname}`}
                >
                  {`Ver Mapa`}
                </Link>
              </CandidateNameText>
            </DetailsPane>
          </CandidateProfileCardSubContainer>
        </>
      )}
    </CandidateProfileCardContainer>
  );
};

export default memo(CandidateProfileCard);
