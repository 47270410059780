import { memo, MouseEventHandler } from "react";
import "./login-text-button.css";

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children: string;
};

const LoginPageTextButton: React.FC<Props> = (props) => {
  const { onClick, children } = props;

  return (
    <button className={"login-text-button"} onClick={onClick}>
      {children}
    </button>
  );
};

export default memo(LoginPageTextButton);
