import { Store } from "@reduxjs/toolkit";
import axios from "axios";
import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import { Pages } from "../../app/constants";
import handleError, { PlatformError } from "../../app/errors";
import { trimStringFields } from "../../app/utils";
import { setAlert } from "../alert/slice";
import { AlertType } from "../alert/type";
import {
  AskResetData,
  IAuthService,
  ResetData,
  SignupData,
  UserCredentials,
  UserData,
} from "./type";

export default class AuthService implements IAuthService {
  async initAuth() {
    // Firebase configuration
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    };

    // Initializing firebase app
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);

    return auth;
  }

  async login(credentials: UserCredentials) {
    try {
      // Trimming
      credentials = trimStringFields(credentials) as UserCredentials;

      // Initializing auth
      const auth = await this.initAuth();

      // Signing on firebase to get the token
      const firebaseUserResponse = await signInWithEmailAndPassword(
        auth,
        credentials.username,
        credentials.password
      );

      // Getting refresh token
      const refreshToken =
        firebaseUserResponse["_tokenResponse"]["refreshToken"];
      const token = await firebaseUserResponse.user.getIdToken();

      return this.loginToDataElege(token, refreshToken, credentials.rememberMe);
    } catch (error) {
      handleError("Error during loging in", error);
    }
  }

  async loginWithGoogle(rememberMe: boolean) {
    try {
      // Initializing auth
      const auth = await this.initAuth();

      // Creating google provider
      const provider = new GoogleAuthProvider();
      const firebaseUserResponse = await signInWithPopup(auth, provider);

      const refreshToken =
        firebaseUserResponse["_tokenResponse"]["refreshToken"];
      const token = await firebaseUserResponse.user.getIdToken();

      return this.loginToDataElege(token, refreshToken, rememberMe);
    } catch (error) {
      handleError("Error during google loging in", error);
    }
  }

  async loginWithFacebook(rememberMe: boolean) {
    try {
      // Initializing auth
      const auth = await this.initAuth();

      // Creating google provider
      const provider = new FacebookAuthProvider();
      const firebaseUserResponse = await signInWithPopup(auth, provider);

      const refreshToken =
        firebaseUserResponse["_tokenResponse"]["refreshToken"];
      const token = await firebaseUserResponse.user.getIdToken();

      return this.loginToDataElege(token, refreshToken, rememberMe);
    } catch (error) {
      handleError("Error during google loging in", error);
    }
  }

  async refreshLogin(userData: UserData) {
    try {
      const refreshResponse = await axios.post(
        `https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
        {
          grant_type: "refresh_token",
          refresh_token: userData.refreshToken,
        },
        {
          withCredentials: false,
        }
      );

      const token = refreshResponse["data"]["access_token"];
      const refreshToken = refreshResponse["data"]["refresh_token"];
      return this.loginToDataElege(token, refreshToken, userData.rememberMe);
    } catch (error) {
      handleError("Error refreshing token", error);
    }
  }

  async loginToDataElege(
    token: string,
    refreshToken: string,
    rememberMe: boolean
  ) {
    // Login to dataelege api to set up the cookies
    const dataelegeLoginResponse = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/login`,
      {
        token: token,
      }
    );

    // Returning user data
    const userData: UserData = {
      id: dataelegeLoginResponse.data.id,
      name: dataelegeLoginResponse.data.full_name,
      email: dataelegeLoginResponse.data.email,
      photoUrl: dataelegeLoginResponse.data.photo_url,
      refreshToken: refreshToken,
      rememberMe: rememberMe,
    };

    return userData;
  }

  async signup(data: SignupData) {
    try {
      //trimming data
      data = trimStringFields(data) as SignupData;

      // Initializing auth
      const auth = await this.initAuth();

      if (!data.name) {
        throw new PlatformError("auth/missing-display-name");
      }

      if (data.password.length < 8) {
        throw new PlatformError("auth/password-too-short");
      }

      // Signing on firebase to get the token
      const firebaseUserResponse = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );

      // Getting refresh token
      const refreshToken =
        firebaseUserResponse["_tokenResponse"]["refreshToken"];
      const token = await firebaseUserResponse.user.getIdToken();

      await updateProfile(firebaseUserResponse.user, {
        displayName: data.name,
      });
      return this.loginToDataElege(token, refreshToken, data.rememberMe);
    } catch (error) {
      handleError("Error during signup in", error);
    }
  }

  async askResetPassword(data: AskResetData, store: Store) {
    try {
      data = trimStringFields(data) as AskResetData;

      await axios.post(`${process.env.REACT_APP_API_URL}/user/password/`, {
        email: data.email,
      });

      store.dispatch(
        setAlert({
          message:
            "Caso o email seja válido, uma mensagem de redefinição de senha será enviada.",
          type: AlertType.MESSAGE,
        })
      );
    } catch (error) {
      handleError("Error while asking for reset code", error);
    }
  }

  async resetPassword(data: ResetData, store: Store) {
    try {
      data = trimStringFields(data) as ResetData;

      if (data.new_password.length < 8) {
        throw new PlatformError("auth/password-too-short");
      }

      if (data.new_password !== data.new_password_repeated) {
        throw new PlatformError("auth/password-do-not-match");
      }

      await axios.put(`${process.env.REACT_APP_API_URL}/user/password/reset`, {
        user_id: data.user_id,
        reset_code: data.reset_code,
        new_password: data.new_password,
      });

      store.dispatch(
        setAlert({
          message: "Senha redefinida com sucesso. Redirecionando para o login.",
          type: AlertType.MESSAGE,
        })
      );

      setTimeout(() => (window.location.href = `/${Pages.LOGIN}`), 5000);
    } catch (error) {
      handleError("Error while reseting password", error);
    }
  }

  async updatePassword(
    newPassword: string,
    newPasswordRepeat: string,
    store: Store
  ) {
    try {
      newPassword = newPassword?.trim();
      newPasswordRepeat = newPasswordRepeat?.trim();

      if (newPassword.length < 8) {
        throw new PlatformError("auth/password-too-short");
      }

      if (newPassword !== newPasswordRepeat) {
        throw new PlatformError("auth/password-do-not-match");
      }

      await axios.put(`${process.env.REACT_APP_API_URL}/user/password/update`, {
        new_password: newPassword,
      });

      store.dispatch(
        setAlert({
          message: "Senha atualizada com sucesso.",
          type: AlertType.MESSAGE,
        })
      );
    } catch (error) {
      handleError("Error while updating password", error);
    }
  }
}
