import { memo } from "react";

type Props = {
  space: number;
};

const ShopSpacer: React.FC<Props> = ({ space }) => {
  return (
    <div
      style={{
        width: "1px",
        height: `${space}px`,
      }}
    ></div>
  );
};

export default memo(ShopSpacer);
