import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import {
  NOT_FOUND,
  Pages,
  PartiesFilterConsts,
  Status,
} from "../../../app/constants";
import { ActiveFilter, hasFilter } from "../../../app/filter";
import { AppDispatch } from "../../../app/store";
import ChangePaneButton from "../../../components/buttons/change-pane-button/change-pane-button";
import { makeContainer } from "../../../components/container/container";
import Page from "../../../components/main-page/page";
import TitleText from "../../../components/text/title-text/title-text";
import WidgetTitleText from "../../../components/text/widget-title-text/widget-title-text";
import PartyService from "../../../features/parties/service";
import {
  getParties,
  getPartiesProfileData,
  selectParties,
  selectPartiesDataFilter,
  selectPartiesProfileData,
  selectPartiesStatus,
} from "../../../features/parties/slice";
import { Party, PartyProfileData } from "../../../features/parties/type";
import PartyCandidateChart from "./components/party-candidate-chart/party-candidates-chart";
import PartyCandidatesSexChart from "./components/party-candidates-sex-chart/party-candidates-sex-chart";
import PartyProfileCard from "./components/party-profile-card/party-profile-card";
import PartyProfileFilter from "./components/party-profile-filter/party-profile-filter";
import PartyProfileSummary from "./components/party-profile-summary/party-profile-summary";
import PartyVotingChart from "./components/party-voting-chart/party-voting-chart";
import "./party-profile.css";

type PaneProps = {
  children?: string | JSX.Element | JSX.Element[];
  show: boolean;
};

const PageContainer = makeContainer("party-profile-page-container");

const LeftPane: React.FC<PaneProps> = ({ children, show }) => {
  return (
    <div
      className={
        show ? "party-profile-left-pane" : "party-profile-left-pane-hidden"
      }
    >
      {children}
    </div>
  );
};

const RightPane: React.FC<PaneProps> = ({ children, show }) => {
  return (
    <div
      className={
        show ? "party-profile-right-pane" : "party-profile-right-pane-hidden"
      }
    >
      {children}
    </div>
  );
};

const PartyInfoContainer = makeContainer("party-info-container");
const PartyVotesChartContainer = makeContainer("party-votes-chart-container");
const PartyCandidatesChartContainer = makeContainer(
  "party-candidates-chart-container"
);
const PartyCandidatesSexChartContainer = makeContainer(
  "party-candidates-sex-chart-container"
);
const WidgetTitleContainer = makeContainer(
  "party-profile-widget-title-container"
);

const PartyProfile: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { id } = useParams();
  const [showRightPane, setShowRightPane] = useState(false);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1024px)" });
  const parties: Party[] = useSelector(selectParties);
  const status: Status = useSelector(selectPartiesStatus);
  const partyProfileData: PartyProfileData = useSelector(
    selectPartiesProfileData
  );
  const partyProfileFilter: ActiveFilter[] = useSelector(
    selectPartiesDataFilter
  );

  const getPartyData = () => {
    const result = parties?.filter((party) => party.id === id);

    if (!result) {
      window.location.href = NOT_FOUND;
    }
    return result ? result[0] : null;
  };

  useEffect(() => {
    const fetchParties = () => {
      dispatch(getParties({ service: new PartyService() }));
    };

    fetchParties();
  }, [dispatch]);

  useEffect(() => {
    const fetchPartyProfile = () => {
      if (hasFilter(partyProfileFilter, PartiesFilterConsts.PARTY_ID)) {
        dispatch(getPartiesProfileData({ service: new PartyService() }));
      }
    };

    fetchPartyProfile();
  }, [dispatch, partyProfileFilter]);

  return (
    <Page
      title={<TitleText>{"Perfil do Partido"}</TitleText>}
      page={Pages.PARTY_PROFILE}
    >
      <PageContainer>
        <ChangePaneButton
          isLeft={!showRightPane}
          onClick={() => setShowRightPane(!showRightPane)}
        />
        <LeftPane show={!showRightPane || !isSmallScreen}>
          <PartyProfileFilter />
          <PartyInfoContainer>
            <PartyProfileCard party={getPartyData()} />
            <PartyProfileSummary summary={partyProfileData} status={status} />
          </PartyInfoContainer>
          <PartyVotesChartContainer>
            <WidgetTitleContainer>
              <WidgetTitleText>{"Candidaturas por ano"}</WidgetTitleText>
            </WidgetTitleContainer>
            <PartyCandidateChart summary={partyProfileData} status={status} />
          </PartyVotesChartContainer>
        </LeftPane>
        <RightPane show={showRightPane || !isSmallScreen}>
          <PartyCandidatesChartContainer>
            <WidgetTitleContainer>
              <WidgetTitleText>{"Votação por ano"}</WidgetTitleText>
            </WidgetTitleContainer>
            <PartyVotingChart summary={partyProfileData} status={status} />
          </PartyCandidatesChartContainer>
          <PartyCandidatesSexChartContainer>
            <WidgetTitleContainer>
              <WidgetTitleText>{"Candidatos por sexo"}</WidgetTitleText>
            </WidgetTitleContainer>
            <PartyCandidatesSexChart
              summary={partyProfileData}
              status={status}
            />
          </PartyCandidatesSexChartContainer>
        </RightPane>
      </PageContainer>
    </Page>
  );
};

export default PartyProfile;
