import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pages } from "../../app/constants";
import { AppDispatch } from "../../app/store";
import { makeContainer } from "../../components/container/container";
import Page from "../../components/main-page/page";
import SettingsTitleText from "../../components/text/settings-title-text/settings-title-text";
import TitleText from "../../components/text/title-text/title-text";
import SettingsService from "../../features/settings/service";
import {
  getSettings,
  selectSettings,
  updateSettings,
} from "../../features/settings/slice";
import ChoroplethSettingsForm from "./components/choropleth-settings-form/choropleth-settings-form";
import MarkSettingsForm from "./components/mark-settings-form/mark-settings-form";
import "./settings.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
};

const PageContainer = makeContainer("settings-page-container");

const SettingsTopContainer: React.FC<Props> = ({ children }) => {
  return (
    <div className="settings-top-container">
      <SettingsTitleText>Mapa Cloroplético</SettingsTitleText>
      <div className="settings-top-inner-container">{children}</div>
    </div>
  );
};

const SettingsBottomContainer: React.FC<Props> = ({ children }) => {
  return (
    <div className="settings-bottom-container">
      <SettingsTitleText>Marcadores</SettingsTitleText>
      <div className="settings-bottom-inner-container">{children}</div>
    </div>
  );
};

const Settings: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const settings = useSelector(selectSettings);

  const onSettingsFieldChange = (field: string, value: any) => {
    const newSettings = { ...settings, [field]: value };

    dispatch(
      updateSettings({
        settings: newSettings,
        service: new SettingsService(),
      })
    );
  };

  useEffect(() => {
    dispatch(getSettings(new SettingsService()));
  }, [dispatch]);

  return (
    <Page
      title={<TitleText>{"Configurações"}</TitleText>}
      page={Pages.SETTINGS}
    >
      <PageContainer>
        <SettingsTopContainer>
          <ChoroplethSettingsForm
            settings={settings}
            onSettingsFieldChange={onSettingsFieldChange}
          />
        </SettingsTopContainer>
        <SettingsBottomContainer>
          <MarkSettingsForm
            settings={settings}
            onSettingsFieldChange={onSettingsFieldChange}
          />
        </SettingsBottomContainer>
      </PageContainer>
    </Page>
  );
};

export default Settings;
