import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../app/store";
import NotebookButton from "../../../../../components/buttons/notebook-button/notebook-button";
import { makeContainer } from "../../../../../components/container/container";

import {
  closeModal,
  setModalContent,
} from "../../../../../features/modal/slice";

import { Status } from "../../../../../app/constants";
import NotebookInputTextArea from "../../../../../components/inputs/notebook-input-text-area/notebook-input-text-area";
import NotebookInputText from "../../../../../components/inputs/notebook-input-text/notebook-input-text";
import NotebookSelectText from "../../../../../components/inputs/notebook-select-text/notebook-select-text";
import Loading from "../../../../../components/loading/loading";
import { selectNotebookStatus } from "../../../../../features/notebook/slice";
import "./change-field-modal.css";

type ModalProps = {
  show: boolean;
  props: ChangeFieldProps;
  onClose: () => void;
};

export type ChangeFieldProps = {
  field: string;
  label: string;
  currentValue: any;
  type: "select" | "input" | "textarea";
  options?: any[];
  onUpdateField: (field: string, newValue: string) => void;
  validate: (value: any) => boolean;
};

const ChangeFieldModalContainer = makeContainer("change-field-modal-container");
const ChangeFieldButtonsContainer = makeContainer(
  "change-field-modal-buttons-container"
);
const ChangeFieldTopContainer = makeContainer(
  "change-field-modal-top-container"
);
const ChangeFieldTopText = makeContainer("change-field-modal-top-text");
const ChangeFieldTitle = makeContainer("change-field-modal-title");
const ContentContainer = makeContainer("change-field-modal-content-container");

const ChangeFieldForm: React.FC<ModalProps> = ({ show, onClose, props }) => {
  const { field, label, currentValue, type, options, validate, onUpdateField } =
    props;

  const [newValue, setNewValue] = useState(currentValue);
  const [hasStartUpdating, setHasStartUpdating] = useState(false);
  const status: Status = useSelector(selectNotebookStatus);

  useEffect(() => {
    if (status === Status.SUCCEEDED && hasStartUpdating) {
      onClose();
    }
  }, [hasStartUpdating, status, onClose]);

  return (
    <ChangeFieldModalContainer>
      <ChangeFieldTopContainer>
        <ChangeFieldTopText>Alterar Caderno</ChangeFieldTopText>
        <ChangeFieldTitle>{label}</ChangeFieldTitle>
      </ChangeFieldTopContainer>
      <ContentContainer>
        {status !== Status.LOADING && type === "select" && (
          <div style={{ marginBottom: "250px" }}>
            <NotebookSelectText
              inputKey={"modal-input"}
              label=""
              options={options}
              value={options.find((f) => f.value === newValue)}
              onChange={(e) => {
                setNewValue(e.value);
              }}
              placeHolder={label}
              className="change-field-modal-select"
              disableClear={true}
            />
          </div>
        )}
        {status !== Status.LOADING && type === "input" && (
          <div>
            <NotebookInputText
              inputKey={"modal-input"}
              placeHolder={label}
              value={newValue}
              invalid={!validate(newValue)}
              onChange={(e) => {
                setNewValue(e.target.value);
              }}
            />
          </div>
        )}
        {status !== Status.LOADING && type === "textarea" && (
          <div>
            <NotebookInputTextArea
              inputKey={"modal-input"}
              placeHolder={label}
              value={newValue}
              invalid={!validate(newValue)}
              onChange={(e) => {
                setNewValue(e.target.value);
              }}
            />
          </div>
        )}
        {status === Status.LOADING && <Loading />}
      </ContentContainer>
      <ChangeFieldButtonsContainer>
        <NotebookButton
          selected
          className="change-field-modal-input-button"
          disabled={newValue === currentValue || !validate(newValue)}
          onClick={() => {
            onUpdateField(field, newValue);
            setHasStartUpdating(true);
          }}
        >
          Salvar
        </NotebookButton>
        <NotebookButton
          className="change-field-modal-input-button"
          onClick={onClose}
        >
          Fechar
        </NotebookButton>
      </ChangeFieldButtonsContainer>
    </ChangeFieldModalContainer>
  );
};

const ChangeFieldModal: React.FC<ModalProps> = memo(
  ({ show, onClose, props }) => {
    const dispatch: AppDispatch = useDispatch();

    const close = () => {
      onClose();
      dispatch(closeModal(null));
    };

    useEffect(() => {
      const changeModalStatus = () => {
        if (show) {
          dispatch(
            setModalContent({
              content: (
                <ChangeFieldForm show={show} props={props} onClose={close} />
              ),
              onClose: () => {},
            })
          );
        } else {
          dispatch(closeModal(null));
        }
      };

      changeModalStatus();
      // eslint-disable-next-line
    }, [dispatch, onClose, show]);

    return <></>;
  }
);

export default ChangeFieldModal;
