import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Status } from "../../app/constants";
import { debounceThunk } from "../../app/utils";
import {
  AskResetData,
  IAuthService,
  ResetData,
  SignupData,
  UserCredentials,
  UserData,
} from "./type";

const userData: UserData | null = null;

// Slice's initial state
const initialState = {
  isLogged: false,
  status: Status.IDLE,
  userData: userData,
  error: "",
};

type LoginParams = { credentials: UserCredentials; service: IAuthService };
type SignupParams = { data: SignupData; service: IAuthService };
type AskResetParams = { data: AskResetData; service: IAuthService };
type ResetParams = { data: ResetData; service: IAuthService };
type SocialLoginParams = { rememberMe: boolean; service: IAuthService };
type RefreshLoginParams = { userData: UserData; service: IAuthService };

// Login's thunk
const loginThunk = createAsyncThunk<UserData, LoginParams>(
  "auth/login",
  async (params) => {
    const { credentials, service } = params;
    const response = await service.login(credentials);
    return response;
  }
);

// Signup's thunk
const signupThunk = createAsyncThunk<UserData, SignupParams>(
  "auth/signup",
  async (params) => {
    const { data, service } = params;
    const response = await service.signup(data);
    return response;
  }
);

// Ask reset thunk
const askResetPasswordThunk = createAsyncThunk<void, AskResetParams>(
  "auth/askreset",
  async (params, store) => {
    const { data, service } = params;
    await service.askResetPassword(data, store);
  }
);

// Reset password thunk
const resetPasswordThunk = createAsyncThunk<void, ResetParams>(
  "auth/reset",
  async (params, store) => {
    const { data, service } = params;
    service.resetPassword(data, store);
  }
);

// Login's thunk
const googleLoginThunk = createAsyncThunk<UserData, SocialLoginParams>(
  "auth/googleLogin",
  async (params) => {
    const { rememberMe, service } = params;
    const response = await service.loginWithGoogle(rememberMe);
    return response;
  }
);

// Login's thunk
export const facebookLoginThunk = createAsyncThunk<UserData, SocialLoginParams>(
  "auth/facebookLogin",
  async (params) => {
    const { rememberMe, service } = params;
    const response = await service.loginWithFacebook(rememberMe);
    return response;
  }
);

// Refresh Login's thunk
const refreshLoginThunk = createAsyncThunk<UserData, RefreshLoginParams>(
  "auth/refresh",
  async (params) => {
    const { userData, service } = params;
    const response = await service.refreshLogin(userData);
    return response;
  }
);

// Selectors
export const selectIsLogged = (state) => state.auth.isLogged;
export const selectAuthUserData = (state) => state.auth.userData;
export const selectAuthStatus = (state) => state.auth.status;
export const selectAuthError = (state) => state.auth.error;

// Reducers
const loading = (state, action) => {
  state.status = Status.LOADING;
};

const successfull = (state, action) => {
  state.status = Status.SUCCEEDED;
  state.isLogged = true;
  state.userData = action.payload;
  state.error = "";
};

const successfullAskReset = (state, action) => {
  state.status = Status.SUCCEEDED;
  state.isLogged = false;
};

const rejected = (state, action) => {
  state.status = Status.FAILED;
  state.isLogged = false;
  state.error = action.error.message;
  state.userData = null;
};

// Slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state, action) {
      state.isLogged = false;
      state.userData = null;
    },
    cleanUp(state, action) {
      state.status = Status.IDLE;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loginThunk.pending, loading)
      .addCase(loginThunk.fulfilled, successfull)
      .addCase(loginThunk.rejected, rejected)
      .addCase(googleLoginThunk.pending, loading)
      .addCase(googleLoginThunk.fulfilled, successfull)
      .addCase(googleLoginThunk.rejected, rejected)
      .addCase(refreshLoginThunk.pending, loading)
      .addCase(refreshLoginThunk.fulfilled, successfull)
      .addCase(refreshLoginThunk.rejected, rejected)
      .addCase(facebookLoginThunk.pending, loading)
      .addCase(facebookLoginThunk.fulfilled, successfull)
      .addCase(facebookLoginThunk.rejected, rejected)
      .addCase(signupThunk.pending, loading)
      .addCase(signupThunk.fulfilled, successfull)
      .addCase(signupThunk.rejected, rejected)
      .addCase(askResetPasswordThunk.pending, loading)
      .addCase(askResetPasswordThunk.fulfilled, successfullAskReset)
      .addCase(askResetPasswordThunk.rejected, rejected)
      .addCase(resetPasswordThunk.pending, loading)
      .addCase(resetPasswordThunk.fulfilled, successfullAskReset)
      .addCase(resetPasswordThunk.rejected, rejected);
  },
});

export const { logout, cleanUp } = authSlice.actions;
export const login = debounceThunk(loginThunk);
export const googleLogin = debounceThunk(googleLoginThunk);
export const refreshLogin = debounceThunk(refreshLoginThunk);
export const facebookLogin = debounceThunk(facebookLoginThunk);
export const signup = debounceThunk(signupThunk);
export const askResetPassword = debounceThunk(askResetPasswordThunk);
export const resetPassword = debounceThunk(resetPasswordThunk);

export default authSlice.reducer;
