import {
  forwardRef,
  memo,
  MouseEventHandler,
  useImperativeHandle,
  useRef,
} from "react";
import "./map-button.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  className?: string;
  style?: React.CSSProperties;
  ref?: any;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export type Ref = HTMLButtonElement;

const MapButton = forwardRef<Ref, Props>((props, ref) => {
  const { onClick, children, className, style } = props;
  const buttonRef = useRef(null);

  useImperativeHandle(ref, () => buttonRef.current);

  return (
    <button
      ref={buttonRef}
      className={`map-button ${className}`}
      onClick={onClick}
      style={style}
    >
      {children}
    </button>
  );
});

export default memo(MapButton);
