import { memo, MouseEventHandler } from "react";
import "./shop-cart-delete-text-button.css";

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children: string;
};

const ShopCartDeleteTextButton: React.FC<Props> = (props) => {
  const { onClick, children } = props;

  return (
    <button className={"shop-cart-delete-text-button"} onClick={onClick}>
      {children}
    </button>
  );
};

export default memo(ShopCartDeleteTextButton);
