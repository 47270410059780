import { memo } from "react";
import { TargetGroupType } from "../../../app/constants";
import "./target-mini-select.css";

type Props = {
  className?: string;
  value?: any;
  onChange: any;
};

type ContainerProps = {
  children?: string | JSX.Element | JSX.Element[];
  className?: string;
};

const TargetMiniSelectContainer: React.FC<ContainerProps> = ({
  children,
  className,
}) => {
  return (
    <div className={`target-mini-select-outer-container ${className}`}>
      {children}
    </div>
  );
};

const TargetMiniSelect: React.FC<Props> = ({ className, value, onChange }) => {
  return (
    <TargetMiniSelectContainer className={className}>
      <hr className={"target-mini-select-line"} />
      <select
        className={"target-mini-select"}
        value={value}
        onChange={onChange}
      >
        <option value={TargetGroupType.NO_GROUP}>Sem Grupo</option>
        <option value={TargetGroupType.ADEQUACY}>Adequação</option>
        <option value={TargetGroupType.PRIORITY}>Prioridade</option>
      </select>
    </TargetMiniSelectContainer>
  );
};

export default memo(TargetMiniSelect);
