import React, { memo, useEffect, useState } from "react";
import { GoAlert } from "react-icons/go";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import {
  getTargetAdequacyLevel,
  getTargetAdequacySettings,
} from "../../../app/target";
import { fromPtBr, isValidInitialTarget, toPtBr } from "../../../app/utils";
import { setAlert } from "../../../features/alert/slice";
import { AlertType } from "../../../features/alert/type";
import { CompleteTargetDetails } from "../../../features/target/type";
import { makeContainer } from "../../container/container";
import WidgetItemText from "../../text/widget-item-text/widget-item-text";
import Tooltip from "../../tooltip/tooltip";
import "./target-widget-item.css";

type Props = {
  details?: CompleteTargetDetails;
  disabled: Boolean;
  onChange: (value: number, target: CompleteTargetDetails) => void;
};

const WidgetItemContainer = makeContainer("target-widget-item-container");
const WidgetItemTextContainer = makeContainer(
  "target-widget-item-text-container"
);
const WidgetTargetInputContainer = makeContainer(
  "target-widget-input-container"
);

const TargetWidgetInput: React.FC<Props> = ({
  details,
  disabled,
  onChange,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [value, setValue] = useState(toPtBr(details.value));
  const [sendTimeout, setSendTimeout] = useState(null);
  const adequacyLevel = getTargetAdequacyLevel(fromPtBr(value), details);
  const adequacySettings = getTargetAdequacySettings(adequacyLevel);
  const style = { color: adequacySettings.adequacyColor };

  const handleChange = (e) => {
    if (disabled) {
      dispatch(
        setAlert({
          message: `Save a meta para poder ajustá-la`,
          type: AlertType.WARNING,
        })
      );
      return;
    }

    let newValue = 0;
    const value = fromPtBr(e.target.value);

    if (isValidInitialTarget(value.toString())) {
      newValue = value;
    }

    clearTimeout(sendTimeout);
    setSendTimeout(setTimeout(() => onChange(newValue, details), 1000));

    setValue(toPtBr(newValue));
  };

  useEffect(() => {
    setValue(toPtBr(details.value));
  }, [details]);

  return (
    <div className="target-widget-input-container" style={style}>
      <Tooltip text={adequacySettings.adequacyLevel} title="">
        <GoAlert className="target-widget-input-icon" />
      </Tooltip>
      <input
        className="target-widget-input"
        style={style}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

const TargetWidgetItem: React.FC<Props> = ({ details, disabled, onChange }) => {
  return (
    <WidgetItemContainer>
      <WidgetItemTextContainer>
        <WidgetItemText>{details.name}</WidgetItemText>
      </WidgetItemTextContainer>
      <WidgetTargetInputContainer>
        <TargetWidgetInput
          details={details}
          disabled={disabled}
          onChange={onChange}
        />
      </WidgetTargetInputContainer>
    </WidgetItemContainer>
  );
};

export default memo(TargetWidgetItem);
