import { memo, MouseEventHandler } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import "./change-pane-button.css";

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isLeft: boolean;
};

const ChangePaneButton: React.FC<Props> = (props) => {
  const { onClick, isLeft } = props;

  return (
    <button className="change-pane-button" onClick={onClick}>
      {isLeft ? <BiChevronRight /> : <BiChevronLeft />}
    </button>
  );
};

export default memo(ChangePaneButton);
