import { setAlert } from "../features/alert/slice";
import { AlertType } from "../features/alert/type";
import { setFilters } from "../features/dashboard/slice";
import { MapAttributeData, QueryResult } from "../features/dashboard/types";
import { GeoProperties, RawGeoMap } from "../features/maps/types";
import { PermissionsData } from "../features/permission/type";
import Attribute from "./attribute";
import { BR_STATES, DashboardFilterConsts, Status } from "./constants";
import { ActiveFilter, add, create, createValue, remove } from "./filter";
import { Feature } from "./maps";
import { AppDispatch } from "./store";
import {
  calculateEnvelope,
  convertPolygons,
  getCurrentLevelOfDetail,
  getDrillDownFilter,
  getRollUpFilter,
  toPtBr,
} from "./utils";

export const toGeoMapDataItems = (
  comparativeMode: boolean,
  rawMapsData: RawGeoMap<GeoProperties>,
  mapData: QueryResult,
  attribute: Attribute
): [Feature[], number[][]] => {
  // Se os dados não estão disponíveis
  if (!mapData || !rawMapsData) {
    return null;
  }

  const dataSource = (
    comparativeMode && mapData.comparative_result
      ? mapData.comparative_result
      : mapData.scenario_result
  ) as MapAttributeData[];

  let features: Feature[] = rawMapsData?.features
    .filter((m) => m.geometry)
    .map((mapItem) => {
      const type = mapItem?.geometry?.type;
      const coordinates = mapItem?.geometry?.coordinates ?? [];
      const polygons = convertPolygons(type, coordinates);

      const record = dataSource.find(
        (mapDataItem) =>
          mapDataItem.id === mapItem.properties.id ||
          (mapDataItem.id === mapItem.properties.zone &&
            mapItem.properties.zone)
      );

      const isZone = type !== "Point" && mapItem.properties.zone;
      const id = isZone ? mapItem.properties.zone : mapItem.properties.id;
      const name = isZone
        ? `${mapItem.properties.zone}ª Zona`
        : mapItem.properties.name;
      const value = `${toPtBr(record?.value) ?? 0} ${attribute?.suffix}`;
      const numericalValue = record?.value ?? 0;
      const dataelegeScaleValue = record?.dataelege_scale_value ?? 0;
      const linearScaleValue = record?.linear_scale_value ?? 0;
      const centroid: any =
        type === "Point"
          ? [mapItem.geometry.coordinates[1], mapItem.geometry.coordinates[0]]
          : [mapItem.properties.centroid_y, mapItem.properties.centroid_x];

      return {
        type,
        id,
        name,
        value,
        numericalValue,
        dataelegeScaleValue,
        linearScaleValue,
        centroid,
        polygons,
      };
    });

  const envelope = calculateEnvelope(features);
  return [features, envelope];
};

export const drillDownMap = (
  dispatch: AppDispatch,
  permissions: PermissionsData,
  feature: Feature,
  dashFilters: ActiveFilter[],
  dashComparativeFilters: ActiveFilter[],
  zoneMode: boolean,
  setStatus
) => {
  const level = getCurrentLevelOfDetail(dashFilters, zoneMode);
  const filter = getDrillDownFilter(level);
  const filterValue = createValue(feature.name, feature.id);

  if (!filter) {
    dispatch(
      setAlert({
        message: "Não é possível detalhar mais",
        type: AlertType.WARNING,
      })
    );
    return;
  }

  let newFilters = add(dashFilters, create(filter, filterValue));
  let newCompFilters = add(dashComparativeFilters, create(filter, filterValue));

  if (filter.value === DashboardFilterConsts.STATE) {
    const stateAbbrev = BR_STATES[feature.name.toUpperCase()];
    const perms = permissions.statePermissions.find(
      (statePermission) => statePermission.state === stateAbbrev
    );

    if (!perms) {
      dispatch(
        setAlert({
          message: "É preciso adquirir um plano para ver este estado",
          type: AlertType.WARNING,
        })
      );
      return;
    }

    let selectedYears = newFilters
      .filter((f) => f.name.value === "year")
      .map((f) => f.value.value);
    selectedYears = selectedYears.concat(
      newCompFilters
        .filter((f) => f.name.value === "year")
        .map((f) => f.value.value)
    );

    const allYearsArePermitted = selectedYears.every((num) =>
      perms.years.includes(num)
    );
    if (!allYearsArePermitted) {
      dispatch(
        setAlert({
          message: "Você não tem permissão para ver os anos selecionados",
          type: AlertType.WARNING,
        })
      );
      return;
    }
  }

  dispatch(
    setFilters({ filters: newFilters, comparativeFilters: newCompFilters })
  );

  setStatus(Status.LOADING);
};

export const rollUpMap = (
  dispatch: AppDispatch,
  dashFilters: ActiveFilter[],
  dashComparativeFilters: ActiveFilter[],
  zoneMode: boolean,
  setStatus
) => {
  const level = getCurrentLevelOfDetail(dashFilters, zoneMode);
  const filter = getRollUpFilter(level);

  if (!filter) {
    dispatch(
      setAlert({
        message: "Você está no nível mais alto.",
        type: AlertType.WARNING,
      })
    );
    return;
  }

  const newFilters = remove(dashFilters, filter.value);
  const newCompFilters = remove(dashComparativeFilters, filter.value);

  dispatch(
    setFilters({ filters: newFilters, comparativeFilters: newCompFilters })
  );

  setStatus(Status.LOADING);
};
