import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { AUTH_PAGE, SHOP_PAGE } from "../../app/constants";
import PagesComponents from "../../app/pages";
import { AppDispatch } from "../../app/store";
import { filterAuthPages } from "../../app/utils";
import { makeContainer } from "../../components/container/container";
import { resetAlert } from "../../features/alert/slice";
import LoginImage from "../../resources/images/login-image.png";
import "./page.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
};

const PageContainer = makeContainer("page-container");
const LoginContent = makeContainer("login-content");

const ImagePage: React.FC<Props> = ({ children }) => {
  return (
    <img alt="congresso nacional" src={LoginImage} className="image-pane">
      {children}
    </img>
  );
};

const AuthPage: React.FC<Props> = ({ children }) => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetAlert(null));
    };
  });

  return (
    <PageContainer>
      <LoginContent>
        <Routes>
          {Object.keys(PagesComponents)
            .filter(filterAuthPages)
            .map((key) => {
              const ElementPage = PagesComponents[key];
              return (
                <Route
                  key={key}
                  path={key.replace(AUTH_PAGE, "")}
                  element={<ElementPage />}
                />
              );
            })}

          {Object.keys(PagesComponents)
            .filter(filterAuthPages)
            .map((key) => {
              const ElementPage = PagesComponents[key];
              return (
                <Route
                  key={`shop-${key}`}
                  path={key.replace(AUTH_PAGE, SHOP_PAGE)}
                  element={<ElementPage />}
                />
              );
            })}
        </Routes>
      </LoginContent>
      <ImagePage />
    </PageContainer>
  );
};

export default AuthPage;
