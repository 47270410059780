import { memo } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Status } from "../../../../../app/constants";
import { toPtBr, toPtBrContracted } from "../../../../../app/utils";
import CustomResponsiveContainer from "../../../../../components/charts/CustomResponsiveContainer";
import { makeContainer } from "../../../../../components/container/container";
import Loading from "../../../../../components/loading/loading";
import NoData from "../../../../../components/no-data/no-data";
import WidgetTitleText from "../../../../../components/text/widget-title-text/widget-title-text";
import { Candidate } from "../../../../../features/candidates/type";
import "./candidate-voting-chart.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  candidate?: Candidate;
  status?: Status;
};

const format = (candidate: Candidate) => {
  let votes = candidate?.election_summary;
  if (!votes) return [];

  const yearGroups = {};
  for (const v of votes) {
    if (!(v.electionyear in yearGroups)) {
      yearGroups[v.electionyear] = {
        Ano: v.electionyear,
        "1º Turno": 0,
        "2º Turno": 0,
        "1º Turno - % Válidos": 0,
        "2º Turno - % Válidos": 0,
      };
    }
    if (v.turn === 1) {
      yearGroups[v.electionyear]["1º Turno"] = v.num_votes;
      yearGroups[v.electionyear]["1º Turno - % Válidos"] =
        v.relative_num_votes * 100;
    } else {
      yearGroups[v.electionyear]["2º Turno"] = v.num_votes;
      yearGroups[v.electionyear]["2º Turno - % Válidos"] =
        v.relative_num_votes * 100;
    }
  }

  const data = Object.keys(yearGroups).map((key) => yearGroups[key]);

  data.sort((v1, v2) => {
    return v1.electionyear - v2.electionyear;
  });

  return data;
};

const formatTicks = (tickItem) => {
  return toPtBrContracted(tickItem);
};

const CustomizedTooltip = ({ payload, label, active }) => {
  if (active && payload && payload.length >= 4) {
    return (
      <div className="candidate-barchart-custom-tooltip">
        <div>{`${label}`}</div>
        <div className="candidate-barchart-custom-tooltip-section">{`1º Turno:`}</div>
        <div>{`${toPtBr(payload[1].value)} voto(s)`}</div>
        <div>{`${toPtBr(payload[3].value)} % dos válidos`}</div>
        <div className="candidate-barchart-custom-tooltip-section">{`2º Turno:`}</div>
        <div>{`${toPtBr(payload[0].value)} voto(s)`}</div>
        <div>{`${toPtBr(payload[2].value)} % dos válidos`}</div>
      </div>
    );
  }

  return null;
};

const WidgetTitleContainer = makeContainer(
  "party-profile-widget-title-container"
);

const CandidateVotingChart: React.FC<Props> = ({ status, candidate }) => {
  const data = format(candidate);

  if (status === Status.LOADING) {
    return <Loading />;
  }

  if (!candidate?.election_summary || !candidate?.election_summary.length) {
    return <NoData />;
  }

  return (
    <>
      <WidgetTitleContainer>
        <WidgetTitleText>{"Histórico de Votação"}</WidgetTitleText>
      </WidgetTitleContainer>

      <CustomResponsiveContainer>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 25,
              right: 0,
              left: 15,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={CustomizedTooltip} />
            <XAxis dataKey="Ano" />
            <YAxis
              yAxisId="left"
              orientation="left"
              color="#2DA5C0"
              tickFormatter={formatTicks}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              color="#E76E6E"
              tickFormatter={formatTicks}
            />
            <Tooltip />
            <Bar
              yAxisId="left"
              dataKey="2º Turno"
              fill="#2DA5C0"
              stackId="year"
            />
            <Bar
              yAxisId="left"
              dataKey="1º Turno"
              fill="#2DA5C0"
              stackId="year"
              radius={[6, 6, 0, 0]}
            />

            <Bar
              yAxisId="right"
              dataKey="2º Turno - % Válidos"
              fill="#E76E6E"
              stackId="year"
            />
            <Bar
              yAxisId="right"
              dataKey="1º Turno - % Válidos"
              fill="#E76E6E"
              stackId="year"
              radius={[6, 6, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </CustomResponsiveContainer>
    </>
  );
};

export default memo(CandidateVotingChart);
