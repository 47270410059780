import { memo, useState } from "react";
import InputLabelText from "../../text/input-label-text/input-label-text";
import "./settings-switch.css";

type Props = {
  className?: string;
  label?: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
};

type ContainerProps = {
  children?: string | JSX.Element | JSX.Element[];
  className?: string;
};

const Container: React.FC<ContainerProps> = ({ children, className }) => {
  return (
    <div className={`settings-switch-outer-container ${className}`}>
      {children}
    </div>
  );
};

const SettingsSwitch: React.FC<Props> = ({
  className,
  value,
  label,
  onChange,
}) => {
  const [innerValue, setInnerValue] = useState(value);

  return (
    <Container>
      <InputLabelText className={className}>{label}</InputLabelText>
      <label className="settings-switch">
        <input
          type="checkbox"
          checked={innerValue}
          onChange={(e) => {
            setInnerValue(e.target.checked);
            onChange(e.target.checked);
          }}
        />
        <span className="settings-slider round"></span>
      </label>
    </Container>
  );
};

export default memo(SettingsSwitch);
