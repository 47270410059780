import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Pages } from "../../../../app/constants";
import { AppDispatch } from "../../../../app/store";
import { makeContainer } from "../../../../components/container/container";
import AnalysisNameText from "../../../../components/text/analysis-name-text/analysis-name-text";
import { setStartupState } from "../../../../features/dashboard/slice";
import CandidateAnalysis from "../../../../resources/images/analysis/candidate.jpg";
import Electorate from "../../../../resources/images/analysis/electorate.jpg";
import ExecutiveAnalysis from "../../../../resources/images/analysis/executive.jpg";
import NullAnalysis from "../../../../resources/images/analysis/null-votes.jpg";
import Parties from "../../../../resources/images/analysis/parties.jpg";
import Presidential from "../../../../resources/images/analysis/presidential.jpg";
import "./analysis.css";

type AnalysisProps = {
  children?: string;
  image?: string;
  onClick?: () => void;
};

const AnalysisContainer = makeContainer("home-analysis");

const AnalysisShortcut: React.FC<AnalysisProps> = ({
  children,
  image,
  onClick,
}) => {
  return (
    <div className="home-analysis-shortcut" onClick={onClick}>
      <div className="home-analysis-shortcut-image">
        <img alt="logo da DataElege" className="analysis-image" src={image} />
      </div>
      <AnalysisNameText className="home-analysis-shortcut-image-text">
        {children}
      </AnalysisNameText>
    </div>
  );
};

const goToNullAnalysis = (
  dispatch: AppDispatch,
  navigate: NavigateFunction
) => {
  return () => {
    dispatch(
      setStartupState({
        mapAttribute: {
          name: "BlankVotesMap",
          friendly_name: "Nº de Votos em Branco",
          description:
            "Número de Votos em Branco de acordo com os filtros selecionados",
          type: "map",
          suffix: "voto(s)",
          valid_levels: ["GLOBAL", "STATE", "CITY", "ZONE", "BURROW", "PLACE"],
          valid_filters: ["year", "turn", "officeId"],
          origin: "voting",
        },
        leftNumberWidgetAttribute: {
          name: "BlankVotes",
          friendly_name: "Nº de Votos em Branco",
          description:
            "Número de Votos em Branco de acordo com os filtros selecionados",
          type: "scalar",
          suffix: "voto(s)",
          valid_levels: ["GLOBAL", "STATE", "CITY", "ZONE", "BURROW", "PLACE"],
          valid_filters: ["year", "turn", "officeId"],
          origin: "voting",
        },
        rightNumberWidgetAttribute: {
          name: "NullVotes",
          friendly_name: "Nº de Votos Nulos",
          description:
            "Número de Votos Nulos de acordo com os filtros selecionados",
          type: "scalar",
          suffix: "voto(s)",
          valid_levels: ["GLOBAL", "STATE", "CITY", "ZONE", "BURROW", "PLACE"],
          valid_filters: ["year", "turn", "officeId"],
          origin: "voting",
        },
      })
    );

    navigate(`/${Pages.VOTING}`);
  };
};

const goExecutiveElected = (
  dispatch: AppDispatch,
  navigate: NavigateFunction
) => {
  return () => {
    dispatch(
      setStartupState({
        filters: [
          {
            name: { label: "Resultado", value: "candidateStatus" },
            value: { label: "ELEITO", value: "ELEITO" },
          },
          {
            name: { label: "Resultado", value: "candidateStatus" },
            value: { label: "2º TURNO", value: "2º TURNO" },
          },
          {
            name: { label: "Cargo", value: "officeId" },
            value: {
              value: "a90ad3e25da26e1d3d0adb969d193668",
              label: "GOVERNADOR",
            },
          },
          {
            name: { label: "Cargo", value: "officeId" },
            value: {
              value: "60681846863154d431c638fa5a126791",
              label: "PRESIDENTE",
            },
          },
        ],
      })
    );

    navigate(`/${Pages.VOTING}`);
  };
};

const goElectorateWithCollege = (
  dispatch: AppDispatch,
  navigate: NavigateFunction
) => {
  return () => {
    dispatch(
      setStartupState({
        filters: [
          {
            name: { label: "Escolaridade", value: "schooling" },
            value: { label: "SUPERIOR COMPLETO", value: "SUPERIOR COMPLETO" },
          },
        ],
      })
    );

    navigate(`/${Pages.ELECTORATE}`);
  };
};

const goToPresidential2022 = (
  dispatch: AppDispatch,
  navigate: NavigateFunction
) => {
  return () => {
    dispatch(
      setStartupState({
        filters: [
          {
            name: { label: "Ano", value: "year" },
            value: { label: "2022", value: 2022 },
          },
          {
            name: { label: "Cargo", value: "officeId" },
            value: {
              value: "60681846863154d431c638fa5a126791",
              label: "PRESIDENTE",
            },
          },
          {
            name: { label: "Turno", value: "turn" },
            value: { value: "2", label: "2º TURNO" },
          },
          {
            name: { label: "Candidato", value: "candidateId" },
            value: {
              label: "JAIR BOLSONARO",
              subLabel: "JAIR MESSIAS BOLSONARO",
              value: "387db9f9cd78437a0973d8549a6c9512",
            },
          },
        ],
        comparativeFilters: [
          {
            name: { label: "Ano", value: "year" },
            value: { label: "2022", value: 2022 },
          },
          {
            name: { label: "Cargo", value: "officeId" },
            value: {
              value: "60681846863154d431c638fa5a126791",
              label: "PRESIDENTE",
            },
          },
          {
            name: { label: "Turno", value: "turn" },
            value: { value: "2", label: "2º TURNO" },
          },
          {
            name: { label: "Candidato", value: "candidateId" },
            value: {
              label: "LULA",
              subLabel: "LUIZ INÁCIO LULA DA SILVA",
              value: "4f72259cda8020a8b5ac4c9fb8e7018c",
            },
          },
        ],
        comparativeMode: true,
      })
    );

    navigate(`/${Pages.VOTING}`);
  };
};

const goToCandidates = (dispatch: AppDispatch, navigate: NavigateFunction) => {
  return () => {
    navigate(`/${Pages.SEARCH_CANDIDATE}?map=true`);
  };
};

const goToParties = (dispatch: AppDispatch, navigate: NavigateFunction) => {
  return () => {
    navigate(`/${Pages.SEARCH_PARTY}?map=true`);
  };
};

const Analysis: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <AnalysisContainer>
      <AnalysisShortcut
        image={NullAnalysis}
        onClick={goToNullAnalysis(dispatch, navigate)}
      >
        Brancos e Nulos
      </AnalysisShortcut>
      <AnalysisShortcut
        image={ExecutiveAnalysis}
        onClick={goExecutiveElected(dispatch, navigate)}
      >
        Eleitos do Executivo
      </AnalysisShortcut>
      <AnalysisShortcut
        image={CandidateAnalysis}
        onClick={goToCandidates(dispatch, navigate)}
      >
        Mapa do Candidato
      </AnalysisShortcut>
      <AnalysisShortcut
        image={Electorate}
        onClick={goElectorateWithCollege(dispatch, navigate)}
      >
        Eleitorado com Ensino Superior
      </AnalysisShortcut>
      <AnalysisShortcut
        image={Parties}
        onClick={goToParties(dispatch, navigate)}
      >
        Votação do Partido
      </AnalysisShortcut>
      <AnalysisShortcut
        image={Presidential}
        onClick={goToPresidential2022(dispatch, navigate)}
      >
        Eleição Presidencial 2022
      </AnalysisShortcut>
    </AnalysisContainer>
  );
};

export default memo(Analysis);
