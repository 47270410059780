import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CITY_TARGET,
  STATE_TARGET,
  Status,
  TargetPages,
  TARGET_OFFICES,
} from "../../../app/constants";
import { AppDispatch } from "../../../app/store";
import TargetFormButton from "../../../components/buttons/target-form-button/target-form-button";
import TargetSelectionButton from "../../../components/buttons/target-selection-button/target-selection-button";
import { makeContainer } from "../../../components/container/container";
import TargetSelect from "../../../components/inputs/target-select/target-select";
import TargetFormContainer from "../../../components/target-page/target-form-container";
import ConstantsService from "../../../features/constants/service";
import {
  getConstants,
  selectConstants,
} from "../../../features/constants/slice";
import { Constants } from "../../../features/constants/type";
import MapsService from "../../../features/maps/service";
import {
  getCities,
  selectMapsStatus,
  selectTargetCities,
} from "../../../features/maps/slice";
import {
  selectCreateTargetRequest,
  setCityName,
  setCreateTargetRequest,
  setCurrentPage,
} from "../../../features/target/slice";
import { CreateTargetRequest } from "../../../features/target/type";
import "./office-selection-page.css";

const SelectOfficeForm = makeContainer("select-office-form");
const SelectOfficeButtonGroup = makeContainer("select-office-button-group");
const CitySelectionContainer = makeContainer(
  "select-office-city-selection-container"
);
const CitySelectionTitle = makeContainer("select-office-city-selection-title");

const getSelectedOffice = (
  constants: Constants,
  createTargetRequest: CreateTargetRequest
) => {
  return constants?.offices.find(
    (o) => o.value === createTargetRequest?.office_id
  )?.label;
};

export const OfficeSelectionPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const constants: Constants = useSelector(selectConstants);
  const createTargetRequest: CreateTargetRequest = useSelector(
    selectCreateTargetRequest
  );
  const selectedOffice = getSelectedOffice(constants, createTargetRequest);
  const citiesStatus = useSelector(selectMapsStatus);
  const cities = useSelector(selectTargetCities);
  const citieOptions = Array.isArray(cities)
    ? cities?.map((c) => ({ label: c.name, value: c.id }))
    : [];
  const selectedCity = citieOptions?.find(
    (o) => o.value === createTargetRequest?.city_id
  );

  const goToNext = () => {
    dispatch(setCurrentPage(TargetPages.TARGET_SELECTION));
  };

  const canAdvance = () => {
    if (!selectedOffice) {
      return false;
    }

    if (selectedOffice === TARGET_OFFICES[0]) {
      return selectedCity;
    }

    return true;
  };

  const selectOffice = (office) => () => {
    const newRequest = { ...createTargetRequest };
    newRequest["office_id"] = constants?.offices?.find(
      (o) => o.label === office
    ).value;
    newRequest["target_type"] =
      office === TARGET_OFFICES[0] ? CITY_TARGET : STATE_TARGET;
    delete newRequest["city_id"];
    dispatch(setCreateTargetRequest(newRequest));
  };

  const selectCity = (e) => {
    const newRequest = { ...createTargetRequest };
    newRequest["city_id"] = e.value;
    dispatch(setCreateTargetRequest(newRequest));
    dispatch(setCityName(e.label));
  };

  useEffect(() => {
    dispatch(getConstants({ service: new ConstantsService() }));
  }, [dispatch]);

  useEffect(() => {
    if (createTargetRequest) {
      dispatch(
        getCities({
          service: new MapsService(),
          stateId: createTargetRequest.state,
        })
      );
    }
  }, [dispatch, createTargetRequest]);

  return (
    <TargetFormContainer
      title="Selecione o cargo ao qual irá concorrer"
      step={2}
    >
      <SelectOfficeForm>
        <SelectOfficeButtonGroup>
          {TARGET_OFFICES.map((office) => (
            <TargetSelectionButton
              key={`btn-${office}`}
              selected={selectedOffice === office}
              onClick={selectOffice(office)}
            >
              {office}
            </TargetSelectionButton>
          ))}
        </SelectOfficeButtonGroup>

        {selectedOffice === TARGET_OFFICES[0] && (
          <CitySelectionContainer>
            <CitySelectionTitle>
              Em qual município irá se candidatar
            </CitySelectionTitle>
            <TargetSelect
              label=""
              placeHolder="Selecione um município"
              value={selectedCity}
              options={citieOptions}
              onChange={selectCity}
              isLoading={citiesStatus === Status.LOADING}
            />
          </CitySelectionContainer>
        )}

        <TargetFormButton disabled={!canAdvance()} onClick={goToNext}>
          Próximo Passo
        </TargetFormButton>
      </SelectOfficeForm>
    </TargetFormContainer>
  );
};

export default OfficeSelectionPage;
