function hexToRgb(hex: string): [number, number, number] {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return [r, g, b];
}

function luminance(r: number, g: number, b: number): number {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function contrast(lum1: number, lum2: number): number {
  return (Math.max(lum1, lum2) + 0.05) / (Math.min(lum1, lum2) + 0.05);
}

function bestContrast(
  background: string,
  color1: string,
  color2: string
): string {
  const bgRgb = hexToRgb(background);
  const color1Rgb = hexToRgb(color1);
  const color2Rgb = hexToRgb(color2);

  const bgLum = luminance(bgRgb[0], bgRgb[1], bgRgb[2]);
  const color1Lum = luminance(color1Rgb[0], color1Rgb[1], color1Rgb[2]);
  const color2Lum = luminance(color2Rgb[0], color2Rgb[1], color2Rgb[2]);

  const contrast1 = contrast(bgLum, color1Lum);
  const contrast2 = contrast(bgLum, color2Lum);

  return contrast1 > contrast2 ? color1 : color2;
}

export default bestContrast;
