import React, { memo, useEffect, useRef, useState } from "react";
import { useOnScreen } from "../../../../app/hooks";
import ShopButton from "../../../../components/buttons/shop-button/shop-button";
import { makeContainer } from "../../../../components/container/container";
import { CandidateSearch } from "../../../../features/candidates/type";
import ShopService from "../../../../features/shop/service";
import Sillouete from "../../../../resources/icons/user-silhouette.svg";
import "./shop-candidate-search-result-item.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  candidate?: CandidateSearch;
  isSelected?: boolean;
  compactMode?: boolean;
  setSelectedCandidate?: (string) => void;
};

const ShopCandidateSearchItemContainer = makeContainer(
  "shop-candidate-search-item-container"
);

export const ShopCandidateSearchItemDetails: React.FC<Props> = ({
  candidate,
  compactMode,
}) => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const [visibilityTimeout, setVisiblityTimeout] = useState(null);
  const [candidatePhoto, setCandidatePhoto] = useState(Sillouete);

  useEffect(() => {
    const notifyVisibilityChange = () => {
      const shouldFetch = isVisible && !visibilityTimeout;
      const shouldStopFetching = !isVisible && visibilityTimeout;

      const fetchPhoto = async () => {
        const service = new ShopService();
        const photo = await service.getCandidatePhoto(candidate.id);
        if (photo) {
          setCandidatePhoto(`data:image/png;base64,${photo}`);
        }
      };

      if (shouldFetch) {
        setVisiblityTimeout(setTimeout(() => fetchPhoto(), 1000));
      }

      if (shouldStopFetching) {
        clearTimeout(visibilityTimeout);
        setVisiblityTimeout(null);
      }
    };

    notifyVisibilityChange();
    // eslint-disable-next-line
  }, [candidate, isVisible]);

  const containerStyle = !compactMode ? {} : { flexGrow: 1 };
  const photoStyle = !compactMode
    ? {}
    : { width: "22px", height: "22px", minWidth: "22px", minHeight: "22px" };

  return (
    <div
      ref={ref}
      className="shop-candidate-search-item-candidate"
      style={containerStyle}
    >
      <img
        alt={"foto do candidato"}
        className="shop-candidate-search-item-photo"
        src={candidatePhoto}
        style={photoStyle}
      ></img>
      <div className="shop-candidate-search-item-details">
        <div className="shop-candidate-search-item-name">
          {candidate && candidate["ballotboxname"]
            ? candidate["ballotboxname"]
            : candidate?.ballotbox_name}
        </div>
        <div className="shop-candidate-search-item-candidature-details">
          {candidate?.name}
        </div>
      </div>
    </div>
  );
};

const ShopCandidateSearchAction: React.FC<Props> = ({
  candidate,
  isSelected,
  setSelectedCandidate,
}) => {
  const handleClick = () => {
    if (isSelected) {
      setSelectedCandidate(null);
      return;
    }
    setSelectedCandidate(candidate.id);
  };

  return (
    <div className="shop-candidate-search-item-action">
      <ShopButton selected={isSelected} onClick={handleClick}>
        {isSelected ? "Selecionado" : "Selecionar"}
      </ShopButton>
    </div>
  );
};

const ShopCandidateSearchResultItem: React.FC<Props> = ({
  candidate,
  isSelected,
  setSelectedCandidate,
}) => {
  return (
    <ShopCandidateSearchItemContainer>
      <ShopCandidateSearchItemDetails candidate={candidate} />
      <ShopCandidateSearchAction
        candidate={candidate}
        isSelected={isSelected}
        setSelectedCandidate={setSelectedCandidate}
      />
    </ShopCandidateSearchItemContainer>
  );
};

export default memo(ShopCandidateSearchResultItem);
