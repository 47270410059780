import { HiMenu } from "react-icons/hi";

import { memo, MouseEventHandler } from "react";
import "./hamburguer-button.css";

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const HamburguerButton: React.FC<Props> = (props) => {
  const { onClick } = props;

  return (
    <button className="hamburguer-button" onClick={onClick}>
      <HiMenu />
    </button>
  );
};

export default memo(HamburguerButton);
