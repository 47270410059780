import { createSlice } from "@reduxjs/toolkit";
import ModalContent from "./type";

// Slice's initial state
const initialState: { content: ModalContent | null; onClose: () => void } = {
  content: null,
  onClose: null,
};

// Selectors
export const selectModalContent = (state) => state.modal.content;
export const selectModalOnClose = (state) => state.modal.onClose;

// Slice
const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setModalContent(state, action) {
      state.content = action.payload.content;
      state.onClose = action.payload.onClose;
    },
    closeModal(state, action) {
      state.content = null;
    },
  },
});

export const { setModalContent, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
