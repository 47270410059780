import * as React from "react";
import { useSelector } from "react-redux";
import { validateText } from "../../../../../app/utils";
import { selectPalette } from "../../../../../features/notebook/slice";
import { ClippedImage, Paper, PaperInside } from "../commons/commons";
import { TemplatePageProps } from "../commons/interfaces";

const Page10: React.FC<TemplatePageProps> = ({
  className,
  notebook,
  triggerImageEdit,
  triggerFieldEdit,
  updateField,
}) => {
  const { palette } = useSelector(selectPalette);
  return (
    <Paper className={className}>
      <PaperInside>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
        >
          <defs>
            <clipPath id="page-10_svg__a">
              <path
                d="M1438.39 2202.13h599.71v260.85h-599.71z"
                className="page-10_svg__cls-5"
              />
            </clipPath>
            <filter id="page-10_svg__b">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="4"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
          </defs>
          <path
            d="M-1.018-.722h211.241v298.753H-1.018z"
            className="page-10_svg__cls-7"
            style={{ fill: palette.backgroundLight, strokeWidth: 0.354859 }}
          />

          <ClippedImage
            id="emerald-page-10-1"
            triggerImageEdit={triggerImageEdit}
            x={0}
            y={0}
            width={212}
            height={90}
            imageNumber={14}
            imageRatio={2.35}
            notebook={notebook}
          >
            <path
              d="M1438.39 2202.13h599.71v260.85h-599.71z"
              transform="matrix(.35486 0 0 .35486 -512.019 -782.708)"
              fill={palette.backgroundLight}
            />
          </ClippedImage>

          <path
            d="M1469.22 2201.34h101.39a165 165 0 0 1 165 165v527h-118a148.39 148.39 0 0 1-148.39-148.39z"
            className="page-10_svg__cls-62"
            style={{ fill: palette.primary, filter: "url(#page-10_svg__b)" }}
            transform="matrix(.35486 0 0 .35486 -512.019 -782.708)"
          />

          <foreignObject x={29.5} y={83} width={60} height={120}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1px",
                fontSize: "20.8219px",
                fontFamily: "Heavitas",
                lineHeight: "24px",
                fontWeight: 700,
                color: palette.backgroundLight,
                textAlign: "start",
                width: "100%",
              }}
            >
              <div style={{ color: palette.terciary }}>meu</div>
              <div>di</div>
              <div>fe</div>
              <div>ren</div>
              <div>cial</div>
            </div>
          </foreignObject>

          <foreignObject
            x={110.005}
            y={95.032}
            width={95}
            height={180}
            className="clickable"
          >
            <div
              style={{
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: palette.backgroundGray,
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.strenghts,
                  field: "strenghts",
                  label: "Diferencial",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.strenghts}
            </div>
          </foreignObject>

          <path
            d="M1534.62 2351.6a10.75 10.75 0 0 0-2.18-6.52 7.14 7.14 0 0 1-1.5-3.93 10.4 10.4 0 0 1 1.37-5.37 4.4 4.4 0 0 1 2.19-1.86c3.81-1.64 6.2-4.65 7.88-8.29a17.5 17.5 0 0 0 1-3.29 6 6 0 0 1 2.33-3.52 7.4 7.4 0 0 1 4.29-1.82 16 16 0 0 1 3 .4c1.32.24 2.62.67 4 .79a12.56 12.56 0 0 0 7.75-2c.33-.21.68-.41 1-.6a8.81 8.81 0 0 1 9.37.68 9.7 9.7 0 0 1 2.13 2.75 27 27 0 0 0 2.33 3.49 11.37 11.37 0 0 0 5.53 3.32c1 .31 2 .59 3 .94 3.07 1.09 4.4 3.49 4.78 6.53a15.9 15.9 0 0 1-.58 4.59 14.6 14.6 0 0 0 .28 7.37 9.9 9.9 0 0 0 1.53 2.94c2.09 2.64 2 5.43.88 8.37a7.2 7.2 0 0 1-3.82 4.19 11.8 11.8 0 0 0-4.2 3 11 11 0 0 0-2.39 4.72c-.14.61-.29 1.22-.42 1.84a6.36 6.36 0 0 1-2.46 4 24 24 0 0 1-3.3 2 5.5 5.5 0 0 1-4.51.13 15.6 15.6 0 0 0-3.31-.93 9.83 9.83 0 0 0-8.22 2.42 5.62 5.62 0 0 1-5.58 1.42 49 49 0 0 1-4.66-1.49 5.26 5.26 0 0 1-3.19-3.32 11.17 11.17 0 0 0-4.85-5.87 7.5 7.5 0 0 0-3.34-1.16c-3.61-.28-5.55-2.52-6.83-5.61a7.1 7.1 0 0 1-.23-5 15.1 15.1 0 0 0 .93-5.31m52.47-4.84a23.78 23.78 0 1 0-23.55 23.83 23.77 23.77 0 0 0 23.55-23.83M1600.78 2403.45l-15.71-7.43c-1.68 5.64-3.35 11.24-5 16.84h-.13l-11.18-25.74a40.3 40.3 0 0 0 21.4-8.83l10.75 25zM1528.43 2403.35c3.57-7.93 7.13-15.86 10.75-23.91 6.19 5 13.34 7.49 21.21 8.28l-11 25.15h-.14c-1.67-5.6-3.34-11.2-5-16.86l-15.7 7.42z"
            className="page-10_svg__cls-8"
            style={{ fill: palette.secondary }}
            transform="matrix(.35486 0 0 .35486 -512.019 -782.708)"
          />
          <path
            d="M1563.32 2367.54a20.73 20.73 0 1 1 20.7-20.68 20.72 20.72 0 0 1-20.7 20.68m-12.4-19.51 9.51 9.52 17.66-17.67-3.92-4-13.8 13.85-5.55-5.64z"
            className="page-10_svg__cls-8"
            style={{ fill: palette.secondary }}
            transform="matrix(.35486 0 0 .35486 -512.019 -782.708)"
          />
          <path
            d="M-.006 298.066s34.634-17.814 83.942-16.238c49.307 1.575 40.93 13.268 75.94 13.225 41.738-.05 51.1-8.186 51.1-8.186v11.2z"
            className="page-10_svg__cls-25"
            style={{ fill: palette.primary, strokeWidth: 0.354859 }}
          />
        </svg>
      </PaperInside>
    </Paper>
  );
};

export default Page10;
