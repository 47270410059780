export function hashCode(data: string) {
  let hash = 0;

  if (data.length === 0) return hash;

  for (let i = 0; i < data.length; i++) {
    const chr = data.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }

  return hash;
}
