import { memo, useState } from "react";
import "./tooltip.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  className?: string;
  title?: string;
  text?: string;
};

const Tooltip: React.FC<Props> = ({ children, title, text, className }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div
      className="tooltip"
      onMouseOver={() => setIsVisible(true)}
      onMouseOut={() => setIsVisible(false)}
    >
      {children}
      {isVisible && (
        <div className={`tooltip-content ${className}`}>
          <div className="tooltip-title">{title}</div>
          <div className="tooltip-text">{text}</div>
        </div>
      )}
    </div>
  );
};

export default memo(Tooltip);
