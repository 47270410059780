import React, { memo } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { makeContainer } from "../../container/container";
import "./filter-chip.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  variant?: "normal" | "disabled" | "scenario-a" | "scenario-b";
  onClick?: (value: any) => void;
};

const FilterChip: React.FC<Props> = ({
  children,
  onClick,
  variant = "normal",
}) => {
  const isDisabled = variant === "disabled";
  const FilterChipContainer = makeContainer(
    `filter-chip-container filter-chip-container-${variant}`
  );

  return (
    <FilterChipContainer>
      <span>{children}</span>
      {!isDisabled && (
        <button onClick={onClick} className="filter-chip-button">
          <IoMdCloseCircle />
        </button>
      )}
    </FilterChipContainer>
  );
};

export default memo(FilterChip);
