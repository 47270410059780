import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import alertReducer from "../features/alert/slice";
import authReducer, { logout } from "../features/auth/slice";
import candidatesReducer from "../features/candidates/slice";
import constantsReducer from "../features/constants/slice";
import dashboardReducer from "../features/dashboard/slice";
import electionssReducer from "../features/elections/slice";
import mapsReducer from "../features/maps/slice";
import menuReducer from "../features/menu/slice";
import modalReducer from "../features/modal/slice";
import notebookReducer from "../features/notebook/slice";
import partiesReducer from "../features/parties/slice";
import permissionReducer from "../features/permission/slice";
import settingsReducer from "../features/settings/slice";
import shopReducer from "../features/shop/slice";
import targetReducer from "../features/target/slice";

const persistConfig = {
  key: "dataelege",
  storage,
};

const settingsPersistConfig = {
  key: "dataelege-settings",
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);
const persistedSettingsReducer = persistReducer(
  settingsPersistConfig,
  settingsReducer
);

const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    menu: menuReducer,
    alert: alertReducer,
    modal: modalReducer,
    constants: constantsReducer,
    permissions: permissionReducer,
    elections: electionssReducer,
    parties: partiesReducer,
    candidates: candidatesReducer,
    maps: mapsReducer,
    dashboard: dashboardReducer,
    settings: persistedSettingsReducer,
    shop: shopReducer,
    target: targetReducer,
    notebook: notebookReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store, null, () => {
  const remeberMe = store.getState().auth?.userData?.rememberMe;
  if (!remeberMe) {
    store.dispatch(logout(null));
  }
});

export default store;
