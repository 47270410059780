import { memo, MouseEventHandler } from "react";
import { Status } from "../../../app/constants";
import "./login-cta-button.css";

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  status?: Status;
  disabled?: boolean;
  children: string;
};

const LoginCtaButton: React.FC<Props> = (props) => {
  const { onClick, children, status, disabled } = props;

  return (
    <button
      className={"login-cta-button"}
      onClick={onClick}
      disabled={disabled}
    >
      {status !== Status.LOADING ? (
        children
      ) : (
        <span className="loading-cta-text">Carregando</span>
      )}
    </button>
  );
};

export default memo(LoginCtaButton);
