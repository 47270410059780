import { memo, MouseEventHandler } from "react";
import FacebookIcon from "../../../resources/icons/facebook-icon.svg";
import GoogleIcon from "../../../resources/icons/google-icon.svg";
import "./social-login-button.css";

type SocialLoginButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children?: string;
  icon: string;
};

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children?: string;
};

const SocialLoginButton: React.FC<SocialLoginButtonProps> = (props) => {
  const { onClick, children, icon } = props;

  return (
    <button className="social-login-button" onClick={onClick}>
      <div className={"social-login-button-content"}>
        <img alt="icon" src={icon} className={"social-login-icon"} />
        <span>{children}</span>
      </div>
    </button>
  );
};

export const GoogleLoginButton: React.FC<Props> = memo((props) => {
  const { onClick, children } = props;
  return (
    <SocialLoginButton onClick={onClick} icon={GoogleIcon}>
      {children}
    </SocialLoginButton>
  );
});

export const FacebookLoginButton: React.FC<Props> = memo((props) => {
  const { onClick, children } = props;
  return (
    <SocialLoginButton onClick={onClick} icon={FacebookIcon}>
      {children}
    </SocialLoginButton>
  );
});
