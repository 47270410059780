import { createSlice } from "@reduxjs/toolkit";
import { Pages, Status } from "../../app/constants";
import MenuData from "./type";

const data: MenuData = {
  isMenuExpanded: false,
  currentPage: Pages.HOME,
  currentPageTitle: "",
};

// Slice's initial state
const initialState = {
  data: data,
  status: Status.IDLE,
  error: "",
};

// Selectors
export const selectMenuData = (state) => state.menu.data;

// Slice
const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setExpanded(state, action) {
      state.data.isMenuExpanded = action.payload;
    },
    setCurrentPage(state, action) {
      state.data.currentPage = action.payload.currentPage;
      state.data.currentPageTitle = action.payload.currentPageTitle;
    },
    setGoBack(state, action) {
      state.data.onGoBack = action.payload;
    },
  },
});

export const { setExpanded, setCurrentPage, setGoBack } = menuSlice.actions;
export default menuSlice.reducer;
