import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../app/store";
import NotebookButton from "../../../../../components/buttons/notebook-button/notebook-button";
import { makeContainer } from "../../../../../components/container/container";

import {
  closeModal,
  setModalContent,
} from "../../../../../features/modal/slice";

import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { setAlert } from "../../../../../features/alert/slice";
import { AlertType } from "../../../../../features/alert/type";
import { cropImage } from "./cropper";
import "./upload-image-modal.css";
import { useDebounceEffect } from "./use-debounce-effect";

type ModalProps = {
  show: boolean;
  aspect: number;
  onUpdateImage: (image: string) => void;
  onClose: () => void;
};

const MAX_SIZE_IN_BYTES = 15728640; // 15MB

const UploadImageModalContainer = makeContainer("upload-image-modal-container");
const UploadImageButtonsContainer = makeContainer(
  "upload-image-modal-buttons-container"
);
const UploadImageTopContainer = makeContainer(
  "upload-image-modal-top-container"
);
const UploadImageTopText = makeContainer("upload-image-modal-top-text");
const UploadImageTitle = makeContainer("upload-image-modal-title");
const ContentContainer = makeContainer("upload-image-modal-content-container");

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const PreviewUpload: React.FC<{ aspect: number; setImage: any }> = ({
  aspect,
  setImage,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const imgRef = useRef<HTMLImageElement>(null);
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const scale = 1;
  const rotate = 0;

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const selectedFile = e.target.files[0];
    if (selectedFile.size > MAX_SIZE_IN_BYTES) {
      dispatch(
        setAlert({
          message: "O arquivo não pode ser maior que 15MB.",
          type: AlertType.WARNING,
        })
      );
      return;
    }

    setCrop(undefined);
    const reader = new FileReader();
    reader.addEventListener("load", () =>
      setImgSrc(reader.result?.toString() || "")
    );
    reader.readAsDataURL(selectedFile);
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current) {
        setImage(cropImage(imgRef.current, completedCrop, scale, rotate));
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  return (
    <>
      <div className="crop-controls">
        <input type="file" accept="image/*" onChange={onSelectFile} />
      </div>
      <div className="crop-preview">
        {!!imgSrc && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
            minHeight={100}
          >
            <img
              alt=""
              ref={imgRef}
              src={imgSrc}
              className="crop-preview-image"
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}
      </div>
    </>
  );
};

const UploadImageForm: React.FC<ModalProps> = ({
  show,
  aspect,
  onClose,
  onUpdateImage,
}) => {
  const [image, setImage] = useState("");

  return (
    <UploadImageModalContainer>
      <UploadImageTopContainer>
        <UploadImageTopText>Enviar Imagem</UploadImageTopText>
        <UploadImageTitle>
          Selecione uma imagem do seu dispositivo
        </UploadImageTitle>
      </UploadImageTopContainer>
      <ContentContainer>
        <PreviewUpload aspect={aspect} setImage={setImage} />
      </ContentContainer>
      <UploadImageButtonsContainer>
        <NotebookButton
          selected
          className="upload-image-modal-input-button"
          disabled={!image}
          onClick={() => {
            onUpdateImage(image);
            onClose();
          }}
        >
          Salvar
        </NotebookButton>
        <NotebookButton
          className="upload-image-modal-input-button"
          onClick={onClose}
        >
          Fechar
        </NotebookButton>
      </UploadImageButtonsContainer>
    </UploadImageModalContainer>
  );
};

const UploadImageModal: React.FC<ModalProps> = memo(
  ({ show, aspect, onClose, onUpdateImage }) => {
    const dispatch: AppDispatch = useDispatch();

    const close = () => {
      onClose();
      dispatch(closeModal(null));
    };

    const updateImage = (image: string) => {
      onUpdateImage(image);
    };

    useEffect(() => {
      const changeModalStatus = () => {
        if (show) {
          dispatch(
            setModalContent({
              content: (
                <UploadImageForm
                  show={show}
                  aspect={aspect}
                  onClose={close}
                  onUpdateImage={updateImage}
                />
              ),
              onClose: () => {},
            })
          );
        } else {
          dispatch(closeModal(null));
        }
      };

      changeModalStatus();
      // eslint-disable-next-line
    }, [dispatch, onClose, show]);

    return <></>;
  }
);

export default UploadImageModal;
