import { memo, useRef } from "react";
import { useSelector } from "react-redux";
import { useOutsideAlerter } from "../../app/hooks";
import {
  selectModalContent,
  selectModalOnClose,
} from "../../features/modal/slice";
import "./modal.css";

type OverlayProps = {
  children?: string | JSX.Element | JSX.Element[];
  show: boolean;
};

const ModalOverlay: React.FC<OverlayProps> = ({ show, children }) => {
  return (
    <div
      className={"modal-overlay"}
      style={{ display: show ? "block" : "none" }}
    >
      {children}
    </div>
  );
};

const ModalWindow: React.FC = () => {
  const wrapperRef = useRef(null);
  const modalContent = useSelector(selectModalContent);
  const onClose = useSelector(selectModalOnClose);
  const show = modalContent !== null;

  useOutsideAlerter(wrapperRef, onClose);
  return (
    <ModalOverlay show={show}>
      <div className="modal" ref={wrapperRef}>
        {modalContent}
      </div>
    </ModalOverlay>
  );
};

export default memo(ModalWindow);
