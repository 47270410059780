import { memo } from "react";
import "./target-form-container.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  title: string;
  step: number;
  style?: React.CSSProperties;
};

export const TargetFormContainer: React.FC<Props> = ({
  title,
  step,
  children,
}) => {
  return (
    <div className="target-form-outer-container">
      <div className="target-form-step">{`Passo ${step}`}</div>
      <div className="target-form-title">{title}</div>
      <div className="target-form-container">{children}</div>
    </div>
  );
};

export default memo(TargetFormContainer);
