import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Pages, Status } from "../../../../app/constants";
import { AppDispatch } from "../../../../app/store";
import LoginCtaButton from "../../../../components/buttons/login-cta-button/login-cta-button";
import LoginTextButton from "../../../../components/buttons/login-text-button/login-text-button";
import { makeContainer } from "../../../../components/container/container";
import LoginCheckboxText from "../../../../components/inputs/login-checkbox/login-checkbox";
import LoginInputText from "../../../../components/inputs/login-input-text/login-input-text";
import LoginFormLabelText from "../../../../components/text/login-form-label-text/login-form-label-text";
import LoginTitleText from "../../../../components/text/login-title-text/login-title-text";
import AuthService from "../../../../features/auth/service";
import {
  cleanUp,
  selectAuthStatus,
  signup,
} from "../../../../features/auth/slice";
import LoginLogo from "../../../../resources/images/login-logo.png";

import "./signup-form.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
};

const Form = makeContainer("signup-form");
const TermsAcceptance = makeContainer("signup-terms-acceptance");
const Signup = makeContainer("signup-signup");

const Logo: React.FC = memo(() => {
  return <img alt="logo" className="signup-logo" src={LoginLogo} />;
});

const SignupForm: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const signupStatus: Status = useSelector(selectAuthStatus);

  const [nameInput, setNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [termsRead, setTermsRead] = useState(false);

  const signupUser = () => {
    if (signupStatus !== Status.LOADING) {
      const signupParams = {
        data: {
          name: nameInput,
          email: emailInput,
          password: passwordInput,
          rememberMe: false,
        },
        service: new AuthService(),
      };

      dispatch(signup(signupParams));
    }
  };

  function handleClick(link: string) {
    return () => navigate(`/${link}`);
  }

  function handleClickTerms(link: string) {
    return () => window.open(`/${link}`, "_blank");
  }

  useEffect(() => {
    return () => {
      dispatch(cleanUp(null));
    };
  }, [dispatch]);

  return (
    <Form>
      <Logo />
      <LoginTitleText className="signup-title">Cadastre-se</LoginTitleText>
      <LoginFormLabelText className="signup-label">Nome*</LoginFormLabelText>
      <LoginInputText
        className="signup-input"
        placeHolder="Seu nome completo"
        type="text"
        value={nameInput}
        onChange={(e) => setNameInput(e.target.value)}
      />
      <LoginFormLabelText className="signup-label">E-mail*</LoginFormLabelText>
      <LoginInputText
        className="signup-input"
        placeHolder="Seu melhor email"
        type="text"
        value={emailInput}
        onChange={(e) => setEmailInput(e.target.value)}
      />
      <LoginFormLabelText className="signup-label">Senha*</LoginFormLabelText>
      <LoginInputText
        className="signup-input-password"
        placeHolder="Mínimo 8 caracteres"
        type="password"
        value={passwordInput}
        onChange={(e) => setPasswordInput(e.target.value)}
        onEnterPressed={signupUser}
      />

      <TermsAcceptance>
        <LoginCheckboxText
          checked={termsRead}
          onChange={(e) => setTermsRead(e.target.checked)}
        ></LoginCheckboxText>
        <div>
          <span className="signup-signup-text">Li e estou ciente dos </span>
          <LoginTextButton onClick={handleClickTerms(Pages.TERMS)}>
            Termos de Uso
          </LoginTextButton>
        </div>
      </TermsAcceptance>
      <LoginCtaButton
        onClick={signupUser}
        status={signupStatus}
        disabled={!termsRead}
      >
        Cadastrar-se
      </LoginCtaButton>
      <Signup>
        <span className="signup-signup-text">Já possui conta? </span>
        <LoginTextButton onClick={handleClick(Pages.LOGIN)}>
          Faça o login
        </LoginTextButton>
      </Signup>
    </Form>
  );
};

export default memo(SignupForm);
