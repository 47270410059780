import { memo, MouseEventHandler } from "react";
import "./notebook-button.css";

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  selected?: boolean;
  children: string | JSX.Element | JSX.Element[];
  disabled?: boolean;
  icon?: () => JSX.Element;
  className?: string;
  variant?: "small" | "normal";
};

const NotebookButton: React.FC<Props> = (props) => {
  const { onClick, selected, children, disabled, icon, className, variant } =
    props;

  const baseClassName =
    variant === "small"
      ? "notebook-button notebook-button-small"
      : "notebook-button";

  const baseContentClassName =
    variant === "small"
      ? "notebook-button-content notebook-button-content-small"
      : "notebook-button-content";

  return (
    <button
      className={`
        ${className} ${baseClassName} ${
        disabled && "notebook-button-disabled"
      }  ${!selected && "unselected-notebook-button"}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div className={baseContentClassName}>
        {icon && icon()}
        {children}
      </div>
    </button>
  );
};

export default memo(NotebookButton);
