import * as React from "react";
import { useSelector } from "react-redux";
import { validateText } from "../../../../../app/utils";
import { selectPalette } from "../../../../../features/notebook/slice";
import { ClippedImage, Paper, PaperInside } from "../commons/commons";
import { TemplatePageProps } from "../commons/interfaces";
import bestContrast from "../commons/utils";

const Page6: React.FC<TemplatePageProps> = ({
  className,
  notebook,
  triggerImageEdit,
  triggerFieldEdit,
  updateField,
}) => {
  const { palette } = useSelector(selectPalette);
  return (
    <Paper className={className}>
      <PaperInside>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
        >
          <defs>
            <clipPath id="page-6_svg__b">
              <path
                d="M928.13 1681.31h-66.06v-66.06a79.62 79.62 0 0 1 79.62-79.62 66.06 66.06 0 0 1 66.06 66.06 79.62 79.62 0 0 1-79.62 79.62"
                className="page-6_svg__cls-5"
              />
            </clipPath>
            <clipPath id="page-6_svg__e">
              <path
                d="M1116.44 1681.31h-66.06v-66.06a79.62 79.62 0 0 1 79.62-79.62 66.06 66.06 0 0 1 66.06 66.06 79.62 79.62 0 0 1-79.62 79.62"
                className="page-6_svg__cls-5"
              />
            </clipPath>
            <clipPath id="page-6_svg__f">
              <path
                d="M1308.51 1681.31h-66.06v-66.06a79.62 79.62 0 0 1 79.62-79.62 66.06 66.06 0 0 1 66.06 66.06 79.62 79.62 0 0 1-79.62 79.62"
                className="page-6_svg__cls-5"
              />
            </clipPath>
            <filter id="page-6_svg__a">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <filter id="page-6_svg__c">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <filter id="page-6_svg__d">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
          </defs>
          <path
            d="M.083-1.175h211.71v299.416H.083z"
            className="page-6_svg__cls-8"
            style={{ fill: palette.secondary, strokeWidth: 0.355647 }}
          />
          <g className="page-6_svg__cls-9" style={{ opacity: 0.13 }}>
            <path d="M7.499 298.55a73.2 73.2 0 0 0 5.452-15.758 80 80 0 0 0 1.518-26.02c-.747-7.152-1.945-14.297-3.24-21.434-1.166-6.438-2.76-12.857-4.114-19.284-1.242-5.903-2.465-11.807-3.592-17.71-.506-2.657-.762-5.336-1.039-7.982 1.117 4.98 2.134 9.99 3.379 14.977 1.067 4.268 2.354 8.507 3.556 12.76 1.39 4.951 2.845 9.902 4.154 14.86 1.177 4.484 2.33 8.969 3.2 13.464.812 4.122 1.537 8.251 1.78 12.384a109 109 0 0 1 0 15.143c-.655 8.308-3.113 16.527-7.676 24.6z" />
            <path d="M15.6 298.55a53.6 53.6 0 0 0 10.126-16.192c2.97-7.469 3.574-15.001 2.887-22.555a66.7 66.7 0 0 0-4.178-17.975c-4.016-10.452-8.447-20.873-12.448-31.329-2.24-5.86-3.966-11.757-5.893-17.643a9.5 9.5 0 0 1-.491-2.931c.605 1.938 1.092 3.887 1.846 5.811 2.041 5.21 4.015 10.428 6.345 15.613 3.912 8.692 8.336 17.338 12.013 26.048 2.394 5.662 4.058 11.38 5.64 17.12 1.68 6.072 1.865 12.185.954 18.292-1.284 8.613-5.335 16.953-12.615 24.959a4.4 4.4 0 0 0-.49.782z" />
            <path d="M24.043 298.55c1.679-1.56 3.418-3.111 5.03-4.687 6.063-5.925 10.004-12.124 11.903-18.593 2.23-7.59 1.625-15.15-.637-22.712-2.49-8.361-6.988-16.527-11.708-24.675-5.135-8.89-10.363-17.736-15.147-26.648-2.134-3.984-3.3-8.07-4.89-12.114a.91.91 0 0 1 .071-.658c3.863 11.833 11.737 23.217 19.205 34.64 5.2 7.928 10.314 15.862 13.895 23.982 2.977 6.757 4.823 13.596 4.52 20.513-.465 10.794-6.37 20.93-18.27 30.337a6 6 0 0 0-.604.615z" />
            <path d="M32.145 298.55c2.632-1.934 5.42-3.83 7.867-5.807q12.476-10.083 14.838-21.585c1.45-6.999.046-13.905-2.927-20.77-4.183-9.648-11.26-18.923-18.348-28.17-6.352-8.315-12.882-16.609-17.427-25.173-1.455-2.738-2.582-5.516-3.837-8.276a1.2 1.2 0 0 1 0-.587c1.483 2.892 2.845 5.869 4.548 8.81 4.232 7.347 9.994 14.478 15.802 21.598 7.18 8.799 14.62 17.559 19.916 26.642 3.973 6.828 6.622 13.738 6.658 20.83.053 10.986-6.786 21.022-20.237 30.177q-1.717 1.166-3.442 2.33z" />
            <path d="M40.25 298.55c3.454-2.133 7.113-4.228 10.314-6.447 9.016-6.224 14.603-13.039 16.602-20.418 1.358-5.015.924-10.022-.737-15.016-3.083-9.246-9.958-18.02-17.526-26.673-7.508-8.586-15.232-17.132-22.559-25.746a60.4 60.4 0 0 1-10.264-16.715 22.4 22.4 0 0 1-1.088-3.702c2.888 8.76 9.133 17.07 16.58 25.222 6.84 7.469 14.088 14.888 20.984 22.36 6.33 6.846 11.953 13.806 15.52 21.068 2.863 5.822 4.268 11.698 3.172 17.662-1.54 8.354-7.845 15.954-18.326 22.86-2.906 1.914-6.195 3.714-9.31 5.567z" />
            <path d="M48.355 298.55c6.128-3.001 11.833-6.142 16.623-9.602 10.851-7.867 15.859-16.395 14.849-25.646-.765-6.974-4.57-13.639-9.702-20.18-6.11-7.788-13.999-15.235-21.759-22.704-7.614-7.337-15.25-14.667-21.104-22.334-3.738-4.898-6.676-9.88-8.18-15.023-.16-.548-.27-1.099-.377-1.65a1.2 1.2 0 0 1 .06-.605c2.561 10.599 11.232 20.325 21.144 29.875 7.788 7.493 16.004 14.901 23.753 22.405 7.373 7.113 13.69 14.418 17.26 22.14 4.378 9.474 3.557 18.75-5.207 27.74-5.57 5.69-13.571 10.67-23.056 15.186l-.925.441z" />
            <path d="M56.795 298.55c1.362-.658 2.7-1.326 4.086-1.973 11.434-5.335 20.777-11.26 26.07-18.352 4.399-5.893 5.448-11.925 3.78-18.074-2.308-8.51-8.82-16.445-16.975-24.113-7.33-6.892-15.19-13.67-22.865-20.489-7.675-6.817-15.133-13.678-20.57-20.94-3.28-4.382-5.712-8.849-6.815-13.45a4.2 4.2 0 0 1 .025-1.338c1.85 8.596 8.038 16.627 16.41 24.301 8.592 7.878 17.846 15.613 26.869 23.395 7.547 6.512 14.862 13.07 20.272 20.012 4.805 6.156 8.01 12.448 7.824 19.017-.242 7.75-5.377 14.82-14.791 21.296-5.502 3.784-12.028 7.173-19.266 10.314a4.4 4.4 0 0 0-.672.409z" />
            <path d="M64.9 298.55c1.184-.526 2.351-1.067 3.557-1.579 11.565-4.893 21.37-10.363 27.609-17 5.28-5.615 7.162-11.476 6.11-17.54-1.323-7.629-6.85-14.72-13.768-21.63-7.17-7.167-15.844-13.981-24.376-20.827-8.418-6.758-16.836-13.515-23.618-20.65-4.887-5.138-8.86-10.398-10.833-15.936a20.6 20.6 0 0 1-.985-10.015 2.7 2.7 0 0 1 .124.562c-.793 7.607 3.201 14.802 9.39 21.805 6.27 7.113 14.503 13.756 22.821 20.396 9.037 7.213 18.18 14.397 26.827 21.695 6.839 5.775 12.195 11.85 15.435 18.262 4.083 8.077 3.617 16.004-3.518 23.761-5.1 5.537-12.803 10.292-22.028 14.582-3.045 1.422-6.25 2.749-9.379 4.122z" />
            <path d="M73.002 298.55c3.336-1.383 6.757-2.735 9.99-4.16 11.416-5.026 20.71-10.67 25.916-17.563 4.21-5.562 4.979-11.252 3.083-17.031-2.46-7.554-8.862-14.5-16.441-21.261-9.108-8.12-19.916-15.798-30.116-23.644-8.913-6.867-17.512-13.806-23.672-21.289-4.887-5.932-8.049-12.017-7.558-18.394a11.6 11.6 0 0 1 .946-3.741c-.117.772-.224 1.543-.355 2.315-.776 4.375.526 8.66 2.909 12.885 3.45 6.125 8.891 11.914 15.766 17.391 9.246 7.359 18.956 14.582 28.572 21.862 8.355 6.313 16.645 12.636 23.174 19.386 4.876 5.036 8.675 10.222 10.247 15.688 2.28 7.938-.847 15.417-9.688 22.388-6.644 5.239-15.507 9.638-25.51 13.61-1.299.516-2.59 1.04-3.881 1.558zM.07 201.263a7.3 7.3 0 0 1 .284 1.199c.611 8.18 1.16 16.36 1.82 24.54 1.15 14.158 2.241 28.316 1.957 42.485-.178 8.962-.918 17.921-1.423 26.88-.043.732-.185 1.461-.28 2.194H.061z" />
            <path d="M81.107 298.55c4.072-1.621 8.287-3.18 12.188-4.88 13.209-5.75 23.569-12.304 27.797-20.516 3.002-5.83 2.234-11.623-.935-17.38-3.716-6.758-9.986-13.074-17.708-19.07-9.222-7.16-18.934-14.195-28.494-21.268-8.713-6.452-17.26-12.942-23.779-19.917-4.072-4.36-7.273-8.837-8.77-13.532-1.387-4.353-1.284-8.692 1.21-12.978-.232.616-.47 1.23-.69 1.846-2.384 6.697 0 13.16 4.811 19.497 5.136 6.757 12.523 13.056 20.798 19.13 9.959 7.294 20.244 14.49 30.23 21.78 8.774 6.402 16.784 13.002 22.307 20.147 3.442 4.453 5.69 9.012 5.953 13.746.399 7.412-4.474 14.112-13.614 20.23-7.273 4.868-16.46 8.93-26.524 12.621-.477.175-.925.356-1.387.548z" />
            <path d="M89.209 298.55c3.364-1.259 6.842-2.46 10.072-3.784 10.541-4.317 19.816-9.09 25.994-14.937q8.45-8.002 6.939-16.836c-1.192-7.003-6.434-13.461-13.16-19.717-7.681-7.152-17.191-13.828-26.709-20.5-10.484-7.351-21.207-14.638-29.988-22.445-6.327-5.626-11.512-11.437-13.87-17.693-2.113-5.644-1.451-11.2 2.603-16.648a1.14 1.14 0 0 1 .598-.377c-.669 1.102-1.423 2.198-1.985 3.315-2.99 5.939-2.041 11.821 1.494 17.654 3.485 5.75 9.037 11.114 15.41 16.317 9.464 7.725 20.55 14.994 31.219 22.38 9.851 6.819 19.514 13.686 26.922 21.13 4.599 4.623 8.18 9.378 9.784 14.414 2.714 8.43-1.195 16.193-11.38 23.338-5.957 4.175-13.326 7.803-21.695 11.025-2.934 1.135-5.921 2.24-8.891 3.36z" />
            <path d="M61.237 163.248c-1.259 1.718-2.731 3.41-3.73 5.157-3.355 5.857-2.554 11.697.807 17.501 3.442 5.95 9.214 11.46 15.83 16.808 7.578 6.131 16.213 11.964 24.877 17.782 10.239 6.882 20.553 13.743 29.163 21.08 6.427 5.484 11.78 11.146 14.478 17.266 4.46 10.1-.519 19.205-14.115 27.428-6.715 4.058-14.674 7.572-23.494 10.701-1.39.495-2.682 1.046-4.02 1.569h-3.712c6.47-2.575 13.244-5.015 19.34-7.76 10.004-4.503 17.619-9.745 21.762-15.894 4.343-6.445 3.724-12.896-.064-19.337-3.674-6.238-9.652-12.05-16.868-17.59-6.498-4.98-13.426-9.855-20.272-14.742-6.996-4.979-14.283-9.905-21.165-14.937-8.574-6.26-16.185-12.743-21.033-19.806-3.065-4.47-4.716-9.033-4.218-13.72.416-3.913 2.43-7.665 5.897-11.26a3.3 3.3 0 0 1 .448-.307z" />
            <path d="M105.746 298.55c2.013-.754 4.008-1.515 6.046-2.262 10.147-3.737 19.266-7.898 26.318-12.902 7.928-5.623 12.092-11.737 11.786-18.416-.242-5.335-3.15-10.424-7.468-15.367-5.51-6.324-12.857-12.224-20.944-17.936-11.025-7.792-22.406-15.495-33.357-23.312-8.026-5.73-15.04-11.708-19.987-18.138-3.756-4.88-6.007-9.877-5.552-15.066.441-4.979 3.35-9.687 8.643-14.083.16-.132.355-.256.74-.555-.09.274-.068.356-.15.434-9.546 8.055-10.634 16.48-5.441 25.176 3.727 6.238 9.926 11.993 17.007 17.573 9.168 7.22 19.71 14.044 29.945 20.962 9.553 6.451 18.942 12.952 26.514 19.937 5.406 4.98 9.734 10.136 11.704 15.649 2.88 8.03-.224 15.56-9.553 22.551-7.824 5.876-18.525 10.57-30.365 14.788-.864.31-1.678.65-2.518.975z" />
            <path d="M113.859 298.55q3.154-1.18 6.309-2.358c12.05-4.488 22.63-9.534 29.803-15.865 8.18-7.202 9.706-14.766 5.954-22.598-3.294-6.87-9.468-13.248-17.427-19.24-8.959-6.736-18.39-13.344-27.808-19.952-9.603-6.757-19.38-13.426-27.111-20.656-5.168-4.833-9.36-9.823-11.41-15.133-3.2-8.336-.17-16.153 9.603-23.42a1.33 1.33 0 0 1 .75-.245c-.256.214-.497.43-.768.644-5.402 4.207-8.77 8.717-9.712 13.564-.968 4.944.657 9.763 3.837 14.49 4.499 6.686 11.441 12.881 19.628 18.778 9.574 6.892 19.56 13.667 29.448 20.474 8.766 6.046 17.266 12.17 24.063 18.729 5.427 5.235 9.602 10.67 11 16.43 1.814 7.562-1.828 14.582-10.69 21.066-8.038 5.875-18.875 10.573-30.86 14.787-.434.153-.815.335-1.22.505z" />
            <path d="M121.964 298.55c2.909-1.106 5.854-2.19 8.72-3.314 11.274-4.424 21.033-9.396 27.534-15.528 6.683-6.302 8.444-12.906 6.082-19.77-2.33-6.758-8.09-12.978-15.03-19.002-8.763-7.611-19.33-14.731-29.632-21.926-8.536-5.96-17.071-11.932-24.248-18.256-6.228-5.473-11.416-11.11-14.034-17.184-4.118-9.557-.053-18.33 12.092-26.368q.807-.534 1.636-1.067a.96.96 0 0 1 .544-.107c-.235.175-.452.356-.711.523-6.857 4.431-11.584 9.247-13.397 14.62-1.53 4.525-.836 9.006 1.422 13.455 3.379 6.707 9.756 12.864 17.103 18.85 8.7 7.073 18.754 13.773 28.54 20.545 9.635 6.668 19.153 13.369 26.756 20.567 5.289 5.004 9.521 10.172 11.434 15.673q4.478 12.832-11.025 23.829c-7.76 5.48-17.928 9.958-29.163 13.97-.434.156-.828.334-1.241.501z" />
            <path d="M130.065 298.55c3.095-1.205 6.246-2.382 9.276-3.617 9.556-3.894 17.914-8.23 23.992-13.383q10.313-8.745 8.702-18.618c-1.084-6.586-5.754-12.707-11.786-18.657-6.53-6.444-14.706-12.48-22.921-18.494-9.39-6.885-18.906-13.738-27.911-20.723-7.782-6.046-14.041-12.394-17.683-19.205-5.716-10.702-1.942-20.628 12.294-29.654a2.7 2.7 0 0 1 1.11-.423c-.622.43-1.241.86-1.87 1.29-6.8 4.646-11.214 9.7-12.612 15.24-1.326 5.264.185 10.417 3.432 15.485 4.122 6.437 10.406 12.47 17.96 18.21 8.998 6.838 18.44 13.56 27.74 20.321 8.508 6.181 16.859 12.401 23.513 19.041 4.463 4.45 7.991 9.037 9.93 13.85 4.068 10.1-.883 19.265-14.227 27.555-6.938 4.31-15.324 7.988-24.433 11.352-.387.146-.75.302-1.12.455z" />
            <path d="M138.17 298.55c4.012-1.653 8.149-3.25 12.007-4.979 9.958-4.438 18.117-9.431 23.263-15.342 4.762-5.47 6.174-11.121 4.72-16.943-1.697-6.804-6.854-13.124-13.241-19.27-7.142-6.87-15.837-13.357-24.504-19.848-9.19-6.878-18.547-13.714-26.318-20.948-6.046-5.626-11.071-11.409-13.575-17.586q-6.178-15.25 13.55-27.976a1.8 1.8 0 0 1 .84-.252c-.225.181-.427.356-.684.544-7.056 4.723-11.718 9.862-13.383 15.517-1.504 5.121-.416 10.171 2.394 15.158 4.037 7.17 10.826 13.841 18.91 20.236 8.596 6.8 17.633 13.486 26.495 20.219 8.61 6.536 16.961 13.137 23.263 20.207 4.421 4.962 7.704 10.058 8.72 15.403 1.384 7.288-1.827 14.145-9.769 20.55-7.191 5.797-17.07 10.555-28.185 14.852-.384.145-.75.302-1.124.455z" />
            <path d="M146.29 298.55c5.406-2.46 11.1-4.804 16.15-7.404 9.35-4.812 16.196-10.278 19.738-16.516 3.372-5.933 3.134-11.886.43-17.847-3.2-7.049-9.11-13.667-16.519-19.994-8.592-7.344-17.722-14.56-26.674-21.82-7.582-6.16-14.965-12.365-20.68-18.934-4.368-5.022-7.661-10.171-8.845-15.552-1.878-8.536 2.155-16.446 12.259-23.722 1.604-1.152 3.368-2.262 5.064-3.39a1 1 0 0 1 .544-.067c-.277.2-.53.405-.832.594-8.372 5.296-13.87 11.114-15.648 17.583-1.363 4.98-.441 9.92 2.084 14.806 3.734 7.223 10.16 13.99 17.9 20.496 8.357 7.027 17.188 13.945 25.816 20.908 8.027 6.48 15.73 13.031 21.495 20.002 4.624 5.587 7.824 11.317 8.18 17.306.526 9.115-6.046 17.181-18.138 24.415-5.594 3.346-12.032 6.337-18.928 9.136z" />
            <path d="M154.377 298.55c7.01-3.005 13.515-6.202 19.06-9.79 7.934-5.157 13.4-10.77 15.591-16.986 1.505-4.268 1.355-8.536-.05-12.804-2.791-8.478-9.634-16.36-18.006-23.98-8.372-7.622-17.181-15.027-25.72-22.552-7.704-6.79-14.86-13.686-19.682-21.023-3.325-5.06-5.384-10.21-5.228-15.499.253-8.575 6.498-16.253 17.526-23.199a1.27 1.27 0 0 1 .733-.142c-.452.313-.893.626-1.351.939-6.54 4.41-11.264 9.168-13.721 14.368-2.362 5-2.415 10.019-.733 15.051 2.728 8.18 9.15 15.801 17.185 23.153 8.66 7.923 17.903 15.72 26.84 23.583 7.05 6.202 13.629 12.5 18.473 19.133 4.172 5.712 6.871 11.545 6.757 17.587-.163 9.603-7.685 18.042-20.876 25.497-3.731 2.112-7.988 4.036-12 6.046-.395.195-.711.42-1.095.63z" />
            <path d="M162.48 298.55c8.93-4.14 16.683-8.667 22.462-13.834 8.087-7.238 11.146-14.905 9.716-22.96-1.351-7.619-6.234-14.831-12.412-21.895-7.266-8.315-16.253-16.278-24.71-24.35-7.58-7.235-14.845-14.525-19.874-22.218-3.837-5.861-6.22-11.825-5.886-17.96.484-8.838 6.807-16.794 17.978-24.02.117-.075.121-.186.178-.282l.444.171c-.298.228-.572.462-.892.68-7.867 5.359-13.092 11.188-15.268 17.536-1.7 4.955-1.362 9.909.405 14.852 3.315 9.272 10.93 17.91 19.56 26.386 7.63 7.497 15.632 14.937 23.242 22.405 6.619 6.52 12.32 13.199 15.951 20.198 2.845 5.487 4.243 11.025 3.148 16.669-1.501 7.735-7.376 14.78-17.253 21.125-3.791 2.433-8.208 4.67-12.33 7-.27.152-.49.323-.733.483z" />
            <path d="M170.922 298.55c1.967-1.155 3.951-2.304 5.893-3.467 9.766-5.847 16.833-12.263 20.272-19.44 2.49-5.228 2.877-10.506 1.682-15.812-1.881-8.343-7.152-16.306-13.937-24.07-6.704-7.668-13.896-15.254-20.834-22.882-6.402-7.028-12.306-14.127-16.196-21.535-2.6-4.95-4.2-9.958-4.154-15.055.089-9.648 6.259-18.468 18.116-26.502a1.5 1.5 0 0 1 .712-.26 5 5 0 0 1-.491.459c-7.216 5.125-12.245 10.651-14.877 16.598a23.33 23.33 0 0 0-1.64 13.415c1.6 10.05 8.383 19.514 16.563 28.807 7.415 8.411 15.293 16.744 22.761 25.148 5.84 6.558 10.702 13.266 13.69 20.219 3.911 9.08 3.954 18.095-2.54 26.986-4.52 6.189-11.711 11.712-20.738 16.783-.355.192-.6.413-.9.62z" />
            <path d="M179.01 298.55c.75-.526 1.465-1.067 2.254-1.575 10.183-6.633 17.178-13.87 20.251-21.82a27.2 27.2 0 0 0 1.287-15.235c-1.867-9.766-7.824-19.105-14.84-28.324-5.826-7.653-11.954-15.257-17.783-22.91-5.363-7.039-9.76-14.198-12.266-21.567-4.368-12.828-1.284-25.137 12.75-36.678a11.5 11.5 0 0 1 2.261-1.519 4 4 0 0 1-.433.47q-12.092 9.922-14.337 21.19c-1.234 6.166-.273 12.294 2.117 18.386 3.976 10.14 11.38 19.835 18.966 29.52 6.146 7.823 12.483 15.648 17.427 23.678 4.57 7.408 7.87 14.912 8.265 22.623q.928 18.067-21.634 33.057c-.355.224-.604.477-.903.712z" />
            <path d="M187.13 298.55c1.777-1.54 3.63-3.065 5.334-4.623 7.618-6.981 12.092-14.354 13.639-22.096 1.309-6.626.462-13.206-1.661-19.77-2.845-8.853-8.066-17.456-13.347-26.052-5.04-8.205-10.13-16.406-14.835-24.65q-5.526-9.68-6.91-19.696c-1.607-11.448 2.134-22.473 13.12-32.915a2.4 2.4 0 0 1 .96-.569c-1.16 1.298-2.365 2.59-3.467 3.912-5.442 6.47-8.301 13.16-9.101 20.07-1 8.66 1.469 17.163 5.31 25.606 4.21 9.247 10.171 18.327 15.805 27.424 4.808 7.767 9.414 15.556 12.557 23.505 2.846 7.227 4.354 14.503 3.46 21.847-1.176 9.706-6.724 18.882-16.95 27.47a7 7 0 0 0-.536.548z" />
            <path d="M195.234 298.55c2.134-2.628 4.442-5.228 6.302-7.888a43.7 43.7 0 0 0 7.615-18.85c1.099-7.354.217-14.673-1.693-21.985-2.202-8.418-5.947-16.716-9.603-25.024-3.727-8.453-7.607-16.893-10.907-25.382-3.201-8.237-4.89-16.556-4.478-24.935.455-9.19 3.674-18.163 10.702-26.826a1.54 1.54 0 0 1 .71-.52c-.87 1.31-1.777 2.611-2.603 3.913a47.3 47.3 0 0 0-6.59 17.569 47.9 47.9 0 0 0 .879 19.585 162 162 0 0 0 6.238 19.298c3.851 9.752 8.535 19.436 12.34 29.188 2.384 6.103 3.991 12.27 5.599 18.43 1.486 5.69 1.646 11.433.931 17.166-1.11 8.916-4.84 17.594-11.903 25.963a2 2 0 0 0-.178.291z" />
            <path d="M211.779 255.816c-.192-1.05-.388-2.102-.573-3.155-.835-4.726-1.515-9.46-2.546-14.18-1.124-5.142-2.575-10.274-3.884-15.41-1.5-5.886-3.161-11.768-4.467-17.665-1.276-5.747-2.386-11.505-3.2-17.266-.91-6.352-1.227-12.722-.235-19.074a137 137 0 0 1 3.158-14.45c.747-2.791 2.152-5.544 3.265-8.315l.44.032a32 32 0 0 0-1.159 3.173c-1.244 4.883-2.692 9.759-3.556 14.656-1.256 7.052-1.095 14.13-.31 21.193.605 5.441 1.53 10.876 2.561 16.306.989 5.2 2.255 10.392 3.478 15.585 1.224 5.192 2.636 10.474 3.841 15.716.95 4.14 1.7 8.29 2.561 12.448.174.85.427 1.696.644 2.542zM203.336 298.55c1.611-3.556 3.585-7.084 4.748-10.669 1.469-4.538 2.272-9.119 3.368-13.678.092-.356.217-.744.327-1.113v10.605a1.6 1.6 0 0 0-.274.356q-2.404 7.248-4.79 14.5zM211.779 206.416a19 19 0 0 1-.277-2.109c-.217-11.128-.491-22.252-.577-33.38-.035-4.315.356-8.629.59-12.94a3.3 3.3 0 0 1 .264-.817zM23.136 178.761v-4.072h.1v4.072zM61.134 163.209l2.678-2.646.384.078-2.959 2.607z" />
          </g>
          <text
            x={13.459}
            y={25.986}
            className="page-6_svg__cls-24"
            style={{
              fontSize: "14.3758px",
              fontFamily: "Heavitas",
              fill: palette.terciary,
              strokeWidth: 0.354782,
            }}
          >
            {"MEUS"}
            <tspan
              className="page-6_svg__cls-25"
              style={{
                strokeWidth: 0.354782,
                fill: palette.primary,
              }}
            >
              <tspan x={13.459} y={43.277} style={{ strokeWidth: 0.355647 }}>
                {"PROJETOS"}
              </tspan>
            </tspan>
          </text>
          <path
            d="M883.22 1513.66h142v478.76h-80.64a97.43 97.43 0 0 1-97.43-97.42v-345.27a36.07 36.07 0 0 1 36.07-36.07"
            className="page-6_svg__cls-64"
            style={{ fill: palette.secondary, filter: "url(#page-6_svg__a)" }}
            transform="matrix(-.35565 0 0 -.35565 371.906 768.52)"
          />

          <ClippedImage
            id="emerald-page-6-1"
            triggerImageEdit={triggerImageEdit}
            x={12}
            y={67}
            width={53}
            height={53}
            imageNumber={5}
            imageRatio={1}
            notebook={notebook}
          >
            <path
              d="M928.13 1681.31h-66.06v-66.06a79.62 79.62 0 0 1 79.62-79.62 66.06 66.06 0 0 1 66.06 66.06 79.62 79.62 0 0 1-79.62 79.62"
              style={{ fill: palette.backgroundLight }}
              transform="matrix(.35565 0 0 .35565 -294.001 -478.408)"
            />
          </ClippedImage>

          <path
            d="M115.118-.634s25.304-.541 48.602 9.851c22.154 9.887 34.89 19.283 41.316 21.04 8.09 2.216 6.658-.153 6.658-.153V-1.193Z"
            className="page-6_svg__cls-7"
            style={{ fill: palette.backgroundLight, strokeWidth: 0.355647 }}
          />
          <path
            d="M1071.57 1513.66h142v478.76h-80.64a97.43 97.43 0 0 1-97.43-97.42v-345.27a36.07 36.07 0 0 1 36.07-36.07"
            className="page-6_svg__cls-135"
            style={{ fill: palette.secondary, filter: "url(#page-6_svg__c)" }}
            transform="matrix(-.35565 0 0 -.35565 505.875 768.52)"
          />
          <path
            d="M1263.14 1513.66h142v478.76h-80.64a97.43 97.43 0 0 1-97.43-97.43v-345.26a36.07 36.07 0 0 1 36.07-36.07"
            className="page-6_svg__cls-136"
            style={{ fill: palette.secondary, filter: "url(#page-6_svg__d)" }}
            transform="matrix(-.35565 0 0 -.35565 642.137 768.52)"
          />

          <ClippedImage
            id="emerald-page-6-2"
            triggerImageEdit={triggerImageEdit}
            x={79}
            y={67}
            width={53}
            height={53}
            imageNumber={6}
            imageRatio={1}
            notebook={notebook}
          >
            <path
              d="M1116.44 1681.31h-66.06v-66.06a79.62 79.62 0 0 1 79.62-79.62 66.06 66.06 0 0 1 66.06 66.06 79.62 79.62 0 0 1-79.62 79.62"
              style={{ fill: palette.backgroundLight }}
              transform="matrix(.35565 0 0 .35565 -294.001 -478.408)"
            />
          </ClippedImage>

          <ClippedImage
            id="emerald-page-6-3"
            triggerImageEdit={triggerImageEdit}
            x={147}
            y={67}
            width={53}
            height={53}
            imageNumber={7}
            imageRatio={1}
            notebook={notebook}
          >
            <path
              d="M1308.51 1681.31h-66.06v-66.06a79.62 79.62 0 0 1 79.62-79.62 66.06 66.06 0 0 1 66.06 66.06 79.62 79.62 0 0 1-79.62 79.62"
              style={{ fill: palette.backgroundLight }}
              transform="matrix(.35565 0 0 .35565 -294.001 -478.408)"
            />
          </ClippedImage>

          <foreignObject
            x={10.005}
            y={123.032}
            width={55}
            height={103}
            className="clickable"
          >
            <div
              style={{
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: bestContrast(
                  palette.secondary,
                  palette.terciary,
                  palette.backgroundLight
                ),
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                height: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.projects_1,
                  field: "projects_1",
                  label: "Projeto",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.projects_1}
            </div>
          </foreignObject>

          <foreignObject
            x={77.005}
            y={123.032}
            width={55}
            height={103}
            className="clickable"
          >
            <div
              style={{
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: bestContrast(
                  palette.secondary,
                  palette.terciary,
                  palette.backgroundLight
                ),
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                height: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.projects_2,
                  field: "projects_2",
                  label: "Projeto",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.projects_2}
            </div>
          </foreignObject>

          <foreignObject
            x={146.005}
            y={123.032}
            width={55}
            height={103}
            className="clickable"
          >
            <div
              style={{
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: bestContrast(
                  palette.secondary,
                  palette.terciary,
                  palette.backgroundLight
                ),
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                height: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.projects_3,
                  field: "projects_3",
                  label: "Projeto",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.projects_3}
            </div>
          </foreignObject>
        </svg>
      </PaperInside>
    </Paper>
  );
};

export default Page6;
