import { debounce } from "lodash";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Attribute from "../../../app/attribute";
import { DashboardFilterConsts, Mode, Status } from "../../../app/constants";
import {
  drillDownMap,
  rollUpMap,
  toGeoMapDataItems,
} from "../../../app/dashboard";
import { hasFilter } from "../../../app/filter";
import { Feature, MapFeatures } from "../../../app/maps";
import { AppDispatch } from "../../../app/store";
import { makeContainer } from "../../../components/container/container";
import MapControls from "../../../components/dashboard/map/controls/map-controls";
import MapViewer from "../../../components/dashboard/map/viewer/map-viewer";
import { setAlert } from "../../../features/alert/slice";
import { AlertType } from "../../../features/alert/type";
import {
  electorateService,
  votingService,
} from "../../../features/dashboard/service";
import {
  applyStartupState,
  getAttributes,
  getMapData,
  selectAttributes,
  selectComparativeMode,
  selectDashboardComparativeFilters,
  selectDashboardFilters,
  selectFullScreenMode,
  selectMapAttribute,
  selectMapData,
  selectMapDataStatus,
  selectMapFeatures,
  selectSelectedFeatures,
  selectZoneMode,
  setComparativeMode,
  setFilters,
  setFullScreenMode,
  setMapAttribute,
  setMapFeatures,
  setSelectedFeatures,
  setZoneMode,
} from "../../../features/dashboard/slice";
import { QueryResult } from "../../../features/dashboard/types";
import MapsService from "../../../features/maps/service";
import {
  getMaps,
  selectMaps,
  selectMapsStatus,
} from "../../../features/maps/slice";
import { GeoProperties, RawGeoMap } from "../../../features/maps/types";
import PermissionService from "../../../features/permission/service";
import {
  getPermissions,
  selectPermissions,
} from "../../../features/permission/slice";
import { PermissionsData } from "../../../features/permission/type";

type ContainerProps = {
  children?: string | JSX.Element | JSX.Element[];
  fullScreenMode: boolean;
};

type Props = {
  mode: Mode;
};

const DashMapContainer = makeContainer("dashboard-map-container");
const FullScreenDashMapContainer = makeContainer(
  "dashboard-fullscreen-map-container"
);

const Container: React.FC<ContainerProps> = ({ fullScreenMode, children }) => {
  return (
    <>
      {fullScreenMode && (
        <FullScreenDashMapContainer>{children}</FullScreenDashMapContainer>
      )}
      {!fullScreenMode && <DashMapContainer>{children}</DashMapContainer>}
    </>
  );
};

// Debounce da atualização de status de carregamento
// para evitar flickering
const updateMapStatus = debounce(
  (rawMapsStatus, mapDataStatus, setStatus) => {
    const newStatus =
      rawMapsStatus !== Status.SUCCEEDED || mapDataStatus !== Status.SUCCEEDED
        ? Status.LOADING
        : Status.SUCCEEDED;

    setStatus(newStatus);
  },
  750,
  { leading: false }
);

const getService = (mode: Mode) => {
  return mode === Mode.VOTING ? votingService : electorateService;
};

const getFirstValidAttribute = (mode: Mode, attributes: Attribute[]) => {
  const origin = mode === Mode.VOTING ? "voting" : "electorate";
  return attributes.find((a) => a.type === "map" && a.origin === origin);
};

const isFromCurrentMode = (mode: Mode, attribute: Attribute) => {
  const origin = mode === Mode.VOTING ? "voting" : "electorate";
  return attribute.origin === origin;
};

const MapWithData: React.FC<Props> = ({ mode }) => {
  const dispatch: AppDispatch = useDispatch();
  // Selectors
  const rawMaps: RawGeoMap<GeoProperties> = useSelector(selectMaps);
  const rawMapsStatus: Status = useSelector(selectMapsStatus);
  const mapData: QueryResult = useSelector(selectMapData);
  const mapDataStatus: Status = useSelector(selectMapDataStatus);
  const mapAttribute: Attribute = useSelector(selectMapAttribute);
  const attributes: Attribute[] = useSelector(selectAttributes);
  const dashFilters = useSelector(selectDashboardFilters);
  const dashComparativeFilters = useSelector(selectDashboardComparativeFilters);
  const permissions: PermissionsData = useSelector(selectPermissions);
  const comparativeMode: boolean = useSelector(selectComparativeMode);
  const fullScreenMode: boolean = useSelector(selectFullScreenMode);
  const zoneMode: boolean = useSelector(selectZoneMode);
  const selectedFeatures: Feature[] = useSelector(selectSelectedFeatures);
  const map: MapFeatures = useSelector(selectMapFeatures);

  // Estado do mapa
  const [status, setStatus] = useState<Status>(Status.LOADING);
  const [triggerCenterMap, setTriggerCenterMap] = useState(0);
  const [triggerZoomIn, setTriggerZoomIn] = useState(0);
  const [triggerZoomOut, setTriggerZoomOut] = useState(0);

  useEffect(() => {
    const doInitialSetUp = () => {
      dispatch(getAttributes({ service: getService(mode) }));
      dispatch(getPermissions(new PermissionService()));
      dispatch(applyStartupState(null));
    };

    doInitialSetUp();
  }, [dispatch, mode]);

  useEffect(() => {
    const updateStatus = () => {
      if (
        [Status.LOADING, Status.SUCCEEDED].includes(rawMapsStatus) &&
        [Status.LOADING, Status.SUCCEEDED].includes(mapDataStatus)
      ) {
        updateMapStatus(rawMapsStatus, mapDataStatus, setStatus);
      }
    };

    updateStatus();
    // eslint-disable-next-line
  }, [rawMapsStatus, mapDataStatus]);

  useEffect(() => {
    const setInitialMapAttribute = () => {
      if (attributes && !mapAttribute) {
        const newAttribute = getFirstValidAttribute(mode, attributes);
        if (newAttribute) {
          dispatch(setMapAttribute(newAttribute));
        }
      }
    };

    setInitialMapAttribute();
  }, [dispatch, mode, mapAttribute, attributes]);

  useEffect(() => {
    const fetchData = () => {
      if (
        mapAttribute &&
        isFromCurrentMode(mode, mapAttribute) &&
        hasFilter(dashFilters, DashboardFilterConsts.YEAR)
      ) {
        dispatch(
          getMapData({
            attribute: mapAttribute,
            filters: dashFilters,
            comparativeFilters: dashComparativeFilters,
            comparativeMode,
            zoneMode,
            service: getService(mode),
          })
        );

        dispatch(
          getMaps({
            filters: dashFilters,
            zoneMode: zoneMode,
            service: new MapsService(),
          })
        );
      }
    };

    fetchData();
  }, [
    dispatch,
    dashFilters,
    dashComparativeFilters,
    mapAttribute,
    comparativeMode,
    zoneMode,
    mode,
  ]);

  useEffect(() => {
    const mergeMapAndData = () => {
      const finishedLoading =
        rawMapsStatus === Status.SUCCEEDED &&
        mapDataStatus === Status.SUCCEEDED;
      const hasData = rawMaps && mapData;

      if (finishedLoading && hasData) {
        const [items, envelope] = toGeoMapDataItems(
          comparativeMode,
          rawMaps,
          mapData,
          mapAttribute
        );
        dispatch(setMapFeatures({ features: items, envelope }));
      }
    };

    mergeMapAndData();
  }, [
    dispatch,
    comparativeMode,
    rawMapsStatus,
    rawMaps,
    mapDataStatus,
    mapData,
    mapAttribute,
  ]);

  useEffect(() => {
    const clearMapSelection = () => {
      dispatch(setSelectedFeatures(null));
    };

    clearMapSelection();
  }, [dispatch, dashFilters, dashComparativeFilters]);

  const handleFeatureSelected = useCallback(
    (selected) => {
      const feature = map?.features?.find((f) => f.id === selected.value);
      dispatch(setSelectedFeatures([feature]));
    },
    [dispatch, map]
  );

  const handleFeatureClick = useCallback(
    (feature: Feature) => {
      // if (selectedFeatures && selectedFeatures[0]) {
      //   if (feature.id === selectedFeatures[0].id) {
      //     drillDownMap(
      //       dispatch,
      //       permissions,
      //       feature,
      //       dashFilters,
      //       dashComparativeFilters,
      //       zoneMode,
      //       setStatus
      //     );
      //     return;
      //   }
      // }
      dispatch(setSelectedFeatures([feature]));
    },
    [dispatch]
  );

  const handleFeatureDblClick = useCallback(
    (feature: Feature) => {
      drillDownMap(
        dispatch,
        permissions,
        feature,
        dashFilters,
        dashComparativeFilters,
        zoneMode,
        setStatus
      );
    },
    [dispatch, permissions, dashFilters, dashComparativeFilters, zoneMode]
  );

  const handleCenterMapBtnClick = useCallback(() => {
    setTriggerCenterMap((t) => t + 1);
  }, []);

  const handleZoomInBtnClick = useCallback(() => {
    setTriggerZoomIn((t) => t + 1);
  }, []);

  const handleZoomOutBtnClick = useCallback(() => {
    setTriggerZoomOut((t) => t + 1);
  }, []);

  const handleComparativeModeBtnClick = useCallback(() => {
    if (!comparativeMode) {
      dispatch(
        setFilters({
          filters: dashFilters,
          comparativeFilters: dashFilters,
        })
      );
    }
    dispatch(setComparativeMode(!comparativeMode));
    setStatus(Status.LOADING);
  }, [dispatch, dashFilters, comparativeMode]);

  const handleZoneCityToggleBtnClick = useCallback(() => {
    dispatch(setZoneMode(!zoneMode));
    setStatus(Status.LOADING);
  }, [dispatch, zoneMode]);

  const handleFullScreenBtnClick = useCallback(() => {
    dispatch(setFullScreenMode(!fullScreenMode));
  }, [dispatch, fullScreenMode]);

  const handleRollUpBtnClick = useCallback(() => {
    rollUpMap(
      dispatch,
      dashFilters,
      dashComparativeFilters,
      zoneMode,
      setStatus
    );
  }, [dispatch, dashFilters, dashComparativeFilters, zoneMode]);

  const handleDrillDownBtnClick = useCallback(() => {
    if (!selectedFeatures || !selectedFeatures.length) {
      dispatch(
        setAlert({
          message: "Selecione um local no mapa",
          type: AlertType.WARNING,
        })
      );
      return;
    }

    const feature = selectedFeatures[0];
    drillDownMap(
      dispatch,
      permissions,
      feature,
      dashFilters,
      dashComparativeFilters,
      zoneMode,
      setStatus
    );
  }, [
    dispatch,
    permissions,
    selectedFeatures,
    dashFilters,
    dashComparativeFilters,
    zoneMode,
  ]);

  const handleAttributeSelected = useCallback(
    (att: Attribute) => {
      dispatch(setMapAttribute(att));
      setStatus(Status.LOADING);
    },
    [dispatch]
  );

  return (
    <Container fullScreenMode={fullScreenMode}>
      <MapControls
        map={map}
        onCenterMapBtnClick={handleCenterMapBtnClick}
        onZoomInBtnClick={handleZoomInBtnClick}
        onZoomOutBtnClick={handleZoomOutBtnClick}
        onComparativeModeBtnClick={handleComparativeModeBtnClick}
        onZoneCityToggleBtnClick={handleZoneCityToggleBtnClick}
        onFullScreenBtnClick={handleFullScreenBtnClick}
        onRollUpBtnClick={debounce(handleRollUpBtnClick, 750)}
        onDrillDownBtnClick={debounce(handleDrillDownBtnClick, 750)}
        onAttributeSelected={handleAttributeSelected}
        handleFeatureSelected={handleFeatureSelected}
      />
      <MapViewer
        map={map}
        status={status}
        triggerCenterMap={triggerCenterMap}
        triggerZoomIn={triggerZoomIn}
        triggerZoomOut={triggerZoomOut}
        handleFeatureClick={debounce(handleFeatureClick, 5000, {
          leading: true,
          maxWait: 5000,
        })}
        handleFeatureDblClick={debounce(handleFeatureDblClick, 5000, {
          leading: true,
          maxWait: 5000,
        })}
      />
    </Container>
  );
};

export default memo(MapWithData);
