import { memo } from "react";
import "./widget-item-text.css";

type Props = {
  children: string;
  className?: string;
};

const WidgetItemText: React.FC<Props> = ({ children, className }) => {
  return <div className={`widget-item-text ${className}`}>{children}</div>;
};

export const WidgetSubItemText: React.FC<Props> = memo(
  ({ children, className }) => {
    return (
      <div className={`widget-sub-item-text ${className}`}>{children}</div>
    );
  }
);

export default memo(WidgetItemText);
