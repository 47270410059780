import { memo, MouseEventHandler } from "react";
import "./see-more-button.css";

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onMap?: boolean;
};

const SeeMoreButton: React.FC<Props> = (props) => {
  const { onClick, onMap } = props;

  return (
    <button className={"see-more-button"} onClick={onClick}>
      {onMap ? "Ver Mapa" : "Ver mais"}
    </button>
  );
};

export default memo(SeeMoreButton);
