import HttpClient from "../../app/client";
import handleError from "../../app/errors";
import { getTimestampInSeconds } from "../../app/utils";
import { IPermissionService, Permission, RawPermission } from "./type";

export default class PermissionService implements IPermissionService {
  async getPermissions() {
    try {
      const client = new HttpClient("auth", false, [404]);
      const response = await client.get<RawPermission>(`/user/permissions`);
      return this.parsePermissions(response);
    } catch (error) {
      handleError("Error while getting permissions", error);
    }
  }

  parsePermissions(permissions: RawPermission[]) {
    if (!permissions) {
      return;
    }

    const isStaff = permissions.some(
      (permission) => permission.name === "staff"
    );

    const parsedPermissions: Permission[] = permissions
      .filter((permission) => permission.name.split(":").length >= 3)
      .map((permission) => {
        const splitPermissions = permission.name.split(":");
        const state = splitPermissions[0];
        const hasTarget = splitPermissions[1];
        const years = splitPermissions.splice(2).map((year) => parseInt(year));

        return {
          state: state,
          years: years,
          hasTarget: hasTarget === "1",
          expirationTime: permission.expiration_time,
          hasExpired: getTimestampInSeconds() > permission.expiration_time,
        };
      });

    const groupedPermissions = [];

    for (const permission of parsedPermissions) {
      if (permission.hasExpired) {
        continue;
      }

      if (!(permission.state in groupedPermissions)) {
        groupedPermissions[permission.state] = {
          state: permission.state,
          years: [],
          yearsWithTarget: [],
        };
      }

      const years = groupedPermissions[permission.state].years.concat(
        permission.years
      );
      groupedPermissions[permission.state].years = years;

      if (permission.hasTarget) {
        const yearsWithTarget = groupedPermissions[
          permission.state
        ].yearsWithTarget.concat(permission.years);
        groupedPermissions[permission.state].yearsWithTarget = yearsWithTarget;
      }
    }

    const statePermissions = [];
    for (const key of Object.keys(groupedPermissions)) {
      statePermissions.push(groupedPermissions[key]);
    }

    return {
      isStaff: isStaff,
      statePermissions: statePermissions,
      parsedPermissions: parsedPermissions,
    };
  }
}
