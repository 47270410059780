import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { MAIN_PAGE, Pages } from "../../app/constants";
import PagesComponents from "../../app/pages";
import { AppDispatch } from "../../app/store";
import { filterMainPages } from "../../app/utils";
import { selectIsLogged } from "../../features/auth/slice";
import { setCurrentPage, setGoBack } from "../../features/menu/slice";
import PageNotFound from "../../pages/404/not-found";
import { makeContainer } from "../container/container";
import "./page.css";
import SideBar from "./side-bar/side-bar";
import TitleBar from "./title-bar/title-bar";

type PageProps = {
  title?: string | JSX.Element | JSX.Element[];
  page?: Pages;
  children?: JSX.Element;
  onGoBack?: () => void;
};

const PageContainer = makeContainer("page-container");
const Main = makeContainer("main");
const Content = makeContainer("content");

type ProtectedRouteProps = {
  isAuthenticated: boolean;
  authenticationPath: string;
  outlet: JSX.Element;
};

function ProtectedRoute({
  isAuthenticated,
  authenticationPath,
  outlet,
}: ProtectedRouteProps) {
  if (isAuthenticated) {
    return outlet;
  } else {
    return <Navigate to={{ pathname: authenticationPath }} />;
  }
}

function ProtectedPage(defaultProtectedRouteProps) {
  return <ProtectedRoute {...defaultProtectedRouteProps} outlet={<Outlet />} />;
}

export const MainPage: React.FC<PageProps> = ({ children, title, page }) => {
  const isLogged = useSelector(selectIsLogged);

  const protetecedProps: Omit<ProtectedRouteProps, "outlet"> = {
    isAuthenticated: isLogged,
    authenticationPath: Pages.LOGIN,
  };

  return (
    <PageContainer>
      <SideBar />
      <Main>
        <TitleBar />
        <Content>
          <Routes>
            <Route element={<ProtectedPage {...protetecedProps} />}>
              {Object.keys(PagesComponents)
                .filter(filterMainPages)
                .map((key) => {
                  const ElementPage = PagesComponents[key];
                  return (
                    <Route
                      key={key}
                      path={key.replace(MAIN_PAGE, "")}
                      element={<ElementPage />}
                    />
                  );
                })}
              <Route path={"*"} element={<PageNotFound />} />
            </Route>
          </Routes>
        </Content>
      </Main>
    </PageContainer>
  );
};

const Page: React.FC<PageProps> = ({ children, title, page, onGoBack }) => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const setUpPageDetails = () => {
      dispatch(
        setCurrentPage({
          currentPage: page,
          currentPageTitle: title,
        })
      );

      dispatch(setGoBack(onGoBack));
    };

    setUpPageDetails();
  });

  return children;
};

export default Page;
