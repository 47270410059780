import React, { memo, useEffect, useState } from "react";
import { makeContainer } from "../../../../components/container/container";
import Loading from "../../../../components/loading/loading";

import "./banner.css";

const BannerContainer = makeContainer("home-banner");
const BannerSubContainer = makeContainer("home-banner-sub-container");

const Banner: React.FC = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    const setUpBannerCode = () => {
      const scriptElement = document.createElement("script");
      scriptElement.async = true;
      scriptElement.charset = "UTF-8";
      scriptElement.src =
        "https://cdn.curator.io/published/36401cd7-c94b-4ba8-a064-6faef6f7784b.js";

      const element = document.getElementsByTagName("script")[0];
      element.parentNode.insertBefore(scriptElement, element);
    };
    setUpBannerCode();
  }, []);

  return (
    <BannerContainer>
      {loading && <Loading />}
      <BannerSubContainer style={{ opacity: loading ? "0%" : "100%" }}>
        <div id="curator-feed-default-feed-layout">
          <a
            href="https://curator.io"
            target="_blank"
            rel="noreferrer"
            className="crt-logo crt-tag"
          >
            Powered by Curator.io
          </a>
        </div>
      </BannerSubContainer>
    </BannerContainer>
  );
};

export default memo(Banner);
