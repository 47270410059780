import { memo } from "react";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
};

const CustomResponsiveContainer: React.FC<Props> = ({ children }) => {
  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default memo(CustomResponsiveContainer);
