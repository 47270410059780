import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Status } from "../../app/constants";
import { debounceThunk } from "../../app/utils";
import { IPermissionService, PermissionsData } from "./type";

const permissionsData: PermissionsData | null = null;

// Slice's initial state
const initialState = {
  isLogged: false,
  status: Status.IDLE,
  permissions: permissionsData,
  error: "",
};

// get permision thunk
const getPermissionsThunk = createAsyncThunk<
  PermissionsData,
  IPermissionService
>("permission/permissions", async (service) => {
  const response = await service.getPermissions();
  return response;
});

// Selectors
export const selectPermissions = (state) => state.permissions.permissions;
export const selectPermissionsStatus = (state) => state.permissions.status;

// Reducers
const loading = (state, action) => {
  state.status = Status.LOADING;
};

const successfull = (state, action) => {
  state.status = Status.SUCCEEDED;
  state.permissions = action.payload;
};

const rejected = (state, action) => {
  state.status = Status.FAILED;
  state.isLogged = false;
  state.error = action.error.message;
  state.userData = null;
};

// Slice
const permissionSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getPermissionsThunk.pending, loading)
      .addCase(getPermissionsThunk.fulfilled, successfull)
      .addCase(getPermissionsThunk.rejected, rejected);
  },
});

export const getPermissions = debounceThunk(getPermissionsThunk);
export default permissionSlice.reducer;
