import { AxiosError } from "axios";
import { FirebaseError } from "firebase/app";
import store from "../app/store";
import { setAlert } from "../features/alert/slice";
import { AlertType } from "../features/alert/type";
import { NOT_FOUND } from "./constants";

const errorMsgMap = {
  ERR_NETWORK: "Erro ao conectar com o servidor",
  "auth/invalid-email": "Email inválido",
  "auth/password-too-short": "Senha precisa ter ao menos 8 caracteres",
  "auth/password-do-not-match": "As senhas digitadas não são iguais",
  "auth/missing-display-name": "Informe o seu nome",
  "auth/email-already-in-use": "Email já cadastrado",
  "auth/user-not-found": "Login e senha inválidos",
  "auth/wrong-password": "Login e senha inválidos",
  "auth/network-request-failed": "Verifique sua conexão com a internet",
};

const UNKNOWN_ERR_MESSAGE = "Erro não identificado";
const DEFAULT_ERR_MESSAGE =
  "Erro ao executar requisição. Tente novamente mais tarde";

export class PlatformError extends Error {
  code: string;

  constructor(code: string) {
    super(code);
    this.code = code;
  }
}

export default function handleError(
  context: string,
  error: FirebaseError | AxiosError,
  ignoreList?: number[]
) {
  try {
    // Logging error
    console.log(`Error ${context}`);
    console.log(error);

    // Ignore the error if the status code is in the ignore list
    if (ignoreList) {
      if (error["response"]) {
        if (ignoreList.includes(error["response"]["status"])) {
          return;
        }
      }
    }

    if (error["response"] && error["response"]["status"] === 404) {
      window.location.href = NOT_FOUND;
      return;
    }

    if (error.code in errorMsgMap) {
      throw Error(errorMsgMap[error.code]);
    }

    if (error.code === "ERR_BAD_RESPONSE" || error.code === "ERR_BAD_REQUEST") {
      const response = error["response"];
      const message = response?.data?.message;

      if (message) {
        throw Error(message);
      }

      throw Error(DEFAULT_ERR_MESSAGE);
    }

    throw Error(UNKNOWN_ERR_MESSAGE);
  } catch (error) {
    if (error.message !== UNKNOWN_ERR_MESSAGE) {
      store.dispatch(
        setAlert({
          message: error.message,
          type: AlertType.ERROR,
        })
      );
    }

    throw error;
  }
}
