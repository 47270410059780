import React from "react";
import Image from "../../resources/images/intro-page-2.svg";
import FirstPage from "./components/first-page";
import Page from "./components/page";

const Login: React.FC = () => {
  return (
    <Page image={Image}>
      <FirstPage />
    </Page>
  );
};

export default Login;
