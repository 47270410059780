import { memo } from "react";
import Logo1 from "../../resources/images/logo-part-1.svg";
import Logo2 from "../../resources/images/logo-part-2.svg";
import Logo3 from "../../resources/images/logo-part-3.svg";
import "./loading.css";

type Props = {
  text?: string;
};

const Loading: React.FC<Props> = ({ text }) => {
  return (
    <div className="animated-loading-logo-container">
      <svg
        className="animated-loading-logo-container-svg"
        viewBox="0 0 150 150"
      >
        <g>
          <image
            x="0"
            y="0"
            width="150"
            height="150"
            href={Logo1}
            className="animated-loading-logo-1"
          ></image>
          <image
            x="0"
            y="0"
            width="150"
            height="150"
            href={Logo2}
            className="animated-loading-logo-2"
          ></image>
          <image
            x="0"
            y="0"
            width="150"
            height="150"
            href={Logo3}
            className="animated-loading-logo-3"
            style={{}}
          ></image>
        </g>
      </svg>
      <span className="animated-loading-logo-text">
        {text ? text : "carregando"}
      </span>
    </div>
  );
};

export default memo(Loading);
