import { memo } from "react";
import "./menu-item-text.css";

type Props = {
  children: string;
  isSelected?: boolean;
};

const MenuItemText: React.FC<Props> = ({ children, isSelected }) => {
  return (
    <span className={isSelected ? "menu-item-text-selected" : "menu-item-text"}>
      {children}
    </span>
  );
};

export default memo(MenuItemText);
