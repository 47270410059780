import { useEffect, useState } from "react";

export function useOutsideAlerter(
  ref: React.MutableRefObject<any>,
  onClose: () => void
) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (onClose) {
          onClose();
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClose]);
}

export function useMouseLeaveAlerter(
  ref: React.MutableRefObject<any>,
  onClose: () => void
) {
  useEffect(() => {
    const { current } = ref;

    function handleClickOutside() {
      if (onClose) {
        onClose();
      }
    }

    current.addEventListener("mouseleave", handleClickOutside);

    return () => {
      current.removeEventListener("mouseleave", handleClickOutside);
    };
  }, [ref, onClose]);
}

export function useOnScreen(ref: React.MutableRefObject<undefined>) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}
