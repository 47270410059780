import * as React from "react";
import { useSelector } from "react-redux";
import { validateText } from "../../../../../app/utils";
import { selectPalette } from "../../../../../features/notebook/slice";
import { ClippedImage, Paper, PaperInside } from "../commons/commons";
import { TemplatePageProps } from "../commons/interfaces";

const Page5: React.FC<TemplatePageProps> = ({
  className,
  notebook,
  triggerImageEdit,
  triggerFieldEdit,
  updateField,
}) => {
  const { palette } = useSelector(selectPalette);
  return (
    <Paper className={className}>
      <PaperInside>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
        >
          <defs>
            <filter id="page-5_svg__a">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <filter id="page-5_svg__b">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <clipPath id="page-5_svg__c">
              <path
                d="M623.95 1849.67a183 183 0 0 1 183 183v151.79H655.11a183 183 0 0 1-183-183 151.79 151.79 0 0 1 151.84-151.79"
                className="page-5_svg__cls-5"
              />
            </clipPath>
          </defs>
          <path
            d="M-.675-.737h211.732V298.71H-.675z"
            className="page-5_svg__cls-7"
            style={{ fill: palette.backgroundLight, strokeWidth: 0.355684 }}
          />
          <g
            className="page-5_svg__cls-9"
            data-name="CZJK68"
            style={{ opacity: 0.13 }}
          >
            <path d="M16.437 256.593c-.886-3.642-2.038-7.28-3.201-10.916-1.067-3.261-2.216-6.523-3.361-9.781l-1.868-.953-.526-.356c1.18 4.343 2.45 8.679 3.421 13.025 1.295 5.787 2.49 11.581 3.24 17.379a53 53 0 0 1-1.518 21.092 52.3 52.3 0 0 1-5.456 12.776h3.379c4.56-6.548 7.021-13.206 7.675-19.943a72.6 72.6 0 0 0 0-12.275c-.249-3.357-.978-6.708-1.785-10.048" />
            <path d="M25.49 249.277a213 213 0 0 0-4.148-7.505l-3.578-1.832c2.134 4.308 4.3 8.615 6.345 12.93a45.7 45.7 0 0 1 4.176 14.582c.686 6.125.085 12.232-2.885 18.286-2.209 4.496-5.435 8.892-10.14 13.128h3.713a3.6 3.6 0 0 1 .49-.636c7.303-6.488 11.336-13.25 12.62-20.232a28.95 28.95 0 0 0-.967-14.842c-1.569-4.642-3.237-9.287-5.627-13.88" />
            <path d="M34.363 246.367q-1.686-.437-3.347-.932c3.671 5.336 6.968 10.671 8.981 16.13 2.263 6.13 2.867 12.261.64 18.411-1.899 5.246-5.843 10.272-11.904 15.077-1.611 1.277-3.35 2.533-5.033 3.8h3.379a5.4 5.4 0 0 1 .605-.499c11.897-7.626 17.784-15.846 18.271-24.592.299-5.609-1.547-11.143-4.524-16.632a89 89 0 0 0-7.068-10.763" />
            <path d="M46.71 248.8q-1.594-.22-3.17-.48c3.159 3.777 5.961 7.594 8.046 11.489 2.973 5.563 4.378 11.165 2.927 16.838q-2.383 9.319-14.84 17.496c-2.45 1.604-5.235 3.14-7.867 4.71h3.375l3.444-1.89c13.455-7.42 20.295-15.557 20.241-24.464-.035-5.747-2.685-11.35-6.658-16.884a87 87 0 0 0-5.499-6.815" />
            <path d="M58.048 249.775q-1.465-.054-2.923-.146c4.809 4.98 8.814 10.02 10.973 15.27 1.66 4.047 2.095 8.106.736 12.175-2 5.982-7.59 11.506-16.604 16.553-3.2 1.796-6.868 3.486-10.314 5.225h3.375c3.112-1.504 6.402-2.96 9.308-4.513 10.482-5.599 16.792-11.76 18.332-18.535 1.1-4.834-.313-9.604-3.172-14.316-2.43-4.002-5.809-7.89-9.71-11.713" />
            <path d="M68.289 249.825c-.883.035-1.779.05-2.665.057a103 103 0 0 1 4.166 4.026c5.132 5.303 8.938 10.706 9.7 16.361 1.013 7.502-3.995 14.416-14.85 20.79-4.788 2.814-10.497 5.364-16.625 7.797h3.375l.921-.356c9.486-3.656 17.49-7.683 23.063-12.31 8.764-7.278 9.585-14.797 5.207-22.476-2.735-4.805-7.089-9.404-12.292-13.89" />
            <path d="M77.145 249.042a75 75 0 0 1-2.237.285c7.47 5.858 13.324 11.919 15.49 18.396 1.668 4.98.615 9.873-3.785 14.65-5.292 5.748-14.632 10.557-26.071 14.879-1.387.522-2.725 1.067-4.083 1.6h3.375a5.4 5.4 0 0 1 .672-.334c7.238-2.533 13.765-5.282 19.268-8.348 9.418-5.25 14.554-10.984 14.793-17.265.202-5.335-2.999-10.425-7.808-15.412a90 90 0 0 0-9.614-8.45" />
            <path d="M87.431 249.633q-1.444-.986-2.913-1.974c-.55.128-1.102.256-1.657.355a136 136 0 0 1 5.221 4.002c6.918 5.602 12.45 11.353 13.769 17.538 1.067 4.916-.829 9.664-6.11 14.228-6.24 5.378-16.046 9.813-27.616 13.779-1.213.416-2.376.854-3.557 1.28h3.38c3.13-1.113 6.334-2.198 9.379-3.343 9.247-3.464 16.927-7.32 22.03-11.809 7.132-6.274 7.602-12.715 3.518-19.264-3.247-5.185-8.607-10.09-15.444-14.792" />
            <path d="M104.881 255.69c-4.05-3.404-8.806-6.69-13.825-9.892q-.752.25-1.508.484c1.945 1.295 3.841 2.6 5.666 3.913 7.58 5.477 13.985 11.108 16.447 17.232 1.881 4.685 1.127 9.298-3.084 13.805-5.207 5.577-14.501 10.165-25.922 14.227-3.24 1.16-6.655 2.251-9.988 3.375h3.375c1.295-.423 2.583-.846 3.885-1.266 10.001-3.201 18.85-6.783 25.513-11.026 8.842-5.655 11.965-11.716 9.685-18.14-1.572-4.428-5.367-8.629-10.244-12.712M.769 229.408c-.356-.313-.711-.622-1.042-.939v70.383h2.362c.096-.594.241-1.184.28-1.778.502-7.263 1.238-14.526 1.423-21.793.285-11.485-.81-22.963-1.956-34.441-.388-3.81-.73-7.622-1.067-11.432" />
            <path d="M119.72 260.63c-5.52-5.787-13.533-11.133-22.304-16.333l-.207-.12q-.832.241-1.675.458 3.322 2.031 6.584 4.08c7.718 4.859 13.992 9.96 17.71 15.458 3.165 4.67 3.937 9.365.935 14.092-4.233 6.655-14.583 11.965-27.8 16.632-3.913 1.376-8.12 2.64-12.193 3.955h3.379c.462-.15.91-.306 1.387-.444 10.066-2.992 19.25-6.285 26.527-10.233 9.14-4.959 14.01-10.39 13.615-16.398-.252-3.837-2.51-7.533-5.957-11.147" />
            <path d="M124.437 256.58c-6.104-4.98-13.747-9.63-21.757-14.228-.413.156-.833.306-1.249.455 6.218 3.642 12.168 7.341 17.297 11.215 6.723 5.072 11.962 10.315 13.16 15.984q1.505 7.164-6.939 13.648c-6.175 4.745-15.45 8.61-25.993 12.11-3.234 1.068-6.709 2.05-10.073 3.07h3.375c2.967-.91 5.95-1.807 8.892-2.724 8.362-2.618 15.732-5.56 21.697-8.946 10.197-5.794 14.106-12.093 11.382-18.918-1.626-4.062-5.193-7.922-9.792-11.667" />
            <path d="M107.439 240.37q-.584.274-1.174.534c5.061 2.924 10.094 5.855 14.885 8.835 7.22 4.493 13.2 9.202 16.87 14.26 3.792 5.221 4.41 10.453.068 15.675-4.147 4.98-11.759 9.247-21.764 12.886-6.097 2.227-12.872 4.204-19.342 6.292h3.713c1.337-.427 2.628-.871 4.016-1.273 8.824-2.536 16.78-5.385 23.496-8.675 13.598-6.662 18.578-14.046 14.12-22.234-2.699-4.962-8.052-9.554-14.483-13.996-6.142-4.25-13.164-8.298-20.405-12.303" />
            <path d="m113.02 238.951-1.374-.747c-.305.171-.611.356-.92.51q5.242 2.955 10.442 5.918c8.088 4.624 15.437 9.415 20.946 14.54 4.308 4.005 7.214 8.131 7.47 12.449.302 5.41-3.86 10.372-11.784 14.939-7.06 4.058-16.184 7.43-26.32 10.46-2.025.605-4.023 1.224-6.047 1.836h3.375c.84-.264 1.654-.54 2.522-.79 11.837-3.422 22.529-7.227 30.365-11.99 9.333-5.67 12.449-11.773 9.557-18.282-1.974-4.457-6.303-8.636-11.71-12.677-7.582-5.666-16.969-10.934-26.523-16.166" />
            <path d="m115.527 235.81-.921.602c7.996 4.553 15.945 9.112 23.55 13.75 7.974 4.86 14.149 10.027 17.428 15.597 3.753 6.35 2.209 12.481-5.954 18.318-7.174 5.133-17.756 9.223-29.806 12.862l-6.31 1.913h3.375c.406-.139.786-.284 1.22-.409 11.987-3.418 22.821-7.227 30.86-11.99 8.867-5.253 12.509-10.948 10.695-17.073-1.384-4.684-5.577-9.073-11.005-13.32-6.794-5.335-15.294-10.276-24.066-15.18q-4.513-2.551-9.066-5.07" />
            <path d="m119.194 233.374-.754.509.864.487c10.315 5.833 20.872 11.606 29.64 17.784 6.939 4.884 12.701 9.924 15.03 15.405 2.362 5.567.602 10.92-6.081 16.027-6.502 4.98-16.262 8.999-27.538 12.588-2.866.914-5.815 1.778-8.724 2.689h3.386c.412-.139.807-.281 1.241-.406 11.229-3.261 21.398-6.882 29.166-11.328q15.536-8.892 11.026-19.303c-1.917-4.46-6.15-8.65-11.435-12.705-7.608-5.837-17.123-11.268-26.762-16.675-3.009-1.7-6.046-3.382-9.059-5.072" />
            <path d="M122.267 231.101c4.884 2.924 9.824 5.834 14.73 8.75 8.219 4.884 16.393 9.778 22.926 15.003 6.047 4.823 10.7 9.785 11.788 15.124s-1.828 10.368-8.704 15.091c-6.078 4.176-14.437 7.694-23.994 10.849-3.034 1.003-6.182 1.956-9.276 2.934h3.375c.356-.124.733-.252 1.12-.356 9.113-2.728 17.497-5.69 24.436-9.201 13.356-6.723 18.31-14.153 14.227-22.34-1.938-3.913-5.467-7.62-9.927-11.226-6.658-5.385-15.01-10.425-23.518-15.437-5.499-3.24-11.047-6.473-16.528-9.717-.21.167-.43.349-.655.526" />
            <path d="M125.671 228.092c4.68 2.974 9.568 5.905 14.412 8.843 8.672 5.26 17.365 10.517 24.507 16.087 6.402 4.98 11.546 10.109 13.246 15.622 1.45 4.72.042 9.301-4.72 13.736-5.147 4.791-13.324 8.84-23.265 12.45-3.86 1.397-8 2.692-12.012 4.033h3.38c.373-.125.739-.253 1.12-.356 11.111-3.482 20.985-7.341 28.187-12.04 7.946-5.19 11.158-10.749 9.771-16.656-1.014-4.333-4.297-8.466-8.718-12.489-6.306-5.733-14.657-11.083-23.265-16.382-7.316-4.503-14.747-8.985-21.953-13.516a58 58 0 0 0-.69.668" />
            <path d="m130.925 224.457-1.299-.896c-.181.242-.355.487-.547.711 3.2 2.29 6.583 4.553 10.005 6.805 8.938 5.886 18.069 11.737 26.676 17.688 7.391 5.146 13.32 10.514 16.522 16.24 2.707 4.834 2.945 9.657-.427 14.47-3.557 5.057-10.393 9.49-19.74 13.387-5.051 2.106-10.75 4.009-16.152 6.004h3.375c6.897-2.272 13.335-4.695 18.93-7.405 12.093-5.869 18.684-12.406 18.158-19.797-.356-4.852-3.557-9.497-8.181-14.029-5.762-5.648-13.466-10.958-21.494-16.212-8.615-5.662-17.464-11.275-25.826-16.966" />
            <path d="M132.689 218.55q-.173.343-.356.682a158 158 0 0 0 12.602 9.8c8.536 6.1 17.404 12.142 25.727 18.282 8.323 6.139 15.216 12.566 18.008 19.438 1.423 3.464 1.554 6.928.046 10.393-2.19 5.04-7.654 9.603-15.593 13.768-5.524 2.91-12.047 5.503-19.061 7.94h3.717c.355-.172.711-.357 1.095-.513 4.009-1.633 8.266-3.201 12.001-4.901 13.189-6.047 20.715-12.88 20.879-20.673.1-4.894-2.6-9.625-6.758-14.256-4.845-5.378-11.428-10.482-18.478-15.51-8.935-6.375-18.18-12.692-26.84-19.115-2.447-1.814-4.741-3.65-6.83-5.514zM135.104 182.294a52 52 0 0 1 1.814-1.031c.462-.253.903-.509 1.351-.761a1.45 1.45 0 0 0-.732.117c-.897.455-1.757.918-2.59 1.384q.081.143.157.291" />
            <path d="M136.537 212.51q.389.507.793 1.014c5.033 6.235 12.3 12.146 19.876 18.008 8.462 6.544 17.429 13.004 24.717 19.744 6.174 5.726 11.058 11.57 12.41 17.745 1.422 6.53-1.626 12.748-9.718 18.616-5.776 4.187-13.516 7.857-22.461 11.215h3.713c.245-.131.466-.27.733-.391 4.126-1.889 8.536-3.703 12.335-5.691 9.877-5.143 15.75-10.856 17.254-17.126 1.092-4.567-.302-9.063-3.148-13.516-3.631-5.691-9.336-11.09-15.952-16.362-7.615-6.082-15.618-12.093-23.248-18.175-6.683-5.335-12.762-10.735-16.7-16.397-.188.459-.387.882-.604 1.316M137.284 188.917c2.771-3.155 6.758-6.142 11.873-8.963.32-.178.594-.356.89-.551l-.442-.139c-.057.075-.064.164-.178.228-5.186 2.717-9.326 5.563-12.32 8.536.056.306.117.598.177.89" />
            <path d="M170.594 298.852c1.967-.939 3.952-1.87 5.897-2.813 9.767-4.738 16.835-9.938 20.274-15.757a15.82 15.82 0 0 0 1.68-12.805c-1.879-6.758-7.15-13.22-13.937-19.513-6.704-6.217-13.896-12.367-20.84-18.549-6.401-5.69-12.306-11.453-16.193-17.457-2.604-4.015-4.205-8.077-4.158-12.207.089-7.825 6.263-14.97 18.122-21.483a1.7 1.7 0 0 1 .711-.21 4 4 0 0 1-.49.356c-7.214 4.154-12.247 8.632-14.879 13.452a15.7 15.7 0 0 0-1.636 10.877c1.597 8.148 8.383 15.82 16.564 23.343 7.416 6.819 15.294 13.573 22.764 20.385 5.84 5.317 10.699 10.755 13.69 16.393 3.913 7.36 3.952 14.668-2.54 21.875-4.52 5.018-11.712 9.496-20.74 13.605-.355.156-.6.334-.9.505z" />
            <path d="M178.7 298.852c.75-.427 1.465-.86 2.252-1.277 10.186-5.378 17.183-11.24 20.274-17.688a18.18 18.18 0 0 0 1.287-12.353c-1.87-7.917-7.825-15.486-14.846-22.96-5.826-6.202-11.951-12.37-17.784-18.573-5.368-5.691-9.76-11.51-12.268-17.486-4.368-10.396-1.287-20.377 12.751-29.731a12 12 0 0 1 2.263-1.23 3.6 3.6 0 0 1-.434.38q-12.094 8.045-14.342 17.176c-1.234 5-.273 9.959 2.135 14.906 3.98 8.22 11.381 16.08 18.972 23.924 6.146 6.356 12.48 12.69 17.428 19.207 4.57 6.007 7.868 12.093 8.263 18.342q.928 14.644-21.633 26.798c-.356.181-.605.384-.907.58z" />
            <path d="M186.806 298.852c1.779-1.252 3.632-2.49 5.335-3.749 7.62-5.659 12.094-11.634 13.64-17.912 1.31-5.37.46-10.706-1.66-16.027-2.846-7.174-8.067-14.15-13.349-21.12-5.044-6.652-10.13-13.3-14.836-19.983-3.688-5.232-5.975-10.567-6.91-15.967-1.608-9.28 2.112-18.218 13.117-26.676a2.6 2.6 0 0 1 .964-.462c-1.16 1.067-2.369 2.098-3.468 3.158-5.442 5.243-8.305 10.67-9.102 16.27-1.003 7.017 1.466 13.913 5.307 20.76 4.211 7.512 10.176 14.857 15.81 22.234 4.809 6.296 9.415 12.61 12.556 19.05 2.845 5.862 4.353 11.76 3.464 17.714-1.18 7.867-6.726 15.294-16.955 22.266a7 7 0 0 0-.534.448z" />
            <path d="M194.909 298.852c2.134-2.134 4.442-4.236 6.306-6.402 4.29-4.98 6.655-10.08 7.612-15.295 1.102-5.964.22-11.897-1.694-17.823-2.201-6.826-5.943-13.551-9.603-20.274-3.724-6.854-7.605-13.697-10.905-20.576-3.201-6.676-4.895-13.424-4.478-20.217.455-7.448 3.674-14.722 10.702-21.743a1.53 1.53 0 0 1 .712-.424c-.872 1.067-1.779 2.135-2.608 3.18-3.556 4.667-5.637 9.415-6.587 14.228a31.6 31.6 0 0 0 .879 15.874 114 114 0 0 0 6.235 15.65c3.852 7.903 8.536 15.753 12.346 23.66 2.38 4.948 3.99 9.96 5.595 14.939 1.486 4.624 1.65 9.269.935 13.918-1.11 7.227-4.84 14.263-11.905 21.049a1.3 1.3 0 0 0-.178.235z" />
            <path d="M211.448 264.209c-.189-.854-.384-1.704-.573-2.558-.836-3.83-1.515-7.668-2.546-11.496-1.124-4.168-2.572-8.33-3.885-12.491-1.5-4.773-3.158-9.54-4.467-14.32-1.273-4.66-2.383-9.326-3.201-14-.91-5.147-1.227-10.315-.238-15.461a94.5 94.5 0 0 1 3.158-11.713c.75-2.262 2.156-4.496 3.265-6.74l.441.025a24 24 0 0 0-1.16 2.571c-1.24 3.959-2.688 7.91-3.556 11.88-1.256 5.72-1.096 11.457-.313 17.18.608 4.414 1.533 8.82 2.564 13.22.99 4.219 2.252 8.427 3.475 12.634s2.64 8.494 3.842 12.74c.95 3.359 1.704 6.72 2.56 10.081.179.686.428 1.373.645 2.06zM203.015 298.852c1.61-2.88 3.585-5.744 4.744-8.647 1.47-3.677 2.277-7.39 3.372-11.09.09-.302.217-.6.324-.903v8.6c-.092.096-.231.189-.27.285q-2.405 5.872-4.795 11.737zM211.448 224.162a13 13 0 0 1-.274-1.71c-.217-9.021-.494-18.041-.58-27.061-.032-3.497.356-6.993.594-10.49a2.5 2.5 0 0 1 .26-.665z" />
          </g>
          <g
            className="page-5_svg__cls-9"
            data-name="CZJK68"
            style={{ opacity: 0.13 }}
          >
            <path d="M17.141 41.991c-.885 3.646-2.038 7.281-3.201 10.916-1.067 3.265-2.216 6.523-3.361 9.782l-1.868.956c-.177.118-.355.242-.526.356 1.18-4.34 2.45-8.679 3.422-13.025 1.294-5.787 2.49-11.578 3.24-17.379a53 53 0 0 0-1.522-21.092A52.2 52.2 0 0 0 7.872-.268h3.38c4.559 6.545 7.02 13.207 7.675 19.944a72 72 0 0 1 0 12.274c-.26 3.337-.971 6.684-1.786 10.041" />
            <path d="M26.193 49.293a217 217 0 0 1-4.147 7.505l-3.578 1.832c2.134-4.31 4.3-8.618 6.345-12.933a45.7 45.7 0 0 0 4.176-14.583c.686-6.124.085-12.232-2.885-18.282-2.208-4.5-5.434-8.892-10.14-13.128h3.713a3.7 3.7 0 0 0 .491.633c7.299 6.491 11.336 13.253 12.62 20.235A28.95 28.95 0 0 1 31.83 35.4c-1.58 4.653-3.247 9.302-5.638 13.893" />
            <path d="M35.068 52.2q-1.686.437-3.35.931c3.67-5.335 6.97-10.67 8.984-16.13 2.262-6.128 2.867-12.26.64-18.41-1.903-5.247-5.844-10.272-11.905-15.074-1.611-1.277-3.35-2.533-5.033-3.799h3.38a6 6 0 0 0 .604.495C40.286 7.842 46.19 16.058 46.659 24.808c.3 5.606-1.547 11.14-4.524 16.632a89 89 0 0 1-7.067 10.76" />
            <path d="M47.413 49.77q-1.597.213-3.169.477c3.159-3.778 5.962-7.594 8.042-11.485 2.977-5.567 4.382-11.165 2.931-16.838q-2.383-9.326-14.839-17.497c-2.45-1.607-5.236-3.144-7.868-4.709h3.376c1.149.63 2.301 1.256 3.443 1.889C52.784 9.027 59.624 17.164 59.57 26.07c-.036 5.744-2.686 11.346-6.659 16.88a87 87 0 0 1-5.499 6.82" />
            <path d="M58.753 48.792c-.978.04-1.953.085-2.924.146 4.809-4.98 8.814-10.02 10.973-15.27 1.66-4.047 2.091-8.106.733-12.171-2-5.983-7.587-11.507-16.6-16.554-3.201-1.778-6.869-3.486-10.315-5.225h3.375c3.113 1.501 6.403 2.96 9.309 4.51 10.482 5.599 16.788 11.76 18.332 18.535 1.099 4.834-.313 9.603-3.173 14.316-2.43 4.002-5.808 7.89-9.71 11.713" />
            <path d="M68.993 48.75q-1.323-.047-2.664-.058a98 98 0 0 0 4.165-4.026c5.132-5.303 8.938-10.706 9.7-16.361 1.013-7.502-3.995-14.416-14.85-20.79C60.556 4.7 54.847 2.155 48.719-.278H52.1l.925.355c9.486 3.66 17.49 7.683 23.063 12.31 8.764 7.278 9.585 14.797 5.203 22.476-2.742 4.799-7.096 9.394-12.3 13.886" />
            <path d="M77.85 49.525q-1.11-.153-2.238-.285c7.47-5.858 13.324-11.919 15.49-18.396 1.668-4.98.615-9.874-3.784-14.65C82.025 10.445 72.68 5.64 61.246 1.314 59.859.792 58.521.248 57.163-.282h3.375a5 5 0 0 0 .672.331C68.45 2.585 74.975 5.331 80.478 8.4c9.418 5.25 14.55 10.98 14.793 17.262.203 5.335-2.999 10.425-7.807 15.415a90.6 90.6 0 0 1-9.615 8.448" />
            <path d="M88.136 48.934q-1.444.993-2.913 1.978a86 86 0 0 0-1.658-.377 132 132 0 0 0 5.222-4.002c6.914-5.602 12.449-11.353 13.768-17.535 1.067-4.915-.832-9.667-6.114-14.227C90.206 9.389 80.396 4.957 68.829.988c-1.213-.416-2.376-.85-3.557-1.277h3.38c3.13 1.11 6.334 2.198 9.379 3.34 9.248 3.464 16.927 7.32 22.03 11.809 7.132 6.277 7.602 12.715 3.519 19.264-3.251 5.203-8.608 10.126-15.444 14.81" />
            <path d="M105.586 42.88c-4.062 3.404-8.807 6.673-13.826 9.892a74 74 0 0 0-1.508-.487 183 183 0 0 0 5.666-3.913c7.58-5.477 13.986-11.111 16.443-17.236 1.882-4.685 1.131-9.294-3.08-13.804C104.074 11.758 94.78 7.166 83.36 3.104 80.119 1.948 76.704.853 73.37-.268h3.376L80.63.995C90.633 4.196 99.5 7.782 106.14 12.021c8.842 5.652 11.968 11.713 9.688 18.14-1.572 4.432-5.37 8.63-10.243 12.72M1.473 69.162c-.355.31-.711.622-1.042.935V-.282h2.362c.096.59.242 1.185.28 1.779.502 7.266 1.239 14.53 1.424 21.792.284 11.485-.811 22.967-1.96 34.441a975 975 0 0 0-1.064 11.432" />
            <path d="M120.425 37.936C114.9 43.727 106.89 49.073 98.12 54.27l-.206.121a83 83 0 0 0-1.676-.458q3.326-2.031 6.584-4.08c7.718-4.859 13.993-9.96 17.71-15.455 3.165-4.67 3.937-9.368.935-14.092-4.233-6.658-14.583-11.969-27.8-16.635-3.913-1.377-8.12-2.64-12.193-3.952h3.379c.462.146.91.303 1.387.445 10.066 2.991 19.25 6.285 26.527 10.23 9.141 4.957 14.01 10.392 13.615 16.4-.256 3.834-2.51 7.533-5.957 11.143" />
            <path d="M125.141 41.991c-6.103 4.98-13.747 9.632-21.76 14.228-.413-.157-.83-.306-1.246-.456 6.218-3.642 12.168-7.341 17.297-11.214 6.723-5.069 11.962-10.315 13.16-15.985q1.505-7.163-6.939-13.647c-6.178-4.742-15.45-8.612-25.993-12.094C96.427 1.756 92.952.778 89.587-.243h3.375c2.967.907 5.951 1.804 8.892 2.725 8.363 2.614 15.732 5.556 21.697 8.942 10.194 5.794 14.103 12.093 11.382 18.919-1.625 4.044-5.193 7.9-9.792 11.648" />
            <path d="M108.143 58.2c-.388-.182-.779-.356-1.174-.537 5.062-2.924 10.095-5.855 14.886-8.836 7.22-4.492 13.2-9.201 16.87-14.259 3.791-5.221 4.41-10.453.067-15.675-4.147-4.98-11.759-9.248-21.764-12.886-6.096-2.223-12.872-4.201-19.346-6.289h3.717c1.338.423 2.625.868 4.016 1.27 8.824 2.54 16.781 5.385 23.496 8.675 13.598 6.662 18.578 14.046 14.121 22.234-2.703 4.98-8.053 9.554-14.483 14-6.143 4.247-13.164 8.298-20.406 12.303" />
            <path d="m113.72 59.619-1.37.747c-.305-.174-.611-.356-.92-.512q5.246-2.949 10.442-5.919c8.089-4.624 15.437-9.411 20.947-14.537 4.307-4.008 7.213-8.134 7.469-12.449.302-5.41-3.86-10.371-11.787-14.938-7.057-4.055-16.18-7.43-26.321-10.461-2.024-.605-4.023-1.22-6.047-1.832h3.376c.84.263 1.654.537 2.522.786 11.837 3.422 22.525 7.228 30.364 11.99 9.334 5.67 12.45 11.774 9.558 18.283-1.974 4.46-6.303 8.636-11.71 12.676-7.58 5.652-16.966 10.934-26.523 16.166" />
            <path d="M116.228 62.756a58 58 0 0 0-.918-.601c7.992-4.55 15.945-9.113 23.55-13.747 7.97-4.859 14.149-10.03 17.428-15.6 3.756-6.346 2.213-12.481-5.95-18.318-7.174-5.133-17.756-9.223-29.807-12.862l-6.313-1.91h3.38c.405.135.785.285 1.22.406 11.986 3.418 22.82 7.227 30.858 11.99 8.867 5.253 12.506 10.948 10.696 17.073-1.384 4.684-5.577 9.077-11.005 13.32-6.797 5.335-15.295 10.276-24.066 15.18q-4.517 2.54-9.073 5.07" />
            <path d="M119.895 65.196c-.246-.17-.498-.356-.754-.509l.868-.49c10.314-5.83 20.871-11.606 29.639-17.785 6.94-4.883 12.701-9.92 15.027-15.404 2.366-5.567.605-10.92-6.078-16.027-6.502-4.98-16.262-9-27.537-12.588-2.87-.91-5.816-1.778-8.725-2.685h3.393c.416.135.811.277 1.245.405 11.229 3.258 21.398 6.882 29.166 11.325q15.536 8.892 11.026 19.307c-1.917 4.456-6.15 8.646-11.435 12.705-7.608 5.833-17.123 11.268-26.762 16.674-3.02 1.697-6.057 3.383-9.073 5.072" />
            <path d="M122.968 67.465c4.887-2.92 9.827-5.833 14.732-8.75 8.217-4.883 16.394-9.777 22.928-14.999 6.046-4.823 10.699-9.785 11.787-15.127s-1.828-10.365-8.703-15.092c-6.08-4.175-14.438-7.69-23.998-10.848-3.03-1-6.179-1.956-9.273-2.93h3.375c.356.12.733.248 1.12.355 9.113 2.728 17.497 5.69 24.436 9.201 13.356 6.723 18.31 14.153 14.228 22.34-1.939 3.913-5.467 7.62-9.928 11.226-6.658 5.385-15.01 10.429-23.517 15.437-5.5 3.244-11.052 6.473-16.53 9.717-.209-.17-.43-.352-.657-.53" />
            <path d="M126.375 70.474c4.681-2.973 9.568-5.9 14.413-8.842 8.671-5.26 17.364-10.517 24.506-16.087 6.403-4.98 11.546-10.105 13.242-15.622 1.455-4.72.047-9.301-4.716-13.733-5.147-4.791-13.324-8.842-23.265-12.449-3.86-1.394-8-2.689-12.012-4.03h3.38c.373.121.74.249 1.12.356 11.111 3.482 20.985 7.338 28.188 12.036 7.946 5.193 11.158 10.752 9.77 16.66-1.013 4.333-4.296 8.466-8.717 12.485-6.307 5.733-14.658 11.083-23.266 16.383-7.316 4.503-14.746 8.984-21.953 13.516-.224-.217-.458-.445-.69-.673" />
            <path d="M131.629 74.11q-.658.448-1.298.9c-.182-.246-.356-.491-.548-.733 3.201-2.29 6.584-4.55 10.002-6.805 8.942-5.883 18.072-11.737 26.676-17.688 7.405-5.129 13.32-10.492 16.518-16.208 2.707-4.83 2.945-9.657-.427-14.47-3.557-5.054-10.393-9.486-19.74-13.387-5.051-2.106-10.75-4.009-16.152-6h3.375c6.897 2.268 13.335 4.69 18.93 7.405 12.093 5.865 18.684 12.402 18.158 19.793-.356 4.855-3.557 9.5-8.181 14.029-5.762 5.648-13.466 10.958-21.498 16.215-8.621 5.645-17.453 11.25-25.815 16.949" />
            <path d="M133.393 80.021q-.173-.346-.356-.686a158 158 0 0 1 12.588-9.8c8.536-6.1 17.404-12.143 25.727-18.282s15.216-12.566 18.004-19.438c1.423-3.464 1.558-6.929.05-10.393-2.19-5.036-7.658-9.603-15.593-13.769-5.524-2.909-12.047-5.498-19.061-7.935h3.717c.355.17.711.356 1.095.509 4.009 1.632 8.266 3.201 12.001 4.905 13.189 6.046 20.715 12.875 20.879 20.669.1 4.897-2.6 9.624-6.758 14.255-4.841 5.378-11.425 10.482-18.475 15.512-8.934 6.374-18.182 12.694-26.84 19.118-2.447 1.814-4.74 3.65-6.829 5.51zM135.808 116.276c.59.356 1.192.69 1.814 1.028.459.252.904.508 1.352.761a1.45 1.45 0 0 1-.733-.117 73 73 0 0 1-2.59-1.384q.081-.144.157-.288" />
            <path d="M137.242 86.057c.26-.356.519-.676.793-1.014 5.033-6.235 12.296-12.146 19.875-18.008 8.462-6.545 17.429-13 24.717-19.744 6.174-5.723 11.058-11.57 12.41-17.745 1.422-6.53-1.626-12.748-9.718-18.613-5.776-4.19-13.516-7.86-22.461-11.215h3.713c.242.128.463.267.733.391 4.126 1.886 8.536 3.703 12.332 5.691 9.88 5.144 15.753 10.856 17.257 17.127 1.092 4.567-.302 9.062-3.147 13.516-3.632 5.69-9.337 11.086-15.953 16.361-7.615 6.082-15.618 12.093-23.247 18.18-6.684 5.334-12.762 10.73-16.7 16.396a19 19 0 0 0-.604-1.323M137.988 109.65c2.768 3.158 6.758 6.142 11.87 8.966.323.178.597.356.892.552l-.44.135c-.058-.075-.065-.164-.182-.224-5.182-2.721-9.323-5.567-12.317-8.537.057-.306.117-.615.177-.893" />
            <path d="M171.298-.282c1.967.936 3.952 1.868 5.898 2.81 9.767 4.741 16.83 9.96 20.274 15.757a15.84 15.84 0 0 1 1.678 12.805c-1.878 6.758-7.149 13.22-13.935 19.512-6.705 6.218-13.897 12.367-20.84 18.553-6.402 5.69-12.307 11.45-16.198 17.453-2.6 4.016-4.2 8.078-4.154 12.207.089 7.825 6.263 14.971 18.14 21.484a1.7 1.7 0 0 0 .711.213 4 4 0 0 0-.49-.356c-7.214-4.154-12.247-8.636-14.879-13.455a15.65 15.65 0 0 1-1.636-10.873c1.597-8.15 8.38-15.821 16.56-23.348 7.417-6.818 15.295-13.572 22.764-20.384 5.84-5.335 10.7-10.756 13.69-16.39 3.913-7.362 3.952-14.672-2.539-21.878-4.52-5.019-11.713-9.497-20.74-13.605-.356-.156-.601-.334-.9-.501z" />
            <path d="M179.404-.282c.75.427 1.462.86 2.252 1.277 10.183 5.374 17.183 11.236 20.274 17.685a18.18 18.18 0 0 1 1.287 12.353c-1.87 7.917-7.825 15.49-14.846 22.963-5.826 6.203-11.954 12.367-17.784 18.57-5.367 5.69-9.76 11.51-12.271 17.485-4.364 10.4-1.284 20.377 12.755 29.732a12 12 0 0 0 2.262 1.234 3.5 3.5 0 0 0-.434-.38q-12.093-8.046-14.341-17.18c-1.235-5-.274-9.96 2.134-14.907 3.98-8.22 11.382-16.08 18.972-23.92 6.143-6.36 12.48-12.694 17.428-19.206 4.571-6.004 7.868-12.094 8.263-18.34q.928-14.643-21.636-26.797c-.356-.185-.601-.387-.904-.58z" />
            <path d="M187.51-.282c1.779 1.249 3.632 2.49 5.336 3.75 7.618 5.658 12.093 11.63 13.64 17.908 1.309 5.37.459 10.706-1.66 16.03-2.846 7.175-8.068 14.146-13.353 21.117-5.04 6.652-10.127 13.3-14.832 19.983-3.689 5.235-5.976 10.567-6.911 15.966-1.608 9.28 2.112 18.219 13.117 26.677a2.5 2.5 0 0 0 .964.459c-1.16-1.05-2.369-2.095-3.468-3.159-5.445-5.243-8.305-10.67-9.102-16.269-1.003-7.018 1.466-13.91 5.307-20.761 4.211-7.509 10.173-14.857 15.807-22.23 4.812-6.3 9.418-12.613 12.559-19.054 2.845-5.859 4.354-11.76 3.464-17.713-1.18-7.865-6.726-15.295-16.955-22.266a7 7 0 0 1-.534-.445z" />
            <path d="M195.613-.282c2.116 2.134 4.442 4.236 6.306 6.402 4.29 4.98 6.651 10.08 7.612 15.295 1.099 5.965.22 11.898-1.693 17.823-2.202 6.826-5.944 13.552-9.604 20.274-3.724 6.85-7.604 13.694-10.909 20.573-3.2 6.68-4.89 13.424-4.474 20.217.455 7.448 3.674 14.722 10.702 21.747a1.53 1.53 0 0 0 .73.42c-.872-1.068-1.797-2.113-2.608-3.18-3.556-4.663-5.637-9.415-6.587-14.228a31.6 31.6 0 0 1 .879-15.878 114 114 0 0 1 6.235-15.65c3.852-7.903 8.536-15.753 12.342-23.656 2.383-4.948 3.99-9.96 5.599-14.939 1.486-4.624 1.646-9.27.935-13.914-1.113-7.228-4.84-14.267-11.908-21.05a2 2 0 0 1-.178-.234z" />
            <path d="M212.16 34.358q-.29 1.279-.573 2.558c-.836 3.834-1.516 7.672-2.547 11.495-1.124 4.173-2.572 8.33-3.884 12.492-1.501 4.773-3.159 9.54-4.468 14.32-1.273 4.66-2.383 9.33-3.2 14-.908 5.15-1.224 10.314-.235 15.461a95 95 0 0 0 3.158 11.716c.747 2.259 2.155 4.493 3.265 6.758l.441-.028a24 24 0 0 1-1.16-2.572c-1.24-3.958-2.688-7.91-3.556-11.88-1.26-5.719-1.1-11.453-.313-17.18.605-4.41 1.533-8.816 2.564-13.22.99-4.215 2.252-8.422 3.475-12.634s2.64-8.49 3.842-12.74c.95-3.358 1.704-6.72 2.56-10.077.175-.69.428-1.376.644-2.063zM203.719-.282c1.611 2.881 3.585 5.74 4.745 8.647 1.469 3.678 2.276 7.391 3.372 11.087.089.302.217.6.323.903v-8.597c-.092-.092-.23-.185-.27-.284Q209.485 5.6 207.094-.264zM212.16 74.405a12.5 12.5 0 0 0-.275 1.71c-.217 9.02-.494 18.04-.58 27.061-.031 3.496.356 6.993.595 10.49a2.4 2.4 0 0 0 .26.664z" />
          </g>
          <path
            d="M297.52 1486.58h466v331.56A176.51 176.51 0 0 1 587 1994.66H291.53a36.07 36.07 0 0 1-36.07-36.07v-430a42.05 42.05 0 0 1 42.05-42.05z"
            className="page-5_svg__cls-21"
            style={{
              fill: palette.backgroundLight,
              filter: "url(#page-5_svg__a)",
            }}
            transform="matrix(-.35568 0 0 -.35568 286.497 760.207)"
          />
          <path
            d="M403.37 1486.53h74.68v343.4a165 165 0 0 1-165 165H255v-360a148.39 148.39 0 0 1 148.39-148.39z"
            className="page-5_svg__cls-22"
            style={{ fill: palette.primary, filter: "url(#page-5_svg__b)" }}
            transform="matrix(-.35568 0 0 -.35568 184.782 760.27)"
          />
          <text
            x={33.328}
            y={97.691}
            className="page-5_svg__cls-23"
            style={{
              fontSize: "20.7317px",
              fontFamily: "Heavitas",
              fill: palette.secondary,
              strokeWidth: 0.352699,
            }}
          >
            <tspan style={{ fill: palette.terciary }}>{"MI"}</tspan>
            <tspan
              x={33.328}
              y={122.781}
              style={{ fill: palette.terciary, strokeWidth: 0.355684 }}
            >
              {"NHA"}
            </tspan>
            <tspan
              className="page-5_svg__cls-8"
              style={{ strokeWidth: 0.355684 }}
            >
              <tspan x={33.328} y={147.871} style={{ strokeWidth: 0.355684 }}>
                {"HIS"}
              </tspan>
              <tspan x={33.328} y={172.961} style={{ strokeWidth: 0.355684 }}>
                {"T\xD3"}
              </tspan>
              <tspan x={33.328} y={198.048} style={{ strokeWidth: 0.355684 }}>
                {"RIA"}
              </tspan>
            </tspan>
          </text>
          {/* <g
            className="page-5_svg__cls-131"
            clipPath="url(#page-5_svg__c)"
            transform="matrix(.35568 0 0 .35568 -75.945 -478.019)"
          >
            <g data-name="1WXcGf">
              <image
                width={2076}
                height={2769}
                data-name="FkisBk"
                transform="matrix(.16 0 0 .16 469.66 1844.45)"
              />
            </g>
          </g> */}

          <ClippedImage
            id="saphire-page-5"
            triggerImageEdit={triggerImageEdit}
            x={92}
            y={180}
            width={120}
            height={120}
            imageNumber={4}
            imageRatio={1}
            notebook={notebook}
          >
            <path
              d="M623.95 1849.67a183 183 0 0 1 183 183v151.79H655.11a183 183 0 0 1-183-183 151.79 151.79 0 0 1 151.84-151.79"
              style={{ fill: palette.backgroundLight }}
              transform="matrix(.35568 0 0 .35568 -75.945 -478.019)"
            />
          </ClippedImage>

          <foreignObject
            x={99.005}
            y={55.032}
            width={90}
            height={122}
            className="clickable"
          >
            <div
              style={{
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: palette.backgroundGray,
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                height: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.bio,
                  field: "bio",
                  label: "Trajetória",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.bio}
            </div>
          </foreignObject>
        </svg>
      </PaperInside>
    </Paper>
  );
};

export default Page5;
