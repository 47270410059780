import React, { memo } from "react";
import { Status } from "../../../../app/constants";
import { makeContainer } from "../../../../components/container/container";
import Loading from "../../../../components/loading/loading";
import ScrollContainer from "../../../../components/scroll/scroll";
import {
  Candidate,
  CandidateElectionSummary,
} from "../../../../features/candidates/type";
import ShopCandidatureSearchResultItem from "../shop-candidature-search-result-item/shop-candidature-search-result-item";
import "./shop-candidature-search-result.css";

type Props = {
  candidate?: Candidate;
  status: Status;
  selectedCandidature: CandidateElectionSummary | null;
  setSelectedCandidature: (CandidateElectionSummary) => void;
};

const ShopCandidatureSearchResultContainer = makeContainer(
  "shop-candidature-search-result"
);

const ShopCandidatureSearchResult: React.FC<Props> = ({
  candidate,
  status,
  selectedCandidature,
  setSelectedCandidature,
}) => {
  const isLoading = status === Status.LOADING;

  return (
    <ShopCandidatureSearchResultContainer>
      {isLoading && <Loading />}
      {!isLoading && (
        <ScrollContainer>
          <>
            {candidate?.election_summary?.map((summary, index) => (
              <ShopCandidatureSearchResultItem
                summary={summary}
                selected={selectedCandidature}
                setSelected={setSelectedCandidature}
                key={`candidature-details-${index}`}
              />
            ))}
          </>
        </ScrollContainer>
      )}
    </ShopCandidatureSearchResultContainer>
  );
};

export default memo(ShopCandidatureSearchResult);
