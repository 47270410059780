import React, { memo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { Pages, ShopPages } from "../../app/constants";
import { AppDispatch } from "../../app/store";
import { getFirstWordOrEmailPrefix } from "../../app/utils";
import { selectAuthUserData, selectIsLogged } from "../../features/auth/slice";
import { UserData } from "../../features/auth/type";
import { setCurrentPage, setIsAuthenticating } from "../../features/shop/slice";
import UserSillhouette from "../../resources/icons/user-silhouette.svg";
import DataElegeLogoLg from "../../resources/images/dataelege-logo-lg.png";
import DataElegeLogoMd from "../../resources/images/dataelege-logo-md.png";
import DataElegeTextLogo from "../../resources/images/dataelege-logo-text.png";
import CartButton from "../buttons/cart-button/cart-button";
import ShopButton from "../buttons/shop-button/shop-button";
import { makeContainer } from "../container/container";
import LogoutModal from "../main-page/logout-modal/logout-modal";
import PopMenu from "../menu/pop-menu";
import "./menu.css";

const MenuContainer = makeContainer("shop-menu-container");
const DetailsContainer = makeContainer("shop-menu-details ");

const UserPhotoDisplay: React.FC<{ photo: string }> = memo(({ photo }) => {
  return (
    <img
      className="shop-user-photo-display"
      src={photo ? photo : UserSillhouette}
      alt="foto do usuario"
    />
  );
});

const UserNameDisplay: React.FC = memo(() => {
  const [menuOpened, setMenuOpenened] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const userData: UserData = useSelector(selectAuthUserData);
  const userDisplayRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleClick(link: string) {
    return () => navigate(`/${link}`);
  }

  const entries = [
    {
      key: "Plataforma",
      label: "Plataforma",
      onClick: handleClick(Pages.HOME),
    },
    {
      key: "Meus Pedidos",
      label: "Meus Pedidos",
      onClick: () => dispatch(setCurrentPage(ShopPages.ORDERS)),
    },
    {
      key: "Meus Dados",
      label: "Meus Dados",
      onClick: handleClick(Pages.PROFILE),
    },
    { key: "Logout", label: "Logout", onClick: () => setShowLogoutModal(true) },
  ];

  return (
    <PopMenu
      open={menuOpened}
      entries={entries}
      containerRef={userDisplayRef}
      fixedWidth={150}
      onClose={() => setMenuOpenened(false)}
    >
      <div
        ref={userDisplayRef}
        className="shop-user-name-display"
        onClick={() => setMenuOpenened(true)}
      >
        <UserPhotoDisplay photo={userData.photoUrl} />
        <div className="shop-user-name-text">
          {getFirstWordOrEmailPrefix(userData.name)}
        </div>
      </div>

      <LogoutModal
        show={showLogoutModal}
        onClose={() => setShowLogoutModal(false)}
      />
    </PopMenu>
  );
});

export const ShopMenu: React.FC = () => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1024px)" });
  const isLogged = useSelector(selectIsLogged);
  const dispatch: AppDispatch = useDispatch();

  return (
    <MenuContainer>
      <a
        href="https://www.dataelege.com.br/loja"
        target="_blank"
        rel="noopener noreferrer"
      >
        <picture>
          <source
            srcSet={!isSmallScreen ? DataElegeTextLogo : DataElegeLogoLg}
            media="(min-width: 1386px)"
          />
          <img
            alt="dataelege-logo"
            className={
              !isSmallScreen ? "dataelege-logo" : "contracted-dataelege-logo"
            }
            src={!isSmallScreen ? DataElegeTextLogo : DataElegeLogoMd}
          />
        </picture>
      </a>

      {!isLogged && (
        <ShopButton
          onClick={() =>
            dispatch(
              setIsAuthenticating({
                isSigningIn: true,
                isSigningUp: false,
              })
            )
          }
        >
          Entrar
        </ShopButton>
      )}
      {isLogged && (
        <DetailsContainer>
          <UserNameDisplay />
          <CartButton
            onClick={() => dispatch(setCurrentPage(ShopPages.CART))}
          />
        </DetailsContainer>
      )}
    </MenuContainer>
  );
};
