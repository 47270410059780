import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  DashboardElectorateFilterNames,
  DashboardFilterConsts,
  DashboardVotingFilterNames,
  Mode,
  SCENARIO_A,
  Status,
} from "../../../app/constants";
import {
  ActiveFilter,
  add,
  create,
  createName,
  createValue,
  FilterNameValue,
  getValue,
  removeComplementaryFilters,
  removeDanglingFilters,
  removeWithValue,
  toFilterNames,
} from "../../../app/filter";
import { AppDispatch } from "../../../app/store";
import {
  getAvaliableYearsForState,
  getMostRecentElection,
} from "../../../app/utils";
import Filter from "../../../components/dashboard/filter/filter";
import { setAlert } from "../../../features/alert/slice";
import { AlertType } from "../../../features/alert/type";
import ConstantsService from "../../../features/constants/service";
import {
  getConstants,
  selectConstants,
  selectConstantsStatus,
} from "../../../features/constants/slice";
import { Constants } from "../../../features/constants/type";
import {
  addFilters,
  selectComparativeMode,
  selectDashboardComparativeFilters,
  selectDashboardFilters,
  selectStartupState,
  setFilters,
} from "../../../features/dashboard/slice";
import PartyService from "../../../features/parties/service";
import {
  getParties,
  selectParties,
  selectPartiesStatus,
} from "../../../features/parties/slice";
import { Party } from "../../../features/parties/type";
import { selectPermissions } from "../../../features/permission/slice";
import { PermissionsData } from "../../../features/permission/type";

type Props = {
  mode: Mode;
};

const FilterWithData: React.FC<Props> = ({ mode }) => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const dashFilters = useSelector(selectDashboardFilters);
  const dashComparativeFilters = useSelector(selectDashboardComparativeFilters);
  const comparativeMode: boolean = useSelector(selectComparativeMode);
  const constants: Constants = useSelector(selectConstants);
  const constantsStatus: Status = useSelector(selectConstantsStatus);
  const parties: Party[] = useSelector(selectParties);
  const partiesStatus: Status = useSelector(selectPartiesStatus);
  const permissions: PermissionsData = useSelector(selectPermissions);
  const startUpState = useSelector(selectStartupState);
  const [filterNames, setFilterNames] = useState(
    mode === Mode.VOTING
      ? DashboardVotingFilterNames
      : DashboardElectorateFilterNames
  );
  const [filterValues, setFilterValues] = useState([]);
  const [candidateMode, setCandidateMode] = useState(false);

  const status = [partiesStatus, constantsStatus].includes(Status.LOADING)
    ? Status.LOADING
    : Status.SUCCEEDED;

  const handleFilterNameChange = (filterName) => {
    const actions = {
      [DashboardFilterConsts.YEAR]: () => {
        const stateFilter = getValue(dashFilters, DashboardFilterConsts.STATE);
        setFilterValues(
          getAvaliableYearsForState(constants, permissions, stateFilter)
        );
      },
      [DashboardFilterConsts.TURN]: () => {
        setFilterValues(constants.turns);
      },
      [DashboardFilterConsts.OFFICE]: () => {
        setFilterValues(constants.offices);
      },
      [DashboardFilterConsts.SEX]: () => {
        setFilterValues(toFilterNames(constants.candidate_genders));
      },
      [DashboardFilterConsts.RACE]: () => {
        setFilterValues(toFilterNames(constants.races));
      },
      [DashboardFilterConsts.SCHOOL_LEVEL]: () => {
        setFilterValues(toFilterNames(constants.school_levels));
      },
      [DashboardFilterConsts.ELECTION_STATUS]: () => {
        setFilterValues(toFilterNames(constants.situations));
      },
      [DashboardFilterConsts.ELECTORATE_MARITAL_STATUS]: () => {
        setFilterValues(toFilterNames(constants.voters_marital_status));
      },
      [DashboardFilterConsts.ELECTORATE_SEX]: () => {
        setFilterValues(toFilterNames(constants.voters_genders));
      },
      [DashboardFilterConsts.ELECTORATE_AGE_GROUP]: () => {
        setFilterValues(toFilterNames(constants.age_groups));
      },
      [DashboardFilterConsts.ELECTORATE_SCHOOLING]: () => {
        setFilterValues(toFilterNames(constants.voters_school_levels));
      },
      [DashboardFilterConsts.PARTY]: () => {
        setFilterValues(parties.map((p) => ({ label: p.abbrev, value: p.id })));
      },
      [DashboardFilterConsts.CANDIDATE]: () => {
        setCandidateMode(true);
      },
    };

    if (candidateMode) {
      setCandidateMode(false);
    }

    actions[filterName.value]();
  };

  const handleFilterAdded = (filter: ActiveFilter, scenarioValue) => {
    if (comparativeMode) {
      if (scenarioValue.value === SCENARIO_A) {
        dispatch(
          setFilters({
            filters: add(dashFilters, filter),
            comparativeFilters: dashComparativeFilters,
          })
        );
      } else {
        dispatch(
          setFilters({
            filters: dashFilters,
            comparativeFilters: add(dashComparativeFilters, filter),
          })
        );
      }
      return;
    }

    dispatch(
      setFilters({
        filters: add(dashFilters, filter),
        comparativeFilters: dashComparativeFilters,
      })
    );
  };

  const handleFilterRemoved = (
    filter: ActiveFilter,
    scenario: FilterNameValue
  ) => {
    const filtersToCheck =
      scenario.value === SCENARIO_A ? dashFilters : dashComparativeFilters;
    if (checkIsLastYearFilter(filtersToCheck, filter)) {
      dispatch(
        setAlert({
          message: "Não é possível remover todo os filtros de anos.",
          type: AlertType.WARNING,
        })
      );
      return;
    }

    if (scenario.value === SCENARIO_A) {
      dispatch(
        setFilters({
          filters: removeDanglingFilters(
            filter,
            removeWithValue(dashFilters, filter)
          ),
          comparativeFilters: removeDanglingFilters(
            filter,
            removeComplementaryFilters(filter, dashComparativeFilters)
          ),
        })
      );
    } else {
      dispatch(
        setFilters({
          filters: removeDanglingFilters(
            filter,
            removeComplementaryFilters(filter, dashFilters)
          ),
          comparativeFilters: removeDanglingFilters(
            filter,
            removeWithValue(dashComparativeFilters, filter)
          ),
        })
      );
    }
  };

  const checkIsLastYearFilter = (
    filters: ActiveFilter[],
    filter: ActiveFilter
  ) => {
    if (filter.name.value !== DashboardFilterConsts.YEAR) {
      return false;
    }

    const yearFilters = filters.filter(
      (f) => f.name.value === DashboardFilterConsts.YEAR
    ).length;
    return yearFilters <= 1;
  };

  useEffect(() => {
    const fetchInitialData = () => {
      dispatch(getConstants({ service: new ConstantsService() }));
      dispatch(getParties({ service: new PartyService() }));
    };

    fetchInitialData();
  }, [dispatch]);

  useEffect(() => {
    const setupInitialFilter = () => {
      if (constants) {
        const queryParams = new URLSearchParams(location.search);
        const year = getMostRecentElection(constants.years);
        const hasStartUp = JSON.parse(JSON.stringify(startUpState));

        const newFilters = [
          create(
            createName("Ano", DashboardFilterConsts.YEAR),
            createValue(year, parseInt(year))
          ),
        ];

        if (mode === Mode.VOTING && !hasStartUp) {
          newFilters.push(
            create(
              createName("Turno", DashboardFilterConsts.TURN),
              createValue("1º TURNO", "1")
            )
          );
        }

        const party = queryParams.get("party");
        const partyId = queryParams.get("partyId");
        if (party) {
          newFilters.push(
            create(
              createName("Partido", DashboardFilterConsts.PARTY),
              createValue(party, partyId)
            )
          );
        }

        const candidate = queryParams.get("candidate");
        const candidateId = queryParams.get("candidateId");
        if (candidate) {
          newFilters.push(
            create(
              createName("Candidato", DashboardFilterConsts.CANDIDATE),
              createValue(candidate, candidateId)
            )
          );
        }

        const initialFilter = {
          filters: newFilters,
        };
        dispatch(addFilters(initialFilter));
      }
    };

    setupInitialFilter();
  }, [dispatch, mode, location, constants, startUpState]);

  useEffect(() => {
    const setupInitialFilterNames = () => {
      setFilterValues([]);
      setFilterNames(
        mode === Mode.VOTING
          ? [...DashboardVotingFilterNames]
          : [...DashboardElectorateFilterNames]
      );
    };

    setupInitialFilterNames();
  }, [setFilterNames, mode, dashFilters, dashComparativeFilters]);

  return (
    <Filter
      activeFilters={dashFilters}
      comparativeActiveFilters={dashComparativeFilters}
      filterNames={filterNames}
      filterValues={filterValues}
      onFilterAdded={debounce(handleFilterAdded, 750)}
      onFilterNameChanged={handleFilterNameChange}
      onFilterRemoved={debounce(handleFilterRemoved)}
      comparativeMode={comparativeMode}
      status={status}
      candidateMode={candidateMode}
    />
  );
};

export default FilterWithData;
