import React, { memo, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Pages } from "../../../../app/constants";
import { useOnScreen } from "../../../../app/hooks";
import { Map, toPtBr } from "../../../../app/utils";
import { makeContainer } from "../../../../components/container/container";
import { ElectionRankingItem as ElectionRakingItemType } from "../../../../features/elections/type";
import UserPhoto from "../../../../resources/icons/user-silhouette.svg";
import "./election-ranking-item.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  rankingItem?: ElectionRakingItemType;
  photos?: Map;
  onVisibilityChange?: (candidateId: string, isVisible: boolean) => void;
};

const ElectionRankingItemContainer = makeContainer(
  "election-ranking-item-container"
);

const ElectionRankingItemPhoto: React.FC<Props> = ({
  rankingItem,
  photos,
  onVisibilityChange,
}) => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const [visibilityTimeout, setVisiblityTimeout] = useState(null);

  useEffect(() => {
    const notifyVisibilityChange = () => {
      const shouldFetch =
        isVisible && !visibilityTimeout && !(rankingItem.candidateId in photos);
      const shouldStopFetching = !isVisible && visibilityTimeout;

      if (shouldFetch) {
        setVisiblityTimeout(
          setTimeout(
            () => onVisibilityChange(rankingItem.candidateId, isVisible),
            3000
          )
        );
      }

      if (shouldStopFetching) {
        clearTimeout(visibilityTimeout);
        setVisiblityTimeout(null);
      }
    };

    notifyVisibilityChange();
  }, [isVisible, visibilityTimeout, photos, rankingItem, onVisibilityChange]);

  return (
    <div className="election-ranking-item-photo-container">
      <Link
        to={`/${Pages.CANDIDATE_PROFILE.replace(
          ":id",
          rankingItem.candidateId
        )}`}
      >
        <img
          ref={ref}
          alt={"foto do candidato"}
          className="election-ranking-item-photo"
          src={
            rankingItem.candidateId in photos && photos[rankingItem.candidateId]
              ? `data:image/png;base64,${photos[rankingItem.candidateId]}`
              : UserPhoto
          }
        />
      </Link>
    </div>
  );
};

const ElectionRankingItemDetails: React.FC<Props> = ({ rankingItem }) => {
  return (
    <div className="election-ranking-item-details">
      <Link
        to={`/${Pages.CANDIDATE_PROFILE.replace(
          ":id",
          rankingItem.candidateId
        )}`}
      >
        <div className="election-ranking-item-name">
          {rankingItem?.ballotbox_name}
        </div>
        <div className="election-ranking-item-candidature-details">
          {`${rankingItem?.party_name} - ${rankingItem?.situation}`}
        </div>
      </Link>
    </div>
  );
};

const ElectionRankingItemVoting: React.FC<Props> = ({ rankingItem }) => {
  return (
    <div className="election-ranking-item-voting">{`${toPtBr(
      rankingItem.votes
    )} votos`}</div>
  );
};

const ElectionRankingItemBar: React.FC<Props> = ({ rankingItem }) => {
  const [width, setWidth] = useState("0%");
  const validVotesPercentage = rankingItem.prct_valid_votes / 100;
  const validVotesPercentageBarWidth = `${validVotesPercentage}%`;
  const validVotesPercentageText = `${toPtBr(validVotesPercentage)} %`;

  setTimeout(() => {
    setWidth(validVotesPercentageBarWidth);
  }, 1500);

  return (
    <div className="election-ranking-item-relative-voting">
      <div className="election-ranking-item-bar">
        <div
          className="election-ranking-item-bar-progress"
          style={{
            width: width,
          }}
        >
          {" "}
        </div>
        <span className="election-ranking-item-bar-text">
          {validVotesPercentageText}
        </span>
      </div>
    </div>
  );
};

const ElectionRankingItem: React.FC<Props> = ({
  rankingItem,
  photos,
  onVisibilityChange,
}) => {
  return (
    <ElectionRankingItemContainer>
      <ElectionRankingItemPhoto
        rankingItem={rankingItem}
        photos={photos}
        onVisibilityChange={onVisibilityChange}
      />
      <ElectionRankingItemDetails rankingItem={rankingItem} />
      <ElectionRankingItemVoting rankingItem={rankingItem} />
      <ElectionRankingItemBar rankingItem={rankingItem} />
    </ElectionRankingItemContainer>
  );
};

export default memo(ElectionRankingItem);
