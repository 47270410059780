import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShopPages, Status, WAITING_PAYMENT } from "../../../app/constants";
import { AppDispatch } from "../../../app/store";
import { fixDate, fromCurrencyToStr } from "../../../app/utils";
import ShopActionButton from "../../../components/buttons/shop-action-button/shop-action-button";
import { makeContainer } from "../../../components/container/container";
import Loading from "../../../components/loading/loading";
import ShopHeading, {
  Variant,
} from "../../../components/text/shop-heading/shop-heading";
import ShopService from "../../../features/shop/service";
import {
  getOrderDetails,
  selectGetOrderStatus,
  selectOrder,
  selectOrderId,
  setCurrentPage,
  setGetOrderStatus,
} from "../../../features/shop/slice";
import { getPaymentMethod, Order } from "../../../features/shop/type";
import ShopOrderItem from "../components/shop-order-item/shop-order-item";
import ShopOrderStatus from "../components/shop-order-status/shop-order-status";
import ShopPageContainer from "../components/shop-page-container/shop-page-container";
import ShopSpacer from "../components/shop-spacer/shop-spacer";
import "./order-details.css";

const TotalContainer = makeContainer("shop-cart-total-container");
const TotalLine = makeContainer("shop-cart-total-line");
const TotalTitle = makeContainer("shop-total-title");
const ShopOrderPaymentMethod = makeContainer(
  "shop-order-details-payment-method"
);
const ShopOrderDate = makeContainer("shop-order-details-date");

const openFileOnAnotherTab = (fileUrl: string) => {
  window.open(fileUrl, "_blank");
};

const OrderDetails: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const orderId = useSelector(selectOrderId);
  const order: Order = useSelector(selectOrder);
  const status: Status = useSelector(selectGetOrderStatus);

  const shouldShowBoletoBtn =
    order?.boleto_url && order?.status === WAITING_PAYMENT;
  const shouldShowPixBtn =
    !order?.boleto_url && order?.pix_url && order?.status === WAITING_PAYMENT;

  useEffect(() => {
    dispatch(
      getOrderDetails({
        id: orderId,
        service: new ShopService(),
      })
    );

    return () => {
      dispatch(setGetOrderStatus(Status.LOADING));
    };
  }, [dispatch, orderId]);

  return (
    <ShopPageContainer>
      <ShopHeading variant={Variant.H1}>{`Detalhes do pedido #${orderId
        .split("-")
        .slice(-1)[0]
        .toUpperCase()}`}</ShopHeading>
      <ShopSpacer space={10} />

      {status === Status.LOADING && (
        <>
          <Loading />
          <ShopSpacer space={50} />
        </>
      )}

      {status === Status.SUCCEEDED && (
        <>
          <div>
            <ShopOrderStatus orderStatus={order?.status} />
            <ShopOrderDate>{`${fixDate(order?.created)}`}</ShopOrderDate>
          </div>

          {order?.items?.map((item, index) => (
            <ShopOrderItem key={`shop-order-item-${index}`} item={item} />
          ))}

          <hr className="shop-order-separator" />
          <TotalContainer>
            <TotalLine>
              <TotalTitle>Total</TotalTitle>
              <div>
                <TotalTitle>{fromCurrencyToStr(order?.price)}</TotalTitle>
                <ShopOrderPaymentMethod>{`${getPaymentMethod(
                  order
                )}`}</ShopOrderPaymentMethod>
              </div>
            </TotalLine>
          </TotalContainer>

          <ShopSpacer space={50} />
          {shouldShowBoletoBtn && (
            <ShopActionButton
              onClick={() => openFileOnAnotherTab(order?.boleto_url)}
            >
              Imprimir Boleto
            </ShopActionButton>
          )}
          {shouldShowPixBtn && (
            <ShopActionButton
              onClick={() => openFileOnAnotherTab(order?.pix_url)}
            >
              QR Code PIX
            </ShopActionButton>
          )}
        </>
      )}

      <ShopActionButton
        outline={true}
        onClick={() => dispatch(setCurrentPage(ShopPages.PRODUCT_SELECT))}
      >
        Voltar à loja
      </ShopActionButton>
      <ShopActionButton
        outline={true}
        onClick={() => dispatch(setCurrentPage(ShopPages.ORDERS))}
      >
        Meus Pedidos
      </ShopActionButton>
    </ShopPageContainer>
  );
};

export default OrderDetails;
