import React, { memo } from "react";
import { BiInfoCircle } from "react-icons/bi";
import { useSelector } from "react-redux";
import Attribute from "../../../../app/attribute";
import { Feature, MapFeatures } from "../../../../app/maps";
import {
  selectMapAttribute,
  selectSelectedFeatures,
} from "../../../../features/dashboard/slice";
import { makeContainer, makeSpanContainer } from "../../../container/container";
import DashSelect from "../../../inputs/dash-select/dash-select";
import Tooltip from "../../../tooltip/tooltip";
import "./map-top-display.css";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  map?: MapFeatures;
  handleFeatureSelected: (selected: any) => void;
};

const MapTopDisplayContainer = makeContainer("map-top-display-container");
const MapTopDisplayContent = makeContainer("map-top-display-content");
const MapTopDisplayPlace = makeContainer("map-top-display-place");
const MapTopDisplaySummary = makeContainer("map-top-display-summary");
const MapTopDisplaySummaryAttribute = makeContainer(
  "map-top-display-summary-attribute"
);
const MapTopDisplaySummaryElement = makeContainer(
  "map-top-display-summary-element"
);
const MapTopDisplayValue = makeContainer("map-top-display-value");
const MapTopDisplayValueNumber = makeSpanContainer(
  "map-top-display-value-number"
);

const MapTopDisplay: React.FC<Props> = ({ map, handleFeatureSelected }) => {
  const selectedFeatures: Feature[] = useSelector(selectSelectedFeatures);
  const attribute: Attribute = useSelector(selectMapAttribute);
  const hasSelected = selectedFeatures?.length > 0;
  const feature = hasSelected ? selectedFeatures[0] : null;

  return (
    <MapTopDisplayContainer>
      <MapTopDisplayContent>
        <MapTopDisplaySummary>
          <MapTopDisplaySummaryAttribute>
            <BiInfoCircle className={"widget-title-info-icon"} />
            <span>{attribute?.friendly_name}</span>
          </MapTopDisplaySummaryAttribute>
          <MapTopDisplaySummaryElement>
            {hasSelected ? `${feature.name}` : "Selecione um local"}
          </MapTopDisplaySummaryElement>
          <MapTopDisplaySummaryElement>
            {hasSelected ? `${feature.value}` : "-"}
          </MapTopDisplaySummaryElement>
        </MapTopDisplaySummary>
        <MapTopDisplayPlace>
          <DashSelect
            label="Locais no Mapa"
            className="map-top-display-select"
            placeHolder="Selecione um local"
            options={map?.features?.map((m) => ({
              label: m.name,
              value: m.id,
            }))}
            value={{ label: feature?.name, value: feature?.id }}
            onChange={(selected) => handleFeatureSelected(selected)}
          />
        </MapTopDisplayPlace>
        <MapTopDisplayValue>
          {hasSelected && (
            <Tooltip
              title={attribute?.friendly_name}
              text={attribute?.description}
            >
              <BiInfoCircle className={"widget-title-info-icon"} />
            </Tooltip>
          )}
          <MapTopDisplayValueNumber>
            {hasSelected ? feature.value : "-"}
          </MapTopDisplayValueNumber>
        </MapTopDisplayValue>
      </MapTopDisplayContent>
    </MapTopDisplayContainer>
  );
};

export default memo(MapTopDisplay);
