import { NotebookPages, Pages } from "./constants";

import TargetPage from "../components/target-page/page";
import FogotPasswordPage from "../pages/auth/forgot-password-page/forgot-password";
import LoginPage from "../pages/auth/login-page/login";
import PasswordResetPage from "../pages/auth/password-reset-page/password-reset";
import SignupPage from "../pages/auth/signup-page/signup";
import CandidateProfilePage from "../pages/candidates/candidates-profile/candidates-profile";
import CandidateSearchPage from "../pages/candidates/search-candidates/search-candidates";
import {
  CandidateMap,
  ElectorateMap,
  VotingMap,
} from "../pages/dashboard/dashboard";
import ElectionsPage from "../pages/elections/elections";
import HomePage from "../pages/home/home";
import IntroFirst from "../pages/intro/intro-first";
import IntroFourth from "../pages/intro/intro-fourth";
import IntroSecond from "../pages/intro/intro-second";
import IntroThird from "../pages/intro/intro-third";
import { NotebookQuestionaire } from "../pages/notebook/questionaire/questionaire";
import { NotebookView } from "../pages/notebook/view/view";
import PartyProfilePage from "../pages/parties/party-profile/party-profile";
import SearchPartyPage from "../pages/parties/search-party/search-party";
import ProfilePage from "../pages/profile/profile";
import SettingsPage from "../pages/settings/settings";
import Terms from "../pages/terms/terms";

const PagesComponents = {
  [Pages.HOME]: HomePage,
  [Pages.LOGIN]: LoginPage,
  [Pages.SIGNUP]: SignupPage,
  [Pages.FORGOT]: FogotPasswordPage,
  [Pages.RESET]: PasswordResetPage,
  [Pages.INTRO_1]: IntroFirst,
  [Pages.INTRO_2]: IntroSecond,
  [Pages.INTRO_3]: IntroThird,
  [Pages.INTRO_4]: IntroFourth,
  [Pages.VOTING]: VotingMap,
  [Pages.ELECTORATE]: ElectorateMap,
  [Pages.CANDIDATE_VOTING]: CandidateMap,
  [Pages.ELECTIONS]: ElectionsPage,
  [Pages.SEARCH_PARTY]: SearchPartyPage,
  [Pages.PARTY_PROFILE]: PartyProfilePage,
  [Pages.SEARCH_CANDIDATE]: CandidateSearchPage,
  [Pages.CANDIDATE_PROFILE]: CandidateProfilePage,
  [Pages.PROFILE]: ProfilePage,
  [Pages.SETTINGS]: SettingsPage,
  [Pages.SHOP]: null,
  [Pages.TARGET]: TargetPage,
  [Pages.TERMS]: Terms,
};

export const NotebookPageComponents = {
  [NotebookPages.NOTEBOOK_QUESTIONAIRE]: NotebookQuestionaire,
  [NotebookPages.NOTEBOOK_VIEW]: NotebookView,
};

export default PagesComponents;
