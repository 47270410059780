import { ChangeEventHandler, memo } from "react";
import "./notebook-input-text.css";

type Props = {
  inputKey: string;
  label?: string;
  width?: number;
  className?: string;
  value?: string;
  type?: string;
  invalid?: boolean;
  placeHolder?: string;
  onEnterPressed?: () => void;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

const NotebookInputText: React.FC<Props> = ({
  inputKey,
  label,
  width,
  className,
  value,
  type,
  placeHolder,
  invalid,
  onChange,
  onEnterPressed,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (onEnterPressed) {
        onEnterPressed();
      }
    }
  };

  return (
    <div
      className={`notebook-input-label-container`}
      style={{ width: width ? `${width}px` : "100%" }}
    >
      <label
        className={`notebook-input-label ${
          invalid ? "notebook-input-label-invalid" : ""
        }`}
        htmlFor={inputKey}
      >
        {label}
      </label>
      <input
        key={inputKey}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        value={value}
        type={type}
        placeholder={placeHolder}
        className={`notebook-input-text ${className} ${
          invalid ? "notebook-input-text-invalid" : ""
        }`}
      ></input>
    </div>
  );
};

export default memo(NotebookInputText);
