import * as React from "react";
import { useSelector } from "react-redux";
import { validateText } from "../../../../../app/utils";
import { selectPalette } from "../../../../../features/notebook/slice";
import { ClippedImage, Paper, PaperInside } from "../commons/commons";
import { TemplatePageProps } from "../commons/interfaces";

const Page12: React.FC<TemplatePageProps> = ({
  className,
  notebook,
  triggerImageEdit,
  triggerFieldEdit,
  updateField,
}) => {
  const { palette } = useSelector(selectPalette);
  return (
    <Paper className={className}>
      <PaperInside>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
        >
          <defs>
            <filter id="page-12_svg__a">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <filter id="page-12_svg__b">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <filter id="page-12_svg__d">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <filter id="page-12_svg__f">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <filter id="page-12_svg__g">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <clipPath id="page-12_svg__c">
              <path
                d="M732.69 3172.65h77.36V3250a93.24 93.24 0 0 1-93.24 93.24 77.36 77.36 0 0 1-77.36-77.36 93.24 93.24 0 0 1 93.24-93.23"
                className="page-12_svg__cls-5"
              />
            </clipPath>
            <clipPath id="page-12_svg__e">
              <path
                d="M714.65 3468.77h-77.36v-77.36a93.24 93.24 0 0 1 93.24-93.24 77.36 77.36 0 0 1 77.36 77.36 93.24 93.24 0 0 1-93.24 93.24"
                className="page-12_svg__cls-5"
              />
            </clipPath>
            <clipPath id="page-12_svg__h">
              <path
                d="M540.73 3874.39h-173v-173a194.63 194.63 0 0 1 194.63-194.63 173 173 0 0 1 173 173 194.63 194.63 0 0 1-194.63 194.63"
                className="page-12_svg__cls-5"
              />
            </clipPath>
          </defs>
          <path
            d="M-.381-.446h210.195v297.274H-.381z"
            className="page-12_svg__cls-7"
            style={{ fill: palette.backgroundLight, strokeWidth: 0.353103 }}
          />
          <path
            d="M232.52 3101.78h573.7V3461H268.59a36.07 36.07 0 0 1-36.07-36.07z"
            className="page-12_svg__cls-74"
            style={{
              fill: palette.backgroundLight,
              filter: "url(#page-12_svg__a)",
            }}
            transform="matrix(.3531 0 0 .3531 -75.105 -1082.933)"
          />
          <text
            x={47.188}
            y={44.366}
            className="page-12_svg__cls-75"
            style={{
              fontSize: "14.0112px",
              fontFamily: "Heavitas",
              fill: palette.primary,
              strokeWidth: 0.353103,
            }}
          >
            {"apoiadores"}
          </text>

          <path
            d="M269.35 3192.55c0-1.87-.07-3.75 0-5.62a11.59 11.59 0 0 1 4.89-9.06 1.4 1.4 0 0 1 .19-.08 9.36 9.36 0 0 0 13.54 0 11.64 11.64 0 0 1 3.95 4.69 1.22 1.22 0 0 0 .8.75 17.3 17.3 0 0 0 10.81 0 1.21 1.21 0 0 0 .78-.72 11.44 11.44 0 0 1 3.5-4.39c.14-.11.3-.21.44-.31a9.43 9.43 0 0 0 13.6 0c.26.19.53.38.78.59a11.74 11.74 0 0 1 4.17 7.53 1 1 0 0 0 .1.25v6.41h-23.71v-5.7a20.76 20.76 0 0 1-10.12 0v5.69z"
            className="page-12_svg__cls-25"
            style={{ fill: palette.primary }}
            transform="matrix(.3531 0 0 .3531 -75.105 -1082.933)"
          />
          <path
            d="M293.07 3192.55v-5.69a20.76 20.76 0 0 0 10.12 0v5.7zM291.3 3147.28a9.45 9.45 0 0 0 13.63 0 1 1 0 0 1 .18.11 21.4 21.4 0 0 0 3.72 2.46 20 20 0 0 1 9.43 14.58.6.6 0 0 1 0 .22 3.88 3.88 0 0 0 1.3 3 5.69 5.69 0 0 1 .52 6.84 5.94 5.94 0 1 1-5.54-9h.37a16.92 16.92 0 0 0-5-10.42v7h-23.58v-7a16.9 16.9 0 0 0-5 10.4 6.4 6.4 0 0 1 3.61 1.33 5.94 5.94 0 0 1 2 6.31 5.94 5.94 0 0 1-11.18.55 6 6 0 0 1 1.76-6.86 1 1 0 0 0 .34-.68 20.47 20.47 0 0 1 5.47-12.8 19.8 19.8 0 0 1 6.12-4.48 3 3 0 0 0 .59-.42z"
            className="page-12_svg__cls-25"
            style={{ fill: palette.primary }}
            transform="matrix(.3531 0 0 .3531 -75.105 -1082.933)"
          />
          <path
            d="M298.14 3135a5.94 5.94 0 1 1-6 5.9 5.93 5.93 0 0 1 6-5.9"
            className="page-12_svg__cls-25"
            style={{ fill: palette.primary }}
            transform="matrix(.3531 0 0 .3531 -75.105 -1082.933)"
          />

          <ClippedImage
            id="emerald-page-12-1"
            triggerImageEdit={triggerImageEdit}
            x={150}
            y={37}
            width={62}
            height={62}
            imageNumber={16}
            imageRatio={1}
            notebook={notebook}
          >
            <path
              d="M732.69 3172.65h77.36V3250a93.24 93.24 0 0 1-93.24 93.24 77.36 77.36 0 0 1-77.36-77.36 93.24 93.24 0 0 1 93.24-93.23"
              transform="matrix(.3531 0 0 .3531 -75.105 -1082.933) "
              style={{ fill: palette.backgroundLight }}
            />
          </ClippedImage>

          <ClippedImage
            id="emerald-page-12-2"
            triggerImageEdit={triggerImageEdit}
            x={150}
            y={80}
            width={60}
            height={64}
            imageNumber={17}
            imageRatio={1}
            notebook={notebook}
          >
            <path
              d="M714.65 3468.77h-77.36v-77.36a93.24 93.24 0 0 1 93.24-93.24 77.36 77.36 0 0 1 77.36 77.36 93.24 93.24 0 0 1-93.24 93.24"
              transform="matrix(.3531 0 0 .3531 -75.105 -1082.933)"
              style={{ fill: palette.backgroundLight }}
            />
          </ClippedImage>

          <g
            className="page-12_svg__cls-9"
            data-name="CZJK68"
            style={{ opacity: 0.13 }}
          >
            <path d="M7.61 296.789a72.7 72.7 0 0 0 5.412-15.646 79.5 79.5 0 0 0 1.512-25.833c-.745-7.101-1.935-14.195-3.217-21.282-1.158-6.39-2.744-12.764-4.085-19.145-1.236-5.861-2.451-11.72-3.57-17.584-.502-2.638-.756-5.297-1.031-7.924 1.108 4.943 2.118 9.919 3.354 14.87 1.06 4.236 2.338 8.445 3.531 12.668 1.38 4.916 2.825 9.83 4.124 14.753 1.17 4.453 2.313 8.905 3.178 13.369.805 4.092 1.526 8.192 1.766 12.295a108.5 108.5 0 0 1 0 15.035c-.65 8.248-3.09 16.408-7.62 24.424z" />
            <path d="M15.642 296.789a53.2 53.2 0 0 0 10.064-16.077c2.948-7.415 3.53-14.894 2.867-22.394a66 66 0 0 0-4.149-17.842c-3.986-10.381-8.386-20.727-12.358-31.108-2.225-5.816-3.934-11.674-5.848-17.518a9.3 9.3 0 0 1-.462-2.906c.603 1.925 1.084 3.86 1.832 5.77 2.027 5.173 3.987 10.353 6.3 15.501 3.884 8.63 8.28 17.214 11.927 25.861 2.377 5.622 4.03 11.3 5.6 16.999 1.667 6.027 1.85 12.097.947 18.16-1.275 8.552-5.279 16.832-12.525 24.78a4.4 4.4 0 0 0-.487.777z" />
            <path d="M24.036 296.789c1.666-1.55 3.393-3.09 4.993-4.654 6.002-5.883 9.932-12.037 11.818-18.46 2.214-7.536 1.614-15.042-.632-22.55-2.472-8.3-6.939-16.408-11.624-24.498-5.1-8.806-10.286-17.609-15.039-26.458-2.119-3.951-3.277-8.012-4.855-12.023a.94.94 0 0 1 .07-.657c3.835 11.752 11.653 23.05 19.068 34.393 5.162 7.87 10.24 15.748 13.796 23.81 2.955 6.708 4.788 13.502 4.491 20.37-.466 10.713-6.327 20.776-18.142 30.12a6.5 6.5 0 0 0-.6.607z" />
            <path d="M32.083 296.789c2.613-1.921 5.378-3.803 7.81-5.766q12.384-10.01 14.728-21.43c1.441-6.95.05-13.806-2.906-20.621-4.152-9.58-11.182-18.782-18.213-27.987-6.31-8.256-12.793-16.49-17.302-24.993-1.444-2.719-2.563-5.473-3.806-8.217a1.2 1.2 0 0 1 0-.582c1.472 2.87 2.825 5.826 4.516 8.746 4.206 7.295 9.922 14.375 15.688 21.444 7.13 8.736 14.517 17.433 19.774 26.455 3.94 6.776 6.575 13.636 6.607 20.677.053 10.908-6.737 20.872-20.092 29.961q-1.705 1.158-3.418 2.313z" />
            <path d="M40.127 296.789c3.428-2.119 7.062-4.199 10.24-6.398 8.947-6.183 14.494-12.949 16.479-20.276 1.349-4.978.922-9.95-.731-14.908-3.058-9.18-9.887-17.891-17.4-26.482-7.455-8.524-15.124-17.006-22.395-25.561a59.9 59.9 0 0 1-10.19-16.596 22 22 0 0 1-1.084-3.676c2.867 8.697 9.067 16.949 16.461 25.042 6.79 7.415 13.987 14.784 20.833 22.2 6.286 6.797 11.868 13.707 15.41 20.917 2.842 5.78 4.237 11.614 3.15 17.535-1.53 8.295-7.79 15.84-18.196 22.698-2.885 1.903-6.15 3.686-9.24 5.526z" />
            <path d="M48.174 296.789c6.084-2.98 11.748-6.098 16.504-9.534 10.773-7.81 15.748-16.274 14.742-25.462-.76-6.925-4.534-13.542-9.633-20.035-6.066-7.733-13.898-15.127-21.603-22.542-7.56-7.281-15.14-14.562-20.953-22.175-3.71-4.862-6.627-9.81-8.121-14.912-.162-.547-.268-1.094-.378-1.642a1.2 1.2 0 0 1 .06-.6c2.542 10.523 11.151 20.18 20.992 29.66 7.733 7.44 15.872 14.8 23.584 22.246 7.32 7.062 13.59 14.315 17.136 21.98 4.35 9.407 3.531 18.616-5.166 27.543-5.533 5.667-13.478 10.593-22.895 15.077l-.918.438z" />
            <path d="M56.553 296.789c1.352-.653 2.68-1.317 4.057-1.96 11.352-5.296 20.628-11.18 25.883-18.22 4.367-5.85 5.413-11.84 3.757-17.945-2.296-8.45-8.76-16.327-16.858-23.94-7.277-6.843-15.08-13.573-22.7-20.342-7.62-6.77-15.025-13.58-20.424-20.791-3.256-4.35-5.67-8.785-6.765-13.354a4.2 4.2 0 0 1 .024-1.328c1.836 8.534 7.98 16.508 16.292 24.127 8.531 7.822 17.72 15.502 26.677 23.231 7.497 6.462 14.757 12.973 20.127 19.87 4.77 6.108 7.952 12.358 7.75 18.876-.24 7.694-5.338 14.714-14.685 21.144-5.462 3.757-11.942 7.122-19.127 10.24a4.5 4.5 0 0 0-.668.406z" />
            <path d="M64.6 296.789c1.18-.523 2.334-1.06 3.531-1.564 11.483-4.863 21.218-10.293 27.412-16.879 5.243-5.579 7.115-11.398 6.07-17.418-1.318-7.574-6.805-14.615-13.67-21.476-7.121-7.115-15.733-13.88-24.2-20.678-8.362-6.709-16.72-13.418-23.454-20.5-4.848-5.1-8.796-10.326-10.755-15.823a20.5 20.5 0 0 1-.979-9.944 2.4 2.4 0 0 1 .124.558c-.784 7.556 3.178 14.696 9.322 21.649 6.225 7.062 14.4 13.658 22.662 20.25 8.969 7.161 18.047 14.294 26.631 21.54 6.79 5.734 12.108 11.765 15.325 18.131 4.053 8.02 3.59 15.89-3.492 23.591-5.064 5.498-12.712 10.219-21.872 14.477-3.022 1.413-6.2 2.73-9.31 4.093z" />
            <path d="M72.644 296.789c3.312-1.374 6.709-2.716 9.919-4.131 11.334-4.986 20.56-10.594 25.73-17.437 4.18-5.522 4.944-11.172 3.062-16.91-2.44-7.5-8.8-14.396-16.324-21.105-9.043-8.065-19.774-15.688-29.898-23.478-8.852-6.818-17.386-13.707-23.506-21.136-4.851-5.89-7.99-11.928-7.503-18.263a11.5 11.5 0 0 1 .943-3.714c-.12.766-.226 1.532-.353 2.298-.77 4.343.522 8.598 2.888 12.793 3.425 6.08 8.828 11.83 15.653 17.267 9.18 7.306 18.82 14.495 28.368 21.705 8.298 6.268 16.525 12.546 23.012 19.248 4.838 5.003 8.609 10.148 10.17 15.575 2.263 7.881-.841 15.307-9.62 22.228-6.592 5.201-15.394 9.57-25.324 13.513-1.292.512-2.57 1.031-3.856 1.547zM.233 200.201a7.5 7.5 0 0 1 .286 1.186c.607 8.122 1.148 16.243 1.804 24.365 1.14 14.057 2.225 28.114 1.942 42.181-.176 8.898-.907 17.793-1.412 26.688-.042.727-.184 1.451-.279 2.178H.226z" />
            <path d="M80.691 296.789c4.043-1.61 8.227-3.153 12.104-4.841 13.111-5.713 23.397-12.221 27.595-20.374 2.98-5.788 2.218-11.54-.925-17.256-3.69-6.71-9.918-12.98-17.584-18.934-9.153-7.108-18.8-14.092-28.287-21.115-8.655-6.406-17.14-12.846-23.612-19.774-4.043-4.33-7.221-8.775-8.708-13.436-1.377-4.322-1.271-8.63 1.2-12.884-.229.61-.465 1.221-.68 1.832-2.367 6.65 0 13.065 4.773 19.357 5.099 6.71 12.433 12.963 20.65 18.994 9.865 7.238 20.105 14.385 29.992 21.62 8.711 6.356 16.663 12.913 22.147 20.004 3.421 4.42 5.65 8.947 5.91 13.647.396 7.359-4.441 14.011-13.516 20.084-7.22 4.834-16.342 8.867-26.334 12.532-.474.173-.918.353-1.377.544z" />
            <path d="M88.735 296.789c3.337-1.25 6.79-2.444 10-3.757 10.462-4.287 19.671-9.025 25.804-14.83q8.39-7.952 6.89-16.716c-1.183-6.957-6.388-13.369-13.065-19.58-7.627-7.1-17.07-13.729-26.515-20.353-10.413-7.298-21.059-14.534-29.777-22.284-6.282-5.586-11.43-11.356-13.771-17.567-2.097-5.6-1.44-11.12 2.585-16.529a1.15 1.15 0 0 1 .593-.374c-.664 1.095-1.413 2.182-1.97 3.29-2.97 5.898-2.027 11.738 1.483 17.529 3.46 5.71 8.972 11.034 15.3 16.2 9.396 7.67 20.405 14.887 30.998 22.221 9.778 6.769 19.372 13.587 26.727 20.978 4.565 4.59 8.121 9.311 9.714 14.311 2.694 8.369-1.187 16.077-11.3 23.17-5.914 4.15-13.23 7.748-21.539 10.947-2.917 1.126-5.883 2.224-8.827 3.337z" />
            <path d="M60.963 162.461c-1.25 1.706-2.712 3.387-3.704 5.12-3.326 5.816-2.532 11.614.802 17.377 3.418 5.907 9.149 11.377 15.716 16.687 7.528 6.088 16.098 11.882 24.718 17.655 10.162 6.833 20.402 13.644 28.954 20.929 6.38 5.445 11.695 11.066 14.378 17.143 4.425 10.028-.519 19.068-14.018 27.231-6.666 4.03-14.569 7.518-23.326 10.625-1.38.49-2.662 1.038-3.99 1.557h-3.711c6.423-2.556 13.153-4.978 19.202-7.7 9.933-4.475 17.492-9.68 21.61-15.785 4.311-6.398 3.697-12.803-.067-19.198-3.648-6.193-9.58-11.963-16.748-17.464-6.448-4.944-13.33-9.785-20.127-14.636-6.946-4.944-14.18-9.834-21.013-14.83-8.513-6.215-16.07-12.652-20.88-19.665-3.046-4.439-4.681-8.969-4.19-13.623.413-3.884 2.411-7.61 5.854-11.179a3 3 0 0 1 .445-.304z" />
            <path d="M105.165 296.789c1.995-.749 3.98-1.504 6.002-2.246 10.074-3.711 19.128-7.842 26.13-12.81 7.87-5.583 12.006-11.653 11.702-18.284-.24-5.297-3.129-10.35-7.415-15.258-5.47-6.278-12.765-12.136-20.795-17.807-10.946-7.736-22.245-15.384-33.117-23.146-7.97-5.688-14.933-11.624-19.84-18.008-3.73-4.844-5.968-9.806-5.513-14.957.434-4.944 3.326-9.619 8.577-13.983.159-.13.353-.254.734-.551-.088.272-.067.353-.144.43-9.478 7.999-10.562 16.364-5.403 24.997 3.697 6.193 9.852 11.907 16.886 17.447 9.1 7.168 19.565 13.947 29.727 20.812 9.488 6.405 18.807 12.86 26.324 19.795 5.367 4.943 9.665 10.063 11.624 15.536 2.857 7.973-.226 15.448-9.488 22.39-7.768 5.834-18.393 10.495-30.144 14.686-.862.304-1.67.643-2.503.964z" />
            <path d="m113.208 296.789 6.268-2.341c11.963-4.456 22.468-9.467 29.59-15.752 8.104-7.15 9.636-14.661 5.907-22.436-3.27-6.819-9.4-13.153-17.302-19.103-8.894-6.688-18.259-13.249-27.609-19.81-9.534-6.708-19.24-13.329-26.913-20.507-5.134-4.8-9.298-9.75-11.331-15.025-3.178-8.273-.166-16.038 9.533-23.248a1.3 1.3 0 0 1 .745-.247c-.254.211-.494.43-.762.639-5.364 4.177-8.704 8.654-9.644 13.467-.956 4.908.657 9.693 3.81 14.385 4.467 6.639 11.36 12.79 19.488 18.644 9.506 6.843 19.439 13.57 29.237 20.328 8.704 6.003 17.143 12.084 23.891 18.595 5.388 5.197 9.534 10.593 10.925 16.313 1.797 7.507-1.818 14.477-10.618 20.914-7.98 5.834-18.739 10.498-30.638 14.686-.428.148-.806.332-1.208.498z" />
            <path d="M121.256 296.789c2.888-1.095 5.812-2.172 8.658-3.291 11.193-4.393 20.882-9.33 27.34-15.417 6.635-6.257 8.383-12.814 6.035-19.629-2.31-6.709-8.033-12.884-14.922-18.866-8.7-7.556-19.191-14.625-29.42-21.765-8.475-5.922-16.932-11.85-24.075-18.128-6.183-5.431-11.335-11.031-13.934-17.062-4.089-9.488-.053-18.2 12.023-26.18.534-.353 1.06-.706 1.625-1.059a.96.96 0 0 1 .54-.106c-.233.173-.449.353-.706.52-6.808 4.4-11.497 9.198-13.302 14.515-1.518 4.492-.83 8.941 1.395 13.358 3.355 6.66 9.686 12.772 16.98 18.715 8.638 7.023 18.62 13.675 28.337 20.399 9.57 6.62 19.015 13.273 26.564 20.42 5.25 4.968 9.453 10.102 11.356 15.56q4.439 12.74-10.946 23.659c-7.708 5.441-17.804 9.887-28.955 13.87-.43.155-.822.331-1.232.497z" />
            <path d="M129.3 296.789c3.075-1.197 6.2-2.366 9.208-3.591 9.492-3.867 17.786-8.171 23.82-13.287q10.24-8.683 8.64-18.485c-1.058-6.54-5.712-12.617-11.697-18.524-6.487-6.398-14.605-12.39-22.761-18.362-9.319-6.836-18.771-13.64-27.712-20.575-7.726-6.003-13.94-12.306-17.556-19.067-5.675-10.625-1.928-20.48 12.207-29.442a2.7 2.7 0 0 1 1.101-.42l-1.854 1.281c-6.754 4.612-11.136 9.63-12.524 15.13-1.317 5.227.184 10.343 3.407 15.375 4.096 6.391 10.332 12.38 17.832 18.079 8.934 6.79 18.312 13.464 27.542 20.176 8.446 6.137 16.737 12.313 23.347 18.905 4.428 4.421 7.93 8.973 9.855 13.75 4.04 10.028-.875 19.128-14.124 27.362-6.889 4.276-15.211 7.927-24.258 11.271-.385.141-.741.297-1.112.449z" />
            <path d="M137.346 296.789c3.983-1.642 8.09-3.228 11.921-4.944 9.887-4.406 18.008-9.364 23.1-15.232 4.725-5.431 6.127-11.042 4.682-16.822-1.684-6.755-6.804-13.03-13.146-19.131-7.09-6.822-15.72-13.263-24.328-19.707-9.125-6.83-18.415-13.612-26.13-20.798-6.003-5.586-10.992-11.327-13.478-17.46q-6.133-15.145 13.453-27.776a1.8 1.8 0 0 1 .837-.25c-.226.183-.427.353-.681.54-7.006 4.689-11.653 9.795-13.284 15.406-1.497 5.084-.417 10.098 2.373 15.049 4.007 7.118 10.748 13.743 18.774 20.091 8.535 6.752 17.507 13.39 26.306 20.074 8.549 6.494 16.84 13.044 23.097 20.064 4.392 4.925 7.648 9.989 8.658 15.292 1.377 7.236-1.812 14.047-9.7 20.403-7.14 5.755-16.949 10.48-27.983 14.745-.382.145-.745.3-1.116.452z" />
            <path d="M145.39 296.789c5.367-2.444 11.02-4.77 16.035-7.352 9.283-4.777 16.08-10.204 19.597-16.394 3.347-5.894 3.114-11.805.427-17.723-3.178-6.998-9.046-13.57-16.398-19.851-8.535-7.292-17.595-14.456-26.483-21.663-7.528-6.116-14.858-12.274-20.533-18.8-4.336-4.985-7.602-10.098-8.781-15.44-1.861-8.475 2.14-16.324 12.171-23.552 1.593-1.144 3.344-2.246 5.032-3.365a.96.96 0 0 1 .536-.067c-.275.197-.526.402-.826.59-8.312 5.257-13.77 11.034-15.536 17.457-1.356 4.943-.442 9.851 2.065 14.7 3.708 7.17 10.088 13.89 17.772 20.349 8.298 6.977 17.065 13.845 25.632 20.759 7.97 6.433 15.621 12.937 21.341 19.858 4.59 5.547 7.769 11.236 8.122 17.182.522 9.05-6.003 17.059-18.026 24.24-5.555 3.323-11.946 6.293-18.792 9.072z" />
            <path d="M153.437 296.789c6.96-2.984 13.418-6.158 18.923-9.721 7.878-5.12 13.305-10.692 15.48-16.86 1.494-4.238 1.349-8.475-.05-12.712-2.768-8.418-9.565-16.243-17.873-23.81s-17.062-14.919-25.54-22.39c-7.648-6.741-14.75-13.588-19.541-20.872-3.301-5.025-5.346-10.134-5.19-15.389.25-8.513 6.45-16.136 17.4-23.033a1.26 1.26 0 0 1 .728-.14c-.449.31-.887.624-1.342.931-6.494 4.379-11.183 9.103-13.62 14.266-2.347 4.964-2.4 9.947-.73 14.947 2.708 8.103 9.085 15.684 17.062 22.983 8.598 7.867 17.775 15.607 26.648 23.414 6.999 6.158 13.531 12.412 18.34 18.997 4.142 5.671 6.823 11.462 6.71 17.461-.163 9.534-7.631 17.913-20.728 25.318-3.704 2.094-7.93 4.004-11.91 6.002-.395.195-.727.417-1.09.625z" />
            <path d="M161.474 296.789c8.863-4.11 16.564-8.605 22.298-13.736 8.03-7.186 11.067-14.798 9.647-22.796-1.342-7.564-6.19-14.721-12.32-21.734-7.217-8.259-16.14-16.165-24.537-24.18-7.524-7.182-14.738-14.42-19.731-22.059-3.807-5.819-6.176-11.74-5.844-17.831.48-8.771 6.758-16.674 17.85-23.849.116-.074.12-.183.176-.275l.441.166c-.296.226-.565.459-.886.674-7.81 5.321-12.998 11.109-15.159 17.412-1.687 4.922-1.352 9.837.403 14.745 3.29 9.206 10.85 17.783 19.42 26.197 7.575 7.443 15.537 14.813 23.076 22.245 6.571 6.473 12.235 13.104 15.84 20.057 2.825 5.445 4.209 10.946 3.121 16.546-1.49 7.68-7.323 14.675-17.129 20.974-3.764 2.416-8.146 4.637-12.242 6.95-.268.151-.487.32-.727.48z" />
            <path d="M169.864 296.789c1.952-1.148 3.923-2.288 5.85-3.443 9.7-5.805 16.713-12.175 20.127-19.3 2.472-5.191 2.857-10.431 1.67-15.696-1.867-8.287-7.1-16.193-13.834-23.898-6.66-7.616-13.796-15.148-20.688-22.722-6.356-6.974-12.218-14.025-16.08-21.377-2.582-4.919-4.17-9.887-4.125-14.95.089-9.577 6.218-18.337 17.987-26.314a1.5 1.5 0 0 1 .707-.257 5 5 0 0 1-.488.455c-7.16 5.092-12.157 10.576-14.77 16.48a23.16 23.16 0 0 0-1.628 13.318c1.59 9.98 8.323 19.375 16.444 28.602 7.362 8.354 15.184 16.624 22.599 24.968 5.798 6.511 10.625 13.17 13.59 20.074 3.885 9.014 3.927 17.97-2.517 26.793-4.488 6.144-11.631 11.631-20.593 16.663-.335.19-.597.41-.893.614z" />
            <path d="M177.91 296.789c.742-.523 1.452-1.06 2.236-1.564 10.11-6.586 17.055-13.771 20.106-21.66a27.04 27.04 0 0 0 1.282-15.13c-1.858-9.696-7.769-18.969-14.739-28.121-5.784-7.6-11.868-15.148-17.655-22.747-5.328-6.988-9.693-14.096-12.182-21.412-4.333-12.737-1.275-24.958 12.659-36.416a11.4 11.4 0 0 1 2.245-1.508 4 4 0 0 1-.427.466q-12.005 9.852-14.237 21.038c-1.225 6.123-.272 12.207 2.119 18.256 3.95 10.067 11.299 19.692 18.834 29.307 6.098 7.79 12.39 15.537 17.302 23.51 4.534 7.355 7.81 14.805 8.203 22.46q.921 17.938-21.48 32.822c-.353.226-.6.473-.896.706z" />
            <path d="M185.955 296.789c1.765-1.53 3.605-3.044 5.296-4.59 7.564-6.932 12.006-14.248 13.542-21.939 1.3-6.578.459-13.11-1.65-19.629-2.824-8.789-8.008-17.327-13.251-25.865-5.004-8.146-10.053-16.288-14.728-24.473q-5.487-9.612-6.861-19.555c-1.596-11.366 2.101-22.313 13.026-32.68a2.4 2.4 0 0 1 .953-.565c-1.147 1.29-2.348 2.571-3.442 3.885-5.403 6.422-8.242 13.064-9.036 19.925-.993 8.598 1.458 17.04 5.272 25.424 4.18 9.18 10.098 18.191 15.691 27.224 4.774 7.712 9.35 15.444 12.468 23.336 2.825 7.175 4.322 14.4 3.436 21.691-1.169 9.637-6.673 18.747-16.829 27.274a6 6 0 0 0-.53.544z" />
            <path d="M194.002 296.789c2.118-2.61 4.41-5.187 6.26-7.832a43.4 43.4 0 0 0 7.557-18.714c1.09-7.306.215-14.573-1.681-21.83-2.186-8.36-5.904-16.595-9.534-24.847-3.7-8.393-7.553-16.773-10.83-25.201-3.177-8.178-4.855-16.437-4.442-24.756.449-9.124 3.648-18.033 10.622-26.635a1.55 1.55 0 0 1 .727-.515c-.868 1.3-1.765 2.592-2.588 3.884a47 47 0 0 0-6.543 17.443 47.5 47.5 0 0 0 .872 19.446 161 161 0 0 0 6.194 19.162c3.824 9.679 8.474 19.294 12.252 28.976 2.366 6.06 3.962 12.182 5.558 18.298 1.476 5.65 1.635 11.352.925 17.044-1.101 8.852-4.802 17.468-11.818 25.777a2 2 0 0 0-.177.286z" />
            <path d="M210.428 254.36c-.19-1.06-.385-2.087-.568-3.129-.83-4.696-1.505-9.396-2.529-14.081-1.115-5.106-2.556-10.201-3.855-15.3-1.49-5.844-3.14-11.684-4.435-17.535-1.265-5.71-2.37-11.427-3.178-17.147-.904-6.306-1.219-12.63-.233-18.937a136 136 0 0 1 3.135-14.346c.742-2.769 2.14-5.505 3.242-8.256l.438.032a33 33 0 0 0-1.152 3.15c-1.235 4.848-2.673 9.689-3.53 14.55-1.247 7.003-1.088 14.03-.308 21.042.6 5.403 1.519 10.798 2.543 16.19.981 5.166 2.238 10.318 3.453 15.473s2.616 10.399 3.813 15.607c.943 4.107 1.692 8.227 2.543 12.341.173.844.423 1.684.639 2.525zM202.046 296.789c1.603-3.531 3.562-7.034 4.713-10.593 1.459-4.506 2.257-9.05 3.348-13.58.088-.354.212-.739.321-1.106v10.53a1.6 1.6 0 0 0-.272.353q-2.383 7.196-4.756 14.396zM210.428 205.314a19 19 0 0 1-.272-2.094c-.215-11.049-.49-22.094-.575-33.142-.032-4.283.353-8.566.59-12.846a3.4 3.4 0 0 1 .257-.812zM23.135 177.857v-4.043h.096v4.043zM60.86 162.416l2.66-2.628.381.082-2.938 2.584z" />
          </g>
          <path
            d="M636.91 3544.59h169.3v169.3A190.19 190.19 0 0 1 616 3904.08a169.3 169.3 0 0 1-169.3-169.3 190.19 190.19 0 0 1 190.19-190.19z"
            className="page-12_svg__cls-159"
            style={{ fill: palette.secondary, filter: "url(#page-12_svg__f)" }}
            transform="matrix(0 .3531 -.3531 0 1461.175 10.934)"
          />

          <ClippedImage
            id="emerald-page-12-3"
            triggerImageEdit={triggerImageEdit}
            x={55}
            y={155}
            width={131}
            height={131}
            imageNumber={18}
            imageRatio={1}
            notebook={notebook}
          >
            <path
              d="M540.73 3874.39h-173v-173a194.63 194.63 0 0 1 194.63-194.63 173 173 0 0 1 173 173 194.63 194.63 0 0 1-194.63 194.63"
              transform="matrix(.3531 0 0 .3531 -75.105 -1082.933)"
              style={{ fill: palette.backgroundLight }}
            />
          </ClippedImage>

          <foreignObject
            x={19.005}
            y={52.032}
            width={123}
            height={78}
            className="clickable"
          >
            <div
              style={{
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                fontWeight: "400",
                color: palette.backgroundGray,
                textAlign: "start",
                width: "100%",
                height: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.supporters,
                  field: "supporters",
                  label: "Apoiadores",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.supporters}
            </div>
          </foreignObject>

          <path
            d="M76.376-2.236s-25.123-.537-48.255 9.784c-21.995 9.813-34.64 19.145-41.016 20.89-8.037 2.2-6.614-.152-6.614-.152V-2.787Z"
            className="page-12_svg__cls-8"
            style={{ fill: palette.secondary, strokeWidth: 0.353103 }}
          />
        </svg>
      </PaperInside>
    </Paper>
  );
};

export default Page12;
