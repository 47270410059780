import HttpClient from "../../app/client";
import { PartiesFilterConsts } from "../../app/constants";
import handleError from "../../app/errors";
import { ActiveFilter, getValue } from "../../app/filter";
import Agir from "../../resources/images/parties/agir.png";
import Avante from "../../resources/images/parties/avante.png";
import Cidadania from "../../resources/images/parties/cidadania.png";
import Dc from "../../resources/images/parties/dc.png";
import Dem from "../../resources/images/parties/dem.png";
import Mdb from "../../resources/images/parties/mdb.png";
import Novo from "../../resources/images/parties/novo.png";
import Patriotas from "../../resources/images/parties/patri.png";
import Pcb from "../../resources/images/parties/pcb.png";
import PcbDoB from "../../resources/images/parties/pcdob.png";
import Pco from "../../resources/images/parties/pco.png";
import Pdt from "../../resources/images/parties/pdt.png";
import Pen from "../../resources/images/parties/pen.png";
import Phs from "../../resources/images/parties/phs.png";
import Pl from "../../resources/images/parties/pl.png";
import Pmb from "../../resources/images/parties/pmb.png";
import Pmn from "../../resources/images/parties/pmn.png";
import Pode from "../../resources/images/parties/pode.png";
import Pp from "../../resources/images/parties/pp.png";
import Ppl from "../../resources/images/parties/ppl.png";
import Pr from "../../resources/images/parties/pr.png";
import Prb from "../../resources/images/parties/prb.png";
import Pros from "../../resources/images/parties/pros.png";
import Prp from "../../resources/images/parties/prp.png";
import Prtb from "../../resources/images/parties/prtb.png";
import Psb from "../../resources/images/parties/psb.png";
import Psc from "../../resources/images/parties/psc.png";
import Psd from "../../resources/images/parties/psd.png";
import Psdb from "../../resources/images/parties/psdb.png";
import Psdc from "../../resources/images/parties/psdc.png";
import Psl from "../../resources/images/parties/psl.png";
import Psol from "../../resources/images/parties/psol.png";
import Pstu from "../../resources/images/parties/pstu.png";
import Pt from "../../resources/images/parties/pt.png";
import Ptb from "../../resources/images/parties/ptb.png";
import Ptc from "../../resources/images/parties/ptc.png";
import Ptdob from "../../resources/images/parties/ptdob.png";
import Ptn from "../../resources/images/parties/ptn.png";
import Pv from "../../resources/images/parties/pv.png";
import Rede from "../../resources/images/parties/rede.png";
import Solidariedade from "../../resources/images/parties/solidariedade.png";
import Uniao from "../../resources/images/parties/uniao.png";
import Up from "../../resources/images/parties/up.png";
import { IPartyService, Party, PartyProfileData } from "./type";

const PartiesLogos = {
  AGIR: Agir,
  PP: Pp,
  DC: Dc,
  PROS: Pros,
  PSTU: Pstu,
  PHS: Phs,
  REDE: Rede,
  SOLIDARIEDADE: Solidariedade,
  "PC do B": PcbDoB,
  DEM: Dem,
  PSDB: Psdb,
  PEN: Pen,
  "PT do B": Ptdob,
  PTN: Ptn,
  "UNIÃO BRASIL": Uniao,
  PSL: Psl,
  PSC: Psc,
  PSDC: Psdc,
  PV: Pv,
  PMB: Pmb,
  PODEMOS: Pode,
  AVANTE: Avante,
  PSOL: Psol,
  MDB: Mdb,
  PMDB: Mdb,
  PATRIOTA: Patriotas,
  PTB: Ptb,
  PR: Pr,
  PPS: Cidadania,
  CIDADANIA: Cidadania,
  PSD: Psd,
  PCB: Pcb,
  "UNIDADE POPULAR": Up,
  PRP: Prp,
  PTC: Ptc,
  NOVO: Novo,
  PDT: Pdt,
  PSB: Psb,
  PMN: Pmn,
  PCO: Pco,
  PPL: Ppl,
  PRTB: Prtb,
  PT: Pt,
  PRB: Prb,
  PL: Pl,
};

export default class PartyService implements IPartyService {
  async getParties(): Promise<Party[]> {
    try {
      const client = new HttpClient("parties", true, []);
      return await client.get<Party[]>(`/parties/search`);
    } catch (error) {
      handleError("Error while getting parties", error);
    }
  }

  async getPartyProfileData(filter: ActiveFilter[]): Promise<PartyProfileData> {
    try {
      const partyProfileFilter = {
        party_id: getValue(filter, PartiesFilterConsts.PARTY_ID),
        state: getValue(filter, PartiesFilterConsts.STATE),
        years: getValue(filter, PartiesFilterConsts.YEARS),
        office: getValue(filter, PartiesFilterConsts.OFFICE),
      };

      const client = new HttpClient("parties", true, []);
      return await client.post<PartyProfileData>(
        `/parties/`,
        partyProfileFilter
      );
    } catch (error) {
      handleError("Error while getting parties profile data", error);
    }
  }

  getPartyLogo(party: string): string {
    return PartiesLogos[party];
  }
}
