import React from "react";
import { Pages } from "../../app/constants";
import { makeContainer } from "../../components/container/container";
import Page from "../../components/main-page/page";
import TitleText from "../../components/text/title-text/title-text";
import Analysis from "./components/analysis/analysis";
import Banner from "./components/banner/banner";
import Calendar from "./components/calendar/calendar";
import "./home.css";

const PageContainer = makeContainer("home-page-container");
const HomeLeftPane = makeContainer("home-left-pane");
const HomeRightPane = makeContainer("home-right-pane");

const Line: React.FC = () => {
  return <hr className="home-separator-line" />;
};

const Home: React.FC = () => {
  return (
    <Page title={<TitleText>{"Home"}</TitleText>} page={Pages.HOME}>
      <PageContainer>
        <HomeLeftPane>
          <TitleText>{"Fique por Dentro"}</TitleText>
          <Banner />
          <Line />
          <TitleText>{"Pesquisas Mais Acessadas"}</TitleText>
          <Analysis />
        </HomeLeftPane>
        <HomeRightPane>
          <Calendar />
        </HomeRightPane>
      </PageContainer>
    </Page>
  );
};

export default Home;
