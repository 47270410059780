import { AiFillPlusCircle } from "react-icons/ai";
import { FiChevronRight } from "react-icons/fi";

import { memo, MouseEventHandler } from "react";
import "./target-button.css";

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  expanded: boolean;
  disabled?: boolean;
};

const TargetButton: React.FC<Props> = (props) => {
  const { onClick, disabled, expanded } = props;

  return (
    <button
      className={
        expanded ? "target-button" : "target-button contracted-target-button"
      }
      onClick={onClick}
      disabled={disabled}
    >
      <div className={"target-button-content"}>
        <AiFillPlusCircle className="target-button-icon" />
        {expanded && <span>{"  Criar Meta de Votos  "} </span>}
        {expanded && <FiChevronRight className="target-button-icon" />}
      </div>
    </button>
  );
};

export default memo(TargetButton);
