import { memo, useEffect, useRef, useState } from "react";
import { useOutsideAlerter } from "../../app/hooks";
import SearchInput from "../inputs/search-input/search-input";
import ScrollContainer from "../scroll/scroll";
import PopUpMenuItemText from "../text/popup-menu-item-text/popup-menu-item-text";
import "./pop-menu.css";

type MenuEntry = {
  key: string;
  label: string;
  onClick?: (entry: MenuEntry) => void;
};

type MenuEntryProps = {
  entry: MenuEntry;
  onClose: () => void;
  onClick?: (entry: MenuEntry) => void;
};

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  entries?: MenuEntry[];
  className?: string;
  open: boolean;
  onClose: () => void;
  containerRef: React.MutableRefObject<any>;
  searchable?: boolean;
  fixedWidth?: number;
};

const PopupMenuEntry: React.FC<MenuEntryProps> = memo(
  ({ entry, onClick, onClose }) => {
    return (
      <div
        className="popup-menu-entry"
        onClick={() => {
          onClick(entry);
          onClose();
        }}
      >
        <PopUpMenuItemText>{entry.label}</PopUpMenuItemText>
      </div>
    );
  }
);

const PopupMenu: React.FC<Props> = ({
  children,
  entries,
  open,
  searchable,
  containerRef,
  fixedWidth,
  onClose,
}) => {
  const popupMenuRef = useRef(null);
  const popupMenuOptionsRef = useRef(null);
  const [search, setSearch] = useState("");
  useOutsideAlerter(popupMenuRef, onClose);

  useEffect(() => {
    const setUpMenuPosition = () => {
      if (!containerRef || !containerRef.current) {
        return;
      }

      const buttonRect = containerRef.current.getBoundingClientRect();
      const buttonWidth = containerRef.current.clientWidth;
      const popupWidth = popupMenuRef.current.clientWidth;
      const halfDifference = Math.abs(buttonWidth - popupWidth) / 2;

      const { height: btnHeight, x: btnX } = buttonRect;
      const { innerWidth: windowWidth } = window;
      const finalMenuPos = btnX - halfDifference + popupWidth;
      let newLeft = halfDifference;

      if (finalMenuPos > windowWidth) {
        const overflow = finalMenuPos - windowWidth + 5;
        newLeft = newLeft + overflow;
      }

      popupMenuRef.current.style.left = `-${newLeft}px`;
      popupMenuRef.current.style.top = `${btnHeight + 5}px`;
    };

    setUpMenuPosition();
  }, [open, containerRef, popupMenuRef]);

  useEffect(() => {
    const setMenuHeight = () => {
      if (!popupMenuOptionsRef || !popupMenuOptionsRef.current) {
        return;
      }

      const entriesCount = entries.length ?? 0;
      const entryHeightInPixels = window.innerWidth > 1386 ? 34 : 26;
      popupMenuOptionsRef.current.style.height = `${
        entriesCount * entryHeightInPixels
      }px`;
    };

    setMenuHeight();
  }, [entries]);

  return (
    <div className="popup">
      {children}
      <div
        ref={popupMenuRef}
        className={`${open ? "popup-content popup-show" : "popup-content"}`}
        style={{ width: fixedWidth }}
      >
        {searchable && (
          <div className="popup-menu-search-container">
            {" "}
            <SearchInput onChange={(e) => setSearch(e.target.value)} />{" "}
          </div>
        )}
        <div ref={popupMenuOptionsRef} className="popup-menu-options">
          <ScrollContainer hidden={!open}>
            {entries
              .filter((entry) => entry.label.includes(search) || !search)
              .map((entry, index) => (
                <PopupMenuEntry
                  key={`menu-entry-${index}`}
                  entry={entry}
                  onClick={entry.onClick}
                  onClose={onClose}
                />
              ))}
          </ScrollContainer>
        </div>
      </div>
    </div>
  );
};

export default memo(PopupMenu);
