import { memo } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { Status } from "../../../../../app/constants";
import { toPtBr } from "../../../../../app/utils";
import CustomResponsiveContainer from "../../../../../components/charts/CustomResponsiveContainer";
import Loading from "../../../../../components/loading/loading";
import NoData from "../../../../../components/no-data/no-data";
import { PartyProfileData } from "../../../../../features/parties/type";
import "./party-candidates-sex-chart.css";

type Props = {
  summary?: PartyProfileData;
  status?: Status;
};

const format = (summary: PartyProfileData) => {
  let candidates_by_sex = summary?.candidates_by_sex?.slice();
  if (!candidates_by_sex) return [];

  return [
    {
      name: "Mulheres",
      value: candidates_by_sex?.filter((c) => c.sex === "FEMININO")[0]
        ?.candidates,
    },
    {
      name: "Homens",
      value: candidates_by_sex?.filter((c) => c.sex === "MASCULINO")[0]
        ?.candidates,
    },
    {
      name: "Não Declarado",
      value:
        candidates_by_sex?.filter(
          (c) => c.sex !== "MASCULINO" && c.sex !== "FEMININO"
        )[0]?.candidates ?? 0,
    },
  ];
};

const COLORS = ["#2DA5C0", "#E76E6E", "#59DAB3"];

const CustomizedTooltip = ({ payload, label, active }) => {
  if (active) {
    return (
      <div className="barchart-custom-tooltip">
        <div>{`${toPtBr(payload[0]?.value)} candidatos`}</div>
      </div>
    );
  }

  return null;
};

const PartyCandidatesSexChart: React.FC<Props> = ({ status, summary }) => {
  const data = format(summary);

  const CustomLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
    index,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill={COLORS[index % COLORS.length]}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {data[index].name}
      </text>
    );
  };

  if (status === Status.LOADING) {
    return <Loading />;
  }

  if (!summary?.candidates_by_sex || !summary?.candidates_by_sex.length) {
    return <NoData />;
  }

  return (
    <CustomResponsiveContainer>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart
          width={500}
          height={300}
          margin={{
            top: 25,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <Tooltip content={CustomizedTooltip} />
          <Pie
            data={data}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={0}
            outerRadius={90}
            label={CustomLabel}
          >
            {data.map((entry, index) => (
              <Cell
                key={`pie-chart-cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </CustomResponsiveContainer>
  );
};

export default memo(PartyCandidatesSexChart);
