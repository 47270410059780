import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Pages, Status } from "../../../app/constants";
import { AppDispatch } from "../../../app/store";
import LoginCtaButton from "../../../components/buttons/login-cta-button/login-cta-button";
import LoginTextButton from "../../../components/buttons/login-text-button/login-text-button";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "../../../components/buttons/social-login-button/social-login-button";
import { makeContainer } from "../../../components/container/container";
import LoginInputText from "../../../components/inputs/login-input-text/login-input-text";
import LoginFormLabelText from "../../../components/text/login-form-label-text/login-form-label-text";
import LoginSubtitleText from "../../../components/text/login-subtitle-text/login-subtitle-text";
import LoginTitleText from "../../../components/text/login-title-text/login-title-text";
import AuthService from "../../../features/auth/service";
import {
  facebookLogin,
  googleLogin,
  login,
  selectAuthStatus,
  selectIsLogged,
} from "../../../features/auth/slice";
import { setIsAuthenticating } from "../../../features/shop/slice";
import ShopPageContainer from "../components/shop-page-container/shop-page-container";
import "./login.css";

const KeepConnectedAndForgotPassword = makeContainer(
  "shop-login-keep-and-forgot"
);
const SocialLogin = makeContainer("shop-login-social-login");
const Signup = makeContainer("shop-login-signup");

const ShopLogin: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const isLogged = useSelector(selectIsLogged);
  const [loginInput, setLoginInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const navigate = useNavigate();
  const loginStatus: Status = useSelector(selectAuthStatus);

  useEffect(() => {
    if (isLogged) {
      dispatch(
        setIsAuthenticating({
          isSigningIn: false,
          isSigningUp: false,
        })
      );
    }
  }, [dispatch, isLogged]);

  const loginUser = () => {
    if (loginStatus !== Status.LOADING) {
      const loginParams = {
        credentials: {
          username: loginInput,
          password: passwordInput,
          rememberMe: true,
        },
        service: new AuthService(),
      };

      dispatch(login(loginParams));
    }
  };

  const loginUserWithFacebook = () => {
    if (loginStatus !== Status.LOADING) {
      const loginParams = {
        rememberMe: true,
        service: new AuthService(),
      };

      dispatch(facebookLogin(loginParams));
    }
  };

  const loginUserWithGoogle = () => {
    if (loginStatus !== Status.LOADING) {
      const loginParams = {
        rememberMe: true,
        service: new AuthService(),
      };

      dispatch(googleLogin(loginParams));
    }
  };

  function handleClick(link: string) {
    return () => navigate(`/${link}`);
  }

  function goToSignin() {
    dispatch(
      setIsAuthenticating({
        isSigningIn: false,
        isSigningUp: true,
      })
    );
  }

  return (
    <ShopPageContainer>
      <LoginTitleText className="shop-login-title">
        Acesse a sua conta
      </LoginTitleText>
      <LoginSubtitleText className="shop-login-subtitle">
        Digite o e-mail e senha de cadastro
      </LoginSubtitleText>
      <LoginFormLabelText className="shop-login-label">
        E-mail
      </LoginFormLabelText>
      <LoginInputText
        className="shop-login-input"
        placeHolder="Seu email cadastrado"
        value={loginInput}
        onChange={(e) => setLoginInput(e.target.value)}
      />
      <LoginFormLabelText className="shop-login-label">
        Senha
      </LoginFormLabelText>
      <LoginInputText
        className="shop-login-input-password"
        type="password"
        placeHolder="Sua senha"
        value={passwordInput}
        onEnterPressed={loginUser}
        onChange={(e) => setPasswordInput(e.target.value)}
      />
      <KeepConnectedAndForgotPassword>
        <LoginTextButton onClick={handleClick(Pages.FORGOT)}>
          Esqueceu a senha?
        </LoginTextButton>
      </KeepConnectedAndForgotPassword>
      <LoginCtaButton onClick={loginUser} status={loginStatus}>
        Entrar
      </LoginCtaButton>
      <SocialLogin>
        <GoogleLoginButton onClick={loginUserWithGoogle}>
          Entrar com Google
        </GoogleLoginButton>
        <FacebookLoginButton onClick={loginUserWithFacebook}>
          Entrar com Facebook
        </FacebookLoginButton>
      </SocialLogin>
      <Signup>
        <span className="shop-login-signup-text">Não possui conta? </span>
        <LoginTextButton onClick={goToSignin}>Cadastre-se</LoginTextButton>
      </Signup>
    </ShopPageContainer>
  );
};

export default ShopLogin;
