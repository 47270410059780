import * as React from "react";
import { useSelector } from "react-redux";
import { NotebooksCausesOptions } from "../../../../../app/constants";
import { validateText } from "../../../../../app/utils";
import { selectPalette } from "../../../../../features/notebook/slice";
import { ClippedImage, Paper, PaperInside } from "../commons/commons";
import { TemplatePageProps } from "../commons/interfaces";

const Page8: React.FC<TemplatePageProps> = ({
  className,
  notebook,
  triggerImageEdit,
  triggerFieldEdit,
  updateField,
}) => {
  const { palette } = useSelector(selectPalette);
  return (
    <Paper className={className}>
      <PaperInside>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
        >
          <defs>
            <filter id="page-8_svg__a">
              <feDropShadow
                dx="6"
                dy="6"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <filter id="page-8_svg__c">
              <feDropShadow
                dx="6"
                dy="6"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <filter id="page-8_svg__e">
              <feDropShadow
                dx="6"
                dy="6"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <filter id="page-8_svg__f">
              <feDropShadow
                dx="6"
                dy="6"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <filter id="page-8_svg__g">
              <feDropShadow
                dx="6"
                dy="6"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <filter id="page-8_svg__h">
              <feDropShadow
                dx="6"
                dy="6"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
            <clipPath id="page-8_svg__b">
              <path
                d="M2237.4 1767.94h-180.82v-351.97h180.82z"
                className="page-8_svg__cls-5"
              />
            </clipPath>
            <clipPath id="page-8_svg__d">
              <path
                d="M2190.35 1709.9h-77.85v-77.85a93.83 93.83 0 0 1 93.83-93.83 77.85 77.85 0 0 1 77.85 77.85 93.83 93.83 0 0 1-93.83 93.83"
                className="page-8_svg__cls-5"
              />
            </clipPath>
            <clipPath id="page-8_svg__i">
              <path
                d="M2190.39 1927.3h-77.85v-77.85a93.83 93.83 0 0 1 93.83-93.83 77.85 77.85 0 0 1 77.85 77.85 93.83 93.83 0 0 1-93.83 93.83"
                className="page-8_svg__cls-5"
              />
            </clipPath>
            <clipPath id="page-8_svg__j">
              <path
                d="M2190.65 2141.15h-78.16v-78.2a94.2 94.2 0 0 1 94.2-94.2 78.16 78.16 0 0 1 78.17 78.2 94.2 94.2 0 0 1-94.2 94.2z"
                className="page-8_svg__cls-5"
              />
            </clipPath>
          </defs>
          <path
            d="M-.728-1.099H210.87v299.257H-.728z"
            className="page-8_svg__cls-7"
            style={{ fill: palette.backgroundLight, strokeWidth: 0.355458 }}
          />
          <g
            className="page-8_svg__cls-9"
            data-name="CZJK68"
            style={{ opacity: 0.13 }}
          >
            <path d="M178.264 154.599a16 16 0 0 1-1.497.608c2.787 2.687 5.648 4.745 8.641 5.687 5.165 1.617 10.039-.6 14.574-6.878 3.413-4.717 6.278-11.02 8.862-18.128q.506-1.386 1.013-2.759v-2.4c-.9 2.368-1.777 4.792-2.709 7.11-3.27 8.108-6.956 14.705-11.435 18.402-3.622 2.993-7.326 3.526-11.09 2.19a21.2 21.2 0 0 1-6.36-3.832" />
            <path d="M172.982 156.06q-.657.07-1.309.082a54 54 0 0 0 7.49 8.001c2.9 2.446 5.869 4.049 8.95 4.226 4.828.285 9.19-3.174 13.174-9.664 3.17-5.169 5.815-11.688 8.218-18.84.114-.355.238-.657.355-.984v-2.371c-1.066 2.893-2.068 5.886-3.174 8.659-3.746 9.377-8.012 16.735-13.362 19.735-3.796 2.132-7.567 1.585-11.317-.661-3.15-1.902-6.146-4.72-9.025-8.183" />
            <path d="M173.867 163.45c-1.998-2.339-3.935-4.916-5.84-7.628q-.647-.14-1.266-.32c2.95 4.411 5.968 8.552 9.146 12 3.007 3.263 6.107 5.798 9.388 6.95 5.484 1.926 10.543-.85 15.196-8.09 2.719-4.234 5.079-9.467 7.183-15.403.736-2.086 1.458-4.205 2.186-6.31v-2.399c-.817 2.389-1.6 4.86-2.463 7.152-2.808 7.486-5.918 14.073-9.725 18.46q-5.215 6-10.966 4.926c-4.56-.846-8.766-4.568-12.84-9.338" />
            <path d="M162.887 155.43q.216-.41.43-.831c2.346 3.594 4.702 7.166 7.109 10.568 3.604 5.125 7.39 9.373 11.453 11.979 4.194 2.69 8.396 3.131 12.59.046 4.006-2.943 7.418-8.35 10.351-15.452 1.777-4.33 3.377-9.139 5.051-13.735v2.638c-.355.949-.71 1.866-1.02 2.843-2.037 6.264-4.326 11.912-6.967 16.682-5.353 9.654-11.282 13.191-17.862 10.024-3.985-1.916-7.674-5.716-11.243-10.283-3.423-4.351-6.68-9.335-9.892-14.478" />
            <path d="M161.746 159.394q-.299-.486-.601-.974.209-.326.409-.657 2.374 3.728 4.756 7.418c3.721 5.74 7.56 10.959 11.68 14.872 3.217 3.057 6.533 5.123 10.006 5.293 4.347.217 8.332-2.74 11.99-8.367 3.26-5.012 5.968-11.492 8.403-18.694.487-1.422.98-2.844 1.471-4.287v2.417c-.21.594-.43 1.173-.632 1.778-2.748 8.406-5.805 15.995-9.63 21.562-4.553 6.626-9.455 8.83-14.684 6.785-3.583-1.4-6.938-4.475-10.184-8.314-4.55-5.385-8.78-12.05-12.984-18.832" />
            <path d="M159.225 161.175q.246-.325.48-.654c3.658 5.687 7.323 11.321 11.048 16.706 3.91 5.663 8.055 10.046 12.526 12.385 5.101 2.666 10.028 1.567 14.716-4.227 4.124-5.093 7.408-12.608 10.333-21.164q.765-2.246 1.532-4.482v2.4c-.106.287-.227.557-.327.863-2.744 8.531-5.804 16.205-9.629 21.914-4.22 6.295-8.794 8.887-13.717 7.593-3.764-.981-7.29-3.96-10.7-7.82-4.265-4.828-8.253-10.863-12.195-17.087q-2.037-3.192-4.067-6.427" />
            <path d="M157.267 163.777q.204-.266.409-.537.198.31.39.619c4.685 7.315 9.324 14.819 14.28 21.043 3.91 4.926 7.969 9.018 12.373 10.664 4.472 1.677 8.769.426 12.875-4.32 3.991-4.62 7.23-11.545 10.109-19.55.732-2.036 1.422-4.13 2.157-6.195v2.4c-.106.29-.224.572-.323.88-2.62 7.974-5.527 15.193-9.1 20.703q-7.144 11.019-15.505 7.841c-3.583-1.361-6.95-4.365-10.205-8.122-4.689-5.4-9.054-12.157-13.394-19-1.358-2.129-2.712-4.286-4.066-6.426" />
            <path d="M155.443 165.96c2.346 3.469 4.682 6.977 7.028 10.457 3.91 5.837 7.852 11.641 12.05 16.28 3.874 4.266 7.859 7.6 12.15 8.368 4.29.767 8.324-1.294 12.12-6.178 3.356-4.316 6.178-10.252 8.716-17.037.804-2.155 1.571-4.39 2.353-6.587v2.4c-.096.262-.199.518-.295.795-2.19 6.466-4.585 12.42-7.39 17.347-5.399 9.483-11.374 13.003-17.947 10.113-3.131-1.38-6.117-3.886-9.018-7.053-4.322-4.724-8.37-10.663-12.398-16.706-2.602-3.91-5.197-7.845-7.82-11.73z" />
            <path d="M153.026 168.377c2.385 3.323 4.738 6.796 7.11 10.233 4.226 6.157 8.449 12.331 12.92 17.418 4.006 4.539 8.119 8.2 12.548 9.405 3.793 1.031 7.465.029 11.02-3.352 3.849-3.654 7.108-9.459 9.991-16.518 1.123-2.74 2.161-5.687 3.238-8.531v2.396c-.096.266-.199.526-.295.8-2.797 7.887-5.897 14.907-9.672 20.012-4.17 5.641-8.634 7.923-13.38 6.938-3.48-.71-6.8-3.05-10.03-6.192-4.603-4.475-8.887-10.404-13.152-16.518-3.619-5.193-7.216-10.468-10.852-15.587.199-.174.37-.337.554-.504" />
            <path d="M150.104 172.109c-.238-.313-.48-.619-.71-.924.195-.128.394-.256.586-.388 1.841 2.275 3.658 4.675 5.467 7.11 4.727 6.348 9.427 12.828 14.218 18.931 4.12 5.26 8.432 9.459 13.02 11.73 3.882 1.92 7.757 2.09 11.624-.305 4.06-2.517 7.621-7.376 10.753-14.016 1.692-3.587 3.22-7.632 4.82-11.467v2.396a102 102 0 0 1-5.947 13.44c-4.713 8.59-9.953 13.269-15.9 12.895-3.91-.245-7.631-2.52-11.268-5.8-4.539-4.092-8.804-9.562-13.024-15.264-4.557-6.132-9.06-12.402-13.639-18.338" />
            <path d="m145.359 173.36.55-.252a117 117 0 0 1 7.87 8.936c4.899 6.064 9.754 12.36 14.685 18.267 4.93 5.908 10.095 10.806 15.615 12.796 2.783 1 5.566 1.106 8.35.036 4.045-1.557 7.699-5.438 11.058-11.073 2.335-3.91 4.418-8.555 6.373-13.532v2.638c-.135.256-.28.497-.408.778-1.312 2.844-2.563 5.869-3.939 8.531-4.855 9.363-10.344 14.705-16.603 14.823-3.932.074-7.732-1.845-11.453-4.806-4.319-3.44-8.417-8.112-12.459-13.12-5.119-6.345-10.195-12.907-15.356-19.056-1.457-1.738-2.929-3.366-4.425-4.849z" />
            <path d="M140.507 176.094c.274.18.544.355.814.561 5.008 3.555 9.757 8.734 14.467 14.115 5.257 6.004 10.443 12.385 15.857 17.546 4.6 4.386 9.295 7.852 14.258 8.815 5.246 1.013 10.237-1.155 14.95-6.903 3.366-4.102 6.313-9.597 9.007-15.946v2.656c-.103.17-.213.327-.312.519-1.518 2.929-2.976 6.064-4.557 8.755-4.134 7.013-8.72 11.186-13.76 12.252-3.668.779-7.276-.213-10.852-2.236-4.557-2.577-8.908-6.629-13.152-11.325-4.884-5.406-9.719-11.09-14.603-16.507-4.265-4.745-8.62-9.06-13.152-11.858.335-.153.687-.292 1.035-.444" />
            <path d="M209.86 200.275c-.75 1.397-1.5 2.805-2.257 4.184-3.807 6.935-7.983 11.954-12.658 14.393a14.16 14.16 0 0 1-10.308 1.194c-5.435-1.333-10.621-5.076-15.676-9.896-4.976-4.76-9.931-9.867-14.9-14.794-4.575-4.543-9.2-8.74-14.023-11.503-3.224-1.845-6.487-2.982-9.804-2.95-6.28.064-12.029 4.447-17.257 12.868a1.1 1.1 0 0 0-.171.494 3 3 0 0 0 .299-.356c3.337-5.122 6.938-8.694 10.81-10.564a14.04 14.04 0 0 1 8.736-1.162c6.544 1.133 12.708 5.95 18.75 11.758 5.478 5.264 10.903 10.86 16.377 16.174 4.265 4.144 8.637 7.596 13.152 9.718 5.91 2.772 11.783 2.808 17.57-1.802 4.03-3.2 7.628-8.318 10.93-14.727.125-.242.27-.427.402-.64z" />
            <path d="M209.86 206.03a34 34 0 0 0-1.023 1.6c-4.32 7.23-9.025 12.2-14.219 14.378a16.35 16.35 0 0 1-9.92.917c-6.363-1.33-12.442-5.556-18.445-10.543-4.977-4.134-9.935-8.484-14.93-12.626-4.585-3.81-9.241-6.931-14.044-8.712-8.353-3.1-16.369-.913-23.883 9.054a8.6 8.6 0 0 0-.992 1.606 2.5 2.5 0 0 0 .306-.305q6.462-8.588 13.799-10.184c4.017-.875 8.008-.192 11.975 1.503 6.601 2.826 12.914 8.087 19.216 13.469 5.105 4.365 10.195 8.865 15.417 12.377 4.823 3.245 9.71 5.588 14.733 5.868q11.763.658 21.523-15.363c.15-.241.313-.43.466-.643z" />
            <path d="M209.86 211.785c-1.002 1.262-1.994 2.577-3.01 3.786-4.547 5.41-9.345 8.598-14.386 9.683-4.315.93-8.598.327-12.874-1.177-5.766-2.033-11.375-5.73-16.966-9.48-5.332-3.58-10.682-7.191-16.053-10.532a33.9 33.9 0 0 0-12.821-4.91c-7.465-1.14-14.638 1.504-21.435 9.317a1.7 1.7 0 0 0-.355.683c.842-.821 1.685-1.682 2.534-2.464 4.216-3.863 8.578-5.893 13.07-6.462 5.638-.71 11.176 1.042 16.679 3.772 6.043 2.989 11.932 7.223 17.858 11.221 5.058 3.416 10.13 6.687 15.302 8.919 4.707 2.03 9.449 3.089 14.219 2.456 6.32-.835 12.295-4.774 17.887-12.036.113-.149.238-.252.355-.38z" />
            <path d="M209.86 217.554c-1.71 1.5-3.401 3.153-5.136 4.475a27.2 27.2 0 0 1-12.274 5.407c-4.792.778-9.558.153-14.318-1.201-5.481-1.564-10.884-4.223-16.294-6.822-5.506-2.648-11.001-5.403-16.529-7.745-5.364-2.275-10.78-3.473-16.237-3.2-5.983.324-11.826 2.61-17.467 7.6a1.07 1.07 0 0 0-.338.52c.85-.62 1.695-1.277 2.552-1.853a29.3 29.3 0 0 1 11.442-4.677 28.6 28.6 0 0 1 12.75.625 99 99 0 0 1 12.57 4.426c6.348 2.737 12.654 6.064 19.006 8.765 3.974 1.692 7.99 2.844 12 3.974a27.1 27.1 0 0 0 11.18.665c5.804-.79 11.456-3.437 16.905-8.453a1.5 1.5 0 0 1 .188-.128z" />
          </g>
          <text
            x={12.919}
            y={25.976}
            className="page-8_svg__cls-24"
            style={{
              fontSize: "14.2924px",
              fontFamily: "Heavitas",
              fill: palette.primary,
              strokeWidth: 0.352724,
            }}
          >
            {"PRINCIPAIS"}
            <tspan
              className="page-8_svg__cls-25"
              style={{
                fill: palette.backgroundGray,
                strokeWidth: 0.352724,
              }}
            >
              <tspan x={12.919} y={43.259} style={{ strokeWidth: 0.355458 }}>
                {"BANDEIRAS"}
              </tspan>
            </tspan>
          </text>
          <path
            d="M220.261 10.947a51 51 0 0 1 1.141-13.038H101.634s34.693 25.323 84.084 23.105c18.672-.846 28.124-3.867 34.916-7.251z"
            className="page-8_svg__cls-25"
            style={{ fill: palette.primary, strokeWidth: 0.355458 }}
          />

          <path
            d="M2150.63 1740.47h457.2v40.22a145.76 145.76 0 0 1-145.76 145.76h-347.51v-149.91a36.07 36.07 0 0 1 36.07-36.07"
            className="page-8_svg__cls-143"
            style={{
              fill: palette.backgroundLight,
              filter: "url(#page-8_svg__e)",
            }}
            transform="translate(0, -76.9) rotate(179.85 473.277 412.68)scale(.35546)"
          />

          <g
            className="page-8_svg__cls-140"
            clipPath="url(#page-8_svg__b)"
            transform="matrix(.35546 0 0 .35546 -732.066 -478.079)"
          >
            <g
              className="page-8_svg__cls-9"
              data-name="CZJK68"
              style={{ opacity: 0.13 }}
            >
              <path d="M1978.73 1725c1.46-.67 3-1.26 4.52-1.8-8.41-8-17-14-26.08-16.82-15.6-4.8-30.3 1.77-44 20.36-10.3 14-19 32.61-26.75 53.64-1 2.74-2 5.45-3.07 8.17v7.1c2.73-7 5.38-14.19 8.18-21 9.88-24 21-43.55 34.53-54.5 10.93-8.86 22.11-10.44 33.47-6.48 6.61 2.33 12.98 6.23 19.2 11.33" />
              <path d="M1994.68 1720.63c1.32-.14 2.64-.22 4-.25a162.2 162.2 0 0 0-22.61-23.69c-8.76-7.24-17.72-12-27-12.52-14.57-.83-27.74 9.41-39.77 28.63-9.57 15.3-17.56 34.61-24.81 55.77-.34 1-.72 2-1.08 2.92v7.1c3.19-8.56 6.25-17.43 9.59-25.63 11.31-27.78 24.19-49.56 40.34-58.45 11.45-6.32 22.84-4.7 34.17 2 9.43 5.55 18.49 13.89 27.17 24.12" />
              <path d="M1992 1698.78c6 6.92 11.87 14.55 17.63 22.59 1.3.26 2.57.58 3.82.94-8.91-13.06-18-25.32-27.61-35.53-9.08-9.67-18.44-17.17-28.34-20.58-16.56-5.7-31.83 2.52-45.87 24-8.21 12.53-15.34 28-21.69 45.6-2.23 6.18-4.4 12.46-6.61 18.69v7.1c2.48-7.08 4.84-14.38 7.44-21.18 8.49-22.17 17.87-41.67 29.37-54.65q15.74-17.78 33.09-14.59c13.77 2.46 26.48 13.48 38.77 27.61" />
              <path d="M2025.15 1722.52c-.44.81-.87 1.64-1.29 2.46-7.09-10.64-14.2-21.21-21.43-31.29-10.89-15.18-22.31-27.75-34.58-35.47-12.66-8-25.34-9.27-38-.14-12.08 8.71-22.39 24.72-31.25 45.76-5.39 12.82-10.18 27.06-15.25 40.67v-7.81c1-2.81 2.12-5.53 3.09-8.45 6.15-18.55 13.06-35.28 21-49.4 16.16-28.59 34.06-39.06 53.92-29.69 12 5.68 23.16 16.94 33.94 30.45 10.32 12.92 20.15 27.68 29.85 42.91" />
              <path d="m2028.59 1710.79 1.82 2.88c-.42.64-.83 1.29-1.24 1.94q-7.15-11-14.35-22c-11.23-17-22.83-32.46-35.26-44-9.71-9-19.72-15.16-30.21-15.67-13.12-.64-25.15 8.11-36.19 24.78-9.84 14.84-18 34-25.37 55.35-1.47 4.26-3 8.46-4.45 12.69v-7.1c.64-1.77 1.31-3.48 1.92-5.3 8.29-24.89 17.52-47.36 29.07-63.84 13.75-19.62 28.55-26.15 44.33-20.1 10.81 4.15 20.94 13.25 30.74 24.62 13.73 15.96 26.51 35.66 39.19 55.75" />
              <path d="M2036.21 1705.52c-.5.63-1 1.28-1.46 1.93-11-16.81-22.1-33.52-33.34-49.52-11.78-16.76-24.32-29.74-37.82-36.66-15.4-7.9-30.27-4.65-44.43 12.51-12.44 15.09-22.36 37.33-31.18 62.67q-2.31 6.65-4.64 13.27v-7.1c.34-.85.69-1.65 1-2.56 8.29-25.21 17.53-48 29.08-64.89 12.74-18.64 26.54-26.3 41.41-22.49 11.35 2.92 22 11.73 32.29 23.14 12.9 14.29 24.92 32.16 36.82 50.6q6.13 9.53 12.27 19.1" />
              <path d="M2042.12 1697.81c-.41.52-.83 1-1.23 1.58-.4-.61-.8-1.21-1.19-1.82-14.14-21.67-28.14-43.88-43.1-62.31-11.84-14.59-24.06-26.71-37.35-31.61-13.5-5-26.48-1.26-38.87 12.79-12.05 13.67-21.82 34.19-30.52 57.9-2.21 6-4.35 12.22-6.52 18.34v-7.1c.33-.87.68-1.7 1-2.61 7.9-23.61 16.68-45 27.46-61.31q21.57-32.67 46.81-23.21c10.81 4 21 12.92 30.81 24 14.15 16 27.33 36 40.43 56.26 4.09 6.38 8.15 12.76 12.27 19.1" />
              <path d="M2047.62 1691.35c-7.08-10.28-14.14-20.66-21.21-31-11.85-17.27-23.71-34.47-36.38-48.2-11.69-12.68-23.72-22.5-36.67-24.78s-25.14 3.84-36.6 18.3c-10.13 12.78-18.65 30.35-26.3 50.45-2.43 6.37-4.75 13-7.12 19.5v-7.1c.3-.79.61-1.54.89-2.36 6.62-19.15 13.85-36.78 22.32-51.37 16.3-28.08 34.32-38.5 54.17-29.94 9.46 4.08 18.48 11.5 27.22 20.88 13.06 14 25.28 31.55 37.44 49.44 7.85 11.57 15.69 23.23 23.56 34.75z" />
              <path d="M2054.92 1684.19c-7.21-9.85-14.31-20.12-21.44-30.31-12.76-18.23-25.5-36.51-39-51.52-12.09-13.44-24.51-24.28-37.88-27.85-11.44-3-22.56-.09-33.31 9.92-11.61 10.82-21.43 28-30.16 48.92-3.38 8.11-6.52 16.82-9.78 25.25v-7.1c.3-.79.61-1.56.9-2.36 8.44-23.36 17.8-44.15 29.19-59.27 12.59-16.7 26.07-23.45 40.4-20.54 10.5 2.14 20.52 9 30.28 18.33 13.9 13.26 26.87 30.82 39.72 48.92 10.92 15.38 21.78 31 32.76 46.15-.6.47-1.13.97-1.68 1.46" />
              <path d="M2063.74 1673.14c.72.92 1.45 1.83 2.17 2.73l-1.77 1.15c-5.55-6.74-11-13.84-16.5-21-14.27-18.8-28.45-38-42.89-56.06-12.43-15.57-25.45-28-39.3-34.73-11.72-5.69-23.42-6.19-35.09.9-12.26 7.45-23 21.84-32.46 41.5-5.11 10.62-9.72 22.6-14.56 34v-7.1c5.51-14.5 11.39-28 18-39.8 14.23-25.44 30.08-39.28 48-38.18 11.77.73 23 7.46 34 17.17 13.7 12.12 26.58 28.32 39.31 45.2 13.68 18.08 27.27 36.64 41.09 54.22" />
              <path d="M2078.07 1669.43c-.56.24-1.11.5-1.67.75a343 343 0 0 1-23.75-26.47c-14.8-18-29.45-36.59-44.34-54.09s-30.47-32-47.13-37.86c-8.4-3-16.8-3.27-25.2-.1-12.22 4.61-23.25 16.1-33.39 32.79-7 11.61-13.34 25.33-19.25 40.07v-7.81c.42-.77.85-1.47 1.24-2.3 4-8.43 7.74-17.39 11.89-25.24 14.66-27.73 31.23-43.55 50.12-43.89 11.88-.22 23.34 5.46 34.57 14.23 13 10.18 25.42 24 37.61 38.85 15.46 18.78 30.79 38.22 46.36 56.43 4.4 5.14 8.85 10 13.37 14.36z" />
              <path d="M2092.71 1661.34c-.82-.55-1.64-1.1-2.46-1.67-15.12-10.58-29.45-25.85-43.67-41.79-15.87-17.79-31.53-36.68-47.87-52-13.89-13-28.06-23.26-43-26.1-15.84-3-30.91 3.42-45.14 20.43-10.16 12.15-19.06 28.45-27.2 47.23v-7.81c.32-.51.65-1 1-1.54 4.58-8.67 9-18 13.76-25.93 12.48-20.77 26.33-33.12 41.53-36.28 11.08-2.3 22 .63 32.76 6.62 13.77 7.63 26.89 19.63 39.71 33.54 14.75 16 29.34 32.84 44.08 48.87 12.91 14.06 26 26.84 39.76 35.12-1.15.44-2.21.86-3.26 1.31" />
              <path d="M1883.34 1589.73c2.28-4.13 4.53-8.31 6.82-12.39 11.49-20.54 24.1-35.4 38.21-42.62a43.54 43.54 0 0 1 31.08-3.54c16.4 4 32.06 15 47.32 29.31 15.08 14.1 30 29.21 45 43.81 13.81 13.44 27.77 25.88 42.33 34 9.74 5.47 19.59 8.84 29.6 8.74 19-.19 36.31-13.17 52.1-38.1a3.3 3.3 0 0 0 .51-1.46 9 9 0 0 0-.91 1c-10.07 15.17-20.94 25.75-32.62 31.28a43.14 43.14 0 0 1-26.37 3.45c-19.76-3.36-38.36-17.63-56.61-34.83-16.54-15.59-32.92-32.15-49.43-47.88-12.9-12.28-26.08-22.5-39.75-28.79-17.85-8.21-35.57-8.31-53 5.34-12.16 9.51-23 24.63-33 43.61-.38.72-.81 1.26-1.22 1.89z" />
              <path d="M1883.34 1572.69c1-1.57 2.09-3.08 3.1-4.73 13-21.42 27.25-36.13 42.89-42.59a50.3 50.3 0 0 1 30-2.71c19.2 3.93 37.56 16.46 55.68 31.21 15 12.25 30 25.14 45 37.4 13.84 11.28 27.91 20.52 42.4 25.79 25.22 9.18 49.41 2.71 72.1-26.81a24.8 24.8 0 0 0 3-4.75 9 9 0 0 0-.93.91q-19.5 25.42-41.65 30.15c-12.13 2.59-24.17.57-36.14-4.45-19.94-8.37-39-23.95-58-39.89-15.42-12.92-30.78-26.24-46.55-36.64-14.56-9.61-29.31-16.55-44.47-17.38q-35.52-2-65 45.49c-.45.72-.94 1.27-1.41 1.9z" />
              <path d="M1883.34 1555.65c3-3.74 6-7.64 9.09-11.21 13.73-16 28.21-25.46 43.44-28.68 13-2.75 26-1 38.86 3.49 17.4 6 34.31 17 51.21 28.07 16.13 10.6 32.25 21.3 48.46 31.19a103.6 103.6 0 0 0 38.71 14.53c22.51 3.38 44.18-4.45 64.71-27.58a5.2 5.2 0 0 0 1.11-2c-2.55 2.43-5.08 5-7.66 7.29-12.71 11.44-25.88 17.45-39.45 19.13-17 2.11-33.73-3.08-50.34-11.16-18.21-8.85-36-21.39-53.91-33.23-15.27-10.12-30.58-19.8-46.2-26.41-14.21-6-28.52-9.15-42.95-7.28-19.08 2.48-37.12 14.14-54 35.65a13 13 0 0 1-1.08 1.12z" />
              <path d="M1883.34 1538.61c5.17-4.45 10.28-9.34 15.51-13.26a82.8 82.8 0 0 1 37.06-16c14.46-2.31 28.85-.46 43.22 3.56 16.55 4.63 32.86 12.5 49.19 20.2 16.62 7.83 33.21 16 49.89 22.93 16.19 6.74 32.55 10.28 49 9.41 18.06-1 35.7-7.72 52.73-22.5a3.1 3.1 0 0 0 1-1.53c-2.57 1.83-5.12 3.77-7.71 5.48a89.4 89.4 0 0 1-34.53 13.85 87.8 87.8 0 0 1-38.5-1.85 303 303 0 0 1-37.94-13.11c-19.17-8.1-38.2-18-57.37-26-12-5-24.13-8.39-36.23-11.77a83.2 83.2 0 0 0-33.74-2c-17.53 2.33-34.59 10.17-51 25a4 4 0 0 1-.57.37z" />
            </g>
          </g>

          <path
            d="M2196.14 1524.06H2274v77.85a93.83 93.83 0 0 1-93.83 93.83 77.85 77.85 0 0 1-77.85-77.85 93.83 93.83 0 0 1 93.83-93.83z"
            className="page-8_svg__cls-141"
            style={{ fill: palette.primary, filter: "url(#page-8_svg__c)" }}
            transform="matrix(.35546 0 0 .35546 -732.066 -478.079)"
          />

          <path
            d="M2150.63 1740.47h457.2v40.22a145.76 145.76 0 0 1-145.76 145.76h-347.51v-149.91a36.07 36.07 0 0 1 36.07-36.07"
            className="page-8_svg__cls-143"
            style={{
              fill: palette.backgroundLight,
              filter: "url(#page-8_svg__e)",
            }}
            transform="rotate(179.85 473.277 412.68)scale(.35546)"
          />

          <path
            d="M2196.17 1740.47H2274v77.85a93.83 93.83 0 0 1-93.83 93.83 77.85 77.85 0 0 1-77.85-77.85 93.83 93.83 0 0 1 93.85-93.83"
            className="page-8_svg__cls-144"
            style={{ fill: palette.secondary, filter: "url(#page-8_svg__f)" }}
            transform="matrix(.35546 0 0 .35546 -732.066 -478.079)"
          />

          <path
            d="M2150.59 1955.31h457.2v40.22A145.76 145.76 0 0 1 2462 2141.29h-347.47v-149.91a36.07 36.07 0 0 1 36.06-36.07"
            className="page-8_svg__cls-145"
            style={{
              fill: palette.backgroundLight,
              filter: "url(#page-8_svg__g)",
            }}
            transform="rotate(179.85 473.263 489.05)scale(.35546)"
          />

          <path
            d="M2196.14 1955.31H2274v77.85a93.83 93.83 0 0 1-93.83 93.83 77.85 77.85 0 0 1-77.85-77.85 93.83 93.83 0 0 1 93.83-93.83z"
            className="page-8_svg__cls-146"
            style={{ fill: palette.primary, filter: "url(#page-8_svg__h)" }}
            transform="matrix(.35546 0 0 .35546 -732.066 -478.079)"
          />

          <ClippedImage
            id="emerald-page-8-1"
            triggerImageEdit={triggerImageEdit}
            x={14}
            y={68}
            width={68}
            height={68}
            imageNumber={9}
            imageRatio={1}
            notebook={notebook}
          >
            <path
              d="M2190.35 1709.9h-77.85v-77.85a93.83 93.83 0 0 1 93.83-93.83 77.85 77.85 0 0 1 77.85 77.85 93.83 93.83 0 0 1-93.83 93.83"
              fill={palette.backgroundLight}
              transform="matrix(.35546 0 0 .35546 -732.066 -478.079)"
            />
          </ClippedImage>

          <foreignObject
            x={85.005}
            y={69.032}
            width={105}
            height={58}
            className="clickable"
          >
            <div
              style={{
                fontSize: "6px",
                fontFamily: "MontserratAlternates",
                fontWeight: 500,
                color: palette.backgroundDark,
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.first_cause,
                  field: "first_cause",
                  label: "Primeira Bandeira",
                  type: "select",
                  options: NotebooksCausesOptions.map((c) => ({
                    value: c,
                    label: c,
                  })),
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.first_cause}
            </div>

            <div
              style={{
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: palette.backgroundGray,
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.first_cause_proposal,
                  field: "first_cause_proposal",
                  label: "Primeira Bandeira",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.first_cause_proposal}
            </div>
          </foreignObject>

          <ClippedImage
            id="emerald-page-8-2"
            triggerImageEdit={triggerImageEdit}
            x={14}
            y={145}
            width={68}
            height={68}
            imageNumber={10}
            imageRatio={1}
            notebook={notebook}
          >
            <path
              d="M2190.39 1927.3h-77.85v-77.85a93.83 93.83 0 0 1 93.83-93.83 77.85 77.85 0 0 1 77.85 77.85 93.83 93.83 0 0 1-93.83 93.83"
              fill={palette.backgroundLight}
              transform="matrix(.35546 0 0 .35546 -732.066 -478.079)"
            />
          </ClippedImage>

          <foreignObject
            x={85.005}
            y={145.032}
            width={105}
            height={58}
            className="clickable"
          >
            <div
              style={{
                fontSize: "6px",
                fontFamily: "MontserratAlternates",
                fontWeight: 500,
                color: palette.backgroundDark,
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.second_cause,
                  field: "second_cause",
                  label: "Segunda Bandeira",
                  type: "select",
                  options: NotebooksCausesOptions.map((c) => ({
                    value: c,
                    label: c,
                  })),
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.second_cause}
            </div>

            <div
              style={{
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: palette.backgroundGray,
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.second_cause_proposal,
                  field: "second_cause_proposal",
                  label: "Segunda Bandeira",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.second_cause_proposal}
            </div>
          </foreignObject>

          <ClippedImage
            id="emerald-page-8-3"
            triggerImageEdit={triggerImageEdit}
            x={0}
            y={210}
            width={87}
            height={87}
            imageNumber={11}
            imageRatio={1}
            notebook={notebook}
          >
            <path
              d="M2190.65 2141.15h-78.16v-78.2a94.2 94.2 0 0 1 94.2-94.2 78.16 78.16 0 0 1 78.17 78.2 94.2 94.2 0 0 1-94.2 94.2z"
              fill={palette.backgroundLight}
              transform="matrix(.35546 0 0 .35546 -732.066 -478.079)"
            />
          </ClippedImage>

          <foreignObject
            x={85.005}
            y={222.032}
            width={105}
            height={58}
            className="clickable"
          >
            <div
              style={{
                fontSize: "6px",
                fontFamily: "MontserratAlternates",
                fontWeight: 500,
                color: palette.backgroundDark,
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.third_cause,
                  field: "third_cause",
                  label: "Terceira Bandeira",
                  type: "select",
                  options: NotebooksCausesOptions.map((c) => ({
                    value: c,
                    label: c,
                  })),
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.third_cause}
            </div>

            <div
              style={{
                fontSize: "5px",
                fontFamily: "MontserratAlternates",
                color: palette.backgroundGray,
                textAlign: "start",
                strokeWidth: 0.352449,
                width: "100%",
                whiteSpace: "pre-wrap",
              }}
              onClick={() =>
                triggerFieldEdit({
                  currentValue: notebook.third_cause_proposal,
                  field: "third_cause_proposal",
                  label: "Terceira Bandeira",
                  type: "textarea",
                  options: [],
                  onUpdateField: updateField,
                  validate: validateText,
                })
              }
            >
              {notebook.third_cause_proposal}
            </div>
          </foreignObject>
        </svg>
      </PaperInside>
    </Paper>
  );
};

export default Page8;
