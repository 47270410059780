import { throttle } from "lodash";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  NotebookPages,
  NOTEBOOK_PAGE,
  Status,
} from "../../../../app/constants";
import { AppDispatch } from "../../../../app/store";
import NotebookButton from "../../../../components/buttons/notebook-button/notebook-button";
import { makeContainer } from "../../../../components/container/container";
import Loading from "../../../../components/loading/loading";
import { closeModal, setModalContent } from "../../../../features/modal/slice";
import { selectNotebookStatus } from "../../../../features/notebook/slice";
import Check from "../../../../resources/images/check.png";
import Error from "../../../../resources/images/error.png";
import "./save-questionaire-modal.css";

type ModalProps = {
  show: boolean;
  isNew: boolean;
  onClose: () => void;
};

const updateStatus = throttle(
  (currentStatus: Status, nextStatus: Status, setStatus: (Status) => void) => {
    if (currentStatus === Status.IDLE && nextStatus === Status.FAILED) {
      return;
    }
    setStatus(nextStatus);
  },
  500,
  { leading: false, trailing: true }
);

const SaveQuestionaireModalContainer = makeContainer(
  "save-questionaire-modal-container"
);
const SaveQuestionaireButtonsContainer = makeContainer(
  "save-questionaire-modal-buttons-container"
);
const SaveQuestionaireTopContainer = makeContainer(
  "save-questionaire-modal-top-container"
);
const SaveQuestionaireTopText = makeContainer(
  "save-questionaire-modal-top-text"
);
const SaveQuestionaireTitle = makeContainer("save-questionaire-modal-title");
const ContentContainer = makeContainer(
  "save-questionaire-modal-content-container"
);

const SaveQuestionaireContent: React.FC<{ status: Status }> = ({ status }) => {
  return (
    <SaveQuestionaireModalContainer>
      <SaveQuestionaireTopContainer>
        <SaveQuestionaireTopText>Quase lá</SaveQuestionaireTopText>
        <SaveQuestionaireTitle>Salvando questionário</SaveQuestionaireTitle>
      </SaveQuestionaireTopContainer>
      <ContentContainer>
        <Loading />
      </ContentContainer>
      <SaveQuestionaireButtonsContainer></SaveQuestionaireButtonsContainer>
    </SaveQuestionaireModalContainer>
  );
};

const SavedQuestionaireContent: React.FC<{ close: any; isNew: boolean }> = ({
  close,
  isNew,
}) => {
  return (
    <SaveQuestionaireModalContainer>
      <SaveQuestionaireTopContainer>
        <SaveQuestionaireTopText>Feito</SaveQuestionaireTopText>
        <SaveQuestionaireTitle>
          Questionário salvo com sucesso
        </SaveQuestionaireTitle>
      </SaveQuestionaireTopContainer>
      <ContentContainer>
        <img className="modal-large-icon" alt="ícone sucesso" src={Check} />
      </ContentContainer>
      <SaveQuestionaireButtonsContainer>
        <NotebookButton
          className="save-questionaire-modal-input-button"
          onClick={() => {
            if (isNew) {
              window.location.href = `/${NOTEBOOK_PAGE}/${NotebookPages.NOTEBOOK_VIEW}`;
            }
            close();
          }}
        >
          Fechar
        </NotebookButton>
      </SaveQuestionaireButtonsContainer>
    </SaveQuestionaireModalContainer>
  );
};

const ErrorDuringSaveQuestionaireContent: React.FC<{ close: any }> = ({
  close,
}) => {
  return (
    <SaveQuestionaireModalContainer>
      <SaveQuestionaireTopContainer>
        <SaveQuestionaireTopText>Algo deu errado...</SaveQuestionaireTopText>
        <SaveQuestionaireTitle>
          Questionário não salvo, tente novamente mais tarde
        </SaveQuestionaireTitle>
      </SaveQuestionaireTopContainer>
      <ContentContainer>
        <img className="modal-large-icon" alt="ícone erro" src={Error} />
      </ContentContainer>
      <SaveQuestionaireButtonsContainer>
        <NotebookButton
          className="save-questionaire-modal-input-button"
          onClick={close}
        >
          Fechar
        </NotebookButton>
      </SaveQuestionaireButtonsContainer>
    </SaveQuestionaireModalContainer>
  );
};

const SaveQuestionaireModal: React.FC<ModalProps> = memo(
  ({ show, onClose, isNew }) => {
    const dispatch: AppDispatch = useDispatch();
    const [status, setStatus] = useState(Status.LOADING);
    const nextStatus: Status = useSelector(selectNotebookStatus);

    useEffect(() => {
      updateStatus(status, nextStatus, setStatus);
      // eslint-disable-next-line
    }, [nextStatus]);

    useEffect(() => {
      setStatus(Status.IDLE);
    }, [show]);

    const close = () => {
      onClose();
      dispatch(closeModal(null));
    };

    const contentByStatus = {
      [Status.IDLE]: <SaveQuestionaireContent status={status} />,
      [Status.LOADING]: <SaveQuestionaireContent status={status} />,
      [Status.SUCCEEDED]: (
        <SavedQuestionaireContent close={close} isNew={isNew} />
      ),
      [Status.FAILED]: <ErrorDuringSaveQuestionaireContent close={close} />,
    };

    useEffect(() => {
      const changeModalStatus = () => {
        if (show) {
          dispatch(
            setModalContent({
              content: contentByStatus[status],
              onClose: () => {},
            })
          );
        } else {
          dispatch(closeModal(null));
        }
      };

      changeModalStatus();
      // eslint-disable-next-line
    }, [dispatch, onClose, show, status]);

    return <></>;
  }
);

export default SaveQuestionaireModal;
