import * as React from "react";
import { useSelector } from "react-redux";
import {
  NotebooksOfficesOptions,
  toFeminine,
} from "../../../../../app/constants";
import { validateText } from "../../../../../app/utils";
import { selectPalette } from "../../../../../features/notebook/slice";
import { ClippedImage, Paper, PaperInside } from "../commons/commons";
import { TemplatePageProps } from "../commons/interfaces";

const Page14: React.FC<TemplatePageProps> = ({
  className,
  notebook,
  triggerImageEdit,
  triggerFieldEdit,
  updateField,
}) => {
  const { palette } = useSelector(selectPalette);
  return (
    <Paper className={className}>
      <PaperInside>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="210mm"
          height="297mm"
          viewBox="0 0 210 297"
        >
          <defs>
            <filter id="page-14_svg__a">
              <feDropShadow
                dx="4"
                dy="4"
                stdDeviation="5"
                floodColor="#000000"
                floodOpacity="0.3"
              />
            </filter>
          </defs>
          <ClippedImage
            id="saphire-page-14"
            triggerImageEdit={triggerImageEdit}
            x={0}
            y={0}
            offsetY={-50}
            width={210}
            height={297}
            imageNumber={20}
            imageRatio={0.7}
            notebook={notebook}
          >
            <rect width="100%" height="100%" fill={palette.backgroundLight} />
          </ClippedImage>

          <path
            d="M1590.57 3065.64h190.07v185.52h-338.47V3214a148.39 148.39 0 0 1 148.4-148.36"
            className="page-14_svg__cls-338"
            style={{
              fill: palette.backgroundDark,
              filter: "url(#page-14_svg__a)",
            }}
            transform="matrix(-.34189 0 0 -.34189 608.985 1111.994) translate(4, 4)"
          />

          <foreignObject
            x={7.055}
            y={7.06}
            width={100}
            height={47}
            className="clickable"
          >
            <div>
              <div
                style={{
                  fontSize: "8.9259px",
                  fontFamily: "Heavitas",
                  color: palette.secondary,
                  strokeWidth: 0.352449,
                }}
                onClick={() =>
                  triggerFieldEdit({
                    currentValue: notebook.candidate_ballout_box_name,
                    field: "candidate_ballout_box_name",
                    label: "Nome de Urna",
                    type: "input",
                    options: [],
                    onUpdateField: updateField,
                    validate: validateText,
                  })
                }
              >
                {notebook.candidate_ballout_box_name}
              </div>
              <div
                style={{
                  fontSize: "6.34409px",
                  fontFamily: "MontserratAlternates",
                  color: palette.backgroundLight,
                  strokeWidth: 0.352449,
                }}
                onClick={() =>
                  triggerFieldEdit({
                    currentValue: notebook.office,
                    field: "office",
                    label: "Cargo",
                    type: "select",
                    options: NotebooksOfficesOptions,
                    onUpdateField: updateField,
                    validate: validateText,
                  })
                }
              >
                {notebook.gender === "Feminino"
                  ? `Candidata a ${toFeminine(notebook.office)?.toLowerCase()}`
                  : `Candidato a ${notebook.office?.toLowerCase()}`}
              </div>
              <div
                style={{
                  fontSize: "6.34409px",
                  fontFamily: "MontserratAlternates",
                  color: palette.backgroundLight,
                  strokeWidth: 0.352449,
                }}
                onClick={() =>
                  triggerFieldEdit({
                    currentValue: notebook.age,
                    field: "age",
                    label: "Idade",
                    type: "input",
                    options: [],
                    onUpdateField: updateField,
                    validate: validateText,
                  })
                }
              >
                {`${notebook.age} anos`}
              </div>
            </div>
          </foreignObject>
        </svg>
      </PaperInside>
    </Paper>
  );
};

export default Page14;
