import React, { memo } from "react";
import { Status } from "../../../../app/constants";
import { makeContainer } from "../../../../components/container/container";
import { ElectionSummary } from "../../../../features/elections/type";
import "./election-summary.css";
import ElectionWidget from "./election-widget";

type Props = {
  children?: string | JSX.Element | JSX.Element[];
  summary?: ElectionSummary;
  status?: Status;
};

const ElectionSummaryContainerContainer = makeContainer(
  "election-summary-container"
);
const ElectionSummaryLine = makeContainer("election-summary-line");

const ElectionSummaryContainer: React.FC<Props> = ({ summary, status }) => {
  return (
    <ElectionSummaryContainerContainer>
      <ElectionSummaryLine>
        <ElectionWidget
          title="Votos Totais"
          value={summary?.total_votes}
          status={status}
        />
      </ElectionSummaryLine>
      <ElectionSummaryLine>
        <ElectionWidget
          title="Válidos"
          value={summary?.valid_votes}
          status={status}
        />
        <ElectionWidget
          title="% Válidos"
          value={summary?.prct_valid / 100}
          status={status}
        />
      </ElectionSummaryLine>
      <ElectionSummaryLine>
        <ElectionWidget
          title="Nulos"
          value={summary?.null_votes}
          status={status}
        />
        <ElectionWidget
          title="% Nulos"
          value={summary?.prct_null_votes / 100}
          status={status}
        />
      </ElectionSummaryLine>
      <ElectionSummaryLine>
        <ElectionWidget
          title="Brancos"
          value={summary?.blank_votes}
          status={status}
        />
        <ElectionWidget
          title="% Brancos"
          value={summary?.prct_blank_votes / 100}
          status={status}
        />
      </ElectionSummaryLine>
    </ElectionSummaryContainerContainer>
  );
};

export default memo(ElectionSummaryContainer);
